import React from "react";
/**
 *
 * @param {*} component
 */

export default function withFileUpload(MainComponent) {
  return class extends React.Component {
    _handleClick = () => {
      this.refs.fileField.click();
    }
    _onUpload = (event) => {
      this.props.onChange(event.target, this.props.type);
      event.target.value = null
    }
    render() {
      return (
        <>
          <div  style={{width:'100%'}}>
            <MainComponent {...this.props} onClick={this._handleClick} />
            <input ref="fileField" accept={this.props.type || 'image/*'} onChange={this._onUpload} id="file-upload" type="file" style={{  display:'none' }} />
          </div>
        </>
      )
    }
  }
}
