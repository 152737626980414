import React from 'react';
import Box from 'commons/muiCustomComponents/Box';
import InfoIcon from '@material-ui/icons/Info';
import ErrorIcon from '@material-ui/icons/Error';

export default function Alert(props) {
  if (props.severity === 'error') {
    return (
      <Box component="span" display="flex">
        <ErrorIcon fontSize="small" style={{marginRight:'8px'}} />
        {
          props.children
        }
      </Box>
    )
  }
  if (props.severity === 'info') {
    return (
      <Box component="span" display="flex">
        <InfoIcon fontSize="small" style={{marginRight:'8px'}} />
        {
          props.children
        }
      </Box>
    )
  }
}
