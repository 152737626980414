import socialAction from "../../constants/actions/social";

const initalState = {};
  
/**
 * This reducer function handle the auth and permission related
 * functionalites
 * @param {*} state 
 * @param {*} action 
 */
export default function socialReducer(state=initalState, action) {
  switch(action.type) {
    case socialAction.FETCHFACEBOOK.SUCCESS:
      return {
        ...state, 
        facebookPages: action.data,
      }
    case socialAction.FETCHTWITTER.SUCCESS:
      return {
        ...state, 
        twitterPages: action.data,
      }
    case socialAction.CLEARFBPAGES.SUCCESS:
      return {
        ...state, 
        facebookPages: [],
      }
    case socialAction.CLEARTWITTERACCOUNTS.SUCCESS:
      return {
        ...state, 
        twitterPages: [],
      }
    case "UpdateActiveDomainAndPermission":
      return {
        ...state,
        facebookPages: [],
        twitterPages: [],
      }
    default:
      return state;
  }
}