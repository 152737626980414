import React from 'react';
import { ThemeProvider } from "@material-ui/core";
import MuiTheme from "./themes/main-theme";
import { Provider } from 'react-redux';
import AppStore from "./app-store";
import AppLayout from "./AppLayout.jsx";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import MobileProvider from "./commons/context/MobileProvider";
import './App.scss';


/**
 * Parent component that will hold the app and connect the app
 * to the redux store and material ui theme
 */
export default function App() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Provider store={AppStore} >
      <MobileProvider.Provider value={!matches}>
        <ThemeProvider theme={Object.assign(MuiTheme,{isMobile : !matches})}>
          <AppLayout />
        </ThemeProvider>
      </MobileProvider.Provider>
    </Provider>
  )
}
