import { combineReducers } from "redux";
import landingReducer from "./components/landing/landing-reducer";
import authReducer from "./components/auth/auth-reducer";
import onBoardReducer from "./components/onboard/onboard-reducer";
import headerReducer from "./components/header/header-reducer";
import articleReducer from "./components/articles/article-reducer";
import storyReducer from "./components/story/story-reducer";
import socialReducer from "./components/social/social-reducer";
import schedulerReducer from "./components/social/scheduler/scheduler-reducer";
import appReducer from "./app-component/app-component-reducer";
import monetizationReducer from "./components/monetization/monetization-reducer";
import usersList from "./components/user-management/user-list-reducer";
import commonComponent from "./components/common/common-reducer";
import insightsReducer from "./components/insights/insights-reducer";



const AppReducer = combineReducers({
  landing: landingReducer,
  auth:authReducer,
  onBoard: onBoardReducer,
  header: headerReducer,
  article : articleReducer,
  story: storyReducer,
  social: socialReducer,
  scheduler: schedulerReducer,
  app: appReducer,
  monetization :monetizationReducer,
  usersManage:usersList,
  commonComponent : commonComponent,
  insights: insightsReducer,
});

export default AppReducer;
