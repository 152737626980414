
import React, { useState,useEffect } from "react";
import Box from "commons/muiCustomComponents/Box";
import Button from "commons/muiCustomComponents/Button";
import AddBenefit from "./AddBenefit";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import VideocamIcon from '@material-ui/icons/Videocam';
import ImageIcon from '@material-ui/icons/Image';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Paper from '@material-ui/core/Paper';
import CloseIcon from '@material-ui/icons/Close';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { useSelector, useDispatch } from "react-redux";
import {
  createNewProduct, generateBenefite, getBenefites,
  showMessage,
  saveMediaToPisco,
  updateBenefit,
  getLiveStreamPost,
  searchArticlesearch,
  createandattachBenefit,
} from "../monetization-action";
import { fetchFeed } from "../monetization-action"
import { CircularLoader } from "commons/commonWrapper/Loaders";
import { Grid, Divider } from "@material-ui/core";

function BenefitContainer({ setTotalBenefits,productId, totalBenefits,isEdit=false }) {
  const [openSidePan, setSidePan] = useState(false);
  const [value, setValue] = React.useState('noaccess');
  const [editValue ,setEditvalue] = useState(null);
  const { landing } = useSelector(state => state);
  const [benefitId, setBenefitId] = useState(null);
  const [isLoading ,setLoading] = useState(false);
  const  [isUpdating ,setUpdating] = useState(false);
  const [isEditBenefit ,setEditBenefit] = useState(false);
  const [tempTotalBenefit,settempTotalBenefit] = useState([]);
  const { sections } = landing;
  const dispatch = useDispatch();
  


  useEffect(()=>{
    if(!isEdit){
      setEditBenefit(true)
    }
  },[isEdit])
  const handleChange = async (event) => {  
    setValue(event.target.value);
    if(event && event.target && event.target.value ==="allsections"){
      setTotalBenefits([{id:'all',type:"section"}])
    }
    if(event && event.target && event.target.value ==="noaccess"){
      setTotalBenefits([])
    }
    if(event && event.target && event.target.value ==="advanced"){
      isEdit ?setTotalBenefits(tempTotalBenefit)
      :setTotalBenefits([])
    }

  };

  useEffect(()=>{
    if(isEdit && totalBenefits.length){
      setEditvalue("advanced")
      totalBenefits.forEach((item)=>{
        if(item.sections.length>0){
          if(item.sections[0]==='all'){
            setEditvalue("allsections")
          }
        }
      })
      const systemBenefits = totalBenefits.filter((item)=>item.type==='SYSTEM') ;
      if (systemBenefits.length===1 ){
        if(systemBenefits[0].sections.length===0 &&systemBenefits[0].contents.length ==0 ){
          setEditvalue("noaccess") 
        }
      }
    }
    
    const getFeed = async () => {
      try {
      if (sections.length > 0) {
        setLoading(true)
        const data = await Promise.all([dispatch(fetchFeed()), dispatch(getLiveStreamPost())]);
        const sectionResult = []
        // check
        
        totalBenefits && totalBenefits[0] && setBenefitId(totalBenefits[0].id)
        totalBenefits[0].sections && totalBenefits[0].sections.forEach((item)=>{
          const result =  sections.find((ele)=>ele.id==item.id)
          if(result){
            sectionResult.push(result)
          }
        })
        const contentsResult = []
        totalBenefits[0].contents && totalBenefits[0].contents.forEach((item)=>{
          const result =  [...data[1].result,...data[0].result].find((ele)=>ele.articleId==item.id)
          if(result){
            contentsResult.push(result)
          }
        })
        setLoading(false)
        settempTotalBenefit(contentsResult.concat(sectionResult))
        setTotalBenefits(contentsResult.concat(sectionResult))
        // setFinalBenefits([...sections, ...data[1].result, ...data[0].result]);
      }
    }
    catch(err){
      setLoading(false)
      console.log("Err",err)
      }
    }
   isEdit && getFeed();
  },[]);

  const saveEditBenefit = async()=>{
    try {
     if(value ==="allsections"){
      setUpdating(true)
      if(benefitId){
        await dispatch(updateBenefit(benefitId,{
          "type": "SYSTEM",
          "sections": ['all'],
          "contents": [],
      },
      ))
      }
      else{
        const data = await dispatch(createandattachBenefit({
        sections: ['all'],
        contents: [],
        type: 'SYSTEM',
        product_id: productId,
        }))
        setBenefitId(data.id)
      }
      
      setEditvalue(value)
       setUpdating(false)
        }
      if(value==="noaccess"){
        setUpdating(true)
        if(benefitId){
        await dispatch(updateBenefit(benefitId,{
              "type": "SYSTEM",
              "sections": [],
              "contents": [],
          },
      ))
        }
        else{
        const data =   await dispatch(createandattachBenefit({
          sections: [],
          contents: [],
          type: 'SYSTEM',
          product_id: productId,
          }))
          setBenefitId(data.id)
        }
      
      setUpdating(false)
      setEditvalue(value)
      }
      if(value==="advanced"){
        setUpdating(true)
        if(benefitId){
        await dispatch(updateBenefit(benefitId,{
          "type": "SYSTEM",
          "sections": totalBenefits.map((item) => {
             return item.id
          }).filter((ele)=>ele!= null),
          "contents": totalBenefits.map((item) => {
             return item.articleId
          }).filter((ele)=>ele!= null),
        },
        ))
      }
      else{
       const data =  await dispatch(createandattachBenefit({
          "sections": totalBenefits.map((item) => {
            if(item.type==="section") return item.id
          }).filter((ele)=>ele!= null),
          "contents": totalBenefits.map((item) => {
            if(item.type==="content") return item.articleId
          }).filter((ele)=>ele!= null),
        type: 'SYSTEM',
        product_id: productId,
        }))
        setBenefitId(data.id)
      }
      settempTotalBenefit(totalBenefits)
      setEditvalue(value)
      setUpdating(false)
    }
      setEditBenefit(false);
    }
    catch(err){
      console.log("err",err)
      setUpdating(false)
    }
       
  }
  const handleClose = () => {
    setSidePan(false);
  }
  const getIcon = (headerType) => {
    if (headerType && headerType.includes('audio')) {
      return <MusicNoteIcon color="secondary" fontSize="small" />
    }
    if (headerType && headerType.includes('video')) {
      return <VideocamIcon color="secondary" fontSize="small" />
    }
    if (headerType && headerType.includes('image')) {
      return <ImageIcon color="secondary" fontSize="small" />
    }
    if (headerType && headerType.includes('text')) {
      return <InsertDriveFileIcon color="secondary" fontSize="small" />
    }
    if (headerType && headerType.includes('liveStream')) {
      return <LiveTvIcon color="secondary" fontSize="small" />
    }
  }
  const removeSelectedPost = (row) => {
    const tempTotalBenefits = totalBenefits;
    const returenedBenefit = tempTotalBenefits.filter((item) => item.articleId !== row.articleId)
    setTotalBenefits(returenedBenefit)
  }
  const removeSelectedSection = (row) => {
    const tempTotalBenefits = totalBenefits;
    const returenedBenefit = tempTotalBenefits.filter((item) => item.id !== row.id)
    setTotalBenefits(returenedBenefit)
  }
  return (
    <>
    <Grid container>
      { !isEditBenefit && isEdit && 
      <Grid item md={12}  xs={12}  sm={12}>
            <Box component="span" fontWeight="bold">Access based benefits</Box>
           {
             editValue==="noaccess" && <Button type="radio" rightText="No access" checked={true} />
           }
           {
             editValue==="allsections" && <Button type="radio" rightText="Full website access" checked={true} />
           }
           { 
           isLoading && editValue==="advanced"?
            <Box>
              <CircularLoader size={20} height="auto"/>
            </Box>
           :
           editValue==="advanced" && tempTotalBenefit.length > 0 &&
            <Box>
              <TableContainer component={Paper}>
                <Table aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell >ACCESS TO</TableCell>
                      <TableCell >TYPE</TableCell>
                      <TableCell >

                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {
                      tempTotalBenefit.map(row => (
                        <TableRow key={row.name}>
                          <TableCell component="th" scope="row">
                            {row.name || row.title}
                          </TableCell>
                          <TableCell >
                            {row.name ? "Section" : <Box display="flex" alignItems="center"> {getIcon(row.headerType)} Post</Box>}
                          </TableCell>
                          <TableCell >
                            {  row.title &&
                                <Box ml={3} mr={3} display="flex" alignItems="center" justifyContent="center">
                                  <Box display="flex" alignItems="center">
                                    {row.paidContent ? <LockIcon fontSize="small" /> : <LockOpenIcon fontSize="small" />}
                                  </Box>
                                </Box>  
                            }
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          }
          <Box mt={3} mb={3} display="flex" justifyContent="flex-end">
             <Button variant="contained" color="secondary" onClick={()=>{
               settempTotalBenefit(totalBenefits);setValue(editValue);setEditBenefit(true)}}>Edit</Button>
          </Box>
      </Grid>
        }
    </Grid>
    { isEditBenefit &&
      <Box>
        <Box mt={3}>
          <Box>
            <FormControl component="fieldset" disabled={isUpdating}>
              <FormLabel component="legend" style={{fontWeight:'bold',color:"black"}}>Access based benefits</FormLabel>
              <RadioGroup aria-label="acess type" name="gender1" value={value} onChange={handleChange} >
                <FormControlLabel value="noaccess" control={<Radio />} label="No access" />
                <FormControlLabel value="allsections" control={<Radio />} label="Full website access" />
                <FormControlLabel value="advanced" control={<Radio />} label="Advanced" />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        { value ==="advanced" && 
        <Box mt={1}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            Your subscribers will have access to
          <Button variant="contained" color="secondary" onClick={() => setSidePan(true)}>ADD ACCESS</Button>
          </Box>
          { 
           isLoading ?
            <Box>
              <CircularLoader size={20} height="auto"/>
            </Box>
           :
           <>
           {
            totalBenefits.length > 0 &&
            <Box>
              <TableContainer component={Paper}>
                <Table aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell >ACCESS TO</TableCell>
                      <TableCell >TYPE</TableCell>
                      <TableCell >

                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {
                      totalBenefits.map(row => (
                        <TableRow key={row.name}>
                          <TableCell component="th" scope="row">
                            {row.name || row.title}
                          </TableCell>
                          <TableCell >
                            {row.name ? "Section" : <Box display="flex" alignItems="center"> {getIcon(row.headerType)} Post</Box>}
                          </TableCell>
                          <TableCell >
                            {
                              row.title ?
                                <Box ml={3} mr={3} display="flex" alignItems="center" justifyContent="center">
                                  <Box display="flex" alignItems="center">
                                    {row.paidContent ? <LockIcon fontSize="small" /> : <LockOpenIcon fontSize="small" />}
                                  </Box>
                                  {<CloseIcon onClick={() => removeSelectedPost(row)} />}
                                </Box>

                                :<Box display="flex"
                                 alignItems="center" justifyContent="center"><CloseIcon onClick={() => removeSelectedSection(row)} /></Box> 
                            }
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            }
            
            </>
          }
        </Box>
        }
        { isEdit && 
        <Box display="flex" justifyContent="flex-end" mt={2}>
              <Button variant="contained" color="secondary" onClick={()=>setEditBenefit(false)}>cancel</Button>
              <Button variant="contained" disabled={isUpdating} color="primary" boxProps={{ml:2}} onClick={saveEditBenefit}>save</Button>
        </Box>
        }
      </Box>
      }
      
      <AddBenefit open={openSidePan} 
      isEdit={isEdit} 
      benefitId={benefitId}
      totalBenefits = {totalBenefits}
      handleClose={handleClose} setTotalBenefits={setTotalBenefits} />
     
    </>
  )
}
export default BenefitContainer;
