import React from "react";
import LandingHome from "components/landing/LandingHome";
/**
 * component to load when the user first come to the system.
 * will decide where to send the user.
 */
function LandingPage () {
    return(
        <LandingHome/>
    )
}

export default LandingPage;