import React, { useState, useEffect} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../../commons/muiCustomComponents/Button';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Menu from "../../../commons/muiCustomComponents/Menu";
import MenuItem from "../../../commons/muiCustomComponents/MenuItem";
import { useHistory } from "react-router-dom";
import { FixedSizeList as List } from 'react-window';
import AddCircle from "@material-ui/icons/AddCircleOutline";
import { Box } from "@material-ui/core";
import moment from 'moment';
import { fetchCategories, fetchUserProfile } from "../../landing/landing-action";
import {getInsightsRevenueActionForHeader} from "../../insights/insights-action";
import { useDispatch, useSelector } from "react-redux";
import { LogglyTracker } from 'loggly-jslogger';

const logger = new LogglyTracker();
logger.push({ 'logglyKey': '142d21f5-10d3-47a9-aa5a-f83e0a3abed7', 'sendConsoleErrors' : true, 'tag' : 'ardbeg,header,domain-list' });

const useStyles = makeStyles(theme => ({
  DomainSelectButton: {
    position: 'relative',
    background: theme.palette.pubWhite,
    color: theme.palette.pubBlack,
    "&:hover,&:focus": {
      background: 'white !important',
      color: theme.palette.pubBlack,
      boxShadow:'none',
    },
    // minWidth:theme.spacing(280* 1/4),
    // border: 'solid 1px rgba(0, 0, 0, 0.25)',
    // borderRadius: theme.spacing(0.25 * 35),
    // padding:theme.spacing(0.25 * 13),
    boxShadow:'none',
    textAlign: 'center',
    
    // width: theme.spacing(0.25 * 200),
    width:'100%',
   
  },
  DomainSelectSpan: {
    textTransform: 'initial',
    overflow: 'hidden',
    whiteSpace: "nowrap",
    marginRight: theme.spacing(0.25 * 38),
    marginLeft: theme.spacing(0.25 * 10),
    textOverflow: 'ellipsis',
  },
  domainListItem: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  DomainSelectArrow: {
    position: 'absolute',
    right: theme.spacing(0.25 * 20),
    margin: 'auto 0',
    paddingTop: theme.spacing(0.25 * 7),
  },
}));

const getFormattedDate = (date) => {
  return moment(date).format('YYYY-MM-DD');
}

function DomainList(props) {
  const classes = useStyles();
  const domainsList = mapDomainsToArray(props.domains);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeDomainId, setActiveDomainId] = useState(null);
  const [activeDomainName, setActiveDomainName] = useState('Loading Domains...');
  const history = useHistory();
  const dispatch = useDispatch();
  const {landing: {userProfile}} = useSelector(state => state);
  
  
  useEffect(() => {
    try {
    if (props.fqdn && props.activeDomain && domainsList.length){
      setActiveDomainName(props.fqdn);
    }
    if(props.activeDomain && domainsList.length) {
      if(!activeDomainId) {
        setActiveDomainId(props.domains[props.activeDomain][0].domainId);
        setActiveDomainName(props.domains[props.activeDomain][0].fqdn);
      }
    }
  }
  catch(err){
    console.log("err",err)
  }
  },[props,props.fqdn ||'']);

  const handleDomainSelect = async (item) => {
    console.log("doamin change called");
    setActiveDomainName(item.fqdn);
    setActiveDomainId(item.domainId);
    setAnchorEl(null);
    try {
      let res = await props.updateCurrentDomain(item.domainId);
      sessionStorage.setItem('active_domain_id', item.domainId);
      localStorage.setItem('active_domain_id', item.domainId);
      if(!res.isQuickCms) {
        history.push('/dash/old-dashboard');
        return;
      }
      history.push('/dash/create');
      dispatch(fetchUserProfile(item.domainId));
      dispatch(fetchCategories());
      
      if(res.userPermissions.includes("EARN:READ_INSIGHTS_REVENUE") && res.featurePermissions.includes("STATISTICS")){
        dispatch(getInsightsRevenueActionForHeader(getFormattedDate(moment(new Date()).subtract(7, 'days').toDate()), 
        getFormattedDate(new Date())));
      }
    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
    }
    
  };

  const goToAddDomain = () => {
    history.push("/onboard");
  }
  const toggleDomainMenu = (event) => {
    if(domainsList.length)
      setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }
  function renderRow({ index, style }) {
    const item = domainsList[index];
    return (
      <MenuItem
        selected={item.domainId === activeDomainId} 
        style={style}
      >
        <Box display="flex" width="100%" justifyContent="space-between">
          <Box width="80%" className={classes.domainListItem} onClick={handleDomainSelect.bind(null,item)}>{item.fqdn}</Box>
          <Box onClick={() => {window.open(`https://${item.fqdn}`, '_blank');}}><ExitToAppIcon/></Box>
        </Box>
      </MenuItem>
    );
  }
  return(
    <>
      <Button variant="contained"  
        className={classes.DomainSelectButton}
        onClick={toggleDomainMenu}
      >
        <div className={classes.DomainSelectSpan}>
          {activeDomainName}
        </div>
        <div className={classes.DomainSelectArrow}>
          <ArrowDropDownIcon/>
        </div>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{texAlign:"center",whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"}}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <List
          height={300}
          width={400}
          itemCount={domainsList.length}
          itemSize={50}
        >
          {renderRow}
        </List>
        <MenuItem
        onClick={goToAddDomain}
        >
        <Button 
          startIcon={<AddCircle/>}
          color="primary" 
          variant="contained" 
          fullWidth>
          Add New Domain
        </Button>
      </MenuItem>
      </Menu>
    </>
  )
}

export default DomainList;

function mapDomainsToArray (domains) {
  const keys = Object.keys(domains);
  let domainArray = [];
  for(let i=0;i <keys.length; i++) {
    domainArray.push(domains[keys[i]][0]);
  }
  domainArray.sort(domainComparator);
  //Using single domain to test. Comment this line out if it's not already.
  //domainArray = domainArray.filter(item => (item && item.subDomain === 'suraj'));
  return domainArray;
}



function domainComparator(a, b) {
  // Use toUpperCase() to ignore character casing
  const domainA = a.fqdn.toUpperCase();
  const domainB = b.fqdn.toUpperCase();

  let comparison = 0;
  if (domainA > domainB) {
    comparison = 1;
  } else if (domainA < domainB) {
    comparison = -1;
  }
  return comparison;
}