import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Input from "commons/muiCustomComponents/InputBox";
import { makeStyles } from '@material-ui/core/styles';
import MobileProvider from "commons/context/MobileProvider";
import Header from "../../content/components/Header";
import Footer from "../../content/components/Footer";
import Grid from "commons/muiCustomComponents/Grid";
import Box from "commons/muiCustomComponents/Box";
import Button from "commons/muiCustomComponents/Button";
import {CircularLoader} from "commons/commonWrapper/Loaders";
import CloseIcon from '@material-ui/icons/Close';
import Cropper from 'react-easy-crop';

const useStyles = makeStyles(theme =>({
  addNewCat: {
    textAlign:"center",
    margin:'auto',
  },
  titleArea: {
    background:'#f5faff',
    color:'#007aff',
    fontSize:'24px',
    padding: theme.spacing(4, 0),
  },
  subscriptionBox: {
    width: theme.isMobile ? '100%': '20%',
  },
  mobileTitle:{
    justifyContent:'center',
    display:'flex',
  },
  subscriptionButton: {
    height:theme.spacing(10),
    marginTop:theme.spacing(4),
    marginBottom:theme.spacing(4),
  },
  nextButton: {
    padding: theme.spacing(4, 0),
    width:'200px',
    borderRadius:theme.spacing(8),
    margin:theme.spacing(2),
  },
  footerButtonMobile: {
    paddingTop:theme.spacing(2),
    paddingBottom:theme.spacing(2),
    borderRadius:theme.spacing(1),
  },
  stepSection : {
    display:'flex',
    justifyContent:'flex-end',
  },
  mobileFooterContainer: {
    padding:theme.spacing(4),
  },
  mobileFooterItem: {
    paddingLeft:theme.spacing(2),
    paddingRight:theme.spacing(2),
  },
  circularLoader: {
    marginTop: '-100px',
  },
  imageTitle: {
    zIndex: 2,
    color: 'white',
    marginLeft: theme.spacing(6),
    fontWeight: '600',
  },
  cropperContainer: {
    position: 'relative',
    width: '100%',
    height: '400px',
    marginBottom: theme.spacing(5),
  },
  cropperHeading: {
    textAlign: 'center',
    color: '#2b2d42',
    fontSize: '1.2em',
  },
  previewImage: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  previewBox: {
    width: '100%',
    height: '69px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  parentBox: {
    position: 'relative',
    margin: '50px auto 5%',
    textAlign: 'center',
  },
  blackBg: {
    position: 'absolute',
    zIndex: '99',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.pubBlack,
  },
  storyPreviewImage: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
  },
  storyOverlay: {
    position: 'absolute',
    zIndex: 100,
    top: theme.spacing(20),
    fontSize: theme.spacing(5),
    left: 0,
    width: '100%',
    padding: theme.spacing(9),
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    fontWeight: '500',
    color: theme.palette.pubWhite,
    boxSizing: 'border-box',
  },
  previewBorder: {
    height: theme.spacing(1),
    backgroundColor: theme.palette.pubveryLightPinkTwo,
    borderRadius: theme.spacing(2),
    marginBottom: theme.spacing(5),
  },
  backgroundFullScreen:{
    backgroundColor:theme.palette.pubBackground,
  },
}));

const StoryForm = (props) => {
  const classes = useStyles();
  const showDialog = props.step >= 1 ? true : false;
  const isMobile = React.useContext(MobileProvider);

  const [crop, setCrop] = useState({x: 0, y: 0});
  const [zoom, setZoom] = useState(2);
  const [aspect] = useState(16/9);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState({});
  const [heading, setHeading] = useState('');
  const [actionLink, setActionLink] = useState('');
  const [error] = useState('Title is required');

  const onCropChange = crop => {
    setCrop(crop);
  }
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }
  const onZoomChange = zoom => {
    setZoom(zoom);
  }
  const handleHeading = (event) => {
    setHeading(event.target.value);
  }
  const handleActionLink = (event) => {
    setActionLink(event.target.value);
  }
  const saveCoverImage = () => {
    props.addNewStory(croppedAreaPixels, heading, actionLink);
    setHeading('');
  }

  return (
    <Dialog fullScreen open={showDialog} onClose={props.closeDialog} classes={{paper:classes.backgroundFullScreen}}>
      <Grid container justify="center">
         {/**----------------------------Header Section---------------------------- */}
          <Header>
             <Grid container justify="center">
                {<Grid item xs={12} md={12}>
                    <Box display="flex" alignItems="center" justifyContent="space-between" ml={isMobile ?4:25} mr={isMobile ?4:25} >
                      <Box display="flex" alignItems="center" height="100%">
                          <CloseIcon style={{color:'white'}} onClick={props.closeDialog} />
                      </Box>
                      <Box component="p" display="flex" justifyContent="center"> {props.step ===4 ? 'Story Preview' : 'Additional Information'}
                      </Box>
                      <Box  component="p" >
                         {props.step!==1 && <Box component="p">Step {parseInt(props.step)-1} of 3 </Box>}
                      </Box>
                      </Box>
                  </Grid>
                }
              </Grid>
          </Header>
          {/**----------------------------End Header Section---------------------------- */}
          {/**---------------------------Main Container Section--------------------------*/}
      <DialogContent
        id="modal-slide-description"
      >
        <Grid container justify="center">
        <Grid item xs={12} sm={6}>
        {props.step === 1 &&
          <Box className={classes.circularLoader}>
            { <CircularLoader /> }
          </Box>
        }
        {props.step === 2 &&
          <>
            <Box className={classes.parentBox}>
              <Box>
                <Box className={classes.previewBox}>
                  <img src={props.image} className={classes.previewImage} alt="preview" />
                  {/* <Box className={classes.imageTitle}>{props.imageTitle}</Box> */}
                </Box>
                <Input
                  heading="Title"
                  placeholder="Title of this story."
                  id="story_title"
                  value={heading}
                  fullWidth
                  inputProps={{
                    onChange: handleHeading,
                  }}
                  headingStyle={{textAlign: 'left'}}
                  helperText={error}
                />
                </Box>
                <Box mb={20} >
                <Input
                  heading="Action link"
                  placeholder="Paste link to a page here."
                  id="action_link"
                  value={actionLink}
                  fullWidth
                  inputProps={{
                    onChange: handleActionLink,
                  }}
                  headingStyle={{textAlign: 'left'}}
                />
              </Box>
            </Box>
          </>
        }
        {props.step === 3 &&
          <>
            <h3 className={classes.cropperHeading}>CROP THUMBNAIL</h3>
            <Box className={classes.cropperContainer}>

              <Cropper
                image={props.image}
                crop={crop}
                zoom={zoom}
                aspect={aspect}
                onCropChange={onCropChange}
                onCropComplete={onCropComplete}
                onZoomChange={onZoomChange}
                cropShape='round'
                cropSize={{ width: 300, height: 300 }}
              />
            </Box>
          </>
        }
        {props.step === 4 &&
          <>
            <Box className={classes.blackBg} >
              <img src={props.image} className={classes.storyPreviewImage} alt="preview" />
            </Box>
            <Box className={classes.storyOverlay}>
              <Box className={classes.previewBorder}>
              </Box>
              {heading}
            </Box>
          </>
        }
        </Grid>
        </Grid>
      </DialogContent>
      {/**----------------------------Footer Section-------------------------------------*/}
      <Grid item xs={12} md={12}>
        <Footer>
              {!isMobile && <Grid container justify="center">
                <Grid item md={8} xs={12}>
                  <Box display="flex" >
                  <Button 
                  color="secondary"
                  disabled={false} 
                  onClick={()=>props.setStep(props.step===2?0:props.step-1)}
                  >Back</Button>
                  <Button 
                  variant="contained" 
                  boxProps={{ml:4}}
                  disabled={(props.step ===1) || (props.step === 2 ? !heading.trim().length : false)}
                  onClick={props.step === 4 ? saveCoverImage : ()=>props.setStep(props.step+1)}
                  >{props.step === 4 ?'Publish':'Next'}</Button>
                  </Box>
                </Grid>
              </Grid>}
              {isMobile && <Grid container justify="center" className={classes.mobileFooterContainer}>
                <Grid item xs={6} sm={6} className={classes.mobileFooterItem}>
                  <Button
                  fullWidth
                  color="secondary"
                  disabled={props.savingMeta} 
                  onClick={()=>props.setStep(props.step===2?0:props.step-1)}
                  >Back</Button>
                </Grid>
                <Grid item xs={6} sm={6} className={classes.mobileFooterItem}>
                  <Button 
                  fullWidth
                  variant="contained" 
                  onClick={props.step === 4 ? saveCoverImage : ()=>props.setStep(props.step+1)}
                  disabled={props.step === 2 ? !heading.trim().length : false}  
                  >{props.step === 4 ?'Publish':'Next'}</Button>
                </Grid>
              </Grid>
              }
              
            </Footer>
      </Grid>
      {/**----------------------------End Footer Section-------------------------------------*/}
      </Grid>
    </Dialog>
  )
}

export default StoryForm;
