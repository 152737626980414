import React,{useContext} from "react";
import MobileProvider from "commons/context/MobileProvider";
import MobileWrapper from "../commons/commonWrapper/MobileWrapper";
import { useSelector } from "react-redux";
import Box from "commons/muiCustomComponents/Box";
import { CircularLoader} from "commons/commonWrapper/Loaders";


function OldDashboard(props) {
  const isMobile = useContext(MobileProvider);
  const { landing:{ activeDomain }, header:{domainList}} = useSelector(state => state);
  const data = activeDomain && domainList[activeDomain];
  const dd = data && data[0];
  return (<>{isMobile ?
    <MobileWrapper isCreateDisable={true}>
    {dd ?<Box display="flex" justifyContent="center" alignItems="center" height="80vh" > <h2 style={{textAlign:"center"}}>
      Please <a href="https://app.pubninja.com">click here&nbsp;</a> to use {dd.fqdn}, change your domain from above drop-down</h2></Box> :
       <CircularLoader message="loading" />}
    </MobileWrapper> : <>
  {dd ? <Box display="flex" justifyContent="center" alignItems="center" height="80vh"> 
  <h2 style={{textAlign:"center"}}>Please <a href="https://app.pubninja.com">click here&nbsp;</a> 
  to use {dd.fqdn}, change your domain from above drop-down</h2></Box>:
       <CircularLoader message="loading" /> }
    </>
    }
  </>)
}

export default OldDashboard;