import setAppMessage from "../../app-component/app-component-action";
import { LogglyTracker } from 'loggly-jslogger';

const logger = new LogglyTracker();
logger.push({ 'logglyKey': '142d21f5-10d3-47a9-aa5a-f83e0a3abed7', 'sendConsoleErrors' : true, 'tag' : 'ardbeg,analytics,insights-action' });


// export function getSubscriptionCurreny () {
//   return async (dispatch, getState, apiHandler) => {
//     try {

//     } catch (err)
//   }
// }

export function getInsightsRevenueAction(startDate, endDate) {
  return async (dispatch, getState, apiHandler) => {
    const {activeDomain, userProfile} = getState().landing;
    try {
      const { api, apiRoutes } = apiHandler;
      const { data } = await api(apiRoutes.fetchInsightsRevenue(activeDomain, startDate, endDate));
      return Promise.resolve(data);
    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}));
      return Promise.reject(err);
    }      
  }
}

export function getInsightsViewsAction(startDate, endDate) {
  return async (dispatch, getState, apiHandler) => {
    const {activeDomain, userProfile} = getState().landing;
    try {
      const { api, apiRoutes } = apiHandler;
      const { data: {result} } = await api(apiRoutes.fetchInsightsViews(activeDomain,startDate, endDate));
      return Promise.resolve(result);
    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}));
      return Promise.reject(err);
    }      
  }
}

export function getInsightsRevenueActionForHeader(startDate, endDate) {
  return async (dispatch, getState, apiHandler) => {
    const {activeDomain, userProfile} = getState().landing;
    try {
      const { api, apiRoutes, actionTypes } = apiHandler;
      const { data } = await api(apiRoutes.fetchInsightsRevenue(activeDomain, startDate, endDate));
      dispatch(revenueType(actionTypes.INSIGHTSREVENUEFORHEADER.SUCCESS, data));
    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}));
      return Promise.reject(err);
    }      
  }
}

export function getSubcriptionTransactionAction(startDate, endDate, type='RECURRING', l20='') {
  return async (dispatch, getState, apiHandler) => {
    const {activeDomain, userProfile} = getState().landing;
    try {
      const { api, apiRoutes, actionTypes } = apiHandler;
      const { data } = await api(apiRoutes.fetchSubscriptionTransaction(activeDomain, startDate, endDate, type, l20));
      return Promise.resolve(data);
    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}));
      return Promise.reject(err);
    }
  }
}

export function getCurrenyFromSubscrptions() {
  return async (dispatch, getState, apiHandler) => {
    const {activeDomain, userProfile} = getState().landing;
    try {
      const {  api, apiRoutes } = apiHandler;
      const { data:{result} } = await api(apiRoutes.getSubscriptionDetail(activeDomain));
      dispatch(setCurrencyData(result));
      return Promise.resolve(result);
    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      return Promise.reject(err);
    }
  }
}

export function setInsightsData(data) {
  return {
    type:'SET_DEFAULT_INSIGHTS',
    data,
  }
}

export function setEarningsData(data, chartName) {
  return {
    type:'SET_EARNINGS_INSIGHTS',
    chartName,
    data,
  }
}

export function setTrafficData(data, chartName) {
  return {
    type: 'SET_TRAFFIC_INSIGHTS',
    data,
    chartName,
  }
}

export function setPageViews(data) {
  return {
    type:'SET_PAGE_VIEW',
    data,
  }
}

export function setCurrencyData(data) {
  return {
    type:'SET_CURRENCY_DATA',
    data,
  }
}

function revenueType(actionName, data){
  return{
    type: actionName,
    data: data,
  }
}

export function setSubscription(data) {
  return {
    type:'SET_SUBSCRIPTION_TRANSACTION',
    data,
  }
}
