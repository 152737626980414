import React,{useCallback} from 'react';
import FullScreenPage from "commons/commonWrapper/FullScreenPage"; 
import Box from "commons/muiCustomComponents/Box";
import AddUserHome from "../components/user-management/AddUserHome";

function UserListPage (props) {
  const barCallback = useCallback(() =>(<Box 
    display="flex"
    alignItems="center"
    >Add New User</Box>),[]);
  return(
    <FullScreenPage BarComponent={barCallback}>
    {(appBarHeight) => <AddUserHome appbarheight={appBarHeight} />}
  </FullScreenPage>
  );
}

export default UserListPage;