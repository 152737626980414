export default class WebSocketUtil {
  socket;
  eventHandlers;
  config;
  connecting = false;
  streamDetails = {};
  onConnectCallback;

  constructor(config,onConnectCallback, eventHandlers = {}, details={}) {
    this.eventHandlers = eventHandlers;
    this.config = config;
    this.streamDetails = details;
    this.onConnectCallback = onConnectCallback;
  }

  connect() {
    if (!this.isConnected() && !this.connecting) {
      this.removeAllListeners();
      this.connecting = true;
      this._connect();
      this._initSocketHandlers(this.eventHandlers, this.config);
    }
  }

  removeAllListeners() {
    try {
      if(this.socket) {
        this.socket.onopen = null;
        this.socket.onclose = null;
        this.socket.onerror = null;
        this.socket.onmessage = null;
        this.socket = null;
      }
    } catch (e) {
      console.error("removing listeners", e);
    }
  }

  disconnect() {
    if(this.socket) {
      console.log("disconnecting....")
      this.socket.close();
    }
  }


  sendData(data, config = {}) {
    if (!this.isConnected()) {
      if(config.retry) {
        setTimeout(() => this.sendData(data, config), 2000);
      }
      return
    } else {
      this.socket.send(JSON.stringify(data));
    }
  }
  isConnected() {
    return this.socket && this.socket.readyState == WebSocket.OPEN;
  }
  _connect() {
    this.socket = new WebSocket(this.config.url);
    this.connectionChecker = setTimeout(() => {
      if(!this.isConnected()) {
        try {
          this.socket.close();
        } catch(e) {
          console.error('socket could not be closed', e);
        }
        console.log('reconnecting.....');
        this.connect();
      }
    }, 8000);
  }
  _initSocketHandlers(eventHandlers, config) {
    this.socket.onopen = (e) => {
      console.log(`Socket connection established with ${config.url}`, e.target.readyState, this.socket.readyState);
      clearTimeout(this.connectionChecker);
      this.connecting = false;
      this.sendData({ "event": "onConnect", "type":"cast", "handler":"ardbeg", "payload": this.streamDetails});
      this.onConnectCallback();
    }
    //onClose event listener
    this.socket.onclose = (e) => {
      this.connecting = false;
      this._handleDisconnect()
    }
    // websocket onerror event listener
    this.socket.onerror = (err) => {
      console.error(
        "Socket encountered error: ",
        err.message,
        "Closing socket",
      );
      // this.socket.close();
      this.connecting = false;
      this._handleDisconnect();
    };
    //on message
    this.socket.onmessage = (e) => {
      const parsedData = JSON.parse(e.data)
      eventHandlers['onMessage'](parsedData);
    }
  }

  _handleDisconnect() {
    clearTimeout(this.connectionChecker);
    const handler = this.eventHandlers['disconnect'] || (() => {
      setTimeout(() => this.connect(), 3000)
    })
    this.removeAllListeners();
    handler();
  }

}
