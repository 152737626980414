import storyAction from "../../constants/actions/story";

export function saveMediaToPiscoAction(file) {
    return async (dispatch, getState, apiHandler) => {
      try {
        const { api, apiRoutes} = apiHandler;
        const { landing: { activeDomain  } } = getState();
        if (!file) {
          return Promise.reject(new Error('Invalid Media File'));
        }

        let name = file.name.replace(/[^a-zA-Z.0-9]/g, '');
        let ext = file.type.split("/")[1];
        if(name === ".png" || name === ".png ") name = `untitled_${Math.ceil(Math.random() * 100000000)}.${ext}`;
        file = new File([file], name, { type: file.type });
        const preSaveFormData = new FormData();
        preSaveFormData.append("file", file, file.name);
        let preSaveResponse = await api(apiRoutes.piscoPreSaveApi(preSaveFormData, activeDomain));
        const localUploadData = getlocalUploadData(file);
        let localUploadResponse = await api(apiRoutes.localUploadApi(localUploadData, activeDomain));

        return Promise.resolve({ preSaveResponse, localUploadResponse });
      }
      catch (error) {
        return Promise.reject(error);
      }
    }
  }


function getlocalUploadData(file) {
    let mediaType = 'Image';
    if (file.type.startsWith('video')) mediaType = 'Video';
    if (file.type.startsWith('audio')) mediaType = 'Audio';
    const formData = new FormData();
    formData.append("file", new Blob());
    formData.append('filename', file.name);
    formData.append('title', file.name);
    formData.append('tags', ['quick-cms'])
    formData.append('imageSource', 'LOCAL');
    formData.append('type', mediaType);
    formData.append('dimensions', {});
    formData.append('caption', file.name);
    return formData;
}

export function addNewStoryAction(content, croppedData, heading, linkAction, cb) {
  return async (dispatch, getState, apiHandler) =>  {
    try {
      const { api, apiRoutes} = apiHandler;
      const { landing: { activeDomain  } } = getState();
      await api(apiRoutes.addNewStory(content.data, croppedData, heading, linkAction, activeDomain));
      cb();
      const stories = await api(apiRoutes.fetchStories(activeDomain));
      dispatch({
        type: storyAction.FETCH.SUCCESS,
        storyFeed: stories.data.result.stories,
      });
    }
    catch (err) {
      return Promise.reject(err);
    }
  }
}

export function fetchStoriesAction(param) {
  return async (dispatch, getState, apiHandler) => {
    try {
      const { api, apiRoutes} = apiHandler;
      const { landing: { activeDomain  } } = getState();
      const stories = await api(apiRoutes.fetchStories(activeDomain));
      dispatch({
        type: storyAction.FETCH.SUCCESS,
        storyFeed: stories.data.result.stories,
      })
    }
    catch(err) {
      return Promise.reject(err);
    }
  }
}

export function deleteStoryAction(storyId, cb) {
  return async (dispatch, getState, apiHandler) => {
    const { api, apiRoutes} = apiHandler;
    const { landing: { activeDomain  } } = getState();
    await api(apiRoutes.deleteStory(storyId, activeDomain));
    cb();
    const stories = await api(apiRoutes.fetchStories(activeDomain));
    dispatch({
      type: storyAction.FETCH.SUCCESS,
      storyFeed: stories.data.result.stories,
    });
  }
}

export function setStoryWidthAction (width){
  return async (dispatch)=>{
    dispatch({
      type: storyAction.WIDTH.SET,
      width: width,
    })
  }
}