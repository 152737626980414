import React, { useEffect, useState, 
  useContext ,
  useRef,
} from 'react';
import Box from "commons/muiCustomComponents/Box";
import Paper from "commons/muiCustomComponents/Paper";
import { makeStyles } from '@material-ui/core/styles';
import Button from "commons/muiCustomComponents/Button";
import Grid from "commons/muiCustomComponents/Grid";
import { useHistory } from "react-router-dom";
import {
  createNewProduct,
  showMessage,
  saveMediaToPisco,
} from "./monetization-action";
import { useDispatch } from "react-redux";
import InputAdornment from '@material-ui/core/InputAdornment';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import { CircularLoader} from 'commons/commonWrapper/Loaders';
import MobileProvider from "commons/context/MobileProvider";
import setAppMessage from 'app-component/app-component-action';
import InputBox from "commons/muiCustomComponents/InputBox";
import DraftjsEditor from "components/content/components/DraftjsEditor";
import MediaUploadContinaer from "./components/MediaUpload";
import PlanContainer from "./components/AddPlan";
import BenefitContainer from "./components/BenefitContainer";
import CustomBenefitContainer from "./components/CustomBenefitContainer";
import Header from "../content/components/Header";
import CloseIcon from '@material-ui/icons/Close';
import { hideIntercom } from 'utils/utils';


//  Add prodcut

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.pubWhite,
  },
  AutoCompleteRoot: {
    border: '1px solid #585858',
  },
  uppercontainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },
  textdecoration: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  breadCrumbsStyle: {
    backgroundColor: theme.palette.primary.main,
    height: theme.spacing(11),
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(4),
    color: theme.palette.pubWhite,
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[1],
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  textBreak: {
    wordBreak: 'break-word',
  },
  contentSectionList: {
    paddingLeft: theme.spacing(6),
  },
  shippingSection: {
    marginTop: theme.spacing(4),
  },
  offerSection: {
    width: '100%',
  },
  radioButtonGroup: {
    margin: theme.spacing(4),
  },
  doneButtonSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  offerBoxSection: {
    padding: theme.spacing(3),
  },
  offerBox: {
    marginTop: theme.spacing(5),
  },
  shippingText: {
    margin: 'auto',
    paddingLeft: theme.spacing(1),
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
}));

function AddProduct() {
  const classes = useStyles();
  const planRef = useRef(null);
  const imageRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useContext(MobileProvider);
  const [wordCount, setWordCount] = useState(0);
  const [content, setContent] = useState({});
  const [productName, setProductName] = useState('');
  const [productNameError, setProductNameErr] = useState(false);
  const [lastUpdate, setLastUpdate] = useState((new Date()).getTime());
  const [productDescripition, setProductDescripition] = useState('');
  const [productSummary ,setProductSummary]= useState('');
  const [isLoadingProduct, setLoadingProduct] = useState(false);

  const [isShippingAddress ,setShippingAddress] = useState(false);
  const [totalBenefits , setTotalBenefits] = useState([]);
  const [openDialog ,setOpenDialog] = useState(false);
  const BenefitContainerRef = useRef(null);
  const customBenefitRef = useRef(null);

  const handleMediaUpload = async (file, cb) => {
    const format = file.type.split('/')[0];
    if (format !== "image") return alert("Invalid File Format.");
    try {
      let res = await dispatch(saveMediaToPisco(file));
      const { localUploadResponse: { cdn } } = res;
      cb(null, cdn);
    } catch (err) {
      const { message } = err;
      dispatch(setAppMessage({ message: message || 'Unable to uploa media.', header: 'Error', type: 'alert' }));
      cb(err);
    }
  }

  useEffect(() => {
    return hideIntercom()
  }, []);

  useEffect(()=>{
    setOpenDialog(true)
    return ()=>{
      setOpenDialog(false)
    }
  },[])

  // update the article content at backend
  const updateHtmlContent = async (html, blockMap, count) => {
    try {
      setContent({ html, blockMap });
      // await dispatch(updateArticleMeta(articleId, { "richDescription": { html: html, objectMap: JSON.stringify(blockMap) } }));
      // metadata.richDescription = { html: html, objectMap:JSON.stringify(blockMap) };
      // await dispatch(updateArticleContent(articleId, html, blockMap)); 
    } catch (err) {
      // handle error
      dispatch(setAppMessage({ message: `Unable to update descripition.`, header: 'Error', type: 'alert' }));
    }
  }
  const createProduct = async ()=>{
    if (!productName) return setProductNameErr(true);
    if(!(imageRef.current && imageRef.current.video ) && !(imageRef.current && imageRef.current.image)) {
      dispatch(showMessage("Video/Image is required."))      
      return} 

    setProductNameErr(false);
    // if (!productDescripition) return setProductDescripitionError(true);
    // setProductDescripitionError(false);
    if (planRef.current && planRef.current.length === 0) {
      dispatch(showMessage("Atleast one plan is required to create product."))
      return;
    }

    if(imageRef.current.video){
      if(!(imageRef.current && imageRef.current.thumbnail)){
        dispatch(showMessage("Please select atleast one thumbnail."))
        return
      }
    }
    if(!content.html){
      dispatch(showMessage("Description is required."))
      return;
    }
    try {
      const benefits =  [
        {
            "type": "SYSTEM",
            "sections": totalBenefits.map((item) => {
              if(item.type==="section") return item.id
            }).filter((ele)=>ele!= null),
            "contents": totalBenefits.map((item) => {
              if(item.type==="content") return item.articleId
            }).filter((ele)=>ele!= null),
        },
        ...customBenefitRef&& customBenefitRef.current,
    ]
      const payload = {
        name: productName,
        description: content.html,
        description_object_map:JSON.stringify(content.blockMap),
        summary:productSummary,
        plans: planRef.current,
        benefits:benefits,
        image: (imageRef.current && imageRef.current.image) || (imageRef.current && imageRef.current.thumbnail)|| null,
        cover_video : (imageRef.current && imageRef.current.video) || null,
        shipping_address_required : +isShippingAddress,
      }
      if (!productDescripition) delete payload.descriptio;
      // if (!productImg) delete payload.image;
      setLoadingProduct(true)
      await dispatch(createNewProduct({ "product": payload }))
      history.push('/dash/products');
      setLoadingProduct(false);
      // goToSubscripition()
    }
    catch (err) {
      setLoadingProduct(false)
    }
  }

  const cancelProduct = ()=>{
    history.push('/dash/products');
  }
  return (
    <Dialog fullScreen open={openDialog}>
          {/**----------------------------Header Section---------------------------- */}
          <Header position="fixed" top={0}>
            <Grid container justify="center">
              {<Grid item xs={12} md={12}>
                <Box display="flex" alignItems="center" justifyContent="space-between" ml={isMobile ? 4 : 25} mr={isMobile ? 4 : 25} >
                  <Box display="flex" alignItems="center" height="100%">
                    <CloseIcon style={{ color: 'white' }} onClick={cancelProduct} />
                  </Box>
                  
                  <Box component="p" display="flex" justifyContent="center">PRODUCT</Box>
                  <Box component="p" ></Box>
                </Box>
              </Grid>
              }
            </Grid>
          </Header>
    <Grid container justify="center">
      <Grid item md={9} xl={10} xs={12} >
        {/* Product's title , summary , description and media's start */}
        <Box mt={20} mb={6}>
          <Paper>
            <Box mt={2}>
              <InputBox
                id="productName"
                heading="Title*"
                helperText={productNameError ? "Title can't be empty" : ''}
                onChange={(e) => { setProductName(e.currentTarget.value) }}
                error={productNameError}
                value={productName || ''}
                variant="standard"
                placeholder="Enter Title Here"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {`${(productName.length) || 0}`}
                    </InputAdornment>
                  ),
                }}
                headingStyle={{
                  component: "span",
                  fontWeight: "bold",
                  fontSize: 14,
                  mb: 1,
                }}
                className={{ root: classes.inputRoot }}
                fullWidth

              />

            </Box>
            <Box mt={6}>
              <InputBox
                id="productSummary"
                heading="Summary"
                helperText={productNameError ? "Summary can't be empty" : ''}
                onChange={(e) => { setProductSummary(e.currentTarget.value) }}
                error={productNameError}
                value={productSummary || ''}
                variant="standard"
                placeholder="Enter Summary Here"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {`${(productSummary.length)}`}
                    </InputAdornment>
                  ),
                }}
                headingStyle={{
                  component: "span",
                  fontWeight: "bold",
                  fontSize: 14,
                  mb: 1,
                }}
                className={{ root: classes.inputRoot }}
                fullWidth

              />

            </Box>
            <Box mt={5} >

              <DraftjsEditor
                objectMap={content.objectMap}
                htmlContent={content.html}
                updateWordCount={(count) => setWordCount(count)}
                lastUpdate={lastUpdate}
                isMobile={isMobile}
                wordCount={wordCount}
                bodytitle={'Description'}
                descriptionCompulsary = {true}
                DraftPlaceholder={"Write your description."}
                handleMediaUpload={handleMediaUpload}
                updateApi={updateHtmlContent} />
            </Box>
            
            <Box mt={6}>
              <MediaUploadContinaer ref={imageRef}/>
            </Box>
          </Paper>
        </Box>
        {/* Product's title , summary , description and media's end */}

        <Box mt={isMobile ? 2 : 6} mb={6}>
          <Paper>
              <PlanContainer ref={planRef}/>
          </Paper>
        </Box>
        <Box mt={isMobile ? 2 : 6} mb={6}>
          <Paper>
              <BenefitContainer ref={BenefitContainerRef} 
              setTotalBenefits= {setTotalBenefits} 
              totalBenefits ={totalBenefits}/>
          </Paper>
        </Box>
        <Box mt={isMobile ? 2 : 6} mb={6}>
          <Paper>
              <CustomBenefitContainer ref={customBenefitRef}/>
          </Paper>
        </Box>
        <Box mt={isMobile ? 2 : 6} mb={6}>
          <Paper>
              <Box component="span" color="primary" fontWeight="bold">Advanced Option</Box>
              <Button color="primary" type="checkbox"
               rightText="Enable shipping address" checked={isShippingAddress} onChange={(e)=>setShippingAddress(e.currentTarget.checked)}/>
          </Paper>
        </Box>
        
        <Box mt={isMobile ? 2 : 6} mb={6}>
            <Box>
              <Divider/>
              <Box display="flex" justifyContent="flex-end" mt={3}>
                  <Button variant="contained" color="secondary" onClick={cancelProduct}>cancel</Button>
                  
                  <Button variant="contained" color="primary" boxProps={{ml:2}} onClick={createProduct}>save</Button>
                  {isLoadingProduct  && <CircularLoader size={20} height="auto" />}
              </Box>
            </Box>
        </Box>
      </Grid>
    </Grid>
    </Dialog>
  )
}

export default AddProduct;
