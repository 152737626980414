import React, { useEffect, useState, useRef, useContext } from 'react';
import MobileWrapper from "../../commons/commonWrapper/MobileWrapper";
import Story from "../story/component/story";
import debounce from "lodash.debounce";
import Filters from "./components/filters";
import Grid from "commons/muiCustomComponents/Grid";
import Box from "commons/muiCustomComponents/Box";
import { useDispatch, useSelector } from "react-redux";
import { fetchFeed, deleteArticle, resetFeeds } from "./article-action";
import MobileProvider from "commons/context/MobileProvider";
import { makeStyles } from '@material-ui/core/styles';
import WithFileUpload from "commons/hocs/with-file-upload";
import { useHistory } from "react-router-dom";
import VideocamIcon from '@material-ui/icons/Videocam';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import ImageIcon from '@material-ui/icons/Image';
// import EditIcon from '@material-ui/icons/Edit';
import DescriptionIcon from '@material-ui/icons/Description';
import Button from "commons/muiCustomComponents/Button";
import { LinearLoader, PageBlockLoader, CircularLoader } from 'commons/commonWrapper/Loaders';
import setAppMessage from "app-component/app-component-action";
import ScrollTop from "../common/Scroll/Scroll";
import Toolbar from '@material-ui/core/Toolbar';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import RootRef from '@material-ui/core/RootRef';
import ArticleFilterBar from "./components/ArticleFilterBar";
import Paper from "commons/muiCustomComponents/Paper";
import clsx from "clsx";
import MediaCard from "./components/MediaCard";
import PostMenu from "./components/PostMenu";
import Menu from '@material-ui/core/Menu';
import MobileMediaCard from "./components/MobileMediaCard";
import { deleteSearchArticle } from "../common/common-action";
import { checkWebmasterVerified, showMessgage , getSubStatus,
  fetchDomainDetails,
} from "./article-action";
import MenuList from '@material-ui/core/MenuList';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {setDefaultTab} from "../monetization/monetization-action";
import LiveTvIcon from '@material-ui/icons/LiveTv';
import { LogglyTracker } from 'loggly-jslogger';

const useStyles = makeStyles(theme => ({
  articlesContainer: {
    backgroundColor: theme.isMobile ? theme.palette.pubBackground : 'inherit',
  },
  filterContainer: {
    paddingBottom: 0,
  },
  storyContainer: {
    backgroundColor: theme.palette.pubStoryBackground,
  },
  createContainer: {
    display: 'flex',
    // marginTop: theme.spacing(5),
    marginLeft: theme.spacing(0),
  },
  addButtonDesktop: {

    marginLeft: theme.spacing(2),
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.pubWhite,
    },
  },
  backGround: {
    background: theme.palette.pubBackground,
  },
  fixed: {
    // position: 'fixed',
    // width: '100%',
    zIndex: 999,
    paddingBottom: theme.spacing(3),
    // transform: 'scale(0.9)',
    // transformOrigin: 'left',
    marginTop: theme.spacing(5),
    // backgroundColor: theme.palette.pubWhite,
  },
  articlesList: {
    // marginTop: '260px',
  },
  articlesListNoCreate: {
    marginTop: theme.spacing(2),
  },

  topToBottom: {
    minHeight: theme.spacing(0),
  },
  articlesContainerWithItem: {
    minHeight: 'auto',
  },
  customCheckMark: {
    paddingTop: theme.spacing(4),
    backgroundColor: theme.palette.pubPrimary,
    borderRadius: theme.spacing(6 * 0.25),
  },
  link: {
    cursor: 'pointer',
  },
  childContainer: {
    position: 'fixed',
  },
  parentContainer: {
    position: 'relative',
  },
}));
const WithUploadButton = WithFileUpload(Button);

function Articles() {
  const isMobile = useContext(MobileProvider);
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [articleId, setArticleId] = useState(null);
  const [headerType, setHeaderType] = useState(null);
  const [status, setStatus] = useState(null);
  const [checkStatus, setcheckStatus] = useState(false);
  const [subDetails ,setSubDetails] = useState(null);
  const [isChecking ,setChecking] = useState(false);
  const [isLoadingSatus ,setLoadingStatus] = useState(false);
  const articleContainerRef = useRef();
  const menuRef = useRef();
  const domRef = React.useRef();
  const open = Boolean(anchorEl);
  const [appBarHeight, setHeight] = React.useState(0);
  const {
    landing: { activeDomain, userPermissions,featurePermissions, userProfile },
    article: { articleFeed, isDeletingArticle, isLoadingArticle, page },
    header: { isChangingDomain ,domainList},
  } = useSelector(state => state);

  const canCreate = !!((userPermissions.indexOf('CREATE:WRITE') > -1) || (userPermissions.indexOf('CREATE:PUBLISH') > -1));
  const [filteredArticles, setFilteredArticles] = useState(null);
  const [filtered, setFiltered] = useState(false);


  const fqdn = domainList && domainList[activeDomain] && domainList[activeDomain][0].fqdn;

  const logger = new LogglyTracker();
  logger.push({ 'logglyKey': '142d21f5-10d3-47a9-aa5a-f83e0a3abed7', 'sendConsoleErrors' : true, 'tag' : 'ardbeg,articles' });

  React.useEffect(() => {
    try {
    if (domRef.current !== null && domRef.current !== undefined ) {
      isMobile ?
        setHeight(domRef.current.getBoundingClientRect().height)
        : setHeight(domRef.current.getBoundingClientRect().height + 80)
    }
  }
  catch(err){
    // eslint-disable-next-line no-console
    const data = {
      'error': err,
      'email': (userProfile||{}).email || '',
      'url': (window.location||{}).href || '',
      'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
      'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
    };
    logger.push({
        'type': 'error',
        'data': data,
      });
    console.log(err)
  }

  }, []);

  function fetchAllArticle() {
    dispatch(resetFeeds());
    dispatch(fetchFeed('draft&live'));
  }


  async function handleDeleteArticle(isSearch = false) {
    try {
      await dispatch(deleteArticle(articleId, status));
      isSearch && dispatch(deleteSearchArticle(articleId))
      handleClose();
    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      dispatch(setAppMessage({ message: err.message || 'unable to delete article', header: 'Error', type: 'alert' }));
    }
  }

  async function handleDeleteMobileArticle(item) {
    const { articleId, status } = item;
    try {
      await dispatch(deleteArticle(articleId, status));
      handleClose();
    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      dispatch(setAppMessage({ message: err.message || 'unable to delete article', header: 'Error', type: 'alert' }));
    }
  }

  async function getStatus() {
    try {
      setLoadingStatus(true)
      const data = await dispatch(checkWebmasterVerified());
      setcheckStatus(data);
      setLoadingStatus(false)
    }
    catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      setLoadingStatus(false)
    }
  }

  async function getSubscriptionStatus (){
    try {
      setChecking(true);
      const data = await dispatch(getSubStatus());
      data['subLanding'] = false;
      if (data.productCount >0 ){
        const {tagline,oneline_description}= await dispatch(fetchDomainDetails());
        if (tagline && oneline_description ) {
          data['subLanding'] = true;
        }
      }
      setSubDetails(data);
      setChecking(false);
    }
    catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      setChecking(false);
    }
  }

  useEffect(() => {
    fetchAllArticle();
    userPermissions.indexOf('SETTING:WEBSITE_SETTINGS') > -1 && getStatus();
    userPermissions.indexOf('EARN:MANAGE_ADS') > -1 && getSubscriptionStatus();
    setFilteredArticles(articleFeed);
    return () => window.onscroll = () => { }
  }, [activeDomain]);

  useEffect(() => {
    window.onscroll = debounce(() => {
      if (document.documentElement.offsetHeight - window.innerHeight - document.documentElement.scrollTop < 200) {
        !isLoadingArticle && dispatch(fetchFeed('draft&live'));
      }
    }, 100);
    return () => window.onscroll = () => { }
  }, [isLoadingArticle, activeDomain])

  const applyFilters = (filters) => {
    const activeFilters = Object.keys(filters) && Object.keys(filters).filter(item => filters[item] === true);
    const filteredArticles = articleFeed.filter((item) => (
      item.headerType && activeFilters.includes(item.headerType.split("/")[0])
    ));
    setFiltered(true);
    setFilteredArticles(filteredArticles);
  }

  const openMembership = ()=>{
    subDetails && !!subDetails.stripeConnected &&
                       subDetails.productCount > 0 && subDetails.subLanding &&
    window.open(`https://${fqdn}/page/membership`, '_blank')
  }


  const createImageArticle = (target, type) => {
    history.push("/dash/create/image/new");
  }

  const createVideoArticle = (target, type) => {
    history.push("/dash/create/video/new");
  }


  async function handleSearchDeleteArticle() {
    try {
      await dispatch(deleteArticle(articleId, status));
      dispatch(deleteSearchArticle(articleId))

    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      dispatch(setAppMessage({ message: err.message || 'unable to delete article', header: 'Error', type: 'alert' }));
    }
  }
  async function handleSearchDeleteMobileArticle(item) {
    const { articleId, status } = item;
    try {
      await dispatch(deleteArticle(articleId, status));
      dispatch(deleteSearchArticle(articleId))

    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      dispatch(setAppMessage({ message: err.message || 'unable to delete article', header: 'Error', type: 'alert' }));
    }
  }

  const articles = filtered ? filteredArticles : articleFeed;

  const setAnchor = (e, article, header, state) => {

    setArticleId(article);
    setHeaderType(header);
    setStatus(state);
    setAnchorEl(e.currentTarget);

  }
  const handleClose = () => {
    setArticleId(null);
    setHeaderType(null);
    setStatus(null);
    setAnchorEl(null);
  }
  const openCustomDialog = () => {
    history.push('/dash/setting/website/owndomain')
  }
  const goToSeo = () => {
    checkStatus && checkStatus.isMapped &&
      history.push('/dash/setting/website/webmaster-domain-map')
  }

  const setUpSub = ()=>{
    dispatch(setDefaultTab(1))
    subDetails && !!subDetails.stripeConnected &&
   history.push('/dash/monetization/subscripition'); 
  }

  const setUpSubPayment = ()=>{
    history.push('/dash/monetization/subscripition'); 
  }

  const setUpSubPage = ()=>{
    subDetails && !!subDetails.stripeConnected ?
      dispatch(setDefaultTab(2))
      : dispatch(setDefaultTab(1))
    history.push('/dash/monetization/subscripition'); 
  }
  /**
   * Infinit scroll
   */
  //(isChangingDomain || articles === null || isDeletingArticle) &&
  return (
    isMobile ?
      <>
        <MobileWrapper>
          {(isChangingDomain || articles === null || isDeletingArticle) && <LinearLoader position="sticky" width="100%" top="70px" zIndex="99999" />}
          <Box bgcolor="pubBackground" width="100%" >
            <Box bgcolor="pubBackground" m={"auto"} ml={2} mr={2} pt={5} pb={4}>
              <ArticleFilterBar taskname="POSTS" handleSearchDeleteMobileArticle={handleSearchDeleteMobileArticle} />
            </Box>
          </Box>
          <Box >
            <Story />
          </Box>

          {/* <Filters applyFilters={applyFilters} /> */}
          <Toolbar id="back-to-top-anchor" classes={{ root: classes.topToBottom }} disableGutters />
          <Box bgcolor="pubBackground" width="100%" minHeight="100%">
            <Box className={classes.articlesContainer} m={"auto"} ml={2} mr={2} pb={15}>
              {articles && articles.map((item, key) => {
                return <Box key={item.articleId} mt={4}>
                  <Paper >
                    <MobileMediaCard setAnchorEl={setAnchor} item={item} handleDeleteMobileArticle={handleDeleteMobileArticle} />
                  </Paper>
                </Box>
              })}
              {isLoadingArticle && page > 0 && <CircularLoader height="auto" mt={3} mb={4} message="loading more articles..." />}

            </Box>
          </Box>
          <Box width="100%" height={35} className={classes.backGround}>
          </Box>
        </MobileWrapper>
        <ScrollTop >
          <Fab color="secondary" size="small" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>
      </>
      :
      <>
        <Grid container>
          <Grid item md={9} xl={10} >

            <Box mt={6}>
              <Paper >
                <Box>
                  {canCreate && <Box className={classes.createContainer}>
                    <Button color="primary" variant="contained" className={classes.addButtonDesktop} 
                    style={{ marginLeft: '0px' }} onClick={() => { history.push("/dash/create/article/new") }}>
                      <DescriptionIcon fontSize="small" />
                      <Box>Add Article</Box>
                    </Button>
                    <Button
                    color="primary"
                    variant="contained"
                      className={classes.addButtonDesktop}
                      type="video/*" onClick={createVideoArticle}>
                      <VideocamIcon fontSize="small" />
                      <Box>Add Video</Box>
                    </Button>
                    <Button
                    color="primary"
                    variant="contained"
                      className={classes.addButtonDesktop}
                      onClick={createImageArticle}>
                      <ImageIcon fontSize="small" />
                      <Box>Add Image</Box></Button>
                    <Button
                    color="primary"
                    variant="contained"
                      className={classes.addButtonDesktop}
                      onClick={() => { history.push("/dash/create/podcast/new") }}>
                      <AudiotrackIcon fontSize="small" />
                      <Box>Add Audio</Box>
                    </Button>
                  </Box>}
                </Box>
              </Paper>

            </Box>

            <Grid item sm={12}>
              {(isChangingDomain || articles === null || isDeletingArticle) && <PageBlockLoader />}
            </Grid>
            <Box mt={7}>
              <ArticleFilterBar taskname="POSTS" setAnchorEl={setAnchor} deleteSearchArticle={handleSearchDeleteArticle} />
            </Box>
            <Box mt={4}>
              <Story />
            </Box>

            <Toolbar id="back-to-top-anchor" classes={{ root: classes.topToBottom }} disableGutters />
            <Box mb={4}>

              <RootRef rootRef={articleContainerRef}>
                <Box className={clsx(classes.articlesContainer, { [classes.articlesContainerWithItem]: articles && articles.length > 0 })}>
                  {articles && articles.map((item, key) =>{
                    return <Box key={item.articleId}>
                      <Box mt={4}>
                        <Paper>
                          <MediaCard setAnchorEl={setAnchor} item={item} />
                        </Paper>
                      </Box>
                    </Box>
                  })}
                  {isLoadingArticle && page > 0 && <CircularLoader height="auto" mt={3} mb={3} message="loading more articles..." />}

                </Box>
              </RootRef>
            </Box>
          </Grid>
          <Grid item md={3} xl={2} className={classes.parentContainer}>
            { userPermissions.indexOf('SETTING:WEBSITE_SETTINGS') > -1 &&
            <Box m={4} mt={6} mr={12.5} width={isLoadingSatus ?'14%':'auto'} className={classes.childContainer}>
            <RootRef rootRef={domRef}>
              <Box className={classes.customCheckMark}>
                <Box pl={4}>
                  <Box component="span" textAlign="center">
                    Website Checklist
                </Box>
                </Box>
                  {isLoadingSatus ?
                  <Box bgcolor="white" mt={4} mb={4} width={'100%'}>
                    <Box>
                      <CircularLoader height="auto" pt={4} pb={4} size={20}/>
                    </Box>
                  </Box>
                  :
                 
                <Box bgcolor="white">
                  <Box component="span"
                    display="flex"
                    mt={3}
                    p={4}
                    pb={0}
                    color="primary.main"
                    alignItems="center"
                    className={classes.link}
                    onClick={openCustomDialog} fontSize={14}>
                    {checkStatus && checkStatus.isMapped ? <CheckBoxIcon fontSize="small" style={{ marginRight: '8px' }} /> :
                      <CheckBoxOutlineBlankIcon fontSize="small" style={{ marginRight: '8px' }} />
                    }
                      Connect a domain you own</Box>
                  <Box component="span"
                    display="flex"
                    mt={3}
                    p={4}
                    pt={0}
                    color={`${checkStatus.isMapped ? 'primary.main' : 'primary.dark'}`}
                    alignItems="center"
                    className={classes.link}
                    onClick={goToSeo} fontSize={14}>
                    {checkStatus && checkStatus.status === 3 ? <CheckBoxIcon fontSize="small" style={{ marginRight: '8px' }} /> :
                      <CheckBoxOutlineBlankIcon fontSize="small" style={{ marginRight: '8px' }} />
                    }
                      Enable SEO</Box>
                </Box>
                 }
              </Box>
              </RootRef>
            </Box>
            }
            { userPermissions.indexOf('EARN:MANAGE_ADS') > -1 && 
            <Box mt={4}>
           
            
              <Box m={4} mt={6} mr={12.5} className={classes.childContainer} top={appBarHeight + 20} >
                <Box className={classes.customCheckMark}>
                  <Box pl={4}>
                    <Box component="span" textAlign="center">
                      Subscription Checklist
                    </Box>
                  </Box>
                  {
                    isChecking ?
                    <Box bgcolor="white">
                      <Box mt={4} mb={4}>
                        <CircularLoader height="auto" pt={4} pb={4} size={20}/>
                      </Box>
                    </Box>
                    :  <Box bgcolor="white">
                    <Box component="span"
                      display="flex"
                      mt={3}
                      p={4}
                      pb={0}
                      color="primary.main"
                      alignItems="center"
                      className={classes.link}
                      onClick={()=>setUpSubPayment()} fontSize={14}>
                      {subDetails && !!subDetails.stripeConnected ? <CheckBoxIcon fontSize="small" style={{ marginRight: '8px' }} /> :
                        <CheckBoxOutlineBlankIcon fontSize="small" style={{ marginRight: '8px' }} />
                      }
                      Setup payments</Box>
                    <Box component="span"
                      display="flex"
                      mt={3}
                      p={4}
                      pt={0}
                      color={`${subDetails && !!subDetails.stripeConnected  ? 'primary.main' : 'primary.dark'}`}
                      alignItems="center"
                      className={classes.link}
                      onClick={()=>setUpSub()} fontSize={14}>
                      {subDetails && subDetails.productCount > 0 ?
                      <CheckBoxIcon fontSize="small" style={{ marginRight: '8px' }} /> :
                        <CheckBoxOutlineBlankIcon fontSize="small" style={{ marginRight: '8px' }} />
                      }
                       Add first product</Box>
                        <Box component="span"
                      display="flex"
                      mt={3}
                      p={4}
                      pt={0}
                      color={`${subDetails && subDetails.subLanding ? 'primary.main' : 'primary.dark'}`}
                      alignItems="center"
                      className={classes.link}
                      onClick={()=>openMembership()} fontSize={14}>
                      {subDetails && !!subDetails.stripeConnected && subDetails.productCount > 0?
                        <CheckBoxIcon fontSize="small" style={{ marginRight: '8px' }} /> :
                        <CheckBoxOutlineBlankIcon fontSize="small" style={{ marginRight: '8px' }} />
                      }
                        Preview and Go live</Box>
                        
                  </Box>
                  }

                </Box>

              </Box>
                        
            </Box>
            }
          </Grid>
        </Grid>
        <ScrollTop >
          <Fab color="secondary" size="small" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>
        <Menu id="postmenu" open={open} anchorEl={anchorEl} style={{ zIndex: '1500' }} keepMounted onClose={handleClose}>
          <MenuList autoFocus>
            <PostMenu articleId={articleId} status={status} handleDeleteArticle={handleDeleteArticle} headerType={headerType} />
          </MenuList>
        </Menu>
      </>
  );
}

export default Articles;
