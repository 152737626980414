
import React,{useCallback,useContext} from "react";
import Categories from "components/settings/Categories";
import FullScreenPage from '../commons/commonWrapper/FullScreenPage';
import Box from "../commons/muiCustomComponents/Box";
import MobileProvider from "commons/context/MobileProvider";


export default function CategoriesHome (){
    
    const isMobile = useContext(MobileProvider);

    const barCallback = useCallback(() =>(<Box 
        display="flex"
        alignItems="center"
        fontSize ={14}
        >Section</Box>),[]);
        
    return(
  
        <FullScreenPage BarComponent={barCallback} hideGoBack={true}>
        {(appBarHeight)=><Categories appBarHeight={appBarHeight}/>}
        </FullScreenPage>

    )
}
