import React from "react";
import { useSelector } from "react-redux";
import { Router, Switch, Route } from "react-router-dom";
import AppRoutes from "./routes/app-routes";
import PageNotFound from "pages/PageNotFound";
import DashboardPage from "./pages/DashboardPage";
import PrivateRoute from "./utils/privateRoutes";
import { createBrowserHistory } from "history";
import MessageDialog from "./app-component/Message/Message";
import ProgressLoader from "./app-component/ProgressBar/ProgressLoader";
// create the history context for router to handle back actions properly
const history = createBrowserHistory();

/**
 * The Main App layout for the app
 * @param {Object} props 
 */
function AppLayout(props) {

  const {
    landing: {
      isAllAuthComplete,
      featurePermissions,
      userPermissions,
    },
    app: {
      msg :{
        isOpen,
      },
    },
  } = useSelector(state => state);


  /**
   * function for registering the routes
   * @param {RouteObject} item 
   */
  const routingHandler = (item) => {
    const Component = item.component;
    if (item.isAuthRequired) {
      return (
        <PrivateRoute
          exact
          key={item.path}
          path={item.path}
          requiredUserPermissions={item.requiredUserPermissions}
          permissionsCheckType={item.permissionsCheckType}
          requiredFeaturePermission={item.requiredFeaturePermission}
          isAllAuthComplete={isAllAuthComplete}
          userPermissions={userPermissions}
          featurePermissions={featurePermissions}
        >
          <Component />
        </PrivateRoute>
      )
    }
    return (
      <Route exact key={item.path} path={item.path}>
        <Component />
      </Route>
    )
  }

  return (
    <>
      <Router history={history}>
        <Switch>
          <Route path="/dash"> <DashboardPage /></Route>
          {AppRoutes.map((item) => routingHandler(item))}
          <Route path="*"><PageNotFound /></Route>
        </Switch>
        <ProgressLoader/>
      </Router>
      {isOpen && <MessageDialog />}
      
    </>
  );
}

export default AppLayout;