
import React from 'react';
import MuiBox from '@material-ui/core/Box';

function  Box(props) {
    const {
        children,
        ...rest
    } = props;
return (<MuiBox {...rest}>{children}</MuiBox>)
}
export default Box;
