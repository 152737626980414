import React from "react";
import LoginHome from "components/auth/LoginHome";
import Hidden from "commons/muiCustomComponents/Hidden";
import Grid from "commons/muiCustomComponents/Grid";
import Box from "commons/muiCustomComponents/Box";
import { makeStyles } from '@material-ui/core/styles';
import Girl from 'assets/images/onboard_share.svg';

const useStyles = makeStyles(theme => ({
  formSection: {
    padding:theme.spacing(2),
    height:'80vh',
  },
  loginImage: {
    backgroundColor:theme.palette.primary.main,
    height:'100vh',
    display:'flex',
    justifyContent:'space-around',
    flexDirection:'column',
  },
  textSection:{
    fontSize:theme.spacing(11),
    color:'white',
    fontWeight:'700',
    textAlign:'center',
  },
  imageBox: {
    marginLeft:'20%',
    width:'80%',
    height:'20%',
  },
  girlImage: {
    width:'100%',
    height:'100%',
    objectFit:'cover',
  },
}));


function LoginPage (props) {
  const classes = useStyles();
  return (
    <>
    <Grid container>
    <Grid item xs={12} sm={12} md={12} lg={8} className={classes.formSection}>
      <LoginHome/>
    </Grid>
    <Hidden mdDown>
       <Grid item xs={false}  md={4} className={classes.loginImage}>
         <Box component="p" className={classes.textSection}>The Creator's<br/>Platform</Box>
         <Box className={classes.imageBox}><img src={Girl} className={classes.girlImage} alt="login"/></Box>
      </Grid>
    </Hidden>
    </Grid>
    </>
  )
}

export default LoginPage;
