

import React from 'react';
import MenuItem from "commons/muiCustomComponents/MenuItem";
import EditIcon from '@material-ui/icons/Edit';
import ShareIcon from '@material-ui/icons/Share';
import DeleteIcon from '@material-ui/icons/Delete';
import { useHistory } from "react-router-dom";
import { useSelector ,useDispatch} from "react-redux";
import setAppMessage from "app-component/app-component-action";


export default function PostMenu({headerType,articleId,handleDeleteArticle,status}) {
    const history = useHistory();
    const dispatch = useDispatch();

    const { landing:{
        userPermissions,
      }} = useSelector(state=>state);

    const goToEditPage = () => {
        if(headerType && headerType.indexOf('video') > -1) {
          history.push(`/dash/create/video/${articleId}`);  
        }
        if(headerType && headerType.indexOf('image') > -1) {
          history.push(`/dash/create/image/${articleId}`);
        }
        if(headerType && headerType.indexOf('text')> -1) {
          history.push(`/dash/create/article/${articleId}`);
        }
        if(headerType && headerType.indexOf('audio')> -1) {
          history.push(`/dash/create/podcast/${articleId}`);
        }
      }
    
      const getLable = () => {
        if(headerType && headerType.indexOf('video') > -1) {
          return "video post" 
        }
        if(headerType && headerType.indexOf('image') > -1) {
          return "image post" 
        }
        if(headerType && headerType.indexOf('text')> -1) {
          return "article" 
        }
        if(headerType && headerType.indexOf('audio')> -1) {
          return "audio post" 
        }
      }
      const deleteArticlePopup = ()=>{
        dispatch(setAppMessage({ message: '',
        leftButtonText:'Close', 
        rightButtonText:'Delete', 
        handleSuccess: ()=>handleDeleteArticle(true), header: `Are you sure you want to delete ${getLable()} ?` }));      
      }
  return (
      <>
        {
            ((userPermissions.indexOf('CREATE:WRITE') > -1) || (userPermissions.indexOf('CREATE:PUBLISH') > -1)) &&
            <MenuItem onClick={goToEditPage} ><EditIcon fontSize="small"  />&nbsp;Edit</MenuItem>
        }
        {/* {
        !!status && 
        ((userPermissions.indexOf('SHARE:NEWSLETTER') > -1) || (userPermissions.indexOf('SHARE:SOCIAL_SCHEDULE') > -1)) 
        && 
        // <MenuItem onClick={goToSharePage}><ShareIcon fontSize="small"  />&nbsp;Share</MenuItem>

        } */}
        <MenuItem onClick={deleteArticlePopup}><DeleteIcon fontSize="small"/>&nbsp;Delete</MenuItem>
      </>
  )
}
