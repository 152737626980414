import React from 'react'
import moment from 'moment';

export default ({ name, message, messageType, image, creation,storedDate, count }) =>
<div className="messageInputDiv">
  {
    (count === 0 || !moment(creation).isSame(storedDate, "day"))
    &&
    <div className="dateSeparator">
      <hr className="dateSeparatorLine"/>
      <div className="dateSeparatorDate">{dateToFromNowDaily(creation)}</div>
    </div>
  }
    {
      messageType === "TEXT" &&
      <p>
        <span className="inputNameLabel"><strong>{name}</strong></span>
        <span className="inputMessage">{message}</span>
      </p>
    }

    {
      messageType === "IMAGE" &&
      <div className="imageDiv">
      <span className="inputNameLabel"><strong>{name}</strong></span>
      <img src={image} alt="" className="inputImage"/>
      </div>
    }
</div>

function dateToFromNowDaily( myDate ) {
  // get from-now for this date
  var fromNow = moment( myDate ).fromNow();

  // ensure the date is displayed with today and yesterday
  var day = moment( myDate ).calendar( null, {
    // when the date is closer, specify custom values
    lastWeek: '[Last] dddd',
    lastDay:  '[Yesterday at] LT',
    sameDay:  '[Today at] LT',
    nextDay:  '[Tomorrow]',
    nextWeek: 'dddd',
    // when the date is further away, use from-now functionality             
    sameElse: function () {
        return "[" + fromNow + "]";
    },
});
  return day
}