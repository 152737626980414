import React from "react";
import { 
  Route, 
  Redirect,
} from "react-router-dom";

function PrivateRoute(props) {

  let hasFeaturePermission  = true;
  let hasUserPermission = true;
  const { 
    children,
    path,
    isAllAuthComplete,
    requiredUserPermissions,
    userPermissions,
    featurePermissions,
    permissionsCheckType = "OR",
    requiredFeaturePermission } = props;

  if(isAllAuthComplete) {
    hasFeaturePermission = checkFeaturePermisson(requiredFeaturePermission, featurePermissions);
    hasUserPermission = checkUserPermissons(requiredUserPermissions, userPermissions, permissionsCheckType);
  }
  // return a Route if the route is a valid route for the user.
  return (
    <Route
      exact
      path={path}
      render={({ match, location }) => (isAllAuthComplete && hasFeaturePermission && hasUserPermission) ? (children) : (
        <Redirect
          to={{
            pathname: "/",
            state: {from: location , userPermissions, requiredUserPermissions, permissionsCheckType},
           // state:{match, from: location},
          }}
        />
      )
      }
    />
  );
}

function checkFeaturePermisson(requiredFeaturePermission, featurePermissions) {
  if(requiredFeaturePermission && featurePermissions.indexOf(requiredFeaturePermission) < 0) {
    return false;
  }
  return true;
}

function checkUserPermissons(requiredUserPermissions, userPermissions, permissionsCheckType) {

  if(requiredUserPermissions && !requiredUserPermissions.length) {
    return true;
  }
  let hasPermission = false;
  if(permissionsCheckType === 'OR') {
    for(let i=0; i < userPermissions.length; i++) {
      if (requiredUserPermissions.indexOf(userPermissions[i]) >= 0) {
        hasPermission = true;
        break;
      }
    }
  }
  if(permissionsCheckType === 'AND') {
    hasPermission = true;
    for(let i=0; i < requiredUserPermissions.length; i++) {
      if (userPermissions.indexOf(requiredUserPermissions[i]) < 0) {
        hasPermission = false;
        break;
      }
    }
  }
  return hasPermission;
}

export default PrivateRoute;