import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import Mic from "@material-ui/icons/MicTwoTone";
import StopRounded from "@material-ui/icons/StopRounded";
import DeleteRounded from "@material-ui/icons/DeleteRounded";
import Check from "@material-ui/icons/Check";
import Dialog from "../../../commons/muiCustomComponents/Dailog";
import Box from "../../../commons/muiCustomComponents/Box";
import Typography from "commons/muiCustomComponents/Typography";
import { makeStyles } from '@material-ui/core/styles';
import { LogglyTracker } from 'loggly-jslogger';

const logger = new LogglyTracker();
logger.push({ 'logglyKey': '142d21f5-10d3-47a9-aa5a-f83e0a3abed7', 'sendConsoleErrors' : true, 'tag' : 'ardbeg,content,audio-recorder' });

const AudioContext = window.AudioContext || window.webkitAudioContext;
const Recorder = window.Recorder;
let timerId;

const useStyles = makeStyles(theme =>({
  dialogContainer: {
    background:'black',
    display:'flex',
    margin:'auto',
    width:'100%',
    height:'100%',
  },
  containerBox: {
    display:'flex',
    margin:'auto',
    flexDirection:'column',
    alignItems:'center',
    width:theme.isMobile ? '100%':'450px',
    height:theme.isMobile ? '100%':'320px',
    background:'#464646',
    justifyContent:'space-evenly',
  },
  timerSection: {
    color:'white',
  },
  micIcon: {
    border:'1px solid transparent',
    padding:`${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(2)}px`,
    borderRadius:'50%',
    cursor:'pointer',
    color: 'white',
    background:'#fd6767',
  },
  stopIcon: {
    border:'1px solid transparent',
    padding:`${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    borderRadius:'50%',
    cursor:'pointer',
    color: 'white',
    background:'#fd6767',
  },
  deleteIcon: {
    border:'1px solid transparent',
    padding:`${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
    borderRadius:'50%',
    cursor:'pointer',
    background:'white',
    margin:theme.spacing(2),
  },
  saveIcon: {
    border:'1px solid transparent',
    padding:`${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
    borderRadius:'50%',
    cursor:'pointer',
    color:'white',
    margin:theme.spacing(2),
    background:theme.palette.primary.main,
  },
}));

function AudioRecorder (props) {
  const classes = useStyles();
  const [device, setDevice] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [recorder, setRecorder] = useState(null);
  const [timer, setTimer] = useState(0);
  const [file, setFile] = useState(null);
  const [duration, setDuration] = useState(0);
  const [isStopped, setIsStopped] = useState(false);
  const [audioCon, setAudioCon] = useState(null);
  const {landing: {userProfile}} = useSelector(state => state);

  useEffect(() => {
    if(!props.open) return;
    navigator.mediaDevices.enumerateDevices()
      .then((deviceInfos) => {
        const options = [];
        for (let i = 0; i !== deviceInfos.length; ++i) {
          const deviceInfo = deviceInfos[i];
          const option = {};
          option.value = deviceInfo.deviceId;
          if (deviceInfo.kind === 'audioinput') {
            option.label = deviceInfo.label || 'microphone ' + (9 + 1);
            options.push(option)
          }
        }
        setDevice((options[0].value));
      })
  }, [props.open]);
  
  const saveRecording = () => {
    props.uploadMediaToPisco(file, duration);
    setFile(null);
    setDuration(0);
  }
  const startRecording = () => {
    const handleSuccess = (stream) => {
      try {
        setIsRecording(true);
        const audioContext = new AudioContext();
        const input = audioContext.createMediaStreamSource(stream);
        setAudioCon(audioContext);
        const rec = new Recorder(input, { numChannels: 1 });
        setRecorder(rec);
        rec.record();
        let i = 0;
        timerId = setInterval(() => {
          const  timer = ++i;
          setTimer(timer);
        }, 1000);
      }
      catch (e) {
        const data = {
          'error': e,
          'email': (userProfile||{}).email || '',
          'url': (window.location||{}).href || '',
          'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
          'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
        };
        logger.push({
            'type': 'error',
            'data': data,
          });
      }
    };
    navigator.mediaDevices.getUserMedia({ audio: { deviceId: { exact: device } }, video: false })
      .then(handleSuccess).catch((error) => {
        // handle unable to record
        alert(error.message);
      });
  }

  const goToHome = () => {
    props.goToHome();
  }


  const stopRecording = () => {
    window.mm = recorder;
    recorder.stop();
    recorder.exportWAV((blob) => {
    clearInterval(timerId);
      setDuration(parseInt(`${timer}`.replace('sec', ''), 10));
      const file = new File([blob], "audio", { lastModified: new Date(), duration: timer, type: 'audio/wav', uploadType: 'audio/wav' });
      setFile(file);
    })
    setIsRecording(false);
    setRecorder(null);
    setIsStopped(true);
  }
  if(!props.open) {
    return null;
  }
  return (
    <Dialog fullScreen open={props.open}>
      <Box className={classes.dialogContainer}>
        <Box className={classes.containerBox}>
            <Box textAlign="center">
              <Typography variant="h2" className={classes.timerSection}>{totalTimeDisplay(timer)}</Typography>
              {!isStopped && <Typography variant="h5" className={classes.timerSection}>
                {!isStopped && !isRecording ? <span>Start Recording</span>: <span>Recording</span>}
              </Typography>}
              {isStopped && <Typography variant="h5" className={classes.timerSection}>
                <span>Recorded</span>
              </Typography>
              }
            </Box>
            {!isStopped && <Box>
              {!isRecording 
              ? <span>
                  <Mic onClick={startRecording} className={classes.micIcon}></Mic>
                </span>
              : <StopRounded className={classes.stopIcon} onClick={stopRecording}></StopRounded>}
            </Box>}
            {isStopped &&<Box display="flex" justifyContent="strech-evenly">
              <DeleteRounded onClick={goToHome} className={classes.deleteIcon}></DeleteRounded>
              <Check onClick={saveRecording} className={classes.saveIcon}></Check>
            </Box>}
        </Box>
      </Box>
        
      
    </Dialog>
  )
}

export default AudioRecorder;


function totalTimeDisplay(timer) {
  const min = Math.floor(timer/60);
  let sec = Math.floor(timer%60);
  sec = sec > 9 ? sec : `0${sec}`;
  return `${min}:${sec}`;
}