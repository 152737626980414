import React, { useEffect, useState, useContext } from 'react';
import Box from "commons/muiCustomComponents/Box";
import { lighten, makeStyles } from '@material-ui/core/styles';
import Paper from "commons/muiCustomComponents/Paper";
import Button from "commons/muiCustomComponents/Button";
import AddIcon from '@material-ui/icons/Add';
import  {LinearLoader, PageBlockLoader} from '../../commons/commonWrapper/Loaders';
import {getCustomerOverview} from "./customers-action";
import { useDispatch, useSelector } from 'react-redux';
import OverviewTable from "./overviewTable";
import { CircularLoader } from "commons/commonWrapper/Loaders";

import { useHistory } from "react-router-dom";
import MobileProvider from "commons/context/MobileProvider";
import { LogglyTracker } from 'loggly-jslogger';

const logger = new LogglyTracker();
logger.push({ 'logglyKey': '142d21f5-10d3-47a9-aa5a-f83e0a3abed7', 'sendConsoleErrors' : true, 'tag' : 'ardbeg,customers' });

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.pubWhite,
  },
  uppercontainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },
  productBackground :{
    backgroundColor:theme.palette.secondary.light,
    border:'none',
    "&:hover,&:focus": {
      backgroundColor:theme.palette.secondary.light,
      border:'none',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function Customers() {
  const dispatch = useDispatch();
  const { landing: { activeDomain, userProfile }} = useSelector((state) => state);
  const classes = useStyles();
  const history = useHistory();

  const [isLoader,setLoader] = useState(false);
  const [type, setType] = useState('all');
  const [email, setEmailFilter] = useState('');
  const [customerData, setCustomerData] = useState({result: [], result_info: {}});
  const isMobile = useContext(MobileProvider);
  useEffect(() => {
    let isMounted = true;
    const fetchDetail = async()=>{
      try {
        setLoader(true);
        let data = await dispatch(getCustomerOverview({}));
        if (data.result && data.result.length) {
          data.result = data.result.map((obj) => {
            return {
              name: obj.type,
              date_created: obj.date_created,
              email: obj.email,
              fullname: `${obj.firstname || ''} ${obj.lastname || ''}`,
              phone_number: `${obj.phone_number || ''}`,
              giftedProducts : obj.giftedProducts,
            }
          });
        }
        setCustomerData(data);
      } catch (err) {
        const data = {
          'error': err,
          'email': (userProfile||{}).email || '',
          'url': (window.location||{}).href || '',
          'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
          'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
        };
        logger.push({
            'type': 'error',
            'data': data,
          });
        console.log(err);
      } finally {
        setLoader(false);
      } 
    }
    fetchDetail();
    return ()=> isMounted = false;
  }, [activeDomain])

  const manageCust = () => {
     history.push('/dash/customers');
  }

  async function onChangePage(page) {
    await callApi({customerType: type, page});
  }
  async function onChangeNoOfRows(perPage) {
    await callApi({customerType: type, page: 1, perPage});
  }

  async function onChangeType(value, name) {
    setType(name);
    setEmailFilter('');
    await callApi({customerType: value, page: 1});
  }

  async function onSearchField(page, searchStr, rawText) {
    setEmailFilter(rawText);
    setType('all');
    await callApi({customerType: 'all', page: page, email: searchStr});
  }

  async function callApi(obj) {
    try {
      setCustomerData({result: [], result_info: {}});
      setLoader(true);
      const searchData = {
        customerType: obj.customerType || 'all',
        page: obj.page || 1,
        perPage: obj.perPage || 50,
      };
      if (obj.email) searchData.email = obj.email;
      let data = await dispatch(getCustomerOverview(searchData));
      if (data.result && data.result.length) {
        data.result = data.result.map((obj) => {
          return {
            name: obj.type,
            date_created: obj.date_created,
            email: obj.email,
            fullname: `${obj.firstname || ''} ${obj.lastname || ''}`,
            phone_number: `${obj.phone_number || ''}`,
            giftedProducts : obj.giftedProducts,
          }
        });
      }
      setCustomerData(data);
    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      console.log(err);
    } finally {
      setLoader(false);
    } 
  }
  return(
    <Box>
      <Box mt={4} className={classes.root}>
        <Paper >
          {isLoader ?
            <Box display="flex" justifyContent="center" alignItems="center" className={classes.loaderBox}>
            <CircularLoader></CircularLoader>
          </Box>
          : <OverviewTable
            type={type}
            data={customerData}
            onPageChange={onChangePage}
            onRowChangeHandle={onChangeNoOfRows}
            onTypeChange={onChangeType}
            onSearch={onSearchField}
            email={email}
            />}
        </Paper>
      </Box>
      <Box mt={4}>
        <Paper>
          <Box className={classes.disclaimerContainer}>
            <strong>Disclaimer:&nbsp;</strong>
            This feature is in beta. We are working to add more filters and improving on accuracy of the data.
            <br/>For any queries & requests, email us at support@pubninja.com. You can also reach out to us using chat option.
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}