import React, { useContext, useState, useCallback ,useRef} from 'react';
import Box from "../../commons/muiCustomComponents/Box";
import { makeStyles } from '@material-ui/core/styles';
import { CircularLoader, LinearLoader } from '../../commons/commonWrapper/Loaders';
import CategoriesList from "./components/CategoriesList";
import { useSelector,useDispatch } from "react-redux";
import Button from "../../commons/muiCustomComponents/Button";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import MobileProvider from "commons/context/MobileProvider";
import FullScreenDialog from "../../commons/commonWrapper/FullScreenDialog";
import InputBox from "../../commons/muiCustomComponents/InputBox";
import SubCategoryForm from "./components/SubCategoryForm";
import CategoryForm from "./components/CategoryForm";
import { addSubCat,editSubCat,addCat,editCat,updateSection, createSection } from "./settings-action";
import Grid from "../../commons/muiCustomComponents/Grid";
import Footer from "commons/commonWrapper/Footer";
import Paper from "commons/muiCustomComponents/Paper";
import kebabCase from "lodash.kebabcase";
import WithFileUpload from "commons/hocs/with-file-upload";
import ImageIcon from '@material-ui/icons/Image';
import AddIcon from '@material-ui/icons/Add';
import CropImageDialog from "../content/CroppingDialog";
import setAppMessage from "app-component/app-component-action";
import DeleteIcon from '@material-ui/icons/Delete';


const uploadImage = () => {
  return (
    <Box width={170} style={{
      border: '1px solid #e5e5e5',
      cursor: 'pointer',
      width: '170px !important', height: '90px',
    }} bgcolor="#e5e5e5">
      <Box width="100%" height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <Box position="relative">
          <AddIcon style={{
            position: 'absolute',
            top: '-7px',
            left: '7px',
          }} />
          <ImageIcon />
        </Box>
        <Box component="span" fontSize={12}> UPLOAD IMAGE </Box>
      </Box>
    </Box>
  )
}

const FileUploaderButton = WithFileUpload(uploadImage);


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  child: {
    backgroundColor: theme.palette.pubVeryLightPink,
    margin: theme.spacing(16),
  },
  iconWidth: {
    width: '100%',
    height: '100%',
  },
}));

export default function Categories({ appBarHeight }) {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const [isEditCat ,setEditCat] = useState(false);
  const [activeCategory, setActiveCategories] = useState({});
  const [activeSubCategory, setActivesubCategory] = useState({});
  const [categoryDescription,setCategoryDescripition] = useState('');
  const [categoryValue,setCategoryvlaue] = useState('');
  const [isOpenSubCat, setOpenSubCat] = useState(false);
  const [isOpenCat,setOpenCat] = useState(false);
  const [error ,setError] = useState(false);
  const [addSubSection , setAddSubSection] = useState(false);
  const isMobile = useContext(MobileProvider);
  const [headerType, setHeaderType] = useState(null);
  const [filename, setFileName] = useState(null);
  const [finalCroppedImage ,    setFinalCroppedImage] = useState('');
  const [openThumbDialog, setThumbnailDialog] = useState(false);
  const [thumbnailContent, setthumbnailContent] = useState('');
  const dispatch = useDispatch();
  const childRef = useRef();

  const { landing: { sections,isCategoryLoading } } = useSelector(state => state);

  const setCategories = useCallback((activeCategory) =>{
    setActiveCategories(activeCategory);
    setCategoryvlaue(activeCategory.name);
    setCategoryDescripition(activeCategory.description);
    setFinalCroppedImage(activeCategory.thumbnail)
  }, [activeCategory]);


  const barCallback = useCallback(() => (<Box
    display="flex"
    alignItems="center"
    color="pubBlack">{activeCategory.name}</Box>), [activeCategory]);

  const clearPageData = useCallback(() => {
    setAddSubSection(false)
    setActiveCategories({});
  setError(false)}, []);
  
  const setSubCategories = useCallback((activeSubCategory) => { setActivesubCategory(activeSubCategory); toggleSubCat(); }, [activeSubCategory])

  const toggleSubCat = (add = false ) => {
    add && setAddSubSection(true);
    setOpenSubCat(!isOpenSubCat)
  }
  const clearPageSubData = () => { toggleSubCat();setAddSubSection(false); setActivesubCategory({}); }

  const barCallbackForSub = useCallback(() => (<Box
    display="flex"
    alignItems="center"
    color="pubBlack">{activeSubCategory.name || "Add Subsection"}</Box>), [activeSubCategory]);

 const barCallbackForCat =  useCallback(() => (<Box
  display="flex"
  alignItems="center"
  color="pubBlack">Add Section</Box>), []);

  const addSubCategory =(data)=>{
    return dispatch(createSection(data))
  }
  const editSubCategory = (id,data)=>{
   return dispatch(updateSection(id,data))
  }
  const addCategory = (data)=>{
    return dispatch(createSection(data))
  }
  const handleChangeCategoryvalue = (e)=>{
    const value = e.currentTarget.value;
    setCategoryvlaue(value);
    if (!value) {setError(true)}
    else{setError(false)}
  }

  const handleChangeDescripitionvalue = (e)=>{
    const value = e.currentTarget.value;
    setCategoryDescripition(value);
  }

  const ChangeCateogry = async()=>{

    if(!categoryValue.trim()){
      setError (true)
      return;
    }

    const payload = {name:categoryValue.trim(),slug :kebabCase(categoryValue.trim()),
      description : categoryDescription,
      thumbnail:finalCroppedImage}
    try {
      setEditCat(true);
      await dispatch(updateSection(activeCategory.id,payload));
      setEditCat(false);
    } catch(err){
      setEditCat(false);
    }
  }


  const handleFileUpload = (target, type) => {
    const allowed = ['jpg','jpeg', 'png','bmp'];
    const file = target && target.files[0];
    const uploadType = type && type.split("/")[0];
    const extension = file && file.type.split("/")[1];
    if (allowed.indexOf(extension) < 0) {
      dispatch(setAppMessage({ message: 'Unsupported media format.', header: 'Error', type: 'alert' }));
      return;
    }
    const { size } = file;
    const sizeInMb = size / 1000000;
    if (sizeInMb > 1024) {
      dispatch(setAppMessage({ message: 'image larger than 1GB not allowed.', header: 'Error', type: 'alert' }));

      return;
    }
    if (file && type && !(file.type.indexOf(uploadType) > -1)) {
      //this.props.InvalidFileError();
      return;
    }
    if (uploadType === 'image' && extension === 'webp') {
      //this.props.InvalidFileError('Webp images are not allowed, as not supported by Safari browser.');
      return;
    }
    if (!file || !file.name) {
      return;
    }
    setHeaderType(file.type);
    
    setFileName(file.name);

    const fr = new FileReader();
    fr.onload = (stream) => {
      setthumbnailContent(stream.target.result);
      setThumbnailDialog(true);
    }
    fr.readAsDataURL(file);


  }

  return (
    <>
     <CropImageDialog
        ref={childRef}
        open={openThumbDialog}
        setThumbnailDialog={setThumbnailDialog}
        thumbnailContent={thumbnailContent}
        name={filename}
        setFinalCroppedImage = {setFinalCroppedImage}
      />
    <Grid container>
      <Grid item xs={12} md={9} xl={10}>
      {isCategoryLoading && <LinearLoader position="sticky" top={appBarHeight}/>}
          <Box m ={isMobile ?2 : 0} mt={4} mb={4} >
            <Paper>
    <Grid container>
      <Grid item xs={12} md={4} xl={6}>
      <Box className={classes.root}  >
        <CategoriesList CategoriesArray={sections} setCategories={setCategories} setSubCategories={setSubCategories} />
       {
         !isMobile && <Box mb={3} mt={3} display="flex" >
         <Button disabled={isLoading} variant="contained" fullWidth onClick={()=>setOpenCat(true)}>Add Section <ArrowRightAltIcon /></Button>
         {isLoading && <CircularLoader height="auto" ml={1} size={20} />}
       </Box>
      
       }
       
        
      </Box>
      {
        isMobile && <><Box height="73px"></Box><Footer >
        <Box mb={3} mt={3} display="flex" width="80%">
           <Button disabled={isLoading} variant="contained" fullWidth onClick={()=>setOpenCat(true)}>Add Section <ArrowRightAltIcon /></Button>
           {isLoading && <CircularLoader height="auto" ml={1} size={20} />}
         </Box>
        </Footer>
        </>
      }
      </Grid>
    </Grid>
      {
        <FullScreenDialog isOpen={Object.keys(activeCategory).length > 0} clearPageData={clearPageData} BarComponent={barCallback}>
          {(barHeight) =><Grid container justify="center"> <Grid item xs={12} md={4}><Box 
          className={classes.root} width="80%" m={"auto"} mt={2}>
            <Box>
              <Box>
                <InputBox
                  error = {error}
                  heading="Section name"
                  fullWidth
                  color="secondary"
                  value={categoryValue ||''}
                  onChange = {handleChangeCategoryvalue}
                  placeholder="Write here..."
                  helperText={error ? "Section can't be empty" :''} 
                  headingStyle={{
                    fontSize: 14,
                    color: "pubGrey",
                    fontWeight: 500,
                    mb: 1,
                  }}
                />
              </Box>
              <Box>
                <InputBox
                  error = {error}
                  heading="Description"
                  fullWidth
                  color="secondary"
                  value={categoryDescription ||''}
                  onChange = {handleChangeDescripitionvalue}
                  placeholder="Write here..."
                  helperText={error ? "descripition can't be empty" :''} 
                  headingStyle={{
                    fontSize: 14,
                    color: "pubGrey",
                    fontWeight: 500,
                    mb: 1,
                  }}
                />
              </Box>
              <Box mt={3} display="flex" className={classes.thumbnailContainer}>

                  {
                    finalCroppedImage ?<Box width={170} display="flex">
                      <img src={finalCroppedImage} width="100%" alt="thumnail_image"/>
                      <DeleteIcon onClick={()=>setFinalCroppedImage("")} />
                    </Box>: <Box width={170}><FileUploaderButton
                    type="image/*"
                    onChange={handleFileUpload}
                  />
                   </Box>
                  }
                   
                 
              </Box>
              
              <Box mb={3} mt={3} display="flex" >
              <Button disabled={isEditCat} variant="contained" fullWidth onClick={ChangeCateogry}>save Section </Button>
              {isEditCat && <CircularLoader height="auto" ml={1} size={20} />}
            </Box>
            </Box>
            <Box component="p">
              Subsection: {activeCategory && activeCategory.sub_section && activeCategory.sub_section.length}
            </Box>
            <Box >
              {activeCategory.sub_section && activeCategory.sub_section.length > 0 && <CategoriesList
                CategoriesArray={activeCategory.sub_section} setCategories={setSubCategories} />}
            </Box>
            {
              isMobile ?<>
               <Box height="73px"></Box>
              <Footer>
                  <Box mb={3} mt={3} display="flex" >
              <Button disabled={isLoading} variant="contained" fullWidth onClick={()=>toggleSubCat(true)}>Add Sub section <ArrowRightAltIcon /></Button>
              {isLoading && <CircularLoader height="auto" ml={1} size={20} />}
            </Box>
              </Footer>
              </>:<Box mb={3} mt={3} display="flex" >
              <Button disabled={isLoading} variant="contained" fullWidth onClick={()=>toggleSubCat(true)}>Add Sub section <ArrowRightAltIcon /></Button>
              {isLoading && <CircularLoader height="auto" ml={1} size={20} />}
            </Box>
            }
            
          </Box>
          
          </Grid>
          </Grid>
          }
        </FullScreenDialog>
      }
      {
        <FullScreenDialog isOpen={isOpenSubCat} clearPageData={clearPageSubData} BarComponent={barCallbackForSub}>
          {(barHeight) => <Grid container justify="center"> <Grid item xs={12} md={4}> <Box 
          className={classes.root} width="80%" m={"auto"} mt={2} height={isMobile ? `calc(100vh - ${barHeight}px)` : 'auto'}>
              <SubCategoryForm 
              activeSubCategory={activeSubCategory} 
              activeCategory = {activeCategory}
              addSubCat ={addSubCategory}
              editSubCat = {editSubCategory}
              categoryId ={activeCategory.id}
              clearPageSubData ={clearPageSubData}
              toggleSubCat={toggleSubCat}
              addSubSection = {addSubSection}
              setAddSubSection ={setAddSubSection}
            />
          </Box>
          </Grid>
          </Grid>
          }
        </FullScreenDialog>
      }
       {
        <FullScreenDialog isOpen={isOpenCat} clearPageData={()=>setOpenCat(false)} BarComponent={barCallbackForCat}>
          {(barHeight) => <Grid container justify="center"> <Grid item xs={12} md={4}><Box 
          className={classes.root} width="80%" m={"auto"} mt={2}>
              <CategoryForm 
              toggleCat={()=>setOpenCat(false)}
              addCategory = {addCategory}
              isLoading = {isLoading}
            />
          </Box>
          </Grid>
          </Grid>
          }
        </FullScreenDialog>
      }
       </Paper>
    </Box>
      </Grid></Grid>
    </>
  );
}
