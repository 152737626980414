import React, { Component } from 'react'
import PropTypes from 'prop-types'
import 'emoji-mart/css/emoji-mart.css'
// @ts-ignore
import { Picker } from 'emoji-mart';
import SendButton from './SendButton'

const filterEmoji = (emoji) => {
    if (
      emoji.name === 'White Smiling Face' ||
      emoji.name === 'White Frowning Face'
    ) {
      return false;
    }
    return true;
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            console.log(reader.result);
            resolve(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
            reject(error)
        };
    })
}

class ChatInput extends Component {
    static propTypes = {
        onSubmitMessage: PropTypes.func.isRequired,
    }
    state = {
        message: '',
        isEmojiOpen: false,
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick,false);
    }

    onSelectEmoji = (data) =>{
        this.setState({ message: this.state.message+ data.native })
    }

    toggleEmoji = () =>{
        this.setState({ isEmojiOpen : !this.state.isEmojiOpen})
    }

    handleClick = (e) =>{
        if(!this.node.contains(e.target) && !this.emoji.contains(e.target)) {
            if(this.state.isEmojiOpen) {
                this.toggleEmoji()
            }
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.submit()
    }

    submit = () => {
        const trimmedMessage = this.state.message.trim();
        const isEmptyMessage =
            trimmedMessage === '' ||
            trimmedMessage === '>' ||
            trimmedMessage === '``````' ||
            trimmedMessage === '``' ||
            trimmedMessage === '**' ||
            trimmedMessage === '____' ||
            trimmedMessage === '__' ||
            trimmedMessage === '****';
        if (isEmptyMessage  === true) {
            return;
        }
        this.props.onSubmitMessage("text", trimmedMessage)
        this.setState({ message : ''})
    }

    fileUpload = async (e) => {
        const file = e.target.files && e.target.files[0];
        if (file) {
            const bs = await getBase64(file)
            this.props.onSubmitMessage("image", bs)
        }
    }

    onKeyPressEvent = (e) => {
        if (e.key === "Enter") {
            e.preventDefault()
            this.submit()
        }
    }

    render() {
        return (
            <div className="textAreaWrapper">
                <div className="divTextarea">
                    <textarea
                        type="text"
                        placeholder={'Type your message'}
                        value={this.state.message}
                        onChange={e => this.setState({ message: e.target.value })}
                        className="inputArea"
                        onKeyPress={this.onKeyPressEvent}
                        rows="1"
                    />
                </div>
                <SendButton sendMessage={this.handleSubmit} />
                <div className="emojiPicker" ref={node => this.node = node}>
                    {
                        this.state.isEmojiOpen &&
                        <Picker
                            native
                            emoji="point_up"
                            title='Pick your emoji'
                            onSelect={this.onSelectEmoji}
                            color="#006CFF"
                            showPreview={false}
                            useButton={true}
                            theme="dark"
                            emojisToShowFilter={filterEmoji}
                        />
                    }
                </div>
                <span className="emojiselect" ref={node => this.emoji = node} onClick={this.toggleEmoji}>
                    <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg">
                        <path d={"M11.108 8.05a.496.496 0 0 1 .212.667C10.581 10.147 8.886 11 7 11c-1.933 0-3.673-.882-4.33-2.302a.497.497 0 0" + 
                        " 1 .9-.417C4.068 9.357 5.446 10 7 10c1.519 0 2.869-.633 3.44-1.738a.495.495 0 0 1 .668-.212zm.792-1.826a.477.477 0 0 1-." + 
                        "119.692.541.541 0 0 1-.31.084.534.534 0 0 1-.428-.194c-.106-.138-.238-.306-.539-.306-.298 0-.431.168-.54.307A.534.534 0 0 1" + 
                        " 9.538 7a.544.544 0 0 1-.31-.084.463.463 0 0 1-.117-.694c.33-.423.742-.722 1.394-.722.653 0 1.068.3 1.396.724zm-7 0a.477.477 0" + 
                        " 0 1-.119.692.541.541 0 0 1-.31.084.534.534 0 0 1-.428-.194c-.106-.138-.238-.306-.539-.306-.299 0-.432.168-.54.307A.533.533 0 0 1" + 
                        " 2.538 7a.544.544 0 0 1-.31-.084.463.463 0 0 1-.117-.694c.33-.423.742-.722 1.394-.722.653 0 1.068.3 1.396.724zM7 0a7 7 0 1 1 0 14A7" + 
                        " 7 0 0 1 7 0zm4.243 11.243A5.96 5.96 0 0 0 13 7a5.96 5.96 0 0 0-1.757-4.243A5.96 5.96 0 0 0 7 1a5.96 5.96 0 0 0-4.243 1.757A5.96 " + 
                        "5.96 0 0 0 1 7a5.96 5.96 0 0 0 1.757 4.243A5.96 5.96 0 0 0 7 13a5.96 5.96 0 0 0 4.243-1.757z"} fill-rule="evenodd"></path>
                    </svg>
                </span>
                <div className="fileUploadDiv">
                    <label>
                        <input type="file" className="fileInput" accept=".jpg,.jpeg,.png,.gif" onChange={this.fileUpload} />
                        <span>
                            <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg">
                                <path d={"M7 .5c3.59 0 6.5 2.91 6.5 6.5s-2.91 6.5-6.5 6.5S.5 10.59.5 7 3.41.5 7 .5zm0 12c3.031 0 5.5-2.469 5.5-5.5S10.031" + 
                                " 1.5 7 1.5A5.506 5.506 0 0 0 1.5 7c0 3.034 2.469 5.5 5.5 5.5zM7.506" + 
                                " 3v3.494H11v1.05H7.506V11h-1.05V7.544H3v-1.05h3.456V3h1.05z"}
                                fill-rule="nonzero"></path>
                            </svg>
                        </span>
                    </label>
                </div>
                
            </div>


            // <form
            //     action="."
            //     onSubmit={e => {
            //       e.preventDefault()
            //       this.props.onSubmitMessage(this.state.message)
            //       this.setState({ message: '' })
            //     }}
            //   >

            //     {/* <input type="submit" value={'Send'} /> */}
            //   </form>

        )
    }
}

export default ChatInput