import React, { useState, useEffect,forwardRef, useImperativeHandle } from 'react';
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Input from "../../../commons/muiCustomComponents/Input";
import Box from "../../../commons/muiCustomComponents/Box";
import Grid from "commons/muiCustomComponents/Grid";
import Button from "commons/muiCustomComponents/Button";
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { LinearLoader,PageBlockLoader, CircularLoader } from 'commons/commonWrapper/Loaders';
import {isValidEmailAddress} from "../../../utils/utils";
import { LogglyTracker } from 'loggly-jslogger';

const logger = new LogglyTracker();
logger.push({ 'logglyKey': '142d21f5-10d3-47a9-aa5a-f83e0a3abed7', 'sendConsoleErrors' : true, 'tag' : 'ardbeg,advertisement,businessinfo' });

/**
 * Handle Error part 
 * Transfer only successpart to parent
 */

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '95%',
  },
  selectEmpty: {
    color: theme.palette.pubGrey,
  },
  container: {
    height: '100%',
  },
}));


function BusinessInfo(props) {
  const classes = useStyles();
  const [companyType, setCompanyType] = useState('Individual');
  const [errBusinessName ,setErrBusinessName] = useState(false);
  const [errBusinessPan,setErrBusinessPan] = useState(false);
  const [errAddressLine1,setErrAdressLine1] = useState(false);
  const [errAddressLine2,setErrAdressLine2] = useState(false);
  const [errCity ,setErrCity] = useState(false);
  const [errPinCode ,setErrPinCode] = useState(false);
  const [errState,setErrState] = useState(false);
  const [errBEmail,setErrBEmail] =useState(false);
  
  const [isLoading ,setLoading] = useState(false);

  const {
        businessName ,setBusinessName,
        businessPan,setBusinessPan,
        GSTNumber,setGSTNumber,
        TAXNumber,setTaxNumber,
        addressLine1,setaddressLine1,
        addressLine2,setaddressLine2,
        city,setCity,
        pinCode ,setPinCode,
        state,setState,
        businessEmail,setBusinessEmail,
        updateBusinessDetails,
        setCompletedItem,
        setActiveStepItem,
        businessType,setBusinessType,
        thirdStepComplete,
        forthStepComplete,
  } = props;

  const {landing: {userProfile}} = useSelector(state => state);

  useEffect(()=>{
    businessType && setCompanyType(businessType)
  },[businessType]);

  const handleChange = (e) => {
    setCompanyType(e.target.value);
  }

  const updateBusiness = async()=>{

    if (companyType !== "Individual") {
      if (!businessName) return setErrBusinessName(true)
      if (!businessPan) return setErrBusinessPan(true)
      if (!businessPan || (businessPan && businessPan.length !== 10)) {
        setErrBusinessPan(true)
        return;
      }
    }
    
    if (!addressLine1) return setErrAdressLine1(true)
    if (!addressLine2) return setErrAdressLine2(true)
    if (!city) return setErrCity(true)
    if (!pinCode) return setErrPinCode(true)
    if (!state) return setErrState(true)
    if (!isValidEmailAddress(businessEmail)) return setErrBEmail(true)

    setErrAdressLine1(false)
    setErrBusinessName(false)
    setErrBusinessPan(false)
    setErrAdressLine2(false)
    setErrCity(false)
    setErrPinCode(false)
    setErrState(false)
    setErrBEmail(false)

    try{
      setLoading(true);
      await updateBusinessDetails({
        company_name : businessName,
        company_address_line1 :addressLine1,
        company_address_line2 :addressLine2,
        type_of_entity :companyType ,
        business_email	:businessEmail,
        business_state : state,
        business_pin	:pinCode,
        business_pan :businessPan,
        gst_id_number :GSTNumber,
        tax_deduction_number:TAXNumber,
        business_city :city,
      });
      setBusinessType(companyType)
      if (!thirdStepComplete || !forthStepComplete){
        setActiveStepItem && setActiveStepItem(3);
        setCompletedItem && setCompletedItem({0:true,1:true,2:true});
      }
      
      setLoading(false);
    }
    catch(err){
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      setLoading(false);
    }

  }
  return (<Box mt={2} ml={4} mr={4}  >
    <Grid container >
      <Grid item md={6} xs={12} >
        <Box m={2} >
          <FormControl className={classes.formControl}>
            <InputLabel className={classes.selectEmpty} > Type of Business *</InputLabel>
            <Select
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              value={companyType}
              onChange={handleChange}
            >
              <MenuItem value={'Individual'}>Individual</MenuItem>
              <MenuItem value={'Company'}>Company</MenuItem>
              <MenuItem value={'LLP'}>LLP (Limited Liability Partnership)</MenuItem>
              <MenuItem value={'SoleProprietorship'}>Sole Proprietorship</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Grid>
    </Grid>

    {companyType !== "Individual" && <Grid container><Grid item md={6} xs={12}>
      <Box m={2}>
        <Input
          fullWidth
          color="secondary"
          error={errBusinessName}
          onChange={(e)=>{setBusinessName(e.currentTarget.value)}}
          value={businessName}
          label="Legal Business Name*"
        >
        </Input>
      </Box>
    </Grid>
      <Grid item md={6} xs={12}>
        <Box m={2}>
          <Input
            fullWidth
            color="secondary"
            value={businessPan}
            error={errBusinessPan}
            onChange={(e)=>{
              setBusinessPan(e.currentTarget.value)
              try{ 
                if (!e.currentTarget.value || e.currentTarget.value.length !== 10) {
                   setErrBusinessPan(true) }
                else {
                  setErrBusinessPan(false)
                }
              }
              catch(err){
                // eslint-disable-next-line no-console
                console.log("err")
              }
            }}
            helperText={errBusinessPan ? (!businessPan) ? 'PAN number Required':`PAN number length must be 10`  : ''}
            label={"Business PAN*"}>
            
          </Input>
        </Box>
      </Grid>
    </Grid>
    }

    <Grid container>
      <Grid item md={6} xs={12}>
        <Box m={2}>
          <Input
            fullWidth
            color="secondary"
            onChange={(e)=>{setGSTNumber(e.currentTarget.value)}}
            value={GSTNumber}
            label="GST Identification Number"
          >
          </Input>
        </Box>
      </Grid>
      <Grid item md={6} xs={12}>
        <Box m={2}>
          <Input
            fullWidth
            color="secondary"
            value={TAXNumber}
            onChange={(e)=>{setTaxNumber(e.currentTarget.value)}}
            label={"Tax deduction account number"}>
          </Input>
        </Box>

      </Grid>

    </Grid>

    <Grid container>
      <Grid item md={6} xs={12}>
        <Box m={2}>
          <Input
            fullWidth
            color="secondary"
            error={errAddressLine1}
            onChange={(e)=>{setaddressLine1(e.currentTarget.value)}}
            value={addressLine1}
            label="Address Line 1*"
          >
          </Input>
        </Box>
      </Grid>
      <Grid item md={6} xs={12}>
        <Box m={2}>
          <Input
            fullWidth
            color="secondary"
            error={errAddressLine2}
            value={addressLine2}
            onChange={(e)=>{setaddressLine2(e.currentTarget.value)}}
            label={"Address Line 2*"}>
          </Input>
        </Box>

      </Grid>

    </Grid>


    <Grid container>
      <Grid item md={6} xs={12}>
        <Box m={2}>
          <Input
            fullWidth
            color="secondary"
            error={errCity}
            onChange={(e)=>{setCity(e.currentTarget.value)}}
            value={city}
            label="City *"
          >
          </Input>
        </Box>
      </Grid>
      <Grid item md={6} xs={12}>
        <Box m={2}>
          <Input
            fullWidth
            color="secondary"
            value={pinCode}
            error ={errPinCode}
            onChange={(e)=>{setPinCode(e.currentTarget.value)}}
            label={"Pin Code*"}>
          </Input>
        </Box>

      </Grid>

    </Grid>
    <Grid container>
      <Grid item xs={12} md={6} >
        <Box m={2}>
          <Input
            fullWidth
            color="secondary"
            value={state}
            error={errState}
            onChange={(e)=>{setState(e.currentTarget.value)}}
            label={"State *"}>
          </Input>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} >
        <Box m={2}>
          <Input
            fullWidth
            type="email"
            color="secondary"
            value={businessEmail}
            error={errBEmail}
            onChange={(e)=>{setBusinessEmail(e.currentTarget.value)}}
            label={"Business Email *"}>
          </Input>
        </Box>
      </Grid>
    </Grid>


    <Box m={2}>
      <Box display="flex">
        
          <Button variant="contained" disabled={isLoading} onClick={updateBusiness}> Save</Button>
          {isLoading && <CircularLoader height="auto" size={20} ml={2}/>}
        
      </Box>
    </Box>
  </Box>)
}
export default BusinessInfo;
