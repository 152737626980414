import React from "react";
import LogoutHome from "components/auth/LogoutHome";
import Hidden from "commons/muiCustomComponents/Hidden";
import Grid from "commons/muiCustomComponents/Grid";
import Box from "commons/muiCustomComponents/Box";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  formSection: {
    padding:theme.spacing(2),
    height:'80vh',
  },
  loginImage: {
    backgroundColor:theme.palette.primary.main,
    height:'100vh',
    display:'flex',
    justifyContent:'space-around',
    flexDirection:'column',
  },
  textSection:{
    fontSize:theme.spacing(11),
    color:'white',
    fontWeight:'700',
    textAlign:'center',
  },
  imageBox: {
    marginLeft:'20%',
    width:'80%',
    height:'20%',
  },
  girlImage: {
    width:'100%',
    height:'100%',
    objectFit:'cover',
  },
}));


function LogoutPage (props) {
  const classes = useStyles();
  return (
    <>
    <Grid container>
      <Grid item><LogoutHome/></Grid>
    </Grid>
    </>
  )
}

export default LogoutPage;
