import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from "../../commons/muiCustomComponents/Box";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import Button from "../../commons/muiCustomComponents/Button";
import {CircularLoader, LinearLoader, PageBlockLoader } from '../../commons/commonWrapper/Loaders';
import {
  getAboutPages,
  addAboutPages,
  deleteAboutPages,
  createNewPage,
} from "./settings-action";
import { useDispatch, useSelector } from "react-redux";
import MobileProvider from "commons/context/MobileProvider";
import PagesList from "./components/PagesList";
import clsx from 'clsx';
import { useHistory } from "react-router-dom";
import Footer from "commons/commonWrapper/Footer";
import Grid from "commons/muiCustomComponents/Grid";
import Paper from "commons/muiCustomComponents/Paper";


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  child: {
    backgroundColor: theme.palette.pubVeryLightPink,
    margin: theme.spacing(16),
  },
  iconWidth: {
    width: '100%',
    height: '100%',
  },
  footerButton: {
    width: theme.spacing(328 * 0.25),    
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
}));

export default function Pages({ appBarHeight }) {

  const classes = useStyles();
  const isMobile = useContext(MobileProvider);
  const [aboutData, setAboutdata] = useState({});
  const [isFatching, setFatching] = useState(false);
  const [isDraftJs, setDraftJs] = useState(false);
  const {landing:{activeDomain},header :{domainList}} = useSelector((state)=>state);
  const history = useHistory();
  const dispatch = useDispatch();
  let currentDomain =null;  

  useEffect(() => {
    let isMounted = true;
    const getStatus = async () => {
      try {
        setFatching(true)
        const data = await dispatch(getAboutPages());
        isMounted && setAboutdata(data);
        isMounted &&  setFatching(false)
      }
      catch (err) {
        isMounted && setFatching(false)
      }
    }
    getStatus()
    return ()=>(isMounted =false)
  }, []);
  
  try {
    currentDomain = domainList[activeDomain][0]['fqdn']
  }
  catch(err){
    //
  }
  const getAllPages= ()=>{
    return dispatch(getAboutPages());
  }

  const addNewPage = async () => {
  
    try {
      setDraftJs(true)
      const data = await dispatch(createNewPage())
      setDraftJs(false)
      history.push(`/dash/setting/website/pages/new/${data.pageId}`)
    }
    catch (err) {
      setDraftJs(false)
    }

  }

  const addDefaultPage = (slug) => {
    return dispatch(addAboutPages(slug))
  }

  const disableDefaultPage = (pageId) => {
    return dispatch(deleteAboutPages(pageId))
  }

  return (
    <>
    <Grid container>
      <Grid item xs={12} md={9} xl={10}>
        {isMobile && isFatching && <LinearLoader/>}
        {!isMobile && isFatching && <PageBlockLoader/>}
          <Box m ={isMobile ?2 : 0} mt={4} mb={4} >
            <Paper>
    <Box className={classes.root} >
      <Box component="p" m={0} ml={5}>Default Pages</Box>
      <Box display="flex" flexWrap="wrap">
        {aboutData.defaults && aboutData.defaults.map((item = {}, key) => {
          return <PagesList key={key}
            addDefaultPage={addDefaultPage}
            disableDefaultPage={disableDefaultPage}
            title={item.title}
            slug={item.slug}
            state = "default"
            currentDomain = {currentDomain}
            status={Object.prototype.hasOwnProperty.call(item, 'status')}
            id={item.id}
            isMobile={isMobile}
            getAllPages ={getAllPages}
            setAboutdata ={setAboutdata}
          />
        })}
      </Box>
      {aboutData.custom && !!aboutData.custom.length && <Box component="p" m={0} ml={5} mt={3}>Custom Pages</Box> }
      <Box display="flex" flexWrap="wrap" mb={1}>
        {aboutData.custom && aboutData.custom.map((item = {}, key) => {
          return <PagesList key={key}
            addDefaultPage={addDefaultPage}
            disableDefaultPage={disableDefaultPage}
            title={item.title}
            slug={item.slug}
            state={"custom"}
            currentDomain = {currentDomain}
            status={Object.prototype.hasOwnProperty.call(item, 'status')}
            id={item.id}
            isMobile={isMobile}
            getAllPages ={getAllPages}
            setAboutdata ={setAboutdata}
          />
        })}
      </Box>
      {
        !isMobile && <Box pb={3} pt={3} ml={!isMobile ? 5 : 0} display="flex" className={clsx({ [classes.footerButton]: !isMobile })}>
        <Button disabled={isDraftJs} variant="contained" fullWidth onClick={addNewPage}>Add New page <ArrowRightAltIcon /></Button>
        {isDraftJs && <CircularLoader height="auto" ml={1} size={20} />}
      </Box>
      }
      
    </Box>
    {
     isMobile &&  <Footer position="sticky">
      <Box pb={3} pt={3} width="80%" display="flex">
        <Button disabled={isDraftJs} variant="contained" fullWidth onClick={addNewPage}>Add New page <ArrowRightAltIcon /></Button>
        {isDraftJs && <CircularLoader height="auto" ml={1} size={20} />}
      </Box>
      </Footer>
    }
    </Paper>
    </Box>
      </Grid></Grid>
    </>
  );
}
