import setAppMessage from "../../app-component/app-component-action";
import * as axios from "axios";


function piscoPreSave(file) {
  const formData = new FormData();
  formData.append("file", file, file.name);
  return formData;
}


function localUpload(file) {
  let mediaType = 'Image';
  if (file.type.startsWith('video')) mediaType = 'Video';
  if (file.type.startsWith('audio')) mediaType = 'Audio';
  const formData = new FormData();
  formData.append("file", new Blob());
  formData.append('filename', file.name);
  formData.append('title', file.name);
  formData.append('tags', ['quick-cms'])
  formData.append('imageSource', 'LOCAL');
  formData.append('type', mediaType);
  formData.append('dimensions', {});
  formData.append('caption', file.name);
  return formData;
}


export function updateDomainList(key,value){
  return async (dispatch, getState) => {
    const { landing:{activeDomain}, header:{domainList}}  = getState();
    try {
    if (domainList[activeDomain]) domainList[activeDomain][0][key] = value ;
    dispatch({ type:"UpdateDomainList", domainList});
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export function setSubscribitionDetail(toggleValue) {
  return async (dispatch, getState, apiHandler) => {
    const { api, apiRoutes } = apiHandler;
    const { activeDomain } = getState().landing;
    try {
      let { data } = await api(apiRoutes.toggleSubscripition(activeDomain));
      dispatch(setAppMessage({ message: data && data && data.message, header: 'Success', type: 'alert' }));
      dispatch(updateDomainList('subscription_enabled',toggleValue));
      return Promise.resolve(data);
    } catch (err) {
      dispatch(setAppMessage({ message: err.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}

export default function getSubScriptionDetail(){
  return async(dispatch,getState,apiHandler)=>{
    const { api, apiRoutes, actionTypes } = apiHandler;
    const { activeDomain } = getState().landing;
    try{
       let { data } = await api(apiRoutes.getSubscriptionDetail(activeDomain));
       dispatch({type:actionTypes.SUBSCRIBE.FETCH.SUCCESS,data:data.result})
       return Promise.resolve(data.result);
    }
    catch(err){
      dispatch(setAppMessage({ message: err.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}

export function getAdsDetail(){
  return async(dispatch,getState,apiHandler)=>{
    const { api, apiRoutes, actionTypes } = apiHandler;
    const { activeDomain } = getState().landing;
    try{
       let { data } = await api(apiRoutes.getConfigureAds(activeDomain));
       dispatch({type:actionTypes.ADS.FETCH.SUCCESS,data:data})
    }
    catch(err){
      dispatch(setAppMessage({ message: err.message, header: 'Error', type: 'alert' }))
    }
  }
}

export function setAdsDetail(adDetail){
  return async(dispatch,getState,apiHandler)=>{
    const { api, apiRoutes, actionTypes } = apiHandler;
    const { activeDomain } = getState().landing;
    try{
       let { data } = await api(apiRoutes.setConfigureAds(activeDomain,adDetail));
       dispatch({type:actionTypes.ADS.SET.SUCCESS,data:adDetail})
       dispatch(setAppMessage({ message: data && data.message, header: 'Success', type: 'alert' }))
      }
    catch(err){
      dispatch(setAppMessage({ message: err.message, header: 'Error', type: 'alert' }))
    }
  }
}

export function genereateOTP(mobileData){
  return async(dispatch,getState,apiHandler)=>{
    const { api, apiRoutes } = apiHandler;
    const { activeDomain } = getState().landing;
    try{
       let { data } = await api(apiRoutes.getOTP(activeDomain,mobileData));
      dispatch(setAppMessage({ message: data && data.message, header: 'Success', type: 'alert' }))
      return Promise.resolve(data);
      }
    catch(err){
      dispatch(setAppMessage({ message: err.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}

export function verifyOTP(otpDetail){
  return async(dispatch,getState,apiHandler)=>{
    const { api, apiRoutes } = apiHandler;
    const { activeDomain } = getState().landing;
    try{
       let { data } = await api(apiRoutes.setOTP(activeDomain,otpDetail));
      return Promise.resolve(data);
      }
    catch(err){
      dispatch(setAppMessage({ message: err.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}

export function getUserBankDetail(){
  return async(dispatch,getState,apiHandler)=>{
    const { api, apiRoutes } = apiHandler;
    const { activeDomain } = getState().landing;
    try{
       let { data } = await api(apiRoutes.getBankDetail(activeDomain));
      return Promise.resolve(data);
      }
    catch(err){
      dispatch(setAppMessage({ message: err.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}


export function setUserbankdetail(bankDetail){

  return async(dispatch,getState,apiHandler)=>{
    const { api, apiRoutes } = apiHandler;
    const { activeDomain } = getState().landing;
    try{
       let { data } = await api(apiRoutes.setBankDetail(activeDomain,bankDetail));
      return Promise.resolve(data);
      }
    catch(err){
      dispatch(setAppMessage({ message: err.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}

export function agreement(){
  return async(dispatch,getState,apiHandler)=>{
    const { api, apiRoutes } = apiHandler;
    const { activeDomain } = getState().landing;
    try{
      let { data } = await api(apiRoutes.fillAgreement(activeDomain));
     dispatch(setAppMessage({ message: "Agreement succeeded !!!", header: 'Success', type: 'alert' }))
     return Promise.resolve(data);
     }
   catch(err){
     dispatch(setAppMessage({ message: err.message, header: 'Error', type: 'alert' }))
     return Promise.reject(err);
   }
  }
}

export function checkStripeAccountAction(){
  return async(dispatch,getState,apiHandler)=>{
    const { api, apiRoutes, actionTypes } = apiHandler;
    const { activeDomain } = getState().landing;
    try{
      let { data } = await api(apiRoutes.checkStripeAccountApi(activeDomain));
      return Promise.resolve(data);
    }
    catch(err){
      dispatch(setAppMessage({ message: err.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}

export function createStripeAccountAction(token){
  return async(dispatch,getState,apiHandler)=>{
    const { api, apiRoutes } = apiHandler;
    const { activeDomain } = getState().landing;
    try{
      let { data } = await api(apiRoutes.createStripeAccountApi(activeDomain, token));
      return Promise.resolve();
    }
   catch(err){
     dispatch(setAppMessage({ message: err.message, header: 'Error', type: 'alert' }))
     return Promise.reject(err);
   }
  }
}



export function getSubscriptionPlanAction(){
  return async(dispatch,getState,apiHandler)=>{
    const { api, apiRoutes ,actionTypes} = apiHandler;
    const { activeDomain } = getState().landing;
    try{
      let { data } = await api(apiRoutes.getSubscriptionPlanApi(activeDomain));
      dispatch({type:actionTypes.SUBSCRIBE.FETCH.SUCCESS,data:data})
      return Promise.resolve(data);
    }
    catch(err){
      dispatch(setAppMessage({ message: err.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}

export function createStripePlanAction(payload){
  return async(dispatch,getState,apiHandler)=>{
    const { api, apiRoutes } = apiHandler;
    const { activeDomain } = getState().landing;
    try{
      let { data } = await api(apiRoutes.createStripePlanApi(activeDomain, payload));
      return Promise.resolve(data);
    }
    catch(err){
      dispatch(setAppMessage({ message: err.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}

export function createSubscriptionPlanAction(payload){
  return async(dispatch,getState,apiHandler)=>{
    const { api, apiRoutes } = apiHandler;
    const { activeDomain } = getState().landing;
    try{
      let { data } = await api(apiRoutes.createSubscriptionPlanApi(activeDomain, payload));
      return Promise.resolve();
    }
    catch(err){
      dispatch(setAppMessage({ message: err.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}

export function showMessage(message){
  return (dispatch)=>{
    dispatch(setAppMessage({ message: message, header: 'Error', type: 'alert' }))
  }
}




//  New subscripition flow actions 

export function  getSubStatus (){
  return async(dispatch,getState,apiHandler)=>{
    const { api, apiRoutes ,actionTypes} = apiHandler;
    const { activeDomain } = getState().landing;
    try{
      let {data } = await api(apiRoutes.getSubscriptionStatus(activeDomain));
      dispatch({type: 'SUBSCRIPTION_STATUS_STATUS_UPDATE', data, activeDomain});
      dispatch({type:actionTypes.SUBSCRIBE.FETCH.SUCCESS,data,activeDomain});
      return Promise.resolve(data);
    }
    catch(err){
      dispatch(setAppMessage({ message: err.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}

export function createNewProduct (payload){
  return async(dispatch,getState,apiHandler)=>{
    const { api, apiRoutes,actionTypes } = apiHandler;
    const { activeDomain } = getState().landing;
    try{
      let {data } = await api(apiRoutes.createProducts(activeDomain,payload));
      dispatch(updateDomainList('subscription_enabled',true));
      dispatch({type:actionTypes.SUBSCRIBE.SET.SUCCESS,data:{productCount:1},activeDomain});
      return Promise.resolve(data);
    }
    catch(err){
      dispatch(setAppMessage({ message: err && err.data&& err.data.status && err.data.status.message, header: 'Error', type: 'alert' }));
      return Promise.reject(err);
    }
  }
}

export function getAllProducts (){
  return async(dispatch,getState,apiHandler)=>{
    const { api, apiRoutes } = apiHandler;
    const { activeDomain } = getState().landing;
    try{
      let {data } = await api(apiRoutes.getProducts(activeDomain));
    
      return Promise.resolve(data);
    }
    catch(err){
      dispatch(setAppMessage({ message: err.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}

export function generateBenefite(payload){
  return async(dispatch,getState,apiHandler)=>{
    const { api, apiRoutes } = apiHandler;
    const { activeDomain } = getState().landing;
    try{
      let {data } = await api(apiRoutes.createBenefits(activeDomain,payload));
      // dispatch(setAppMessage({ message: data && data.status && data.status.message, header: 'Success', type: 'alert' }))
      return Promise.resolve(data);
    }
    catch(err){
      console.log("err",err)
      dispatch(setAppMessage({ message: err && err.data&& err.data.status && err.data.status.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}

export function getBenefites(){
  return async(dispatch,getState,apiHandler)=>{
    const { api, apiRoutes } = apiHandler;
    const { activeDomain } = getState().landing;
    try{
      let {data } = await api(apiRoutes.getBenefits(activeDomain));
      return Promise.resolve(data);
    }
    catch(err){
      console.log("err",err)
      dispatch(setAppMessage({ message: err && err.data&& err.data.status && err.data.status.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}



export const deleteProductById = (payload)=>{
  return async(dispatch,getState,apiHandler)=>{
    const { api, apiRoutes } = apiHandler;
    const { activeDomain } = getState().landing;
    try{
      let {data } = await api(apiRoutes.deleteProduct(activeDomain,payload));
      return Promise.resolve(data);
    }
    catch(err){
      dispatch(setAppMessage({ message: err && err.data&& err.data.status && err.data.status.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
} 

export function getProductsById (productId){
  return async(dispatch,getState,apiHandler)=>{
    const { api, apiRoutes } = apiHandler;
    const { activeDomain } = getState().landing;
    try{
      let {data } = await api(apiRoutes.getProductById(activeDomain,productId));
      return Promise.resolve(data);
    }
    catch(err){
      dispatch(setAppMessage({ message: err && err.data&& err.data.status && err.data.status.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}

export function dltPlan (productId,planId){
  return async(dispatch,getState,apiHandler)=>{
    const { api, apiRoutes } = apiHandler;
    const { activeDomain } = getState().landing;
    try{
      let {data } = await api(apiRoutes.deletePlan(activeDomain,productId,planId));
      return Promise.resolve(data);
    }
    catch(err){
      dispatch(setAppMessage({ message: err && err.data&& err.data.status && err.data.status.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}

export function removeBenefit (productId,benefitId){
  return async(dispatch,getState,apiHandler)=>{
    const { api, apiRoutes } = apiHandler;
    const { activeDomain } = getState().landing;
    try{
      let {data } = await api(apiRoutes.deleteBenefit(activeDomain,productId,benefitId));
      dispatch(setAppMessage({ message: data&& data.status && data.status.message, header: 'Success', type: 'alert' }))
      return Promise.resolve(data);
    }
    catch(err){
      dispatch(setAppMessage({ message: err && err.data&& err.data.status && err.data.status.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}

export function addNewPlan (payload){
  return async(dispatch,getState,apiHandler)=>{
    const { api, apiRoutes } = apiHandler;
    const { activeDomain } = getState().landing;
    try{
      let {data } = await api(apiRoutes.addPlan(activeDomain,payload));
      return Promise.resolve(data);
    }
    catch(err){
      dispatch(setAppMessage({ message: err && err.data&& err.data.status && err.data.status.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}

export function connectWithStripe (payload){
  return async(dispatch,getState,apiHandler)=>{
    const { api, apiRoutes } = apiHandler;
    const { activeDomain } = getState().landing;
    let data = {};
    try{
      if (payload.providerName === 'cashfree'){
        const {resdata} = await connecToCashfreeCallMaster(activeDomain, payload);
        console.log("res data", resdata);
        data = resdata;
      } else {
        const {resdata} = await api(apiRoutes.connectStripe(activeDomain,payload));
        data = resdata;
      }
      return Promise.resolve(data);
    }
    catch(err){
      dispatch(setAppMessage({ message: err && err.data&& err.data.status && err.data.status.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}

// cashfree connect should always go to master.pubninja.com in prod
async function connecToCashfreeCallMaster (activeDomain, payload) {
  const headers = {
    'Access-Control-Allow-Credentials': true,
    'Access-Control-Allow-Origin': '*',
  };
  let url = process.env.REACT_APP_MASTER_API_SERVER || 'https://dev.pubninja.com';
  url = `${url}/subscription/creator_subscription_management/${activeDomain}/connect`
  headers['Authorization'] = localStorage.getItem('accessToken');
  return await axios.post(url, payload, {headers});
}

export function attachBenefits (payload){
  return async(dispatch,getState,apiHandler)=>{
    const { api, apiRoutes } = apiHandler;
    const { activeDomain } = getState().landing;
    try{
      let {data } = await api(apiRoutes.attachBenefits(activeDomain,payload));
      
      return Promise.resolve(data);
    }
    catch(err){
      dispatch(setAppMessage({ message: err && err.data&& err.data.status && err.data.status.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}


export const updateProductById = (payload ,productId)=>{
  return async(dispatch,getState,apiHandler)=>{
    const { api, apiRoutes } = apiHandler;
    const { activeDomain } = getState().landing;
    try{
      let {data } = await api(apiRoutes.updateProduct(activeDomain,payload,productId));
      dispatch(setAppMessage({ message: "Successfully updated your product", header: 'Success', type: 'alert' }))
      return Promise.resolve(data);
    }
    catch(err){
      dispatch(setAppMessage({ message: err && err.data&& err.data.status && err.data.status.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}

export function saveMediaToPisco(file) {
  return async (dispatch, getState, apiHandler) => {
    try {
      if (!file) {
        return Promise.reject(new Error('Invalid Media File'));
      }
      const { api, apiRoutes } = apiHandler;
      let name = file.name.replace(/[^a-zA-Z.0-9]/g, '');
      file = new File([file], name, { type: file.type });
      const { landing: { activeDomain }} = getState();
      const preSaveData = piscoPreSave(file);
      let {data:preSaveResponse} = await api(apiRoutes.piscoPreSaveApi(preSaveData, activeDomain));
      const localUploadData = localUpload(file);
      let {data:localUploadResponse} = await api(apiRoutes.localUploadApi(localUploadData, activeDomain));
      return Promise.resolve({ preSaveResponse, localUploadResponse });
    } catch (error) {
      return Promise.reject(error);
    }
  }
}


export function updateBenefit (benefitId,payload){
  return async(dispatch,getState,apiHandler)=>{
    const { api, apiRoutes } = apiHandler;
    const { activeDomain } = getState().landing;
    try{
      let {data } = await api(apiRoutes.updateBenefits(activeDomain,benefitId,payload));
      // dispatch(setAppMessage({ message: data&& data.status && data.status.message, header: 'Success', type: 'alert' }))
      return Promise.resolve(data);
    }
    catch(err){
      dispatch(setAppMessage({ message: err && err.data&& err.data.status && err.data.status.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}

export  function fetchDomainDetails() {
  return async (dispatch, getState, apiHandler) => {
    const {api, apiRoutes} = apiHandler;
    const { activeDomain } = getState().landing;
    try {
    let { data:domainDetail } = await api(apiRoutes.getDomainDetailsApi(activeDomain));
    return Promise.resolve(domainDetail);
    } catch (err) {
      dispatch(setAppMessage({ message: err.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}

export function setDomainDetails(payload,showPreview){
return async (dispatch, getState, apiHandler) => {
  const {api, apiRoutes} = apiHandler;
  const { activeDomain } = getState().landing;
  try {
  let { data } = await api(apiRoutes.updateDomainDetailsApi(payload,activeDomain));
  dispatch(setAppMessage({ message: 'You can preview your page to make sure everything looks right',
   header: 'Subscription Page changes are updated', leftButtonText :'preview' , rightButtonText :'close',
   handleFailure : showPreview,
  }));
  return Promise.resolve(data);
  } catch (err) {
    dispatch(setAppMessage({ message: err.data.error, header: 'Error', type: 'alert' }));
    return Promise.reject(err);
  }
}
}

export function setDefaultTab (num){
  return async (dispatch, getState, apiHandler) => {
    const { api, apiRoutes,actionTypes } = apiHandler;
    dispatch({type:actionTypes.SUBSCRIBE.SET_TAB,data:num});
  }
}


export function fetchFeed(feedType="live", headerType = '',Component) {
  return async (dispatch, getState, apiHandler) => {
    const { api, apiRoutes ,actionTypes} = apiHandler;
    
    const { landing :{activeDomain} } = getState();
   
    try {
     
      let { data: ArticleFeed } = await api(apiRoutes.fetchArticles(activeDomain, feedType, headerType));
      return Promise.resolve(ArticleFeed);
    } catch (err) {
     
      dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}))
      return Promise.reject(err);
    }
  }
}

export function searchArticlesearch(formData) {
  return async(dispatch, getState, apiHandler) => {
    try {
      const { api, apiRoutes, actionTypes } = apiHandler;
      const { landing: { activeDomain },article:{page} } = getState();
      const {data} = await api(apiRoutes.searchArticleApi(formData, activeDomain));
      return Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}


export function updateArticleMeta (articleId, data) {
  return async (dispatch, getState, apiHandler) => {
    try {
      const { landing: { activeDomain }} = getState();
      const {api, apiRoutes } = apiHandler;
      await api(apiRoutes.updateArticleMetaApi(activeDomain, articleId, data));
      return Promise.resolve({ updated: true});
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export function getLiveStreamPost (){
  return async(dispatch, getState, apiHandler) => {
    try {
      const { api, apiRoutes, actionTypes } = apiHandler;
      const { landing: { activeDomain },article:{page} } = getState();
      const {data} = await api(apiRoutes.fetchLiveStreamPost(activeDomain));
      return Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export function unpublishProductById (id){
  return async(dispatch, getState, apiHandler) => {
    try {
      const { api, apiRoutes } = apiHandler;
      const { landing: { activeDomain }} = getState();
      const {data} = await api(apiRoutes.unpublishProduct(activeDomain, id));
      dispatch(setAppMessage({ message: data&& data.status && data.status.message, header: 'Success', type: 'alert' }))
      return Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export function publishProductById (id){
  return async(dispatch, getState, apiHandler) => {
    try {
      const { api, apiRoutes } = apiHandler;
      const { landing: { activeDomain }} = getState();
      const {data} = await api(apiRoutes.publishProduct(activeDomain, id));
      dispatch(setAppMessage({ message: data&& data.status && data.status.message, header: 'Success', type: 'alert' }))
      return Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export function unpublishPlanById (productId,planId){
  return async(dispatch, getState, apiHandler) => {
    try {
      const { api, apiRoutes } = apiHandler;
      const { landing: { activeDomain }} = getState();
      const {data} = await api(apiRoutes.unpublishPlan(activeDomain, productId,planId));
      dispatch(setAppMessage({ message: data&& data.status && data.status.message, header: 'Success', type: 'alert' }))
      return Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export function publishPlanById (productId,planId){
  return async(dispatch, getState, apiHandler) => {
    try {
      const { api, apiRoutes } = apiHandler;
      const { landing: { activeDomain }} = getState();
      const {data} = await api(apiRoutes.publishPlan(activeDomain, productId,planId));
      dispatch(setAppMessage({ message: data&& data.status && data.status.message, header: 'Success', type: 'alert' }))
      return Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export function editPlanAction(productId,payload){
  return async(dispatch, getState, apiHandler) => {
    try {
      const { api, apiRoutes } = apiHandler;
      const { landing: { activeDomain }} = getState();
      await api(apiRoutes.unpublishPlan(activeDomain, productId,payload.id));
      delete payload.id
      delete payload.status
      delete payload.subscriberCount
      delete payload.stripePlanId
      delete payload.currencySymbol
      
      const {data} = await api(apiRoutes.addPlan(activeDomain,payload));
      dispatch(setAppMessage({ message: "Plan successfully edited", header: 'Success', type: 'alert' }))
      return Promise.resolve(data);
    } catch (err) {
      console.log(err)
      dispatch(setAppMessage({ message: (err.data && err.data.status && err.data.status.message) || 'something went wrong ', header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}


export function createandattachBenefit(payload){
  return async(dispatch, getState, apiHandler) => {
    try {
      const { api, apiRoutes } = apiHandler;
      const { landing: { activeDomain }} = getState();
      const {data} = await api(apiRoutes.createandattatch(activeDomain, payload));
      dispatch(setAppMessage({ message: (data&& data.status && data.status.message) || 'Benefit created', header: 'Success', type: 'alert' }))
      return Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}