import React  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from './Button';
import PropTypes from 'prop-types';



//Action sheet 

/**
 * pass any content in ActionSheet Component it will render 
 * Open button behave like normal button , So you can pass any style 
 * Example :
 * <ActionSheet> 
    <div style={{display:'flex',justifyContent:'space-around',alignItems:'center',flexDirection:'column',height:'45vh',margin:'auto',width:'90%'}}>
      <Button variant ="contained" fullWidth style ={{height :'50px'}} >Image </Button>
      <Button variant ="contained" fullWidth style ={{height :'50px'}}  > Audio</Button>
      <Button variant ="contained"  fullWidth style ={{height :'50px'}}  > Video</Button>
      <Button variant ="contained"  fullWidth style ={{height :'50px'}}  > Article</Button>
    </div>
  </ActionSheet>
 */

const useStyles = makeStyles((theme) => ({
  fullList: {
    width: 'auto',
  },
  root: {
    borderTopLeftRadius: theme.spacing(2),
    borderTopRightRadius: theme.spacing(2),
    maxHeight: '60vh',
  },
}));
function ActionSheet(props) {

  const { type, buttonText, ...rest } = props;
  const classes = useStyles();

  const [state, setState] = React.useState({
    bottom: false,
  });

  const toggleDrawer = (side = "bottom", open) => event => {

    if (event && (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift'))) {
      return;
    }
    setState({ ...state, [side]: open });
  };

  return (
    <>
      <Button type={type} {...rest} onClick={toggleDrawer('bottom', true)}>{buttonText}</Button>
      <Drawer
        anchor="bottom"
        open={state.bottom}
        onClose={toggleDrawer('bottom', false)}
        classes={
          {
            paper: classes.root,
          }
        }
      >
        {typeof props.children == 'function' ? props.children(toggleDrawer("bottom", false)) : props.children}
      </Drawer>
    </>
  );
}

ActionSheet.propTypes = {
  type: PropTypes.string,
  buttonText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

ActionSheet.defaultProps = {
  type: '',
  buttonText: 'open action sheet',
}

export default ActionSheet;
