import React from 'react';
import Dialog from "commons/muiCustomComponents/Dailog";
import CloseIcon from '@material-ui/icons/Close';
import Button from "commons/muiCustomComponents/Button";
import Box from "commons/muiCustomComponents/Box";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  storyBackground: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: 'inherit',
    display: 'flex',
    justifyContent: 'center',
    zIndex: '1203',
    backgroundColor: 'black',
  },
  closeIcon: {
    position: 'absolute',
    right: theme.spacing(5),
    top: theme.spacing(7),
    color: 'white',
    cursor: 'pointer',
    zIndex: 1204,
  },
  deleteButton: {
    position: 'absolute',
    bottom: theme.spacing(2.5),
    display: 'flex',
  },
  storyImage: {
    objectFit: 'contain',
  },
  storyOverlay: {
    position: 'absolute',
    zIndex: 100,
    fontSize: theme.spacing(5),
    left: 0,
    width: '100%',
    padding: theme.spacing(9, 12),
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    fontWeight: '500',
    color: theme.palette.pubWhite,
    boxSizing: 'border-box',
  },
  previewBorder: {
    height: theme.spacing(1),
    backgroundColor: theme.palette.pubveryLightPinkTwo,
    borderRadius: theme.spacing(2),
    marginBottom: theme.spacing(5),
  },
}));

function Showstory(props) {
  const classes = useStyles();
  const {story, changeStory} = props;
  return (
    <Dialog fullScreen open={!!props.story}>
    <Box className={classes.storyBackground}>
      <img className={classes.storyImage} src={story.storyUrl} alt="story" />
      <CloseIcon className={classes.closeIcon} onClick={()=>{changeStory(null)}} />
      <Box className={classes.deleteButton}>
        <Button variant="contained" onClick={()=>{props.deleteStory(story.id)}} >
         DELETE
        </Button>
      </Box>
      <Box className={classes.storyOverlay}>
        <Box className={classes.previewBorder}>
        </Box>
        {story.heading}
      </Box>
    </Box>
    </Dialog>
  )
}

export default Showstory;
