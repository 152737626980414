import React, {useState, useEffect,useContext} from "react";
//TODO: replace with local imported mui components
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from "commons/muiCustomComponents/Grid";
import Switch from '@material-ui/core/Switch';
import {makeStyles, Box} from "@material-ui/core";
import setAppMessage from "app-component/app-component-action";
import { withStyles } from '@material-ui/core/styles';
import { 
  fetchUserDomainPermissions, 
  fetchSimplifiedPermissionList,
  updateDomainPermissions,
 } from "./user-list-action";
import { useParams} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LinearLoader, PageBlockLoader } from "commons/commonWrapper/Loaders";
import Paper from "commons/muiCustomComponents/Paper";
import MobileProvider from "commons/context/MobileProvider";
import { LogglyTracker } from 'loggly-jslogger';

const logger = new LogglyTracker();
logger.push({ 'logglyKey': '142d21f5-10d3-47a9-aa5a-f83e0a3abed7', 'sendConsoleErrors' : true, 'tag' : 'ardbeg,user-management,user-permissions' });


const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
      backgroundColor:'#ebf5ff',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: '0px',
    backgroundColor:'#f8f8f8',
  },
}))(MuiExpansionPanelDetails);

const useStyles = makeStyles(theme => ({
  addUserSection: {
    height:'100px',
    display:'flex',
    alignItems:'center',
  },
  addUserButton: {

  },
  child :{
    height:'100%',
  },
  heading: {
    textDecoration:"underline",
    fontWeight:'800',
    fontSize: theme.typography.pxToRem(15),
    padding: theme.spacing(2),
  },
  listItem: {
    padding:`${theme.spacing(4)}px ${theme.spacing(1)}px`,
    borderBottom:'1px solid white',
  },
  titleItem: {
    display:'flex',
    paddingLeft:theme.spacing(10),
    alignItems:'center',
  },
  descItem: {
    width:'80%',
    paddingLeft:theme.spacing(10),
    color:theme.palette.pubbrownGrey,
    fontSize: theme.isMobile ? theme.spacing(3): theme.spacing(4),
  },
  detailPanel: {
    padding:'0px',
  },
  switchSection:{
    display:'flex',
    justifyContent:'center',
  },
}));

function UserPermissionHome (props) {
  const params = useParams();
  const dispatch = useDispatch();
  const classes = useStyles();
  const isMobile = useContext(MobileProvider);
  const { emailId } = params;
  const { usersManage: { userPermissions }, landing: { userProfile }}  = useSelector(state => state);

  useEffect(() => {
    fetchPermissonsAndCreateMap();
  },[]);

  const [fetchingData, setFetchingData] = useState(false);
  const [updatingPerm, setUpdatingPerm] = useState(false);
  const [create, setCreate] = useState([]);
  const [earn, setEarn] = useState([]);
  const [share, setShare] = useState([]);
  const [setting, setSetting] = useState([]);

  const handlePermissionChange = async (event) => {
    event.persist();
    try {
      setUpdatingPerm(true);
      await dispatch(updateDomainPermissions(event, emailId));
      setUpdatingPerm(false);
    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      dispatch(setAppMessage({ message: err.message || `Unable to change permission.`, header: 'Error', type: 'alert' }));
      setUpdatingPerm(false);
    }
  }

  async function fetchPermissonsAndCreateMap() {
    try {
      setFetchingData(true);
      let res = await Promise.all([
        dispatch(fetchUserDomainPermissions(emailId)),
        dispatch(fetchSimplifiedPermissionList()),
      ]);
      let sp = res[1];
      let tempCreate = [];
      let tempEarn = [];
      let tempShare = [];
      let tempSetting = [];
      sp.forEach((item) => {
        if(item.perm) {
          let key = item.perm.split(":")[0].toLowerCase();
          if(key === 'create') tempCreate.push(item);
          if(key === 'earn') tempEarn.push(item);
          if(key === 'share') tempShare.push(item);
          if(key === 'setting') tempSetting.push(item);
        }
      });
      setCreate(tempCreate);
      setEarn(tempEarn);
      setShare(tempShare);
      setSetting(tempSetting);
    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      // do error handling
      dispatch(setAppMessage({ message: err.message || `Unable to fetch user permission.`, header: 'Error', type: 'alert' }));
    } finally {
      setFetchingData(false);
    }
  }
  
  if(fetchingData) {
    return (
      <Grid container>
      <Grid item xs={12} md={9} xl={10}>
      {isMobile && <LinearLoader/>}
      {!isMobile && <PageBlockLoader/>}
      </Grid></Grid>
    )
  }
  return (
    <>
    <Grid container>
      <Grid item xs={12} md={9} xl={10}>
          <Box m ={isMobile ?2 : 0} mt={4} mb={4} >
            <Paper>
      {updatingPerm && <LinearLoader/>}
      <ExpansionPanel TransitionProps={{ unmountOnExit: true }}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>CREATE</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container>
            {create.map(item => {
                return (
                  <React.Fragment key={`${item.perm}_content`}>
                  <Grid item xs={9} md={10}  className={classes.titleItem}>
                    <Box>
                      <Box>{item.title}</Box>
                    </Box>
                  </Grid>
                  <Grid item xs={3} md={2} className={classes.switchSection}>
                    <Box >
                        <Switch
                          color="primary"
                          disabled={updatingPerm}
                          checked={userPermissions.indexOf(item.perm) > -1}
                          onChange={handlePermissionChange}
                          value={item.perm}
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12} className={classes.listItem} >
                    <Box className={classes.descItem}>{item.desc}</Box>
                  </Grid>
                  </React.Fragment>
                )
              })
            }
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel TransitionProps={{ unmountOnExit: true }}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>EARN</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
        <Grid container>
            {
              earn.map(item => {
                return (
                  <React.Fragment key={`${item.perm}_content`}>
                  <Grid item xs={9} md={10} className={classes.titleItem} >
                    <Box>
                      <Box>{item.title}</Box>
                    </Box>
                  </Grid>
                  <Grid item xs={3} md={2}>
                    <Box >
                        <Switch
                          color="primary"
                          disabled={updatingPerm}
                          checked={userPermissions.indexOf(item.perm) > -1}
                          onChange={handlePermissionChange}
                          value={item.perm}
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12} className={classes.listItem} >
                    <Box className={classes.descItem}>{item.desc}</Box>
                  </Grid>
                  </React.Fragment>
                )
              })
            }
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel TransitionProps={{ unmountOnExit: true }}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>SHARE</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
        <Grid container>
            {
              share.map(item => {
                return (
                  <React.Fragment key={`${item.perm}_content`}>
                  <Grid item xs={9} md={10}  className={classes.titleItem}>
                    <Box>
                      <Box>{item.title}</Box>
                    </Box>
                  </Grid>
                  <Grid item xs={3} md={2}>
                    <Box >
                        <Switch
                          color="primary"
                          disabled={updatingPerm}
                          checked={userPermissions.indexOf(item.perm) > -1}
                          onChange={handlePermissionChange}
                          value={item.perm}
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12} className={classes.listItem} >
                    <Box className={classes.descItem}>{item.desc}</Box>
                  </Grid>
                  </React.Fragment>
                )
              })
            }
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel TransitionProps={{ unmountOnExit: true }}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>SETTING</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.detailPanel}>
        <Grid container>
            {
              setting.map(item => {
                return (
                  <React.Fragment key={`${item.perm}_content`}>
                  <Grid item xs={9} md={10}   className={classes.titleItem}>
                    <Box>
                      <Box>{item.title}</Box>
                    </Box>
                  </Grid>
                  <Grid item xs={3} md={2}>
                    <Box >
                        <Switch
                          color="primary"
                          disabled={updatingPerm}
                          checked={userPermissions.indexOf(item.perm) > -1}
                          onChange={handlePermissionChange}
                          value={item.perm}
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12} className={classes.listItem} >
                    <Box className={classes.descItem}>{item.desc}</Box>
                  </Grid>
                  </React.Fragment>
                )
              })
            }
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      </Paper>
          </Box>
      </Grid>
    </Grid>
    </>
  )
}

export default UserPermissionHome;