/**
 * This action file is created for common component like Search
 */

const commonComponent = {
  SEARCH: {
    FETCH: {
      REQUEST: 'commonComponent.SEARCH.FETCH.REQUEST',
      FAILURE:'commonComponent.SEARCH.FETCH.FAILURE',
      SUCCESS:'commonComponent.SEARCH.FETCH.SUCCESS',
    },
    CLEAR: {
      REQUEST: 'commonComponent.CLEAR.FETCH.REQUEST',
      FAILURE:'commonComponent.CLEAR.FETCH.FAILURE',
      SUCCESS:'commonComponent.CLEAR.FETCH.SUCCESS',
    },
    RESET :{
      META : 'commonComponent.RESET.META',
    },
    SET :{
      META : 'commonComponent.SET.META',
    },
    DELETE :{
      ARTICLE :'commonComponent.DELETE.ARTICLE',
    },
  },
}

export default commonComponent;