import setAppMessage from "../../app-component/app-component-action";

export function doAllAuthDataFetch() {

  return async (dispatch, getState, apiHandler) => {
    try {
      const { userApi, api, apiRoutes } = apiHandler;
      const {data: userDetails} = await userApi(apiRoutes.fetchUserApi());
      let {activeDomain} = populateUserInfo(userDetails);
      if (!activeDomain) {
        return Promise.resolve({hasDomain:!!activeDomain});
      }
      if(activeDomain &&  (sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id'))) {
        activeDomain = parseInt(sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id'));
      }
      const {data: userProfile} = await userApi(apiRoutes.fetchUserProfileApi(activeDomain));
      userProfile.domainObjs = userDetails.domainObjs;
      sessionStorage.setItem("user", JSON.stringify(userProfile));
      window.Intercom && window.Intercom('update', { email: userProfile.email, user_id: userProfile.id, name: userProfile.email });
      let data = {activeDomain, userProfile};
      if(activeDomain) {
        
        let [{ data: userPermissions }, {data:featurePermissions}] = await Promise.all(
          [
            api(apiRoutes.fetchUserPermissionsApi(activeDomain)),
            api(apiRoutes.fetchDomainFeaturePermissionsApi(activeDomain)),
          ]);
        data = {...data, userPermissions, featurePermissions};
        if(!featurePermissions.includes('QUICK_CMS')) {
          dispatch(CompleteAuthDataFetchSuccess("isAllAuthSuccess", data));
          return Promise.resolve({hasDomain:true, notQuickCms:true});
        } else {
          dispatch(CompleteAuthDataFetchSuccess("isAllAuthSuccess", data));
          return Promise.resolve({hasDomain:true, notQuickCms:false, userPermissions, featurePermissions});
        }
      }
      
      dispatch(CompleteAuthDataFetchSuccess("isAllAuthSuccess", data));
      //check if active domain is available and based on that fetch the permisison skip
      return Promise.resolve({hasDomain:!!activeDomain});
    } catch (err) {
      console.log("Err", err);
      dispatch(CompleteAuthDataFetchError("isAllAuthFail"));
      return Promise.reject(err);
    }
  }
}

export function fetchCategories() {
  return async(dispatch, getState, apiHandler) => {
    const { api, apiRoutes, actionTypes } = apiHandler;
    try {
      const { landing: { activeDomain } } = getState();
      dispatch({type:actionTypes.CATEGORY.REQUEST});
      const {data:{ section}} = await api(apiRoutes.getFullSection(activeDomain));
      dispatch(fetchCategoriesSuccess(actionTypes.CATEGORY.SUCCESS,section));
      return Promise.resolve();
    } catch (err) {
      dispatch({type:actionTypes.CATEGORY.FAILURE});
      return Promise.reject();
    }
  }
}

export function fetchUserProfile(domainId) {
  return async(dispatch, getState, apiHandler) => {
    const { userApi, apiRoutes, actionTypes } = apiHandler;
    try {
      const {data: userProfile} = await userApi(apiRoutes.fetchUserProfileApi(domainId));
      dispatch(profileFetchSuccess(userProfile));
      return Promise.resolve();
    } catch (err) {
      dispatch({type:actionTypes.CATEGORY.FAILURE});
      return Promise.reject();
    }
  }
}
export function updateUser (payload,avator,cover){
  return async(dispatch, getState, apiHandler) => {
    try {
      const { userApi, apiRoutes } = apiHandler;
      const { landing: { activeDomain } } = getState();
      const {data} =await userApi(apiRoutes.updateUserInfoApi(activeDomain, payload));
      dispatch(updateUerInfoAction("updateUserInfo",payload,avator,cover));
      dispatch(setAppMessage({ message: data.message, header: 'Success', type: 'alert' }))
      return Promise.resolve();
    } catch (err) {
      dispatch(setAppMessage({ message: err.data.message, header: 'Error', type: 'alert' }))
      return Promise.reject();
    }
  }
}

function profileFetchSuccess(userProfile) {
  return {
    type: 'updateUserProfile',
    data: {userProfile},
  }
}
function fetchCategoriesSuccess(actionName, data) {
  return {
    type:actionName,
    data,
  }
}

function CompleteAuthDataFetchSuccess(actionName, data) {
  return {
    type: actionName,
    data,
  }
}

function CompleteAuthDataFetchError(actionName) {
  return {
    type: actionName,
  }
}

function populateUserInfo(userInfo) {
  const { domains:[activeDomain]} = userInfo;
  return {
    activeDomain,
    userInfo,
  }
}

function updateUerInfoAction (type,payload,avator,cover){
  return {
    type,
    payload,
    avator,
    cover,
  }
} 
