import React, { useState, useEffect,Fragment } from "react";
import RootRef from '@material-ui/core/RootRef';
import { makeStyles } from '@material-ui/core/styles';
import Box from "commons/muiCustomComponents/Box";
import Button from "commons/muiCustomComponents/Button";
import withFileUpload from "commons/hocs/with-file-upload";
import ProgressDialog from "commons/commonWrapper/ProgressDialog";
import { useDispatch, useSelector } from "react-redux";
import MobileProvider from "commons/context/MobileProvider";
import AddStoryForm from './add-story-form.js';
import {
  fetchStoriesAction,
  saveMediaToPiscoAction,
  addNewStoryAction,
  deleteStoryAction,
  setStoryWidthAction,
} from '../story-action.js';
import ShowStory from './show-story.js';
import Paper from "commons/muiCustomComponents/MuiPaper";
 import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import clsx from "clsx";
import { LogglyTracker } from 'loggly-jslogger';

const logger = new LogglyTracker();
logger.push({ 'logglyKey': '142d21f5-10d3-47a9-aa5a-f83e0a3abed7', 'sendConsoleErrors' : true, 'tag' : 'ardbeg,story' });



const useStyles = makeStyles(theme => ({

  storyButton: {
    // background: 'linear-gradient(to bottom, #fad961, #f76b1c)', // unique css for story
    // boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2)', // unique css for story
    margin: theme.spacing(3 / 4),
    cursor: 'pointer',
    boxShadow:'none',
  },
  storyContainer: {
    // borderStyle: 'dashed',
    borderColor: theme.palette.primary.dark,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom:theme.spacing(2),
    marginTop:theme.spacing(6),
    marginLeft:theme.spacing(0),
    marginRight:theme.spacing(4),
    padding: theme.spacing(3/4),
    boxSizing: 'border-box',
    cursor: 'pointer',
    
  },
  storyImage: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'cover',
    borderRadius: '50%',

  },
  storyTitle: {
    textOverflow: 'ellipsis',
    whiteSpace: 'pre',
    overflow: 'hidden',
    maxWidth: '66px',
    marginTop:theme.spacing(2 * 0.25),
    textAlign: 'center',
    fontSize: theme.spacing(3),
    color: theme.palette.primary.dark,
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 'calc(100% - 16px)',
    textTransform:'capitalize',
  },
  addStoryButton: {
    display: 'inline-block',
    border: 2,
    borderColor: theme.palette.pubStoryText,
    borderRadius: '50%',
    boxShadow:'none',
  },
  addStoryTitle: {
    textAlign: 'center',
    color: theme.palette.pubStoryText,
  },
  storyText: {
    color: theme.palette.pubStoryText,
    fontSize: theme.spacing(3),
    textAlign: 'center',
  },
  cardShadow: {
    // display:'none',
    boxShadow: '0 0 6px 0 rgba(34, 54, 78, 0.06)',
    flexGrow: 1,
    // [theme.breakpoints.between('600px','750px')]:{
    //   maxWidth:'650px',
    // },
    // [theme.breakpoints.up('md')]: {
    //   maxWidth:'960px',
    // },
    // [theme.breakpoints.up('xl')]: {
    //   maxWidth:'1350px',
    // },
  },
  mobileStory :{
    margin :'auto',
    marginLeft:theme.spacing(2),
    marginRight:theme.spacing(2),
  },
  tabCss :{
    padding:'0px',
    margin:'0px',
    opacity:1,
    minWidth:'unset',
  },
  tabWraper :{
    alignItems:'start',
  },
  tabRoot :{
    minWidth:'unset',
    padding:theme.spacing(0),
  },
}));

const AddStoryButton = (props) => {
  const classes = useStyles();
  return (
    <Box>
      <Button type="addfab"
        color="secondary"
        disableFocusRipple = {true}
        disableRipple = {true}
        boxProps ={
          {
            mt:6,
            mr:4,
            mb:2,
          }
        }
        style={{boxShadow:"none",width:'55px',height:'55px'}}
      />
      <Box className={classes.storyTitle}>Add story</Box>
    </Box>
  );
}

const FileUploaderButton = withFileUpload(AddStoryButton);

function Story(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobile = React.useContext(MobileProvider);
  const { landing:{
    userPermissions,
    userProfile,
  }} = useSelector(state=>state);
  const storyRef = React.useRef();
  const [step, setStep] = useState(0);
  const [showStory, setShowStory] = useState(null);
  const [showStoryModal, setShowStoryModal] = useState(false);
  const [content, setContent] = useState({});
  const [imageSrc, setImageSrc] = useState(null);
  const [imageTitle, setImageTitle] = useState(null);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showProgressDialog, setShowProgressDialog] = useState(false);
  const [storyWidth ,setStoryWidth] = useState('');

  const { landing: { activeDomain }, story: { storyFeed ,storyRealWidth} } = useSelector(state => state);
  
  useEffect(() => {
    dispatch(fetchStoriesAction());
  }, [activeDomain]);

  useEffect(()=>{
    if (!storyRealWidth && storyRef.current !== null) {
      const innerStoryWidth =  storyRef.current.getBoundingClientRect().width;
      setStoryWidth(innerStoryWidth);
      dispatch(setStoryWidthAction(innerStoryWidth))
     }
     if (storyRealWidth) {
       setStoryWidth(storyRealWidth);
     }
  },[activeDomain,storyRealWidth]);

  const handleFileUpload = (target, type) => {
    const file = target && target.files[0];
    const uploadType = type && type.split("/")[0];
    const extension = file && file.type.split("/")[1];
    setStep(0);
    if (file && type && !(file.type.indexOf(uploadType) > -1)) {
      //this.props.InvalidFileError();
      return;
    }
    if (uploadType === 'image' && extension === 'webp') {
      //this.props.InvalidFileError('Webp images are not allowed, as not supported by Safari browser.');
      return;
    }
    if(!file || !file.name) {
      return;
    }
    setShowStoryModal(true);
    setStep(1);
    saveMediaToPisco(file);
  }

  const saveMediaToPisco = (file) => {
    dispatch(saveMediaToPiscoAction(file)).then(res => {
      setContent(res.localUploadResponse);
      setImageSrc(res.preSaveResponse.data.s3Url);
      setImageTitle(res.localUploadResponse.data.title);
      setStep(2);
    })
      .catch(err => {
        const data = {
          'error': err,
          'email': (userProfile||{}).email || '',
          'url': (window.location||{}).href || '',
          'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
          'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
        };
        logger.push({
            'type': 'error',
            'data': data,
          });
        setStep(0);
      })
  }

  const addNewStory = (croppedAreaPixels, heading, actionLink) => {
    setDisableSubmitButton(true);
    setIsProcessing(true);
    setShowProgressDialog(true);
    dispatch(addNewStoryAction(content, croppedAreaPixels, heading, actionLink, afterAdd));
  }

  const afterAdd = () => {
    setStep(0);
    setIsProcessing(false);
    setTimeout(() => setShowProgressDialog(false), 2000);
    setDisableSubmitButton(false);
  }

  const changeStory = (story) => {
    setShowStory(story);
  }

  const deleteStory = (storyId) => {
    setShowProgressDialog(true);
    setIsProcessing(true);
    dispatch(deleteStoryAction(storyId, afterDelete));
  }

  const afterDelete = () => {
    changeStory(null);
    setStep(0);
    setIsProcessing(false);
    setTimeout(() => setShowProgressDialog(false), 2000);
    setDisableSubmitButton(false);
  }

  const closeAddStoryDialog = () => {
    setStep(0);
    setShowStory(null);
    setContent({});
    setImageSrc(null);
    setImageTitle(null);
    setDisableSubmitButton(false);
    setShowStoryModal(false);
  }

  return (
    <RootRef rootRef={storyRef}>

    <Paper className={clsx(classes.cardShadow,{[classes.mobileStory] : isMobile})} style={{maxWidth:storyWidth}}>
      
      <Tabs
        variant="scrollable"
        scrollButtons="on"
        value={false}
        component="div"
      >
        {
          ((userPermissions.indexOf('CREATE:WRITE') > -1) || (userPermissions.indexOf('CREATE:PUBLISH') > -1)) &&
         <Tab 
         classes ={{root:classes.tabRoot}}
         component="div"
         label={ <FileUploaderButton  
          type="image/*" style={{boxShadow:'none'}} 
          onChange={handleFileUpload}>Upload File</FileUploaderButton>}
         />
        }
  
        {
          storyFeed && storyFeed.length > 0 && storyFeed.map((item, key) => {
            return (
              <Tab
                key={key}
                disableRipple
                classes={{
                  root :classes.tabCss,
                  wrapper : classes.tabWraper,
                }}
                label={
                  <Box className={classes.storyTitle} >
                    {item.heading}
                  </Box>}
                onClick={() => changeStory(item)}
                icon={
                  <Box
                    width={55}
                    height={55}
                    border={2}
                    borderRadius={'50%'}
                    className={classes.storyContainer}
                  >
                    <img
                      src={item.titleUrl}
                      alt="click to view story"
                      className={classes.storyImage}
                    />
                  </Box>
                } />
            );
          })
        }
        {
          (step >= 1)
          && showStoryModal &&
          <AddStoryForm
            image={imageSrc}
            imageTitle={imageTitle}
            step={step}
            setStep={setStep}
            addNewStory={addNewStory}
            disableSubmitButton={disableSubmitButton}
            closeDialog={closeAddStoryDialog}
          />
        }
        {
          showStory
          &&
          <ShowStory
            story={showStory}
            changeStory={changeStory}
            deleteStory={deleteStory}
          />
        }
        {
          showProgressDialog

          &&
          <ProgressDialog open={showProgressDialog} isProcessing={isProcessing} />
        }
      </Tabs>
    </Paper>
    </RootRef>
  )
}

export default Story;
