import React, { useState, useContext, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from "../../../commons/muiCustomComponents/Box";
import InputBox from "../../../commons/muiCustomComponents/InputBox";
import Button from "../../../commons/muiCustomComponents/Button";
import { CircularLoader } from '../../../commons/commonWrapper/Loaders';
import Footer from "commons/commonWrapper/Footer";
import MobileProvider from "commons/context/MobileProvider";
import kebabCase from "lodash.kebabcase";
import CategoriesList from "./CategoriesList";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import setAppMessage from "app-component/app-component-action";
import { useSelector,useDispatch } from "react-redux";
import ImageIcon from '@material-ui/icons/Image';
import AddIcon from '@material-ui/icons/Add';
import WithFileUpload from "commons/hocs/with-file-upload";
import CropImageDialog from "../../content/CroppingDialog";
import DeleteIcon from '@material-ui/icons/Delete';


const uploadImage = () => {
  return (
    <Box width={170} style={{
      border: '1px solid #e5e5e5',
      cursor: 'pointer',
      width: '170px !important', height: '90px',
    }} bgcolor="#e5e5e5">
      <Box width="100%" height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <Box position="relative">
          <AddIcon style={{
            position: 'absolute',
            top: '-7px',
            left: '7px',
          }} />
          <ImageIcon />
        </Box>
        <Box component="span" fontSize={12}> UPLOAD IMAGE </Box>
      </Box>
    </Box>
  )
}

const FileUploaderButton = WithFileUpload(uploadImage);



const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    border: `1px solid ${theme.palette.pubveryLightPinkTwo}`,
    backgroundColor: theme.palette.pubGreywhite,
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  textStyle: {
    color: theme.palette.pubBlack,
    textTransform: "capitalize",
    fontWeight: "500",
  },
}));

export default function SubCategoryForm({ activeSubCategory,
  clearPageSubData,
  addSubCat,
  editSubCat,
  categoryId,
  activeCategory,
  addSubSection ,
  setAddSubSection,
  toggleSubCat,
  setSubCategories,
}) {
  const classes = useStyles();
  const childRef = useRef();
  const [isLoading, setLoading] = useState(false);
  const { description = '', name = '', id, category, title ,thumbnail=""} = activeSubCategory;
  const [subDec, setSubDec] = useState(description);
  const [subCat, setSubCat] = useState(name);
  const [error, setError] = useState(false);
  const isMobile = useContext(MobileProvider);
  const [open, setOpen] = React.useState(false);
  const [subsubsection ,setsubsubsection] = useState('');
  const [subsubDec ,setSubSubDes] = useState('');
  const [subloader ,setSubLoader] = useState(false);
  const [edit ,setEdit] = useState(false);
  const [subsubId ,setSUbSUbId] = useState(false);
  const [headerType, setHeaderType] = useState(null);
  const [openThumbDialog, setThumbnailDialog] = useState(false);
  const [thumbnailContent, setthumbnailContent] = useState('');
  const [filename, setFileName] = useState(null);
  const [finalCroppedImage , setFinalCroppedImage] = useState(thumbnail);
  const [finalsubCroppedImage,setFinalSubCroppedImage] = useState('');
  const [isActiveSubSection , setActiveSubSection] = useState(false);

  const dispatch = useDispatch();

  useEffect(()=>{
    if(childRef &&childRef.current && childRef.current.getThumbnail()){
      isActiveSubSection ?
      setFinalSubCroppedImage(childRef &&childRef.current && childRef.current.getThumbnail())
      :setFinalCroppedImage(childRef &&childRef.current && childRef.current.getThumbnail())
    }
  },[childRef &&childRef.current && childRef.current.getThumbnail()])
 
  const handleClickOpen = () => {
    setOpen(true);
    setActiveSubSection(true);  

  };

  const handleClose = () => {
    setsubsubsection('');
    setSubSubDes('');
    setFinalSubCroppedImage('');
    setOpen(false);
  };



  const handleSubDec = (e) => {
    const value = e.currentTarget.value;
    setSubDec(value);
  }
  const handlesubSubDec = (e)=>{
    const value = e.currentTarget.value;
    setSubSubDes(value);
  }
  const handleSubCat = (e) => {
    const value = e.currentTarget.value;
    setSubCat(value);
    if (!value) { setError(true) }
    else { setError(false) }
  }


  const submitSubCat = async () => {

    const submitData = {
      name: subCat.trim(),
      slug: kebabCase(subCat.trim()),
      description:subDec||'',
      thumbnail:finalCroppedImage||'',
    }
    if (!subCat.trim()) {
      setError(true)
      return;
    }

    if (id) {
      try {
        setLoading(true)
        await editSubCat(id, submitData);
        activeCategory.sub_section && activeCategory.sub_section.forEach(element => {
          if (element.id === id) {
            element.name = subCat
            element.slug = kebabCase(subCat.trim())
            element.thumbnail = finalCroppedImage
            element.description = subDec
          }
        });
        setLoading(false)
        clearPageSubData()
      }
      catch (err) {
        setLoading(false)
      }
      return
    }

    if (categoryId) {
      try {
        const payload = {
          type: kebabCase(subCat),
          name: subCat.trim(),
          description:subDec||'',
          thumbnail:finalCroppedImage||'',
          parent_section: categoryId,
          hierarchy: 1,
          status: 1,
        }
        setLoading(true);
        const { metadata } = await addSubCat(payload)
        setAddSubSection(false);
        activeCategory.sub_section.push(metadata)
        setLoading(false)
        clearPageSubData()
      }
      catch (err) {
        setLoading(false)
      }

      return
    }

  }
  const handlesubsubsection = (e)=>{
    setsubsubsection(e.currentTarget.value)
  }
  const addsubsubsection  = async()=>{
    try {
      const payload = {
        type: kebabCase(subsubsection),
        name: subsubsection,
        description:subsubDec,
        thumbnail:finalsubCroppedImage,
        parent_section: activeSubCategory.id,
        hierarchy: 2,
        status: 1,
      }
      setSubLoader(true);
      const { metadata } = await addSubCat(payload);
      if (!activeSubCategory.sub_sub_section) {
        activeSubCategory.sub_sub_section = [];
      }
      setActiveSubSection(false)
      activeSubCategory.sub_sub_section.push(metadata);
      setsubsubsection('');
      setSubSubDes('');
      setFinalSubCroppedImage('');
      handleClose();
      setSubLoader(false)
    }
    catch (err) {
      dispatch(setAppMessage({ message: err.message ||'Error in subsubsection creation', header: 'Error', type: 'alert' }));

      setSubLoader(false)
    }
  }
  const editsubsubsection = (submitData) =>{
    try{ 
      handleClickOpen();
      setsubsubsection(submitData.name);
      setSubSubDes(submitData.description);
      setFinalSubCroppedImage(submitData.thumbnail)
      setSUbSUbId(submitData.id);
      setEdit(true);   
      setActiveSubSection(true);  
    }
    catch(err){
      console.log("err",err)
    }
  }

  const setEditsubsubsection =async ()=> {
    try {
      setSubLoader(true);
      await editSubCat(subsubId, {name:subsubsection,slug:kebabCase(subsubsection),description:subsubDec,thumbnail:finalCroppedImage});
      activeSubCategory.sub_sub_section && activeSubCategory.sub_sub_section.length > 0 &&
      activeSubCategory.sub_sub_section.forEach((item)=>{
        if (item.id === subsubId){
          item.name = subsubsection;
          item.thumbnail = finalsubCroppedImage;
          item.description = subsubDec;
        }
      })
      setActiveSubSection(false)
      setsubsubsection('');
      setSubSubDes('');
      setFinalSubCroppedImage('');
      handleClose();
      setSubLoader(false)
    }
    catch(err){
      setSubLoader(false)
    }
  }
  const handleFileUpload = (target, type) => {
    const allowed = ['jpg','jpeg', 'png','bmp'];
    const file = target && target.files[0];
    const uploadType = type && type.split("/")[0];
    const extension = file && file.type.split("/")[1];
    if (allowed.indexOf(extension) < 0) {
      dispatch(setAppMessage({ message: 'Unsupported media format.', header: 'Error', type: 'alert' }));
      return;
    }
    const { size } = file;
    const sizeInMb = size / 1000000;
    if (sizeInMb > 1024) {
      dispatch(setAppMessage({ message: 'image larger than 1GB not allowed.', header: 'Error', type: 'alert' }));

      return;
    }
    if (file && type && !(file.type.indexOf(uploadType) > -1)) {
      //this.props.InvalidFileError();
      return;
    }
    if (uploadType === 'image' && extension === 'webp') {
      //this.props.InvalidFileError('Webp images are not allowed, as not supported by Safari browser.');
      return;
    }
    if (!file || !file.name) {
      return;
    }
    setHeaderType(file.type);
    
    setFileName(file.name);

    const fr = new FileReader();
    fr.onload = (stream) => {
      setthumbnailContent(stream.target.result);
      setThumbnailDialog(true);
    }
    fr.readAsDataURL(file);


  }

  return (
    <>
    <CropImageDialog
        ref={childRef}
        open={openThumbDialog}
        setThumbnailDialog={setThumbnailDialog}
        thumbnailContent={thumbnailContent}
        name={filename}
      />
      <Box className={classes.root}>
        <Box>
          <InputBox
            error={error}
            heading="Subsection name"
            fullWidth
            color="secondary"
            value={subCat}
            onChange={handleSubCat}
            placeholder="Write here..."
            helperText={error ? "Subsection can't be empty" : ''}
            headingStyle={{
              fontSize: 14,
              color: "pubGrey",
              fontWeight: 500,
              mb: 1,
            }}
          />

        </Box>
        <Box>
          <InputBox
            heading="section description"
            fullWidth
            color="secondary"
            value={subDec}
            onChange={handleSubDec}
            multiline
            rows={5}
            placeholder="Write here..."
            headingStyle={{
              fontSize: 14,
              color: "pubGrey",
              fontWeight: 500,
              mb: 1,
            }}
          />
        </Box>
        <Box mt={3} display="flex" className={classes.thumbnailContainer}>
            <Box width={170} display="flex">{
              finalCroppedImage ? <>
              <img src={finalCroppedImage} width="100%" alt="thumbnail_image"/>
              <DeleteIcon onClick={()=>setFinalCroppedImage("")} />
              </>
              :
              <FileUploaderButton
              type="image/*"
              onChange={handleFileUpload}
            />}
          </Box>
        </Box>
        

        {
          !isMobile && <Box mb={3} mt={3} display="flex" >
            <Button disabled={isLoading} variant="contained" fullWidth onClick={submitSubCat}>Submit</Button>
            {isLoading && <CircularLoader height="auto" ml={1} size={20} />}
          </Box>
        }
      </Box>

      {
        isMobile && <Footer>
          <Box mb={3} mt={3} display="flex" width="80%">
            <Button disabled={isLoading} variant="contained" fullWidth onClick={submitSubCat}>Submit</Button>
            {isLoading && <CircularLoader height="auto" ml={1} size={20} />}
          </Box>
        </Footer>
      }
      {
        !addSubSection && <>
      <Box component="p">
        SubSubsection: {activeSubCategory && activeSubCategory.sub_sub_section && activeSubCategory.sub_sub_section.length}
      </Box>
      <Box >
        {activeSubCategory.sub_sub_section && activeSubCategory.sub_sub_section.length > 0 && <CategoriesList
          CategoriesArray={activeSubCategory.sub_sub_section} setCategories={editsubsubsection} />}
      </Box>
      </>
    }
      {
         !addSubSection && ( isMobile ? <>
          <Box height="73px"></Box>
          <Footer>
            <Box mb={3} mt={3} display="flex" >
              <Button disabled={isLoading} variant="contained" fullWidth onClick={handleClickOpen}>Add Sub sub section <ArrowRightAltIcon /></Button>
              {/* {isLoading && <CircularLoader height="auto" ml={1} size={20} />} */}
            </Box>
          </Footer>
        </> : <Box mb={3} mt={3} display="flex" >
            <Button disabled={isLoading} variant="contained" fullWidth onClick={handleClickOpen}>Add Sub sub section <ArrowRightAltIcon /></Button>
            {/* {isLoading && <CircularLoader height="auto" ml={1} size={20} />} */}
          </Box>)
      }
      <Dialog open={open} onClose={handleClose} maxWidth="md"  fullWidth aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add subsubsection</DialogTitle>
        <DialogContent>
          <InputBox
            autoFocus
            heading="subsubsection name"
            margin="dense"
            id="subsubsection"
            label="subsubsection"
            onChange={handlesubsubsection}
            value={subsubsection}
            placeholder="Write here..."
            fullWidth
          />
          <Box>
          <InputBox
            heading="subsubsection description"
            fullWidth
            color="secondary"
            value={subsubDec}
            onChange={handlesubSubDec}
            multiline
            rows={5}
            placeholder="Write here..."
            headingStyle={{
              fontSize: 14,
              color: "pubGrey",
              fontWeight: 500,
              mb: 1,
            }}
          />
        </Box>
        <Box mt={3} display="flex" className={classes.thumbnailContainer}>
            <Box width={170} display="flex">{
              finalsubCroppedImage ? <>
              <img src={finalsubCroppedImage} width="100%" alt="thumbnail_image"/>
              <DeleteIcon onClick={()=>setFinalSubCroppedImage("")} />
              </>
              :
              <FileUploaderButton
              type="image/*"
              onChange={handleFileUpload}
            />}
          </Box>
        </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="contained">
            Cancel
          </Button>
          <Button disabled={subloader} onClick={edit ?setEditsubsubsection :addsubsubsection} color="primary" variant="contained">
            {!edit ?'ADD':'EDIT'}
          </Button>
          {subloader && <CircularLoader size={20} height="auto"/> }
        </DialogActions>
      </Dialog>
    </>
  );
}
