import React, { useEffect, useState, useContext } from 'react';
import Box from "commons/muiCustomComponents/Box";
import { makeStyles } from '@material-ui/core/styles';
import Button from "commons/muiCustomComponents/Button";
import AddIcon from '@material-ui/icons/Add';
import ProdutList from "./ProductList";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.pubWhite,
  },
  uppercontainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },
  productBackground :{
    backgroundColor:theme.palette.secondary.light,
    border:'none',
    "&:hover,&:focus": {
      backgroundColor:theme.palette.secondary.light,
      border:'none',
    },
  },
}));


export default function Products() {
  const classes = useStyles();
  const history = useHistory();

  const manageProd = () => {
     history.push('/dash/subscripition/manage-product');
  }
  return (
    <>
      <Box mt={4} className={classes.root}>
        <Box bgcolor="pubPrimary" height="75px" className={classes.uppercontainer}>
          <Box component="span" color="primary.main" display="flex" flexDirection="column">
             Products
                {/* <Box component="span" fontSize={12} color="primary.main">
              Create a flexible list for your audience, add or customise benefits and create payment plans.
                </Box> */}
          </Box>
          <Button variant="outlined" className={classes.productBackground} onClick={manageProd} >
            <AddIcon fontSize="small" />
              Add Product
            </Button>
        </Box>
        <ProdutList />
      </Box>
    </>
  );
}
