
import React,{useImperativeHandle,forwardRef, useEffect,useRef} from "react";
import WithFileUpload from "commons/hocs/with-file-upload";
import Button from "commons/muiCustomComponents/Button";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    saveMediaToPisco,
  } from "../monetization-action";
import InputBox from "commons/muiCustomComponents/InputBox";
import InputAdornment from '@material-ui/core/InputAdornment';
import Box from "commons/muiCustomComponents/Box";
import CropImageDialog from "../../content/CroppingDialog";

import { LinearLoader, CircularLoader } from "commons/commonWrapper/Loaders";
import { Divider } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import VideoPlayer from 'react-video-js-player';
import setAppMessage,{uploadMediaAction,abortMediaAlert} from "app-component/app-component-action";
import ImageIcon from '@material-ui/icons/Image';
import AddIcon from '@material-ui/icons/Add';
import clsx from "clsx";
import CheckIcon from '@material-ui/icons/Check';
import { makeStyles } from '@material-ui/core/styles';

const WithUploadButton = WithFileUpload(Button);
const WithvideoUploadButton = WithFileUpload(Button);

const uploadImage = () => {
  return (
    <Box width={170} style={{
      border: '1px solid #e5e5e5',
      cursor: 'pointer',
      width: '170px !important', height: '90px',
    }} bgcolor="#e5e5e5">
      <Box width="100%" height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <Box position="relative">
          <AddIcon style={{
            position: 'absolute',
            top: '-7px',
            left: '7px',
          }} />
          <ImageIcon />
        </Box>
        <Box component="span" fontSize={12}> UPLOAD IMAGE </Box>
      </Box>
    </Box>
  )
}
const FileUploaderButton = WithFileUpload(uploadImage);

const useStyles = makeStyles(theme => ({
  inputRoot: {
    backgroundColor: theme.palette.pubWhite,
    "&:hover,&:focus": {
      backgroundColor: theme.palette.pubWhite,
    },
  },
  minHeight: {
    minHeight: '100%',
  },
  mobileMargin: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    width:'90%',
  },
  footer: {
    padding: theme.spacing(4),
    background: theme.palette.pubWhite,
    borderTop: '1px solid #e5e5e5',
    width: '100%',
    height: '100%',
  },
  bodyContainer: {
    position: 'relative',
    top: '75px',
  },
  cardDesgin: {
    border: '1px solid #e5e5e5',
    width: '170px !important',
    position: 'relative',
    height: '90px',
    borderRadius: theme.spacing(1),
    marginLeft: theme.spacing(3),
  },
  slected: {
    opacity: '0.2',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  unslected: {
    opacity: '0.7',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  checkIconClass: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    fontSize: theme.spacing(11),
    color: theme.palette.pubWhite,
  },
  mediaClass: {
    backgroundColor: 'grey',
  },
  marginLeft :{
    marginLeft:theme.spacing(0),
  },
  thumbnailContainer: {
    display: 'inline-flex',
    // overflow:'scroll',
  },
  wrapperClass :{
    overflowX: 'scroll',
    flex: '1 1 auto',
    display: 'inline-block',
    position: 'relative',
    whiteSpace: 'nowrap',
    width:'100%',
    "&:-webkit-scrollbar": {
      display: 'none',
      width:'0px',
    },
    msOverflowStyle:'none',
  },
}));


 function MediaUpload(props,ref){
    const dispatch = useDispatch();
    const classes = useStyles();
    const [requesting ,setRequesting] = useState(false);
    const [mediaContent ,setMediaContent] = useState(null);
    const { landing, header: { domainList } } = useSelector(state => state);
    const { activeDomain, userPermissions } = landing;
    const [fileURL,setFileURL] = useState(null);
    const [thumbURLArray ,setThumbURLArray] = useState([]);
    const [tileData, setTileData] = useState([1, 2, 3, 4]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [thumbURL ,setThumbURL] = useState(null);
    const [defaultThumb ,setDeafaultThumb] = useState(false);
    const [thumbIndex ,setThumbIndex] = useState(false);
    const [filename, setFileName] = useState(null);
    const [thumbnailContent, setthumbnailContent] = useState(null);
    const [openThumbDialog, setThumbnailDialog] = useState(false);
    const [onlYImage ,setOnlyImage] = useState(false);
    const childRef = useRef();

    useEffect(()=>{
      if(props.poster){
        setTileData([props.poster,2,3,4])
        setSelectedItem(0)
      }
    },[props.poster])

    useEffect(()=>{
      if(props.prodcutVideo){
        setFileURL(props.prodcutVideo)
      }
      if(props.prodcutImage){
        setMediaContent(props.prodcutImage)
      }
    },[props.prodcutImage,props.prodcutVideo])
    const uploadMediaContent = async(target, type) => {
        const allowed = ['jpg', 'jpeg', 'png','gif'];
        const file = target && target.files[0];
        const uploadType = type && type.split("/")[0];
        const extension = file && file.type.split("/")[1];
    
        if (allowed.indexOf(extension) < 0) {
          dispatch(setAppMessage({ message: 'Unsupported media format.', header: 'Error', type: 'alert' }));
          return;
        }
    
        const { size } = file;
        const sizeInMb = size / 1000000;
        if (sizeInMb > 500) {
          dispatch(setAppMessage({ message: 'image larger than 500MB not allowed.', header: 'Error', type: 'alert' }));
          return;
        }
        const fr = new FileReader();
        setFileName(file.name)
        if(extension==='gif'){
          let res = await dispatch(saveMediaToPisco(file));
          const { localUploadResponse: { cdn } } = res;
          setMediaContent(`https:${cdn}`)
        }
        else {
        fr.onload = (stream) => {
          setthumbnailContent(stream.target.result);
          setThumbnailDialog(true);
          setOnlyImage(true);
          // setContentBase64(stream.target.result);
        }
        
        fr.readAsDataURL(file);
      }
        // uploadMediaToPisco(file);
      }
      const uploadMediaToPisco = async (file) => {
    
        try {
          setRequesting(true);
          let res = await dispatch(saveMediaToPisco(file));
          const { localUploadResponse: { cdn, s3Url } } = res;
    
          setMediaContent(`https:${cdn}`)
          setRequesting(false);
        } catch (err) {
          setRequesting(false);
          const { message } = err;
          // dispatch(setAppMessage({ message: message || 'Unable to upload media.', header: 'Error', type: 'alert' }));
          // do error handling
        }
      }
    
  const uploadVideoContent = async (target, type) => {
        const allowed = ['mp4','mpeg','wmv','m4v','3gp','mkv','avi','mov','quicktime','x-ms-wmv','x-matroska','3gpp'];
        const file = target && target.files[0];
        let extension = file && file.type.split("/")[1];
        const { size } = file;
        if(!extension){
          extension = file.name && file.name.split('.').pop()
        }
        const sizeInMb = size / 1000000;
        if (sizeInMb > 1024 *10) {
          dispatch(setAppMessage({ message: 'Video larger than 10GB not allowed.', header: 'Error', type: 'alert' }));
          
          return;
        }
        if (allowed.indexOf(extension) < 0) {
          dispatch(setAppMessage({ message: 'Unsupported media format.', header: 'Error', type: 'alert' }));
        } else {
          // setFile(file);
    
          try {
          setRequesting(true);
          
          const data=  await dispatch(uploadMediaAction(file,{
            filename: file && file.name,
            filetype: extension && extension,
            type : 'post',
            domain_id : activeDomain,
            uniqueId : "PRODUCT_VIDEO",
           },'video'));
           let thumbnailResponse = data.thumbnailUrls;
           thumbnailResponse.length >0 && thumbnailResponse.forEach((url)=>{ 
             tileData.unshift(url);
             if (selectedItem !== null) {
               setSelectedItem(selectedItem + 1)
             }
             if (isNumber(tileData)) {
               tileData.pop()
             }
     
             setTileData([...tileData]);
     
           });
     
           thumbnailResponse.length >0 && setThumbURLArray(thumbnailResponse)
           
          //  setthumbMsg(true)
          // let thumbnailResponse = await dispatch(getVideoThumbnail(data.uploadedURL));
         
          
          setFileURL(data.hlsUrl);
          setRequesting(false);
        }
        catch(err){
          console.log("err",err)
          dispatch(setAppMessage({ message: err.message || 'Unable to save video.', header: 'Error', type: 'alert' }));
          setRequesting(false);
        }
          // uploadMediaToPisco(file);
        }
      }

      const setSelected = (item) => {
   
        if( thumbURLArray.includes(tileData[item])){
          setFileName(GetimageName(tileData[item]));
          setThumbIndex(item);
          setDeafaultThumb(true);
          setthumbnailContent(tileData[item]);
          setThumbnailDialog(true);
        }
        else {
          if (item === selectedItem) {
            setSelectedItem(null);
            return;
          }
          setSelectedItem(item);
        }
        
      }
    
      const handleFileUpload = (target, type) => {
        const allowed = ['jpg', 'jpeg','png','bmp'];
        const file = target && target.files[0];
        const uploadType = type && type.split("/")[0];
        const extension = file && file.type.split("/")[1];
        if (allowed.indexOf(extension) < 0) {
          dispatch(setAppMessage({ message: 'Unsupported media format.', header: 'Error', type: 'alert' }));
          return;
        }
        const { size } = file;
        const sizeInMb = size / 1000000;
        if (sizeInMb > 1024) {
          dispatch(setAppMessage({ message: 'image larger than 1GB not allowed.', header: 'Error', type: 'alert' }));
    
          return;
        }
        if (file && type && !(file.type.indexOf(uploadType) > -1)) {
          //this.props.InvalidFileError();
          return;
        }
        if (uploadType === 'image' && extension === 'webp') {
          //this.props.InvalidFileError('Webp images are not allowed, as not supported by Safari browser.');
          return;
        }
        if (!file || !file.name) {
          return;
        }
        const fr = new FileReader();
        setFileName(file.name)
        fr.onload = (stream) => {
          setthumbnailContent(stream.target.result);
          setThumbnailDialog(true);
        }
        fr.readAsDataURL(file);
  
      }
    
    useImperativeHandle(ref,()=>({image:mediaContent,video:fileURL,thumbnail:tileData[selectedItem]}))

    return (
      <>
      <CropImageDialog
        ref={childRef}
        open={openThumbDialog}
        setThumbnailDialog={setThumbnailDialog}
        thumbnailContent={thumbnailContent}
        setTileData={setTileData}
        tileData={tileData}
        name={filename}
        defaultThumb = {defaultThumb}
        thumbIndex = {thumbIndex}
        setThumbURL ={setThumbURL}
        setThumbURLArray = {setThumbURLArray}
        thumbURLArray = {thumbURLArray}
        setDeafaultThumb ={setDeafaultThumb}
        selectedItem = {selectedItem}
        setSelectedItem = {setSelectedItem}
        setFinalCroppedImage ={setMediaContent}
        setOnlyImage ={setOnlyImage}
        onlYImage = {onlYImage}
      />
      <Box mt={2} position="relative" >
              <Box>
                {fileURL&& <Box component="span" fontWeight="bold" mb={2}>Video </Box>}
              </Box>
              <Box>
                {mediaContent &&<Box component="span" fontWeight="bold" mb={2}>Image </Box>}
              </Box>
            {mediaContent || fileURL?
             <Box>
            <Box display="flex">
              { mediaContent && <>
              
              <Box width={150} height={150}   bgcolor="pubSecondary">
              <img src={mediaContent} alt="prodcut_media" width="100%" height="100%" style={{objectFit:'contain'}}/>
              </Box>
              </>
             
              }
             { fileURL && <>
              <VideoPlayer src={fileURL} width="279" height="152" controls>
              </VideoPlayer>
              </>
              
             }
              <DeleteIcon onClick={()=>{setMediaContent(null)
               setTileData([1, 2, 3, 4])
               setSelectedItem(null);
                setFileURL(null)}}/>
              
            </Box>
            <Box mb={2} mt={2}>
              {fileURL &&  <Box component="span" fontSize={14}fontWeight="bold">Thumbnail</Box> }
            </Box> 
            <Box display="flex" mt={3}>  
                {fileURL && <Box width={170}><FileUploaderButton
                      type="image/*"
                      onChange={handleFileUpload}
                    />
                    </Box>
                }
            {fileURL && tileData.map((tile, key) => {
                    return <Box key={key} className={clsx({ [classes.cardDesgin]: true })} >
                      {
                        tile && tile.toString().indexOf('http') == 0 ? <>
                          <img src={tile} alt="thumb-img" width="100%"
                            height="100%" style={{ objectFit: 'cover', cursor: 'pointer' }} onClick={() => setSelected(key)} />
                          {selectedItem !== null && selectedItem === key && <Box
                            className={classes.slected} width="100%" height="100%" bgcolor="pubBlack" onClick={() => setSelected(key)}>

                          </Box>}
                          {
                            selectedItem !== null && selectedItem === key && <CheckIcon className={classes.checkIconClass} onClick={() => setSelected(key)} />
                          }
                          {selectedItem !== null && selectedItem !== key &&
                            <Box width="100%" height="100%" bgcolor="pubWhite" className={classes.unslected} onClick={() => setSelected(key)} />}
                        </>
                          :
                          <Box width="100%" height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                            <ImageIcon style={{ opacity: '0.7' }} />
                          </Box>

                      }
                    </Box>

                  })}
              </Box>
            </Box>
            :<> { requesting ? <>
              <Box mb={2}>
              <Box component="span" fontSize={14} fontWeight="bold" >Uploading media ...</Box>
              </Box> 
              <Box display="flex" alignItems="center" bgcolor="pubSecondary" justifyContent="center" width={150} height={150}>
                <CircularLoader size={20} height="auto"/></Box>
                </>
              : <>
              <Box mb={2}>
              <Box component="span" fontSize={14} fontWeight="bold" >Upload image/video *</Box>
              </Box> 
              <Box display="flex" alignItems="center" bgcolor="pubSecondary" flexDirection="column" justifyContent="space-around" width={150} height={150}>
                <WithUploadButton
                  color="primary"
                  variant="contained"
                  type="image/*" onChange={uploadMediaContent}
                  >
                  IMAGE
                </WithUploadButton>
                <WithUploadButton
                color="primary"
                variant="contained"
                type="*" onChange={uploadVideoContent}
                >
                VIDEO
              </WithUploadButton>
               </Box>
               </>
            }
            </>
            }
            </Box>
            
            </>
    )
}

export default forwardRef(MediaUpload)

function isNumber(arr) {
  let res = false;
  arr.forEach((item) => {
    if (typeof item === 'number') {
      res = true;
      return;
    }
  })
  return res;
}

function GetimageName(url)
{
   if (url)
   {
      const extension = url.split('.').reverse()
      let m = url.toString().match(/.*\/(.+?)\./);
      if (m && m.length > 1)
      {
         return m[1]+'.'+extension[0];
      }
   }
   return "";
}

