import React, {useCallback, useContext} from "react";
import FullScreenPage from 'commons/commonWrapper/FullScreenPage';
import LiveSchedule from "../components/content/live/LiveSchedule";
import Box from "commons/muiCustomComponents/Box";
import MobileProvider from "commons/context/MobileProvider";


function LiveStreamHome(props) {
  const isMobile = useContext(MobileProvider);

  const barCallback = useCallback(() =>(<Box
    display="flex"
    alignItems="center"
  >Live Schedule</Box>),[]);
  return(
    <FullScreenPage BarComponent={barCallback} isDesktop={false} isCloseIcon={true}>
      {(appBarHeight) => <Box mt={isMobile ?0:6}><LiveSchedule appbarheight={appBarHeight} /> </Box>}
    </FullScreenPage>
  )
}

export default LiveStreamHome;
