import actions from "../../constants/actions";

const initalState = {
  isAllAuthComplete:false,
  userPermissions:[],
  activeDomain:null,
  userProfile:{},
  featurePermissions: [],
  sections:[],
  isSectionLoading :false,
};

/**
 * This reducer function handle the auth and permission related
 * functionalites
 * @param {*} state 
 * @param {*} action 
 */
export default function landingReducer (state=initalState, action) {
  
  switch(action.type) {
    case 'isAllAuthSuccess': {
      const {data: {userProfile, activeDomain, featurePermissions, userPermissions}} = action;
      return {
        ...state,
        isAllAuthComplete: true,
        userProfile,
        activeDomain,
        featurePermissions: featurePermissions || [],
        userPermissions: userPermissions || [],
      }
    }
    case 'UpdateActiveDomainAndPermission': {
      const {data: { activeDomain, featurePermissions, userPermissions}} = action;
      return {
        ...state,
        activeDomain,
        featurePermissions: featurePermissions || [],
        userPermissions: userPermissions || [],
      }
    }

    case 'updateUserProfile': {
      const {data: {userProfile}} = action;
      return {
        ...state,
        userProfile,
      }
    }
    case "updateUserInfo" :{
      const {payload,avator,cover} = action;
      state.userProfile = {
        ...state.userProfile,
        description :payload.get("description"),
        profilePicture :avator,
        coverPicture :cover,
        phoneNumber : payload.get("phoneNumber"),
        fbProfile : payload.get("fbProfile"),
        twitterProfile : payload.get("twitterProfile"),
        ownWebsite : payload.get("ownWebsite"),
      }
      return {
        ...state,
      }
    }
    case actions.CATEGORY.SUCCESS: {
      const { data } = action;
      return {
        ...state,
        sections :data,
        isSectionLoading : false,
      }
    }
    case actions.CATEGORY.REQUEST :{
      return {...state , isSectionLoading : true}
    }
    case actions.CATEGORY.FAILURE :{
      return {...state , isSectionLoading : false}
    }
    case 'isAllAuthFail':
      return {
        ...state,
        isAllAuthComplete:false,
      }
    default:
      return state;
  }
}