import React, {useEffect, useState} from "react";
import Dialog from "commons/muiCustomComponents/Dailog";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import {makeStyles} from '@material-ui/core/styles';
import {useDispatch, useSelector} from "react-redux";
import Grid from "commons/muiCustomComponents/Grid";
import Box from "commons/muiCustomComponents/Box";
import MobileProvider from "commons/context/MobileProvider";
import Header from "../components/Header";
import {useHistory} from "react-router-dom";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {LogglyTracker} from 'loggly-jslogger';
import Button from "commons/muiCustomComponents/Button";
import {deleteArticle, fetchRawFeed} from "../../articles/article-action";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import {CircularLoader} from "../../../commons/commonWrapper/Loaders";
import moment from "moment";
import Paper from "../../../commons/muiCustomComponents/Paper";
import setAppMessage from "../../../app-component/app-component-action";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import EmailPosts from "../../social/Newsletter/EmailPost";
import EmailList from "../../social/Newsletter/EmailList";
import Typography from "@material-ui/core/Typography";
import Bowser from "bowser";

const useStyles = makeStyles(theme => ({

  mobileTitle: {
    justifyContent: 'center',
    display: 'flex',
  },
  footerButtonDesktop: {},
  footerButtonMobile: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },
  stepSection: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: theme.spacing(25),
  },
  mobileFooterContainer: {
    padding: theme.spacing(4),
  },
  mobileFooterItem: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  imgContainer: {
    minHeight: `calc(100vh - 180px)`,
  },
  selectedImage: {
    objectFit: 'contain',
  },
  backgroundFullScreen: {
    backgroundColor: theme.palette.pubBackground,
  },
  mobileMargin: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  formControl: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  paddingControl: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  heighPowerFont: {
    fontWeight: 600,
  },
  currentLiveStatus: {
    backgroundColor: 'red',
    color: 'white',
    padding: '0.5em',
    textAlign: 'center',
    borderRadius: '0.2em',
  },
  leftRootButton: {
    flexGrow:1,
    maxWidth:'unset',
    borderRight:'1px solid white',
  },
  rightRootButton: {
    flexGrow:1,
    maxWidth:'unset',
    borderRight:'1px solid white',
  },
  RootButton :{
    flexGrow:1,
    maxWidth:'unset',
  },
  navlink: {
    textDecoration: 'none',
    color: theme.palette.pubGreyishBrown,
  },
  textClass: {
    textTransform: 'capitalize',
  },
  tabIndicator: {
    display: 'none',
  },
  selected :{
    fontWeight:'bold',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

function LiveSchedule(props) {
  const classes = useStyles();
  const isMobile = React.useContext(MobileProvider);
  const dispatch = useDispatch();
  const history = useHistory();
  const [isDirty, setIsDirty] = useState(false);
  const [subCategory, setSubcategory] = useState(null);
  const [metaData, setMetaData] = useState({});
  const [showSelectDialog, setShowSelectDialog] = useState(true);
  const [description, setDescription] = useState('');
  const [articles, setArticles] = useState(null);
  const [showMetaDialog, setShowMetaDialog] = useState(false);
  const [showPreviewDialog, setShowPreviewDialog] = useState(false);
  const [showQuickShareDialog, setQuickShareDialog] = useState(false);
  const [requesting, setRequesting] = useState(true);
  const [savingTitle, setSavingTitle] = useState(false);
  const [savingMeta, setSavingMeta] = useState(false);
  const [savingArticle, setSavingArticle] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const {landing, header: {domainList}} = useSelector(state => state);
  const {categories, activeDomain, userPermissions, userProfile} = landing;
  const domainInfo = domainList[activeDomain] && domainList[activeDomain][0];
  const [yCategory, setYCategory] = useState([])
  const [liveMap, setLiveMap] = useState(null);
  const [canOthersGoLive, setCanOthersGoLive] = useState(true);
  const [isLoading, setIsloading] = useState(true);
  const [activeLive, setActiveLive] = useState(null);
  const [currentDraft, setCurrentDraft] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [recentFeed, setRecentFeed] = useState([]);
  const currentUserId = JSON.parse(sessionStorage.getItem('user')).id;

  const [articleIdToBeDeleted, setArticleIdToBeDeleted] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [emailCheck, setEmailCheck] = useState(false);
  const browser = Bowser.getParser(window.navigator && window.navigator.userAgent);
  const logger = new LogglyTracker();

  // We are not using the logging in this file, the object is instantiated however
  logger.push({
    'logglyKey': '142d21f5-10d3-47a9-aa5a-f83e0a3abed7',
    'sendConsoleErrors': true,
    'email': ((landing||{}).userProfile||{}).email || '',
    'tag': 'live-meta-collection',
    'url': (window.location||{}).href || '',
    'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
    'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
  });

  useEffect(() => {
    loadScheduledPosts();
  }, []);

  const loadScheduledPosts = async () => {
    setIsloading(true);
    let feed = await (await dispatch(fetchRawFeed('live&isShowLive=0,1,2', 'liveStream')));
    let tempRecentFeed = [];
    let tempUpcomingFeed = [];
    let tempLiveMap = {};
    feed.result.forEach(a => {
      if (a.isShowLive == 2) {
        tempRecentFeed.push(a);
      } else if (a.isShowLive == 1) {
        if (a.streamingMeta) {
          if (a.streamingMeta.userId == currentUserId) {
            setCanOthersGoLive(false);
          }
          tempLiveMap[a.articleId] = a.streamingMeta.userId;
        }
      }
      if (a.isShowLive == 1 || a.isShowLive == 0) {
        tempUpcomingFeed.push(a)
      }
    });
    setRecentFeed(tempRecentFeed);
    setArticles(tempUpcomingFeed);
    setLiveMap(tempLiveMap);
    setIsloading(false);
  };

  const deleteLiveArticle = async () => {
    setShowDeleteModal(false);
    if (articleIdToBeDeleted) {
      setIsloading(true);
      await dispatch(deleteArticle(articleIdToBeDeleted, 4, emailCheck));
      loadScheduledPosts(true);
    } else {
      console.log('####### No article id set');
    }
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const openLive = (url)=>{
    if (browser.getOSName() =='iOS') {
      if (browser.getBrowserName()!='Safari'){
      dispatch(setAppMessage({ message: 'Do you still want to continue?',
      leftButtonText:'Cancel',
      rightButtonText:'Continue',
      handleSuccess: ()=>{
        history.push(url)
      }, header: 'Browser not compatible, please open it in Safari' }));
      return;
     }
     history.push(url);
     return;
    }
    if ((browser.getOSName() !='iOS'|| browser.getOSName()!='macOS') && browser.getBrowserName() != 'Chrome'){
      dispatch(setAppMessage({ message: 'Do you still want to continue?',
      leftButtonText:'Cancel',
      rightButtonText:'Continue',
      handleSuccess: ()=>{
        history.push(url)
      }, header: 'Browser not compatible, please open it in chrome' }));
      return;
    }
    history.push(url);
  }
  return (
    <>
      <Box mb={4} mt={isMobile?4:'unset'}>
        <Dialog open={showDeleteModal}  aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">ARE YOU SURE YOU WANT TO DELETE THE EVENT?</DialogTitle>
          <DialogContent>
            <Box display="flex" alignItems="center">
            <Checkbox
              checked={emailCheck}
              onChange={(e)=>{setEmailCheck(e.target.checked)}}
              name="checkedB"
              color="primary"
            />
            <Box component="span" color="primary.main" fontWeight="bold"
                fontSize={12}>{'Send email to notify your audience.'}</Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={()=>{setShowDeleteModal(false)}} color="primary">
                NO
              </Button>
            <Button onClick={()=>{deleteLiveArticle()}} color="primary">
              DELETE
            </Button>
          </DialogActions>
        </Dialog>
        <Grid container>
          <Grid item xs={12} md={9} xl={10}>
            <AppBar position="static" elevation={1} style={{ zIndex: 1, borderRadius: '6px', height: '40px', justifyContent: 'center' }}>
              <Tabs value={tabValue}
                    variant = 'scrollable'
                    classes={{ indicator: classes.tabIndicator }}
                    onChange={handleChangeTab} aria-label="simple tabs example">

                <Tab classes={{root:classes.rightRootButton,
                  selected :classes.selected,

                }}  label={'Upcoming Live'} {...a11yProps(0)} />


                <Tab classes={{root:classes.RootButton,
                  selected :classes.selected,
                }}  label={'Recent Live'} {...a11yProps(0)} />

              </Tabs>
            </AppBar>
            <TabPanel value={tabValue} index={0}>
              <Grid container justify="center">
                <Grid item md={12} xl={12} >
                  <Box mt={6}>
                    <Paper>
                      <Box>
                        <Button variant='contained' color='secondary'
                                onClick={() => openLive('/dash/live/metaCollection')}><Box>New Live Video</Box></Button>
                      </Box>
                    </Paper>
                  </Box>
                </Grid>
              </Grid>
              <Grid  container justify="center">
                <Grid style={{backgroundColor: "white", marginTop: "2em"}}  item xs={12} md={12} xl={12}>
                  {isLoading && <CircularLoader height="auto" size={20} mt={10} mb={10}/>}
                  {!isLoading && articles && articles.length > 0 &&
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.heighPowerFont}>Title</TableCell>
                          <TableCell className={classes.heighPowerFont}>Scheduled At</TableCell>
                          <TableCell className={classes.heighPowerFont}>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          articles.map(row => (
                            !row ? null :
                              <TableRow key={row.articleId}>
                                <TableCell component="th" scope="row" className={classes.wordBreak}>
                                  {row.title}
                                </TableCell>
                                <TableCell>
                                  {liveMap[row.articleId] ?
                                    <>
                                      <Box className={classes.currentLiveStatus}>
                                        Currently Live
                                      </Box>
                                    </>
                                    :
                                    <>
                                      {row.showTime && moment(row.showTime * 1000).format('hh:mm a, dddd, Do MMMM')}
                                    </>
                                  }
                                </TableCell>
                                <TableCell>
                                  {((canOthersGoLive && !liveMap[row.articleId]) || (!canOthersGoLive && (liveMap[row.articleId] == currentUserId))) &&
                                  <>
                                    <Button style={{marginRight: '5px', marginTop: '5px'}}
                                            onClick={() => {
                                              setArticleIdToBeDeleted(row.articleId);
                                              setShowDeleteModal(true);
                                            }}><DeleteIcon/></Button>
                                    <Button style={{marginRight: '5px', marginTop: '5px'}}
                                      onClick={() => openLive('/dash/live/metaCollection/' + row.articleId)}><EditIcon/></Button>
                                  </>
                                  }
                                  {canOthersGoLive && !liveMap[row.articleId] &&
                                  <Button style={{marginRight: '5px', marginTop: '5px', width: '100%'}} variant='contained' color='primary'
                                          onClick={() => openLive('/dash/live/transmission/' + row.articleId)}>Start Live Video</Button>
                                  }
                                  {!canOthersGoLive && (liveMap[row.articleId] == currentUserId) &&
                                  <Button style={{marginRight: '5px', marginTop: '5px', width: '100%'}} variant='contained' color='primary'
                                          onClick={() => openLive('/dash/live/transmission/' + row.articleId)}>Resume Live Video</Button>
                                  }
                                </TableCell>
                              </TableRow>
                          ))
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>}
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <Grid  container justify="center">
                <Grid style={{backgroundColor: "white", marginTop: "2em"}}  item xs={12} md={12} xl={12}>
                  {!isLoading && recentFeed && recentFeed.length == 0 &&
                    <Box mt={6} style={{ textAlign: 'center' }}>
                      <Paper>
                        <Box>
                          No recent live videos
                        </Box>
                      </Paper>
                    </Box>
                  }
                  {isLoading && <CircularLoader height="auto" size={20} mt={10} mb={10}/>}
                  {!isLoading && recentFeed && recentFeed.length > 0 &&
                  <TableContainer>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.heighPowerFont}>Title</TableCell>
                          <TableCell className={classes.heighPowerFont}>Scheduled At</TableCell>
                          <TableCell className={classes.heighPowerFont}>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          recentFeed.map(row => (
                            !row ? null :
                              <TableRow key={row.articleId}>
                                <TableCell component="th" scope="row" className={classes.wordBreak}>
                                  {row.title}
                                  {row.recordedLink ? <Box><a href={`${row.recordedLink}`} target="_blank">Download</a></Box>: ''}
                                </TableCell>
                                <TableCell>
                                  {row.showTime && moment(row.showTime * 1000).format('hh:mm a, dddd, Do MMMM')}
                                </TableCell>
                                <TableCell>
                                  {((canOthersGoLive && !liveMap[row.articleId]) || (!canOthersGoLive && (liveMap[row.articleId] == currentUserId))) &&
                                  <>
                                    <Button style={{marginRight: '5px', marginTop: '5px'}}
                                            onClick={() => {
                                              setArticleIdToBeDeleted(row.articleId);
                                              setShowDeleteModal(true);
                                            }}><DeleteIcon/></Button>
                                    <Button style={{marginRight: '5px', marginTop: '5px'}}
                                            onClick={() =>openLive('/dash/live/metaCollection/' + row.articleId)}><EditIcon/></Button>
                                  </>
                                  }
                                  {canOthersGoLive &&
                                  <Button style={{marginRight: '5px', marginTop: '5px', width: '100%'}} variant='contained' color='primary'
                                          onClick={() => openLive('/dash/live/transmission/' + row.articleId)}>Restart Live Video</Button>
                                  }
                                </TableCell>
                              </TableRow>
                          ))
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>}
                </Grid>
              </Grid>
            </TabPanel>
          </Grid>
        </Grid>
      </Box>

    </>
  )
}

export default LiveSchedule;
