import axios from "axios";
import { LogglyTracker } from 'loggly-jslogger';

const logger = new LogglyTracker();
logger.push({
  'logglyKey': '142d21f5-10d3-47a9-aa5a-f83e0a3abed7',
  'sendConsoleErrors': true,
  'tag': 'creator-website-error',
  'url': (window.location||{}).href || '',
  'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
  'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
});
/**
 * interceptor for response. Modifiying the error obj to have the requried keys at top level.
 */
axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if(error.response) {
    const { status, data } = error.response;
    return Promise.reject({status, data, message:data.message});
  }
  return Promise.reject(error);
});

/**
 * Function to make the api call it will return the axios
 * promise that will resolve or reject based on the response
 * @param {RequestObject}
 * @returns {Promise}
 */ 

let cancelSource;

const headers = {
  'Access-Control-Allow-Credentials': true,
  'Access-Control-Allow-Origin': '*',
};
//let headers = {};
function makeApiCall({method, endpoint, data}) {
  //check for valid endpoint
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  if(!endpoint || !method) return Promise.reject({message:"Invalid request data"});

  endpoint = endpoint.startsWith("/") ? endpoint : `/${endpoint}`;
  
  method = method.toLowerCase();

  //check for valid method
  if (!method === 'get' && !method === 'post' && !method === 'delete' && !method === 'put') return Promise.reject({message:"Invalid request method."});
  cancelSource = source;
  if(localStorage.getItem('accessToken')) {
    headers['Authorization'] = localStorage.getItem('accessToken');
  }

  axios.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    if (process.env.REACT_APP_APP_ENV && process.env.REACT_APP_APP_ENV === 'production') {
      const data = {
        // Cannot get the email here
        'status-code': error.status || 0,
        'message': error.message || 'no message',
        'method': method,
        'endpoint': endpoint,
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
        'type': 'error',
        'data': data,
      });
    }
    return Promise.reject(error);
  });

  return axios({
    baseURL: process.env.REACT_APP_USER_API_SERVER|| 'http://localhost:8080', // set the base url
    timeout: 200000000000,
    headers: headers,
    method: method,
    url: endpoint,
    data: data,
    cancelToken: source.token,
  });
}

//export the api func
export default makeApiCall;

export function cancelAPiCall(){
  return cancelSource;
}