import React, { useState,useEffect} from 'react';
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Input from "../../../commons/muiCustomComponents/Input";
import Box from "../../../commons/muiCustomComponents/Box";
import Grid from "commons/muiCustomComponents/Grid";
import Button from "commons/muiCustomComponents/Button";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { CircularLoader } from 'commons/commonWrapper/Loaders';
import {isValidEmailAddress} from "../../../utils/utils";
import { LogglyTracker } from 'loggly-jslogger';

const logger = new LogglyTracker();
logger.push({ 'logglyKey': '142d21f5-10d3-47a9-aa5a-f83e0a3abed7', 'sendConsoleErrors' : true, 'tag' : 'ardbeg,advertisement,businessinforow' });


/**
 * Handle Error part 
 * Transfer only successpart to parent
 */

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '95%',
  },
  selectEmpty: {
    color: theme.palette.pubGrey,
  },
  container: {
    height: '100%',
  },
}));


function BusinessInfoRow(props) {
  const classes = useStyles();
  const [companyType, setCompanyType] = useState('Corporation');

  const [errAddressLine1,setErrAdressLine1] = useState(false);
  const [errAddressLine2,setErrAdressLine2] = useState(false);
  const [errCity ,setErrCity] = useState(false);
  const [errPinCode ,setErrPinCode] = useState(false);
  const [errState,setErrState] = useState(false);
  const [errBEmail,setErrBEmail] =useState(false);
  const [isLoading, setLoading] = useState(false);
  const [errbusinessNameRow ,setErrBusinessNameRow] = useState(false);

  const {
    businessNameRow, setBusinessNameRow,
    addressLine1Row, setaddressLine1Row,
    addressLine2Row, setaddressLine2Row,
    cityRow, setCityRow,
    pinCodeRow, setPinCodeRow,
    stateRow, setStateRow,
    businessEmailRow, setBusinessEmailRow,
    updateBusinessDetails,
    setActiveStepItem,
    setCompletedItem,
    businessTypeRow ,setBusinessTypeRow,
    thirdStepComplete,
    forthStepComplete,
  } = props;

  const {landing: {userProfile}} = useSelector(state => state);

  useEffect(()=>{
    businessTypeRow && setCompanyType(businessTypeRow)
  },[businessTypeRow]);

  const handleChange = (e) => {
    setCompanyType(e.target.value);
  }

  const updateBusiness = async () => {
    if (!businessNameRow && companyType !== "Individual,soleproprietor,orsingle-memberLLC") return setErrBusinessNameRow(true)
    if (!addressLine1Row) return setErrAdressLine1(true)
    if (!addressLine2Row) return setErrAdressLine2(true)
    if (!cityRow) return setErrCity(true)
    if (!pinCodeRow) return setErrPinCode(true)
    if (!stateRow) return setErrState(true)
    if ((!isValidEmailAddress(businessEmailRow))) return setErrBEmail(true)

    setErrAdressLine1(false)
    setErrAdressLine2(false)
    setErrCity(false)
    setErrPinCode(false)
    setErrState(false)
    setErrBEmail(false)
    setErrBusinessNameRow(false)
    try {
      setLoading(true);
      companyType !== "Individual,soleproprietor,orsingle-memberLLC" ?
      await updateBusinessDetails({
        company_name: businessNameRow,
        company_address_line1: addressLine1Row,
        company_address_line2: addressLine2Row,
        type_of_entity: companyType,
        business_email: businessEmailRow,
        business_state: stateRow,
        business_pin: pinCodeRow,
        business_city: cityRow,
      }):
     await updateBusinessDetails({
        company_address_line1: addressLine1Row,
        company_address_line2: addressLine2Row,
        type_of_entity: companyType,
        business_email: businessEmailRow,
        business_state: stateRow,
        business_pin: pinCodeRow,
        business_city: cityRow,
      });

      setBusinessTypeRow(companyType);
      if (!thirdStepComplete ||  !forthStepComplete) {
        setActiveStepItem && setActiveStepItem(3);
        setCompletedItem && setCompletedItem({ 0: true, 1: true, 2: true });
      }
      setLoading(false);
    }
    catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      setLoading(false);
    }

  }
  return (<Box mt={2} ml={4} mr={4}  >
    <Grid container >
      <Grid item md={6} xs={12} >
        <Box m={2} >
          <FormControl className={classes.formControl}>
            <InputLabel className={classes.selectEmpty} > Type of Business *</InputLabel>
            <Select
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              value={companyType}
              onChange={handleChange}
            >
              <MenuItem value={'Corporation'}>Corporation</MenuItem>
              <MenuItem value={'Partnership'}>Partnership</MenuItem>
              <MenuItem value={'NonProfiltOrganisation'}>Non Profilt Organisation</MenuItem>
              <MenuItem value={'LimitedLiabilityCompany(LLC)'}>Limited Liability Company (LLC)</MenuItem>
              <MenuItem value={'Individual,soleproprietor,orsingle-memberLLC'}>Individual, sole proprietor, or single-member LLC</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Grid>
    </Grid>

    {companyType !== "Individual,soleproprietor,orsingle-memberLLC" && <Grid container><Grid item md={6} xs={12}>
      <Box m={2}>
        <Input
          fullWidth
          color="secondary"
          onChange={(e) => { setBusinessNameRow(e.currentTarget.value) }}
          value={businessNameRow}
          error ={errbusinessNameRow}
          label="Legal Business Name*"
        >
        </Input>
      </Box>
    </Grid>
    </Grid>
    }
    <Grid container>
      <Grid item md={6} xs={12}>
        <Box m={2}>
          <Input
            fullWidth
            color="secondary"
            error={errAddressLine1}
            onChange={(e) => { setaddressLine1Row(e.currentTarget.value) }}
            value={addressLine1Row}
            label="Address Line 1*"
          >
          </Input>
        </Box>
      </Grid>
      <Grid item md={6} xs={12}>
        <Box m={2}>
          <Input
            fullWidth
            color="secondary"
            error={errAddressLine2}
            value={addressLine2Row}
            onChange={(e) => { setaddressLine2Row(e.currentTarget.value) }}
            label={"Address Line 2*"}>
          </Input>
        </Box>

      </Grid>

    </Grid>


    <Grid container>
      <Grid item md={6} xs={12}>
        <Box m={2}>
          <Input
            fullWidth
            color="secondary"
            onChange={(e) => { setCityRow(e.currentTarget.value) }}
            value={cityRow}
            label="City *"
            error={errCity}
          >
          </Input>
        </Box>
      </Grid>
      <Grid item md={6} xs={12}>
        <Box m={2}>
          <Input
            fullWidth
            color="secondary"
            error={errPinCode}
            value={pinCodeRow}
            onChange={(e) => { setPinCodeRow(e.currentTarget.value) }}
            label={"Pin Code*"}>
          </Input>
        </Box>

      </Grid>

    </Grid>
    <Grid container>
      <Grid item xs={12} md={6} >
        <Box m={2}>
          <Input
            fullWidth
            color="secondary"
            value={stateRow}
            error={errState}
            onChange={(e) => { setStateRow(e.currentTarget.value) }}
            label={"State *"}>
          </Input>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} >
        <Box m={2}>
          <Input
            fullWidth
            color="secondary"
            type="email"
            error={errBEmail}
            value={businessEmailRow}
            onChange={(e) => { setBusinessEmailRow(e.currentTarget.value) }}
            label={"Business Email *"}>
          </Input>
        </Box>
      </Grid>
    </Grid>

    <Box m={2}>
      <Box display="flex">
        <Button variant="contained" onClick={updateBusiness}> Save</Button>
        {isLoading && <CircularLoader height="auto" size={20} ml={2} />}
      </Box>
    </Box>
  </Box>)
}
export default BusinessInfoRow;
