import React,{useCallback,useContext} from 'react';
import SocialAccounts from "../components/settings/SocialAccounts";
import FullScreenPage from '../commons/commonWrapper/FullScreenPage';
import Box from "../commons/muiCustomComponents/Box";
import MobileProvider from "commons/context/MobileProvider";

export default function GoogleAnalyticsHome() {
  const isMobile = useContext(MobileProvider);
  const barCallback = useCallback(() =>(<Box 
  display="flex"
  alignItems="center"
  component="span"
  fontSize ={14}
  >Social Accounts</Box>),[]);

  return <>
      <FullScreenPage BarComponent={barCallback}>
        {(appbarheight)=> <SocialAccounts appbarheight= {appbarheight}/>}
      </FullScreenPage>
      
    </>
}
