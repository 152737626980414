import React, { useEffect, useState } from "react";
import Dialog from "../../../commons/muiCustomComponents/Dailog";
import { CircularLoader} from "../../../commons/commonWrapper/Loaders";
import Button from "../../../commons/muiCustomComponents/Button";
import Box from "commons/muiCustomComponents/Box";
import MobileProvider from "commons/context/MobileProvider";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  imageSectionHidden: {
    visibility:'hidden',
  },
  imageSectionVisible: {
    visibility:'visible',
  },
  theParent: {
    width:'90%',
    margin:'auto',
    padding:theme.spacing(4),
  },
  buttonSection: {
    textAlign:'center',
    margin:theme.spacing(2),
  },
  buttonItem: {
    margin:'auto 8px',
  },
  actionSectionMobile: {
    margin: theme.spacing(6),
    textAlign: "center",
  },
  actionSectionDesktop: {
    margin: theme.spacing(4),
    textAlign: "right",
  },
}));


function ImageEditor (props) {

  const [isLoading, setIsLoading ] = useState(true);
  const isMobile = React.useContext(MobileProvider);
  const classes = useStyles();
  useEffect(()=> {
    if(props.baseContent && props.open) {
      let img = new Image();
      img.src = props.baseContent;
      setTimeout(()=> {
        if(img.width < 800 || img.height < 420) {
          alert('Image Resolution is incorrect is smaller than 800 * 420');
          setIsLoading(true);
          props.closeDialog();
          return;
        }
        window.$('#thepicture').guillotine({width: props.width || 800 , height: props.height || 420});
        setFit();
        setIsLoading(false);
      },100);
    }
  },[props]);

  
  const setZoomOut = () => {
    window.$('#thepicture').guillotine('zoomOut');
  }

  const setZoomIn = () => {
    window.$('#thepicture').guillotine('zoomIn');
  }
  const setFit = () => {
    window.$('#thepicture').guillotine('fit');
  }

  const getEditData = () => {
    const data = window.$('#thepicture').guillotine('getData');
    props.getCropData(data);
    closeDialog();
  }
  
  const closeDialog = () => {
    setIsLoading(true);
    props.closeDialog();
  }
  if(!props.open || !props.baseContent) {
    return null;
  }

  return (
    <Dialog 
    fullWidth={true}
    maxWidth={"md"} 
    fullScreen={isMobile}
    open={props.open} size="md">  
    <>
      {isLoading && <CircularLoader/>}
      <div id="theparent" className={classes.theParent}>
        <img id="thepicture" className={isLoading ? classes.imageSectionHidden:classes.imageSectionVisible} src={props.baseContent} alt="crop"/>
        <Box className={classes.buttonSection}>
          <Button className={classes.buttonItem} onClick={setZoomOut}>zoom out</Button>
          <Button className={classes.buttonItem} onClick={setFit}>fit</Button>
          <Button className={classes.buttonItem} onClick={setZoomIn}>zoom in</Button>
        </Box>
        <Box className={isMobile ? classes.actionSectionMobile : classes.actionSectionDesktop}>
          <Button className={classes.buttonItem} variant="contained" onClick={getEditData}>Crop</Button>
          <Button className={classes.buttonItem} variant="contained" onClick={closeDialog}>Cancel</Button>
        </Box>
        
      </div>
    </>
    </Dialog>
    
  )
}

export default ImageEditor;