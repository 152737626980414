import articleAction from "./article";
import socialAction from "./social";
import onboardAction from "./onboard";
import landingAction from "./landing";
import schedulerAction from "./scheduler";
import monetizationAction from "./monetization";
import commonComponent from "./common-component";
import insightsAction from "./insights";

const actions = {
  ...articleAction,
  ...socialAction,
  ...onboardAction,
  ...landingAction,
  ...schedulerAction,
  ...monetizationAction,
  ...commonComponent,
  ...insightsAction,
}


export default actions;