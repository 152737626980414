import React, {useState, useContext} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import Box from "commons/muiCustomComponents/Box";
import Button from "commons/muiCustomComponents/Button";
import Grid from "commons/muiCustomComponents/Grid";
import Input from "commons/muiCustomComponents/Input";
import { LinearLoader ,PageBlockLoader} from "commons/commonWrapper/Loaders";
import setAppMessage from "app-component/app-component-action";
import { addNewUser } from "./user-list-action";
import MobileProvider from "commons/context/MobileProvider";
import { LogglyTracker } from 'loggly-jslogger';

const logger = new LogglyTracker();
logger.push({ 'logglyKey': '142d21f5-10d3-47a9-aa5a-f83e0a3abed7', 'sendConsoleErrors' : true, 'tag' : 'ardbeg,user-management,add-user-home' });



const useStyles = makeStyles(theme => ({
  containerSection : {
    marginTop: theme.spacing(4),
    flexDirection:'column',
  },
  inputItem: {
    margin: `${theme.spacing(3)}px ${theme.spacing(1)}px`,
  },
}));

function AddUserHome (props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useContext(MobileProvider);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [requesting, setRequesting] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const {landing: {userProfile}} = useSelector(state => state);

  const handleInputChange = (event) => {
    if(event.target.name === 'email') setEmail(event.target.value);
    if(event.target.name === 'firstName') setFirstName(event.target.value);
    if(event.target.name === 'lastName') setLastName(event.target.value);
  }

  const handleAddNewUser = async() => {
    setIsDirty(true);
    if(!email.length || !firstName.length || !lastName.length) {
      return;
    }
    try {
      setRequesting(true);
      await dispatch(addNewUser(email, firstName, lastName));
      setRequesting(false);
      dispatch(setAppMessage({ message: `User added successfully.`, header: 'Success', type: 'alert' }));
      history.push("/dash/settings/users/");
    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      dispatch(setAppMessage({ message: err.message || `Unable to add user.`, header: 'Error', type: 'alert' }));
      setRequesting(false);
      //handle error
    }
  }
  return (
    <>
      {requesting && isMobile && <LinearLoader/>}
      {requesting && !isMobile && <PageBlockLoader/>}

      <Grid container className={classes.containerSection}>
        <Grid item md={4} xs={12} sm={12} className={classes.inputItem}>
        <Box ml={3} mr={3}>
          <Input 
            label="Email" 
            id="email" 
            error={isDirty && !email.length}
            helperText={(isDirty && !email.length) ? "* Email is required": ''}
            type="email" 
            name="email" 
            onChange={handleInputChange} 
            fullWidth 
            value={email}/>
        </Box>
        </Grid>
        <Grid item md={4} xs={12} sm={12} className={classes.inputItem}>
        <Box ml={3} mr={3}>
          <Input 
          label="Firstname" 
          id="firstname" 
          type="text" 
          name="firstName" 
          error={isDirty && !firstName.length}
          helperText={(isDirty && !firstName.length) ? "* first name is required": ''}
          onChange={handleInputChange} 
          fullWidth 
          value={firstName}/>
        </Box>
        </Grid>
        <Grid item md={4} xs={12} sm={12} className={classes.inputItem}>
        <Box ml={3} mr={3}>
          <Input 
            label="Lastname" 
            id="lastname" 
            type="text" 
            name="lastName" 
            error={isDirty && !lastName.length}
            helperText={(isDirty && !lastName.length) ? "* last name is required": ''}
            onChange={handleInputChange} 
            fullWidth 
            value={lastName}/>
        </Box>
        </Grid>
        <Grid item md={4} xs={12} sm={12} className={classes.inputItem}>
          <Box ml={3} mr={3}>
            <Button 
            fullWidth 
            size="large" 
            disabled={requesting} 
            onClick={handleAddNewUser} 
            variant="contained">Add New User</Button>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default AddUserHome;