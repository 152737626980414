import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from "../../../commons/muiCustomComponents/Box";
import InputBox from "../../../commons/muiCustomComponents/InputBox";
import Button from "../../../commons/muiCustomComponents/Button";
import { CircularLoader } from '../../../commons/commonWrapper/Loaders';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    width: '100%',
  },
  selectEmpty: {
    color: theme.palette.pubGrey,
  },
}));

export default function DnsForm({ item = {}, updateContent, updateRecords, host, handleClose }) {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const [content, setContent] = useState(item.content || '');
  const [name, setSubCat] = useState(item.name || '');
  const [ttl, setTtl] = useState(item.ttl || '');
  const [type, setType] = useState(item.type || 'A')
  const [currentProxy, setproxy] = useState(item.proxied || false)
  const [nameError, setNameError] = useState(false)
  const [contentError, setContentError] = useState(false)


  useEffect(() => {
    if (Object.keys(item).length > 0) {
      setContent(item.content)
      setSubCat(item.name)
      setTtl(item.ttl)
      setType(item.type)
      setproxy(item.proxied)
    }

  }, [item])
  const handleContent = (e) => {
    const value = e.currentTarget.value;
    setContent(value);
    if (!value) setContentError(true)
    else setContentError(false)
  }
  const handleType = (e) => {
    const value = e.target.value;
    setType(value);
  }
  const handleName = (e) => {
    const value = e.currentTarget.value;
    setSubCat(value);
    if (!value) setNameError(true)
    else setNameError(false)
  }
  const handleTtl = (e) => {
    const value = e.target.value;
    setTtl(value);
  }
  const handleRadioChange = (e) => {
    const value = e.target.checked;
    setproxy(value);
  }
  const submitDns = async () => {

    if (!name && !content) {
      setNameError(true)
      setContentError(true)
      return;
    }

    if (!name) {
      setNameError(true)
      return;
    }

    if (!content) {
      setContentError(true)
      return;
    }
    const payload = { ...item, name: `${name}.${host}`, content: content, ttl: +ttl, type: type, proxied: currentProxy }
    if (currentProxy) {
      payload.ttl = 1
    }
    try {
      setLoading(true)
      if (!item.id) {
        await updateRecords([{ ...payload, id: null }])
        updateContent()
      }
      else {
        await updateRecords(payload)
        updateContent()
      }
      setLoading(false)
      handleClose()
    }
    catch (err) {
      setLoading(false)
    }

  }

  return (
    <>
      <Box className={classes.root}>
        <Box>
          <InputBox
            heading="Name *"
            fullWidth
            color="secondary"
            value={name}
            error={nameError}
            helperText={nameError ? "Name can't be empty" : ""}
            onChange={handleName}
            placeholder="Write here..."
            headingStyle={{
              fontSize: 14,
              color: "pubGrey",
              fontWeight: 500,
              mb: 1,
            }}
          />
        </Box>
        <Box mt={2}>
          <FormControl className={classes.formControl}>
            <InputLabel className={classes.selectEmpty} > Type</InputLabel>
            <Select
              labelId="type-customized-select-label"
              id="type-customized-select"
              value={type}
              onChange={handleType}
            >
              <MenuItem value="A">A</MenuItem>
              <MenuItem value="AAAA">AAAA</MenuItem>
              <MenuItem value="CNAME">CNAME</MenuItem>
              <MenuItem value="TXT">TXT</MenuItem>
              <MenuItem value="NS">NS</MenuItem>
              <MenuItem value="SPF">SPF</MenuItem>
              <MenuItem value="PTR">PTR</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <InputBox
            heading="Content *"
            fullWidth
            error={contentError}
            helperText={contentError ? "Content can't be empty" : ""}
            color="secondary"
            value={content}
            onChange={handleContent}
            placeholder="Write here..."
            headingStyle={{
              fontSize: 14,
              color: "pubGrey",
              fontWeight: 500,
              mb: 1,
            }}
          />
        </Box>
        <Box component="p"> Proxied</Box>
        <Box display="flex">
          <Button type="toggle"
            leftText="No"
            checked={currentProxy}
            value={currentProxy}
            onChange={handleRadioChange}
            rightText="Yes" />
        </Box>
        {
          !currentProxy && <Box mt={2}>
            <FormControl className={classes.formControl}>
              <InputLabel className={classes.selectEmpty} > TTL (time-to-live)</InputLabel>
              <Select
                labelId="ttl-customized-select-label"
                id="ttl-customized-select"
                onChange={handleTtl}
                value={ttl}
              >
                <MenuItem value="1">Auto</MenuItem>
                <MenuItem value="120">2 min</MenuItem>
                <MenuItem value="300">5 min</MenuItem>
                <MenuItem value="600">10 min</MenuItem>
                <MenuItem value="900">15 min</MenuItem>
                <MenuItem value="1800">30 min</MenuItem>
                <MenuItem value="3600">1 hr</MenuItem>
                <MenuItem value="7200">2 hr</MenuItem>
                <MenuItem value="18000">5 hr</MenuItem>
                <MenuItem value="43200">12 hr</MenuItem>
                <MenuItem value="86400">1 day</MenuItem>
              </Select>
            </FormControl>
          </Box>
        }
      </Box>

      <Box mb={3} mt={4} display="flex" justifyContent="flex-end">
        <Button onClick={handleClose}  >Close</Button>
        <Button disabled={isLoading} variant="contained" boxProps={{ ml: 3 }} onClick={submitDns}>Submit</Button>
        {isLoading && <CircularLoader height="auto" ml={1} size={20} />}
      </Box>
    </>
  );
}
