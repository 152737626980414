import React from 'react';
import { Redirect, useLocation } from "react-router-dom";

const OnBoardHome = (props) => {

  const { search  } = useLocation();
  return (
    <>
      <Redirect to={`/onboard/domain${search}`} />
    </>
  )
}

export default OnBoardHome;