import React, { Component } from 'react'
import ChatInput from './ChatInput'
import ChatMessage from './ChatMessage'
import moment from 'moment'

import './App.css';
var storeDate = moment()

class Chat extends Component {
  state = {
    name: '',
    messages: [],
  }
  
  constructor(props) {
    super()
    if(!this.isopen) {
      const user = JSON.parse(sessionStorage.getItem("user"));
      this.user = user;
      const URL = `wss://feni.pubninja.com/chat/${props.articleId}/${user.id}`
      this.URL = URL;
      this.ws = new WebSocket(URL)
    }
    
  }

  connect() {
    if(!this.isopen) {
      this.ws = new WebSocket(this.URL)
      this.handlers()
    }
  }

  disconnect(retry = true) {
    console.log('disconnected')
    this.isopen = false
    if(retry) {
      setTimeout(()=>{
        this.connect()
      }, 3000)
    }

  }

  handlers() {
    this.ws.onopen = () => {
      // on connecting, do nothing but log it to the console
      this.setState(() => ({ messages: [] }))
      const user = this.user
      this.isopen = true
      console.log('chat connected')
      const name = [user.firstName, user.lastName].filter(a => a && (a !== "")).join(" ");
      const userDetails = {
        UserName: name,
        EmailID: user.email,
        Profile: user.profilePicture || "",
      }
      const onConnect = {
        "event": "onConnect",
        "handler": "ardbeg",
        "payload": userDetails,
      }
      this.ws.send(JSON.stringify(onConnect))
      
      const message = {
        "event": "fetchLatest",
        "handler": "ardbeg",
        "payload": {
          "limit":100,
        },
      }
      this.ws.send(JSON.stringify(message))
    }

    this.ws.onmessage = evt => {
      // on receiving a message, add it to the list of messages
      console.log("message aala:::", JSON.parse(evt.data))
      const message = JSON.parse(evt.data)
      const payload = message.payload;
      switch (message.event) {
        case "onMessage": {
          this.addMessage({ name: payload.UserName, 
            message: payload.Message, messageType: payload.MessageType, image: payload.Image, creation: payload.Created_at,
          id: payload.MessageID });
          break;
        }
        case "fetchLatestCallback": {
          payload.data.list.map((m) => {
            this.addMessage({ name: m.UserName, message: m.Message, messageType: m.MessageType, 
              image: m.Image, creation: m.CreatedAt,id: m.MessageID })
          })
          this.props.participantHandler(payload.data.members)
          break;
        }
        case 'newMemberCallback': {
          this.props.participantHandler(payload.data)
          break
        }
        default:
          console.log("default case: ", message.event)

      }
    }

    this.ws.onclose = ()=>{
      this.disconnect()
    }
  }

  componentDidMount() {
    this.handlers()
  }

  addMessage = (message) =>{
    message.storedDate = storeDate
    storeDate = moment(message.creation)
    //this.setState(state => ({ messages: [...state.messages, message] }))
    this.setState(prevState => {
      const { messages } = prevState;
      messages.push(message)
      messages.sort((a,b)=>a.id - b.id)
      return messages;
    })
    this.scrollToBottom()
  }

  submitMessage = (type, messageString) => {
    console.log("sending through websocket::", messageString)
    // on submitting the ChatInput form, send the message, add it to the list and re {set the input
    let message = {};
    if (type == "text") {
      message = {
        "event": "onMessage",
        "handler": "ardbeg",
        "payload": {
          "MessageType": "TEXT",
          "Message": messageString,
        },
      }
    } else {
      message = {
        "event": "onMessage",
        "handler": "ardbeg",
        "payload": {
          "MessageType": "IMAGE",
          "Image": messageString,
        },
      }
    }
    this.ws.send(JSON.stringify(message))
  }

  scrollToBottom = () => {
    // const chat = document.getElementById("chatMessage");
    // chat.scrollTop = chat.scrollHeight;
    if(this.el){
      this.el.scrollIntoView({ behavior: 'smooth' });
    }
  };

  render() {
    
    return (
      <div className='chatContainer'>
        {
          typeof this.props.headerComponent === 'function' && this.props.headerComponent()
        }
        <div className="chatMessage" id="chatMessage">
          {this.state.messages.map((message, index) => 
            <ChatMessage
            key={index}
            message={message.message}
            name={message.name}
            messageType={message.messageType}
            image={message.image}
            creation={message.creation}
            storedDate = {message.storedDate}
            count = {index}
            />,
          )}
          <div ref={el => { this.el = el; }}></div>
        </div>
        {/* <label htmlFor="name">
          Name:&nbsp;
          <input
            type="text"
            id={'name'}
            placeholder={'Enter your name...'}
            value={this.state.name}
            onChange={e => this.setState({ name: e.target.value })}
          />
        </label> */}
        <div className="chatInput">
          <ChatInput
            ws={this.ws}
            onSubmitMessage={(type,messageString) => this.submitMessage(type,messageString)}
          />
        </div>
      </div>
    )
  }
}

export default Chat