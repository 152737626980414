
const socialAction = {
  FETCHFACEBOOK: {
    SUCCESS: "facebookPagesSuccess",
  },
  FETCHTWITTER: {
    SUCCESS: "twitterPagesSuccess",
  },
  CLEARFBPAGES: {
    SUCCESS: "clearFacebookPagesSuccess",
  },
  CLEARTWITTERACCOUNTS: {
    SUCCESS: "clearTwitterPagesSuccess",
  },
}
    
export default socialAction;