
import React, { useState ,useImperativeHandle,forwardRef} from "react";
import Box from "commons/muiCustomComponents/Box";
import Button from "commons/muiCustomComponents/Button";
import Divider from '@material-ui/core/Divider';
import InputBox from "commons/muiCustomComponents/InputBox";
import DeleteIcon from '@material-ui/icons/Delete';


function BenefitContainer(props,ref) {
  const [name ,setName] = useState('');
  const [description,setDescription] = useState('');
  const [totalCustomBenefit ,setTotalBenefit] = useState([]);
  
  const addBenefit = ()=>{
    let isReturn = false;
    totalCustomBenefit.forEach((item)=>{
      if(item.name === name){
        isReturn =true;
      }
    })
    if(isReturn) return;
    
    if(!name) return;
    const temp ={name,description,"type": "CUSTOM",sections:[],contents:[]};
    setName('');
    setDescription('');
    setTotalBenefit(totalCustomBenefit.concat([temp]))
    
  }

  const removeBenefit = (key)=>{
    totalCustomBenefit.splice(key, 1)
    setTotalBenefit([...totalCustomBenefit])
  }
  
  useImperativeHandle (ref ,()=>totalCustomBenefit.filter(item=>item.name))
  return (
    <Box>
      <Box mt={3} mb={2}>
        <Box component="span" fontWeight="bold">Custom Benefits (Not displayed on website)</Box>
      </Box>
      {totalCustomBenefit.length>0 &&
        totalCustomBenefit.map((row,key)=><Box key={key} display="flex" alignItems="center" p={3} pt={0}>
        <InputBox
          id="benefitName"
          heading="Name"
          onChange={(e) => { 
            totalCustomBenefit[key].name = e.currentTarget.value
            setTotalBenefit([...totalCustomBenefit])
           }}
          value={row.name || ''}
          variant="outlined"
          placeholder="Enter Name Here"
          size="small"
          headingStyle={{
            component: "span",
            fontWeight: "bold",
            fontSize: 14,
            mb: 1,
          }}
          
          fullWidth
        />
        <Box ml={2}>
        <InputBox
          id="benefitDescription"
          heading="Description"
          onChange={(e) => { 
            totalCustomBenefit[key].description = e.currentTarget.value
            setTotalBenefit([...totalCustomBenefit])
           }}
          value={row.description || ''}
          variant="outlined"
          placeholder="Enter Description Here"
          size="small"
          headingStyle={{
            component: "span",
            fontWeight: "bold",
            fontSize: 14,
            mb: 1,
          }}
          
          fullWidth
        />
        </Box>
        <DeleteIcon onClick={()=>removeBenefit(key)}/>
        </Box>)
        }
      <Box display="flex" p={3}>
      <InputBox
        id="benefitName"
        heading="Name"
        onChange={(e) => { setName(e.currentTarget.value) }}
        value={name || ''}
        variant="outlined"
        placeholder="Enter Name Here"
        size="small"
        headingStyle={{
          component: "span",
          fontWeight: "bold",
          fontSize: 14,
          mb: 1,
        }}
        
        fullWidth
      />
      <Box ml={2}>
      <InputBox
        id="benefitDescription"
        heading="Description"
        onChange={(e) => { setDescription(e.currentTarget.value) }}
        value={description || ''}
        variant="outlined"
        placeholder="Enter Description Here"
        size="small"
        headingStyle={{
          component: "span",
          fontWeight: "bold",
          fontSize: 14,
          mb: 1,
        }}
        
        fullWidth
      />
      </Box>
        
      </Box>
      <Button color="secondary" variant="contained" boxProps={{ml:3}} onClick={()=>addBenefit(true)}>Add</Button>
      {/* <Divider/> */}
      
    </Box>
  )
}
export default forwardRef(BenefitContainer);
