export function isValidEmailAddress(emailAddress) {
    // eslint-disable-next-line max-len
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,20}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    return pattern.test(emailAddress);
  }

export function hideIntercom(invert = false) {
  window.zE('webWidget', invert ? 'show' : 'hide');

  return () => {
    window.zE('webWidget', invert ? 'hide' : 'show');
  }
}

export function download(fileData, fileName = 'file.csv') {
  let url = window.URL.createObjectURL(fileData);
  let a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  a.remove();
}
