import React ,{useContext,useCallback}from "react";
import ManageProducts from "../components/monetization/AddProduct";
import MobileProvider from "commons/context/MobileProvider";
import Box from "../commons/muiCustomComponents/Box";
import FullScreenPage from '../commons/commonWrapper/FullScreenPage';

function Manageproducts () {

  const isMobile = useContext(MobileProvider);

  const barCallback = useCallback(() =>(<Box 
    display="flex"
    alignItems="center"
    component="span"
    >Subscription</Box>),[]);
  return (

    <FullScreenPage BarComponent={barCallback} isDesktop={false} >
      <Box m={isMobile ?2:0}> <ManageProducts/> </Box>
    </FullScreenPage >

  )
}

export default Manageproducts;