import React,{useCallback,useContext} from 'react';
import FullScreenPage from "commons/commonWrapper/FullScreenPage"; 
import Box from "commons/muiCustomComponents/Box";
import UserPermissions from "../components/user-management/UserPermissions";
import MobileProvider from "commons/context/MobileProvider";


function UserListPage (props) {
  const isMobile = useContext(MobileProvider);
  const barCallback = useCallback(() =>(<Box 
    display="flex"
    alignItems="center"
    >User Permission</Box>),[]);
  return(
    <FullScreenPage BarComponent={barCallback}>
    {(appBarHeight) => <UserPermissions appbarheight={appBarHeight} />}
  </FullScreenPage>
  );
}

export default UserListPage;