import React, {useEffect, useState, useContext} from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, sendLoginOtp } from "./auth-action";
import { useHistory, useLocation } from "react-router-dom";
import Box from "../../commons/muiCustomComponents/Box";
import { makeStyles } from '@material-ui/core/styles';
import Input from "../../commons/muiCustomComponents/Input";
import Button from "../../commons/muiCustomComponents/Button";
import Divider from '@material-ui/core/Divider';
import MobileProvider from "commons/context/MobileProvider";
import { LinearLoader } from "commons/commonWrapper/Loaders";
import setAppMessage from "app-component/app-component-action";
import { fbPixelEvent } from "../../utils/facebook-pixel-client.js";
//import GoogleIcon from "assets/icons/google-16.svg";
import Dialog from "commons/muiCustomComponents/Dailog";
import DialogActions from "commons/muiCustomComponents/DialogActions";
import DialogContent from "commons/muiCustomComponents/DialogContent";
import DialogTitle from "commons/muiCustomComponents/DialogTitle";
import DialogContentText from "commons/muiCustomComponents/DialogContentText";
import { registerLoginEvent, registerSignupEvent } from "../../utils/adwords-client";
import { LogglyTracker } from 'loggly-jslogger';

const logger = new LogglyTracker();
logger.push({ 'logglyKey': '142d21f5-10d3-47a9-aa5a-f83e0a3abed7', 'sendConsoleErrors' : true, 'tag' : 'ardbeg,auth,login-home' });

const emailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const otpFormat = /^\d{4}$/;
const useStyles = makeStyles(theme => ({
  headerBarMobile: {
    width:'100%',
    height:theme.spacing(20),
    backgroundColor:theme.palette.pubBlack,
    color:theme.palette.pubWhite,
  },
  headerBarDesktop : {
    width:'100%',
    height:theme.spacing(20),
    borderBottom: `1px solid ${theme.palette.pubbrownGrey}`,
    textAlign:'left',
  },
  headingTextDesktop: {
    fontSize:theme.spacing(6),
    paddingLeft:theme.spacing(8),
  },
  headingTextMobile: {
    fontSize:theme.spacing(9),
    letterSpacing:theme.spacing(2),
  },
  containerBox: {
    maxWidth:'400px',
    margin:"auto",
    height:"80vh",
  },
  signup: {
    position:"relative",
    top:'10%',
  },
  forgotPassword: {
    cursor:"pointer",
  },
  viewButtonLabel: { textTransform: "none" },
}));

function LoginHome (props) {

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const isMobile = useContext(MobileProvider);
  const query = new URLSearchParams(search);
  const utmSource = query.get("utm_source");
  const utmCampaign = query.get('utm_campaign');
  const utmMedium = query.get('utm_medium');
  const [isDirty, setIsDirty] = useState(false);
  const [otpIsDirty, setOtpIsDirty] = useState(false);
  const [email, setEmail]  = useState('');
  const [password, setPassword] = useState('');
  const [requesting, setRequesting] = useState(false);
  const [showOtpDialog, setShowOtpDialog] = useState(false);
  const [otpEmail, setOtpEmail] = useState('');
  const [otpValue, setOtpValue] = useState('');
  const [otpStep, setOtpStep] = useState(0);
  const {landing: {userProfile}} = useSelector(state => state);
  useEffect(() => {
    //loadGoogleButton();
    try {
      if(utmSource === 'fbpaid') {
        fbPixelEvent(`${utmMedium}-loginpage`, {});
      }
      if(utmSource === 'sempaid') { 
        registerLoginEvent();
      }
    } catch (err) {
      //handle error via sentry
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
    }
  },[]);

  // handle email and password login 
  const  handleLogin = async (mode) => {
    // if(mode === 'local') {
    //   setIsDirty(true);
    //   if(!email.length) return;
    // }

    // if(mode === 'otp') {
    //   setOtpIsDirty(true);
    //   if(!otpValue.length || !otpValue.match(otpFormat)) return;
    // }
    if(mode !== 'otp') {
      setIsDirty(true);
      if(!password.length) return;
    }
    try {
      setRequesting(true);
      await dispatch(loginUser(mode === 'otp'? null :email, otpEmail ,mode==='otp' ? null: password, otpValue));
      setRequesting(false);
      history.push(`/${search}`);
    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      setRequesting(false);
      dispatch(setAppMessage({message:err.message || 'Unable to login', header:'Error', type:'alert'}));
    }
  }

  //handle the input changes
  const handleInputChange = (event) => {
    let { name, value } = event.target;
    value = value.trim();
    if(name === 'email') setEmail(value);
    if(name === 'password') setPassword(value);
    if(name === 'otp') setOtpValue(value);
    if(name === 'otpEmail') setOtpEmail(value);
  }

  const goToForgotPassword = () => {
    history.push("/forgot-password")
  }

  const handleOtpSignin = () => {
    setShowOtpDialog(!showOtpDialog);
  }
  const handleSendOtp = async () => {

    try {
      setOtpIsDirty(true);
      //if(!otpEmail.length || (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(otpEmail))) return;
      if(!otpEmail.length) return;
      setRequesting(true);
      await dispatch(sendLoginOtp(otpEmail));
      setOtpStep(1);
      setOtpIsDirty(false);
      setRequesting(false);
    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      setOtpIsDirty(false);
      setRequesting(false);
      dispatch(setAppMessage({message:err.message || 'Unable to login', header:'Error', type:'alert'}));
      // show error
    }
  }
  const closeOtpDailog = () => {
    setShowOtpDialog(false);
    setOtpStep(0);
    setOtpValue('');
    setOtpEmail('');
    setOtpIsDirty(false);
  }
  return (
    <>
      {renderHeader({classes, isMobile})}
      {requesting && <LinearLoader/>}
      <Box className={classes.containerBox}>
        {/*<Box display="flex" alignItems="center" justifyContent="center" mt={10} mb={4}>
          Sign in using email
        </Box>*/}
        <Box display="flex" alignItems="center" justifyContent="center" mt={20} mb={4}>
          <Input 
            label="Email" 
            id="email" 
            type="email" 
            name="email" 
            onChange={handleInputChange} 
            fullWidth 
            value={email}/>
        </Box>
        <Box pb={6}>
          <Input 
            label="Password" 
            id="password"  
            type="password" 
            name="password"
            error={isDirty && !password.length}
            helperText={(isDirty && !password.length) ? "* Invalid password." : ""}
            onChange={handleInputChange} 
            fullWidth 
            value={password}/>
        </Box>
        <Box ml={6} mr={6} mt={6}>
          <Button fullWidth size="large" disabled={requesting} onClick={handleLogin.bind(null, 'local')} variant="contained">SIGN IN</Button>
        </Box>
        <Box display="flex" mt={2} ml={4} mr={4} mb={2} alignItems="center" justifyContent="center">
        <Box display="flex" mt={4} mb={2} className={classes.forgotPassword} onClick={goToForgotPassword}>Set a new password</Box>
        </Box>
        <Box ml={6} display="flex" mr={6} mt={6} alignItems="center" justifyContent="center">
          <Box style={{visibility:`${requesting ? 'hidden':'visible'}`}}>
            <Button onClick={handleOtpSignin}>SIGN IN using OTP</Button>
          </Box>
        </Box>
        <Box ml={6} display="flex" className={classes.signup} mb={8} mr={6} alignItems="center" justifyContent="center">
          <Box>{`Don’t have an account? `}<a href={`/signup${search}`}>SIGN UP</a></Box>
          </Box>
      </Box>
      <Dialog open={showOtpDialog} maxWidth={"xs"} fullWidth={true}>
      <DialogTitle id="form-dialog-title">{otpStep === 0 ? "SIGN IN using OTP": "Verify OTP"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
              {otpStep === 0 ? "" :
              <p style={{margin:'0px'}}>{`We have sent an OTP to ${otpEmail}`}</p>}
          </DialogContentText>
            {otpStep === 0 && <Input
              label="Email/Phone" 
              id="otpEmail"  
              type="email" 
              name="otpEmail"
              error={otpIsDirty && (!otpEmail.length)}
              helperText={otpIsDirty && (!otpEmail.length) ? "* Input is Invalid." : ""}
              onChange={handleInputChange}
              fullWidth  
              value={otpEmail}/>}
            {otpStep === 1 && <Input 
              label="OTP" 
              id="otp"  
              type="text" 
              name="otp"
              error={otpIsDirty && (!otpValue.length || !otpValue.match(otpFormat))}
              helperText={(otpIsDirty && (!otpValue.length || !otpValue.match(otpFormat)) ) ? "* OTP is Invalid." : ""}
              onChange={handleInputChange}
              fullWidth  
              value={otpValue}/>}
        </DialogContent>
        <DialogActions>
          {otpStep === 0 && <>
            <Button onClick={closeOtpDailog} fullWidth color="primary">
              Cancel
            </Button>
            <Button fullWidth onClick={handleSendOtp} variant="contained" disabled={requesting}>
              Request OTP
            </Button>
          </>}
          {otpStep === 1 && <>
            <Button onClick={closeOtpDailog} fullWidth color="primary">
              Cancel
            </Button>
            <Button fullWidth  onClick={handleLogin.bind(null, 'otp')} variant="contained" disabled={requesting}>
              SIGN IN
            </Button>
          </>}
        </DialogActions>
      </Dialog>
    </>
  )
}

export default LoginHome;


function renderHeader(props) {
  const { isMobile, classes } = props;
  if(isMobile) {
    return (<Box display="flex" alignItems="center" justifyContent="center" className={classes.headerBarMobile}>
      <Box component="p" className={classes.headingTextMobile}>PUBNINJA</Box>
    </Box>)
  }
  return (
    <Box display="flex" alignItems="center" className={classes.headerBarDesktop}>
      <Box component="p" className={classes.headingTextDesktop}>SIGN IN</Box>
    </Box>
  )
}