const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', error => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') 
    image.src = url
  })


export default async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
  let link = imageSrc;
  let pat = /^https?:\/\//i;
  if (pat.test(imageSrc))
  {
    if (link.match(/\?/)) link += `&r=${Math.floor(Math.random()*1000000)}`;
    else link += `?r=${Math.floor(Math.random()*1000000)}`;  
  }
  
  const image = await createImage(link)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  canvas.width = 800
  canvas.height = 420
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  ctx.fillStyle = "#e5e5e5";
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  
  ctx.drawImage(image, pixelCrop.x *scaleX, pixelCrop.y*scaleY, pixelCrop.width,pixelCrop.height,
    0, 0, canvas.width, canvas.height); 

  // if(image.height > image.width) {
  //   ctx.drawImage(image,
  //     pixelCrop.x *scaleX, pixelCrop.y*scaleY, pixelCrop.width,pixelCrop.height,
  //     canvas.width / 2 - image.width / 2,
  //     0,image.width,420,
  //     );
  // }
  // else {
    
  // }
    
  // As a blob
  return new Promise(resolve => {
    canvas.toBlob(file => {
      resolve({url:URL.createObjectURL(file),file})
    }, 'image/jpeg')
  })
}
