
const articleAction = {
  FETCH: {
    REQUEST: "articleListFetchRequest",
    FAILURE: "articleListFetchFailure",
    SUCCESS: "articleListFetchSuccess",
    RESET: "articleListFetchReset",
  },
  DELETE: {
    REQUESTED:"articleDeleteRequest",
    DONE:"articleDeleteDone",
  },
  
}

export default articleAction;
