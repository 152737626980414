import React from 'react';
import Paper from "commons/muiCustomComponents/MuiPaper";
import Box from "commons/muiCustomComponents/Box";
import { makeStyles } from '@material-ui/core/styles';
import FilterListIcon from '@material-ui/icons/FilterList';
import FullScreenDialog from "commons/commonWrapper/FullScreenDialog";
import MobileSearch from "commons/commonWrapper/MobileSearch";
import Search from "../../common/Search/Search";


const useStyles =  makeStyles((theme) => ({
     background :{
        backgroundColor: theme.palette.primary.main,
        color:theme.palette.pubWhite,
        height:theme.spacing(10),
        borderRadius: theme.spacing(6 * 0.25),
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between',
    },
    button :{
        display:'flex',
        alignItems:'center',
        color: theme.palette.pubWhite,
        marginRight:theme.spacing(6),
    },
    cursorClass :{
        cursor:'pointer',
    },
  }));

export default function ArticleFilterBar({taskname,setAnchorEl=()=>{},handleSearchDeleteMobileArticle}){
    const classes = useStyles();
    return (
        <Paper classes={{ root:classes.background}} square elevation={0}>
                <Box component="span" ml={6} style={{fontSize:'14px'}}>{taskname}</Box>
                <Box className={classes.button}>
                <FullScreenDialog type="search" 
                BarComponent={MobileSearch}
                 buttonText="search" size="small" fontSize="small" color="secondary" style={{ color: 'white'}} >
             { (appBarHeight)=> <Search appBarHeight ={appBarHeight} setAnchorEl={setAnchorEl}
             handleSearchDeleteMobileArticle={handleSearchDeleteMobileArticle}/> }
                 </FullScreenDialog>
                    {/* <FilterListIcon fontSize="small" className={classes.cursorClass}/> */}
                </Box>
        </Paper>
    )
}