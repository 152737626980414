import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Box from "../../../commons/muiCustomComponents/Box";
import Button from "../../../commons/muiCustomComponents/Button";


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
   border:`1px solid ${theme.palette.pubveryLightPinkTwo}`,
   backgroundColor:theme.palette.pubGreywhite,
   borderRadius:theme.spacing(1),
   marginTop:theme.spacing(2),
  },
  sublistItem :{
    border:`1px solid ${theme.palette.pubveryLightPinkTwo}`,
   backgroundColor:theme.palette.pubWhite,
   borderRadius:theme.spacing(1),
   marginTop:theme.spacing(2),
  },
  textStyle :{
    color: theme.palette.pubBlack,
    textTransform: "capitalize",
    fontWeight: "500",
  },
  lshape :{
    width:theme.spacing(2),
    height: 'auto',
    alignSelf: 'center',
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(4),
    border:`1px solid ${theme.palette.pubveryLightPinkTwo}`,
    marginRight :theme.spacing(2),
    marginLeft :theme.spacing(2),
    borderTopWidth:'0',
    borderRightWidth :'0',
  },
}));

export default function CategoriesList({ CategoriesArray ,setCategories,setSubCategories}) {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <List component="nav" aria-label="main mailbox folders">
        {
          CategoriesArray.map((item, key) => (
            <React.Fragment key={key}>
              <ListItem className={classes.listItem} divider>
                <ListItemText primary={item.name} className={classes.textStyle}/>
                <ListItemIcon>
                  <Button type="chips" color="secondary" label="Edit" onClick={()=>setCategories(item)}/>
                </ListItemIcon>
              </ListItem>
              {item.sub_section&&item.sub_section.length > 0 &&
              item.sub_section.map((subitem,key)=> <Box display="flex" key={key}>
                <Box className={classes.lshape}>
                </Box>
                <ListItem className={classes.sublistItem} divider>
                <ListItemText primary={subitem.name} className={classes.textStyle}/>
                <ListItemIcon>
                  <Button type="chips" color="secondary" label="Edit" onClick={()=>setSubCategories(subitem)}/>
                </ListItemIcon>
                </ListItem>
                 </Box>)
            }
            </React.Fragment>
          ))
        }
      </List>
    </Box>
  );
}
