import React, {useState} from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Box from "commons/muiCustomComponents/Box";
import Input from "commons/muiCustomComponents/Input";
import {
  createEmailCampaignDraft,
  updateEmailCampaignContent,
} from "../../scheduler/scheduler-action";
import CampaignSegmentList from "./CampaignSegmentList";
import Dialog from "commons/muiCustomComponents/Dailog";
import Header from "../../../content/components/Header";
import Footer from "../../../content/components/Footer";
import Grid from "commons/muiCustomComponents/Grid";
import MobileProvider from "commons/context/MobileProvider";
import { LinearLoader, PageBlockLoader } from 'commons/commonWrapper/Loaders';
import Button from "commons/muiCustomComponents/Button";
import setAppMessage from "../../../../app-component/app-component-action";
import DraftjsEditor from "../../../content/components/DraftjsEditor";
import {saveMediaToPisco} from "../../../content/content-action";
import CloseIcon from '@material-ui/icons/Close';


const useStyles = makeStyles(theme => ({
  captionConatiner: {
    marginTop: theme.spacing(5),
  },
  captionText: {
    fontSize: theme.spacing(5),
    marginBottom:  theme.spacing(2),
    color: theme.palette.PubGrey,
  },
  captionBox: {
    background: theme.palette.pubGreywhite,
  },
  addPostBtn: {
    borderRadius: theme.spacing(6),
    background: theme.palette.pubGreyishBrown,
    color: theme.palette.pubWhite,
    marginTop: theme.spacing(5),
    "&:hover, &:focus": {
      background: theme.palette.pubdeepSkyBlue,
    },
  },
  footerButtonMobile: {
    paddingTop:theme.spacing(2),
    paddingBottom:theme.spacing(2),
    borderRadius:theme.spacing(1),
  },
  nextButton: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    width: theme.spacing(50),
    borderRadius: theme.spacing(8),
    margin: theme.spacing(2),
  },
  newsletterBodyContainer: {
    marginTop: theme.isMobile?theme.spacing(4):theme.spacing(10),
    marginBottom:theme.isMobile?theme.spacing(4): theme.spacing(30),
    marginLeft:theme.isMobile?theme.spacing(2):'unset',
    marginRight:theme.isMobile?theme.spacing(2):'unset',
  },
  mobileTitle:{
    justifyContent:'center',
    display:'flex',
  },
  desktopTitle: {
    display:'flex',
    marginLeft: theme.spacing(12),
  },
  backBtn: {
    position: "absolute",
    left: 0,
    top: "50%",
    transform: "translateY(-50%)",
    cursor: "pointer",
    marginLeft: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      marginLeft: 0,
    },
  },
  relative: {
    position: "relative",
  },
  backgroundFullScreen:{
    backgroundColor:theme.palette.pubBackground,
  },
}));

const CreateCampaign = (props) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const [newsletterSubject, setNewsletterSubject] = useState("");
  const [campaignId, setCampaignId] = useState(null);
  const [contentHtml, setContentHtml] = useState("");
  const [contentObjectMap, setContentObjectMap] = useState("")
  const [openRecepients, setOpenRecepients] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const [isCreating, setCreating] = useState(false);
  const isMobile = React.useContext(MobileProvider);


  const handleNewsletterSubject = (e) => {
    setNewsletterSubject(e.target.value);
  }

  const openRecepientsDialog = () => {
    setOpenRecepients(true);
  }

  const closeRecepientsDialog = () => {
    setOpenRecepients(false);
    setNewsletterSubject('');
  }

  const resetForm = () => {
    setNewsletterSubject('');
    setDirty(false);
    setContentHtml('');
    setContentObjectMap('');
  }
  const createCampaignFunc = async () => {
    if(!newsletterSubject || !newsletterSubject.length){
      setDirty(true);
      return;
    }
    setCreating(true);
      try {
        //do the campaign creation
        const data = await dispatch(createEmailCampaignDraft(newsletterSubject));
        if(!data.campaignId) throw Error('Unable to create Campaign');
        await setCampaignId(data.campaignId);
        await waitForTime();
        await dispatch(updateEmailCampaignContent(data.campaignId, contentHtml, contentObjectMap));
        openRecepientsDialog();
      } catch(err) {
        dispatch(setAppMessage({message: err.message || 'unable to create campaign',header :'Error',type:'alert'}));
      } finally {
        setCreating(false);
        //props.closeCreateCampaignDialog();
        // closeRecepientsDialog();
        resetForm();
      }
  }

  const waitForTime = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 2000);
    })
  }
  const handleMediaUpload = async(file, cb) => {
    const format = file.type.split('/')[0];
    if (format !== "image") return alert("Invalid File Format.");
    try {
      let res = await dispatch(saveMediaToPisco(file));
      const { localUploadResponse: { cdn } } = res;
      cb(null, cdn);
    } catch (err) {
      const { message } = err;
      dispatch(setAppMessage({ message: message || 'Unable to uploa media.', header: 'Error', type: 'alert' }));
      cb(err);
    }
  }

  const updateHtmlContent = async (html, blockMap, count) => {
    await setContentHtml(html);
    await setContentObjectMap(JSON.stringify(blockMap));
  }

  return (
    <>
      <Dialog fullScreen open={props.open} onClose={props.closeCreateCampaignDialog} classes={{paper:classes.backgroundFullScreen}}>
        <Header>
          <Grid container justify="center">
                {<Grid item xs={12} md={12}>
                    <Box display="flex" alignItems="center" justifyContent="space-between" ml={isMobile ?4:25} mr={isMobile ?4:25} >
                      <Box display="flex" alignItems="center" height="100%">
                        <CloseIcon style={{color:'white'}} onClick={props.closeCreateCampaignDialog}/>
                      </Box>
                      <Box component="p" display="flex" justifyContent="center">Create Campaign</Box>
                      <Box  component="p" ></Box>
                      </Box>
                  </Grid>
                }
              </Grid>
        </Header>
        <Grid item xs={12} md={12}>
        {isCreating && <LinearLoader />}
          <Grid container justify="center">
            <Grid item md={8} xs={12}>
              <Box className={classes.newsletterBodyContainer}>
                <Box className={classes.captionConatiner}>
                  <Box className={classes.captionText}>Subject</Box>
                  <Input
                    error={isDirty && !newsletterSubject.length}           
                    helperText={(isDirty && !newsletterSubject.length) && "Subject cannot be empty."} 
                    fullWidth
                    className={classes.captionBox} 
                    label="" 
                    placeholder="Newsletter Subject" 
                    value={newsletterSubject} 
                    onChange={handleNewsletterSubject} />
                </Box>
                <Box className={classes.captionConatiner}>
                  <Box className={classes.captionText}>Paragraph</Box>                  
                </Box>

                {/**---------------DraftJS Starts----------------------- */}
                <Box mt={0} mb={0} className={classes.commonMargin + " " + "quick-share-draftjs"}>
                  <DraftjsEditor
                    DraftPlaceholder="Write here"
                    objectMap={null}
                    htmlContent={''}
                    lastUpdate={''}
                    handleMediaUpload={handleMediaUpload}
                    updateApi={updateHtmlContent}/>
                </Box>
                {/**---------------DraftJS Ends----------------------- */}
                {/* <AddedArticles articlesTitle={state.articlesTitle} articlesId={state.articles} removeArticle={removeArticle} /> */}
                <Box height="70px"></Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        
        <Dialog fullScreen open={openRecepients} onClose={closeRecepientsDialog} classes={{paper:classes.backgroundFullScreen}}>
          {<CampaignSegmentList
            campaignId={campaignId}
            getCampaigns={props.getCampaigns}
            closeRecepientsDialog={closeRecepientsDialog}
            closeCreateCampaignDialog ={props.closeCreateCampaignDialog}
            isCreating={isCreating}
          />}
        </Dialog>
        <Grid item xs={12} md={12}>
          <Footer>
            {!isMobile && <Grid container justify="center">
              <Grid item md={8} xs={12}>
                <Box display="flex">
                  <Button 
                    variant="contained"
                    onClick={createCampaignFunc} 
                    disabled={isCreating}
                    >Select Recipients</Button>
                </Box>
              </Grid>
            </Grid>}
              {isMobile && <Grid container justify="center" className={classes.mobileFooterContainer}>
                <Button
                  fullWidth
                  variant="contained" 
                  disabled={isCreating}
                  onClick={createCampaignFunc}>Select Recipients
                </Button>
              </Grid>}
          </Footer>
        </Grid>
      </Dialog>
    </>
  )
}

export default CreateCampaign;