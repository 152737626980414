
import React, { useState, useImperativeHandle,forwardRef } from "react";
import Box from "commons/muiCustomComponents/Box";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Input from "commons/muiCustomComponents/Input";
import MobileProvider from "commons/context/MobileProvider";
import InputAdornment from '@material-ui/core/InputAdornment';
import { useSelector } from "react-redux";
import Button from "commons/muiCustomComponents/Button";


const useStyles = makeStyles(theme => ({
  bodyContainer: {
    position: 'relative',
    top: '75px',
  },
  minHeight: {
    height: '100%',
  },
  textdecoration: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));

function AddPlan(props,ref) {

  const classes = useStyles();
  const isMobile = React.useContext(MobileProvider);

  const [activeType, setActiveType] = useState("#1")
  const [recurringInterval, setRecurringInterval] = useState(['month', 'quarter', 'halfyear', 'year']);
  const [activeRecurringInterval, setActiveRecurringInterval] = useState('month');
  const [planPrice, setPlanPrice] = useState('');
  const [finalPlan, setFinalPlan] = useState([]);
  const [isAddPlan, setisAddPlan] = useState(true);
  const [firstRecurringInterval, setFirstRecurringInterval] =useState(['month', 'quarter', 'halfyear', 'year']);
  const [activeFirstRecurringInterval, setActiveFirstRecurringInterval] = useState('');
  const [count ,setCount] = useState(1);
  const { landing: { activeDomain, sections }, monetization: {
    subscribe,
  } } = useSelector((state) => state);
  const { currencySymbol = '', subscriptionCurrency } = subscribe && Object.keys(subscribe).length > 0 && subscribe[activeDomain];

  const resetRecurringPlan = () => {
    setRecurringInterval(['month', 'quarter', 'halfyear', 'year'])
    setActiveRecurringInterval('month')
  }

  const addFinalPlan = () => {
    if (activeType !== '#3' && !planPrice) return;

    // for recurring
    if (activeType === "#1") {
      const payload = {
        name: activeRecurringInterval,
        interval: activeRecurringInterval,
        price: +planPrice,
        currency: subscriptionCurrency,
        description: activeRecurringInterval,
        type: "RECURRING",
      }
      const remaningRecurringInterval = recurringInterval.filter((item) => item !== activeRecurringInterval);
      if (remaningRecurringInterval.length === 0) {
        setisAddPlan(false)
      }
      else {
        setActiveRecurringInterval(remaningRecurringInterval[0])
      }
      if(count===1){
        setActiveFirstRecurringInterval(activeRecurringInterval)
        const remaningFirstRecurringInterval = firstRecurringInterval.filter((item) => item !== remaningRecurringInterval[0]);
        setFirstRecurringInterval(remaningFirstRecurringInterval);
      }
      setRecurringInterval(remaningRecurringInterval)
      setFinalPlan(finalPlan.concat(payload))
      setPlanPrice('')
      setCount(c=>c+1);
    }

    //for one time
    if (activeType === "#2") {
      const payload = {
        name: "ONETIME",
        interval: "indefinite",
        price: +planPrice,
        currency: subscriptionCurrency,
        description: "One time access plan",
        type: "ONETIME",
      }
      resetRecurringPlan();
      setFinalPlan([payload]);
      setisAddPlan(false)
    }
    if (activeType === '#3') {
      const payload = {
        name: "FREE",
        interval: "indefinite",
        price: 0,
        currency: subscriptionCurrency,
        description: "free access plan",
        type: "FREE",
      }
      resetRecurringPlan();
      setFinalPlan([payload]);
      setisAddPlan(false);
    }
  }
  const removeFinalPlan = (option)=>{
    if (activeType === "#1") {
     try {
     const remaningFilterPlan =  finalPlan.filter((item)=>item.interval !== option.interval);
     setFinalPlan(remaningFilterPlan);
     const newRecurringInterval = recurringInterval.concat([option.interval]);
     setisAddPlan(true)
     setActiveRecurringInterval(newRecurringInterval[0])
     setRecurringInterval(newRecurringInterval);
     }
     catch(err){
      console.log("err",err)
     }
    }
    if(activeType ==="#2"){
      try {
        resetRecurringPlan();
        setFinalPlan([]);
        setPlanPrice('');
        setisAddPlan(true)
        }
        catch(err){
         console.log("err",err)
        }
    }
    if(activeType ==="#3"){
      try {
        resetRecurringPlan();
        setFinalPlan([]);
        setPlanPrice('');
        setisAddPlan(true)
        }
        catch(err){
         console.log("err",err)
        }
    }
  }
  useImperativeHandle(ref,()=>finalPlan)
  return (

    <Box>

      <Box mb={3}>
        <Box component="span" fontWeight="bold" >Plans</Box>
      </Box>
      {/* Showing plans start*/}
      <Box>
          {
            finalPlan && finalPlan.map((planItem)=>{
              return <Box display="flex" key={planItem.interval} mb={2}>
                <Box>
                 <FormControl variant="outlined" className={classes.formControl} size="small" disabled>
                <Select
                  labelId="type"
                  id={planItem.type}
                  value={planItem.type}
                  onChange={()=>{}}
                >
                  <MenuItem value={planItem.type}>
                    {planItem.type==="RECURRING" && "Recurring"}
                    {planItem.type==="ONETIME" && "Onetime"}
                    {planItem.type==="FREE" && "Free"}
                  </MenuItem>
                </Select>
              </FormControl>
              </Box>
              <Box ml={2}>
                 <FormControl style={{width:'120px'}} variant="outlined" className={classes.formControl} size="small" disabled>
                <Select
                  labelId="interval"
                  id={planItem.interval}
                  value={planItem.interval}
                  // onChange={(e) => setActiveFirstRecurringInterval(e.target.value)}
                >
                  
                    <MenuItem value={planItem.interval}>
                    {planItem.type ==="ONETIME"?"_":planItem.interval}
                    </MenuItem>
                  
                  {/* {
                      firstRecurringInterval && firstRecurringInterval.map((item, key) => {
                        return <MenuItem key={key} value={item}>{item}</MenuItem>
                      })
                    } */}
                </Select>
              </FormControl>
              </Box>
              {activeType !== '#3' && <Box>
              <Input
                style={{ width: '100px', marginLeft: isMobile ? '0px' : '8px', marginRight: '16px' }}
                size="small"
                placeholder="0.00"
                value={planItem.price}
                disabled
                onChange={(e) => { }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box component="span">
                        {currencySymbol}
                      </Box>
                    </InputAdornment>
                  ),
                }}
                
              />
              </Box>}
              <Box ml={2}>
                <Button variant="contained" color="secondary" onClick={()=>removeFinalPlan(planItem)}>remove</Button>
              </Box>
              </Box>
            })
          }
      </Box>
      {/* Showing plans end*/}
      {
        isAddPlan &&
        <Box>
          <Box display="flex">
            <Box>
              <FormControl variant="outlined" className={classes.formControl} size="small">
                <Select
                  labelId="type"
                  id="type"
                  value={activeType}
                  placeholder="select type"
                  onChange={(e) => setActiveType(e.target.value)}
                >
                  <MenuItem value={"#2"}>One time payment</MenuItem>
                  <MenuItem value={"#1"}>Recurring </MenuItem>
                  <MenuItem value={"#3"}>Free </MenuItem>
                </Select>
              </FormControl>
            </Box>
            {activeType === "#1" &&
              <Box ml={2}>
                <FormControl style={{width:"120px"}} variant="outlined" className={classes.formControl} size="small">
                  <Select
                    labelId="type"
                    id="type"
                    value={activeRecurringInterval}
                    placeholder="select type"
                    onChange={(e) =>{
                      setActiveRecurringInterval(e.target.value)
                      if(count===1){
                        const remaningFirstRecurringInterval = firstRecurringInterval.filter((item) => item !== e.target.value);
                        setFirstRecurringInterval(remaningFirstRecurringInterval);
                      }
                    
                    }}
                  >
                    {
                      recurringInterval && recurringInterval.map((item, key) => {
                        return <MenuItem key={key} value={item}>{item}</MenuItem>
                      })
                    }
                  </Select>
                </FormControl>
              </Box>
            }
            {activeType !== '#3' && <Box>
              <Input
                style={{ width: '100px', marginLeft: isMobile ? '0px' : '8px', marginRight: '16px' }}
                size="small"
                placeholder="0.00"
                value={planPrice}
                onChange={(e) => { setPlanPrice(e.currentTarget.value) }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box component="span">
                        {currencySymbol}
                      </Box>
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  min: 0,
                  step: 1,
                }}
                type="number"
              />
            </Box>}
          </Box>
          <Button variant="contained" color="secondary" boxProps={{ mt: 2 }} onClick={addFinalPlan}>ADD</Button>
        </Box>
      }
    </Box>
  )
}

export default forwardRef(AddPlan);
