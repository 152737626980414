/**
 * Input Box with heading
 */
import React from 'react';
import Input from "./Input";
import PropTypes from 'prop-types';
import Box from "./Box";
/**
 * 
 * Input box take two styles 
 *   heading : heading of input box
 *   boxStyle : style of whole input box
 *   headingStyle : style of heading 
 */
function InputBox(props) {
  const {
    heading,
    headingStyle,
    boxStyle,
    ...rest
  } = props;
  return <Box style ={boxStyle}>
    <Box component="p" {...headingStyle}>{heading}</Box>
    <Input {...rest} />
  </Box>
}
InputBox.propTypes = {
  heading: PropTypes.string.isRequired,
  headingStyle : PropTypes.object,
  boxStyle : PropTypes.object,
}
InputBox.defaultProps = {
  heading: 'heading',
  headingStyle :{},
  boxStyle :{},
}
export default InputBox;
