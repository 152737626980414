import setAppMessage from "../../app-component/app-component-action";
import { LogglyTracker } from 'loggly-jslogger';

const logger = new LogglyTracker();
logger.push({ 'logglyKey': '142d21f5-10d3-47a9-aa5a-f83e0a3abed7', 'sendConsoleErrors' : true, 'tag' : 'ardbeg,advertisement,advertisement-action' });

export default function getAdsDetail(){
  return async(dispatch,getState,apiHandler)=>{
    const { api, apiRoutes } = apiHandler;
    const { activeDomain, userProfile } = getState().landing;
    try{
       const {data} = await api(apiRoutes.adDetails(activeDomain));
       return Promise.resolve(data)
    }
    catch(err){
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      dispatch(setAppMessage({ message: err.message, header: 'Error', type: 'alert' }))
    }
  }
}

export function setAdsDetail(){
  return async(dispatch,getState,apiHandler)=>{
    const { api, apiRoutes, actionTypes } = apiHandler;
    const { activeDomain, userProfile } = getState().landing;
    try{
       let { data } = await api(apiRoutes.setConfigureAds(activeDomain));
       dispatch({type:actionTypes.ADS.SET.SUCCESS})
       dispatch(setAppMessage({ message: data && data.message, header: 'Success', type: 'alert' }))
       return Promise.resolve(data)
      }
    catch(err){
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      dispatch(setAppMessage({ message: err.message, header: 'Error', type: 'alert' }))
      return Promise.reject()
    }
  }
}

export function genereateOTP(mobileData){
  return async(dispatch,getState,apiHandler)=>{
    const { api, apiRoutes } = apiHandler;
    const { activeDomain, userProfile } = getState().landing;
    try{
       let { data } = await api(apiRoutes.getOTP(activeDomain,mobileData));
      dispatch(setAppMessage({ message: data && data.message, header: 'Success', type: 'alert' }))
      return Promise.resolve(data);
      }
    catch(err){
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      dispatch(setAppMessage({ message: err.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}
export function verifyOTP(otpDetail){
  return async(dispatch,getState,apiHandler)=>{
    const { api, apiRoutes } = apiHandler;
    const { activeDomain, userProfile } = getState().landing;
    try{
       let { data } = await api(apiRoutes.setOTP(activeDomain,otpDetail));
      return Promise.resolve(data);
      }
    catch(err){
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      dispatch(setAppMessage({ message: err.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}
export function getUserBankDetail(){
  return async(dispatch,getState,apiHandler)=>{
    const { api, apiRoutes } = apiHandler;
    const { activeDomain, userProfile } = getState().landing;
    try{
       let { data } = await api(apiRoutes.getBankDetail(activeDomain));
      return Promise.resolve(data);
      }
    catch(err){
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      dispatch(setAppMessage({ message: err.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}
export function setUserbankdetail(bankDetail){
  return async(dispatch,getState,apiHandler)=>{
    const { api, apiRoutes } = apiHandler;
    const { activeDomain, userProfile } = getState().landing;
    try{
       let { data } = await api(apiRoutes.updateBankDetails(activeDomain,bankDetail));
       dispatch(setAppMessage({ message: data && data.message, header: 'Success', type: 'alert' }))
      return Promise.resolve(data);
      }
    catch(err){
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      dispatch(setAppMessage({ message: err.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}
export function agreement(){
  return async(dispatch,getState,apiHandler)=>{
    const { api, apiRoutes } = apiHandler;
    const { activeDomain, userProfile } = getState().landing;
    try{
      let { data } = await api(apiRoutes.fillAgreement(activeDomain));
     dispatch(setAppMessage({ message: "Agreement succeeded !!!", header: 'Success', type: 'alert' }))
     return Promise.resolve(data);
     }
   catch(err){
    const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
     dispatch(setAppMessage({ message: err.message, header: 'Error', type: 'alert' }))
     return Promise.reject(err);
   }
  }
}
export function showMessage(message){
  return (dispatch)=>{
    dispatch(setAppMessage({ message: message, header: 'Error', type: 'alert' }))
  }
}



export function updateAdsInfo(payload){

  return async(dispatch,getState,apiHandler)=>{
    const { api, apiRoutes } = apiHandler;
    const { activeDomain, userProfile } = getState().landing;
    try{
       let { data } = await api(apiRoutes.updateAdsInfo(activeDomain,payload));
      dispatch(setAppMessage({ message: data && data.message, header: 'Success', type: 'alert' }))
      return Promise.resolve(data);
      }
    catch(err){
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      dispatch(setAppMessage({ message: err.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
  
}

export function bankDetails(payload){
  return async(dispatch,getState,apiHandler)=>{
    const { api, apiRoutes } = apiHandler;
    const { activeDomain, userProfile } = getState().landing;
    try{
       let { data } = await api(apiRoutes.updateBankDetails(activeDomain,payload));
      dispatch(setAppMessage({ message: data && data.message, header: 'Success', type: 'alert' }))
      return Promise.resolve(data);
      }
    catch(err){
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      dispatch(setAppMessage({ message: err.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }

}
