import React from 'react';
import SettingList from "./components/SettingListRenderer";
import Paper from "commons/muiCustomComponents/Paper";
import Grid from "commons/muiCustomComponents/Grid";
import Box from "commons/muiCustomComponents/Box";
import MobileProvider from "commons/context/MobileProvider";
import { useDispatch, useSelector } from "react-redux";


let settingsList = [
  {name:"Name and Logo",link:'/dash/setting/websites/name&logo'},
  {name:"Google Analytics",link:'/dash/setting/websites/googleanalytics'},
  {name:"Social Accounts",link:'/dash/setting/websites/socialaccounts'},
  {name:"Pages",link:'/dash/setting/website/pages'},
  {name:"Connect a Domain you own",link:'/dash/setting/website/owndomain'},
  {name:"Ads",link:'/dash/monetization/ads'},
  {name:"Enable SEO",link:'/dash/setting/website/webmaster-domain-map'},
  {name:"Delete my website",link:'/dash/setting/website/deletemywebsite'},
]

export default function SettingHome(){
  const isMobile = React.useContext(MobileProvider);
  const {
    landing: { userPermissions },
  } = useSelector(state => state);
    if (!(userPermissions.indexOf('EARN:MANAGE_ADS') > -1)){
      settingsList =  settingsList.filter((item)=>item.name !== "Ads")
    }
    return(
      <Grid container >
        <Grid item xs= {12} md={9} xl={10}  >
          <Box m={isMobile? 2:0} mt={isMobile?4:0}>
            <Paper elevation={1}>
              <Box>
                <SettingList settingsList ={settingsList}/>
              </Box>  
            </Paper>
          </Box>
           
        </Grid>
      </Grid>
        
    )
}

