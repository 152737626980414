
import storyAction from "../../constants/actions/story";

const storyDefaultState = {
  storyFeed : [],
  storyRealWidth :'',
}

export default function story(state = storyDefaultState,action){
    
    switch(action.type) {
        case storyAction.FETCH.SUCCESS: {
            return {
                ...state,
                storyFeed : action.storyFeed,
              }
        }
        case storyAction.WIDTH.SET:{
            return {
              ...state,
              storyRealWidth : action.width,
            }
        }
        default:
          return state;
      }
}