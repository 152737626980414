import React from "react";
import AppBar from "@material-ui/core/AppBar";
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  subheader: {
    height:`${theme.isMobile ? '120px': '90px'}`,
    display:'flex',
    alignItems:'center',
    justifyContent:"center",
    color:theme.palette.pubGrey,
    top:'auto',
    bottom:'0',
    boxShadow:'none',
    backgroundColor:theme.palette.pubBackground,
  },
}));

function Header (props) {
  const classes = useStyles();
  return (
    <AppBar className={classes.subheader}>
      {props.children}
    </AppBar>
  )
}

export default Header;