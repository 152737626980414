import React from 'react';
import StepContent from '@material-ui/core/StepContent';

class  CustomStepContent extends React.Component {
  render() {
    const {...rest} = this.props;
    return (<StepContent {...rest}  />)
  }
}

export default CustomStepContent;