import SignupPage from "../pages/SignupPage";
import LoginPage from "../pages/LoginPage";
import LogoutPage from "../pages/LogoutPage";
import LandingPage from "../pages/LandingPage";
import OnBoardPage from "../pages/OnBoard";
import OnBoardAbout from "../components/onboard/components/OnBoardAbout";
import OnBoardDomain from "../components/onboard/components/OnBoardDomain";
// import OnBoardShare from "../components/onboard/components/OnBoardShare";
import ForgetOPasswordPage from "../pages/ForgetPasswordPage";
import ResetPasswordPage from "../pages/ResetPasswordPage";

/**
 * Contains the app routes in the app that needs to be
 * registered with the react router without the dashboard layout.
 * Sample route
 * {
 *  path:"/create", // path to shown in url
 * 
 *  component: CreatePage, // component that needs to be rendered
 *  isAuthRequired:true or false // if the route is protected, defaults to false
 *  withDashboard : true or flase, // load the page with dashboard or without dashboard, default
 *                  to false
 *  requiredFeaturePermission: PERM_NAME or null // if the route need any feature permission
 *  requiredPermissions : [] // all the permissions required for the route to be registered
 *  permissionsCheckType: 'OR' or 'AND' // the way we want to check the permissions choose OR 
 *                         if any one permission is fine, choose AND if all the permission are
 *                         needed defaults to OR
 *
 * }
 */
const routes = [
  {
    path:"/login",
    component: LoginPage,
    isAuthRequired:false,
  },
  {
    path:"/logout",
    component: LogoutPage,
    isAuthRequired:false,
  },
  {
    path:"/forgot-password",
    component: ForgetOPasswordPage,
    isAuthRequired:false,
  },
  {
    path:"/signup",
    component: SignupPage,
    isAuthRequired:false,
  },
  {
    path:"/onboard",
    component: OnBoardPage,
    isAuthRequired:false,
    requiredFeaturePermission:null,
    requiredUserPermissions: [],
    permissionsCheckType:"AND",
  },
  {
    path:"/onboard/about",
    component: OnBoardAbout,
    isAuthRequired:false,
  },
  {
    path:"/onboard/domain",
    component: OnBoardDomain,
    isAuthRequired:false,
  },
  // {
  //   path:"/onboard/share",
  //   component: OnBoardShare,
  //   isAuthRequired:false,
  // },
  {
    path:"/auth/password/reset/:tokenId",
    component: ResetPasswordPage,
    isAuthRequired:false,
  },
  /** This route has to be at the last of the array*/
  {
    path:"/",
    component: LandingPage,
    isAuthRequired: false,
  },
];

export default routes;