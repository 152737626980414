import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Box from "commons/muiCustomComponents/Box";
import { useDispatch, useSelector } from "react-redux";
import Button from "commons/muiCustomComponents/Button";
import { CircularLoader, LinearLoader, PageBlockLoader } from 'commons/commonWrapper/Loaders';
import moment from "moment"
import { useHistory } from "react-router-dom";
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Divider from '@material-ui/core/Divider';
import Input from "commons/muiCustomComponents/Input";
import InputAdornment from '@material-ui/core/InputAdornment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MobileProvider from "commons/context/MobileProvider";
import { unpublishPlanById, addNewPlan ,publishPlanById,editPlanAction} from "../monetization-action";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  textdecoration: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  heighPowerFont: {
    fontWeight: 600,
  },
  flexClasses: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  productBackground: {
    backgroundColor: theme.palette.secondary.light,
    border: 'none',
    "&:hover,&:focus": {
      backgroundColor: theme.palette.secondary.light,
      border: 'none',
    },
  },
}));


export default function EditPlan({ plans = [], productId, currencyVal,setPlans }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = React.useContext(MobileProvider);
  const [rows, setRows] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [fetchingProduct, setFetchingProduct] = useState(false);
  const { landing: { activeDomain, userProfile } } = useSelector((state) => state);
  const [isLoading, setLoading] = useState(false);
  const [activeprodcutId, setActiveproductId] = useState(null);
  const [activePlan, setActivePlan] = useState(null);
  const [isShowOption, setShowOption] = useState(true);
  const [activeType, setActiveType] = useState("#1")
  const [recurringInterval, setRecurringInterval] = useState(['month', 'quarter', 'halfyear', 'year']);
  const [activeRecurringInterval, setActiveRecurringInterval] = useState('month');
  const [isEdit, setEdit] = useState(false);
  const [planPrice, setPlanPrice] = useState('');
  const [isAdd, setAdd] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [finalPlans, setFinalPlans] = useState([]);
  const [addnigPlan, setAddingPlan] = useState(false);
  const [activeMonthIntervalId ,setActiveMonthIntervalId] = useState(null);
  const [activeYearIntervalId ,setActiveYearIntervalId] = useState(null);
  const [activeHalfyearIntervalId ,setActiveHalfYearIntervalId] = useState(null);
  const [activeQuarterIntervalId ,setActiveQuarterIntervalId] = useState(null);
  const [isEditingPlan ,setEditingPlan] = useState(false);
  const [oneTimeIntervalId ,setOneTimeIntervalId] = useState(null);

  useEffect(() => {
    if (plans.length > 0) {
      let tempRecurringInterval = recurringInterval;
      plans.forEach((item) => {
        if (item.type === "ONETIME" && item.status===1) {
          setActiveType("#2")
          if(item.status===1){
            setOneTimeIntervalId(item.id)
          }
        }
        if (item.type === "FREE" && item.status===1) {
          setActiveType("#3")
          if(item.status===1){
            setOneTimeIntervalId(item.id)
          }
        }
        if (item.status === 1) {
          tempRecurringInterval = tempRecurringInterval.filter((option) => option !== item.interval)
        }
      });
      setRecurringInterval(tempRecurringInterval)
      
      plans.forEach((item)=>{
        if(item.interval==="month" && item.status ===1){
          setActiveMonthIntervalId(item.id)
        }
        if(item.interval==="year" && item.status ===1){
          setActiveYearIntervalId(item.id)
        }
        if(item.interval==="quarter" && item.status ===1){
          setActiveQuarterIntervalId(item.id)
        }
        if(item.interval==="halfyear" && item.status ===1){
          setActiveHalfYearIntervalId(item.id)
        }
      })
      setFinalPlans(plans)
    }

  }, [plans.length])

  const goToEditPage = (row) => {
    history.push(`/dash/subscripition/edit-product/${row.id}`);
  }
  const manageProd = () => {
    history.push('/dash/subscripition/manage-product');
  }

  const resetRecurringPlan = () => {
    setRecurringInterval(['month', 'quarter', 'halfyear', 'year'])
    setActiveRecurringInterval('month')
  }
  if (fetchingProduct) {
    return <Box pt={4} pb={4} display="flex" justifyContent="center" width="100%">
      <CircularLoader height="auto" message="Fetching products..." />
    </Box>
  }

  const open = Boolean(anchorEl);

  const handleSelectPlan = (event, acPlan) => {
    setAnchorEl(event.currentTarget);
    setActivePlan(acPlan);
  }

  const handleClose = () => {
    setAnchorEl(null);
    // setEdit(false);
  }

  const handleEdit = async() => {

    let tempRecurringInterval = recurringInterval;
    try {
      finalPlans.forEach(element => {
        if (element.interval === "indefinite" && element.status==1) {
          if(element.type === 'FREE') {
            setActiveType("#3");
          } else {
            setActiveType("#2");
          }
          if (element.status === 1) {
            setOneTimeIntervalId(element.id)
          }
        }
        else {
          setActiveType("#1")
          if (element.status === 1) {
            tempRecurringInterval = tempRecurringInterval.filter((item) => item !== element.interval)
          }
        }
      });
      tempRecurringInterval.unshift(activePlan.interval)
      setActiveRecurringInterval(tempRecurringInterval[0]);
      setRecurringInterval(tempRecurringInterval);
      setPlanPrice(activePlan.price)
      setEdit(true)
      setAnchorEl(null);
      setAdd(false);
    }
    catch(err){
     console.log("err",err)
    }
   
  }
  const discardPlan = () => {
    setEdit(false);
    resetRecurringPlan();
    setAdd(true);
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }

  const openDialogaddNewPlan = () => {
    setOpenDialog(true);
    let tempRecurringInterval = recurringInterval;

    finalPlans.forEach(element => {
      if (element.status === 1) {
        tempRecurringInterval = tempRecurringInterval.filter((item) => item !== element.interval)
      }
    });
    setActiveRecurringInterval(tempRecurringInterval[0]);
    setRecurringInterval(tempRecurringInterval);
  }

  const unpublishPlan = async () => {
    // console.log("activePlan",activePlan)
    try {
      setLoading(true)
      await dispatch(unpublishPlanById(productId, activePlan.id));
      finalPlans.forEach((item)=>{
        if(item.id===activePlan.id){
          item.status = 0
        }
      })
      if(activePlan.interval==="month"){
        setActiveMonthIntervalId(null);
      }
      if(activePlan.interval==="quarter"){
        setActiveQuarterIntervalId(null);
      }
      if(activePlan.interval==="year"){
        setActiveYearIntervalId(null);
      }
      if(activePlan.interval==="halfyear"){
        setActiveHalfYearIntervalId(null);
      }
      if(activePlan.interval ==="indefinite"){
        setOneTimeIntervalId(null)
      }
      setFinalPlans([...finalPlans])
      handleClose()
      setLoading(false)
    }
    catch (err) {
      setLoading(false)
    }
  }

  const publishPlan = async () => {
    try {
      setLoading(true)
      await dispatch(publishPlanById(productId, activePlan.id))
      finalPlans.forEach((item)=>{
        if(item.id===activePlan.id){
          item.status = 1
        }
      })
      if(activePlan.interval==="month"){
        setActiveMonthIntervalId(activePlan.id);
      }
      if(activePlan.interval==="quarter"){
        setActiveQuarterIntervalId(activePlan.id);
      }
      if(activePlan.interval==="year"){
        setActiveYearIntervalId(activePlan.id);
      }
      if(activePlan.interval==="halfyear"){
        setActiveHalfYearIntervalId(activePlan.id);
      }
      if(activePlan.interval ==="indefinite"){
        setOneTimeIntervalId(activePlan.id)
      }
      setFinalPlans([...finalPlans])
      handleClose()
      setLoading(false)
    }
    catch (err) {
      setLoading(false)
    }
  }

  const saveNewPlan = async () => {
    try {
      setAddingPlan(true)
      const data = await dispatch(addNewPlan({
        product_id: +productId,
        name: (activeType==="#2" || activeType === '#3') ?'indefinite': activeRecurringInterval,
        currency: currencyVal,
        price: activeType === '#3' ? 0 : +planPrice,
        interval: (activeType==="#2" || activeType === '#3') ?'indefinite':activeRecurringInterval,
        description: (activeType==="#2" || activeType === '#3') ?'indefinite':activeRecurringInterval,
        type: activeType==="#2" ?"ONETIME": activeType === '#3' ? "FREE" :"RECURRING",
      }));
      if(activeRecurringInterval==="month"){
        setActiveMonthIntervalId(data.id);
      }
      if(activeRecurringInterval==="quarter"){
        setActiveQuarterIntervalId(data.id);
      }
      if(activeRecurringInterval==="year"){
        setActiveYearIntervalId(data.id);
      }
      if(activeRecurringInterval==="halfyear"){
        setActiveHalfYearIntervalId(data.id);
      }
      if(activeType === "#2"){
        setOneTimeIntervalId(data.id)
      }
      setAddingPlan(false);
      const tempFinalPlans = finalPlans;
      tempFinalPlans.push(data.plan)
      setPlans([...tempFinalPlans])
      setFinalPlans([...tempFinalPlans])
      setOpenDialog(false)
    }
    catch (err) {
      console.log("err", err)
      setAddingPlan(false)
    }
  }

  const saveEditPlan = async()=>{
    try {
    setEditingPlan(true)
    const tempPlan = {...activePlan}
    activePlan.price=planPrice;
    let payload = {
      product_id: +productId,
      name: activeRecurringInterval,
      currency: currencyVal,
      price: +planPrice,
      interval: activeRecurringInterval,
      description: activeRecurringInterval,
      type: "RECURRING",
      id :activePlan.id,
    }
    if(activeType==="#2"){
      payload = {
        product_id: +productId,
        name: "indefinite",
        currency: currencyVal,
        price: +planPrice,
        interval: "indefinite",
        description: "indefinite",
        type: "ONETIME",
        id :activePlan.id,
      }
    }
    if (activeType === '#3') {
      const payload = {
        product_id: +productId,
        name: "FREE",
        interval: "indefinite",
        price: 0,
        currency: currencyVal,
        description: "free access plan",
        type: "FREE",
        id :activePlan.id,
      }
    }
    const data = await dispatch(editPlanAction(productId,payload));
    let tempfinalPlans = finalPlans;
    tempPlan.status = 0;
    tempfinalPlans = tempfinalPlans.filter((item)=>item.id !== tempPlan.id)
    tempfinalPlans.push(tempPlan);
    tempfinalPlans.push(data.plan);
    setFinalPlans(tempfinalPlans);
    setEditingPlan(false);
    discardPlan();
    
    if(data.plan.interval==="month"){
      setActiveMonthIntervalId(data.plan.id);
    }
    if(data.plan.interval==="quarter"){
      setActiveQuarterIntervalId(data.plan.id);
    }
    if(data.plan.interval==="year"){
      setActiveYearIntervalId(data.plan.id);
    }
    if(data.plan.interval==="halfyear"){
      setActiveHalfYearIntervalId(data.plan.id);
    }
    if(data.plan.interval ==="indefinite"){
      setOneTimeIntervalId(data.plan.id)
    }
    }
    catch(err){
      setEditingPlan(false)
    }
  }

  const getStatus = (option,id)=>{
    let isReturn =false;
     finalPlans.forEach((item)=>{
      if(item.status ==1 && item.interval==="indefinite"){
        isReturn =true;
      }
      if(item.status ==1 && item.interval==="month"){
        isReturn =false;
      }
      if(item.status ==1 && item.interval==="quarter"){
        isReturn =false;
      }
      if(item.status ==1 && item.interval==="year"){
        isReturn =false;
      }
      if(item.status ==1 && item.interval ==="halfyear"){
        isReturn =false;
      }
    })
    if(!getActiveStatus() && option ==="indefinite"){
      isReturn =true;
    }

    if(isReturn && option ==="indefinite" &&( oneTimeIntervalId==id  || oneTimeIntervalId==null)){
      return true;
    }

    if(isReturn){
      return false;
    }

    

    if(option==="month" &&( activeMonthIntervalId==id  || activeMonthIntervalId==null)){
      return true;
    }
    if(option==="quarter" && (activeQuarterIntervalId==id || activeQuarterIntervalId==null)){
      return true;
    }
    if(option==="year" && (activeYearIntervalId==id || activeYearIntervalId==null)){
      return true;
    }
    if(option==="halfyear" && (activeHalfyearIntervalId==id || activeHalfyearIntervalId==null)){
      return true;
    }
   
    return false;

  }

  const getActiveStatus = ()=>{
    let activePlan = false;
    finalPlans.forEach((item)=>{
      if(item.status ==1){
        activePlan=true
      }
    })
    return activePlan ? true :false;
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.heighPowerFont}>PLANS</TableCell>
              <TableCell className={classes.heighPowerFont}>FREQUENCY</TableCell>
              <TableCell className={classes.heighPowerFont}>SUBSCRIBERS</TableCell>
              <TableCell className={classes.heighPowerFont}>PRICE</TableCell>
              <TableCell className={classes.heighPowerFont}>STATUS</TableCell>
              <TableCell className={classes.heighPowerFont}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {
              finalPlans.map(row => (
                <TableRow key={row.id}>
                  {isEdit && activePlan && activePlan.status===1 && 
                  activePlan.id === row.id && getStatus(row.interval,row.id)?
                    <>
                      <TableCell component="th" scope="row">
                        <FormControl variant="outlined" className={classes.formControl} size="small" 
                        disabled>
                          <Select
                            labelId="type"
                            id="type"
                            value={activeType}
                            placeholder="select type"
                            onChange={(e) => setActiveType(e.target.value)}
                          >
                            <MenuItem value={"#2"}>One time payment</MenuItem>
                            <MenuItem value={"#1"}>Recurring </MenuItem>
                            <MenuItem value={"#3"}>Free </MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell >
                        {activeType === "#1" &&
                          <Box ml={2}>
                            <FormControl style={{ width: "120px" }} variant="outlined" className={classes.formControl} size="small">
                              <Select
                                labelId="type"
                                id="type"
                                value={activeRecurringInterval}
                                placeholder="select type"
                                onChange={(e) => setActiveRecurringInterval(e.target.value)}
                              >
                                {
                                  recurringInterval && recurringInterval.map((item, key) => {
                                    return <MenuItem key={key} value={item}>{item}</MenuItem>
                                  })
                                }
                              </Select>
                            </FormControl>
                          </Box>

                        }
                      </TableCell>
                      <TableCell >
                        <Box >{row.subscriberCount || 0}</Box>
                      </TableCell>
                      <TableCell >
                        <Input
                          // style={{ width: '100px', marginLeft: isMobile ? '0px' : '8px', marginRight: '16px' }}
                          size="small"
                          placeholder="0.00"
                          value={planPrice}
                          disabled={activeType === '#3'}
                          onChange={(e) => { setPlanPrice(e.currentTarget.value) }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Box component="span">
                                  {/* {currencySymbol} */}
                                </Box>
                              </InputAdornment>
                            ),
                          }}
                          inputProps={{
                            min: 0,
                            step: 1,
                          }}
                          type="number"
                        />
                      </TableCell>
                      <TableCell  >
                        {row.status === 1 ? "Published" : "Unpublished"}
                      </TableCell>
                      <TableCell>

                      </TableCell>
                    </>
                    :
                    <>
                      <TableCell component="th" scope="row">
                        {row.type}
                      </TableCell>
                      <TableCell >
                        {(row.type==="ONETIME" || row.type==="FREE")?"_":row.interval}
                      </TableCell>
                      <TableCell >
                        <Box >{row.subscriberCount || 0}</Box>
                      </TableCell>
                      <TableCell >{row.price}</TableCell>
                      <TableCell  >
                        {row.status === 1 ? "Published" : "Unpublished"}
                      </TableCell>
                      <TableCell>
                        
                        { !isEdit && getStatus(row.interval,row.id)&&
                          <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={(e) => handleSelectPlan(e, row)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        }
                      </TableCell>
                    </>
                  }
                </TableRow>

              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {
          activePlan && activePlan.status === 0 ?
            <MenuItem onClick={publishPlan}>
              <Box display="flex" alignItems="center">
                Publish
                {isLoading && <CircularLoader size={20} height="auto" />}
              </Box>
            </MenuItem>
            :
            <>
              <MenuItem onClick={handleEdit}>
                Edit
                {isEditingPlan && <CircularLoader size={20} height="auto"/>}
              </MenuItem>
              <MenuItem onClick={unpublishPlan}>
                <Box display="flex" alignItems="center">
                  Unpublish
                {isLoading && <CircularLoader size={20} height="auto" />}
                </Box>
              </MenuItem>
            </>
        }
      </Menu>

      <Divider />

      <Box p={3} display="flex" justifyContent="flex-end">
        {
          isEdit && <>
            <Button variant="contained" color="secondary" onClick={discardPlan}>Close</Button>
        <Button disabled={isEditingPlan} variant="contained" color="primary" onClick={saveEditPlan} boxProps={{ ml: 2 }}>Save</Button> 
        {isEditingPlan && <CircularLoader size={20} height="auto"/>
        }
          </>
        }
        {
          activeType === "#1" && recurringInterval.length>0 && 
          isAdd && <Button variant="contained" color="primary" onClick={openDialogaddNewPlan}>ADD</Button>
        }
        {
          activeType ==="#2" && !getActiveStatus() && 
          <Button variant="contained" color="primary" onClick={openDialogaddNewPlan}>ADD</Button>
        }
        {
          activeType ==="#3" && !getActiveStatus() && 
          <Button variant="contained" color="primary" onClick={openDialogaddNewPlan}>ADD</Button>
        }
      </Box>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Plans</DialogTitle>
        <DialogContent>
          <Box display="flex">
            <FormControl variant="outlined" className={classes.formControl} size="small" disabled={getActiveStatus()}>
              <Select
                labelId="type"
                id="type"
                value={activeType}
                placeholder="select type"
                onChange={(e) => setActiveType(e.target.value)}
              >
                <MenuItem value={"#2"}>One time payment</MenuItem>
                <MenuItem value={"#1"}>Recurring </MenuItem>
                <MenuItem value={"#3"}>Free </MenuItem>
              </Select>
            </FormControl>
            {activeType === "#1" &&
              <Box ml={2}>
                <FormControl style={{ width: "120px" }} variant="outlined" className={classes.formControl} size="small">
                  <Select
                    labelId="type"
                    id="type"
                    value={activeRecurringInterval}
                    placeholder="select type"
                    onChange={(e) => setActiveRecurringInterval(e.target.value)}
                  >
                    {
                      recurringInterval && recurringInterval.map((item, key) => {
                        return <MenuItem key={key} value={item}>{item}</MenuItem>
                      })
                    }
                  </Select>
                </FormControl>
              </Box>
            }
            <Box>
              <Input
                style={{ width: '100px', marginLeft: isMobile ? '0px' : '8px', marginRight: '16px' }}
                size="small"
                disabled={activeType === '#3'}
                placeholder="0.00"
                value={planPrice}
                onChange={(e) => { setPlanPrice(e.currentTarget.value) }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box component="span">
                        {/* {currencySymbol} */}
                      </Box>
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  min: 0,
                  step: 1,
                }}
                type="number"
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary" variant="contained">
            Close
          </Button>
          <Box display="flex">
            <Button onClick={saveNewPlan} color="primary" variant="contained" autoFocus>
              save
          </Button>
            {addnigPlan && <CircularLoader size={20} height="auto" />}
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

