import React, {useEffect, useState, useContext} from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, registerUser, sendLoginOtp, sendPhoneVerifyOtp } from "./auth-action";
import { useHistory, useLocation } from "react-router-dom";
import Box from "../../commons/muiCustomComponents/Box";
import { makeStyles } from '@material-ui/core/styles';
import Input from "../../commons/muiCustomComponents/Input";
import Button from "../../commons/muiCustomComponents/Button";
import Divider from '@material-ui/core/Divider';
import MobileProvider from "commons/context/MobileProvider";
import { LinearLoader } from "commons/commonWrapper/Loaders";
import setAppMessage from "app-component/app-component-action";
import { fbPixelEvent } from "utils/facebook-pixel-client";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import phoneCountryList from "../common/phoneCountry";

import { registerSignupEvent } from "utils/adwords-client";
import { LogglyTracker } from 'loggly-jslogger';

const logger = new LogglyTracker();
logger.push({ 'logglyKey': '142d21f5-10d3-47a9-aa5a-f83e0a3abed7', 'sendConsoleErrors' : true, 'tag' : 'ardbeg,auth,signup-home' });

const emailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const otpFormat = /^\d{4}$/;

const useStyles = makeStyles(theme => ({
  headerBarMobile: {
    width:'100%',
    height:theme.spacing(20),
    backgroundColor:theme.palette.pubBlack,
    color:theme.palette.pubWhite,
  },
  headerBarDesktop : {
    width:'100%',
    height:theme.spacing(20),
    borderBottom: `1px solid ${theme.palette.pubbrownGrey}`,
    textAlign:'left',
  },
  headingTextDesktop: {
    fontSize:theme.spacing(6),
    paddingLeft:theme.spacing(8),
  },
  headingTextMobile: {
    fontSize:theme.spacing(9),
    letterSpacing:theme.spacing(2),
  },
  containerBox: {
    maxWidth:'400px',
    margin:"auto",
    height:"65vh",
  },
  signup: {
    position:"relative",
    top:'10%',
  },
  forgotPassword: {
    cursor:"pointer",
  },
  termsAndCond: {
    margin: theme.spacing(8),
    fontSize: theme.spacing(3),
  },
  alreadyUserSection: {
    fontSize: theme.spacing(4),
  },
}));

function SignupHome (props) {

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const {search} = useLocation();
  const query = new URLSearchParams(search);
  const utmSource = query.get("utm_source");
  const utmCampaign = query.get('utm_campaign');
  const utmMedium = query.get('utm_medium');
  const prefilledEmail = query.get('email');
  const isMobile = useContext(MobileProvider);

  const [email, setEmail]  = useState('');
  const [step, setStep] = useState(0);
  const [phoneNo, setPhoneNo] = useState('');
  const [phoneCountry, setPhoneCountry] = useState('+91');
  const [phoneOtp, setPhoneOtp] = useState('');
  const [password, setPassword] = useState('');
  const [isDirty, setIsDirty] = useState(false);
  const [otpIsDirty, setOtpIsDirty] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const [showOtpDialog, setShowOtpDialog] = useState(false);
  const [otpEmail, setOtpEmail] = useState('');
  const [otpValue, setOtpValue] = useState('');
  const [otpStep, setOtpStep] = useState(0);

  const [isUsedPrefilled, setUsedPrefilled] = useState(false);

  const {landing: {userProfile}} = useSelector(state => state);

  useEffect(() => {
    //loadGoogleButton();
    if (prefilledEmail && !isUsedPrefilled) {
      setEmail(prefilledEmail);
      handleSignup();
    }
  },[email]);

  // handle email and password login 
  const  handleSignup = async () => {
    setIsDirty(true);
    if(!email.length || !password.length) {
      return;
    }
    if(!emailformat.test(email)) {
      return;
    }
    if (phoneNo.length && step === 0) {
      await sendOtpToPhone();
      setStep(1);
      setIsDirty(false);
      return;
    }
    if(phoneNo.length && !phoneOtp.length) {
      return;
    }
    setUsedPrefilled(true);
    try {
      setRequesting(true);
      let res = await dispatch(registerUser(email, password, phoneNo, phoneCountry, phoneOtp));
      setRequesting(false);
      if (res.code === 'SIGNIN_TO_CONTINUE') {
        dispatch(setAppMessage({message: 'Sign in to continue', header:'Success', type:'alert'}));
      } else if(res.code === 'ALREADY_REGISTERD') {
        setStep(0);
        dispatch(setAppMessage({message: 'Email or Phone Already registered.', header:'Error', type:'alert'}));
      }else{
        try {
          if(utmSource === 'fbpaid') {
            fbPixelEvent(`${utmMedium}-signup`, { "content_email": email});
          }
          if(utmSource === 'sempaid') { 
            registerSignupEvent();
          }
        } catch (err) {
          //handle error via sentry
        }
        if(res.accessToken) localStorage.setItem('accessToken', res.accessToken);
        history.push(`/${search}`);
      }
    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      dispatch(setAppMessage({message:err.message || 'Unable to singup', header:'Error', type:'alert'}));
      setRequesting(false);
    }
  }

  //handle the input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if(name === 'email') setEmail(value);
    if(name === 'password') setPassword(value);
    if(name === 'otp') setOtpValue(value);
    if(name === 'otpEmail') setOtpEmail(value);
    if(name === 'phonecountry') setPhoneCountry(value);
    if(name === 'phoneno') setPhoneNo(value);
    if(name === 'phoneotp') setPhoneOtp(value);
  }

  const sendOtpToPhone = () => {
    try {
      dispatch(sendPhoneVerifyOtp(null, phoneNo, phoneCountry));
    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      dispatch(setAppMessage({message:err.message || 'Unable to send otp', header:'Error', type:'alert'}));
    }
  }
  return (
    <>
      {renderHeader({classes, isMobile})}
      {requesting && <LinearLoader/>}
      <Box className={classes.containerBox}>
        <Box ml={6} mr={6} mt={45} mb={4}>
          {step === 0 && <><Box display="flex" alignItems="center" justifyContent="center" mt={20} mb={4}>
          <Input 
              label="Email *" 
              id="email" 
              type="email" 
              name="email" 
              error={isDirty && (!email.length || !emailformat.test(email))}
              helperText={(isDirty && (!email.length || !emailformat.test(email))) ? "* Email is invalid." : ""}
              onChange={handleInputChange} 
              fullWidth 
              value={email}/>
          </Box>
          <Box pb={6}>
            <Input 
              label="Password *" 
              id="password" 
              type="password" 
              name="password" 
              error={isDirty && !password.length}
              helperText={(isDirty && !password.length) ? "* Password is required." : ""}
              onChange={handleInputChange} 
              fullWidth 
              value={password}/>
          </Box>
          <Box display="flex" mb={4}>
            <Box style={{width:'100px'}}>
              <Select
                labelId="demo-simple-select-filled-label"
                value={phoneCountry}
                id="phonecountry"
                variant="outlined"
                name="phonecountry" 
                onChange={handleInputChange}
              >
                {phoneCountryList.map((item) => {
                  return (
                  <MenuItem key={item.country} value={item.code}>{item.country}</MenuItem>
                  )
                })}
              </Select>
            </Box>
            <Box style={{width:'390px'}}>
              <Input 
                label="Phone Number" 
                id="phoneno" 
                type="text" 
                fullWidth
                name="phoneno" 
                //error={isDirty && !password.length}
                //helperText={(isDirty && !password.length) ? "* Password is required." : ""}
                onChange={handleInputChange}  
                value={phoneNo}/>
            </Box>
          </Box></>}
          {step === 1 && 
            <>
            <Box display="flex" alignItems="center" justifyContent="center" mt={20} mb={4}>
            <Input 
                label="Phone" 
                id="disabled_phone" 
                type="text" 
                name="disabled_phone" 
                error={isDirty && !email.length}
                helperText={(isDirty && !email.length) ? "* Email is required." : ""}
                onChange={handleInputChange} 
                fullWidth 
                disabled
                value={`${phoneCountry}${phoneNo}`}/>
            </Box>
            <Box>
            <Input 
                label="Phone OTP" 
                id="phone_otp" 
                type="text" 
                fullWidth
                name="phoneotp" 
                error={isDirty && !phoneOtp.length}
                helperText={(isDirty && !phoneOtp.length) ? "* OTP is required." : ""}
                onChange={handleInputChange}  
                value={phoneOtp}/>
            </Box></>}
        </Box>
        <Box ml={6} mr={6} mt={6} >
          <Button fullWidth size="large" disabled={requesting} onClick={handleSignup} variant="contained">SIGN UP</Button>
        </Box>
        <Box className={classes.termsAndCond}>
        By signing up, you agree to our <a href="https://pubninja.com/terms.html">Terms</a> and <a href="https://pubninja.com/privacy.html">Privacy Policy</a> 
        </Box>
        <Box ml={6} display="flex" mr={6} mt={6} alignItems="center" justifyContent="center">
          <Box style={{visibility:`${requesting ? 'hidden':'visible'}`}}>
            {/* <Button onClick={handleOtpSignin}>Signup with OTP</Button> */}
          </Box>
        </Box>
        <Box component="p" display="flex" mt={0} justifyContent="center" className={classes.alreadyUserSection} pb={6}>
          Already have an account?&nbsp;<a href={`/login${search}`}>SIGN IN</a>
        </Box>
      </Box>
    </>
  )
}

export default SignupHome;


function renderHeader(props) {
  const { isMobile, classes } = props;
  if(isMobile) {
    return (<Box display="flex" alignItems="center" justifyContent="center" className={classes.headerBarMobile}>
      <Box component="p" className={classes.headingTextMobile}>PUBNINJA</Box>
    </Box>)
  }
  return (
    <Box display="flex" alignItems="center" className={classes.headerBarDesktop}>
      <Box component="p" className={classes.headingTextDesktop}>Create your account</Box>
    </Box>
  )
}