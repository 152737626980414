import React, { useState, useEffect, useContext, useRef } from "react";
import Input from "../../commons/muiCustomComponents/Input";
import Box from "../../commons/muiCustomComponents/Box";
import Button from "../../commons/muiCustomComponents/Button";
import Grid from "../../commons/muiCustomComponents/Grid";
import Heart from "./components/HeartComponent";
import { makeStyles } from '@material-ui/core/styles';
import withFileUpload from "../../commons/hocs/WithFileUpload";
import fetchDomainDetails, { setDomainDetails, saveMediaToPisco ,savePageMediaToPisco} from "./settings-action";
import { useDispatch ,useSelector} from "react-redux";
import MobileProvider from "commons/context/MobileProvider";
import { CircularLoader, PageBlockLoader ,LinearLoader } from '../../commons/commonWrapper/Loaders';
import setAppMessage,{uploadMediaAction} from "../../app-component/app-component-action"
import Paper from "commons/muiCustomComponents/Paper";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import VideoPlayer from 'react-video-js-player';
import CropImageDialog from "./CroppingDialog";

const FavUpload = withFileUpload(Button);
const CoverUpload = withFileUpload(Button);
const LogoUpload = withFileUpload(Button);
const WithUploadButton = withFileUpload(Button);

const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: theme.palette.pubPaleGrey,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),

    "&:hover,&:focus": {
      backgroundColor: theme.palette.pubPaleGrey,
    },
  },
  formControl :{
    width:'100%',
  },
}));



export default function NameLogo({ appBarHeight }) {
  const classes = useStyles();
  const isMobile = useContext(MobileProvider);
  const [name, setName] = useState('');
  const [oneline_description, setOnelineDescription] = useState('');
  const [cover_video, setCoverVideo] = useState({});
  const [tagline, setTagline] = useState('');
  const [cover, setCover] = useState('');
  const [isLoadingCover, setLoadingCover] = useState(false);
  const [fav, setFav] = useState('');
  const [logo, setLogo] = useState('');
  const [ requesting, setRequesting ] = useState(false);
  const [fileURL, setFileURL] = useState(null);
  const [file,setFile] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [domainDetail, setDomainDetail] = useState({});
  const [isFetching, setfetching] = useState(false);
  const [isLoadingFav, setLoadingFav] = useState(false);
  const [isLoadingLogo, setLoadingLogo] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [aboutError, setAboutError] = useState(false);
  const { landing, header: { domainList } } = useSelector(state => state);
  const { activeDomain, userPermissions } = landing;
  const [taglineError, setTaglineError] = useState(false);
  const [oneline_descriptionError, setOnelineDescriptionError] = useState(false);
  const [color , setColor] =useState('#0086FF');
  const [font ,setFont] = useState('BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif');
  const childRef = useRef();
  const [filename, setFileName] = useState(null);
  const [openThumbDialog, setThumbnailDialog] = useState(false);
  const [thumbnailContent, setthumbnailContent] = useState(null);
  const dispatch = useDispatch();

  function handleNameChange(e) {
    const value = e.currentTarget.value
    setName(value)
    if (!value) setNameError(true)
    else setNameError(false)
  }

  function handleTaglineChange(e) {
    const value = e.currentTarget.value
    setTagline(value);
    if (!value) setTaglineError(true)
    else setTaglineError(false)
  }

  function handleOnelineDescriptionChange(e) {
    const value = e.currentTarget.value
    setOnelineDescription(value);
    if (!value) setOnelineDescriptionError(true)
    else setOnelineDescriptionError(false)
  }

  useEffect(() => {
    let isMounted = true;
    const getDetails = async () => {
      try {
        setfetching(true)
        const detail = await dispatch(fetchDomainDetails())
        // eslint-disable-next-line camelcase
        const { title, favicon_url, logo,primary_color, primary_font, oneline_description, tagline, cover_photo, cover_video } = detail;
        isMounted && setDomainDetail(detail)
        isMounted && setName(title)
        isMounted && setOnelineDescription(oneline_description)
        isMounted && setTagline(tagline)
        isMounted && setCover(cover_photo)
        isMounted && setFav(favicon_url)
        isMounted && setLogo(logo)
        isMounted && setfetching(false)
        // eslint-disable-next-line camelcase
        isMounted && primary_color && setColor(primary_color)
        // eslint-disable-next-line camelcase
        isMounted && primary_font && setFont(primary_font)
        isMounted && cover_video && setCoverVideo(cover_video)
        isMounted && cover_video && cover_video.hlsUrl && setFileURL(cover_video.hlsUrl)
      } catch (err) {
        isMounted && setfetching(false)
      }
    }
    getDetails()
    return () => isMounted = false;
  }, []);

  const handleFavUpload = async (target) => {
    const file = target && target.files[0];
    let reader = new FileReader();


    let img = new Image();

    reader.onload = async (e) => {
      img.onload = async (e) => {

        if ((img.width !== img.height) || (img.width > 256 || img.height > 256)) {
          dispatch(setAppMessage({ type: "alert", message: 'Favicon images should be square, with max size 256x256px', header: "Error" }))
          return;
        }
        try {
          setLoadingFav(true)
          const { localUploadResponse } = await dispatch(savePageMediaToPisco(file));
          const { cdn } = localUploadResponse;
          setFav(`https:${cdn}`);
          setLoadingFav(false)
        }
        catch (err) {
          setLoadingFav(false)
        }
      }
      img.src = reader.result;

    }
    reader.readAsDataURL(file);

  }

  const handleCoverUpload = async (target,type) => {
    try {
      // setLoadingCover(true)
      // const { localUploadResponse } = await dispatch(savePageMediaToPisco(file));
      // const { cdn } = localUploadResponse;
      // setCover(`https:${cdn}`);
      // setLoadingCover(false)
      const allowed = ['jpg', 'jpeg','png','bmp','gif'];
      const file = target && target.files[0];
      const uploadType = type && type.split("/")[0];
      const extension = file && file.type.split("/")[1];
      if (allowed.indexOf(extension) < 0) {
        dispatch(setAppMessage({ message: 'Unsupported media format.', header: 'Error', type: 'alert' }));
        return;
      }
      const { size } = file;
      const sizeInMb = size / 1000000;
      if (sizeInMb > 1024) {
        dispatch(setAppMessage({ message: 'image larger than 1GB not allowed.', header: 'Error', type: 'alert' }));
  
        return;
      }
      if (file && type && !(file.type.indexOf(uploadType) > -1)) {
        //this.props.InvalidFileError();
        return;
      }
      if (uploadType === 'image' && extension === 'webp') {
        //this.props.InvalidFileError('Webp images are not allowed, as not supported by Safari browser.');
        return;
      }
      if (!file || !file.name) {
        return;
      }
      const fr = new FileReader();
      setFileName(file.name)
      if (extension==='gif'){
        setLoadingCover(true)
        const { localUploadResponse } = await dispatch(savePageMediaToPisco(file));
        const { cdn } = localUploadResponse;
        setCover(`https:${cdn}`);
        setLoadingCover(false)
      }
      else {
      fr.onload = (stream) => {
        setthumbnailContent(stream.target.result);
        setThumbnailDialog(true);
      }
      fr.readAsDataURL(file);
    }

    }
    catch (err) {
      setLoadingCover(false)
    }
  }


  const handleLogoUpload = async (target) => {
    const file = target && target.files[0];
    try {
      setLoadingLogo(true)
      const { localUploadResponse } = await dispatch(savePageMediaToPisco(file));
      const { cdn } = localUploadResponse;
      setLogo(`https:${cdn}`);
      setLoadingLogo(false)
    }
    catch (err) {
      setLoadingLogo(false)
    }
  }

  const saveDomainDetails = async () => {
    const payload = {
      title: name,
      oneline_description: oneline_description,
      tagline: tagline,
      // eslint-disable-next-line camelcase
      favicon_url: fav,
      logo: logo,
      cover_photo: cover,
      primary_color :color,
      primary_font :font,
      cover_video: cover_video,
    }
    if (!name) {
      setNameError(true);
      return;
    }

    try {
      setLoading(true)
      await dispatch(setDomainDetails({ ...domainDetail, ...payload }))
      setLoading(false)
    }
    catch (err) {
      setLoading(false)
    }
  }

  const changeColor = (e) => {
    setColor(e.target.value);
  }
  const changeFont = (e) => {
    setFont(e.target.value);
  }

  const createVideoArticle = async (target, type) => {
    const allowed = ['mp4', 'ogg', 'webm', 'm4v', 'quicktime','mkv'];
    const file = target && target.files[0];
    const uploadType = type && type.split("/")[0];
    let extension = file && file.type.split("/")[1];
    if(!extension){
      extension = file.name && file.name.split('.').pop()
    }
    if (allowed.indexOf(extension) < 0) {
      dispatch(setAppMessage({ message: 'Unsupported media format.', header: 'Error', type: 'alert' }));
    } else{
      setFile(file);

      setRequesting(true);
      // uploadMediaToPisco(file);
      try {
      const data=  await dispatch(uploadMediaAction(file,{
        filename: file && file.name,
        filetype: extension && extension,
        type : 'post',
        domain_id : activeDomain,
        uniqueId : "COVER_VIDEO",
       },'video'));
       setCoverVideo({
        cdn: data.uploadedURL,
        hlsUrl :data.hlsUrl,
      });
        setFileURL(data.hlsUrl);
        setRequesting(false);
    }
    catch (err) {
      setRequesting(false);
      const { message } = err;
      dispatch(setAppMessage({ message: message || 'Unable to upload media.', header: 'Error', type: 'alert' }));
    }
    }
  }

  const uploadMediaToPisco = async(file) => {
    try {
      setRequesting(true);
      const {localUploadResponse} = await dispatch(savePageMediaToPisco(file));
      if (localUploadResponse && localUploadResponse.brightcove_video_id) {
        setCoverVideo({
          brightcoveVideoId: localUploadResponse.brightcove_video_id,
          cdn: localUploadResponse.cdn,
          s3Url: localUploadResponse.s3Url,
        });
        var fileURL = URL.createObjectURL(file);
        setFileURL(fileURL);
        setRequesting(false);
      }
    } catch (err) {
      setRequesting(false);
      const { message } = err;
      dispatch(setAppMessage({ message: message || 'Unable to upload media.', header: 'Error', type: 'alert' }));
    }
  }

  const removeVideo = () => {
    setCoverVideo(null);
    setFileURL(null);
  }
  
  return (
    <>
    <CropImageDialog
        ref={childRef}
        open={openThumbDialog}
        setThumbnailDialog={setThumbnailDialog}
        thumbnailContent={thumbnailContent}
        name={filename}
        setFinalCroppedImage ={setCover}
      />
    {/* <Grid item xs={12} md={9} xl={10}> {isFetching && <PageBlockLoader top={appBarHeight} />}</Grid> */}
    <Grid container> <Grid item xs={12} md={9} xl={10}>
    {isMobile && isFetching && <LinearLoader top={appBarHeight} />}
    {!isMobile && isFetching && <PageBlockLoader />}

      <Box m ={isMobile ?2 : 0} mt={4} mb={4}>
      <Paper> 
      <Grid container>
      <Grid item xs={12} md={8}>
        <Box >
          <Box >
            <Input
              error={nameError}
              helperText={nameError ? "Brand name can't be empty" : ''}
              color="secondary"
              value={name || ''}
              onChange={handleNameChange}
              fullWidth
              label="Brand name*" />
          </Box>
          <Box mt={3}>
            <Input
              error={taglineError}
              helperText={taglineError ? "Description can't be empty" : ''}
              color="secondary"
              value={tagline || ''}
              onChange={handleTaglineChange}
              fullWidth
              label="Tagline*" />
          </Box>
          <Box mt={3}>
            <Input
              error={oneline_descriptionError}
              helperText={oneline_descriptionError ? "Description can't be empty" : ''}
              color="secondary"
              value={oneline_description || ''}
              onChange={handleOnelineDescriptionChange}
              fullWidth
              rows="3" multiline
              label="What’s it about*" />
          </Box>
          <Box mt={3} width="100%">
            <Box component="span">Cover image (Recommended size 1600 x 400)</Box>
            <Heart image={cover} isLoadingFav={isLoadingCover} />
            <CoverUpload boxProps={{ mt: 2 }} className={classes.button} fullWidth onChange={handleCoverUpload}>
              Cover Photo
           </CoverUpload>
          <Box mt={5} width="100%">
            <Box mt={6}>
                  <Box component="span" fontWeight="bold" fontSize={14}>{
                  requesting ?
                  'Uploading Video'
                  :
                  fileURL ? 'Video Uploaded':
                  'Cover Video'}</Box>
                  <Box component="span" fontSize={12}>&nbsp;{requesting ? `${file && file.name}. Please wait`:fileURL ? '':'(MP4 format only)'}</Box>
                  
                </Box>
                <Box mt={3}>
                    <Box position="relative" width={279} height={152} bgcolor="pubSecondary" display="flex" justifyContent="center" alignItems="center" >
                    {
                      requesting ?
                        <>
                        <CircularLoader height={"auto"} size={20} position="absolute" top={3} right={3}/>
                        {/* <Button variant="contained" color="primary" onClick={()=> {cancelAPI();}}>CANCEL</Button> */}
                        </>
                      :
                      <Box>
                        {
                        fileURL ? <Box display="flex" width={279} height={152}>
                          <VideoPlayer src={fileURL} width={279} height={152} controls>
                          </VideoPlayer>
                          <DeleteIcon onClick={removeVideo} />
                        </Box>
                        :
                       
                          <WithUploadButton
                            color="primary"
                            variant="contained"
                            type="video/*" onChange={createVideoArticle}>
                            BROWSE
                           </WithUploadButton>
                       
                        }
                      </Box>
                    } 
                    </Box>
                </Box>
          </Box>
          </Box>
          <Box mt={5} display="flex" flexWrap={isMobile ? "wrap" : "no-wrap"} justifyContent="space-around">

            <Box mt={3} width="100%">
              <Heart image={fav} isLoadingFav={isLoadingFav} />
              <FavUpload boxProps={{ mt: 2 }} className={classes.button} fullWidth onChange={handleFavUpload}>
                Select Favicon
             </FavUpload>
            </Box>

            <Box mt={3} ml={isMobile ? 'auto' : 3} width="100%">
              <Heart image={logo} isLoadingLogo={isLoadingLogo} />
              <LogoUpload boxProps={{ mt: 2 }} className={classes.button} fullWidth onChange={handleLogoUpload}>
                Select Logo
             </LogoUpload>
            </Box>
          </Box>
          <Grid container >
          <Grid item md={6} xs={12} >
            <Box m={2} >
              <FormControl className={classes.formControl}>
                <InputLabel className={classes.selectEmpty} > Change Color </InputLabel>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  value={color}
                  onChange={changeColor}
                >
                  <MenuItem value={'#0086FF'}>#0086FF  
                  {<Box component="span" width="16px" height="16px" ml={2} mr={2} bgcolor="#0086FF"></Box>}</MenuItem>
                  <MenuItem value={'#F45B69'}>#F45B69 {<Box component="span" width="16px" height="16px" ml={2} bgcolor="#F45B69"></Box>}</MenuItem>
                  <MenuItem value={'#EFA00B'}>#EFA00B {<Box component="span" width="16px" height="16px" ml ={2} bgcolor="#EFA00B"></Box>}</MenuItem>
                  <MenuItem value={'#9F4DCD'}>#9F4DCD {<Box component="span" width="16px" height="16px" ml ={2} bgcolor="#9F4DCD"></Box>}</MenuItem>
                  <MenuItem value={'#1F8497'}>#1F8497 {<Box component="span" width="16px" height="16px" ml ={2} bgcolor="#1F8497"></Box>}</MenuItem>
                  <MenuItem value={'#22ABA0'}>#22ABA0 {<Box component="span" width="16px" height="16px" ml ={2} bgcolor="#22ABA0"></Box>}</MenuItem>
                  <MenuItem value={'#18A777'}>#18A777 {<Box component="span" width="16px" height="16px" ml ={2} bgcolor="#18A777"></Box>}</MenuItem>
                  <MenuItem value={'#285D2C'}>#285D2C {<Box component="span" width="16px" height="16px" ml ={2} bgcolor="#285D2C"></Box>}</MenuItem>
                  <MenuItem value={'#86B700'}>#86B700 {<Box component="span" width="16px" height="16px" ml ={2} bgcolor="#86B700"></Box>}</MenuItem>
                  <MenuItem value={'#27EA72'}>#27EA72 {<Box component="span" width="16px" height="16px" ml ={2} bgcolor="#27EA72"></Box>}</MenuItem>
                  <MenuItem value={'#F8D543'}>#F8D543 {<Box component="span" width="16px" height="16px" ml ={2} bgcolor="#F8D543"></Box>}</MenuItem>
                  <MenuItem value={'#FFB100'}>#FFB100 {<Box component="span" width="16px" height="16px" ml ={2} bgcolor="#FFB100"></Box>}</MenuItem>
                  <MenuItem value={'#FF8810'}>#FF8810 {<Box component="span" width="16px" height="16px" ml ={2} bgcolor="#FF8810"></Box>}</MenuItem>
                  <MenuItem value={'#07B3FF'}>#07B3FF {<Box component="span" width="16px" height="16px" ml ={2} bgcolor="#07B3FF"></Box>}</MenuItem>
                  <MenuItem value={'#00D3CB'}>#00D3CB {<Box component="span" width="16px" height="16px" ml ={2} bgcolor="#00D3CB"></Box>}</MenuItem>
                  <MenuItem value={'#3B73F2'}>#3B73F2 {<Box component="span" width="16px" height="16px" ml ={2} bgcolor="#3B73F2"></Box>}</MenuItem>
                  <MenuItem value={'#4830D3'}>#4830D3 {<Box component="span" width="16px" height="16px" ml ={2} bgcolor="#4830D3"></Box>}</MenuItem>
                  <MenuItem value={'#6217B3'}>#6217B3 {<Box component="span" width="16px" height="16px" ml ={2} bgcolor="#6217B3"></Box>}</MenuItem>
                  <MenuItem value={'#F99259'}>#F99259 {<Box component="span" width="16px" height="16px" ml ={2} bgcolor="#F99259"></Box>}</MenuItem>
                  <MenuItem value={'#FC6617'}>#FC6617 {<Box component="span" width="16px" height="16px" ml ={2} bgcolor="#FC6617"></Box>}</MenuItem>
                  <MenuItem value={'#FF5050'}>#FF5050 {<Box component="span" width="16px" height="16px" ml ={2} bgcolor="#FF5050"></Box>}</MenuItem>
                  <MenuItem value={'#D2302C'}>#D2302C {<Box component="span" width="16px" height="16px" ml ={2} bgcolor="#D2302C"></Box>}</MenuItem>
                  <MenuItem value={'#EA2186'}>#EA2186 {<Box component="span" width="16px" height="16px" ml ={2} bgcolor="#EA2186"></Box>}</MenuItem>
                  <MenuItem value={'#FF6595'}>#FF6595 {<Box component="span" width="16px" height="16px" ml ={2} bgcolor="#FF6595"></Box>}</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item md={6} xs={12} >
            <Box m={2} >
              <FormControl className={classes.formControl}>
                <InputLabel className={classes.selectEmpty} > Change font </InputLabel>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  value={font}
                  onChange={changeFont}
                >
                  <MenuItem value={'BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Helvetica,Arial,sans-serif'}>System font</MenuItem>
                  <MenuItem value={'Muli, sans-serif'}>Muli, sans-serif </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
          <Box display="flex" mt={4} mb={4}>
            <Button disabled={isLoading} fullWidth onClick={saveDomainDetails} variant="contained">
              Save
            </Button>
            {isLoading && <CircularLoader height="auto" ml={1} size={20} />}
          </Box>
        </Box>
      </Grid>
    </Grid>
    </Paper>
    </Box>
      </Grid></Grid>
      </>
  )
}
