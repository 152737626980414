import React,{useCallback,useContext} from 'react';
import DeleteWebsite from "../components/settings/DeleteWebsite";
import FullScreenPage from '../commons/commonWrapper/FullScreenPage';
import Box from "../commons/muiCustomComponents/Box";
import MobileProvider from "commons/context/MobileProvider"

export default function DeleteWebsiteHome() {
  const isMobile = useContext(MobileProvider);
  const barCallback = useCallback(() =>(<Box 
  display="flex"
  alignItems="center"
  fontSize ={14}
  >Delete My Website</Box>),[]);

  return <FullScreenPage BarComponent={barCallback}>
        {(appBarHeight) => <DeleteWebsite appbarheight={appBarHeight} />}
      </FullScreenPage>
}
