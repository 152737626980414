import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';

class  CustomMenuItem extends React.Component {
  render() {
    const {...rest} = this.props;
    return (<MenuItem {...rest}  />)
  }
}

export default CustomMenuItem;