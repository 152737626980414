import React, { useEffect,useState } from "react";
import {useSelector,useDispatch} from "react-redux";
import sidebarRoutes from "../../routes/sidebar-routes";
import {NavLink}  from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Collapse from '@material-ui/core/Collapse';
import { Box } from "@material-ui/core";
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import clsx from "clsx";
import fetchDomainDetails from "../settings/settings-action";
import { LogglyTracker } from 'loggly-jslogger';

const logger = new LogglyTracker();
logger.push({ 'logglyKey': '142d21f5-10d3-47a9-aa5a-f83e0a3abed7', 'sendConsoleErrors' : true, 'tag' : 'ardbeg,sidebar-mobile' });

const drawerWidth = '100%';

const useStyles = makeStyles(theme => ({

  customList: {
    marginLeft : theme.spacing(8),
    marginRight :theme.spacing(8),
  },
  navLink: {
    transition: "all 300ms linear",
    margin: 0,
    borderRadius: theme.spacing(2),
    position: "relative",
    display: "block",
    // padding: theme.spacing(5, 7),
    marginTop:theme.spacing(20 * 0.25),
    backgroundColor: "transparent",
    letterSpacing: theme.spacing(-0.09),
    fontWeight: 'normal',
    width: '100%',
    textDecoration: 'none',
    cursor: 'pointer',
    fontSize: theme.spacing(16 * (1/4)),
    color: 'rgb(0,0,0,0.5)',
    "&:hover": {
      outline: "none",
      // backgroundColor: "rgba(200, 200, 200, 0.2)",
      boxShadow: "none",
    },
    "&:hover,&:focus": {
      color: "inherit",
    },
  },
  collapsibleLink: {
    transition: 'visibility 0s, opacity 0.3s linear',
  },
  hidden: {
    opacity: 0,
    visibility: 'hidden',
  },
  customListItem: {
    // backgroundColor:'#d8d8d812',
    // borderRadius:theme.spacing(5/4),
    // margin:`${theme.spacing(5/4)}px auto`,
  },
  dropdownArrow: {
    position:'absolute',
    right:theme.spacing(0),
    // marginTop: `-${theme.spacing(2)}px`,
  },
  listContainer: {
    backgroundColor:theme.palette.pubBackground,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor:theme.palette.pubBackground,
    borderRight:'none',
  },
  activeClass : {
    // border:`1px solid ${theme.palette.primary.main}`,
    color:theme.palette.primary.main,
    // backgroundColor: theme.palette.pubPaleGreyTwo,
  },
  subRoutesPadding :{
    paddingLeft: theme.spacing(45 * 0.25),
    // marginTop:theme.spacing(4),
    width:'90%',
  },
  firstEle :{
    marginTop:theme.spacing(30 * 0.25),
  },
}));

function SideBar (props) {
  const { landing } = useSelector(state =>  state);
  const classes = useStyles();
  const { userPermissions ,featurePermissions,activeDomain, userProfile} = landing;
  const [driveLink ,setDriveLink] = useState(null);

  const dispatch = useDispatch();

  useEffect(()=>{
    const getDetails = async ()=>{
      try {
       const details =  await dispatch(fetchDomainDetails());
       const {google_drive_link=null} = details;
       setDriveLink(google_drive_link)
      }
      catch(err){
        console.log("err")
        const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      } 
    }
    getDetails();
  },[activeDomain])

  const openDriveLink = ()=>{
    try{
       window.open(driveLink, '_blank');
    }
    catch(err){
      // eslint-disable-next-line no-console
      console.log("err")
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
    }
  }
  return(
        <>
        <Box className={classes.listContainer} minHeight={`calc(100vh - 63px)`}>
          <CustomList className={classes.customList}>  
            {sidebarRoutes.map(item => {
              if(driveLink && item.notPartOfDashboard){
                if(!driveLink) return null
                return <CustomListItem button key={item.path}  label={item.name}>   
                     <Box  className={clsx({[classes.navLink]:true,[classes.firstEle]:item.id===0.0})}
                      display="flex" alignItems="center" onClick={openDriveLink} mt={(item.id===1.0||item.id===3.1)?10:0}> {item.icon}
                       <Box component="span" ml={6}>{item.name}</Box>
                      </Box>
                  </CustomListItem>
              }
              if(item.notPartOfDashboard) return null
              if(!checkUserPermissons(item.requiredPermission,
                 userPermissions, item.permissionType,featurePermissions ,item.requiredFeaturePermission)) return null;
              return (
                <CustomListItem button key={item.path} label={item.name}>
                  {
                    item.isCollapse ? 
                    <CollapsibleLink userPermissions={userPermissions} featurePermissions = {featurePermissions} item={item}/>
                    :
                    <NavLink activeClassName={classes.activeClass} className={clsx({[classes.navLink]:true,[classes.firstEle]:item.id===0.0})} to={item.path}>
                     <Box display="flex" alignItems="center" mt={(item.id===1.0 ||item.id===3.0)?10:0}> {item.icon}
                       <Box component="span" ml={6}>{item.name}</Box>
                      </Box>
                    </NavLink>
                  }
                </CustomListItem>
              )
              })}
          </CustomList>
          </Box>
          </>
  )
}

export default SideBar;

function CollapsibleLink(props) {
  const [hidden, setHidden] = React.useState(true);
  const { item } = props;
  const classes = useStyles();
  return (
    <>
      <Box className={classes.navLink} to={item.path} onClick={()=>setHidden(!hidden)}>
      <Box display="flex" alignItems="center" mt={(item.id===2.0)?10:0}> {item.icon}
          <Box component="span" ml={6}>{item.name}</Box>
          {
          hidden && <ArrowDropDownIcon  className={classes.dropdownArrow}/>
        }
        {
          !hidden && <ArrowDropUpIcon  className={classes.dropdownArrow}/>
        }
      </Box>
        
      </Box>
      <Collapse in={!hidden}>
        <List disablePadding>
          {item.views.map((subRoute) => {
            if(!checkUserPermissons(subRoute.requiredPermission, 
              props.userPermissions, subRoute.permissionType,props.featurePermissions,subRoute.requiredFeaturePermission)) return null;
            return (
              <CustomListItem button key={subRoute.path}>
                  <NavLink className={classes.navLink +' '+ classes.subRoutesPadding} activeClassName={classes.activeClass} to={subRoute.path}>
                    {subRoute.name}
                  </NavLink>
              </CustomListItem>
            )
          })}
        </List>
      </Collapse>
      
    </>
  );
}

function CustomList(props) {
  const classes = useStyles();
  return (<Box className={classes.customList}>
    {props.children}
  </Box>)
}

function CustomListItem(props) {
  const classes = useStyles();
  return (
  <Box
    className={classes.customListItem}
  >{props.children}</Box>
  )
}



function checkUserPermissons(requiredUserPermissions, userPermissions, permissionsCheckType,featurePermissions=[] , requiredFeaturePermission='') {
  let hasPermission = false;
  if (requiredFeaturePermission){
    if (!featurePermissions.includes(requiredFeaturePermission)){
      return hasPermission;
    } 
  }

  if(requiredUserPermissions && !requiredUserPermissions.length) {
    return true;
  }
  
  if(permissionsCheckType === 'OR') {
    
    for(let i=0; i < userPermissions.length; i++) {
      if (requiredUserPermissions.indexOf(userPermissions[i]) >= 0) {
        hasPermission = true;
        break;
      }
    }
  }
  if(permissionsCheckType === 'AND') {
    hasPermission = true;
    for(let i=0; i < requiredUserPermissions.length; i++) {
      if (userPermissions.indexOf(requiredUserPermissions[i]) < 0) {
        hasPermission = false;
        break;
      }
    }
  }
  return hasPermission;
}