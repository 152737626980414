import React,{useCallback} from 'react';
import GoogleAnalytics from "../components/settings/GoogleAnalytics";
import FullScreenPage from '../commons/commonWrapper/FullScreenPage';
import Box from "../commons/muiCustomComponents/Box";


export default function GoogleAnalyticsHome() {

  const barCallback = useCallback(() =>(<Box 
  display="flex"
  alignItems="center"
  component="span"
  fontSize ={14}
>Google analytics</Box>),[]);

  return <FullScreenPage BarComponent={barCallback}>
        {(appBarHeight) => <GoogleAnalytics appbarheight={appBarHeight} />}
      </FullScreenPage>
}
