import React, { useState } from 'react';
import clsx from "clsx";
import Box from "../../../commons/muiCustomComponents/Box";
import { makeStyles } from '@material-ui/core/styles';
import { CircularLoader } from '../../../commons/commonWrapper/Loaders';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import Button from "../../../commons/muiCustomComponents/Button";
import Dialog from '@material-ui/core/Dialog';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.pubPaleGrey,
    border: `1px solid ${theme.palette.pubdeepSkyBlue}`,
    borderRadius: theme.spacing(5 * 0.25),
  },
  sheetStyle: {
    fill: theme.palette.pubLightBlue,
    width: theme.spacing(64 * 0.25),
    height: theme.spacing(80 * 0.25),
  },
  desktopcardLayout: {
    width: theme.spacing(328 * 0.25),
    height: theme.spacing(197 * 0.25),
  },
  mobileCardLayout: {
    width: '100%',
  },
  wordBreakStyle :{
    wordBreak:'break-word',
  },
}));

export default function PageList({
  title,
  slug,
  isMobile,
  id,
  status, addDefaultPage,
  disableDefaultPage,
  state,
  getAllPages,
  setAboutdata,
  currentDomain,
}) {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();

  const editActivePage = async (id) => {
    history.push(`/dash/setting/website/pages/${state}/${id}`)
  }
  const addPage = async () => {
    try {
      setLoading(true)
      await addDefaultPage(slug)
      const data = await getAllPages()
      setAboutdata(data)
      setLoading(false)
    }
    catch (err) {
      setLoading(false)
    }
  }

  const disablePage = async (id) => {
    try {
      setLoading(true)
      await disableDefaultPage(id)
      const data = await getAllPages()
      setAboutdata(data)
      setLoading(false)
    }
    catch (err) {
      setLoading(false)
    }
  }
  return (
    <>
      <Box mt={isMobile ? 3 : 5} ml={!isMobile ? 5 : 0}
        className={clsx({ [classes.desktopcardLayout]: !isMobile }, { [classes.root]: true }, { [classes.mobileCardLayout]: isMobile })}>
        <Box display="flex" ml={4} mt={4}>
          <InsertDriveFileIcon className={classes.sheetStyle} />
          <Box ml={2}>
            <Box component="p" className={classes.wordBreakStyle}>{title}</Box>
            {slug && <a className={classes.wordBreakStyle}  href={`https://${currentDomain}/page/${slug}`} target="_blank">/{slug}</a>}
          </Box>
        </Box>
        <Box mr={10} mt={3} mb={3} ml={4}>
          {
            status ?
              <Box display="flex">
                <Button type="group" groupButton={
                  {
                    number: 2,
                    texts: ['Edit', `${state === 'default' ? 'Disable' : 'Delete'}`],
                    buttonColors: ['primary', 'default'],
                    disables: [false, false],
                    onClickListeners: [() => { editActivePage(id) }, () => { disablePage(id) }],
                    variants: ['contained', 'contained'],
                  }
                } />
              </Box>
              : <Button type="group" groupButton={
                {
                  number: 2,
                  texts: ['Enable', 'Disable'],
                  buttonColors: ['primary', 'primary'],
                  disables: [false, true],
                  onClickListeners: [() => { addPage() }, () => { }],
                  variants: ['contained', 'contained'],
                }
              } />
          }
        </Box>
      </Box>
      <Dialog
        open={isLoading}
        maxWidth="xs"
        fullWidth
      >
        <Box m={9}>
          <CircularLoader height="auto" />
        </Box>

      </Dialog>
    </>
  );
}
