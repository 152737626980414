import React,{useCallback,useContext} from 'react';
import Profile from "../components/profile/Profile";
import FullScreenPage from '../commons/commonWrapper/FullScreenPage';
import Box from "../commons/muiCustomComponents/Box";
import MobileProvider from "commons/context/MobileProvider";

export default function ProfileHome() {
  const isMobile = useContext(MobileProvider);

  const barCallback = useCallback(() =>(<Box 
  display="flex"
  alignItems="center"
  >Profile</Box>),[]);
  return <>
      <FullScreenPage BarComponent={barCallback} isDesktop={false} isCloseIcon={true}>
        {(appBarHeight) => <Profile appbarheight={appBarHeight} />}
      </FullScreenPage>
    </>
}
