
export function loginUser(email, commonInput, password, otp) {
  return async(dispatch, getState, apiHandler) => {
    try {
      const { userApi, apiRoutes } = apiHandler;
      let response = await userApi(apiRoutes.loginUserApi(email, commonInput, password, otp));
      const { data:{accessToken, userInfo}} = response;
      localStorage.setItem('accessToken', accessToken);
      //TODO: add user name as proper name
      window.Intercom &&  window.Intercom('update', {email: userInfo.email, user_id: userInfo.uuid, name: userInfo.email});
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export function logoutUser() {
  return async (dispatch, getState, apiHandler) => {
    const { api, apiRoutes } = apiHandler;
    try {
      await api(apiRoutes.logoutUserApi());
      window.Intercom && window.Intercom('update');
      await dispatch({type:'ClearDomainList'});
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export function sendPassResetOtp(email) {
  return async (dispatch, getState, apiHandler) => {
    const {userApi, apiRoutes } = apiHandler;
    try {
      await userApi(apiRoutes.sendPasswordResetOtp(email));
      return Promise.resolve();
    } catch(err) {
      return Promise.reject(err);
    }
  }
}

export function resetPassword(password, token) {
  return async (dispatch, getState, apiHandler) => {
    const {userApi, apiRoutes} = apiHandler;
    try {
      await userApi(apiRoutes.resetPasswordApi(password, token));
      return Promise.resolve();
    } catch(err) {
      return Promise.reject(err);
    }
  }
}

export function forgotPassword(email, otp) {
  return async (dispatch, getState, apiHandler) => {
    const {userApi, apiRoutes } = apiHandler;
    try {
      const res = await userApi(apiRoutes.forgotPasswordApi(email, otp));
      return Promise.resolve(res);
    } catch(err) {
      return Promise.reject(err);
    }
  }
}

export function registerUser(email, password, phoneNo, phoneCountry, phoneOtp) {
  return async (dispatch, getState, apiHandler) => {
    const { userApi, apiRoutes } = apiHandler;
    try {
      // registerNewUser(email, password, phoneno, phonecountrycode, phoneotp)
      let {data} = await userApi(apiRoutes.registerNewUser(email, password, phoneNo, phoneCountry, phoneOtp));
      return Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export function setNewPassword(password, token) {
  return async (dispatch, getState, apiHandler) => {
    const {userApi, apiRoutes} = apiHandler;
    try {
      let { data } = await userApi(apiRoutes.resetPasswordApi(password, token));
      return Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export function sendPhoneVerifyOtp(commonInput, phoneNo, phoneCountry) {
  return async (dispatch, getState, apiHandler) => {
    const {userApi, apiRoutes} = apiHandler;
    try {
      let {data} = await userApi(apiRoutes.sendLoginOtp(commonInput, phoneNo, phoneCountry));
      return Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  } 
}

export function sendLoginOtp(commonInput, phoneNo, phoneCountry) {
  return async (dispatch, getState, apiHandler) => {
    const {userApi, apiRoutes} = apiHandler;
    try {
      let {data} = await userApi(apiRoutes.sendLoginOtp(commonInput, phoneNo, phoneCountry));
      return Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  } 
}