const appEnv = process.env.REACT_APP_APP_ENV;
export function fbPixelEvent(eventName, data) {
  if(appEnv !== 'production') {
    // eslint-disable-next-line
    console.log('fb pixel event triggered', eventName);
    return;
  }
  try {
    window.fbq('track', eventName, data || {});
  } catch (err) {
    // eslint-disable-next-line
    console.error("unable to track event");
  }
}