import React, { useState, useCallback, useImperativeHandle, forwardRef} from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Box from "../../commons/muiCustomComponents/Box";
import ImageIcon from '@material-ui/icons/Image';
import { saveMediaToPisco } from "./content-action";
import { useSelector, useDispatch } from "react-redux";
import { LinearLoader, CircularLoader } from "commons/commonWrapper/Loaders";
import Cropper from 'react-easy-crop';
import MobileProvider from "commons/context/MobileProvider";
import Slider from '@material-ui/core/Slider';
import getCroppedImg from "./components/CropImage";


const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },

}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialog: {
    padding: theme.spacing(0),
    paddingTop: theme.spacing(4),
  },
  contnetRoot: {
    justifyContent: 'center',
  },
  paperDialog :{
    margin: theme.spacing(0),
  },
  cardDesgin: {
    border: '1px solid #e5e5e5',
    width: '170px !important',
    position: 'relative',
  },
  slected: {
    opacity: '0.2',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  unslected: {
    opacity: '0.7',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  checkIconClass: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    fontSize: theme.spacing(11),
    color: theme.palette.pubWhite,
  },
  mediaClass: {
    backgroundColor: 'grey',
  },
  mediaStyle :{
    backgroundColor: 'grey',
    margin:'auto',
  },
  containerStyle :{
    backgroundColor: 'grey',
  },
}));

const ThumbnailDialogs = ({ thumbnailContent , setDeafaultThumb,setFinalCroppedImage,
  defaultThumb=false,thumbIndex,setSelectedItem=()=>{},selectedItem,isBlur = false,setOnlyImage ,onlYImage=false,
  setBlurImg,tileData,setTileData,setThumbnailDialog, open,name,setThumbURL,setThumbURLArray ,thumbURLArray = [] }, ref) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(2);
  const [aspect] = useState(80/42);
  const [croppedImage, setCroppedImage] = useState(null)
  const isMobile = React.useContext(MobileProvider);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppingImage, setCroppingImage] = useState(false);



  const onCropChange = crop => {
    setCrop(crop);
  }

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }

  const onZoomChange = zoom => {
    setZoom(zoom);
  }

  const handleClose = () => {
    setDeafaultThumb && setDeafaultThumb(false);
    setThumbnailDialog(false);
  };

  const showCroppedImage = useCallback(async () => {
    try {
      setCroppingImage(true)
      const croppedImageRes = await getCroppedImg(
        thumbnailContent,
        croppedAreaPixels,
      )
      croppedImageRes.file.name = name;

      const res = await (dispatch(saveMediaToPisco(croppedImageRes.file)));
      const { localUploadResponse: { cdn } } = res;
      setCroppedImage(`https:${cdn}`);

      if(thumbURLArray && thumbURLArray.length > 0 && thumbURLArray.includes(tileData[thumbIndex])) {
        if(selectedItem === null){
          setSelectedItem(thumbIndex);
        }
        if (selectedItem !== null) {
          setSelectedItem(selectedItem + 1)
        }
        const remaningThumb = thumbURLArray.filter((url)=>url !== tileData[thumbIndex]);
        setThumbURLArray(remaningThumb);
        setDeafaultThumb(false);
      }
      if(defaultThumb) {
        tileData[thumbIndex] = `https:${cdn}`;
        if(selectedItem === null){
          setSelectedItem(thumbIndex);
        }
        if (selectedItem !== null) {
          setSelectedItem(selectedItem + 1)
        }
        isBlur ? setBlurImg(null):
        setThumbURL(null);
        setDeafaultThumb(false);
      }
      else {
        tileData && tileData.unshift(`https:${cdn}`);
        if(selectedItem === null){
          setSelectedItem(0);
        }
        if (selectedItem !== null) {
          setSelectedItem(selectedItem + 1)
        }
        if (isNumber(tileData)) {
          tileData.pop()
        }
      }
      
      tileData  && setTileData([...tileData]);
      onlYImage && setFinalCroppedImage && setFinalCroppedImage(`https:${cdn}`)
      if(onlYImage){
        setOnlyImage(false)
      }
      setCroppingImage(false);
      handleClose();
    } catch (e) {
      console.error(e);
      setCroppingImage(false);
    }
  }, [croppedAreaPixels]);

  useImperativeHandle(ref, () => ({
    getThumbnail() {
      return croppedImage || '';
    },
  }));

  return (
    <div>
      <Dialog onClose={handleClose}
        classes={isMobile ?{paper:classes.paperDialog} :{}}
        disableEscapeKeyDown disableBackdropClick aria-labelledby="customized-dialog-title" open={open}>

        <DialogContent >

            <Box minWidth={isMobile ?319:520} minHeight={isMobile?213:340} position="relative">
              <Cropper
                image={thumbnailContent}
                crop={crop}
                zoom={zoom}
                aspect={aspect}
                onCropChange={onCropChange}
                onCropComplete={onCropComplete}
                onZoomChange={onZoomChange}
                cropShape='rect'
                style={{ cropAreaStyle: classes.mediaClass}}
              />
            </Box>
 
        </DialogContent>
        <DialogActions>
          
            <Box width="100%" >
              <Box mb={4}>
                <Box component="span" color="primary.dark" fontSize={12} display="flex" justifyContent="center">
                  Move image or use Zoom slider to fit image in the window
            </Box>
              </Box>
              <Box display="flex" flexWrap="wrap" justifyContent="space-between" mt={5} mb={5} ml={3} mr={3}>
                <Box display="flex" width={isMobile ? "80%" : "35%"} alignItems="center">
                  <ImageIcon style={{ fontSize: '16px', marginRight: '8px' }} />
                  <Slider value={zoom} min={1}
                    max={3}
                    step={0.1} onChange={(e, zoom) => onZoomChange(zoom)} />
                  <ImageIcon style={{ fontSize: '24px', marginLeft: '8px' }} />
                </Box>
                <Box display="flex">
                  <Button onClick={handleClose} disabled={croppingImage} variant="contained" color="secondary">
                    CLOSE
                  </Button>

                  <Button autoFocus disabled={croppingImage} variant="contained" color="primary" style={{ marginLeft: '12px' }} onClick={showCroppedImage}>
                    CROP & CONTINUE
                  </Button>
                  {croppingImage && <CircularLoader size={20} height="auto" ml={2}/>}
                  <Box>
                  </Box>

                </Box>
              </Box>
            </Box> 
          
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default forwardRef(ThumbnailDialogs);

function isNumber(arr) {

  let res = false;
  if(!arr) return res;
  arr.forEach((item) => {
    if (typeof item === 'number') {
      res = true;
      return;
    }
  })
  return res;
}