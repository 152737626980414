import React from 'react';
import Box from '../muiCustomComponents/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  checkboxContainer: {
    marginTop: theme.spacing(6),
  },
  checkboxLabel: {
    height: theme.spacing(15),
    display: "flex",
    alignItems: "center",
  },
  checkboxName: {
    color: theme.palette.pubdeepSkyBlue,
    fontWeight: "bold",
  },
  checkboxDescription: {
    color: theme.palette.pubdeepSkyBlue,
    marginTop: theme.spacing(2),
  },
  cursorPointer: {
    cursor: "pointer",
  },
}));

const handleClick = (e) => {
  e.target.parentNode.click();
  e.preventDefault()
}


const CheckboxWithDescription = (props) => {
  const {name, checked, label} = props;
  const classes = useStyles();
  return (
    <Box className={classes.checkboxContainer}>
      <input type="checkbox" className="styled-checkbox" name={name} id={name} 
      checked={checked} onChange={props.handleChange} />
      <label className={classes.checkboxLabel} htmlFor={name}>
        <Box>
          <Box className={classes.checkboxName}>{label}</Box>
        </Box>
        <label className={classes.cursorPointer} onClick={handleClick}></label>              
      </label>
    </Box>
  )
}

export default CheckboxWithDescription;
