import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Box from "commons/muiCustomComponents/Box";
import Button from "commons/muiCustomComponents/Button";
import {Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  dialogContainer: {
    padding: theme.spacing(5),
    boxSizing: "border-box",
  },
  btnContainer:{
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(5),
  },
  primaryBtn: {
    background: theme.palette.pubdeepSkyBlue,
    color: theme.palette.pubWhite,
    marginLeft: theme.spacing(2),
  },
}));

const CSVPreview = (props) => {
  const {uploadCSVPreview = [], confirmUpload, open, closePreviewDialog} = props;
  const renderData = uploadCSVPreview.slice(1, 50);

  const classes = useStyles();
  
  return (
    <>
      <Dialog open={open} 
      maxWidth={'md'}
      fullWidth
      >
        <Box className={classes.dialogContainer}>
          <Box>Preview</Box>
          <Table style={{ minWidth: '650px' }}>
            <TableHead>
              <TableRow>
                <TableCell>{uploadCSVPreview[0][0]}</TableCell>
                <TableCell>{uploadCSVPreview[0][1]}</TableCell>
                <TableCell>{uploadCSVPreview[0][2]}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                renderData.map((ele, key) => {
                  return (
                    <TableRow key={key}>
                      <TableCell>{ele[0]}</TableCell>
                      <TableCell>{ele[1]}</TableCell>
                      <TableCell>{ele[2]}</TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
          <Box className={classes.btnContainer}>
            <Button onClick={closePreviewDialog} variant="contained" disableFocusRipple color="default">Cancel</Button>
            <Button onClick={confirmUpload} variant="contained" disableFocusRipple color="secondary" boxProps={{ml:4}} variant="contained">Upload</Button>
          </Box>
        </Box>
      </Dialog>
    </>
  )
}

export default CSVPreview;
