import app from "../constants/actions/app";
import { Upload } from "tus-js-client";
import { LogglyTracker } from 'loggly-jslogger';

const logger = new LogglyTracker();
logger.push({ 'logglyKey': '142d21f5-10d3-47a9-aa5a-f83e0a3abed7', 'sendConsoleErrors' : true, 'tag' : 'ardbeg,app-component' });


export default function setAppMessage(data) {
  return {
    type: app.MessageAction.SET,
    data: data,
  }
}

export function clearAppMessage() {
  return {
    type: app.MessageAction.CLEAR,
  }
}

let Id = null;


export function uploadMediaAction(data, fileMeta,type) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { landing: { activeDomain, userProfile },header :{domainList}} = getState();
      
      const alreadyRunnig = JSON.parse(localStorage.getItem("isRunningUpload") || '[]');
      if (alreadyRunnig.includes(fileMeta.uniqueId)) {
        const data = {
          'message': 'Media uploader is already busy',
          'email': (userProfile||{}).email || '',
          'url': (window.location||{}).href || '',
          'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
          'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
        };
        logger.push({
            'type': 'error',
            'data': data,
          });
        return reject({ message: 'Media uploader is already busy' });
      }
      if (!data) {
        const data = {
          'message': 'file is required',
          'email': (userProfile||{}).email || '',
          'url': (window.location||{}).href || '',
          'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
          'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
        };
        logger.push({
            'type': 'error',
            'data': data,
          });
        return reject("file is required");
      }
      

      const cachedUniqueId  = fileMeta.uniqueId || null;
      dispatch(MediaActionReset());
      
      Object.defineProperty(data, 'name', {
        value: activeDomain + fileMeta.filename,
        writable: true,
      });
      const domainName = domainList && domainList[activeDomain] && domainList[activeDomain][0].domainName;
      const tld = domainList && domainList[activeDomain] && domainList[activeDomain][0].tld;

      if (!type) {
        const data = {
          'message': 'Please pass type of media',
          'email': (userProfile||{}).email || '',
          'url': (window.location||{}).href || '',
          'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
          'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
        };
        logger.push({
            'type': 'error',
            'data': data,
          });
        return reject("Please pass type of media");
      }
      
      delete fileMeta.uniqueId;
      let upload = new Upload(data, {
        endpoint: getUploadUrl(type),
        retryDelays: [0, 3000, 5000, 10000, 20000],
        metadata: fileMeta,
        onError: function (error) {
          console.log("Failed because: " + error)
          const alreadyRunnig = JSON.parse(localStorage.getItem("isRunningUpload") || '[]');
          const RUNNIG_UPLOADER =  alreadyRunnig.filter((item)=>item !==cachedUniqueId)
          localStorage.setItem("isRunningUpload",JSON.stringify(RUNNIG_UPLOADER));
          dispatch(MediaControlAction(false));
          const data = {
            'message': 'Error in media upload',
            'error': error,
            'email': (userProfile||{}).email || '',
            'url': (window.location||{}).href || '',
            'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
            'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
          };
          logger.push({
              'type': 'error',
              'data': data,
            });
          return reject({ message: 'Error in media upload' });
        },
        onProgress: function (bytesUploaded, bytesTotal) {
          const { app: { storage: { isAbort } } } = getState();
          if (isAbort) {
            upload.abort().then(async() => {
              dispatch(MediaActionReset())
              const alreadyRunnig = await JSON.parse(localStorage.getItem("isRunningUpload") || '[]');
              
              const RUNNIG_UPLOADER =  alreadyRunnig.filter((item)=>item !==cachedUniqueId)
              
             localStorage.setItem("isRunningUpload",JSON.stringify(RUNNIG_UPLOADER));
              return reject({ message: "Request has been canceled" });
            }).catch((err) => {
              const data = {
                'message': "Can't abort",
                'error': err,
                'email': (userProfile||{}).email || '',
                'url': (window.location||{}).href || '',
                'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
                'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
              };
              logger.push({
                  'type': 'error',
                  'data': data,
                });
              const alreadyRunnig = JSON.parse(localStorage.getItem("isRunningUpload") || '[]');
              const RUNNIG_UPLOADER =  alreadyRunnig.filter((item)=>item !==cachedUniqueId)
              localStorage.setItem("isRunningUpload",JSON.stringify(RUNNIG_UPLOADER));
              dispatch(setAppMessage({ message: `Can't abort`, header: 'Error', type: 'alert' }));
            })
          }
          else {
            let percentage = (bytesUploaded / bytesTotal * 100).toFixed(2);
            let RUNNING_UPLOADER = JSON.parse(localStorage.getItem("isRunningUpload") || '[]')||[];
            
            if (!RUNNING_UPLOADER.includes(cachedUniqueId)){
              RUNNING_UPLOADER.push(cachedUniqueId);
              localStorage.setItem("isRunningUpload",JSON.stringify(RUNNING_UPLOADER));
            } 
            
            dispatch(MediaControlAction(true))
            dispatch({
              type: app.MediaAction.SET,
              fileMeta: {
                filename: fileMeta.filename,
                percentage: `${percentage}%`,
              },
            })
          }

        },
        onSuccess: function (res) {
          
          dispatch(ProcessingStatus("PROGRESSING"));
          let result = upload.url.split("+")[0];
          let numberOfRetery = 0;
          Id = setInterval(async () => {
            try {
              
              const apiRes = await fetch(`${getURL()}/status?fileId=${result.substr(result.lastIndexOf("/") + 1)}`,{
                headers:{
                  Domain:domainName,
                  tld :tld,
                },
              })
              .then((res) => res.json());
              dispatch(ProcessingStatus(apiRes.status));
              if(apiRes.status ==="PROGRESSING"){
               apiRes.jobPercent ? dispatch(ProcessingResult(apiRes.jobPercent)) :
               dispatch(ProcessingResult(""))
              }
              if (apiRes.status==="ERROR"){
                numberOfRetery = numberOfRetery +1 ;
                if(numberOfRetery >100){
                  clearInterval(Id);
                  const alreadyRunnig = JSON.parse(localStorage.getItem("isRunningUpload") || '[]');
                  const RUNNIG_UPLOADER =  alreadyRunnig.filter((item)=>item !==cachedUniqueId)
                  localStorage.setItem("isRunningUpload",JSON.stringify(RUNNIG_UPLOADER));
                  dispatch(MediaActionReset());
                  const data = {
                    'message': "unable to process media please upload again",
                    'email': (userProfile||{}).email || '',
                    'url': (window.location||{}).href || '',
                    'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
                    'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
                  };
                  logger.push({
                      'type': 'error',
                      'data': data,
                    });
                  return reject({message: "unable to process media please upload again"})
                }
              }
              
              if (apiRes.status === "COMPLETE") {
                const alreadyRunnig = JSON.parse(localStorage.getItem("isRunningUpload") || '[]');
                const RUNNIG_UPLOADER =  alreadyRunnig.filter((item)=>item !==cachedUniqueId)
                localStorage.setItem("isRunningUpload",JSON.stringify(RUNNIG_UPLOADER));
                dispatch(MediaActionReset());
                const finalURL = `${getInputUrl()}/${result.substr(result.lastIndexOf("/") + 1)}`;
                clearInterval(Id);
                return resolve({ uploadedFileName: upload.file.name,
                   uploadedURL: finalURL, serverURL: upload.url, hlsUrl: apiRes.hlsUrl || '' ,thumbnailUrls :apiRes.thumbnailUrl});
              }
            }
            catch (err) {
              console.log("error.....", err)
              const data = {
                'error': err,
                'email': (userProfile||{}).email || '',
                'url': (window.location||{}).href || '',
                'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
                'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
              };
              logger.push({
                  'type': 'error',
                  'data': data,
                });
              dispatch(ProcessingStatus("ERROR"));
            }
          }, 5000);

        },
      });
      (async () => {
        const previousUploads = await upload.findPreviousUploads();
        if (previousUploads.length > 0) {
          // previousUploads[0].creationTime
          dispatch(setAppMessage({
            message: '',
            leftButtonText: 'Start a new upload',
            rightButtonText: 'RESUME',
            handleFailure: () => upload.start(),
            handleSuccess: () => { upload.resumeFromPreviousUpload(previousUploads[0]); upload.start() },
            header: 'You already started uploading this file. Do you want to resume this upload?',
          }));
        }
        else {

          upload.start()
        }


      })()


    })
  }
}


export function abortMediaAction(val) {
  return {
    type: app.MediaControl.IS_ABORT,
    data: val,
  }
}

export function MediaControlAction(val) {
  return {
    type: app.MediaControl.IS_OPEN,
    data: val,
  }
}

export function MediaActionReset() {
  return {
    type: app.MediaControl.RESET,
  }
}

export function ProcessingStatus(status) {
  return {
    type: app.MediaControl.IS_STATUS,
    status,
  }
}

export function ProcessingResult(processingPercentage) {
  return {
    type: app.MediaControl.PROCESSING_PERCENTAGE,
    processingPercentage,
  }
}

export function abortMediaAlert(fun) {
  return (dispatch, getState) => {
    const { app: { storage: { isOpen } } } = getState();
    if (isOpen) {
      dispatch(setAppMessage({
        message: '',
        leftButtonText: 'Close',
        rightButtonText: 'Abort',
        handleSuccess: () => {
          
          dispatch(abortMediaAction(true));
          dispatch(MediaActionReset());
          if(Id){
            clearInterval(Id)
          }
          if (fun instanceof Function) {
            fun();
          }
        }, header: 'Do you want to abort file upload?',
      }));
    }

    else {
      if (fun instanceof Function) {
        fun();
      }
    }
  }
}


function getURL() {
  // eslint-disable-next-line no-undef
  if (process.env.REACT_APP_APP_ENV === 'development') {
    return `https://dev-upload.pubninja.com`;
  }
  // eslint-disable-next-line no-undef
  if (process.env.REACT_APP_APP_ENV === 'production') {
    return `https://upload.pubninja.com`;
  }
  if (process.env.REACT_APP_APP_ENV === 'stage') {
    return `https://upload.pubninja.com`;
  }
  return `https://dev-upload.pubninja.com`;
}

function getInputUrl(){
  if (process.env.REACT_APP_APP_ENV === 'development') {
    return `https://tusd-test.s3.amazonaws.com/inputs`;
  }
  // eslint-disable-next-line no-undef
  if (process.env.REACT_APP_APP_ENV === 'production') {
    return `https://s3.amazonaws.com/cdn.pubninja.com/inputs`;
  }
  if (process.env.REACT_APP_APP_ENV === 'stage') {
    return `https://s3.amazonaws.com/cdn.pubninja.com/inputs`;
  }
  return `https://tusd-test.s3.amazonaws.com/inputs/inputs`;
}

function getUploadUrl(type){

  if (process.env.REACT_APP_APP_ENV === 'development') {
    return `https://dev-upload-elb.pubninja.com/${type==='audio'?'audioUpload':'files'}/`;
  }
  // eslint-disable-next-line no-undef
  if (process.env.REACT_APP_APP_ENV === 'production') {
    return `https://upload.pubninja.com/${type==='audio'?'audioUpload':'files'}/`;
  }
  if (process.env.REACT_APP_APP_ENV === 'stage') {
    return `https://upload.pubninja.com/${type==='audio'?'audioUpload':'files'}/`;
  }
  return `https://dev-upload-elb.pubninja.com/${type==='audio'?'audioUpload':'files'}/`;
}
