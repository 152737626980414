

import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from "commons/muiCustomComponents/Grid";
import Box from "commons/muiCustomComponents/Box";
import moment from 'moment';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import LockIcon from '@material-ui/icons/Lock';
import VideocamIcon from '@material-ui/icons/Videocam';
import ImageIcon from '@material-ui/icons/Image';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ActionSheet from "commons/muiCustomComponents/ActionSheet";
import Button from "commons/muiCustomComponents/Button";
import Delete from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ShareIcon from '@material-ui/icons/Share';
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const imageDefaultImage = 'https://pubninja.com/assets/images/images2-01.png';
const articleDefaulImage = 'https://pubninja.com/assets/images/Article-01.png';
const videoDefaultImage = 'https://pubninja.com/assets/images/video-01.png';
const audioDefaultImage = 'https://pubninja.com/assets/images/Audio-01.png';

const useStyles = makeStyles(theme => ({
  statusButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.pubWhite,
    fontSize: theme.spacing(10 * 0.25),
    height: theme.spacing(13 * 0.25),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  draftButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.pubWhite,
    fontSize: theme.spacing(10 * 0.25),
    height: theme.spacing(13 * 0.25),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  title: {
    fontSize: theme.spacing(4),
    color: theme.palette.primary.main,
    wordBreak: 'break-word',
  },
  time: {
    fontSize: theme.spacing(10 * 0.25),
    color: theme.palette.primary.dark,
    fontWeight: 500,
  },
  imgContainer: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: theme.spacing(5 * 0.25),
  },
  imgBoxContainer: {
    height: theme.spacing(160 * 0.25),
  },
  iconPadding: {
    marginLeft: theme.spacing(2),
  },
  previewLink: {
    marginLeft: theme.spacing(2),
    textDecoration: 'underline',
    fontSize: '1rem',
    color: theme.palette.primary.main,
  },
}));

export default function MedaCard(props) {
  const classes = useStyles();
  const history = useHistory();
  const {
    title,
    status,
    headerContent,
    dateUpdated,
    lastUpdated,
    headerType,
    articleId,
    coverPicture,
    imgExtension,
    paidContent,
    scheduleStatus,
    thumbnail,
    url,
  } = props.item;
  const {handleDeleteMobileArticle =()=>{},handleSearchDeleteMobileArticle=()=>{}} = props;
  const { landing: {
    userPermissions,
  } } = useSelector(state => state);

  const formattedDateUpdated = moment(dateUpdated).format("DD MMM 'YY H:mm a");
  const formattedLastUpdated = moment(lastUpdated).format("DD MMM 'YY H:mm a");

  const goToEditPage = () => {
    if (headerType.indexOf('video') > -1) {
      history.push(`/dash/create/video/${articleId}`);
    }
    if (headerType.indexOf('image') > -1) {
      history.push(`/dash/create/image/${articleId}`);
    }
    if (headerType.indexOf('text') > -1) {
      history.push(`/dash/create/article/${articleId}`);
    }
    if (headerType.indexOf('audio') > -1) {
      history.push(`/dash/create/podcast/${articleId}`);
    }
  }

  const goToSharePage = () => {
    if (headerType.indexOf('video') > -1) {
      history.push(`/dash/create/video/${articleId}#quick-share`);
    }
    if (headerType.indexOf('image') > -1) {
      history.push(`/dash/create/image/${articleId}#quick-share`);
    }
    if (headerType.indexOf('text') > -1) {
      history.push(`/dash/create/article/${articleId}#quick-share`);
    }
    if (headerType.indexOf('audio') > -1) {
      history.push(`/dash/create/podcast/${articleId}#quick-share`);
    }
  }
  
  return <Grid container >
    <Grid item xs={12} md={10} >
      <Grid container >

        {
          headerType && headerType.includes('audio') &&
          <>
            <Grid item xs={12} md={4}>
              <Box className={classes.imgBoxContainer}>
                <img className={classes.imgContainer} src={thumbnail ||  audioDefaultImage} alt="thumbnail" />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container>
                <Grid item xs={10}>
                  <Box mt={4} height="100%">
                    <Box>
                      <Box className={classes.title} >
                        {title === '' ? 'Untitled' : title}
                      </Box>
                      <Box mt={4} className={classes.time} component="p">
                        Updated on {status === 4 ? formattedLastUpdated : formattedDateUpdated}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <ActionSheet type="trippledot" color="primary" parentStyle={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                    {(handleClose) => {
                      return (
                        <>
                          {
                            ((userPermissions.indexOf('CREATE:WRITE') > -1) || (userPermissions.indexOf('CREATE:PUBLISH') > -1)) &&
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="space-evenly"
                              mb={4}
                              mt={4}
                              ml={4}
                              mr={4}
                            >
                              <Button variant="contained"
                                onClick={() => { handleClose(); goToEditPage() }}
                                className={classes.actionSheetButton}
                                color="primary"> <EditIcon fontSize="small" /> Edit
                             </Button>
                            </Box>}
                          {/* {

                            !!status &&
                            ((userPermissions.indexOf('SHARE:NEWSLETTER') > -1) || (userPermissions.indexOf('SHARE:SOCIAL_SCHEDULE') > -1))
                            &&

                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="space-evenly"
                              mb={2}
                              mt={2}
                              ml={4}
                              mr={4}
                            >
                              <Button variant="contained"
                                onClick={() => { handleClose(); goToSharePage() }}
                                className={classes.actionSheetButton}
                                color="primary"> <ShareIcon fontSize="small" /> Share
                           </Button>
                            </Box>

                          } */}
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="space-evenly"
                            mb={2}
                            mt={2}
                            ml={4}
                            mr={4}
                          >
                            <Button variant="contained"
                              onClick={() => { handleClose(); handleDeleteMobileArticle(props.item) ;handleSearchDeleteMobileArticle(props.item) }}
                              className={classes.actionSheetButton}
                              color="primary"> <Delete fontSize="small" /> Delete
                            </Button>
                          </Box>
                        </>
                      )
                    }}
                  </ActionSheet>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={false}>
              <Box display="flex" alignItems="center" mt={4}>
                <Box className={status === 0 ? classes.draftButton : classes.statusButton}>{status === 0 ? 'DRAFT' : 'LIVE'}</Box>
                <MusicNoteIcon color="secondary" fontSize="small" className={classes.iconPadding} />
                {!!paidContent && <LockIcon color="secondary" fontSize="small" className={classes.iconPadding} />}
                { status === 4 && <a className={classes.previewLink} href={url} target='_blank'>preview</a>}
              </Box>
            </Grid>
          </>
        }

        {
          headerType && headerType.includes('video') &&
          <>
            <Grid item xs={12} md={4}>
              <Box className={classes.imgBoxContainer}>
                <img className={classes.imgContainer} src={thumbnail || videoDefaultImage} alt="thumbnail" />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container>
                <Grid item xs={10}>
                  <Box mt={4} height="100%">
                    <Box>
                      <Box className={classes.title} >
                        {title === '' ? 'Untitled' : title}
                      </Box>
                      <Box mt={4} className={classes.time} component="p">
                        Updated on {status === 4 ? formattedLastUpdated : formattedDateUpdated}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <ActionSheet type="trippledot" color="primary" parentStyle={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                    {(handleClose) => {
                      return (
                        <>
                          {
                            ((userPermissions.indexOf('CREATE:WRITE') > -1) || (userPermissions.indexOf('CREATE:PUBLISH') > -1)) &&
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="space-evenly"
                              mb={4}
                              mt={4}
                              ml={4}
                              mr={4}
                            >
                              <Button variant="contained"
                                onClick={() => { handleClose(); goToEditPage() }}
                                className={classes.actionSheetButton}
                                color="primary"> <EditIcon fontSize="small" /> Edit
                             </Button>
                            </Box>
                          }
                          {/* {
                            !!status &&
                            ((userPermissions.indexOf('SHARE:NEWSLETTER') > -1) || (userPermissions.indexOf('SHARE:SOCIAL_SCHEDULE') > -1))
                            &&
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="space-evenly"
                              mb={2}
                              mt={2}
                              ml={4}
                              mr={4}
                            >
                              <Button variant="contained"
                                onClick={() => { handleClose(); goToSharePage() }}
                                className={classes.actionSheetButton}
                                color="primary"> <ShareIcon fontSize="small" /> Share
                           </Button>
                            </Box>
                          } */}
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="space-evenly"
                            mb={2}
                            mt={2}
                            ml={4}
                            mr={4}
                          >
                            <Button variant="contained"
                              onClick={() => { handleClose(); handleDeleteMobileArticle(props.item) ;handleSearchDeleteMobileArticle(props.item)}}
                              className={classes.actionSheetButton}
                              color="primary"> <Delete fontSize="small" /> Delete
                            </Button>
                          </Box>
                        </>
                      )
                    }}
                  </ActionSheet>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={false}>
              <Box display="flex" alignItems="center" mt={4}>
                <Box className={status === 0 ? classes.draftButton : classes.statusButton}>{status === 0 ? 'DRAFT' : 'LIVE'}</Box>
                <VideocamIcon color="secondary" fontSize="small" className={classes.iconPadding} />
                {!!paidContent && <LockIcon color="secondary" fontSize="small" className={classes.iconPadding} />}
                { status === 4 && <a className={classes.previewLink} href={url} target='_blank'>preview</a>}
              </Box>
            </Grid>
          </>
        }

        {
          headerType && headerType.includes('image') &&
          <>
            <Grid item xs={12} md={4}>
              <Box className={classes.imgBoxContainer}>
                <img className={classes.imgContainer} src={thumbnail || imageDefaultImage}
                  alt="thumbnail" />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container>
                <Grid item xs={10}>
                  <Box mt={4} height="100%">
                    <Box>
                      <Box className={classes.title} >
                        {title === '' ? 'Untitled' : title}
                      </Box>
                      <Box mt={4} className={classes.time} component="p">
                        Updated on {status === 4 ? formattedLastUpdated : formattedDateUpdated}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <ActionSheet type="trippledot" color="primary" parentStyle={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                    {(handleClose) => {
                      return (
                        <>
                          {
                            ((userPermissions.indexOf('CREATE:WRITE') > -1) || (userPermissions.indexOf('CREATE:PUBLISH') > -1)) &&

                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="space-evenly"
                              mb={4}
                              mt={4}
                              ml={4}
                              mr={4}
                            >
                              <Button variant="contained"
                                onClick={() => { handleClose(); goToEditPage() }}
                                className={classes.actionSheetButton}
                                color="primary"> <EditIcon fontSize="small" /> Edit
                           </Button>
                            </Box>
                          }
                          {/* {
                            !!status &&
                            ((userPermissions.indexOf('SHARE:NEWSLETTER') > -1) || (userPermissions.indexOf('SHARE:SOCIAL_SCHEDULE') > -1))
                            &&
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="space-evenly"
                              mb={2}
                              mt={2}
                              ml={4}
                              mr={4}
                            >
                              <Button variant="contained"
                                onClick={() => { handleClose(); goToSharePage() }}
                                className={classes.actionSheetButton}
                                color="primary"> <ShareIcon fontSize="small" /> Share
                         </Button>
                            </Box>

                          } */}
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="space-evenly"
                            mb={2}
                            mt={2}
                            ml={4}
                            mr={4}
                          >
                            <Button variant="contained"
                              onClick={() => { handleClose(); handleDeleteMobileArticle(props.item) ;handleSearchDeleteMobileArticle(props.item)}}
                              className={classes.actionSheetButton}
                              color="primary"> <Delete fontSize="small" /> Delete
                          </Button>
                          </Box>
                        </>
                      )
                    }}
                  </ActionSheet>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={false}>
              <Box display="flex" alignItems="center" mt={4}>
                <Box className={status === 0 ? classes.draftButton : classes.statusButton}>{status === 0 ? 'DRAFT' : 'LIVE'}</Box>
                <ImageIcon color="secondary" fontSize="small" className={classes.iconPadding} />
                {!!paidContent && <LockIcon color="secondary" fontSize="small" className={classes.iconPadding} />}
                { status === 4 && <a className={classes.previewLink} href={url} target='_blank'>preview</a>}
              </Box>
            </Grid>
          </>
        }

        {
          headerType && headerType.includes('text') &&
          <>
            <Grid item xs={12} md={4}>
              <Box className={classes.imgBoxContainer}>
                { <img className={classes.imgContainer} src={thumbnail ||  articleDefaulImage} alt="thumbnail" />}
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container>
                <Grid item xs={10}>
                  <Box mt={4} height="100%">
                    <Box>
                      <Box className={classes.title} >
                        {title === '' ? 'Untitled' : title}
                      </Box>
                      <Box mt={4} className={classes.time} component="p">
                        Updated on {status === 4 ? formattedLastUpdated : formattedDateUpdated}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <ActionSheet type="trippledot" color="primary" parentStyle={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                    {(handleClose) => {
                      return (
                        <>
                          {
                            ((userPermissions.indexOf('CREATE:WRITE') > -1) || (userPermissions.indexOf('CREATE:PUBLISH') > -1)) &&

                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="space-evenly"
                              mb={4}
                              mt={4}
                              ml={4}
                              mr={4}
                            >
                              <Button variant="contained"
                                onClick={() => { handleClose(); goToEditPage() }}
                                className={classes.actionSheetButton}
                                color="primary"> <EditIcon fontSize="small" /> Edit
                           </Button>
                            </Box>
                          }
                          {/* {
                            !!status &&
                            ((userPermissions.indexOf('SHARE:NEWSLETTER') > -1) || (userPermissions.indexOf('SHARE:SOCIAL_SCHEDULE') > -1))
                            &&
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="space-evenly"
                              mb={2}
                              mt={2}
                              ml={4}
                              mr={4}
                            >
                              <Button variant="contained"
                                onClick={() => { handleClose(); goToSharePage() }}
                                className={classes.actionSheetButton}
                                color="primary"> <ShareIcon fontSize="small" /> Share
                         </Button>
                            </Box>
                          } */}
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="space-evenly"
                            mb={2}
                            mt={2}
                            ml={4}
                            mr={4}
                          >
                            <Button variant="contained"
                              onClick={() => { handleClose(); handleDeleteMobileArticle(props.item);handleSearchDeleteMobileArticle(props.item) }}
                              className={classes.actionSheetButton}
                              color="primary"> <Delete fontSize="small" /> Delete
                          </Button>
                          </Box>
                        </>
                      )
                    }}
                  </ActionSheet>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={false}>
              <Box display="flex" alignItems="center" mt={4}>
                <Box className={status === 0 ? classes.draftButton : classes.statusButton}>{status === 0 ? 
                  scheduleStatus===0 ? 'SCHEDULED':'DRAFT' :  'LIVE'}</Box>
                <InsertDriveFileIcon color="secondary" fontSize="small" className={classes.iconPadding} />
                {!!paidContent && <LockIcon color="secondary" fontSize="small" className={classes.iconPadding} />}
                { status === 4 && <a className={classes.previewLink} href={url} target='_blank'>preview</a>}
              </Box>
            </Grid>
          </>
        }
      </Grid>
    </Grid>


    {
      /**
       * Setting 
       */
    }
  </Grid>
}


