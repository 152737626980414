import CreatePage from "pages/CreatePage";
import TextArticlePage from "pages/TextArticlePage";
import ImageArticlePage from "pages/ImageArticlePage";
import VideoArticlePage from "pages/VideoArticlePage";
import UserListPage from "pages/userListPage";
import UserPermissionsPage from "pages/UserPermissions";
import AddNewUserPage from "pages/AddUserHomePage";
import SettingsPage from "pages/SettingsPage";
import AnalyticsPage from "pages/AnalyticsPage";
import VisitorPage from "pages/VisitorPage";
import PodcastArticlePage from "pages/PodcastArticlePage";
import OldDashboard from "../pages/OldDashboard";
import AdsPage from "pages/Ads";
import SubscripitionPage from "pages/Subscripition";
import ManageProducts from "pages/ManageProducts";
import NewsletterPage from "pages/NewsletterPage";
// import FacebookPage from "pages/FacebookPage";
// import TwitterPage from "pages/TwitterPage";
import NameLogo from "pages/NameLogo";
import GoogleAnalytics from "pages/GoogleAnalytics";
import SocialAccounts from "pages/SocialAccounts";
import Categories from "pages/Categories";
import DeleteWebsite from "pages/DeleteWebsite";
import MapCustomDomainPage from "pages/MapCustomDomainPage";
import PagesHome from "pages/Pages";
import PageContentHome from "pages/PageContent";
import ProfileHome from "pages/ProfileHome";
import InsightsHome from "pages/InsightsHome";
import WebmasterDomainMap from "pages/WebmasterDomainMap";
import EditProducts  from "pages/EditProduct";
import LiveStreamHome  from "pages/LiveStreamHome";
import LiveMetaCollection from "../components/content/live/LiveMetaCollection";
import LiveTransmission from "../components/content/live/LiveTransmission";
import LiveSchedule from "../components/content/live/LiveSchedule";
import ProductsHome from "pages/Products";
import Customers from "pages/Customers";


/**
 * Contains all the routes in the app that needs to be
 * registered with the react router.
 * Sample route
 * {
 *  path:"/create", // path to shown in url
 * 
 *  component: CreatePage, // component that needs to be rendered
 *  isAuthRequired:true or false // if the route is protected, defaults to false
 *  withDashboard : true or flase, // load the page with dashboard or without dashboard, default
 *                  to false
 *  requiredFeaturePermission: PERM_NAME or null // if the route need any feature permission
 *  requiredPermissions : [] // all the permissions required for the route to be registered
 *  permissionsCheckType: 'OR' or 'AND' // the way we want to check the permissions choose OR 
 *                         if any one permission is fine, choose AND if all the permission are
 *                         needed defaults to OR
 *
 * }
 */
const routes = [
  {
    path:"/dash/create/article/:articleId",
    component: TextArticlePage,
    isAuthRequired:true,
    requiredFeaturePermission:null,
    requiredUserPermissions: ['CREATE:WRITE','CREATE:PUBLISH'],
    permissionsCheckType:"OR",
  },
  {
    path:"/dash/create/image/:articleId",
    component: ImageArticlePage,
    isAuthRequired:true,
    requiredFeaturePermission:null,
    requiredUserPermissions: ['CREATE:WRITE','CREATE:PUBLISH'],
    permissionsCheckType:"OR",
  },
  {
    path:"/dash/create/podcast/:articleId",
    component: PodcastArticlePage,
    isAuthRequired:true,
    requiredFeaturePermission:null,
    requiredUserPermissions: ['CREATE:WRITE','CREATE:PUBLISH'],
    permissionsCheckType:"OR",
  },
  {
    path:"/dash/create/video/:articleId",
    component: VideoArticlePage,
    isAuthRequired:true,
    requiredFeaturePermission:null,
    requiredUserPermissions: ['CREATE:WRITE','CREATE:PUBLISH'],
    permissionsCheckType:"OR",
  },
  {
    path:"/dash/create",
    component: CreatePage,
    isAuthRequired:true,
    requiredFeaturePermission:null,
    requiredUserPermissions: [],
    permissionsCheckType:"OR",
  },
  {
    path:"/dash/old-dashboard",
    component: OldDashboard,
    isAuthRequired:true,
    requiredFeaturePermission:null,
    requiredUserPermissions: [],
    permissionsCheckType:"OR",
  },
  {
    path:"/dash/share/newsletter",
    component: NewsletterPage,
    isAuthRequired:true,
    requiredFeaturePermission:null,
    requiredUserPermissions: ["SHARE:NEWSLETTER"],
    permissionsCheckType:"OR",
    withDashboard: true,
  },
  // {
  //   path:"/dash/share/facebook",
  //   component: FacebookPage,
  //   isAuthRequired:true,
  //   requiredFeaturePermission:null,
  //   requiredUserPermissions: ["SHARE:SOCIAL_SCHEDULE",'SHARE:MANAGE_ACCOUNTS'],
  //   permissionsCheckType:"OR",
  //   withDashboard: true,
  // },
  // {
  //   path:"/dash/share/twitter",
  //   component: TwitterPage,
  //   isAuthRequired:true,
  //   requiredFeaturePermission:null,
  //   requiredUserPermissions: ["SHARE:SOCIAL_SCHEDULE",'SHARE:MANAGE_ACCOUNTS'],
  //   permissionsCheckType:"OR",
  //   withDashboard: true,
  // },
  {
    path:"/dash/monetization/ads",
    component: AdsPage,
    isAuthRequired:true,
    requiredFeaturePermission:null,
    requiredUserPermissions: ['EARN:MANAGE_ADS'],
    permissionsCheckType:"OR",
    withDashboard: true,
  },
  {
    path:"/dash/monetization/subscripition",
    component: SubscripitionPage,
    isAuthRequired:true,
    requiredFeaturePermission:null,
    requiredUserPermissions: ['EARN:MANAGE_ADS'],
    permissionsCheckType:"OR",
    withDashboard: true,
  },

  {
    path:"/dash/products",
    component: ProductsHome,
    isAuthRequired:true,
    requiredFeaturePermission:null,
    requiredUserPermissions: [],
    permissionsCheckType:"OR",
    withDashboard: true,
  },
  {
    path:"/dash/customers",
    component: Customers,
    isAuthRequired:true,
    requiredFeaturePermission:null,
    requiredUserPermissions: ['EARN:READ_INSIGHTS_REVENUE'],
    permissionsCheckType:"OR",
    withDashboard: true,
  },
  {
    path :"/dash/subscripition/manage-product",
    component:ManageProducts,
    isAuthRequired:true,
    requiredFeaturePermission:null,
    requiredUserPermissions: ['EARN:MANAGE_ADS'],
    permissionsCheckType:"OR",
    withDashboard: true,
  },
  {
    path :"/dash/subscripition/edit-product/:productId",
    component:EditProducts,
    isAuthRequired:true,
    requiredFeaturePermission:null,
    requiredUserPermissions: ['EARN:MANAGE_ADS'],
    permissionsCheckType:"OR",
    withDashboard: true,
  },
  {
    path:"/dash/analytics/",
    component: AnalyticsPage,
    isAuthRequired:true,
    requiredFeaturePermission:null,
    requiredUserPermissions: ['EARN:READ_INSIGHTS_REVENUE'],
    permissionsCheckType:"OR",
  },
  {
    path:"/dash/analytics/earnings",
    component: AnalyticsPage,
    isAuthRequired:true,
    requiredFeaturePermission:null,
    requiredUserPermissions: ['EARN:READ_INSIGHTS_REVENUE'],
    permissionsCheckType:"OR",
  },
  {
    path:"/dash/analytics/visitors",
    component: VisitorPage,
    isAuthRequired:true,
    requiredFeaturePermission:null,
    requiredUserPermissions: ['EARN:READ_INSIGHTS_REVENUE'],
    permissionsCheckType:"OR",
  },
  {
    path:"/dash/settings/websites",
    component: SettingsPage,
    isAuthRequired:true,
    requiredFeaturePermission:null,
    requiredUserPermissions: ['SETTING:WEBSITE_SETTINGS'],
    permissionsCheckType:"OR",
  },
  {
    path :"/dash/setting/websites/name&logo",
    component: NameLogo,
    isAuthRequired:true,
    requiredFeaturePermission:null,
    requiredUserPermissions: ['SETTING:WEBSITE_SETTINGS'],
    permissionsCheckType:"OR",
    withDashboard: true,
  },
  {
    path :"/dash/setting/website/webmaster-domain-map",
    component:WebmasterDomainMap,
    isAuthRequired:true,
    requiredFeaturePermission:null,
    requiredUserPermissions: ['SETTING:WEBSITE_SETTINGS'],
    permissionsCheckType:"OR",
    withDashboard: true,
  },
  {
    path :"/dash/setting/websites/googleanalytics",
    component: GoogleAnalytics,
    isAuthRequired:true,
    requiredFeaturePermission:null,
    requiredUserPermissions: ['SETTING:WEBSITE_SETTINGS'],
    permissionsCheckType:"OR",
    withDashboard: true,
  },
  {
    path :"/dash/setting/websites/socialaccounts",
    component: SocialAccounts,
    isAuthRequired:true,
    requiredFeaturePermission:null,
    requiredUserPermissions: ['SETTING:WEBSITE_SETTINGS'],
    permissionsCheckType:"OR",
    withDashboard: true,
  },
  {
    path :"/dash/setting/website/categories",
    component:Categories,
    isAuthRequired:true,
    requiredFeaturePermission:null,
    requiredUserPermissions: ['SETTING:WEBSITE_SETTINGS'],
    permissionsCheckType:"OR",
    withDashboard: true,
  },
  {
    path :"/dash/setting/website/deletemywebsite",
    component:DeleteWebsite,
    isAuthRequired:true,
    requiredFeaturePermission:null,
    requiredUserPermissions: ['SETTING:WEBSITE_SETTINGS'],
    permissionsCheckType:"OR",
    withDashboard: true,
  },
  {
    path :"/dash/setting/website/owndomain",
    component:MapCustomDomainPage,
    isAuthRequired:true,
    requiredFeaturePermission:null,
    requiredUserPermissions: ['SETTING:WEBSITE_SETTINGS'],
    permissionsCheckType:"OR",
    withDashboard: true,
  },
  {
    path :"/dash/setting/website/pages",
    component:PagesHome,
    isAuthRequired:true,
    requiredFeaturePermission:null,
    requiredUserPermissions: ['SETTING:WEBSITE_SETTINGS'],
    permissionsCheckType:"OR",
    withDashboard: true,
  },
  {
    path :"/dash/setting/website/pages/:state/:pageId",
    component:PageContentHome,
    isAuthRequired:true,
    requiredFeaturePermission:null,
    requiredUserPermissions: ['SETTING:WEBSITE_SETTINGS'],
    permissionsCheckType:"OR",
    withDashboard: true,
  },   
  {
    path :"/dash/settings/users",
    component:UserListPage,
    isAuthRequired:true,
    requiredFeaturePermission:null,
    requiredUserPermissions: ['SETTING:USER_MANAGEMENT'],
    permissionsCheckType:"OR",
    withDashboard: true,
  }, 
  
  {
    path :"/dash/user/profile",
    component:ProfileHome,
    isAuthRequired:true,
    requiredFeaturePermission:null,
    requiredUserPermissions: [],
    permissionsCheckType:"OR",
    withDashboard: true,
  },

  {
    path :"/dash/user/analytics",
    component:InsightsHome,
    isAuthRequired:true,
    requiredFeaturePermission:null,
    requiredUserPermissions: ["EARN:READ_INSIGHTS_REVENUE"],
    permissionsCheckType:"OR",
    withDashboard: true,
  },

  {
    path :"/dash/settings/users/permissions/:emailId",
    component:UserPermissionsPage,
    isAuthRequired:true,
    requiredFeaturePermission:null,
    requiredUserPermissions: ['SETTING:USER_MANAGEMENT'],
    permissionsCheckType:"OR",
    withDashboard: true,
  },
  {
    path :"/dash/settings/users/new",
    component:AddNewUserPage,
    isAuthRequired:true,
    requiredFeaturePermission:null,
    requiredUserPermissions: ['SETTING:USER_MANAGEMENT'],
    permissionsCheckType:"OR",
    withDashboard: true,
  },
  {
    path :"/dash/live/metaCollection/:articleId",
    component:LiveMetaCollection,
    isAuthRequired:true,
    requiredFeaturePermission:'LIVE_STREAM',
    requiredUserPermissions: ['CREATE:PUBLISH'],
    permissionsCheckType:"OR",
    withDashboard: true,
  },
  {
    path :"/dash/live/metaCollection",
    component:LiveMetaCollection,
    isAuthRequired:true,
    requiredFeaturePermission:'LIVE_STREAM',
    requiredUserPermissions: ['CREATE:PUBLISH'],
    permissionsCheckType:"OR",
    withDashboard: true,
  },
  {
    path :"/dash/live/transmission/:articleId",
    component:LiveTransmission,
    isAuthRequired:true,
    requiredFeaturePermission:'LIVE_STREAM',
    requiredUserPermissions: ['CREATE:PUBLISH'],
    permissionsCheckType:"OR",
    withDashboard: true,
  },
  {
    path :"/dash/live/schedule",
    component:LiveStreamHome,
    isAuthRequired:true,
    requiredFeaturePermission:'LIVE_STREAM',
    requiredUserPermissions: ['CREATE:PUBLISH'],
    permissionsCheckType:"OR",
    withDashboard: true,
  },
  
];

export default routes;
