import React from "react";
import Dialog from "commons/muiCustomComponents/Dailog";
import Grid from "commons/muiCustomComponents/Grid";
import Box from "commons/muiCustomComponents/Box";
import Button from "commons/muiCustomComponents/Button";
import { makeStyles } from '@material-ui/core/styles';
import MobileProvider from "commons/context/MobileProvider";
import Header from "./components/Header";
import { LinearLoader } from "commons/commonWrapper/Loaders";
import Footer from "./components/Footer";
import ArrowForward from "@material-ui/icons/ArrowForward";
import Edit from "@material-ui/icons/Edit";
import moment from "moment";
import ProgressDialouge from "./components/progressDialouge";
import CloseIcon from '@material-ui/icons/Close';


const useStyles = makeStyles(theme => ({
  stepSection : {
    display:'flex',
    justifyContent:'flex-end',
  },
  mobileTitle:{
    justifyContent:'center',
    display:'flex',
  },
  nextButton: {
    paddingTop: theme.spacing(4),
    paddingBottom:theme.spacing(4),
    width:theme.spacing(50),
    borderRadius:theme.spacing(8),
    margin:theme.spacing(2),
  },
  footerButtonMobile: {
    paddingTop:theme.spacing(2),
    paddingBottom:theme.spacing(2),
    borderRadius:theme.spacing(1),
  },
  mobileFooterContainer: {
    padding:theme.spacing(4),
  },
  mobileFooterItem: {
    paddingLeft:theme.spacing(2),
    paddingRight:theme.spacing(2),
  },
  previewSection: {
    marginTop:theme.spacing(13),
    marginBottom: theme.spacing(25),
  },
  htmlContent: {
    '& figure-image': {
      textAlign: 'center',
      width:'100%',
    },
  },
  titleSection: {
    wordBreak:'break-word',
    fontFamily:"'Open Sans', sans-serif",
    fontSize:'30px',
    lineHeight:'1.13',
    color:'#22364e',
    letterSpacing:'normal',
  },
  descSection:{
    color:'#747572',
    fontSize:'16px',
    whiteSpace:"pre-wrap",
    marginTop:theme.isMobile ?'4px':'16px',
    marginBottom:theme.isMobile ? '8px':'16px',
  },
  mainContainer: {
    margin: theme.isMobile ? theme.spacing(2): 'auto',
  },
  coverImageSection: {
    width:'100%',
    height:theme.isMobile? '200px':'400px',
    objectFit:'cover',
  },
  authorSection: {
    color:'#22364e',
    lineHeight:'2.08',
    fontSize:'12px',
    fontWeight:'600',
    fontFamily:"'Open Sans', sans-serif",
    textTransform:'uppercase',
  },
  dateSection: {
    marginBottom:'20px',
    color:'#9f9f9f',
    letterSpacing:'0.5',
    lineHeight:'2',
    fontSize:'12px',
    fontFamily:"'Open Sans', sans-serif",
  },
  backgroundFullScreen:{
    backgroundColor:theme.palette.pubBackground,
  },
}));

function ArticlePreview(props) {
  
  const classes = useStyles();
  const isMobile = React.useContext(MobileProvider);
  const { showProgress, savingArticle } = props;

  if(!props.open) {
    return null;
  }
  return (
    <>
    {<ProgressDialouge open={showProgress} savingarticle={savingArticle} />}
      <Dialog maxWidth={'md'} fullWidth open={props.open} onClose={props.closeDialog} classes={{paper:classes.backgroundFullScreen}}>
        <Grid container>
          {/**----------------------------Header Section---------------------------- */}
            <Header>
            <Grid container justify="center">
              <Grid item xs={12} md={12}>
                <Box display="flex" alignItems="center" justifyContent="space-between" ml={isMobile ? 4 : 25} mr={isMobile ? 4 : 25} >
                  <Box display="flex" alignItems="center" height="100%">
                    <CloseIcon style={{ color: 'white' }} onClick={props.closeDialog} />
                  </Box>
                  <Box component="p" display="flex" justifyContent="center">Post Preview</Box>
                  <Box></Box>
                </Box>
              </Grid>
            </Grid>
            </Header>
            {props.savingMeta  && <LinearLoader/>}
          {/**----------------------------End Header Section---------------------------- */}



          {/**---------------------------Main Container Section--------------------------*/}
          <Grid container justify="center" className={classes.mainContainer}>
            <Grid item xs={12} md={6} className={classes.previewSection}>
              <Box className={classes.titleSection}>{props.metaData.title}</Box>
              <Box className={classes.descSection}>{props.metaData.description}</Box>
              {props.metaData.author && <Box className={classes.authorSection}>By {props.metaData.author.firstname} {props.metaData.author.lastname}</Box>}
              {props.metaData && <Box className={classes.dateSection}>{moment(props.metaData.dateCreated).format("DD MMM 'YY H:mm a")}</Box>}
              {props.metaData.coverPicture && <Box>
                <img className={classes.coverImageSection} src={`${props.metaData.coverPicture}_800_420${props.metaData.imgExtension}`} alt="cover"/>
              </Box>}
              <Box className={classes.htmlContent} dangerouslySetInnerHTML={{__html:props.content.html}}></Box>
            </Grid>
          </Grid>
          {/**---------------------------End Main Container Section--------------------------*/}


          {/**----------------------------Footer Section-------------------------------------*/}
          {/* <Grid item xs={12} md={12}>
            <Footer>
            {!isMobile && <Grid container justify="center">
                <Grid item md={8} xs={12}>
                  <Box display="flex">
                  <Button 
                  color="secondary"
                  disabled={props.savingMeta} 
                  onClick={props.saveToDraft}>Save Draft</Button>
                  <Button 
                  variant="contained" 
                  disabled={props.savingMeta}
                  boxProps={{ml:4}}
                  onClick={props.publishOrUpdateArticle} 
                  >{props.metaData.status === 4 ? 'Save Changes':'Publish'}</Button>
                  </Box>
                </Grid>
              </Grid>}
              {isMobile && <Grid container justify="center" className={classes.mobileFooterContainer}>
                <Grid item xs={6} sm={6} className={classes.mobileFooterItem}>
                  <Button
                  fullWidth
                  color="secondary"
                  disabled={props.savingMeta} 
                  
                  onClick={props.saveToDraft}>Save Draft</Button>
                </Grid>
                <Grid item xs={6} sm={6} className={classes.mobileFooterItem}>
                  <Button 
                  fullWidth
                  variant="contained" 
                  disabled={props.savingMeta}
                  onClick={props.publishOrUpdateArticle} 
                  >{props.metaData.status === 4 ? 'Save Changes':'Publish'}</Button>
                </Grid>
              </Grid>
              }
            </Footer>
          </Grid> */}
          {/**----------------------------End Footer Section-------------------------------------*/}
        </Grid>
      </Dialog>
    </>
  )
}

export default ArticlePreview;