

import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from "commons/muiCustomComponents/Grid";
import Box from "commons/muiCustomComponents/Box";
import moment from 'moment';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import LockIcon from '@material-ui/icons/Lock';
import MoreIcon from '@material-ui/icons/MoreVert';
import VideocamIcon from '@material-ui/icons/Videocam';
import ImageIcon from '@material-ui/icons/Image';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import IconButton from '@material-ui/core/IconButton';

const imageDefaultImage = 'https://pubninja.com/assets/images/images2-01.png';
const articleDefaulImage = 'https://pubninja.com/assets/images/Article-01.png';
const videoDefaultImage = 'https://pubninja.com/assets/images/video-01.png';
const audioDefaultImage = 'https://pubninja.com/assets/images/Audio-01.png';

const useStyles = makeStyles(theme => ({
  statusButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.pubWhite,
    fontSize: theme.spacing(10 * 0.25),
    height: theme.spacing(13* 0.25),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  draftButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.pubWhite,
    fontSize: theme.spacing(10 * 0.25),
    height: theme.spacing(13* 0.25),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  title: {
    fontSize: theme.spacing(4),
    color: theme.palette.primary.main,
    wordBreak: 'break-word',
  },
  time: {
    fontSize: theme.spacing(10 * 0.25),
    color: theme.palette.primary.dark,
    fontWeight: 500,
  },
  imgContainer: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: theme.spacing(5 * 0.25),
  },
  imgBoxContainer: {
    maxWidth: theme.spacing(208 * 0.25),
    height: theme.spacing(123 * 0.25),
    [theme.breakpoints.up('xl')]: {
      width:'auto',
      maxWidth:'unset',
    },
  },
  iconPadding: {
    marginLeft: theme.spacing(2),
  },
  previewLink: {
    marginLeft: theme.spacing(2),
    textDecoration: 'underline',
    fontSize: '1rem',
    color: theme.palette.primary.main,
  },
}));

export default function MedaCard(props) {
  const classes = useStyles();
  const {
    title="",
    status,
    headerContent,
    dateUpdated,
    lastUpdated,
    headerType,
    articleId,
    thumbnail,
    coverPicture,
    imgExtension,
    paidContent,
    scheduleStatus,
    url,
  } = props.item;
  const formattedDateUpdated = moment(dateUpdated).format("DD MMM 'YY H:mm a");
  const formattedLastUpdated = moment(lastUpdated).format("DD MMM 'YY H:mm a");
  
  return <Grid container >
    <Grid item xs={12} md={10} >
      <Grid container >

        {
          headerType && headerType.includes('audio') &&
          <>
            <Grid item xs={12} md={4}>
              <Box className={classes.imgBoxContainer}>
                <img className={classes.imgContainer} src={thumbnail || audioDefaultImage} alt="thumbnail" />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Box ml={6} display="flex" height="100%" flexDirection="column" justifyContent="space-between">
                <Box>
                  <Box className={classes.title} >
                    {title === '' ? 'Untitled' : title}
                  </Box>
                  <Box mt={4} className={classes.time} component="p">
                    Updated on {status === 4 ? formattedLastUpdated : formattedDateUpdated}
                  </Box>
                </Box>
                <Box display="flex" alignItems="center">
                  <Box className={status === 0 ? classes.draftButton : classes.statusButton}>{status === 0 ? 
                   scheduleStatus===0 ? 'SCHEDULED':'DRAFT': 'LIVE'}</Box>
                  <MusicNoteIcon color="secondary" fontSize="small" className={classes.iconPadding} />
                  {!!paidContent && <LockIcon color="secondary" fontSize="small" className={classes.iconPadding}/>}
                  { status === 4 && <a className={classes.previewLink} href={url} target='_blank'>preview</a>}
                </Box>
              </Box>
            </Grid>
          </>
        }

        {
         headerType && headerType && headerType.includes('video') &&
          <>
            <Grid item xs={12} md={4}>
              <Box className={classes.imgBoxContainer}>
                <img className={classes.imgContainer} src={thumbnail || videoDefaultImage} alt="thumbnail" />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Box ml={6} display="flex" height="100%" flexDirection="column" justifyContent="space-between">
                <Box>
                  <Box className={classes.title} >
                    {title === '' ? 'Untitled' : title}
                  </Box>
                  <Box mt={4} className={classes.time} component="p">
                    Updated on {status === 4 ? formattedLastUpdated : formattedDateUpdated}
                  </Box>
                </Box>
                <Box display="flex" alignItems="center">
                  <Box className={status === 0 ? classes.draftButton : classes.statusButton}>{status === 0 ?
                   scheduleStatus===0 ? 'SCHEDULED':'DRAFT' :  'LIVE'}</Box>
                  <VideocamIcon color="secondary" fontSize="small" className={classes.iconPadding} />
                  {!!paidContent && <LockIcon color="secondary" fontSize="small"  className={classes.iconPadding}/>}
                  { status === 4 && <a className={classes.previewLink} href={url} target='_blank'>preview</a>}
                </Box>
              </Box>
            </Grid>
          </>
        }

        {
          headerType && headerType.includes('image') &&
          <>
            <Grid item xs={12} md={4}>
              <Box className={classes.imgBoxContainer}>
                <img className={classes.imgContainer} src={thumbnail || imageDefaultImage}
                  alt="thumbnail" />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Box ml={6} display="flex" height="100%" flexDirection="column" justifyContent="space-between">
                <Box>
                  <Box className={classes.title} >
                    {title === '' ? 'Untitled' : title}
                  </Box>
                  <Box mt={4} className={classes.time} component="p">
                    Updated on {status === 4 ? formattedLastUpdated : formattedDateUpdated}
                  </Box>
                </Box>
                <Box display="flex" alignItems="center">
                  <Box className={status === 0 ? classes.draftButton : classes.statusButton}>{status === 0 ? 
                  scheduleStatus===0 ? 'SCHEDULED':'DRAFT' :  'LIVE'}</Box>
                  <ImageIcon color="secondary" fontSize="small" className={classes.iconPadding} />
                  {!!paidContent && <LockIcon color="secondary" fontSize="small" className={classes.iconPadding} />}
                  { status === 4 && <a className={classes.previewLink} href={url} target='_blank'>preview</a>}
                </Box>
              </Box>
            </Grid>
          </>
        }

        {
          headerType && headerType.includes('text') &&
          <>
            <Grid item xs={12} md={4}>
              <Box className={classes.imgBoxContainer}>
                {
                <img className={classes.imgContainer} src={thumbnail || articleDefaulImage} alt="thumbnail" />}
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Box ml={6} display="flex" height="100%" flexDirection="column" justifyContent="space-between">
                <Box>
                  <Box className={classes.title} >
                    {title === '' ? 'Untitled' : title}
                  </Box>
                  <Box mt={4} className={classes.time} component="p">
                    Updated on {status === 4 ? formattedLastUpdated : formattedDateUpdated}
                  </Box>
                </Box>
                <Box display="flex" alignItems="center">
                  <Box className={status === 0 ? 
                    classes.draftButton : classes.statusButton}>{status === 0 ? scheduleStatus===0 ? 'SCHEDULED':'DRAFT' :  'LIVE'}</Box>
                  <InsertDriveFileIcon color="secondary" fontSize="small" className={classes.iconPadding} />
                  {!!paidContent && <LockIcon color="secondary" fontSize="small" className={classes.iconPadding} />}
                  { status === 4 && <a className={classes.previewLink} href={url} target='_blank'>preview</a>}
                </Box>
              </Box>
            </Grid>
          </>
        }
      </Grid>
    </Grid>


    {
      /**
       * Setting 
       */
    }


    <Grid item xs={12} md={2}>
      <Box display="flex" justifyContent="flex-end">
        <IconButton color="primary" onClick={(e)=>props.setAnchorEl(e,articleId,headerType,status)}>
          <MoreIcon aria-controls="simple-menu" aria-haspopup="true" />
        </IconButton>
      </Box>
    </Grid>
  </Grid>
}


