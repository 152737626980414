import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from "../../commons/muiCustomComponents/Box";
import Input from "../../commons/muiCustomComponents/Input";
import Button from "../../commons/muiCustomComponents/Button";
import { CircularLoader, PageBlockLoader, LinearLoader } from '../../commons/commonWrapper/Loaders';
import fetchDomainDetails, { setDomainDetails } from "./settings-action";
import { useDispatch } from "react-redux";
import MobileProvider from "commons/context/MobileProvider";
import clsx from "clsx";
import Grid from "commons/muiCustomComponents/Grid";
import Paper from "commons/muiCustomComponents/Paper";


const useStyles = makeStyles(theme => ({
  desktopLayout: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'baseline',
  },
}));

export default function SocialAccount({ appbarheight }) {

  const classes = useStyles();
  const isMobile = useContext(MobileProvider);
  const [isFetching, setfetching] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [domainDetail, setDomainDetail] = useState({});

  //form field
  const [facebook, setFacebook] = useState('');
  const [instagram, setInstagram] = useState('');
  const [twitter, setTwitter] = useState('');
  const [youtube, setYoutube] = useState('');
  const [pinterest, setPinterest] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [snapchat, SetSnapchat] = useState('');
  const [flipboard, SetFlipboard] = useState('');
  const [tiktok, setTikTok] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    let isMounted = true;
    const getDetails = async () => {
      try {
        setfetching(true)
        const detail = await dispatch(fetchDomainDetails())
        // eslint-disable-next-line camelcase
        const { fb_url, flipboard_url, linkedin_url, pintrest_url, insta_url, snapchat_url, yt_url, twitter_url, tiktok_url } = detail;
        isMounted && setDomainDetail(detail)
        isMounted && setFacebook(fb_url)
        isMounted && setInstagram(insta_url)
        isMounted && setTwitter(twitter_url)
        isMounted && setYoutube(yt_url)
        isMounted && setPinterest(pintrest_url)
        isMounted && setLinkedin(linkedin_url)
        isMounted && SetSnapchat(snapchat_url)
        isMounted && SetFlipboard(flipboard_url)
        isMounted && setTikTok(tiktok_url)
        isMounted && setfetching(false)

      } catch (err) {
        isMounted && setfetching(false)
      }
    }
    getDetails();
    return () => (isMounted = false)
  }, []);

  const handleChange = (e, name) => {

    const value = e.currentTarget.value;

    if (name === "facebook") setFacebook(value)
    if (name === "instagram") setInstagram(value)
    if (name === "twitter") setTwitter(value)
    if (name === "youtube") setYoutube(value)
    if (name === "pinterest") setPinterest(value)
    if (name === "linkedin") setLinkedin(value)
    if (name === "snapchat") SetSnapchat(value)
    if (name === "flipboard") SetFlipboard(value)
    if (name === "tiktok") setTikTok(value)
  }

  const saveDomainDetails = async () => {
    // eslint-disable-next-line camelcase
    const payload = {
      // eslint-disable-next-line camelcase
      fb_url: facebook,
      // eslint-disable-next-line camelcase
      flipboard_url: flipboard,
      // eslint-disable-next-line camelcase
      linkedin_url: linkedin,
      // eslint-disable-next-line camelcase
      pintrest_url: pinterest,
      // eslint-disable-next-line camelcase
      insta_url: instagram,
      // eslint-disable-next-line camelcase
      snapchat_url: snapchat,
      // eslint-disable-next-line camelcase
      yt_url: youtube,
      // eslint-disable-next-line camelcase
      twitter_url: twitter,
      // eslint-disable-next-line camelcase
      tiktok_url: tiktok,
    }
    try {
      setLoading(true)
      // eslint-disable-next-line camelcase
      await dispatch(setDomainDetails({ ...domainDetail, ...payload }))
      setLoading(false)
    }
    catch (err) {
      setLoading(false)
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={9} xl={10}>
          {isMobile && isFetching && <LinearLoader top={appbarheight} />}
          {!isMobile && isFetching && <PageBlockLoader />}
          <Box m={isMobile ? 2 : 0} mt={4} mb={4} >
            <Paper>

              <Grid container>
                <Grid item xs={12} md={8} xl={5}>
                  <Box  >

                    <Box mt={isMobile ? 0 : 3} >
                      <Box component="p" color="pubbrownGrey" fontSize={12}>
                        Add your social account's link on your website. Accounts you add here will show up on your website homepage and footer section.
                      </Box>
                      <Box className={clsx({ [classes.desktopLayout]: !isMobile })}>
                        <Box>
                          <Box mt={5}>
                            <Input
                              label="Facebook"
                              fullWidth
                              color="secondary"
                              value={facebook || ''}
                              onChange={(e) => handleChange(e, 'facebook')}
                            />
                          </Box>
                          <Box mt={3}>
                            <Input
                              label="Instagram"
                              fullWidth
                              color="secondary"
                              value={instagram || ''}
                              onChange={(e) => handleChange(e, 'instagram')}
                            />
                          </Box>
                          <Box mt={3}>
                            <Input
                              label="Twitter"
                              fullWidth
                              color="secondary"
                              value={twitter || ''}
                              onChange={(e) => handleChange(e, 'twitter')}
                            />
                          </Box>
                          <Box mt={3}>
                            <Input
                              label="Youtube"
                              fullWidth
                              color="secondary"
                              value={youtube || ''}
                              onChange={(e) => handleChange(e, 'youtube')}
                            />
                          </Box>
                          <Box mt={3}>
                            <Input
                              label="TikTok"
                              fullWidth
                              color="secondary"
                              value={tiktok || ''}
                              onChange={(e) => handleChange(e, 'tiktok')}
                            />
                          </Box>
                        </Box>
                        <Box>
                          <Box mt={3}>
                            <Input
                              label="Pinterest"
                              fullWidth
                              color="secondary"
                              value={pinterest || ''}
                              onChange={(e) => handleChange(e, 'pinterest')}
                            />
                          </Box>
                          <Box mt={3}>
                            <Input
                              label="Linkedin"
                              fullWidth
                              color="secondary"
                              value={linkedin || ''}
                              onChange={(e) => handleChange(e, 'linkedin')}
                            />
                          </Box>
                          <Box mt={3}>
                            <Input
                              label="Snapchat"
                              fullWidth
                              color="secondary"
                              value={snapchat || ''}
                              onChange={(e) => handleChange(e, 'snapchat')}
                            />
                          </Box>
                          <Box mt={3}>
                            <Input
                              label="Flipboard"
                              fullWidth
                              color="secondary"
                              value={flipboard || ''}
                              onChange={(e) => handleChange(e, 'flipboard')}
                            />
                          </Box>
                          
                        </Box>
                      </Box>
                    </Box>

                    <Box display="flex" m={"auto"} mb={3} mt={3} width={isMobile ? "auto" : "95%"}>
                      <Button fullWidth disabled={isLoading} variant="contained" onClick={saveDomainDetails}>
                        Submit
             </Button>
                      {isLoading && <CircularLoader height="auto" size={20} ml={1} />}
                    </Box>

                  </Box>
                </Grid></Grid>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
