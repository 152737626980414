
import React, { useCallback,useContext } from "react";
import Pages from "components/settings/Pages";
import FullScreenPage from '../commons/commonWrapper/FullScreenPage';
import Box from "../commons/muiCustomComponents/Box";
import MobileProvider from "commons/context/MobileProvider";

export default function PagesHome() {

  const isMobile = useContext(MobileProvider);
  const barCallback = useCallback(() => (<Box
    display="flex"
    alignItems="center"
    component="span"
    fontSize ={14}
    >Pages</Box>), []);

  return (
    <>
      <FullScreenPage BarComponent={barCallback}>
        {(appBarHeight) => <Pages appBarHeight={appBarHeight} />}
      </FullScreenPage>
    </>
  )
}
