import React, { useEffect, useState, useContext } from 'react';
import Box from "commons/muiCustomComponents/Box";
import { makeStyles } from '@material-ui/core/styles';
import Button from "commons/muiCustomComponents/Button";
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import {
  getEmailSegmentsList,
  getEmailCampaignList,
} from "../scheduler/scheduler-action";
import MobileProvider from "commons/context/MobileProvider";
import { LinearLoader, PageBlockLoader } from 'commons/commonWrapper/Loaders';
import DataTable from "./DataTable";
import CreateCampaign from "./components/CreateCampaign";




const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.pubWhite,
  },
  uppercontainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingBottom:theme.spacing(4),
  },
  productBackground :{
    backgroundColor:theme.palette.secondary.light,
    border:'none',
    "&:hover,&:focus": {
      backgroundColor:theme.palette.secondary.light,
      border:'none',
    },
  },
}));


export default function EmailPosts() {
  const classes = useStyles();
  const isMobile = useContext(MobileProvider);
  const dispatch = useDispatch();
  const [openCreateCampaign, setOpenCreateCampaign] = useState(false);
  const [isLoader,setLoader] = useState(false);

  const {
    scheduler: {
      emailCampaigns,
    },
  } = useSelector(state =>  state);

  const getCampaigns = () => {
    dispatch(getEmailCampaignList());
  }

  useEffect(() => {
    let isMounted = true;
    async function getPages(){
      try{
        setLoader(true);
        await Promise.all([dispatch(getEmailSegmentsList()), dispatch(getEmailCampaignList())]);
        isMounted && setLoader(false);
      } catch(err){
        isMounted && setLoader(false);
      }
      
    }
    getPages();
    return () => (isMounted = false);
  }, []);

  const openCreateCampaignFunc = () => {
    setOpenCreateCampaign(true);
  }

  const closeCreateCampaignDialog = () => {
    setOpenCreateCampaign(false);
  }

  return (
    <>
      {isLoader && !isMobile && <PageBlockLoader />}
      {isLoader && isMobile && <LinearLoader />}
      <Box mt={4} className={classes.root}>
        <Box bgcolor="pubPrimary"  className={classes.uppercontainer}>
          <Box width={isMobile ?'100%':'65%'} pt={4}  >
          <Box
              component="span"
              color="primary.main"
              display="flex"
              flexDirection="column"
              fontWeight="bold"
            >
             Email-only Posts
          </Box>
          <Box pb={isMobile?4 :0}>
            <Box component="span" fontSize={12} color="secondary.main">
            Create email-only posts to send out to your free and paid email lists. These posts will not be 
            published to the website. You can create regular posts in Post section of the dashboard.
              </Box>
          </Box>
          </Box>
            <Button variant="outlined" className={classes.productBackground} onClick={openCreateCampaignFunc} >
              <AddIcon fontSize="small" />Create Email-only Post
            </Button>
          </Box>
          <Box bgcolor={'white'} pl={4} pr={4} pt={6} pb={6}>
          {
            emailCampaigns && emailCampaigns.filter(item => item.status === 2).length > 0 ?
            <DataTable activeCampaigns={emailCampaigns} /> :
              <Button variant="outlined" className={classes.productBackground} onClick={openCreateCampaignFunc} >
               <AddIcon fontSize="small" />
                  Create Email-only Post
              </Button>
          }
          </Box>
      </Box>
      <CreateCampaign
        open={openCreateCampaign}
        closeCreateCampaignDialog={closeCreateCampaignDialog}
        getCampaigns={getCampaigns}
      />
    </>
  );
}
