import React, { useCallback } from "react";
import PageContent from "../components/settings/PageContent";
import FullScreenPage from '../commons/commonWrapper/FullScreenPage';
import Box from "../commons/muiCustomComponents/Box";

export default function PageContentHome() {
  const barCallback = useCallback(() => (<Box
    display="flex"
    alignItems="center"
    fontSize ={14}
    >Pages</Box>), []);

  return <FullScreenPage BarComponent={barCallback}>
    <PageContent />
  </FullScreenPage>
}
