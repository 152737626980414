import React,{useState} from "react";
import Dialog from "commons/muiCustomComponents/Dailog";
import Grid from "commons/muiCustomComponents/Grid";
import Box from "commons/muiCustomComponents/Box";
import Button from "commons/muiCustomComponents/Button";
import { makeStyles } from '@material-ui/core/styles';
import MobileProvider from "commons/context/MobileProvider";
import Header from "./components/Header";
import { LinearLoader } from "commons/commonWrapper/Loaders";
import Footer from "./components/Footer";
import ArrowForward from "@material-ui/icons/ArrowForward";
import Edit from "@material-ui/icons/Edit";
import ProgressDialouge from "./components/progressDialouge";
import CloseIcon from '@material-ui/icons/Close';
import { useHistory} from "react-router-dom";


const useStyles = makeStyles(theme => ({
  stepSection : {
    display:'flex',
    justifyContent:'flex-end',
  },
  mobileTitle:{
    justifyContent:'center',
    display:'flex',
  },
  nextButton: {
    paddingTop:theme.spacing(4),
    paddingBottom:theme.spacing(4),
    width:theme.spacing(50),
    borderRadius:theme.spacing(8),
    margin:theme.spacing(2),
  },
  footerButtonMobile: {
    paddingTop:theme.spacing(2),
    paddingBottom:theme.spacing(2),
    borderRadius:theme.spacing(1),
  },
  mobileFooterContainer: {
    padding:theme.spacing(4),
  },
  mobileFooterItem: {
    paddingLeft:theme.spacing(2),
    paddingRight:theme.spacing(2),
  },
  snapImage: {
    width:'100%',
    height:'100%',
    objectFit:'cover',
  },
  snapImageBox: {
    marginTop:theme.spacing(8),
    width:'100%',
    height:theme.isMobile ? theme.spacing(70): theme.spacing(75),
  },
  selectImage: {
    paddingTop:theme.spacing(2),
    // height:'100%',
  },
  titleArea: {
    background:theme.palette.pubPaleGrey,
    color:theme.palette.primary.main,
    fontSize:theme.spacing(6),
    paddingTop:theme.spacing(4),
    paddingBottom:theme.spacing(4),
  },
  descriptionArea: {
    marginTop:theme.spacing(8),
  },
  contentContainer: {
    margin:theme.spacing(2),
  },
  preText: {
    whiteSpace:'pre-wrap',
  },
  backgroundFullScreen:{
    backgroundColor:theme.palette.pubBackground,
  },
}));

function ArticlePreview(props) {
  const classes = useStyles();
  const history = useHistory();
  const isMobile = React.useContext(MobileProvider);
  const [showPreviewDialog, setShowPreviewDialog] = useState(false);
  const { showProgress, savingArticle } = props;

  const closePreviewPage = () => {
    setShowPreviewDialog(false);
    history.push('/dash/create');
  }
  return (
    <>
    {<ProgressDialouge open={showProgress} savingarticle={savingArticle}  />}
      <Dialog fullWidth maxWidth={"md"} open={props.open} onClose={props.closeDialog} classes={{paper:classes.backgroundFullScreen}}>
        <Grid container>
          {/**----------------------------Header Section---------------------------- */}
            <Header>
              <Grid container justify="center">
              <Grid container justify="center">
            {<Grid item xs={12} md={12}>
                    <Box display="flex" alignItems="center" justifyContent="space-between" ml={isMobile ?4:25} mr={isMobile ?4:25} >
                      <Box display="flex" alignItems="center" height="100%">
                          <CloseIcon style={{color:'white'}} onClick={props.closePreview}/>
                      </Box>
                      <Box component="p" display="flex" justifyContent="center">Post Preview</Box>
                      <Box  component="p" ></Box>
                      </Box>
                  </Grid>
                }
              </Grid>
              </Grid>
            </Header>
            {props.savingMeta  && <LinearLoader/>}
          {/**----------------------------End Header Section---------------------------- */}



          {/**---------------------------Main Container Section--------------------------*/}
          <Grid container justify="center">
            <Grid container>
              <Grid item xs={12} md={12}>
              {props.savingMeta  && <LinearLoader/>}
              </Grid>
            </Grid>
            <Grid container className={classes.contentContainer}>
            {!isMobile &&<Grid container justify="center" className={classes.titleArea}>
              <Grid item xs={12} md={8}>
                <span>{props.title}</span>
              </Grid>
            </Grid>}
            <Grid container justify="center" className={classes.selectImage}>
              <Grid item xs={12} md={4} >
                <Box >
                  <img className={classes.snapImage} 
                      src={props.metadata && props.metadata.headerContent&& props.metadata.headerContent.cdn } alt="article snap"></img>
                </Box>
              </Grid>
            </Grid>
            <Grid container justify="center" className={classes.descriptionArea}>
              <Grid item xs={12} md={8}>
                <p className={classes.preText}>
                {props.metadata && props.metadata.richDescription && <Box  dangerouslySetInnerHTML={{__html:props.metadata.richDescription.html}}></Box>}
                </p>
              </Grid>
            </Grid>
            </Grid>
          </Grid>
          {/**---------------------------End Main Container Section--------------------------*/}


          {/**----------------------------Footer Section-------------------------------------*/}
          <Grid item xs={12} md={12}>
            {/* <Footer>
            {!isMobile && <Grid container justify="center">
                <Grid item md={8} xs={12}>
                  <Box display="flex">
                  <Button 
                  color="secondary"
                  disabled={props.savingMeta} 
                  
                  onClick={props.saveToDraft}>Save Draft</Button>
                  <Button 
                  variant="contained" 
                  disabled={props.savingMeta}
                  onClick={props.publishOrUpdateArticle} 
                  boxProps={{ml:4}}
                  >{props.status === 4? 'Save Changes':'Publish'}</Button>
                  </Box>
                </Grid>
              </Grid>}
              {isMobile && <Grid container justify="center" className={classes.mobileFooterContainer}>
              <Grid item xs={6} sm={6} className={classes.mobileFooterItem}>
                  <Button
                  fullWidth
                  color="secondary"
                  disabled={props.savingMeta} 
                  
                  onClick={props.saveToDraft}>Save Draft</Button>
                </Grid>
                <Grid item xs={6} sm={6} className={classes.mobileFooterItem}>
                  <Button 
                  fullWidth
                  variant="contained" 
                  disabled={props.savingMeta}
                  onClick={props.publishOrUpdateArticle} 
                  >{props.status === 4? 'Save changes':'Publish'}</Button>
                </Grid>
              </Grid>
              }
            </Footer> */}
          </Grid>
          {/**----------------------------End Footer Section-------------------------------------*/}
        </Grid>
      </Dialog>
    </>
  )
}

export default ArticlePreview;