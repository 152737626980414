import React, { useState, useCallback, useImperativeHandle, forwardRef, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import GridList from '@material-ui/core/GridList';
import Box from "../../commons/muiCustomComponents/Box";
import ImageIcon from '@material-ui/icons/Image';
import AddIcon from '@material-ui/icons/Add';
import WithFileUpload from "commons/hocs/with-file-upload";
import { saveMediaToPisco } from "./content-action";
import { useSelector, useDispatch } from "react-redux";
import { LinearLoader, CircularLoader } from "commons/commonWrapper/Loaders";
import CheckIcon from '@material-ui/icons/Check';
import clsx from "clsx";
import Cropper from 'react-easy-crop';
import MobileProvider from "commons/context/MobileProvider";
import Slider from '@material-ui/core/Slider';
import getCroppedImg from "./components/CropImage";
import UseInfo from "../../commons/CustomComponents/UseInfo";
import setAppMessage from "app-component/app-component-action";

const uploadImage = () => {
  return (
    <Box width={173} style={{
      border: '1px solid #e5e5e5',
      cursor: 'pointer',
      width: '173px !important', height: '142px',
    }} bgcolor="#e5e5e5">
      <Box width="100%" height="100%" display="flex" position="relative" flexDirection="column" alignItems="center" justifyContent="center">
        <Box position="absolute" top="5px" right="5px">
         {/* <UseInfo info="Select and crop the image to 800 x 420 pixels. Size of image must be minimum 200 x 200 pixels." /> */}
        </Box>
        <Box position="relative">
          <AddIcon style={{
            position: 'absolute',
            top: '-7px',
            left: '7px',
          }} />
          <ImageIcon />
        </Box>
        <Box component="span" fontSize={12}> UPLOAD IMAGE </Box>
      </Box>
    </Box>
  )
}

const IsUploadImage = () => {
  return (
    <Box width={173} style={{
      border: '1px solid #e5e5e5',
      width: '173px !important', height: '142px',
    }} bgcolor="#e5e5e5">
      <Box width="100%" height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <CircularLoader height="auto" size={20} message="Uploading ..." />
      </Box>
    </Box>
  )
}

const FileUploaderButton = WithFileUpload(uploadImage);

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },

}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  rootDialog: {
    padding: theme.spacing(0),
    paddingTop: theme.spacing(4),
  },
  paperDialog :{
    margin: theme.spacing(0),
  },
  contnetRoot: {
    justifyContent: 'center',
  },
  cardDesgin: {
    border: '1px solid #e5e5e5',
    width: '170px !important',
    position: 'relative',
  },
  slected: {
    opacity: '0.2',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  unslected: {
    opacity: '0.7',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  checkIconClass: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    fontSize: theme.spacing(11),
    color: theme.palette.pubWhite,
  },
  mediaClass: {
    backgroundColor: 'grey',
  },
}));

const ThumbnailDialogs = ({ setThumbnailDialog, open, thumbnail }, ref) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [thumb, setThumb] = useState(false);
  const [tileData, setTileData] = useState([1, 2, 3, 4, 5]);
  const [isUpLoadingThumbnail, setUploadingThumbnail] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [openCroping, setOpencroping] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(2);
  const [aspect] = useState(80 / 42);
  const [croppedImage, setCroppedImage] = useState(null)
  const isMobile = React.useContext(MobileProvider);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppingImage, setCroppingImage] = useState(false);
  const [filename, setfileName] = useState(null);
  const [displayName ,setDisplayName] = useState(null);

  useEffect(() => {
    try {
    if (typeof thumbnail == 'string') {
      setTileData([thumbnail, 2, 3, 4, 5]);
      setSelectedItem(0);
    }
  }
  catch(err){
    console.log("err",err)
  }
  }, [thumbnail])

  const onCropChange = crop => {
    setCrop(crop);
  }
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }
  const onZoomChange = zoom => {
    setZoom(zoom);
  }



  const handleClose = () => {
    setThumbnailDialog(false);
  };
  const handleFileUpload = (target, type) => {
    const allowed = ['jpg','jpeg', 'png','bmp'];
    const file = target && target.files[0];
    const uploadType = type && type.split("/")[0];
    const extension = file && file.type.split("/")[1];
    if (allowed.indexOf(extension) < 0) {
      dispatch(setAppMessage({ message: 'Unsupported media format.', header: 'Error', type: 'alert' }));
      return;
    }
    const { size } = file;
    const sizeInMb = size / 1000000;
    if (sizeInMb > 500) {
      dispatch(setAppMessage({ message: 'image larger than 500MB not allowed.', header: 'Error', type: 'alert' }));

      return;
    }
    if (file && type && !(file.type.indexOf(uploadType) > -1)) {
      //this.props.InvalidFileError();
      return;
    }
    if (uploadType === 'image' && extension === 'webp') {
      //this.props.InvalidFileError('Webp images are not allowed, as not supported by Safari browser.');
      return;
    }
    if (!file || !file.name) {
      return;
    }
    saveMediaToPiscofun(file)
  }

  const saveMediaToPiscofun = (file) => {

    setUploadingThumbnail(true)
    setfileName(file.name)
    dispatch(saveMediaToPisco(file)).then(res => {
      const { localUploadResponse: { s3Url } } = res;
      setThumb(s3Url);
      tileData.unshift(s3Url);
      if (selectedItem !== null) {
        setSelectedItem(selectedItem + 1)
      }
      if (isNumber(tileData)) {
        tileData.pop()
      }
      setTileData(tileData);
      
      setUploadingThumbnail(false)
    }).catch(err => {
      setUploadingThumbnail(false);
    })
  }

  const proceedCrop = () => {
    setOpencroping(true);
  }
  const goBack = () => {
    setOpencroping(false);
  }

  const setSelected = (item) => {
    if (item === selectedItem) {
      setSelectedItem(null);
      return;
    }
    setSelectedItem(item);
  }
  const showCroppedImage = useCallback(async () => {
    try {
      setCroppingImage(true)
      const croppedImageRes = await getCroppedImg(
        tileData[selectedItem],
        croppedAreaPixels,
      )
      croppedImageRes.file.name = filename;
      const res = await (dispatch(saveMediaToPisco(croppedImageRes.file)));
      const { localUploadResponse: { cdn } } = res;
      setCroppedImage(`https:${cdn}`);
      setDisplayName(filename)
      setCroppingImage(false);
      handleClose();
    } catch (e) {
      console.error(e);
      setCroppingImage(false);
    }
  }, [croppedAreaPixels]);

  useImperativeHandle(ref, () => ({
    getThumbnail() {
      return croppedImage || null;
    },
    getFileName(){
      return displayName ||""
    },
  }));

  return (
    <div>
      <Dialog onClose={handleClose}
        classes={isMobile ?{paper:classes.paperDialog} :{}}
        // fullScreen={isMobile ?true: false} 
        disableEscapeKeyDown disableBackdropClick aria-labelledby="customized-dialog-title" open={open}>

        <DialogContent >
          {openCroping ?
            <Box minWidth={isMobile ?319:520} minHeight={isMobile?213:340} position="relative">
              <Cropper
                image={tileData[selectedItem]}
                crop={crop}
                zoom={zoom}
                aspect={aspect}
                onCropChange={onCropChange}
                onCropComplete={onCropComplete}
                onZoomChange={onZoomChange}
                cropShape='rect'
                style={{ cropAreaStyle: classes.mediaClass }}
              />
            </Box>
            :
            <GridList cellHeight={134} cols={4} classes={{ root: classes.contnetRoot }} >
              {isUpLoadingThumbnail ?
                <Box width={173} style={{ width: '173px !important' }}><IsUploadImage /></Box>
                : <FileUploaderButton
                  type="image/*"
                  onChange={handleFileUpload}
                />
              }
              {tileData.map((tile, key) => {
                return <Box key={key} className={clsx({ [classes.cardDesgin]: true })} >
                  {
                    tile && tile.toString().indexOf('http') == 0 ? <>
                      <img src={tile} alt="thumb-img" width="100%"
                        height="100%" style={{ objectFit: 'cover', cursor: 'pointer' }} onClick={() => setSelected(key)} />
                      {selectedItem !== null && selectedItem === key && <Box
                        className={classes.slected} width="100%" height="100%" bgcolor="pubBlack" onClick={() => setSelected(key)}>

                      </Box>}
                      {
                        selectedItem !== null && selectedItem === key && <CheckIcon className={classes.checkIconClass} onClick={() => setSelected(key)} />
                      }
                      {selectedItem !== null && selectedItem !== key &&
                        <Box width="100%" height="100%" bgcolor="pubWhite" className={classes.unslected} onClick={() => setSelected(key)} />}
                    </>
                      :
                      <Box width="100%" height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        <ImageIcon style={{ opacity: '0.7' }} />
                      </Box>

                  }
                </Box>

              })}
            </GridList>
          }
        </DialogContent>
        <DialogActions>
          {
            openCroping ? <Box width="100%" >
              <Box mb={4}>
                <Box component="span" color="primary.dark" fontSize={12} display="flex" justifyContent="center">
                  Move image or use Zoom slider to fit image in the window
            </Box>
              </Box>
              <Box display="flex" flexWrap="wrap" justifyContent="space-between" mt={5} mb={5} ml={3} mr={3}>
                <Box display="flex" width={isMobile ? "80%" : "35%"} alignItems="center">
                  <ImageIcon style={{ fontSize: '16px', marginRight: '8px' }} />
                  <Slider value={zoom} min={1}
                    max={3}
                    step={0.1} onChange={(e, zoom) => onZoomChange(zoom)} />
                  <ImageIcon style={{ fontSize: '24px', marginLeft: '8px' }} />
                </Box>
                <Box display="flex">
                  <Button onClick={goBack} disabled={croppingImage} variant="contained" color="secondary">
                    BACK
                  </Button>

                  <Button autoFocus disabled={croppingImage} variant="contained" color="primary" style={{ marginLeft: '12px' }} onClick={showCroppedImage}>
                    CROP & CONTINUE
                  </Button>
                  {croppingImage && <CircularLoader size={20} height="auto" ml={2} />}
                  <Box>
                  </Box>

                </Box>
              </Box>
            </Box> : <Box width={518} margin="auto" mt={4}
              mb={4} display="flex" justifyContent={isMobile ? `center` : `flex-end`}><Button onClick={handleClose} variant="contained" color="secondary">
                  CLOSE
          </Button>
                <Button autoFocus
                  style={{ marginLeft: '12px' }}
                  disabled={!(selectedItem !== null)} onClick={proceedCrop} variant="contained" color="primary">
                  proceed to crop
          </Button>
              </Box>
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default forwardRef(ThumbnailDialogs);

function isNumber(arr) {
  let res = false;
  arr.forEach((item) => {
    if (typeof item === 'number') {
      res = true;
      return;
    }
  })
  return res;
}