import React from 'react';
import Stepper from '@material-ui/core/Stepper';

class  CustomStepper extends React.Component {
  render() {
    const {...rest} = this.props;
    return (<Stepper {...rest}  />)
  }
}

export default CustomStepper;