import setAppMessage from "../../app-component/app-component-action";

export function getFacebookPages() {

  return async (dispatch, getState, apiHandler) => {
    try {
      const { api, apiRoutes, actionTypes } = apiHandler;
      const activeDomain = getState().landing.activeDomain;
      const {data} = await api(apiRoutes.fetchFacebookPages(activeDomain));
      dispatch(facebookPages(actionTypes.FETCHFACEBOOK.SUCCESS, data));
      return Promise.resolve(data);
    } catch (err) {
      dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}));
      return Promise.reject(err);
    }
  }
}

export function storeFacebookPage(userData) {

  return async (dispatch, getState, apiHandler) => {
    try {
      const { api, apiRoutes } = apiHandler;
      const activeDomain = getState().landing.activeDomain;
      await api(apiRoutes.storeFacebookPage(userData,activeDomain));
      return Promise.resolve();
    } catch (err) {
      dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}));
      return Promise.reject(err);
    }
  }
}

export function deleteFacebookPage(userData) {

  return async (dispatch, getState, apiHandler) => {
    try {
      const { api, apiRoutes } = apiHandler;
      const activeDomain = getState().landing.activeDomain;
      const page = {page: userData};
      await api(apiRoutes.deleteFacebookPage(page,activeDomain));
      return Promise.resolve();
    } catch (err) {
      dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}));
      return Promise.reject(err);
    }
  }
}

export function getTwitterPages() {

  return async (dispatch, getState, apiHandler) => {
    try {
      const { api, apiRoutes, actionTypes } = apiHandler;
      const activeDomain = getState().landing.activeDomain;
      const {data} = await api(apiRoutes.fetchTwitterPages(activeDomain));
      dispatch(twitterPages(actionTypes.FETCHTWITTER.SUCCESS, data));
    } catch (err) {
      dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}));
      return Promise.reject(err);
    }
  }
}

export function updateUser(userData) {

  return async (dispatch, getState, apiHandler) => {
    if(!userData.screen_name && !userData.oauth_token && !userData.oauth_token_secret){
      return;
    }
    try {
      const { api, apiRoutes } = apiHandler;
      const activeDomain = getState().landing.activeDomain;
      userData.domain_id = activeDomain;
      await api(apiRoutes.updateUser(userData));
      return Promise.resolve();
    } catch (err) {
      dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}));
      return Promise.reject(err);
    }
  }
}

export function deleteTwitterPage(userData) {

  return async (dispatch, getState, apiHandler) => {
    try {
      const { api, apiRoutes } = apiHandler;
      await api(apiRoutes.deleteTwitterPage(userData));
      return Promise.resolve();
    } catch (err) {
      dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}));
      return Promise.reject(err);
    }
  }
}

export function clearFbTwitterAction() {

  return async (dispatch, getState, apiHandler) => {
    try {      
      const { actionTypes } = apiHandler;
      dispatch(facebookPages(actionTypes.CLEARFBPAGES.SUCCESS, []));
      dispatch(facebookPages(actionTypes.CLEARTWITTERACCOUNTS.SUCCESS, []));
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

function facebookPages(actionName, data){
  return{
    type: actionName,
    data: data,
  }
}

function twitterPages(actionName, data){
  return{
    type: actionName,
    data: data,
  }
}
