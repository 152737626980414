import React, { useCallback ,useContext} from "react";
import NameLogo from "../components/settings/NameLogo";
import FullScreenPage from '../commons/commonWrapper/FullScreenPage';
import Box from "../commons/muiCustomComponents/Box";
import MobileProvider from "commons/context/MobileProvider";


export default function NameLogoPage() {
  const isMobile = useContext(MobileProvider);
  const barCallback = useCallback(() => (<Box
    display="flex"
    alignItems="center"
    component="span"
    fontSize ={14}
    >Name & Logo</Box>), []);

  return <FullScreenPage BarComponent={barCallback}>
      {(appBarHeight)=><NameLogo appBarHeight={appBarHeight} /> } 
  </FullScreenPage>
}
