import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography:{
    // fontFamily:'Muli',
  },
  palette: {
    primary: {
      // main :'#007aff',
      main:'#101010',
      light :'#F6F6F5',
      dark:'#9FA3B3',
    },
    secondary: {
      // main :'#464646',
      main:'#585858',
      light:'#E5E5E5',
      dark:'#C1C1C1',
    },
    pubBlack: '#000000',
    // pubdeepSkyBlue: '#007aff',
    pubdeepSkyBlue:'#101010',
    pubGreyishBrown: '#464646',
    pubVeryLightPink: '#f0f0f0',
    pubPaleGrey: '#f5faff',
    pubGreenBlue: '#00a984',
    pubdeepSkyBlueTwo: '#1877f2',
    pubPaleGreyTwo: '#ebf5ff',
    pubScarlet: '#b00020',
    pubRightOrange: '#ff6c00',
    pubLightBlue: '#97c9ff',
    pubRed: '#d10000',
    pubWhite: '#ffffff',
    pubbrownGrey: '#8e8e8e',
    pubveryLightPinkTwo: '#d8d8d8',
    pubGreywhite:'#f8f8f8',
    pubStoryBackground : '#b5b5b51f',
    pubStoryText : "#f58823",
    pubArticleBackground : "#e1e1e1",
    pubGrey: '#4a4a4a',
    pubGreen: '#56aa85',
    publightGreen: "#c7e5f2",
    pubLightGrey: "#f5f5f5",
    pubBackground :'#f6f6f5',

    pubPrimary : '#CCDAD1',
    pubSecondary :'#EDEDEB',
  },
  spacing: 4,
  status: {
    danger: 'orange',
  },
  overrides: {
    MuiButton: {
      label: {
        textTransform:'uppercase',
      },
      root:{
        fontSize:'14px',
        padding:'4px 14px',
      },
      outlined :{
        fontSize:'14px',
        padding:'4px 14px',
      },
      containedPrimary :{
        backgroundColor:'#585858',
        color:'#FFFFFF',
      },
      containedSecondary :{
        backgroundColor:'#e5e5e5',
        color:'#101010',
      },
    },
    MuiPaper:{
      rounded :{
        borderRadius:'6px',
      },
    },
  },
});

theme.shadows[1] = `0 0 6px 0 rgba(34, 54, 78, 0.06)`;

export default responsiveFontSizes(theme);
