import React from 'react';
import Paper from "commons/muiCustomComponents/MuiPaper";
import Box from "commons/muiCustomComponents/Box";
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles =  makeStyles((theme) => ({
     background :{
        backgroundColor: theme.palette.primary.main,
        color:theme.palette.pubWhite,
        height:theme.spacing(10),
        borderRadius: theme.spacing(6 * 0.25),
        display:'flex',
        alignItems:'center',
    },
    button :{
        display:'flex',
        alignItems:'center',
        color: theme.palette.pubWhite,
        marginRight:theme.spacing(3),
        marginLeft:theme.spacing(3),
        cursor:'pointer',
    },
    cursorClass :{
        cursor:'pointer',
    },
  }));

export default function ArticleFilterBar({taskname,goToProduct}){
    const classes = useStyles();
    return (
        <Paper classes={{ root:classes.background}} square elevation={0}>
                <Box className={classes.button} onClick={goToProduct}>
                    <ArrowBackIcon/>
                </Box>
                <Box component="span" ml={6} style={{fontSize:'14px'}}>{taskname}</Box>
        </Paper>
    )
}