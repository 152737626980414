import React from 'react';
import PostAddIcon from '@material-ui/icons/PostAdd';
import SettingsIcon from '@material-ui/icons/Settings';
import AssessmentIcon from '@material-ui/icons/Assessment';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import WidgetsIcon from '@material-ui/icons/Widgets';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import EmailIcon from '@material-ui/icons/Email';
import PagesIcon from '@material-ui/icons/Pages';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

const sideRoute = [
  {
    path:"/dash/create",
    name:"Posts",
    requiredPermission:[],
    permissionType:'OR',
    icon : <PostAddIcon fontSize="small"/>,
    id :0.0,
  },
  
  {
    path:"/dash/live/schedule",
    name:"Live",
    requiredPermission:['CREATE:PUBLISH'],
    requiredFeaturePermission :'LIVE_STREAM',
    permissionType:'AND',
    icon :<LiveTvIcon fontSize="small"/>,
    id :0.4,
  },
  {
    path:"/dash/setting/website/categories",
    name:"Sections",
    requiredPermission:[],
    permissionType:'OR',
    icon :<PagesIcon fontSize="small"/>,
    id :0.4,
  },


  {
    path:"/dash/products",
    name:"Products",
    requiredPermission:[],
    permissionType:'OR',
    icon :<WidgetsIcon fontSize="small"/>,
    id :1.0,
  },
  {
    path:"/dash/customers",
    name:"Customers",
    requiredPermission:[],
    permissionType:'OR',
    icon :<PeopleAltOutlinedIcon fontSize="small"/>,
    id :1.1,
  },

  {
    path:"/dash/share/newsletter",
    name:"Email",
    requiredPermission:['SHARE:NEWSLETTER'],
    permissionType:'OR',
    icon :<EmailIcon fontSize="small"/>,
    id :3.0,
  },

  // {
  //   path:"/dash/monetization/ads",
  //   name:"Ads",
  //   requiredPermission:['EARN:MANAGE_ADS'],
  //   icon :<MonetizationOnIcon fontSize="small"/>,
  //   permissionType:'OR',
  //   id :1.1,
  // },
  {
    path:"/dash/analytics",
    name:"Analytics",
    requiredPermission:['EARN:READ_INSIGHTS_REVENUE'],
    permissionType:'OR',
    icon : <AssessmentIcon fontSize="small"/>,
    isCollapse: true,
    id:2.0,
    views: [
      {
        path:"/dash/analytics/earnings",
        name:"Earnings",
        requiredPermission:['EARN:READ_INSIGHTS_REVENUE'],
        permissionType:'OR',
      },
      {
        path:"/dash/analytics/visitors",
        name:"Visitors",
        requiredPermission: ['EARN:READ_INSIGHTS_REVENUE'],
        permissionType:'OR',
      },
    ],
  },
  {
    path:"/dash/settings",
    name:"Settings",
    requiredPermission:['SETTING:USER_MANAGEMENT','SETTING:WEBSITE_SETTINGS'],
    permissionType:'OR',
    icon : <SettingsIcon fontSize="small"/>,
    isCollapse: true,
    id:2.1,
    views: [
      {
        path:"/dash/settings/websites",
        name:"Websites",
        requiredPermission:['SETTING:WEBSITE_SETTINGS'],
        permissionType:'OR',
      },

      {
        path:"/dash/settings/users",
        name:"Users",
        requiredPermission: ['SETTING:USER_MANAGEMENT'],
        permissionType:'OR',
      },
      {
        path:"/dash/monetization/subscripition",
        name:"Payments",
        requiredPermission:['EARN:MANAGE_ADS'],
        permissionType:'OR',
        // icon :<CardMembershipIcon fontSize="small"/>,
        // id :1.0,
      },
    ],
  },
  {
    path:"",
    notPartOfDashboard :true,
    name:"Google Drive",
    requiredPermission:[],
    permissionType:'OR',
    icon :<InsertDriveFileIcon fontSize="small"/>,
    id :3.1,
  },
]

export default sideRoute;
