import React from "react";
import PodcastHome from "components/content/PodcastHome";

function PodcastPage(props) {

  return(
    <PodcastHome/>
  )
}

export default PodcastPage;