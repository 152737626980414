import React from 'react';
import StepLabel from '@material-ui/core/StepLabel';

class  CustomStepLabel extends React.Component {
  render() {
    const {...rest} = this.props;
    return (<StepLabel {...rest}  />)
  }
}

export default CustomStepLabel;