/**
 * Pubninja custom Button Component
 */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import MuiButton from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Fab from '@material-ui/core/Fab';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import Chip from '@material-ui/core/Chip';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import MoreIcon from '@material-ui/icons/MoreVert';
import MenuIcon from '@material-ui/icons/Menu';
import Box from "./Box";

/**
 * 
 * Give the type of Button and you will get the Button accroding to Button type
 * 
 * supportedType :  'arrowfab' ,
 *                  'addfab', 
 *                  'editfab', 
 *                  'group', 
 *                  'chips' ,
 *                  'checkbox',
 *                  'radio',
 *                  'toggle'
 * By default it return normal Button
 * Each and every Button support all material ui props . you can pass any props that is supported by material ui
 * In case of groupButton , you should pass groupButton props like this
 *  
 *  <Button type ="group"  groupButton ={
        {
          number : 5,
          onClickListeners : [this.handleChange,this.handleChange,this.handleChange] ,
          texts : ['a','b','c','d','e'],
        }
   } /> 
   default number value is 2 and default button name is 'text'            
 */

const ColorSwitch = withStyles((theme) => ({
  switchBase: {
    color: (pr) => getColor(pr, theme),
    '&$checked': {
      color: (pr) => getColor(pr, theme),
    },
    '&$checked + $track': {
      backgroundColor: (pr) => getColor(pr, theme),
    },
  },
  checked: {},
  track: {},
}))((props) => <Switch {...props} />);

const ColorRadio = withStyles((theme) => ({
  root: {
    color: (pr) => getColor(pr, theme),
    '&$checked': {
      color: (pr) => getColor(pr, theme),
    },
  },
  checked: {},
}))(props => <Radio  {...props} />);

const ColorCheckBox = withStyles((theme) => ({
  root: {
    color: (pr) => getColor(pr, theme),
    '&$checked': {
      color: (pr) => getColor(pr, theme),
    },
  },
  checked: {},
}))(props => <Checkbox  {...props} />);

function getColor(pr, theme) {
  if (pr.color === 'primary' || pr.color === 'secondary' || pr.color === 'inherit') {
    return pr.color
  }
  if (pr.color === 'greenButton')
    return theme.palette.pubGreenBlue
  if (pr.color === 'orangeButton')
    return theme.palette.pubRightOrange
}

const useStyles = (props) => makeStyles((theme) => {

  return {
    greenButton: {
      background: theme.palette.pubGreenBlue,
      color: theme.palette.pubWhite,
      "&:hover,&:focus": {
        background: theme.palette.pubGreenBlue,
        color: theme.palette.pubWhite,
      },
    },
    orangeButton: {
      background: theme.palette.pubRightOrange,
      color: theme.palette.pubWhite,
      "&:hover,&:focus": {
        background: theme.palette.pubRightOrange,
        color: theme.palette.pubWhite,
      },
    },
    borderRightStyle :{
      borderTopRightRadius :'0',
      borderBottomRightRadius:'0',
    },
    borderLeftStyle :{
      borderTopLeftRadius :'0',
      borderBottomLeftRadius:'0',
    },
    borderMiddleStyle :{
      borderRadius:'none',
    },
  }
})(props);

function Button(props) {

  const { type,
    color,
    iconName,
    variant,
    groupButton,
    disabled,
    leftText,
    rightText,
    children,
    parentStyle,
    boxProps,
    textColorProps ,
    fontSize,
    ...rest } = props;
  const classes = useStyles(props);
  if (type === 'arrowfab')
    return <GetButton
      parentStyle={parentStyle}
      color={color}
      classes={classes}
      boxProps={boxProps}
      Component={<Fab disabled={disabled} aria-label="Expand" {...rest} > <UpIcon /> </Fab>}
    />

  if (type === 'addfab')
    return <GetButton
      parentStyle={parentStyle}
      color={color}
      classes={classes}
      boxProps={boxProps}
      Component={<Fab disabled={disabled} aria-label="Add" {...rest}  > <AddIcon /> </Fab>}
    />

  if (type === 'editfab')
    return <GetButton
      parentStyle={parentStyle}
      color={color}
      classes={classes}
      boxProps={boxProps}
      Component={<Fab disabled={disabled} aria-label="Edit" {...rest} > <EditIcon /></Fab>}
    />

  if (type === 'group')
    return getButtonGroup(groupButton, classes)

  if (type === 'chips')
    return <GetButton
      parentStyle={parentStyle}
      color={color}
      classes={classes}
      boxProps={boxProps}
      Component={<Chip disableRipple={true} 
      disableFocusRipple={true} label="Basic" disabled={disabled} {...rest}   />}
    />

  if (type === 'toggle')
    return <Box
      display="flex"
      alignItems="center"  {...boxProps}><Box component="p" {...textColorProps}>{leftText}</Box>
      <ColorSwitch
        disabled={disabled} color={color} {...rest} />
      <Box component="p" {...textColorProps}>{rightText}</Box></Box>

  if (type === 'checkbox')
    return <Box
      display="flex"
      alignItems="center"
      {...boxProps}><Box component="p"  {...textColorProps}>{leftText}</Box>
      <ColorCheckBox
        disabled={disabled} color={color}  {...rest} />
      <Box component="p"  {...textColorProps}>{rightText}</Box></Box>


  if (type === 'radio')
    return <Box
      display="flex"
      alignItems="center"
      {...boxProps} > <Box component="p" {...textColorProps}>{leftText}</Box>
      <ColorRadio disabled={disabled} color={color} {...rest} /> <Box component="p" {...textColorProps}>{rightText}</Box> </Box>


  if (type === 'trippledot')
    return <GetButton
      parentStyle={parentStyle}
      color={color}
      classes={classes}
      boxProps={boxProps}
      Component={<IconButton disabled={disabled}  {...rest} >
        <MoreIcon />
      </IconButton>}
    />

  if (type === 'search')
    return <GetButton
      parentStyle={parentStyle}
      color={color}
      
      classes={classes}
      boxProps={boxProps}
      Component={<IconButton disabled={disabled}  {...rest} >
        <SearchIcon fontSize = {fontSize} />
      </IconButton>}
    />
    if (type === 'menubutton')
    return <GetButton
    parentStyle={parentStyle}
    color={color}
    classes={classes}
    
    boxProps={boxProps}
    Component={<IconButton disabled={disabled}  {...rest} >
      <MenuIcon />
    </IconButton>}
  />
  return <GetButton
    parentStyle={parentStyle}
    color={color}
    disableRipple={true} 
    disableFocusRipple={true}
    classes={classes}
    boxProps={boxProps}
    Component={<MuiButton 
      variant={variant} 
      disableRipple={true} 
      disableFocusRipple={true}
      disabled={disabled} {...rest}> {children} </MuiButton>}
  />
}
// eslint-disable-next-line react/no-typos
Button.propTypes = {
  type: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary', "default", "greenButton", "orangeButton"]),
  text: PropTypes.string,
  groupButton: PropTypes.object,
  variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
  iconPosition: PropTypes.oneOf(['left', 'right']),
  disabled: PropTypes.bool,
  leftText: PropTypes.string,
  rightText: PropTypes.string,
  textColorProps : PropTypes.object,
  label : PropTypes.oneOfType([PropTypes.object,PropTypes.string]),
}
/**
 * default props of Button component
 */
Button.defaultProps = {
  groupButton: {
    number: 2,
    texts: ['one', 'two'],
    buttonColors: ['primary', 'primary'],
    disables: [false, false],
    onClickListeners: [() => { }, () => { }],
  },
  type: '',
  color: 'primary',
  text: 'Button Name',
  disabled: false,
  variant: 'outlined',
  leftText: '',
  rightText: '',
  parentStyle: {},
  textColorProps :{},
}
export default Button;

function getButtonGroup(groupButton, classes) {
  const { number = 2, texts = [], buttonColors = [], disables = [], onClickListeners = [],variants=[] } = groupButton;

  // handle empty case
  if (disables.length !== number) fillDefaultValue(disables, false, number)
  if (buttonColors.length !== number) fillDefaultValue(buttonColors, 'primary', number)
  if (texts.length !== number) fillDefaultValue(texts, 'text', number)
  if (onClickListeners.length !== number) fillDefaultValue(onClickListeners, () => { }, number)
  if(variants.length !== number) fillDefaultValue(variants,'outlined',number)

  return  <ButtonGroup fullWidth variant="contained" color="primary" aria-label="contained primary button group">
    {[...Array(number).keys()].map((num, key) => {
      return <GetButton
        key={key}
        color={buttonColors[key]}
        classes={classes}
        Component={<MuiButton fullWidth disabled={disables[key]}
        onClick={onClickListeners[key]}
         variant={variants[key]}
         // eslint-disable-next-line eqeqeq
         className={clsx({[classes.borderLeftStyle] : number==(key+1)},
         // eslint-disable-next-line eqeqeq
         {[classes.borderRightStyle] : (key==0)},
         )}
          > {texts[key]} </MuiButton>}
      />
    })}
  </ButtonGroup>
}

function fillDefaultValue(ele, defaultVal, number) {
  if (Array.isArray(ele)) {
    const remaningEle = number - ele.length;
    remaningEle > 0 && [...Array(remaningEle).keys()].forEach(() => ele.push(defaultVal))
    return ele;
  }
}

function GetButton({ parentStyle, color, boxProps, classes, Component }) {

  if ((typeof parentStyle == 'object' && Object.keys(parentStyle).length) || (typeof boxProps == 'object' && Object.keys(boxProps).length)) {
    return <Box {...boxProps} style={parentStyle}>
      {
        (color === 'primary' || color === 'secondary' || color === "default") ?
        React.cloneElement(Component,{color:color})
          : React.cloneElement(Component,{className:clsx(classes[color])})
      }
    </Box>
  }

  if (color === 'primary' || color === 'secondary' || color === "default")
    return React.cloneElement(Component,{color:color})
  return React.cloneElement(Component,{className:clsx(classes[color])})

}
