import React from "react";
import Drawer from '@material-ui/core/Drawer';


function CustomDrawer (props) {
  const {...rest} = props;
  return (
    <Drawer {...rest}/>
  )
}


export default CustomDrawer;