import React from 'react';

import Box from "../../../commons/muiCustomComponents/Box";


export default function ShowMapDomain({ mapData,saveDomainMapping,isLoading,EditMapDomainName }) {
  // eslint-disable-next-line camelcase
  const { nameServer, status, map_domain } = mapData;
  if (status ==="active") return <Box mt={3}>
          <Box component="p" bgcolor="pubGreywhite" p={3} textAlign="center">{map_domain}&nbsp; is&nbsp; {status}</Box>
  </Box>
  return (
    <>
    <Box mt={3} >
      <Box component="p" fontWeight="bold" mt={0} pt={0}>
         1. Log in to your registrar account
      </Box>
      <Box>
        <Box component="p" fontWeight="bold" mt={6}> 2. Remove these name servers:</Box>
        {nameServer && nameServer.original_name_servers && nameServer.original_name_servers.map((item, keys) => {
          return <Box component="p" key={keys} p={3} bgcolor="pubBackground" color="pubRed"   >{item}</Box>
        })}
      </Box>
      <Box mt={6}>
        <Box component="p" fontWeight="bold"> 3. And replace with:</Box>
        {nameServer && nameServer.name_servers && nameServer.name_servers.map((item, keys) => {
          return <Box component="p" key={keys} p={3} bgcolor="pubBackground" >{item}</Box>
        })}
      </Box>
      </Box>
      
    </>
  );
}