import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from "commons/muiCustomComponents/Grid";
import Box from "commons/muiCustomComponents/Box";
import MobileProvider from "commons/context/MobileProvider";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
// import { getSubStatus } from "./monetization-action";
import { useSelector } from 'react-redux';
import VisitorComponent from "./VisitorComponent";
import { LinearLoader, PageBlockLoader } from 'commons/commonWrapper/Loaders';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  leftRootButton: {
    flexGrow:1,
    maxWidth:'unset',
    borderRight:'1px solid white',
  },
  rightRootButton: {
    flexGrow:1,
    maxWidth:'unset',
    borderRight:'1px solid white',
  },
  leftRootButtonStripe: {
    flexGrow:1,
    maxWidth:'unset',
    borderLeft:'1px solid white',
  },
  RootButton :{
    flexGrow:1,
    maxWidth:'unset',
  },
  navlink: {
    textDecoration: 'none',
    color: theme.palette.pubGreyishBrown,
  },
  iconPosition: {
    justifyContent: theme.isMobile ? 'flex-end' : 'flex-start',
  },
  checkIcon: {
    marginRight: theme.spacing(3),
    fill: theme.palette.primary.main,
    alignSelf: 'center',
  },
  subheading: {
    paddingLeft: theme.spacing(0.5),
    color: theme.palette.pubGreyishBrown,
    paddingBottom: theme.spacing(3),
  },
  container: {
  },
  cardStyle: {
    border: `1px solid rgba(0, 0, 0, 0.12)`,
    boxShadow: 'none',
  },
  textClass: {
    textTransform: 'capitalize',
  },
  tabIndicator: {
    display: 'none',
  },
  selected :{
    fontWeight:'bold',
  },
}));



export default function NewsubScription({ appBarHeight }) {
  const classes = useStyles();
  const isMobile = useContext(MobileProvider);
  const { monetization:{defaultTab}} = useSelector((state) => state);
  const [value, setValue] = React.useState(0);
  const [isLoading] = React.useState(false);

  useEffect(()=>{
    setValue(defaultTab)
  },[defaultTab]);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {isLoading && !isMobile && <PageBlockLoader />}
      {isLoading && isMobile && <LinearLoader />}
      {
        <Box mb={4} mt={isMobile?4:'unset'}>
          <Grid container>
            <Grid item xs={12} md={9} xl={10}>
              <AppBar position="static" elevation={1} style={{ zIndex: 1, borderRadius: '6px', height: '40px', justifyContent: 'center' }}>
                <Tabs value={value} 
                variant = 'scrollable'
                 classes={{ indicator: classes.tabIndicator }}
                  onChange={handleChange} aria-label="simple tabs example">
                  {
                    <Tab classes={{root:classes.rightRootButton,
                      selected :classes.selected,
                    }} label={'Sessions'} {...a11yProps(0)} />
                  }
                  
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                <VisitorComponent />
              </TabPanel>
            </Grid>
          </Grid>
        </Box>
      }
    </>
  );
}