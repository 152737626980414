import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';

//TODO :// change it with custom Dialog
import Dialog from '@material-ui/core/Dialog';

// pub  common component
import Button from '../../commons/muiCustomComponents/Button';
import DialogActions from '../../commons/muiCustomComponents/DialogActions';
import DialogContent from '../../commons/muiCustomComponents/DialogContent';
import DialogContentText from '../../commons/muiCustomComponents/DialogContentText';
import DialogTitle from '../../commons/muiCustomComponents/DialogTitle';
import Box from "../../commons/muiCustomComponents/Box";

import { useDispatch, useSelector } from "react-redux";
import { clearAppMessage } from "../app-component-action";



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.spacing(127/40),
    boxShadow: '0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14)',
    backgroundColor: theme.palette.pubWhite,
    alignSelf: theme.isMobile ? 'flex-end' : 'center',
  },
  progressMedia :{
    borderRadius: theme.spacing(127/40),
    boxShadow: '0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14)',
    backgroundColor: theme.palette.pubWhite,
    alignSelf: 'center',
  },
  dialogTitle :{
    backgroundColor:theme.palette.pubPrimary,
  },
  content: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop:theme.spacing(5),
    fontSize: theme.spacing(4) ,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: theme.spacing(3/40),
    color: theme.palette.pubBlack,
  },
  footer: {
    paddingRight: theme.spacing(25/2),
    paddingBottom: theme.spacing(25/4),
  },
  hadingText: {
    paddingLeft:theme.spacing(4),
    paddingRight:theme.spacing(4),
    fontSize: theme.spacing(4),
    fontWeight: '800',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: theme.spacing(3/40),
    color: theme.palette.pubBlack,
    marginBottom: '0px',
    textTransform:'uppercase',
  },
}));

/**
 * Popup message component
 * @param {String} header heading
 * @param {String} message
 * @param {function} handleSuccess
 * @param {String} leftButtonText
 * @param {String} rightButtonText
 */
function MessageDialog() {
  const { app: { msg: {
    message,
    header,
    isOpen,
    leftButtonText,
    rightButtonText,
    handleFailure,
    handleSuccess,
    type,
    isBodyElement,
    Component } } } = useSelector((state) => state);
    const { app: { storage:  {isOpen: isOpenMedia } } } = useSelector((state) => state);

  const dispatch = useDispatch();

  const handleClose = () => {
    if (typeof handleSuccess === "function") {
      handleSuccess()
    }
    dispatch(clearAppMessage());
  };

  const successHandler = () => {
    if(handleSuccess instanceof Function)handleSuccess();
    dispatch(clearAppMessage());
  }

  const failureHandler = () => {
    
    if(handleFailure instanceof Function)handleFailure();
    dispatch(clearAppMessage());
  }
  const classes = useStyles();
  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      maxWidth={"xs"}
      fullWidth={true}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      classes={
        {
          paper: isOpenMedia ?classes.progressMedia:classes.root,
        }
      }
      style ={isOpenMedia ? {alignSelf:'center'}:{}}
    >
      {typeof Component === 'function' ? Component() : <><DialogTitle 
        classes={{root:classes.dialogTitle}}
        id="alert-dialog-slide-title"><Box component="p" className={classes.hadingText} >{header}</Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.content} id="alert-dialog-slide-description">
            {isBodyElement ? message : message && message.toString()}
          </DialogContentText>
        </DialogContent></>}
      <DialogActions className={classes.footer}>
        {
          type === 'alert' ? <Button variant="contained" onClick={handleClose} color="primary">
            OK
          </Button> :
            <>
              <Button variant="contained" onClick={failureHandler} color="secondary">
                {leftButtonText}
              </Button>
              <Button variant="contained" onClick={successHandler} color="primary">
                {rightButtonText}
              </Button>
            </>
        }
      </DialogActions>
    </Dialog>
  );
}

export default MessageDialog;
