import React, {forwardRef, useRef, useImperativeHandle } from "react";
import {useDispatch} from "react-redux";
import {makeStyles} from '@material-ui/core/styles';
import {getFacebookPages, storeFacebookPage} from '../social-action';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Button from "../../../commons/muiCustomComponents/Button";
import RootRef from '@material-ui/core/RootRef';

const useStyles = makeStyles(theme => ({
  addButton: {
    background: theme.palette.pubLightGrey,
    color: theme.palette.pubGrey,
    height: theme.spacing(10),
    fontWeight: "bold",
    width: "100%",
    border: 0,
    marginTop: theme.spacing(1),
    '&:hover': {
      background: theme.palette.pubLightGrey,
      color: theme.palette.pubGrey,
      border: 0,
    },
  },
  circleIcon: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    color: theme.palette.pubGrey,
    borderRadius: "50%",
  },
}));

const AddFacebookButton = forwardRef((props,ref) => {

  const classes = useStyles();
  const btnRef = useRef();
  const mandatoryPermissions = ['manage_pages', 'publish_pages'];
  const dispatch = useDispatch();

  const {setLoader} = props;

  const fbLogin = () => {
    return new Promise((resolve, reject) => {
      window.FB.login((res)=>{
        if (res.status === 'connected') {
          return resolve(res.authResponse);
        }
        return reject(`Login error, pls login with facebook`);
      }, {scope: mandatoryPermissions.join(',')});
    });
  }

  const checkMandatoryPermissions = (auth) => {
    return new Promise((res, rej) => {
      window.FB.api('/me/permissions', (r) => {
        if (r.error) {
          return rej(r.error.message || r.error);
        } else if (r.data && r.data.length) {
          const grantedPermissions = [];
          Object.keys(r.data).forEach((index) => {
            const obj = r.data[index];
            if (obj.status === 'granted') {
              grantedPermissions.push(obj.permission);
            }
          });
          const permDiff = mandatoryPermissions.filter((perm) => grantedPermissions.indexOf(perm) === -1 );
          if (permDiff.length === 0) {
            return res(auth);
          } else {
            return rej(`Please allow mandatory permissions ${permDiff.join(', ')}`);
          }
        } else {
          return rej(`Error while getting my permissions from Facebook.`);
        }
      });
    });
  }

  const getAllAccounts = (auth) => {
    return new Promise((res, rej) => {
      window.FB.api('/me/accounts', (r) => {
        if (r.error){
          return rej(r.error.message || r.error);
        } else if (r.data && r.data.length) {
          return res({data: r.data, auth});
        }
        return rej(`Error occured while getting your Facebook Page.`);
      });
    });
  }

  const getDebugTokenDB = (obj) => {
    return new Promise((res, rej) => {
      window.FB.api(`/debug_token?input_token=${obj.access_token}`, r => {
        if (r.error) return res(obj);
        obj.debug = r;
        return res(obj);
      });
    });
  }

  const debugToken = (objD) => {
    return new Promise((res, rej) => {
      Promise.all(objD.data.map((obj) => getDebugTokenDB(obj))).then((d)=>res({pagesArr: d, userObj: objD.auth})).catch(e=>rej(e));
    });
  }

  const storeAllFbPages = async (res) => {
    if (res.pagesArr && res.pagesArr.length) {
      try{
        setLoader(true);
        await dispatch(storeFacebookPage(res));
        await dispatch(getFacebookPages());
        setLoader(false);
      } catch(err){
        setLoader(false);
      }      
    } else {
      //No page data
    }
  }

  const connectFB = () => {
    if (window.FB) {
      fbLogin()
        .then((auth) => {
          return checkMandatoryPermissions(auth)
        }).then((auth) => {
          return getAllAccounts(auth)
        }).then((obj) => {
          return debugToken(obj)
        }).then(data => {
          storeAllFbPages(data);
        }).catch((e) => {
          //(e.error||{}).message || e.message
        });
    } else {
      // Facebook script loading error, pls turn off your blocker and retry.
    }
  }

  useImperativeHandle(ref, () => ({
    click: () => {
      connectFB();
    },
  }));

  return (
    <RootRef rootRef={btnRef}>
      <Button 
        className={classes.addButton} 
        onClick={connectFB}        
        startIcon={<AddCircleIcon className={classes.circleIcon} />}
      >
        Add facebook page
      </Button>
    </RootRef>
    
  )
});

export default AddFacebookButton;
