import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import OnBoardHeader from './OnBoardHeader';
import OnBoardFooter from './OnBoardFooter';
import Input from '../../../commons/muiCustomComponents/Input';
import CheckboxWithDescription from '../../../commons/CustomComponents/CheckboxWithDescription';
import {getCreateTypeAction, updateCreateTypeAction, scheduleUpdateUser} from '../onboard-action';
import Box from '../../../commons/muiCustomComponents/Box';
import Typography from '../../../commons/muiCustomComponents/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { LinearLoader } from "commons/commonWrapper/Loaders";
import OnBoardAboutImg from 'assets/images/onboard_about.svg';
import {clearFbTwitterAction} from '../../social/social-action';

const useStyles = makeStyles(theme => ({
  body: {
    marginTop: theme.spacing(25),
    marginBottom: theme.spacing(28),
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(20),
      marginRight: theme.spacing(20),
      marginTop: theme.spacing(30),
    },
  },
  widthObj: {
    width: "100%",
    [theme.breakpoints.up('sm')]: {
      width: "360px",
    },
  },
  creatorHeading: {
    fontSize: theme.spacing(6),
    marginBottom: theme.spacing(8),
  },
  nameContainer: {
    paddingTop: theme.spacing(6),
  },
  onboardContainer: {
    display: "flex",
  },
  onboardContainerLeft: {
    width: "100%",
    [theme.breakpoints.up('sm')]: {
      width: "60%",
    },
  },
  onboardContainerRight: {
    display: "none",
    width: "40%",
    minHeight: "100vh",
    flexGrow: 1,
    background: theme.palette.pubdeepSkyBlue,
    [theme.breakpoints.up('sm')]: {
      display: "block",
    },
  },
  loader: {
    position: "fixed",
    top: theme.spacing(14),
    zIndex: 101,
    left: 0,
    right: 0,
    [theme.breakpoints.up('sm')]: {
      top: theme.spacing(20),
      right: "40%",
    },      
  },
  textSection:{
    fontSize:theme.spacing(11),
    color: theme.palette.pubWhite,
    fontWeight:'700',
    textAlign:'center',
  },
  imageBox: {
    marginLeft:'20%',
    marginTop: '30%',
    width:'80%',
    height:'20%',
  },
  girlImage: {
    width:'100%',
    objectFit:'cover',
  },
}));

const OnBoardAbout = () => {

  const history = useHistory();
  const classes = useStyles();
  const { search } = useLocation();

  const {onBoard : {
    store,
  }, landing: {userProfile}} = useSelector(state =>  state);

  let initialStates = {
    AudioCheck: false,
    VideoCheck: false,
    ImageCheck: false,
    BlogCheck: false,
    OtherCheck: false,
  }

  const labelName = {
    AudioCheck: "Audio",
    VideoCheck: "Video",
    ImageCheck: "Image",
    BlogCheck: "Blog",
    OtherCheck: "Other",
  }
  
  const createType = (store && JSON.parse(store).createType) || {};
  const [initialState, setCreateState] = useState( {...initialStates, ...createType});
  const [userName, setUserName] = useState("");
  const [requesting, setRequesting] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    if(userProfile && userProfile.firstName){
      setUserName(userProfile.firstName + " " + userProfile.lastName);
    }
    async function getCreateType() {
      dispatch(getCreateTypeAction());
      dispatch(clearFbTwitterAction());
    }
    getCreateType();
  },[]);

  const handleChange = (e) => {
    const target = e.target;
    initialState[target.id] = target.checked;
    setCreateState({...initialState});
  }

  const handleNameChange = (e) => {
    setUserName(e.target.value);
  }

  const goNext = async (e) => {
    const fullName = userName.split(' '),
    firstName = fullName[0],
    lastName = fullName[fullName.length - 1];

    const data = {createType : initialState};
    const nameObj = {firstName: firstName, lastName: lastName};
    try{
      setRequesting(true);
      await Promise.all([
        dispatch(scheduleUpdateUser(nameObj)),
        dispatch(updateCreateTypeAction(data)),
      ]);
      setRequesting(false);
      history.push(`/onboard/domain${search}`); 
    } catch(err){
      setRequesting(false);
      //err handling
    }
       
  }

  return (
    <Box className={classes.onboardContainer}>
      <Box className={classes.onboardContainerLeft}>
      <OnBoardHeader />
      <Box className={classes.loader}>
        {requesting && <LinearLoader />}
      </Box>      
      <Box className={classes.body}>
        <Box className={classes.widthObj}>
          <Input fullWidth label="Your Name" value={userName} onChange={handleNameChange} />
        </Box>
        <Box className={classes.widthObj} mt={14}>
          <Typography className={classes.creatorHeading} variant="h3" component="">
            What kind of creator are you?
          </Typography>
          {initialState && Object.keys(initialState).map(item => {
            const label = labelName[item];
            return (
              <CheckboxWithDescription key={item} name={item} label={label} checked={initialState[item]} handleChange={handleChange} />
            )
          })}
        </Box>
      </Box>
      <OnBoardFooter primaryHandler={goNext} disabled={requesting} />
      </Box>
      <Box className={classes.onboardContainerRight}>
        <Box component="p" className={classes.textSection}>The Creator's<br/>Platform</Box>
        <Box className={classes.imageBox}><img src={OnBoardAboutImg} className={classes.girlImage} alt="login"/></Box>        
      </Box>
		</Box>
  )
}

export default OnBoardAbout;