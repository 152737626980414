import React,{useCallback,useContext} from 'react';
import Insights from "../components/insights/Insights";
import FullScreenPage from 'commons/commonWrapper/FullScreenPage';
import Box from "commons/muiCustomComponents/Box";
import MobileProvider from "commons/context/MobileProvider";

export default function InsightsHome() {
  const isMobile = useContext(MobileProvider);

  const barCallback = useCallback(() =>(<Box 
  display="flex"
  alignItems="center"
  >Insights</Box>),[]);
  return (
    <>
      <FullScreenPage BarComponent={barCallback} isDesktop={false} isCloseIcon={true}>
          {(appBarHeight) => <Box mt={isMobile ?0:6}><Insights appbarheight={appBarHeight} /> </Box>}  
      </FullScreenPage>
    </>
  )
}
