/**
 * Input Box Without heading
 */
import React from 'react';
import PropTypes from 'prop-types';
//material ui import
import TextField from '@material-ui/core/TextField';
/**
 * 
 * return default input box 
 * support all props that is provided by material ui
 */
function Input( props ) {
  const {
    variant,
    inputLable,
    ...rest } = props;
  return <TextField  label={inputLable} variant={variant} {...rest} />
}
Input.propTypes = {
  variant: PropTypes.oneOf(['outlined', 'standard', 'filled']),
  inputLable: PropTypes.string.isRequired,
}
Input.defaultProps = {
  variant: 'outlined',
  inputLable: '',
}
export default Input;
