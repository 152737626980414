import React, { useState } from 'react';
import { useSelector } from "react-redux";
import Input from "../../../commons/muiCustomComponents/Input";
import Box from "../../../commons/muiCustomComponents/Box";
import Grid from "commons/muiCustomComponents/Grid";
import Button from "commons/muiCustomComponents/Button";
import { LinearLoader, PageBlockLoader, CircularLoader } from 'commons/commonWrapper/Loaders';
import { LogglyTracker } from 'loggly-jslogger';

const logger = new LogglyTracker();
logger.push({ 'logglyKey': '142d21f5-10d3-47a9-aa5a-f83e0a3abed7', 'sendConsoleErrors' : true, 'tag' : 'ardbeg,advertisement,personalinfo' });


function PersonalInfo(props) {

  const [isLoading, setLoading] = useState(false);
  const [errFristName, setErrFirstName] = useState(false);
  const [errSecondName, setErrSecondName] = useState(false);
  const [errPanNumber, setErrPanNumber] = useState(false);

  const { country,
    setCompletedItem,
    setActiveStepItem,
    setPersonalDetails,
    setfirstName,
    setSecondName,
    setPAN,
    firstName,
    secondName,
    PAN,
    handleSecurityNumber,
    securityNumber,
    secondStepComplete,
    thirdStepComplete,
  } = props;

  const {landing: {userProfile}} = useSelector(state => state);

  const handleFirstName = (e) => {
    if (!e.currentTarget.value) { 
      setfirstName(e.currentTarget.value)
      setErrFirstName(true) }
    else {
      setfirstName(e.currentTarget.value)
      setErrFirstName(false)
    }
  }

  const handleSecondName = (e) => {
    if (!e.currentTarget.value) { 
      setSecondName(e.currentTarget.value)
      setErrSecondName(true) }
    else {
      setSecondName(e.currentTarget.value)
      setErrSecondName(false)
    }
  }

  const handlePAN = (e) => {
    setPAN(e.currentTarget.value)

    try{ 
    if (!e.currentTarget.value || e.currentTarget.value.length !== 10) {
       setErrPanNumber(true) }
    else {
      setErrPanNumber(false)
    }
  }
  catch(err){
    // eslint-disable-next-line no-console
    console.log("err")
  }
  }

  const handleSecurityNumberRow = (e) => {
    handleSecurityNumber(e.currentTarget.value)
  }

  const setPersonalInfo = async () => {

    if (!firstName) {
      setErrFirstName(true)
      return;
    }
    if (!secondName) {
      setErrSecondName(true)
      return;
    }

    if ( country.code === 'IN') {
      if (!PAN || (PAN && PAN.length !== 10)) {
        setErrPanNumber(true)
        return;
      }
    }

    try {
      setLoading(true)
      country.code === 'IN' ?
        await setPersonalDetails({ firstname: firstName, lastname: secondName, pan_number: PAN })
        : await setPersonalDetails({ firstname: firstName, lastname: secondName, social_security: securityNumber })
      setLoading(false)
      if(!secondStepComplete || !thirdStepComplete){
        setActiveStepItem && setActiveStepItem(2);
        setCompletedItem && setCompletedItem({ 0: true, 1: true });
      }
     
    }
    catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      setLoading(false)
    }

  }

  return (<Box mt={2} ml={4} mr={4}  >
    <Grid container >
      <Grid item md={6} xs={12} >
        <Box m={2} >
          <Input
            fullWidth
            color="secondary"
            onChange={handleFirstName}
            vlaue={firstName}
            defaultValue={firstName}
            error={errFristName}
            helperText={errFristName ? 'Legal first name required' : ''}
            label="Legal First Name *">
          </Input>
        </Box>
      </Grid>
      <Grid item md={6} xs={12}>
        <Box m={2}>
          <Input
            fullWidth
            color="secondary"
            defaultValue={secondName}
            onChange={handleSecondName}
            vlaue={secondName}
            error={errSecondName}
            label="Legal Last Name *"
            helperText={errSecondName ? 'Legal last name required' : ''}
          >
          </Input>
        </Box>
      </Grid>
    </Grid>
    {
      country && country.code === 'IN' ?
        <Box m={2}>
          <Input
            fullWidth
            color="secondary"
            value={PAN}
            defaultValue={PAN}
            onChange={handlePAN}
            error={errPanNumber}
            helperText={errPanNumber ? (!PAN) ? 'PAN number Required':`PAN number length must be 10`  : ''}
            label={"PAN *"}>
          </Input>
        </Box>
        :
        <Box m={2}>
          <Input
            fullWidth
            color="secondary"
            value={securityNumber}
            defaultValue={securityNumber}
            onChange={handleSecurityNumberRow}
            label={"Last 4 digits of social security number"}>
          </Input>
        </Box>
    }

    <Box m={2}>
      <Box display="flex">
        <Button variant="contained" disabled={isLoading} onClick={setPersonalInfo}> Save</Button>
        {isLoading && <CircularLoader height="auto" size={20} ml={2} />}
      </Box>
    </Box>
  </Box>)
}
export default PersonalInfo;