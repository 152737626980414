import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import Grid from "commons/muiCustomComponents/Grid";
import Paper from "commons/muiCustomComponents/Paper";
import Button from "commons/muiCustomComponents/Button";
import ButtonGroup from "commons/muiCustomComponents/ButtonGroup";
import Typography from '@material-ui/core/Typography';
import Box from "commons/muiCustomComponents/Box";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import ArrowForward from "@material-ui/icons/ArrowForward";

import {
  getInsightsRevenueAction,
  setEarningsData,
} from "./insights-action";
import MobileProvider from "commons/context/MobileProvider";
import { makeStyles } from '@material-ui/core/styles';
import { CircularLoader } from "commons/commonWrapper/Loaders";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { LogglyTracker } from 'loggly-jslogger';

const logger = new LogglyTracker();
logger.push({ 'logglyKey': '142d21f5-10d3-47a9-aa5a-f83e0a3abed7', 'sendConsoleErrors' : true, 'tag' : 'ardbeg,analytics,ads' });

const useStyles = makeStyles(theme => ({
  quickViewSection: {
    margin: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    height: theme.isMobile ? '50px' : '80px',
    padding: theme.spacing(4),
  },
  detailViewSection: {
    // margin: theme.spacing(2),
  },
  revenueNumber: {
    fontSize: theme.spacing(3),
    color: 'grey',
  },
  trafficNumber: {
    fontSize: theme.spacing(3),
    color: 'grey',
  },
  tabPanel: {
  },
  timeRangeSection: {
    paddingTop: theme.spacing(4),
    // paddingBottom:theme.spacing(4),
    margin: theme.spacing(2),
  },
  timeRangePreviewContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    margin: theme.spacing(2),
  },
  timeRangePreviewSection: {
    fontWeight: '600',
    padding: theme.spacing(2),
    fontSize: theme.spacing(3.5),
    display:'flex',
    justifyContent:'center',
    cursor:'pointer',
    textTransform:'uppercase',
  },
  chartTitle: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    fontWeight: 'bold',
    padding: theme.spacing(2),
  },
  quickViewContainer: {
    marginTop: theme.spacing(4),
  },
  disclaimerContainer: {
    fontSize: theme.spacing(3.5),
  },
  customToolbarContainer: {
    textAlign: 'center',
    background: theme.palette.primary.main,
    color: 'white',
    padding: theme.spacing(2),
  },
  detailViewContainer: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  loaderBox: {
    position: 'absolute',
    top: 0,
    height: '50px',
    width: '100%',
    zIndex: 20,
    background: "rgba(255,255,255,0.5)",
  },
  grossContainer: {
    marginTop:theme.spacing(6),
    fontSize: theme.spacing(4),
    fontWeight: '600',
    color:theme.palette.primary.main,
  },
  tabIndicator :{
    display:'none',
  },
  datRangeGridContainer :{
    justifyContent:'space-between',
  },
  table: {
    // minWidth: 650,
  },
  textdecoration: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  heighPowerFont: {
    fontWeight: 600,
  },
  flexClasses: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  productBackground :{
    backgroundColor:theme.palette.secondary.light,
    border:'none',
    "&:hover,&:focus": {
      backgroundColor:theme.palette.secondary.light,
      border:'none',
    },
  },
  wordBreak :{
    wordBreak:'break-word',
  },

}));

const getFormattedDate = (date) => {
  return moment(date).format('YYYY-MM-DD');
}

function getDateRange(startDate, endDate) {
  let dateList = [];
  let tempDate = startDate;
  while (moment(tempDate).isSameOrBefore(endDate)) {
    dateList.push(getFormattedDate(tempDate));
    tempDate = moment(tempDate).add(1, 'day');
  }
  return dateList;
}

function getDateRangeData(startDate, endDate, data) {
  const temp = {};
  const final = [];
  data.forEach((obj) => {
    if (obj.date) {
      const date = moment(obj.date).format("YYYY-MM-DD");
      temp[date] = obj;
    }
  });
  for (var i = endDate; moment(i).diff(moment(startDate), 'days') >=0; i=moment(i).subtract(1, 'day').format("YYYY-MM-DD")) {
    if (temp[i]) {
      final.push(temp[i]);
    } else {
      final.push({date: i, adsRev: 0});
    }
  }
  if (data.length && data[data.length -1] && data[data.length -1].date === 'Net Total') {
    final.push(data[data.length -1]);
  }
  return final;
}

const Ads = ({ appbarheight }) => {

  const isMobile = useContext(MobileProvider);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const {landing: {userProfile}} = useSelector(state => state);

  const defaultDateRange = {
    startDate: moment(new Date()).subtract(7, 'days').toDate(),
    endDate: moment(new Date()).toDate(),
    key: 'selection',
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchInitialData = async () => {
    try {
      setIsLoading(true);
      dispatch(setEarningsData({ startDate: defaultDateRange.startDate, endDate: defaultDateRange.endDate, data: [] }, 'adsOnly'));
      const dateList = getDateRange(getFormattedDate(defaultDateRange.startDate), getFormattedDate(defaultDateRange.endDate));
      const res = await dispatch(getInsightsRevenueAction(getFormattedDate(defaultDateRange.startDate), getFormattedDate(defaultDateRange.endDate)));
      const formattedData = getDateRangeData(getFormattedDate(defaultDateRange.startDate), getFormattedDate(defaultDateRange.endDate), res);
      dispatch(setEarningsData({ startDate: defaultDateRange.startDate, endDate: defaultDateRange.endDate, data: formattedData }, 'adsOnly'));
    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Grid container className={classes.detailViewContainer}>
      <Grid item md={12} xl={12} xs={12}>
         <Box ml={isMobile?2:0} mr={isMobile?2:0}>
          {RenderChartSection({ isMobile, defaultDateRange, isLoading })}
        </Box>
      </Grid>
    </Grid>
  )
}

export default Ads;

function RenderChartSection(props) {

  const classes = useStyles();
  const { insights: {earnings: {adsOnly}} } = useSelector(state => state);
  return (
    <Box>
      <Grid container >

            <Grid item md={12} xs={12}>

              <Box mt={4}>
                <Paper>
                {RenderTabularWithTimeRange({
                  allowedFilters: ['1d', '1w', '4w'],
                  defaultDateRange: props.defaultDateRange,
                  data: adsOnly,
                  isLoading: props.isLoading,
                })}
              </Paper>
              </Box>
            </Grid>
          </Grid>
          <Grid><Box mt={4}>
              <Paper>
                <Box className={classes.disclaimerContainer}>
                  <strong>Disclaimer:&nbsp;</strong>
                  All the amounts above are estimates that can be readjusted according to the daily and monthly data verification
                  conducted by the external advertiser networks.
                  We plot the revenue statistics directly through APIs from the advertiser’s dashboard which is refreshed automatically every 6 hours.
                </Box>
              </Paper>
            </Box>
          </Grid>
    </Box>
  )
}

function RenderTabularWithTimeRange(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [activeFilter, setActiveFilter] = useState("1w");
  const [isLocalLoading, setisLocalLoading] = useState(false);
  const isMobile = useContext(MobileProvider);
  const filters = isMobile ? ['1d', '1w', '4w', '1y', 'mtd', 'ytd'] : ['1d', '1w', '4w', '1y', 'mtd', 'ytd', 'custom']
  const [dateRange, setDateRange] = useState(props.defaultDateRange);

  let adsRevenueArr = [];
  if (props.data) {
    adsRevenueArr = props.data.data || [];
  }

  const fetchSubscriptionTrasaction = async (activeFilter, dateRange) => {
    setisLocalLoading(true);
    dispatch(setEarningsData({ startDate: dateRange.startDate, endDate: dateRange.endDate, data: [] }, 'adsOnly'));
    try {
      const dateList = getDateRange(getFormattedDate(dateRange.startDate), getFormattedDate(dateRange.endDate));
      let res = await dispatch(getInsightsRevenueAction(getFormattedDate(dateRange.startDate), getFormattedDate(dateRange.endDate)));
      const formattedData = getDateRangeData(getFormattedDate(dateRange.startDate), getFormattedDate(dateRange.endDate), res);
      dispatch(setEarningsData({ startDate: dateRange.startDate, endDate: dateRange.endDate, data: formattedData }, 'adsOnly'));
    } catch (err) {
      // handle error
    } finally {
      setisLocalLoading(false);
    }
  }
  const handleFilterChange = (value, range) => {
    setActiveFilter(value);
    let newDateRange = dateRange;
    switch (value) {
      case '1d':
        newDateRange = {
          startDate: moment().toDate(),
          endDate: moment().toDate(),
          key: 'selection',
        }
        break;
      case '1w':
        newDateRange = {
          startDate: moment().subtract(1, 'week').toDate(),
          endDate: moment().toDate(),
          key: 'selection',
        }
        break;
      case '4w':
        newDateRange = {
          startDate: moment().subtract(4, 'weeks').toDate(),
          endDate: moment().toDate(),
          key: 'selection',
        }
        break;
      case '1y':
        newDateRange = {
          startDate: moment().subtract(1, 'year').toDate(),
          endDate: moment().toDate(),
          key: 'selection',
        }
        break;
      case 'mtd':
        newDateRange = {
          startDate: moment().startOf('month').toDate(),
          endDate: moment().toDate(),
          key: 'selection',
        }
        break;
      case 'ytd':
        newDateRange = {
          startDate: moment().startOf('year').toDate(),
          endDate: moment().toDate(),
          key: 'selection',
        }
        break;
      case 'custom':
        newDateRange = range;
        break;
      default: return;
    }
    setDateRange(newDateRange);
    fetchSubscriptionTrasaction(value, newDateRange);
  }
  return (
    <Box>
      <Box style={{ position: 'relative'}}>
        {(props.isLoading || isLocalLoading) &&
          <Box display="flex" justifyContent="center" alignItems="center" className={classes.loaderBox}>
            <CircularLoader></CircularLoader>
          </Box>
        }
      </Box>

      {(!props.isLoading || !isLocalLoading) &&
        <Box>
          {
            TimeRangeSelector({
              allowedFilters: filters,
              activeFilter,
              handleFilterChange,
              dateRange,
              setDateRange: setDateRange,
              showSelectedDates: true,
            })
          }
          <Box mt={3}>
           <TableContainer>
             <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {adsRevenueArr && adsRevenueArr.length > 0 && <TableCell className={classes.heighPowerFont}>DATE</TableCell>}
                  {adsRevenueArr && adsRevenueArr.length > 0 &&
                    <TableCell  className={classes.heighPowerFont}>USD</TableCell>
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  adsRevenueArr && adsRevenueArr.length > 0 && adsRevenueArr.map((obj, i) => {
                    return <TableRow key={i}>
                      <TableCell component="th" scope="row" className={classes.wordBreak}>{obj.date}</TableCell>
                      <TableCell  className={classes.wordBreak}>{(Math.round(obj.adsRev * 100) / 100).toFixed(2) || 0}</TableCell>
                    </TableRow>
                  })
                }
              </TableBody>
             </Table>
           </TableContainer>
          </Box>
        </Box>
      }
    </Box>
  )
}


function TimeRangeSelector(props) {

  const classes = useStyles();
  const { allowedFilters, dateRange } = props;
  const [showStartDatePicker, setStartShowDatePicker] = useState(false);
  const [showEndDatePicker, setShowEndDatePicker] = useState(false);

  const isMobile = useContext(MobileProvider);

  const handleFilterChange = (event) => {
    const value = event.currentTarget.value;
    if (value === 'custom') {
      setStartShowDatePicker(true);
      return;
    }
    props.handleFilterChange(value);
  }
  const handleStartSelect = (moment) => {
    props.setDateRange({
      startDate: moment.toDate(),
      endDate: moment.toDate(),
      key: 'selection',
    });
    setTimeout(() => {
      setStartShowDatePicker(false);
      setShowEndDatePicker(true);
    }, 200);
  }

  const handleEndSelect = (momentDate) => {
    props.setDateRange({
      startDate: moment(dateRange.startDate).toDate(),
      endDate: momentDate.toDate(),
      key: 'selection',
    });
    setShowEndDatePicker(false);
    props.handleFilterChange('custom', {
      startDate: moment(dateRange.startDate).toDate(),
      endDate: momentDate.toDate(),
      key: 'selection',
    });
  }

  const CustomDatePickerField = (props) => {
    return (
      <span onClick={() => props.onClickHandler(true)}>{props.value}</span>
    )
  }

  const CustomToolbar = (data, props) => {
    return (
      <Box className={classes.customToolbarContainer}>
        <Typography variant="h4">{data}</Typography>
        <Typography variant="h5">{moment(props.date).format('DD,MMM YYYY')}</Typography>
      </Box>
    )
  }
  return (
    <Grid container className={classes.datRangeGridContainer}>
      <Grid item xs={12} md={8} lg={6}>
        <ButtonGroup  size="small" aria-label="small button group">
          {
            allowedFilters.map((item) => {
              return (
                <Button key={item} variant={props.activeFilter === item ? 'contained' : 'outlined'} value={item} onClick={handleFilterChange}>
                  {item.toUpperCase()}
                </Button>
              )
            })
          }
        </ButtonGroup>
      </Grid>
      <Grid item md={4} lg={4}>
        {props.showSelectedDates && !isMobile && dateRange && <Box >
          <Paper className={classes.timeRangePreviewSection}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                open={showStartDatePicker}
                autoOk
                disableFuture
                onClickHandler={() => { setStartShowDatePicker(true) }}
                onOpen={() => setStartShowDatePicker(true)}
                onClose={() => setStartShowDatePicker(false)}
                maxDate={moment()}
                format='DD MMM YYYY'
                TextFieldComponent={CustomDatePickerField}
                ToolbarComponent={CustomToolbar.bind(null, 'Start Date')}
                value={moment(dateRange.startDate)}
                onChange={handleStartSelect}
                variant="inline"
              />
              <ArrowForward style={{ fontSize: '14px' }} />
              <KeyboardDatePicker
                open={showEndDatePicker}
                autoOk
                disableToolbar={false}
                onClickHandler={(event) => { setShowEndDatePicker(true) }}
                onOpen={() => setShowEndDatePicker(true)}
                onClose={() => setShowEndDatePicker(false)}
                minDate={moment(dateRange.startDate)}
                maxDate={moment()}
                format='DD MMM YYYY'
                disableFuture
                value={moment(dateRange.endDate)}
                TextFieldComponent={CustomDatePickerField}
                ToolbarComponent={CustomToolbar.bind(null, 'End Date')}
                onChange={handleEndSelect}
                variant="inline"
              />
            </MuiPickersUtilsProvider>
          </Paper>
        </Box>}
      </Grid>
    </Grid>
  )
}

