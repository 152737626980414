
const schedulerAction = {
    FETCHSCHEDULEDTWEETS: {
      SUCCESS: "scheduledTweetsSuccess",
    },
    FETCHSCHEDULEDFBPOSTS: {
        SUCCESS: "scheduledFbPostsSuccess",
    },
    FETCHNEWSLETTER: {
      SUCCESS: "fetchNewsletterSuccess",
    },
    FETCHDOMAINCAMPAIGNS: {
      SUCCESS: "fetchCampaignsSuccess",
    },
}
      
export default schedulerAction;