import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import AddCircle from '@material-ui/icons/AddCircleOutline';
import setAppMessage from "app-component/app-component-action";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Avatar from '@material-ui/core/Avatar';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Paper from '@material-ui/core/Paper';
import SideBar from "../../components/sidebar/SideBarMobile";
import Button from "../muiCustomComponents/Button";
import ActionSheet from "../muiCustomComponents/ActionSheet";
import Box from "../muiCustomComponents/Box";
import AppBar from '../muiCustomComponents/AppBar';
import FullScreenDialog from "./FullScreenDialog";
import { FixedSizeList as List } from 'react-window';
import WithFileUpload from "commons/hocs/file-upload";
import { useHistory } from "react-router-dom";
import VideocamIcon from '@material-ui/icons/Videocam';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import ImageIcon from '@material-ui/icons/Image';
import EditIcon from '@material-ui/icons/Edit';
import LiveTvIcon from '@material-ui/icons/LiveTv';


import { useDispatch, useSelector } from "react-redux";

import { fetchDomainList, updateActiveDomain } from "../../components/header/header-action";
import { hideIntercom } from 'utils/utils';

const useStyles = makeStyles(theme => ({
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 12,
    background: theme.palette.pubBackground,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    background: theme.palette.pubBackground,
    boxShadow:'none',
  },
  topBar: {
    top: '0',
    background: theme.palette.pubBackground,
    boxShadow:'none',
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    right: 0,
    margin: '0 auto',
    width:theme.spacing(12),
    height:theme.spacing(12),
  },
  domainHeading: {
    backgroundColor: theme.palette.pubWhite,
    color: theme.palette.pubBlack,
    marginBottom: theme.spacing(3),
  },
  websiteButton: {
    background: theme.palette.pubWhite,
    color: theme.palette.pubBlack,
    boxShadow:'none',
    "&:hover,&:focus": {
      background: theme.palette.pubWhite,
      color: theme.palette.pubBlack,
      boxShadow:'none',
    },
  },
  buttonStyle: {
    backgroundColor: theme.palette.pubPaleGreyTwo,
    color: theme.palette.pubdeepSkyBlue,
    fontSize: theme.spacing(4),
    fontWeight: 700,
    minHeight: theme.spacing(14),
    margin: 'auto',
    borderRadius: theme.spacing(2),
    width: '90%',
    overflow: 'hidden',
    "&:hover,&:focus": {
      color: theme.palette.pubWhite,
      backgroundColor: theme.palette.pubdeepSkyBlue,
    },
  },
  activeButton: {
    color: theme.palette.pubWhite,
    backgroundColor: theme.palette.pubdeepSkyBlue,
    fontSize: theme.spacing(4),
    fontWeight: 700,
    minHeight: theme.spacing(14),
    margin: 'auto',
    borderRadius: theme.spacing(2),
    width: '90%',
    overflow: 'hidden',
    "&:hover,&:focus": {
      color: theme.palette.pubWhite,
      backgroundColor: theme.palette.pubdeepSkyBlue,
    },
  },
  addButtonMobile: {
    width: '100%',
    padding: theme.spacing(4, 0),
    margin: theme.spacing(2, 0),
    color: theme.palette.pubWhite,
    fontWeight: 500,
  },
  domainListItem: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  addDomainButton: {
    padding:theme.spacing(2),
  },
  avatorColor :{
    backgroundColor:theme.palette.secondary.main,
  },
  bigAvator :{
    width: theme.spacing(9),
    height : theme.spacing(9),
    fontSize:theme.spacing(3),
  },
  toolBarRoot :{
    width:'90%',
    margin:'auto',
    paddingBottom:theme.spacing(3),
    paddingTop:theme.spacing(3),
  },
}));

const WithUploadButton = WithFileUpload(Button);

export default function MobileWrapper(props) {
  const { children,isCreateDisable = false } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();


  const { header: { domainList }, landing: { activeDomain, userPermissions, featurePermissions,
     userProfile: { profilePicture, firstName, lastName } }, insights: {
      revenueHeader,
    } } = useSelector(state => state);
  const domainArray = mapDomainsToArray(domainList);

  const createImageArticle = (target, type) => {
    history.push("/dash/create/image/new");
  }

  const createVideoArticle = (target, type) => {
    history.push("/dash/create/video/new");
  }

  // TODO : update domain on button select
  const updateCurrentDomain = (activeDomain, closeDrawer) => {
    dispatch(updateActiveDomain(activeDomain));
    sessionStorage.setItem('active_domain_id', activeDomain);
    localStorage.setItem('active_domain_id', activeDomain);
    history.push("/");
    closeDrawer();
    return;
  }

  function fetchDomainNames() {
    dispatch(fetchDomainList());
  }

  const goToProfile = () => {
    history.push("/dash/user/profile");
  }

  function goToInsights(){
    history.push("/dash/analytics/earnings");
  }

  const goToAddDomain = () => {
    history.push("/onboard");
  }
  const createName = () => {
    if(firstName && lastName) {
      return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase()
    }
    if(firstName) {
      return `${firstName.charAt(0)}${firstName.charAt(1)}`.toUpperCase()
    }
    return 'User'
  }
  useEffect(() => {
    fetchDomainNames();
    // show the intercom for mobile by passing invert boolean
    // it will hide the intercom when this components unmounts
    return hideIntercom(true);
  }, []);

  function RenderList(props) {
    function renderRow({ index, style }) {
      const item = domainArray[index];
      return (
        <Button
          onClick={() => updateCurrentDomain(item.domainId, props.prop)}
          variant="contained"
          boxProps={{ mb: 2, display: 'flex' }}
          style={style}
          className={activeDomain === item.domainId ? classes.activeButton : classes.buttonStyle} >
          <Box display="flex" width="100%" color="inherit">
            <Box color="inherit" component="span" width="80%" m="auto" className={classes.domainListItem}>{item.fqdn} </Box>
            <Box onClick={() => {window.open(`https://${item.fqdn}`, '_blank');}} style={{ marginLeft: 'auto', alignSelf: 'center' }}><ExitToAppIcon/></Box>
          </Box>
        </Button>
      );
    }  
    return (
      <List
        height={250}
        width={window.innerWidth}
        itemCount={domainArray.length}
        itemSize={50}
      >
        {renderRow}
      </List>
    );
  }

  let totalRevenue = 0;
  const getReveue = () => {
    let sum = 0;

    for(let i=0; i<revenueHeader.length;i++){
      sum = sum + revenueHeader[i].adsRev + revenueHeader[i].subsRevUsd;
    }
    return(sum);
  }

  if(revenueHeader){
    totalRevenue = getReveue();
  }


  const showInsights = userPermissions.includes("EARN:READ_INSIGHTS_REVENUE");

  return (
    <>
      <AppBar position="sticky" className={classes.topBar}>
        <Paper square className={classes.paper} elevation={0}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pt={4}
            width="90%"
            m="auto" >
            <Avatar onClick={goToProfile}
             src={profilePicture}
              className={classes.bigAvator} classes ={{colorDefault : classes.avatorColor}}  >{createName()}</Avatar>
            <ActionSheet className={classes.websiteButton} buttonText={<>my website <ArrowDropDownIcon /></>} variant="contained"  >
            {
              (prop) => {
                return(
                <Box
                  display="flex"
                  justifyContent="space-between"
                  flexDirection="column"
                >
                <AppBar position="sticky" className={classes.domainHeading}>
                  <Box component="p" width="90%" m='auto' pt={3} pb={3} fontSize={16} >Select your Website</Box>
                </AppBar>
                <RenderList prop={prop}/>
                <Button
                onClick={goToAddDomain}
                className={classes.addDomainButton} 
                startIcon={<AddCircle/>}
                color="primary" 
                variant="contained"
                fullWidth>
                Add New Domain
              </Button>
                </Box>
                
              )}
            }
            </ActionSheet>
          {showInsights && <Button onClick={goToInsights}  color="secondary"  variant="contained"  > {`EARNINGS`}</Button>}
          </Box>
        </Paper>
      </AppBar>

      {children}
      
     { !isCreateDisable && <AppBar position="fixed"  className={classes.appBar}>
        <Toolbar disableGutters classes ={{root: classes.toolBarRoot}}>
          <FullScreenDialog 
          type="menubutton"
          color="primary"
          backButton={false}
          isMenu="true"
           buttonText="search"
            BarComponent={()=><Box component="p" 
            fontSize={20} color="white">Menu</Box>}
             >
               {(height)=> <SideBar height ={height}/>}
          </FullScreenDialog>
          <ActionSheet type="addfab" color="primary" className={classes.fabButton} >
            <Box
              display="flex"
              justifyContent="space-around"
              alignItems="center"
              flexDirection="column"
              margin="auto"
              width="90%"
              mt={4}
              mb={4}
            >
              {
                /**
                 * TODO :// All create stuff should be here for mobile
                 */
                <>
                  <Button
                    variant="contained" color="primary" className={classes.addButtonMobile} type="video/*" onClick={createVideoArticle}>
                    <VideocamIcon/>Video
                  </Button>
                  <Button
                    variant="contained" color="primary" className={classes.addButtonMobile} onClick={createImageArticle}>
                      <ImageIcon/>Image</Button>
                  <Button variant="contained" className={classes.addButtonMobile} onClick={()=>{history.push("/dash/create/podcast/new")}}>
                    <AudiotrackIcon/>Audio
                  </Button>
                  <Button variant="contained" className={classes.addButtonMobile} onClick={() => {history.push("/dash/create/article/new")}}>
                  <EditIcon/>Article</Button>
                  { userPermissions.indexOf('CREATE:PUBLISH') > -1                    
                    && featurePermissions.indexOf('LIVE_STREAM') > -1 && 
                      <Button
                      variant="contained" className={classes.addButtonMobile}
                      onClick={() => { history.push("/dash/live/schedule") }}>
                      <LiveTvIcon fontSize="small" />
                      Live
                    </Button>}
                </>
              }
            </Box>
          </ActionSheet>
        </Toolbar>
      </AppBar>
        }
    </>
  );
}

function mapDomainsToArray(domains) {
  const keys = Object.keys(domains);
  let domainArray = [];
  for (let i = 0; i < keys.length; i++) {
    domainArray.push(domains[keys[i]][0]);
  }
  domainArray.sort(domainComparator);
  //Using single domain to test. Comment this line out if it's not already.
  //domainArray = domainArray.filter(item => (item && item.subDomain === 'suraj'));
  return domainArray;
}

function domainComparator(a, b) {
  // Use toUpperCase() to ignore character casing
  const domainA = a.fqdn.toUpperCase();
  const domainB = b.fqdn.toUpperCase();

  let comparison = 0;
  if (domainA > domainB) {
    comparison = 1;
  } else if (domainA < domainB) {
    comparison = -1;
  }
  return comparison;
}
