import React, {useState, useEffect,useRef} from "react";
import Grid from "../../commons/muiCustomComponents/Grid";
import Button from "../../commons/muiCustomComponents/Button";
import Box from "../../commons/muiCustomComponents/Box";
import Input from "../../commons/muiCustomComponents/Input";
import DraftjsEditor from "./components/DraftjsEditor";
import { makeStyles } from '@material-ui/core/styles';
import ArrowForward from "@material-ui/icons/ArrowForward";
import Edit from "@material-ui/icons/Edit";
import TextArticleMetaHome from "./contentMetaHome";
import { useHistory,useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MobileProvider from "commons/context/MobileProvider";
import Header from "./components/Header";
import Footer  from "./components/TextFooter";
import setAppMessage from "app-component/app-component-action";
import { LinearLoader, CircularLoader } from "commons/commonWrapper/Loaders";
import { 
  fetchArticleContent, 
  fetchArticleMeta, 
  updateArticleContent,
  updateArticleMeta,
  publishArticle,
  sendVerificationLink,
  createNewArticle,
  clearCache,
  saveMediaToPisco,
  deleteArticle,
  sendResetPasswordMail,
} from "./content-action";
import TextArticlePreview from "./TextArticlePreview";
import TextArticleQuickShare from "./TextArticleQuickShare";
import CloseIcon from '@material-ui/icons/Close';
import Dialog from "commons/muiCustomComponents/Dailog";
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import DraftsIcon from '@material-ui/icons/Drafts';
import ThumbnailDialogs from './ThumbnailDialog';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputBox from "commons/muiCustomComponents/InputBox";
import UseInfo from "../../commons/CustomComponents/UseInfo";
import {hideIntercom} from "../../utils/utils";
import { LogglyTracker } from 'loggly-jslogger';

const logger = new LogglyTracker();
logger.push({ 'logglyKey': '142d21f5-10d3-47a9-aa5a-f83e0a3abed7', 'sendConsoleErrors' : true, 'tag' : 'ardbeg,content,text-home' });


const useStyles = makeStyles(theme => ({
  subheader: {
    width:'100%',
    height: theme.spacing(20),
    display:'flex',
    alignItems:'center',
    justifyContent:"center",
    borderBottom:'1px solid grey',
    backgroundColor:theme.palette.pubWhite,
  },
  mainContainer: {
   justifyContent:'center',
  },
  draftJs: {
    // backgroundColor:theme.palette.pubGreywhite,
  },
  mobileMargin: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    width:'90%',
  },
  wordCount: {
    textAlign:'right',
  },
  mobileTitle:{
    justifyContent:'center',
    display:'flex',
  },
  nextButton: {
    paddingTop:theme.spacing(4),
    paddingBottom:theme.spacing(4),
    width:theme.spacing(50),
    borderRadius:theme.spacing(8),
    margin:theme.spacing(2),
  },
  footerButtonMobile: {
    paddingTop:theme.spacing(2),
    paddingBottom:theme.spacing(2),
    borderRadius:theme.spacing(1),
  },
  mobileFooterContainer: {
    padding:theme.spacing(4),
  },
  mobileFooterItem: {
    paddingLeft:theme.spacing(2),
    paddingRight:theme.spacing(2),
  },
  backgroundFullScreen:{
    // backgroundColor:theme.palette.pubBackground,
  },
  inputRoot :{
    backgroundColor:theme.palette.pubWhite,
    "&:hover,&:focus":{
      backgroundColor:theme.palette.pubWhite,
    },
  },
}));


export default function TextHome (props) {

  // default hook values
  const classes = useStyles();
  const { articleId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = React.useContext(MobileProvider);
  const location = useLocation();

  const { landing:{activeDomain, userPermissions, userProfile}, header:{domainList}} = useSelector(state => state);
  const  domainInfo = domainList[activeDomain] && domainList[activeDomain][0];

  // Setting state data
  const [lastUpdate, setLastUpdate] = useState((new Date()).getTime());
  const [wordCount, setWordCount] = useState(0);
  const [isDirty, setIsDirty] = useState(false);
  const [subCategory, setSubcategory] = useState(null);
  const [metaData, setMetaData]  = useState({});
  const [showSelectDialog, setShowSelectDialog] = useState(true);
  const [content, setContent]  = useState({});
  const [showMetaDialog, setShowMetaDialog] = useState(false);
  const [showPreviewDialog, setShowPreviewDialog] = useState(false);
  const [showQuickShareDialog, setQuickShareDialog] = useState(false);
  const [ requesting, setRequesting ] = useState(true);
  const [ savingTitle, setSavingTitle ] = useState(false);
  const [savingMeta, setSavingMeta] = useState(false);
  const [savingArticle, setSavingArticle] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [openThumbnailDialog , setThumbnailDialog] = useState(false);
  const [thumbnail,setThumbnail] = useState(null);
  const [isDeleteArticle ,setDeleteArticle] = useState(false);
  const [sectionID, setSectionID] = useState(null);
  const [subSectionID, setSubSectionID] = useState(null);
  const [subsubsectionID, setSubSubSectionID] = useState(null);
  const [sectionDefaultvalue, setSectionDefaultVlaue] = useState(null);
  const [subSectionDefaultvalue, setSubSectionDefaultVlaue] = useState(null);
  const [subSubSectionDefaultvalue, setSubSubSectionDefaultVlaue] = useState(null);
  const [cashedContent ,setcashedContent] = useState(null);

  const childRef = useRef();
  
  useEffect(()=>{
    return hideIntercom();
  },[])

  useEffect(() => {
    if(articleId === 'new') {
      createArticle();
    } else {
      fetchArticleInfo();
    }
  },[articleId]);

  // update the article content at backend
  const updateHtmlContent = async (html, blockMap, count) => {
    try {
      setContent({ html, blockMap});
      await dispatch(updateArticleContent(articleId, html, blockMap)); 
    } catch (err) {
      // handle error
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      dispatch(setAppMessage({ message: `Unable to update Article content.`, header: 'Error', type: 'alert' }));
    }
  }

  // close the article flow and go to feeds
  const saveToDraft = async () => {

    try {
      setSavingTitle(true);
      const data = {
        headerType:'text/html',
        headerContent:'{}',
        title:metaData.title,
        summary:metaData.description,
        thumbnail : (childRef.current &&  childRef.current.getThumbnail()) || thumbnail,
        hlsURL:'',
      }
      await dispatch(updateArticleMeta(articleId, data));
      closeMetaDailog();
      closePreviewDialog();
      setSavingTitle(false);
      history.push('/dash/create');
    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      setSavingTitle(false);
      const { message } = err;
      dispatch(setAppMessage({ message: message || 'Unable to process article.', header: 'Error', type: 'alert' }));
      history.push('/dash/create');
    }
    
  }
  const dispatchResetMail = () => {
    dispatch(sendResetPasswordMail());
  } 
  // publish or update the article
  const publishOrUpdateArticle = async() => {
    try {
      setSavingArticle(true);
      setShowProgress(true);
      if(metaData.status === 4){
        await dispatch(clearCache(articleId));
      } else {
        await dispatch(publishArticle(articleId));
      }
      setTimeout(() => {
        setSavingArticle(false);
        closeProgressBar();
      }, 2000);
    } catch (err) {
      setShowProgress(false);
      closePreviewDialog(true);
      const { message, data:{code}} = err;
      if (code && code === 'ERR_NO_VERIFY') {
        dispatch(setAppMessage({ 
          message: message,
          leftButtonText: 'Cancel',
          rightButtonText: 'Verify email',
          handleSuccess: async () => {
            try {
              await dispatch(sendVerificationLink());
              dispatch(setAppMessage({ message: 'Verfication link sent to your mail.', header: 'Success', type: 'alert' }));
              history.push('/dash/create');
            } catch (err) {
              const data = {
                'error': err,
                'email': (userProfile||{}).email || '',
                'url': (window.location||{}).href || '',
                'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
                'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
              };
              logger.push({
                  'type': 'error',
                  'data': data,
                });
              dispatch(setAppMessage({ message: err.message|| 'unable to send verification link', header: 'Error', type: 'alert' }));
              history.push('/dash/create');
            }
          },
          header: 'Error' }));
      } else {
        dispatch(setAppMessage({ message: message, header: 'Error', type: 'alert' }));
        history.push('/dash/create');
      }
    }
  }

  // close the progress bar
  const closeProgressBar = () => {
    return setTimeout(() => {
      setShowProgress(false);
      if( (userPermissions.indexOf('SHARE:NEWSLETTER') > -1) || (userPermissions.indexOf('SHARE:SOCIAL_SCHEDULE') > -1)) {
        goToquickshare();
      } else {
        history.push("/dash/create");
      }
    }, 2000);
  }

  // fetch article content and meta data
  const fetchArticleInfo = async () => {
    try {
      const [{result:articleContent}, {result:metaData}] =  await Promise.all([
        dispatch(fetchArticleContent(articleId)),
        dispatch(fetchArticleMeta(articleId)),
      ]);
      if(metaData.headerType && metaData.headerType.indexOf('text') === -1) {
        dispatch(setAppMessage({ message: `Cannot be edited as Text Article.`, header: 'Error', type: 'alert' }));
        history.push("/dash/create");
        return;
      }
      metaData.description = metaData.summary ? metaData.summary :'';
      setMetaData(metaData);
      setThumbnail(metaData.thumbnail);
      setcashedContent(articleContent);
      setContent(articleContent);
      setRequesting(false);
    }catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      const { message } = err;
      dispatch(setAppMessage({ message: message || 'Unable to fetch article.', header: 'Error', type: 'alert' }));
      history.push('/dash/create');
    }
  }
  async function handleDeleteArticle(status) {
    try {
      setDeleteArticle(true)
      await dispatch(deleteArticle(articleId, metaData.status ||0 ));
      closeMetaDailog();
      closePreviewDialog(true);
      setDeleteArticle(false)
      history.push('/dash/create');
    } catch (err) {
      setDeleteArticle(false)
      console.log("err",err)
      dispatch(setAppMessage({ message: err.message || 'unable to delete article', header: 'Error', type: 'alert' }));
    }
  }


  // handle artile title metadata
  const handleTitle = (event) => {
    if (event.target && event.target.value.length <= 150){
      const meta = {...metaData, title:event.target.value};
      setMetaData(meta);
    }  
  }

  const saveDraftAtPreview = () => {
    closePreviewDialog();
    history.push('/dash/create');
  }
  const updateCoverImageSmall  = (coverPicture, coverPictureSmall, imgExtension) => {
    setMetaData({...metaData, coverPicture, coverPictureSmall, imgExtension});
  }
  // save the metadata changes and go to preview page
  const goToPreview = async (saveDraft) => {
    setSavingMeta(true);
    try {
      const { description, categoryId, paidContent} = metaData;
      const data = {
        description,
        categoryId,
        paidContent,
        yCategoryId : subCategory,
        headerType:'text/html',
        headerContent:'{}',
      }
      await dispatch(updateArticleMeta(articleId, data));
      if(saveDraft) {
        closeMetaDailog();
        closePreviewDialog();
        setSavingMeta(false);
        history.push('/dash/create');
      } else {
        setSavingMeta(false);
        setShowMetaDialog(false);
        setShowPreviewDialog(true);
        
      }
    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      const { message } = err;
      setSavingMeta(false);
      dispatch(setAppMessage({ message: message || 'Unable to process article.', header: 'Error', type: 'alert' }));
      //show error message
    }
  }

  const goToquickshare = () => {
    setShowPreviewDialog(false);
    setShowSelectDialog(false);
    setQuickShareDialog(true);
  }
  
  // if(location.hash === "#quick-share" && !showQuickShareDialog){
  //   goToquickshare();
  // }

  // create new article
  const createArticle = async () => {
    try {
      const {result: {metadata:{ articleId }}} = await dispatch(createNewArticle());
      dispatch(updateArticleMeta(articleId, {headerType:'text/html', title:''}));
      history.push(`/dash/create/article/${articleId}`);
    } catch (err) {
      const {message} = err;
      dispatch(setAppMessage({ message: message || 'Unable to process article.', header: 'Error', type: 'alert' }));
      history.push('/dash/create/');
    }
  }

  // update the metadata state value
  const handleMetaChange = (event) => {
    if(event.target.name === 'category') {
      setMetaData({...metaData, categoryId:event.target.value});
      setSubcategory (null);
    }
    if(event.target.name === 'subscriptionToggle') {
      setMetaData({...metaData, paidContent: Number(Boolean(event.target.value))});
    }
    if(event.target.name === 'description') {
      if (event.target && event.target.value.length <= 200){
        setMetaData({...metaData, description: event.target.value});
      }
      
    }
  }



  const handleMediaUpload = async(file, cb) => {
    const format = file.type.split('/')[0];
    if (format !== "image") return alert("Invalid File Format.");
    try {
      let res = await dispatch(saveMediaToPisco(file));
      const { localUploadResponse: { cdn } } = res;
      cb(null, cdn);
    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      const { message } = err;
      dispatch(setAppMessage({ message: message || 'Unable to uploa media.', header: 'Error', type: 'alert' }));
      cb(err);
    }
  }

  // save title and go to meta page
  const saveTitleAndOpenMeta = async() => {
    setIsDirty(true);
    if(!metaData.title) {
      return;
    }
    if(!metaData.description) {
      return;
    }
    setSavingTitle(true);
    try {
      const fthumbnail = (childRef.current &&  childRef.current.getThumbnail()) || thumbnail;
      setThumbnail(fthumbnail);
      // await dispatch(updateArticleMeta(articleId, {thumbnail :fthumbnail,headerType:'text/html',summary:metaData.description, title:metaData.title}));
      setShowMetaDialog(true);
      setSavingTitle(false);
    } catch (err) {
      // show error
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      const {  message } = err;
      dispatch(setAppMessage({ message: message || 'Unable to upload media.', header: 'Error', type: 'alert' }));
    } finally {
      setSavingTitle(false);
    }
  }

  const openThumbnailDialoghandler = ()=>{
    setThumbnailDialog(true);
  }

  // close the meta dialogue
  const closeMetaDailog = () => {
    setShowMetaDialog(false);
  }

  // close the preview dialouge
  const closePreviewDialog = () => {
    setShowPreviewDialog(false);
  }
  
  const closeQuickShareDialog = () => {
    setQuickShareDialog(false);
    history.push('/dash/create');
  }
  const handleyCatChange = (e)=>{
    setSubcategory(e.target.value);
  }
  const goToBack = ()=>{
    setShowSelectDialog(true);
    setShowMetaDialog(false);
    history.push(`/dash/create/article/${articleId}`);
  }
  const openPreview = ()=>{
    setShowPreviewDialog(true);
  }

  const deleteArticlePopup = ()=>{
    dispatch(setAppMessage({ message: '',
    leftButtonText:'Close', 
    rightButtonText:'Delete', 
    handleSuccess: handleDeleteArticle, header: 'Are you sure you want to delete Article  ?' }));      
  }

  const discardMedia = async()=>{
    if(articleId){
      try {
        setSavingTitle(true);
        await dispatch(updateArticleContent(articleId, cashedContent.html,JSON.parse(cashedContent.objectMap))); 
        setSavingTitle(false);
        closeMetaDailog();
        closePreviewDialog();
        history.push('/dash/create');
      } catch (err) {
        const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
        setSavingTitle(false);
        // handle error
        dispatch(setAppMessage({ message: `Unable to update Article content.`, header: 'Error', type: 'alert' }));
      }
    }
    else{
      closeMetaDailog();
      closePreviewDialog();
      history.push('/dash/create');
    }
  }

  return(
    <>
    <TextArticleQuickShare
      open={showQuickShareDialog}
      userPermissions={userPermissions}
      closeQuickShareDialog={closeQuickShareDialog}
      articleId={articleId}
    />
    <TextArticlePreview 
      open={showPreviewDialog}
      content={content}
      saveToDraft={saveDraftAtPreview}
      publishOrUpdateArticle={publishOrUpdateArticle}
      savingArticle={savingArticle}
      metaData={metaData} 
      showProgress={showProgress}
      closeDialog={closePreviewDialog}/>
    {showMetaDialog && <TextArticleMetaHome
      open={showMetaDialog}
      metadata ={metaData}
      domainInfo = {domainInfo}
      articleId= {articleId}
      title = {metaData.title}
      summary = {metaData.description}
      openPreview ={openPreview}
      thumbnail = {thumbnail}
      headerType= {'text/html'}
      goToBack={goToBack}
      deleteLoader = {isDeleteArticle}
      handleDeleteArticle={handleDeleteArticle}

      sectionID = {sectionID}
      setSectionID = {setSectionID}
      subSectionID ={subSectionID}
      setSubSectionID = {setSubSectionID}
      subsubsectionID = {subsubsectionID}
      setSubSubSectionID = {setSubSubSectionID}
      sectionDefaultvalue = {sectionDefaultvalue}
      setSectionDefaultVlaue = {setSectionDefaultVlaue}
      subSectionDefaultvalue = {subSectionDefaultvalue}
      setSubSectionDefaultVlaue = {setSubSectionDefaultVlaue}
      subSubSectionDefaultvalue = {subSubSectionDefaultvalue}
      setSubSubSectionDefaultVlaue = {setSubSubSectionDefaultVlaue}
      />
    }
     <>

     <Dialog fullScreen open={showSelectDialog}  classes={{paper:classes.backgroundFullScreen}}>
       

      {/**--------------------------Mobile Header Section------------------------------*/}
      {<Header>
        <Grid container justify="center">
                {<Grid item xs={12} md={12}>
                    <Box display="flex" alignItems="center" justifyContent="space-between" ml={isMobile ?4:25} mr={isMobile ?4:25} >
                      <Box display="flex" alignItems="center" height="100%">
                          <CloseIcon style={{color:'white'}} onClick={discardMedia}/>
                      </Box>
                      <Box component="p" display="flex" justifyContent="center">ARTICLES</Box>
                      <Box  component="p" >Step 1 of 2</Box>
                      </Box>
                  </Grid>
                }
              </Grid>
      </Header>}
    {/**-----------------------------End of Mobile Header Section-------------------------------*/}

    {/**------------------------------Body Section---------------------------------*/}
    {(savingTitle || requesting) && <LinearLoader position="sticky" top={`${isMobile ? '70px': '75px'}`} width="100%"/>}
    <Grid container>
      <Grid container className={classes.mainContainer}>
      {!requesting && <>

      <Grid item xs={12} md={8}>
        {/* <Box display="flex" justifyContent="space-between">
        <Box mb={4} mt={4}>Last saved at {getLastUpdate(lastUpdate)}</Box>
        <Box mb={4} mt={4} className={classes.wordCount}>{wordCount} words</Box>
        </Box> */}
      </Grid>
      
      <Grid item xs={11} sm={10} md={8}>
        <Box mt={6}>
          <InputBox 
            id="texttitle"
            error={isDirty &&metaData.title !== undefined&& metaData.title !== null&& !metaData.title.length}           
            helperText={(isDirty && metaData.title !== undefined&& metaData.title !== null&& !metaData.title.length) && "Title cannot be empty."}
            onChange={handleTitle} 
            heading="Title*"
            variant="standard"
            value={metaData.title || ''} 
            placeholder="Enter Title Here"
            className={{root:classes.inputRoot}}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {`${ (metaData && metaData.title &&metaData.title.length ) || 0}/150`}
                </InputAdornment>
              ),
            }}
            headingStyle={{
              component:"span",
              fontWeight:"bold",
              fontSize:14,
              mb: 1,
            }}
            fullWidth/></Box>
            <Box mt={5}>
          <InputBox 
            id="textdescription"
            heading="Summary*"
            variant="standard"
            error={isDirty && metaData && metaData.description !== undefined&& metaData.description !== null &&!metaData.description.length}    
            helperText={(isDirty && metaData.description
               !== undefined && metaData.description !== null &&  !metaData.description.length) && "Summary cannot be empty."}
            value={(metaData && metaData.description) || ''}
            onChange={handleMetaChange}       
            name="description"
            multiline
            rowsMax={4}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {`${ (metaData && metaData.description &&metaData.description.length ) || 0}/200`}
                </InputAdornment>
              ),
            }}
            headingStyle={{
              component:"span",
              fontWeight:"bold",
              fontSize:14,
              mb: 1,
            }}
            // helperText={(isDirty && !metaData.title.length) && "Summary cannot be empty."}
            // label={`Summary (${(metaData.description && metaData.description.length)||0} characters)`} 
            placeholder="Give a short summary about the article."
            className={{root:classes.inputRoot}}
            fullWidth/></Box>
      </Grid>
      <Grid item xs={11} sm={10} md={8}>
        <Box mt={5}  className={classes.draftJs}>
        
        <DraftjsEditor
          objectMap={content.objectMap}
          htmlContent={content.html}
          updateWordCount={(count) => setWordCount(count)}
          lastUpdate={lastUpdate}
          isMobile = {isMobile}
          wordCount = {wordCount}
          handleMediaUpload={handleMediaUpload}
          updateApi={updateHtmlContent}/>
          {/* <Box height={90} width="100%"></Box> */}
        </Box>
      </Grid>
      </>}
      </Grid>
      <Box height={isMobile?125:100} width="100%"></Box>
    </Grid>
    {/**--------------------------------End Body Section------------------------------------*/}
    <ThumbnailDialogs  thumbnail = {thumbnail} ref={childRef} open={openThumbnailDialog} setThumbnailDialog={setThumbnailDialog}/>

    {/**--------------------------------Footer Section -------------------------------------*/}
    <Grid item xs={12} md={12}>
      
      <Footer bgColor="white">
      <Grid container justify="center" className={isMobile ? classes.mobileMargin : {}}>
          <Grid item md={8} xs={12}>
            <Box display="flex" justifyContent="space-between" width="100%">
              <Box mb={4} mt={4}>Last saved at {getLastUpdate(lastUpdate)}</Box>
               {childRef &&childRef.current && childRef.current.getFileName()
                &&<Box mb={4} mt={4} overFlow="hidden">{childRef.current &&
                   childRef.current.getFileName()} selected</Box>
              }
              {childRef &&childRef.current && !childRef.current.getFileName()
                &&
                 <Box mb={4} mt={4}>
                   jpg, png ,bmp only
                 </Box>          
              }
            </Box> 
          </Grid>
                <Grid item md={8} xs={12}>
                  <Box display="flex" flexWrap="wrap-reverse" justifyContent={isMobile ?"flex-end":metaData.status === 4 ?"flex-end":"space-between"} mb={5} >
                   { metaData.status !== 4 &&
                    <Box display="flex" mt={isMobile?2:0}>
                    <Button 
                    color="secondary"
                    variant="contained" 
                    disabled={savingTitle  ||  requesting || isDeleteArticle}
                    onClick={saveToDraft}>
                       {isMobile ? <DraftsIcon/> :'Save Draft' }
                      </Button>
                    <Box display="flex">
                    <Button variant="contained" disabled={requesting || isDeleteArticle} boxProps={{ml:3}} color="secondary" onClick={deleteArticlePopup}>
                      <DeleteIcon/> 
                    </Button>
                      {isDeleteArticle && <CircularLoader size={20} height="auto"/>}
                    </Box>
                    
                  </Box>
                }
                  <Box display="flex">
                    <Button variant="contained"  color="secondary" onClick={openThumbnailDialoghandler}>
                      {thumbnail || (childRef &&childRef.current && childRef.current.getThumbnail()) ?'UPDATE THUMBNAIL ':'SET THUMBNAIL '} 
                    </Button>
                    <UseInfo info=" Select an image to be cropped. Recommended size 800x420" />

                  <Button 
                  variant="contained" 
                  disabled={savingTitle ||  requesting||isDeleteArticle}
                  boxProps={{ml:4}}
                  onClick={saveTitleAndOpenMeta} 
                  >Next</Button>
                  </Box>
                  </Box>
                </Grid>
              </Grid>              
      </Footer>
    </Grid>
    </Dialog>
    </>
    </>
  )
}

function getLastUpdate(time) {
  let hh = (new Date(time)).getHours();
  const ll = hh >= 12 ? 'PM': 'AM';
  hh = hh > 12 ? hh-12 : hh;
  const min = (new Date(time)).getMinutes();
  return `${hh}:${min} ${ll}`
}
