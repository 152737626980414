import React, {useEffect, useState, useContext} from "react";
import { useDispatch, useSelector } from "react-redux";
import { 
  setNewPassword,
 } from "./auth-action";
import { useHistory, useParams } from "react-router-dom";
import Box from "../../commons/muiCustomComponents/Box";
import { makeStyles } from '@material-ui/core/styles';
import Input from "../../commons/muiCustomComponents/Input";
import Button from "../../commons/muiCustomComponents/Button";
import Divider from '@material-ui/core/Divider';
import MobileProvider from "commons/context/MobileProvider";
import { LinearLoader } from "commons/commonWrapper/Loaders";
import setAppMessage from "app-component/app-component-action";
import { LogglyTracker } from 'loggly-jslogger';

const logger = new LogglyTracker();
logger.push({ 'logglyKey': '142d21f5-10d3-47a9-aa5a-f83e0a3abed7', 'sendConsoleErrors' : true, 'tag' : 'ardbeg,auth,reset-password' });

const useStyles = makeStyles(theme => ({
  headerBarMobile: {
    width:'100%',
    height:theme.spacing(20),
    backgroundColor:theme.palette.pubBlack,
    color:theme.palette.pubWhite,
  },
  headerBarDesktop : {
    width:'100%',
    height:theme.spacing(20),
    borderBottom: `1px solid ${theme.palette.pubbrownGrey}`,
    textAlign:'left',
  },
  headingTextDesktop: {
    fontSize:theme.spacing(6),
    paddingLeft:theme.spacing(8),
  },
  headingTextMobile: {
    fontSize:theme.spacing(9),
    letterSpacing:theme.spacing(2),
  },
  containerBox: {
    maxWidth:'400px',
    margin:"auto",
    height:"80vh",
  },
  signup: {
    position:"relative",
    top:'10%',
  },
  forgotPassword: {
    cursor:"pointer",
  },
}));

function LoginHome (props) {

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useContext(MobileProvider);
  const { tokenId } = useParams();
  const [isDirty, setIsDirty] = useState(false);
  const [confirmPassword, setConfirmPassowrd]  = useState('');
  const [password, setPassword] = useState('');
  const [requesting, setRequesting] = useState(false);
  const {landing: {userProfile}} = useSelector(state => state);

  useEffect(() => {
  },[]);

  // handle email and password login 
  const  handleLogin = async () => {
    
    setIsDirty(true);
    if(!password.length || !confirmPassword.length || (confirmPassword !== password) || password.length < 6) {
      return;
    }
    try {
      setRequesting(true);
      await dispatch(setNewPassword(password, tokenId));
      localStorage.removeItem('accessToken');
      setRequesting(false);
      history.push("/");
    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      setRequesting(false);
      dispatch(setAppMessage({message:err.message || 'Unable to reset password', header:'Error', type:'alert'}));
    }
  }

  //handle the input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if(name === 'confirm-password') setConfirmPassowrd(value);
    if(name === 'password') setPassword(value);
  }
  const isErrorConfirmPassword = () => {
    if(isDirty && confirmPassword.length===0) {
      return true
    }
    if(isDirty && confirmPassword !== password) {
      return true;
    }
  }
  const confirmPasswordErrorMessage = () => {
    if(isDirty && confirmPassword.length===0) {
      return 'Confirm password cannot be empty'
    }
    if(isDirty && confirmPassword !== password) {
      return 'Password and confirm password do not match';
    }
    return '';
  }

  return (
    <>
      {renderHeader({classes, isMobile})}
      {requesting && <LinearLoader/>}
      <Box className={classes.containerBox}>
        <Box ml={6} mr={6} mt={40}>
          <Input 
            label="Password" 
            id="password" 
            error={isDirty && (password.length===0 || password.length < 6)}
            type="password" 
            helperText={(isDirty && (password.length === 0 ? "password cannot be empty": password.length < 6 ? 'minimum length is 6': '' ))}
            name="password"
            onChange={handleInputChange} 
            fullWidth 
            value={password}/>
        </Box>
        <Box ml={6} mr={6} mt={6}>
          <Input 
            label="Confirm Password" 
            error={isErrorConfirmPassword()}
            id="confirm-password"  
            type="password"
            helperText={confirmPasswordErrorMessage()}
            name="confirm-password"
            onChange={handleInputChange} 
            fullWidth 
            value={confirmPassword}/>
        </Box>
        <Box ml={6} mr={6} mt={6}>
          <Button fullWidth size="large" disabled={requesting} onClick={handleLogin} variant="contained">Set new password</Button>
        </Box>
        <Box  mt={4} ml={4} mr={4} mb={6}>
        </Box>
      </Box>
      
    </>
  )
}

export default LoginHome;


function renderHeader(props) {
  const { isMobile, classes } = props;
  if(isMobile) {
    return (<Box display="flex" alignItems="center" justifyContent="center" className={classes.headerBarMobile}>
      <Box component="p" className={classes.headingTextMobile}>PUBNINJA</Box>
    </Box>)
  }
  return (
    <Box display="flex" alignItems="center" className={classes.headerBarDesktop}>
      <Box component="p" className={classes.headingTextDesktop}>Set a new password</Box>
    </Box>
  )
}