import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Box from "commons/muiCustomComponents/Box";
import { getAllProducts, deleteProductById,unpublishProductById ,publishProductById,
} from "./monetization-action";
import { useDispatch, useSelector } from "react-redux";
import Button from "commons/muiCustomComponents/Button";
import { CircularLoader, LinearLoader, PageBlockLoader } from 'commons/commonWrapper/Loaders';
import moment from "moment"
import { useHistory } from "react-router-dom";
import AddIcon from '@material-ui/icons/Add';
import MoreIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from "commons/muiCustomComponents/MenuItem";
import setAppMessage from "app-component/app-component-action";



function Gettoggle(disableProduct, row) {
  const [status, setStatus] = useState(row.status);
  return <Button type="toggle"
    checked={status}
    onChange={(e) => setStatus(e.target.value)}
    onClick={() => disableProduct(row)} />
}

const useStyles = makeStyles((theme)=> ({
  table: {
    minWidth: 650,
  },
  textdecoration: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  heighPowerFont: {
    fontWeight: 600,
  },
  flexClasses: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  productBackground :{
    backgroundColor:theme.palette.secondary.light,
    border:'none',
    "&:hover,&:focus": {
      backgroundColor:theme.palette.secondary.light,
      border:'none',
    },
  },
}));


export default function SimpleTable() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [rows, setRows] = React.useState([]);
  const [fetchingProduct, setFetchingProduct] = useState(false);
  const { landing: { activeDomain, userProfile } } = useSelector((state) => state);
  const [isLoading, setLoading] = useState(false);
  const [activeprodcutId, setActiveproductId] = useState(null);
  const [open ,setOpen] = useState(false);
  const [anchorEl ,setAnchorEl] = useState(null);
  const [prodcutEntity,setRow] = useState(null);
  const {
    header: {
      domainList,
    },
  } = useSelector(state =>  state);

  useEffect(() => {
    const getProducts = async () => {
      try {
        setFetchingProduct(true)
        const data = await dispatch(getAllProducts());
        setRows(data);
        setFetchingProduct(false);
      }
      catch (err) {
        setFetchingProduct(false);
      }
    }
    getProducts();
  }, [activeDomain]);

  const handleClose = ()=>{
    setOpen(false);
    setAnchorEl(null);
    setRow(null)
  }

  const handleOpen = (e,val)=>{
    setOpen(true);
    setRow(val)
    setAnchorEl(e.currentTarget);
  }
  const disableProduct = async (row) => {
    try {
      setLoading(true);
      setActiveproductId(row.id)
      await dispatch(deleteProductById(
        {
          product_id: row.id,
        },
      ));
      setActiveproductId(null)
      setLoading(false)
    }
    catch (err) {
      setLoading(false)
    }

  }

  const goToEditPage = (row) => {
    history.push(`/dash/subscripition/edit-product/${row.id}`);
  }
  const manageProd = () => {
    history.push('/dash/subscripition/manage-product');
  }

  if (fetchingProduct) {
    return <Box pt={4} pb={4} display="flex" justifyContent="center" width="100%">
      <CircularLoader height="auto" message="Fetching products..." />
    </Box>
  }
  if (rows.length === 0) {
    return <Box pt={4} pb={4} className={classes.container}>
      <Box ml={4} mr={4} mb={4}>
      <Box component="span" >
      Product offerings are at the heart of subscriptions. 
      Your audience will subscribe to the products that you offer based on their requirements. We recommend creating up to 3 value-added products when starting
    </Box>
      </Box>
      
      <Button variant="outlined" className={classes.productBackground} onClick={manageProd} >
        <AddIcon fontSize="small" />
        Add Product 
        </Button>
    </Box>
  }

  const unpublishAction = async ()=>{
    try {
      setLoading(true)
      setOpen(true)
      await dispatch(unpublishProductById(prodcutEntity.id));
      
      const finalRow = rows.map((val)=>{if (val.id ===prodcutEntity.id){
        val.status = 0;
      }
      return val;
     })
      setRow(finalRow);
      setLoading(false)
      handleClose();
    }
    catch(err){
      setLoading(false)
      console.log("err",err)
    }
    
  }
  const publishAction = async ()=>{
    try {
      setLoading(true)
      setOpen(true)
      await dispatch(publishProductById(prodcutEntity.id));
      const finalRow = rows.map((val)=>{if (val.id ===prodcutEntity.id){
        val.status = 1;
      }
      return val;
     });
      setRow(finalRow);
      setLoading(false)
      handleClose();
    }
    catch(err){
      setLoading(false)
      console.log("err",err)
    }
    
  }

  const showUnPublish = ()=>{
      setOpen(false)
      // eslint-disable-next-line max-len
      dispatch(setAppMessage({ message: `${prodcutEntity.subscriberCount===0 ?'This product has no active subscribers. New users will not be able to subscribe. Do you want to continue?':
      // eslint-disable-next-line max-len
      'This product has active subscribers. It will continue subscriptions for all active subscribers. New users will not be able to subscribe. Do you want to continue?'}`,
            header: 'UNPUBLISH PRODUCT', leftButtonText :'close' , rightButtonText :'unpublish',
            handleFailure : handleClose,
            handleSuccess:unpublishAction,
    }));
  }
  const showpublish = ()=>{
    setOpen(false)
      dispatch(setAppMessage({ message: 'You are about to publish this product. Your audience will be able to subscribe. Do you want to continue?',
            header: 'PUBLISH PRODUCT', leftButtonText :'close' , rightButtonText :'publish',
            handleFailure : handleClose,
            handleSuccess:publishAction,
    }));
  }

  const goToPreview =()=>{
    const fqdn = domainList && domainList[activeDomain] && domainList[activeDomain][0].fqdn;
    window.open(`https://${fqdn}/product/${prodcutEntity.id}`, '_blank')
  }
  
  return (
    <>
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.heighPowerFont}>PRODUCT NAME</TableCell>
            <TableCell  className={classes.heighPowerFont}>PLAN</TableCell>
            {/* <TableCell  className={classes.heighPowerFont}>SUBSCRIBERS</TableCell> */}
            <TableCell  className={classes.heighPowerFont}>DATE</TableCell>
            <TableCell  className={classes.heighPowerFont}>STATUS</TableCell>
            <TableCell className={classes.heighPowerFont}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

          {
            rows.map(row => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell >
                  {row.plans && row.plans.map((plan, key) => <>{plan.type ==="ONETIME" ?
                   <Box key={key}>{`${plan.currencySymbol} ${plan.price} (${plan.subscriberCount} subscribers)`}</Box>
                    :
                   <Box key={key}>{`${plan.currencySymbol} ${plan.price} / ${plan.interval} (${plan.subscriberCount} subscribers)`}</Box>}</>)}
                </TableCell>
                
                <TableCell >{moment(row.productUpdated).format("DD MMM YYYY")}</TableCell>
                <TableCell >{row.status===1 ?"Published":"Unpublished"}</TableCell>
                <TableCell  >                   
                <Box display="flex" justifyContent="flex-end">
                  <IconButton color="primary" onClick={(e)=>handleOpen(e,row)}>
                    <MoreIcon aria-controls="simple-menu" aria-haspopup="true" />
                  </IconButton>
                </Box>
    
                  {/* {Gettoggle(disableProduct,row)} */}
                  {/* <Button type="toggle" onClick={() => disableProduct(row)} />
                  {isLoading && activeprodcutId === row.id && <CircularLoader height="auto" size={20} />} */}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
    <Menu id="postmenu" open={open} anchorEl={anchorEl} style={{ zIndex: '1500' }} keepMounted onClose={handleClose}>
      <MenuList autoFocus >
        {prodcutEntity && prodcutEntity.status===1 &&<MenuItem onClick={()=>goToEditPage(prodcutEntity)} >Edit </MenuItem> }
        {prodcutEntity && prodcutEntity.status===1 &&<MenuItem onClick={goToPreview} >Preview </MenuItem>}
        <MenuItem onClick={prodcutEntity&&prodcutEntity.status===1 ? 
          showUnPublish:showpublish}>{prodcutEntity&&
          prodcutEntity.status===1 ?'Unpublish':'publish'} {isLoading && <CircularLoader height="auto" size={20} ml={3}/>}</MenuItem>
      </MenuList>
    </Menu>
    </>
  );
}