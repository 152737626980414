import { createStore, applyMiddleware } from 'redux';
import AppReducer from "./app-reducer";
import thunk from 'redux-thunk';
import api from "./utils/apiClient";
import userApi from "./utils/userApiClient";
import apiRoutes from "./utils/apiRoutes";
import actionTypes from "./constants/actions";
import { composeWithDevTools } from 'redux-devtools-extension';

let composeEnhancers = composeWithDevTools({});

/**
 * pass api handler, user api handler, api routes and action types with thunk
 */
const middlewares = [thunk.withExtraArgument({api, userApi, apiRoutes, actionTypes})];

let store = createStore(AppReducer, applyMiddleware(...middlewares));
if (process.env.NODE_ENV === "development") 
  store = store = createStore(AppReducer,composeEnhancers( applyMiddleware(...middlewares)));


/**
 * This is the redux of the store that will contain the 
 * complete app state and connect all the actions to the reducers
 */
export default store;