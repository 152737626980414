import setAppMessage from "../../app-component/app-component-action";


// export function getSubscriptionCurreny () {
//   return async (dispatch, getState, apiHandler) => {
//     try {

//     } catch (err)
//   }
// }

export function getInsightsRevenueAction(startDate, endDate) {
  return async (dispatch, getState, apiHandler) => {
    try {
      const { api, apiRoutes } = apiHandler;
      const activeDomain = getState().landing.activeDomain;
      const { data } = await api(apiRoutes.fetchInsightsRevenue(activeDomain, startDate, endDate));
      return Promise.resolve(data);
    } catch (err) {
      dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}));
      return Promise.reject(err);
    }      
  }
}

export function getInsightsViewsAction(startDate, endDate) {
  return async (dispatch, getState, apiHandler) => {
    try {
      const { api, apiRoutes } = apiHandler;
      const activeDomain = getState().landing.activeDomain;
      const { data: {result} } = await api(apiRoutes.fetchInsightsViews(activeDomain,startDate, endDate));
      return Promise.resolve(result);
    } catch (err) {
      dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}));
      return Promise.reject(err);
    }      
  }
}

export function getInsightsRevenueActionForHeader(startDate, endDate) {
  return async (dispatch, getState, apiHandler) => {
    try {
      const { api, apiRoutes, actionTypes } = apiHandler;
      const activeDomain = getState().landing.activeDomain;
      const { data } = await api(apiRoutes.fetchInsightsRevenue(activeDomain, startDate, endDate));
      dispatch(revenueType(actionTypes.INSIGHTSREVENUEFORHEADER.SUCCESS, data));
    } catch (err) {
      dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}));
      return Promise.reject(err);
    }      
  }
}

export function getSubcriptionTransactionAction(startDate, endDate) {
  return async (dispatch, getState, apiHandler) => {
    try {
      const { api, apiRoutes, actionTypes } = apiHandler;
      const activeDomain = getState().landing.activeDomain;
      const { data } = await api(apiRoutes.fetchSubscriptionTransaction(activeDomain, startDate, endDate));
      return Promise.resolve(data);
    } catch (err) {
      dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}));
      return Promise.reject(err);
    }
  }
}

export function getCurrenyFromSubscrptions() {
  return async (dispatch, getState, apiHandler) => {
    try {
      const {  api, apiRoutes } = apiHandler;
      const activeDomain = getState().landing.activeDomain;
      const { data:{result} } = await api(apiRoutes.getSubscriptionDetail(activeDomain));
      dispatch(setCurrencyData(result));
      return Promise.resolve(result);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export function setInsightsData(data) {
  return {
    type:'SET_DEFAULT_INSIGHTS',
    data,
  }
}

export function setEarningsData(data, chartName) {
  return {
    type:'SET_EARNINGS_INSIGHTS',
    chartName,
    data,
  }
}

export function setTrafficData(data, chartName) {
  return {
    type: 'SET_TRAFFIC_INSIGHTS',
    data,
    chartName,
  }
}

export function setPageViews(data) {
  return {
    type:'SET_PAGE_VIEW',
    data,
  }
}

export function setCurrencyData(data) {
  return {
    type:'SET_CURRENCY_DATA',
    data,
  }
}

function revenueType(actionName, data){
  return{
    type: actionName,
    data: data,
  }
}

export function setSubscription(data) {
  return {
    type:'SET_SUBSCRIPTION_TRANSACTION',
    data,
  }
}

function subscriptionTransactionType(actionName, data){
  return{
    type: actionName,
    data: data,
  }
}