import React from "react";
import ButtonGroup from "@material-ui/core/ButtonGroup";


function CustomButtonGroup (props) {
  return (
    <ButtonGroup {...props}></ButtonGroup>
  )
}

export default CustomButtonGroup;