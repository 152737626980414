import React from 'react';
import Box from "../../../commons/muiCustomComponents/Box";
import { makeStyles } from '@material-ui/core/styles';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { CircularLoader } from '../../../commons/commonWrapper/Loaders';


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.pubPaleGrey,
    position:'relative',
  },
  child :{
      backgroundColor:theme.palette.pubVeryLightPink,
      margin:theme.spacing(16),
  },
  iconWidth :{
    width:'100%',
    height:'100%',
  },
  iconPosition :{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

export default function Heart({image,isLoadingFav,isLoadingLogo}) {
  const classes = useStyles();
  return (
    <Box className={classes.root} display="flex" justifyContent="center" alignItems="center">
      
      {
        image ? <Box width={200} height={200}>
          <img src={image} alt="img" width="100%" height="100%" style={{objectFit:'contain'}}/>
          {(isLoadingFav || isLoadingLogo ) &&  <CircularLoader mb={100} height="auto" className={classes.iconPosition}/> }
        </Box>

        : <Box className={classes.child} >
        <FavoriteIcon className={classes.iconWidth}/>
        {(isLoadingFav || isLoadingLogo ) && <CircularLoader height="auto" className={classes.iconPosition}/> }
      </Box>
      }
      
    </Box>
  );
}