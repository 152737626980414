
import React ,{useCallback,useContext} from "react";
import CustomersHome from "components/customers/CustomersHome";
import FullScreenPage from '../commons/commonWrapper/FullScreenPage';
import Box from "../commons/muiCustomComponents/Box";
import MobileProvider from "commons/context/MobileProvider";


export default function SubscripitionPage() {
  const isMobile = useContext(MobileProvider);

  const barCallback = useCallback(() =>(<Box 
    display="flex"
    alignItems="center"
    component="span"
    >Customers</Box>),[]);

  return (
     <FullScreenPage BarComponent={barCallback} isDesktop={false} isCloseIcon={true} >
       <Box mt={isMobile ?2:6} ml={isMobile ?2:0} mr={isMobile ?2:0}><CustomersHome /> </Box>
    </FullScreenPage >
  )
}
