import React, { useEffect, useState,useContext } from 'react';
import Box from "../../commons/muiCustomComponents/Box";
import InputBox from "../../commons/muiCustomComponents/InputBox";
import DraftjsEditor from "../../components/content/components/DraftjsEditor";
import Button from "../../commons/muiCustomComponents/Button";
import { getCurrentPageDetail, updatePageDetails, savePageMediaToPisco ,publishPage} from "./settings-action";
import { useParams,useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CircularLoader,LinearLoader, PageBlockLoader } from '../../commons/commonWrapper/Loaders';
import Grid from "../../commons/muiCustomComponents/Grid";
import Paper from "commons/muiCustomComponents/Paper";
import MobileProvider from "commons/context/MobileProvider";

export default function PageContent() {
  const { pageId ,state} = useParams();
  const dispatch = useDispatch();
  const  history = useHistory();
  const isMobile = useContext(MobileProvider);
  const [title,setTitle] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [isPublishing,setPublishing] = useState(false);
  const [currentPageData, setCurrentPage] = useState({});
  
  useEffect(() => {
    const getEditPages = async () => {
      try {
        setLoading(true)
        const data = await dispatch(getCurrentPageDetail(pageId));
        setTitle(data.title)
        setCurrentPage({ ...data });
        setLoading(false)
      }
      catch (err) {
        setLoading(false)
      }

    }
    getEditPages();
  }, [pageId]);

  const updatePage = (html, objectMap) => {
    if (state==='default'){
      dispatch(updatePageDetails(pageId, { html, objectMap: JSON.stringify(objectMap)}))
      return;
    }
    dispatch(updatePageDetails(pageId, { html, objectMap: JSON.stringify(objectMap),title }))
  }

  const handleMediaUpload = async (file, cb) => {
    const format = file.type.split('/')[0];
    if (format !== "image") return alert("Invalid File Format.");
    try {
      let res = await dispatch(savePageMediaToPisco(file));
      const { localUploadResponse: { cdn } } = res;
      cb(null, cdn);
    } catch (err) {
      cb(err);
    }
  }

  const onChangePageTitle = (e)=>{
    const value  = e.currentTarget.value
    // if (!(state==='default')){
    //   dispatch(updatePageDetails(pageId, { title :value }))
    // }
    setTitle(value)
  }
  const backToPages = ()=>{
    history.push("/dash/setting/website/pages")
  }
  const publishCurrentPage = async ()=>{
    try {
      setPublishing(true)
      await dispatch(publishPage(pageId,backToPages))
      setPublishing(false)
      
    }
    catch(err){
      setPublishing(false)
    }
    
  }

  if (isLoading) {
    return  <Grid container>
    <Grid item xs={12} md={9} xl={10}>
     {isMobile && isLoading && <LinearLoader/>}
      {!isMobile && isLoading && <PageBlockLoader/>}
    </Grid>
    </Grid>
  }

  return (
    <Grid container>
    <Grid item xs={12} md={9} xl={10}>
      {isMobile && isLoading && <LinearLoader/>}
      {!isMobile && isLoading && <PageBlockLoader/>}
        <Box m ={isMobile ?2 : 0} mt={4} mb={4} >
          <Paper>
    <Grid container><Grid item xs={12} md={12}>
    <Box >
      <Box>
        <InputBox
          heading="Page Title"
          fullWidth
          color="secondary"
          value={title}
          placeholder="Write here..."
          onChange = {onChangePageTitle}
          disabled = {state==='default'}
          headingStyle={{
            fontSize: 14,
            color: "pubGrey",
            fontWeight: 500,
            mb: 1,
          }}
        />
      </Box>
      <Box mt={3}>
        <DraftjsEditor
          objectMap={currentPageData.objectMap}
          htmlContent={currentPageData.html}
          lastUpdate={''}
          handleMediaUpload={handleMediaUpload}
          updateApi={updatePage}
          DraftPlaceholder ="Write here ..."
        />
      </Box>
      <Box mb={2} display="flex">
      <Button fullWidth
       onClick ={publishCurrentPage}
       disabled={isPublishing}
       variant="contained">Publish and Go back</Button>
       {
         isPublishing &&  <CircularLoader height="auto" size={20}/>
       }
       
      </Box>
    </Box>
    </Grid>
    </Grid>
    </Paper>
    </Box>
      </Grid></Grid>
  );
}