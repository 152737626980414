import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Dialog from "commons/muiCustomComponents/Dailog";
import {useHistory, useParams} from "react-router-dom";
import Grid from "commons/muiCustomComponents/Grid";
import Box from "commons/muiCustomComponents/Box";
import Button from "commons/muiCustomComponents/Button";
import {makeStyles} from '@material-ui/core/styles';
import Input from "commons/muiCustomComponents/Input";
import MobileProvider from "commons/context/MobileProvider";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {CircularLoader, LinearLoader} from "commons/commonWrapper/Loaders";
import EditIcon from "@material-ui/icons/Edit";
import {
  changeCoverPicture,
  clearCache, createNewArticle,
  createSection, fetchArticleMeta,
  getProductsDetails,
  publishArticle,
  sendVerificationLink,
  saveMediaToPisco,
  updateArticleMeta,
  updateSection,
} from "../content-action";
import setAppMessage from "app-component/app-component-action";
import ImageEditor from "../components/ImageEditor";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from "moment";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import kebabCase from "lodash.kebabcase";
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CloseIcon from "@material-ui/icons/Close";
import CropImageDialog from "../CroppingDialog";
import ImageIcon from "@material-ui/icons/Image";
import WithFileUpload from "commons/hocs/with-file-upload";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputBox from "../../../commons/muiCustomComponents/InputBox";
import {fetchRawFeed} from "../../articles/article-action";
import {hideIntercom} from "../../../utils/utils";
import { LogglyTracker } from 'loggly-jslogger';

const logger = new LogglyTracker();
logger.push({ 'logglyKey': '142d21f5-10d3-47a9-aa5a-f83e0a3abed7', 'sendConsoleErrors' : true, 'tag' : 'ardbeg,content,live-meta-collection' });


const videoDefaultImage = 'https://cdn.pubninja.com/assets/images/video-01.png';

const uploadImage = () => {
  return (
    <Button
      variant="contained"
      color="secondary"
      startIcon={<ImageIcon />}
    >
      Upload Image
    </Button>
  )
};
const FileUploaderButton = WithFileUpload(uploadImage);

const useStyles = makeStyles(theme => ({
  addNewCat: {
    textAlign: "center",
    margin: 'auto',
  },
  titleArea: {
    background: theme.palette.pubPaleGrey,
    color: theme.palette.primary.main,
    fontSize: theme.spacing(6),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  subscriptionBox: {
    width: theme.isMobile ? '100%' : '20%',
  },
  mobileTitle: {
    justifyContent: 'center',
    display: 'flex',
  },
  subscriptionButton: {
    height: theme.spacing(10),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  nextButton: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    width: '200px',
    borderRadius: theme.spacing(8),
    margin: theme.spacing(2),
  },
  footerButtonMobile: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },
  stepSection: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  mobileFooterContainer: {
    padding: theme.spacing(4),
  },
  mobileFooterItem: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  coverImageSection: {
    display: theme.isMobile ? 'none' : 'block',
    marginLeft: 'auto',
    marginBottom: '80px',
  },
  AutoCompleteRoot: {
    border: '1px solid #585858',
  },
  contentContainer: {
    padding: theme.spacing(2),
    height: '80vh',
    alignItems: 'center',
  },
  backgroundFullScreen: {
    backgroundColor: theme.palette.pubBackground,
  },
  coverImageContainer: {
    borderRadius: theme.spacing(1),
    border: `1px solid #707070`,
  },
  mobileLayout: {
  },
  thumbnailContainer: {
    display: 'inline-flex',
    // overflow:'scroll',
  },
  wrapperClass :{
    overflowX: 'scroll',
    flex: '1 1 auto',
    display: 'inline-block',
    position: 'relative',
    whiteSpace: 'nowrap',
    width:'100%',
    "&:-webkit-scrollbar": {
      display: 'none',
      width:'0px',
    },
    msOverflowStyle:'none',
  },
}));

function LiveMetaCollection(props) {
  const classes = useStyles();
  const isMobile = React.useContext(MobileProvider);
  const dispatch = useDispatch();
  const history = useHistory();
  const {landing: {activeDomain, userPermissions, sections, userProfile}, header: {domainList}} = useSelector(state => state);
  const [finalSection, setFinalSection] = useState([{name: 'create'}]);
  const [coverFile, setCoverFile] = useState(null);
  const [uploadingCover, setUploadingCover] = useState(false);
  const [showImageEditor, setShowImageEditor] = useState(false);
  const [imageContent, setImageContent] = useState(null);
  const [subSection, setSubSection] = useState([]);
  const [subsubSection, setSubSubSection] = useState([]);
  const [openDatePicker, setDatePicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [productInfo, setProductInfo] = useState(null);
  const [savingMeta, setSavingMeta] = useState(false);
  const [paidContent, setPaidContent] = useState(false);
  const [isPublishing, setPublishing] = useState(false);
  const [onOPensection, setOnOpenSection] = useState(false);
  const [allProductInfo, setAllProductInfo] = useState(null);
  const [isShowInput, setShowInput] = useState(false);
  const [sectionvalue, renameSection] = useState('');
  const [activeOption, setActiveOption] = useState(null);
  const [isDirty, setIsDirty] = useState(false);
  const [opennew, setNew] = useState(false);
  const [openSecDialog, setOpenSecDialog] = useState(false);
  // const [value, setValue] = useState('updatesection');
  const [isLoading, setLoading] = useState(false);
  const [articleId, setArticleId] = useState(null);
  const [level, setLevel] = useState(0);
  const [sectionID, setSectionID] = useState(null);
  const [subSectionID, setSubSectionID] = useState(null);
  const [subsubsectionID, setSubSubSectionID] = useState(null);
  const [sectionDefaultvalue, setSectionDefaultValue] = useState('');
  const [subSectionDefaultvalue, setSubSectionDefaultVlaue] = useState('');
  const [subSubSectionDefaultvalue, setSubSubSectionDefaultVlaue] = useState('');
  const [checked, setChecked] = useState(false);

  const [metadata, setmetadata] = useState({});
  const domainInfo = domainList[activeDomain] && domainList[activeDomain][0];
  const [headerType, setHeaderType] = useState('liveStream');
  const [title, setTitle] = useState('');
  const [thumbnail,setThumbnail] = useState(null);
  const [summary, setSummary] = useState(null);
  const params = useParams();
  const childRef = useRef();
  const [openThumbDialog, setThumbnailDialog] = useState(false);
  const [thumbnailContent, setthumbnailContent] = useState(null);
  const [tileData, setTileData] = useState([1, 2, 3, 4]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [filename, setFileName] = useState(null);
  const [thumbURL ,setThumbURL] = useState(null);
  const [defaultThumb ,setDeafaultThumb] = useState(false);
  const [thumbIndex ,setThumbIndex] = useState(false);
  const [blurImg ,setBlurImg] = useState(false);
  const [isBlur ,setIsBlur] = useState(false);
  const [isArticleLoading, setIsArticleLoading] = useState(true);
  const [canGoLive, setCanGoLive] = useState(false);
  const currentUserId = JSON.parse(sessionStorage.getItem('user')).id;
  const [streamMakeArticle, setStreamMakeArticle] = useState(true);

  useEffect(() => {
    try {
      setFinalSection([{name: 'create'}, ...sections]);
    } catch (err) {
      const data = {
          'error': err,
          'email': (userProfile||{}).email || '',
          'url': (window.location||{}).href || '',
          'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
          'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
        };
        logger.push({
            'type': 'error',
            'data': data,
          });
      console.log("err", err)
    }
  }, [sections.length, canGoLive]);

  useEffect(()=>{
    return hideIntercom();
  },[]);

  useEffect(() => {
    setArticleId(params.articleId);
    handleFirstLoad()
  }, [finalSection.length]);

  const handleFirstLoad = async () => {
    if (finalSection && finalSection.length == 1) {
      return;
    }
    setIsArticleLoading(true);
    try {
      let meta;
      let tempArticleId = params.articleId && parseInt(params.articleId);
      const loadArticle= async () => {
        if (!tempArticleId) {
          const {result: {metadata: m}} = await dispatch(createNewArticle());
          setmetadata(m);
          meta = m;
          setArticleId(m.articleId);
        } else {
          const {result: m} = await dispatch(fetchArticleMeta(tempArticleId));
          setmetadata(m);
          meta = m;
          setArticleId(m.articleId);
        }
      }
      await loadArticle();
      setCanGoLive(false);
      const checkLiveStreams = async () => {
        let existingLiveStreams = (await dispatch(fetchRawFeed('live&isShowLive=1', 'liveStream'))).result
          .filter(a => (a.streamingMeta && a.streamingMeta.userId == currentUserId))
        if (existingLiveStreams.length > 1) {
          setCanGoLive(false);
        } else if (existingLiveStreams.length == 1) {
          setCanGoLive(existingLiveStreams[0].articleId === tempArticleId);
        } else {
          setCanGoLive(true);
        }
      }
      checkLiveStreams();
      if (Object.keys(meta).length > 0) {
        if (typeof meta.streamMakeArticle !== 'undefined' && meta.streamMakeArticle !== null) setStreamMakeArticle(meta.streamMakeArticle);
        const {sectionId = 0, subSectionId = null, subSubSectionId = null, paidContent, productIdArr = [], showTime} = meta;
        if (showTime) {
          setSelectedDate(showTime * 1000);
        }
        setPaidContent(!!paidContent);

        if (productIdArr.length > 0) {
          setChecked(true);
        }

        setSubSubSectionID(subSubSectionId);
        const sectionItem = sectionId ? finalSection.find((item) => item.id === sectionId) :
          finalSection[0];

        sectionId ? setSectionID(sectionId) :
          setSectionID(finalSection[0].id);

        setSectionDefaultValue(sectionItem);
        
        const {sub_section} = sectionItem;

        if (sub_section.length > 0) {
          subSectionId ? setSubSectionID(subSectionId) :
            setSubSectionID(null);
          subSectionId ?
            setSubSectionDefaultVlaue(sub_section.find((item) => item.id === subSectionId)) :
            setSubSectionDefaultVlaue(null);
          try {
            if (sub_section.length > 0) {
              const {sub_sub_section} = subSectionId ? sub_section.find((item) => item.id === subSectionId) :
                sub_section[0];
              if (sub_sub_section.length > 0) {
                subSubSectionId ?
                  setSubSubSectionDefaultVlaue(sub_sub_section.find((item) => item.id === subSubSectionId))
                  : setSubSubSectionDefaultVlaue(null)
              } else {
                setSubSubSectionDefaultVlaue('')
              }

            }
          } catch (err) {
            const data = {
              'error': err,
              'email': (userProfile||{}).email || '',
              'url': (window.location||{}).href || '',
              'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
              'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
            };
            logger.push({
                'type': 'error',
                'data': data,
              });
            console.log("err", err)
          }

        } else {
          setSubSectionID(null);
          setSubSectionDefaultVlaue('');
        }

        setSubSection([{name: 'create'}, ...sub_section || []]);
        if (sub_section.length > 0) {
          const {sub_sub_section} = subSectionId ? sub_section.find((item) => item.id === subSectionId) :
            sub_section[0];
          setSubSubSection([{name: 'create'}, ...sub_sub_section || []]);
        }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('err', err)
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
    }
    setIsArticleLoading(false);
  }

  useEffect(() => {
    const getSub = async () => {
      const data = await dispatch(getProductsDetails());
      if (data) {
        const {products = []} = data;
        setAllProductInfo(products)
        paidContent ?
          setProductInfo(products.find((ele) => ele.productName === 'PAID_ACCESS'))
          : setProductInfo(products.find((ele) => ele.productName === 'PUBLIC_ACCESS'));
      }
    }
    getSub();

  }, [])


  const handleDateChange = (date) => {
    setSelectedDate(moment(date).toDate());
  };

  const getThumbnail = () => {
    return videoDefaultImage;
  }

  const streamMakeArticleChange = (e) => {
    setStreamMakeArticle(e.target.checked);
  };

  const closeImageEditor = () => {
    setShowImageEditor(false);
    setCoverFile(null);
  }
  const getCropDataAndUpload = (target) => {
    const notAllowed = ['webp', 'gif', 'svg+xml'];
    const file = target && target.files[0];
    const extension = file && file.type.split("/")[1];
    // if the extension is not allowed show error
    if (notAllowed.indexOf(extension) > -1) {
      dispatch(setAppMessage({message: 'Unsupported media format.', header: 'Error', type: 'alert'}));
      return;
    }
    setCoverFile(file);
    const fr = new FileReader();
    fr.onload = (stream) => {
      setImageContent(stream.target.result);
      openImageEditor();
    }
    fr.readAsDataURL(file);
  }

  const openImageEditor = () => {
    setShowImageEditor(true);
  }

  const getCropData = (cropData) => {
    handleCoverImageUpload(cropData);
  }

  const handleCoverImageUpload = async (crop) => {
    setUploadingCover(true);
    const file = coverFile;
    let cropData = {
      x: crop.x,
      y: crop.y,
      scale: parseFloat(crop.scale.toFixed(4)),
    }
    try {
      const { localUploadResponse } = await dispatch(saveMediaToPisco(file));
      const { cdn } = localUploadResponse;
      let { data: {
        result: {
          coverPicture,
          coverPictureSmall,
          imgExtension,
        },
      },
      } = await dispatch(changeCoverPicture(articleId, { source: `https:${cdn}`, cropData }));
      updateCoverImageSmall(coverPicture, coverPictureSmall, imgExtension);
    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      const { message } = err;
      dispatch(setAppMessage({ message: message || 'Unable to change cover image.', header: 'Error', type: 'alert' }));
      // handle error
    } finally {
      setUploadingCover(false);
      setCoverFile(null);
    }
  }

  const updateCoverImageSmall  = (coverPicture, coverPictureSmall, imgExtension) => {
    setmetadata({...metadata, coverPicture, coverPictureSmall, imgExtension});
  }

  const openDatePickerFun = (val = true) => {
    setDatePicker(val)
  }

  const enablePrice = (event) => {
    setPaidContent(true);
    setProductInfo(allProductInfo.find((ele) => ele.productName === 'PAID_ACCESS'))
  }

  const disablePrice = (event) => {
    setPaidContent(false);
    setProductInfo(allProductInfo.find((ele) => ele.productName === 'PUBLIC_ACCESS'));
  }
  const CustomDatePickerField = (props) => {
    return (
      <span onClick={() => props.onClickHandler(true)}>{}</span>
    )
  }

  const emailChanges = (e) => {
    setChecked(e.target.checked);
  }

  const setMeta = async (e) => {
    if (!(metadata.title && metadata.title.trim().length > 1) || !(metadata.summary && metadata.summary.trim().length > 1)) {
      dispatch(setAppMessage({message: 'Please fill title and summary for the stream', header: 'Error', type: 'alert'}));
      return;
    }
    setSavingMeta(true);
    let tempArticleId = articleId;
    try {
      const data = {
        title: metadata.title,
        section_id: sectionID,
        sub_section_id: subSectionID,
        sub_sub_section_id: subsubsectionID,
        paidContent: paidContent,
        thumbnail: (childRef.current &&  childRef.current.getThumbnail()) || metadata.thumbnail || getThumbnail(),
        headerType: headerType,
        summary: metadata.summary,
        headerContent: "{}",
        showTime: selectedDate && moment(selectedDate).unix(),
        streamMakeArticle: streamMakeArticle,
      };
      if (checked && productInfo.productId) data.productIdArr = [productInfo.productId];
      if (checked) data.sendLiveEmail = true;
      await dispatch(updateArticleMeta(tempArticleId, data));
      setSavingMeta(false);
      // history.push('/dash/live/transmission/' + tempArticleId);
      await publish(e);
    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      setSavingMeta(false);
      const {message} = err;
      dispatch(setAppMessage({message: message || 'Unable to save live video.', header: 'Error', type: 'alert'}));
      return Promise.reject(err);
      // handle error
    }
  };

  const publish = async (e) => {
    try {
      setPublishing(true);
      if (metadata && metadata.status === 4) {
        await dispatch(clearCache(articleId, {skip: true}));
        setPublishing(false);
      } else {
        const dataObj = {skip: true, sendLiveEmail: checked}
        const res = await dispatch(publishArticle(articleId, dataObj));
        metadata.status = 4;
        setmetadata(metadata);
        dispatch(setAppMessage({message: res.data.result || ''}));
        setPublishing(false);
      }
      dispatch(setAppMessage({ message: "Live video saved" ,handleSuccess:()=>{
          if (e) {
            history.push('/dash/live/transmission/' + articleId);
          } else {
            history.push('/dash/live/schedule');
          }
        },
        header: 'Success',
        type: 'alert' }));
    } catch (err) {
      setPublishing(false);
      const { message, data:{code} } = err;
      if (code && code === 'ERR_NO_VERIFY') {
        dispatch(setAppMessage({ 
          message: message,
          leftButtonText: 'Cancel',
          rightButtonText: 'Verify email',
          handleSuccess: async () => {
            try {
              await dispatch(sendVerificationLink());
              dispatch(setAppMessage({ message: 'Verfication link sent to your mail.', header: 'Success', type: 'alert' }));
            } catch (err) {
              const data = {
                'error': err,
                'email': (userProfile||{}).email || '',
                'url': (window.location||{}).href || '',
                'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
                'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
              };
              logger.push({
                  'type': 'error',
                  'data': data,
                });
              dispatch(setAppMessage({ message: err.message|| 'unable to send verification link', header: 'Error', type: 'alert' }));
            }
          },
          header: 'Error' }));
      } else {
        dispatch(setAppMessage({ message: message, header: 'Error', type: 'alert' }));
      }
      dispatch(setAppMessage({message: message, header: 'Error', type: 'alert'}));
    }
  };

  const onShowInput = () => {
    setShowInput(true);

  }
  const closeSelectDialog = async () => {
    setShowInput(false);
    setOnOpenSection(false);
    renameSection('');
    setNew(false);
  }
  const setActiveSection = async (option) => {
    try {
      const {sub_section = []} = option;
      setSubSection([{name: 'create'}, ...sub_section]);
      setSubSectionDefaultVlaue('');
      setSubSubSectionDefaultVlaue('');
      setSubSubSection([]);
      await setActiveOption(option);
      await setSectionID(option.id);
      await setSubSectionID(null);
      await setSubSubSectionID(null);
    } catch (err) {
      console.log("err", err)
    }
  }
  const setActiveSubSection = async (option) => {
    try {
      const {sub_sub_section = []} = option;
      if (sub_sub_section.length > 0 && sub_sub_section[0].name === 'create') {
        setSubSubSection([...sub_sub_section]);
      } else {
        setSubSubSection([{name: 'create'}, ...sub_sub_section]);
      }

      setSubSectionDefaultVlaue(option);
      setSubSubSectionDefaultVlaue('');
      await setActiveOption(option);
      await setSubSectionID(option.id);
      await setSubSubSectionID(null);
    } catch (err) {
      const data = {
          'error': err,
          'email': (userProfile||{}).email || '',
          'url': (window.location||{}).href || '',
          'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
          'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
        };
        logger.push({
            'type': 'error',
            'data': data,
          });
      console.log("err")
    }
  }

  const setActiveSubSubSection = (option) => {
    setActiveOption(option);
    setSubSubSectionID(option.id);
    setSubSubSectionDefaultVlaue(option);
  }

  const openSectionDialog = () => {
    setOpenSecDialog(true);
  }
  const handleClickEditOpen = (option, lvl) => {
    setLevel(lvl)
    setActiveOption(option)
    renameSection(option.name);
    setOpenSecDialog(true);
  }
  const handleClose = () => {
    setOpenSecDialog(false);
    setNew(false);
  }
  const onUpdateSection = async () => {
    try {
      setLoading(true)
      await dispatch(updateSection(activeOption.id, {name: sectionvalue, slug: kebabCase(sectionvalue)}));
      finalSection.forEach((ele) => {
        if (ele.id === activeOption.id) {
          ele.name = sectionvalue;
        }
      })

      subSection.forEach((ele) => {
        if (ele.id === activeOption.id) {
          ele.name = sectionvalue;
        }
      })

      subsubSection.forEach((ele) => {
        if (ele.id === activeOption.id) {
          ele.name = sectionvalue;
        }
      })

      renameSection('');

      setLoading(false)
      handleClose();
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  const handleTitle = (event) => {
    if (event.target && event.target.value.length <= 150){
      const meta = {...metadata, title: event.target.value};
      setmetadata(meta);
    }
  }

  const handleSummary = (event) => {
    if (event.target && event.target.value.length <= 200){
      const meta = {...metadata, summary: event.target.value};
      setmetadata(meta);
    }
  }

  const getID = () => {
    if (level === 1) {

      return 0
    }
    if (level === 2) {

      return sectionID
    }
    if (level === 3) {

      return subSectionID
    }
  }

  const onCreateSection = async () => {
    try {
      if (!sectionvalue) {
        return;
      }
      setLoading(true);
      const {metadata} = await dispatch(createSection({
        name: sectionvalue,
        type: kebabCase(sectionvalue),
        parent_section: getID(),
        hierarchy: level - 1,
        status: 1,
      }));
      setFinalSection([{name: 'create'}, ...sections]);

      if (level == 1) {
        setFinalSection([...finalSection, metadata]);
      }
      if (level == 2) {
        finalSection.forEach((item) => {
          if (item.id === sectionID) {
            item.sub_section = [...subSection, metadata];
          }
        })
        setSubSection([...subSection, metadata]);
      }
      if (level == 3) {
        finalSection.forEach((item) => {
          if (item.id === sectionID) {
            const {sub_section} = item;
            sub_section.forEach((subitem) => {
              if (subitem.id === subSectionID) {
                subitem.sub_sub_section = [...subsubSection, metadata];
              }
            });
            if (sub_section.length > 0 && sub_section[0].name === 'create') {
              setSubSection(sub_section)
            } else {
              setSubSection([{name: 'create'}, ...sub_section])
            }
            item.sub_section = sub_section;
          }
        });

        setSubSubSection([...subsubSection, metadata]);
      }

      renameSection('');
      setLoading(false)
      handleClose();
    } catch (err) {
      const data = {
          'error': err,
          'email': (userProfile||{}).email || '',
          'url': (window.location||{}).href || '',
          'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
          'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
        };
        logger.push({
            'type': 'error',
            'data': data,
          });
      setLoading(false)
      dispatch(setAppMessage({message: err.message || 'something went wrong!!!.', header: 'Error', type: 'alert'}));
    }
  }

  const onCreateSubsection = async () => {
    try {
      const {metadata} = await dispatch(createSection({
        name: sectionvalue,
        type: kebabCase(sectionvalue),
        parent_section: activeOption.id,
        hierarchy: level,
        status: 1,
      }));
      if (level === 1) {
        finalSection.forEach((ele) => {
          if (ele.id === activeOption.id) {
            ele.sub_section = [...ele.sub_section, metadata]
          }
        })
      }
      if (level === 2) {
        subSection.forEach((ele) => {
          if (ele.id === activeOption.id) {
            ele.sub_sub_section = [...ele.sub_sub_section, metadata]
          }
        })
      }
      handleClose();
    } catch (err) {
      const data = {
          'error': err,
          'email': (userProfile||{}).email || '',
          'url': (window.location||{}).href || '',
          'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
          'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
        };
        logger.push({
            'type': 'error',
            'data': data,
          });
      console.log(err)
    }
  }

  const addSections = (lvl) => {
    setNew(true);
    setLevel(lvl);
    openSectionDialog();
  };

  const getTitle = () => {
    if (opennew) {
      if (level === 2) return "Add Subsection"
      if (level === 3) return "Add SubSubsection"
      return "Add section"

    } else {

      if (level === 2) return "Edit Subsection"
      if (level === 3) return "Edit SubSubsection"
      return "Edit Section"
    }
  }

  const removeDate = (e) => {
    e.stopPropagation();
    setSelectedDate(null);
  };

  const handleFileUpload = (target, type) => {
    const allowed = ['jpg','jpeg', 'png','bmp'];
    const file = target && target.files[0];
    const uploadType = type && type.split("/")[0];
    const extension = file && file.type.split("/")[1];
    if (allowed.indexOf(extension) < 0) {
      dispatch(setAppMessage({ message: 'Unsupported media format.', header: 'Error', type: 'alert' }));
      return;
    }
    const { size } = file;
    const sizeInMb = size / 1000000;
    if (sizeInMb > 500) {
      dispatch(setAppMessage({ message: 'image larger than 500MB not allowed.', header: 'Error', type: 'alert' }));

      return;
    }
    if (file && type && !(file.type.indexOf(uploadType) > -1)) {
      //this.props.InvalidFileError();
      return;
    }
    if (uploadType === 'image' && extension === 'webp') {
      //this.props.InvalidFileError('Webp images are not allowed, as not supported by Safari browser.');
      return;
    }
    if (!file || !file.name) {
      return;
    }
    setFileName(file.name);

    const fr = new FileReader();
    fr.onload = (stream) => {
      setthumbnailContent(stream.target.result);
      setThumbnailDialog(true);
    }
    fr.readAsDataURL(file);


  }
  const setSelected = (item) => {

    if(tileData[item] === blurImg || tileData[item] === thumbURL ){
      tileData[item] === blurImg ? setIsBlur(true) :setIsBlur(false)
      setFileName(GetimageName(thumbURL || blurImg));
      setThumbIndex(item);
      setDeafaultThumb(true);
      setthumbnailContent(tileData[item] === blurImg ?blurImg:thumbURL);
      setThumbnailDialog(true);
    }
    else {
      if (item === selectedItem) {
        setSelectedItem(null);
        return;
      }
      setSelectedItem(item);
    }

  }

  return (
    <>
      <CropImageDialog
        ref={childRef}
        open={openThumbDialog}
        setThumbnailDialog={setThumbnailDialog}
        thumbnailContent={thumbnailContent}
        setTileData={setTileData}
        tileData={tileData}
        name={filename}
        defaultThumb = {defaultThumb}
        thumbIndex = {thumbIndex}
        setDeafaultThumb ={setDeafaultThumb}
        setThumbURL ={setThumbURL}
        selectedItem = {selectedItem}
        setBlurImg={setBlurImg}
        isBlur ={isBlur}
        setSelectedItem = {setSelectedItem}
      />
      <ImageEditor
        open={showImageEditor}
        closeDialog={closeImageEditor}
        getCropData={getCropData}
        baseContent={imageContent}/>
      <Dialog fullScreen open={true} >
        <Grid container>
          {/**----------------------------Header Section---------------------------- */}
          <Header>
            <Grid container justify="center">
              {<Grid item xs={12} md={12}>
                <Box display="flex" alignItems="center" justifyContent="space-between" ml={isMobile ? 4 : 25}
                     mr={isMobile ? 4 : 25}>
                  <Box display="flex" alignItems="center" height="100%">
                    <CloseIcon style={{color: 'white'}} onClick={() => {
                      history.push("/dash/live/schedule")
                    }}/>
                  </Box>
                  <Box component="p" display="flex" justifyContent="center">LIVE VIDEO</Box>
                </Box>
              </Grid>
              }
            </Grid>
          </Header>

          {/**----------------------------End Header Section---------------------------- */}


          {/**---------------------------Main Container Section--------------------------*/}
          {savingMeta && <LinearLoader position="sticky" top={`${isMobile ? '70px' : '75px'}`} width="100%"/>}
          {isArticleLoading && <Grid container justify="center"><CircularLoader height="auto" size={20} mt={10}/></Grid>}

          {!isArticleLoading && <Grid container justify="center">
            <Grid container justify="center">
              <Grid item xs={11} md={8}>
                <Box mt={6}>
                  <InputBox
                    id="texttitle"
                    error={isDirty &&metadata.title !== undefined&& metadata.title !== null&& !metadata.title.length}
                    helperText={(isDirty && metadata.title !== undefined&& metadata.title !== null&& !metadata.title.length) && "Title cannot be empty."}
                    onChange={handleTitle}
                    heading="Title*"
                    variant="standard"
                    value={metadata.title || ''}
                    placeholder="Enter Title Here"
                    className={{root:classes.inputRoot}}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {`${ (metadata && metadata.title &&metadata.title.length ) || 0}/150`}
                        </InputAdornment>
                      ),
                    }}
                    headingStyle={{
                      component:"span",
                      fontWeight:"bold",
                      fontSize:14,
                      mb: 1,
                    }}
                    fullWidth/></Box>
                <Box mt={5}>
                  <InputBox
                    id="textdescription"
                    heading="Summary*"
                    variant="standard"
                    error={isDirty && metadata && metadata.summary !== undefined&& metadata.summary !== null &&!metadata.summary.length}
                    value={(metadata && metadata.summary) || ''}
                    onChange={handleSummary}
                    name="description"
                    multiline
                    rowsMax={4}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {`${ (metadata && metadata.summary &&metadata.summary.length ) || 0}/200`}
                        </InputAdornment>
                      ),
                    }}
                    headingStyle={{
                      component:"span",
                      fontWeight:"bold",
                      fontSize:14,
                      mb: 1,
                    }}
                    // helperText={(isDirty && !metadata.title.length) && "Summary cannot be empty."}
                    // label={`Summary (${(metadata.description && metadata.description.length)||0} characters)`}
                    placeholder="Give a short summary about the article."
                    className={{root:classes.inputRoot}}
                    fullWidth/></Box>
                <Box>
                  <Box mt={6} mb={3}>
                    <Box component="span" fontSize={14} fontWeight="bold" color="primary.main">Thumbnail</Box>
                  </Box>
                  <Box mt={3} display="flex" className={classes.thumbnailContainer}>

                    {
                      <Box><FileUploaderButton
                        type="image/*"
                        onChange={handleFileUpload}
                      />
                      </Box>
                    }
                  </Box>
                  <Box width='50%' height='100%' className={classes.coverImageContainer} mt={2}>
                    <img src={(childRef.current &&  childRef.current.getThumbnail()) || metadata.thumbnail ||  getThumbnail()}
                         width='100%' height='100%' alt="image_article"/>
                  </Box>
                </Box>
                {sectionDefaultvalue && <>
                  <Grid item md={7} xs={12} className={isMobile ? classes.mobileLayout : {}}>
                    <Box mt={6} mb={3}>
                      <Box component="span" fontSize={14} fontWeight="bold" color="primary.main">Section</Box>
                    </Box>
                    <Box display="flex" flexWrap={isMobile ? "wrap" : "nowrap"}>


                      <Box display="flex" width="100%">
                        <Autocomplete
                          id="section"
                          size="small"
                          fullWidth
                          disableClearable
                          options={finalSection}
                          defaultValue={sectionDefaultvalue}
                          classes={{paper: classes.AutoCompleteRoot}}
                          style={{width: isMobile ? '100%' : '100%'}}
                          getOptionLabel={(option) => option.name === 'create' ? option.name : option.name}
                          renderOption={option => (option.name === 'create' ? <Box position="sticky"
                                                                                   onClick={() => addSections(1)}
                                                                                   width="100%" display="flex"
                                                                                   alignItems="center">
                              <AddIcon fontSize="small"/> Add section
                            </Box> : <Box display="flex" width="100%" height="100%" alignItems="center"
                                          justifyContent="space-between">
                              <Box width="100%" height="100%"
                                   onClick={() => setActiveSection(option)}>{option.name}</Box>
                              <IconButton>
                                <EditIcon fontSize="small"
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            handleClickEditOpen(option, 1)
                                          }}
                                />
                              </IconButton>
                            </Box>

                          )}
                          renderInput={params => <Input {...params} fullWidth label="section*" variant="outlined"/>}
                        />
                      </Box>

                      <Box display="flex" width="100%" ml={isMobile ? 0 : 2} mt={isMobile ? 3 : 0}>
                        <Autocomplete
                          id="subsection"
                          size="small"
                          disableClearable
                          disabled={subSection && subSection.length === 0}
                          options={subSection}
                          defaultValue={subSectionDefaultvalue}
                          value={subSectionDefaultvalue}
                          getOptionLabel={(option) => option.name === 'create' ? option.name : option.name}

                          classes={{paper: classes.AutoCompleteRoot}}
                          style={{width: isMobile ? '100%' : '100%'}}
                          renderOption={option => option.name === 'create' ? <Box position="sticky"
                                                                                  onClick={() => addSections(2)}
                                                                                  width="100%" display="flex"
                                                                                  alignItems="center">
                            <AddIcon fontSize="small"/> Add subsection
                          </Box> : <Box display="flex" width="100%" height="100%" alignItems="center"
                                        justifyContent="space-between"><Box width="100%" height="100%"
                                                                            onClick={(event) => {
                                                                              setActiveSubSection(option)
                                                                            }}>{option.name}{
                          }</Box>

                            <IconButton>
                              <EditIcon fontSize="small"
                                        onClick={(event) => {
                                          event.stopPropagation();
                                          handleClickEditOpen(option, 2)
                                        }}
                              />
                            </IconButton>

                          </Box>}
                          renderInput={params => <Input {...params} fullWidth label="subsection" variant="outlined"/>}
                        />
                      </Box>
                      <Box display="flex" width="100%" ml={isMobile ? 0 : 2} mr={isMobile ? 0 : 2}
                           mt={isMobile ? 3 : 0}>
                        <Autocomplete
                          id="subsubsection"
                          size="small"
                          disableClearable
                          defaultValue={subSubSectionDefaultvalue}
                          value={subSubSectionDefaultvalue}
                          disabled={subsubSection && subsubSection.length === 0}
                          options={subsubSection || null}
                          classes={{paper: classes.AutoCompleteRoot}}
                          style={{width: isMobile ? '100%' : '100%'}}
                          getOptionLabel={(option) => option.name === 'create' ? option.name : option.name}

                          renderOption={option => option.name === 'create' ? <Box position="sticky"
                                                                                  onClick={() => addSections(3)}
                                                                                  width="100%" display="flex"
                                                                                  alignItems="center">
                            <AddIcon fontSize="small"/> Add subsubsection
                          </Box> : <Box display="flex" width="100%" alignItems="center"
                                        justifyContent="space-between">
                            <Box width="100%" height="100%" onClick={() => setActiveSubSubSection(option)}>{option.name}{
                              // activeBenefits===option.id && postingBenefits && <CircularLoader height="auto" size={20}/>
                            }</Box>
                            {

                              <IconButton>
                                <EditIcon fontSize="small"
                                          onClick={() => handleClickEditOpen(option, 3)}
                                />
                              </IconButton>
                            }
                          </Box>}
                          renderInput={params => <Input {...params} fullWidth label="subsubsection"
                                                        variant="outlined"/>}
                        />
                      </Box>

                    </Box>
                    <Box>
                      {!!(domainInfo && domainInfo.subscription_enabled) && <Grid item xs={12} md={8}>
                        <Box mt={6} mb={2}>
                          <Box component="span" fontSize={14} fontWeight="bold" color="primary.main">
                            Subscriber's Only
                          </Box>
                        </Box>
                        <Box className={classes.subscriptionBox}>
                          <Button type="group"
                                  groupButton={
                                    {
                                      number: 2,
                                      texts: ['YES', 'NO'],
                                      buttonColors: [`${paidContent ? 'primary' : 'secondary'}`, `${!paidContent ? 'primary' : 'secondary'}`],
                                      onClickListeners: [enablePrice, disablePrice],
                                      variants: ['contained', 'contained'],
                                    }
                                  }
                          />
                          {/* <ButtonToggle className={classes.subscriptionButton} name="subscriptionToggle"
                            labelpositive='Yes' labelnegative="No" value={paidContent} onChange={handleSubscriptionToggle}></ButtonToggle> */}
                        </Box>
                        <Box mt={4} color="primary.dark" fontSize={12}>
                          {
                            paidContent ? <>
                                This post will be accessible to only paid subscribers of your subscription products.</>
                              : <>This post will be accessible to everyone</>
                          }

                        </Box>
                        <Box>

                        </Box>
                      </Grid>}
                    </Box>
                    <Box>
                      {!!(domainInfo && domainInfo.subscription_enabled) && <Grid item xs={12} md={8}>
                        <Box mt={6} mb={2}>
                          <Box color="primary.main" fontWeight="bold" fontSize={14}>Notify by Email</Box>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Button type="checkbox" size="small" onChange={emailChanges} checked={checked} />
                          <Box component="span" color="primary.main" fontWeight="bold"
                            fontSize={12}>{paidContent ? 'Send email to paid subscribers' : 'Send email to all subscribers'}</Box>
                        </Box>
                      </Grid>}
                    </Box>
                    <Box>
                    <Grid item xs={12} md={8}>
                      <Box display="flex" mt={6}>
                        <Button type="checkbox" size="small" onChange={streamMakeArticleChange} checked={streamMakeArticle} />
                        <Box mt={2}>Automatically generate a recorded video once live is over</Box>
                      </Box>
                    </Grid>
                    </Box>
                    {
                    <Box mt={6} style={{marginBottom: '7em'}}>
                      <Box>
                        <Box fontSize={14} component="span" fontWeight="bold" color="primary.main">Schedule Event</Box>
                      </Box>
                      <Box mt={4}>
                        <Button color="secondary" variant="contained" onClick={openDatePickerFun}>
                          {selectedDate ? <>{moment(selectedDate).format('LLLL')}
                            <CancelOutlinedIcon fontSize="small" onClick={removeDate}/></> : 'Schedule'}</Button>

                      </Box>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDateTimePicker
                          open={openDatePicker}
                          disablePast
                          onOpen={() => openDatePickerFun(true)}
                          onClose={() => openDatePickerFun(false)}
                          margin="normal"
                          id="date-picker-dialog"
                          label="Date picker dialog"
                          format='DD MMM YYYY'
                          value={selectedDate}
                          onChange={handleDateChange}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          onError={console.log}
                          TextFieldComponent={CustomDatePickerField}

                        />
                      </MuiPickersUtilsProvider>
                    </Box>

                    }
                  </Grid>
                </>
                }
              </Grid>
            </Grid>
          </Grid>
          }
          {/**---------------------------End Main Container Section--------------------------*/}

          {/**----------------------------Footer Section-------------------------------------*/}
          {!isArticleLoading &&
          <Grid item xs={12} md={12}>
            <Footer>
              {<Grid container justify="center">
                <Grid item md={8} xs={12} className={isMobile ? classes.mobileLayout : {}}>
                  <Box display="flex" justifyContent="space-between">
                    <Box display="flex">
                      {<Button
                        variant="contained"
                        disabled={isPublishing || savingMeta || uploadingCover}
                        onClick={() => setMeta()}
                        boxProps={{ml: 4}}
                      >{selectedDate ? 'Schedule Live Video' : 'Save draft'}</Button>
                      }
                      {(!canGoLive && !selectedDate) && <Box mx={2}>
                        You already have an active live event.
                      </Box>
                      }
                      {!selectedDate && canGoLive && <Button
                        variant="contained"
                        disabled={isPublishing || savingMeta || uploadingCover}
                        onClick={() => setMeta('Start Live Video')}
                        boxProps={{ml: 4}}
                      >Start Live Video</Button>}
                      {isPublishing && <CircularLoader size={20} height="auto"/>}
                    </Box>
                  </Box>
                </Grid>
              </Grid>}


            </Footer>
          </Grid>
          }
          {/**----------------------------End Footer Section-------------------------------------*/}
        </Grid>

      </Dialog>
      <Dialog
        open={openSecDialog}
      >
        <DialogTitle id="alert-dialog-title">{getTitle()}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Input
              value={sectionvalue}
              onChange={(e) => renameSection(e.target.value)}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose} color="default">
            CLOSE
          </Button>
          <Button disabled={isLoading} variant="contained" onClick={opennew ? onCreateSection : onUpdateSection}
                  color="primary">
            OK
          </Button>
          {isLoading && <CircularLoader size={20} height="auto"/>}
        </DialogActions>

      </Dialog>
    </>
  )
}

export default LiveMetaCollection;


function GetimageName(url)
{
  if (url)
  {
    const extension = url.split('.').reverse()
    let m = url.toString().match(/.*\/(.+?)\./);
    if (m && m.length > 1)
    {
      return m[1]+'.'+extension[0];
    }
  }
  return "";
}
