import {calcelAPiCall} from "../../utils/apiClient";
import {fetchCategories} from "../landing/landing-action";
import setAppMessage from "../../app-component/app-component-action";


export function createNewArticle() {
  return async (dispatch, getState, apiHandler) => {
    const { api, apiRoutes } = apiHandler;
    try {
      const { landing:{activeDomain } } = getState();
      const { data } = await api(apiRoutes.createNewArticleApi(activeDomain));
      
      return Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  }
} 


export function fetchArticleContent (articleId) {
  return async (dispatch, getState, apiHandler) => {
    try {
      const { landing: {activeDomain}} = getState();
      const { api, apiRoutes } = apiHandler;
      const { data } = await api(apiRoutes.fetchArticleContentApi(activeDomain, articleId));
      return Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export function fetchArticleMeta (articleId) {
  return async (dispatch, getState, apiHandler) => {
    try {
      const { landing: {activeDomain }} = getState();
      const { api, apiRoutes } = apiHandler;
      const { data }  = await api(apiRoutes.fetchArticleMetaApi(activeDomain, articleId));
      return Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export function updateArticleMeta (articleId, data) {
  return async (dispatch, getState, apiHandler) => {
    try {
      const { landing: { activeDomain }} = getState();
      const {api, apiRoutes } = apiHandler;
      await api(apiRoutes.updateArticleMetaApi(activeDomain, articleId, data));
      return Promise.resolve({ updated: true});
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export function getBlurImage(source) {
  return async (dispatch, getState, apiHandler) => {
    const { landing: { activeDomain }} = getState();
    const { api, apiRoutes } = apiHandler;
    try {
      let {data:{result}} = await api(apiRoutes.blurImageApi(source, activeDomain));
      return Promise.resolve(result);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export function updateArticleContent (articleId, html, objectMap) {
  return async (dispatch, getState, apiHandler) => {
    try {
      const { landing: { activeDomain }} = getState();
      const { api, apiRoutes } = apiHandler;
      await api(apiRoutes.updateArticleContentApi(activeDomain, articleId, html, JSON.stringify(objectMap)));
      return Promise.resolve({ updated: true });
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export function clearCache(articleId,data) {
  return async (dispatch, getState, apiHandler) => {
    try {
      const { landing: { activeDomain }} = getState();
      const { api, apiRoutes } = apiHandler;
      await api(apiRoutes.clearCache(articleId, activeDomain,data));
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export function changeCoverPicture(articleId, changeCoverForm) {
  return async (dispatch, getState, apiHandler) => {
    try {
      const { landing: {activeDomain}} = getState();
      const { api, apiRoutes } = apiHandler;
      const res = await api(apiRoutes.updateCoverImageApi(articleId, activeDomain, changeCoverForm));
      return Promise.resolve(res);
    } catch(err) {
      return Promise.reject(err);
    }
  }
}


export function saveMediaToPisco(file) {
  return async (dispatch, getState, apiHandler) => {
    try {
      if (!file) {
        return Promise.reject(new Error('Invalid Media File'));
      }
      const { api, apiRoutes } = apiHandler;
      let name = file.name.replace(/[^a-zA-Z.0-9]/g, '');
      file = new File([file], name, { type: file.type });
      const { landing: { activeDomain }} = getState();
      const preSaveData = piscoPreSave(file);
      let {data:preSaveResponse} = await api(apiRoutes.piscoPreSaveApi(preSaveData, activeDomain));
      const localUploadData = localUpload(file);
      let {data:localUploadResponse} = await api(apiRoutes.localUploadApi(localUploadData, activeDomain));
      return Promise.resolve({ preSaveResponse, localUploadResponse });
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export function publishArticle(articleId,data) {
  return async(dispatch, getState, apiHandler) => {
    try {
      const { api, apiRoutes } = apiHandler;
      const { landing: {activeDomain }} = getState();
      let res =  await api(apiRoutes.publishArticleApi(activeDomain, articleId,data));
      return Promise.resolve(res);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export function sendVerificationLink() {
  return async(dispatch, getState, apiHandler) => {
    try {
      const { userApi, apiRoutes } = apiHandler;
      const { landing: {userProfile}} = getState();
      console.log("user pf", userProfile.email);
      let res =  await userApi(apiRoutes.sendEmailVerificationLink(userProfile.email));
      return Promise.resolve(res);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export function getVideoThumbnail(source) {
  return async (dispatch, getState, apiHandler) => {
    try {
      const {api, apiRoutes } = apiHandler;
      const {landing: {activeDomain}} = getState();
      let {data} = await api(apiRoutes.videoThumbnailApi(source, activeDomain));
      return Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export function sendResetPasswordMail () {
  return async (dispatch, getState, apiHandler) => {
    try {
      const {userApi, apiRoutes } = apiHandler;
      const {landing: {userProfile}} = getState();
      const { email } = userProfile;
      await userApi(apiRoutes.forgotPasswordApi(email));
      return Promise.resolve('done');
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export function createBroadcast(articleId) {
  return async (dispatch, getState, apiHandler) => {
    const { landing: { activeDomain }} = getState();
    const { api, apiRoutes } = apiHandler;
    try {
      let result = await api(apiRoutes.createBroadcast(activeDomain, articleId));
      return Promise.resolve(result.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export function makeBroadcastLive(articleId, data={}) {
  return async (dispatch, getState, apiHandler) => {
    const { landing: { activeDomain }} = getState();
    const { api, apiRoutes } = apiHandler;
    try {
      let result = await api(apiRoutes.makeBroadcastLive(activeDomain, articleId, data));
      return Promise.resolve(result.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export function uploadBroadcastFile(articleId, data) {
  return async (dispatch, getState, apiHandler) => {
    const { landing: { activeDomain }} = getState();
    const { api, apiRoutes } = apiHandler;
    try {
      let result = await api(apiRoutes.uploadBroadcastFile(activeDomain, articleId, data));
      return Promise.resolve(result.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export function switchToBlank(articleId) {
  return async (dispatch, getState, apiHandler) => {
    const { landing: { activeDomain }} = getState();
    const { api, apiRoutes } = apiHandler;
    try {
      let result = await api(apiRoutes.switchToBlankPdf(activeDomain, articleId));
      return Promise.resolve(result.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export function switchToMainPdf(articleId) {
  return async (dispatch, getState, apiHandler) => {
    const { landing: { activeDomain }} = getState();
    const { api, apiRoutes } = apiHandler;
    try {
      let result = await api(apiRoutes.switchToMainPdf(activeDomain, articleId));
      return Promise.resolve(result.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export function uploadDefaultFile(articleId) {
  return async (dispatch, getState, apiHandler) => {
    const { landing: { activeDomain }} = getState();
    const { api, apiRoutes } = apiHandler;
    try {
      let result = await api(apiRoutes.uploadBroadcastDefaultFile(activeDomain, articleId));
      return Promise.resolve(result.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export function setBroadcastMetadata(articleId, data) {
  return async (dispatch, getState, apiHandler) => {
    const { landing: { activeDomain }} = getState();
    const { api, apiRoutes } = apiHandler;
    try {
      let result = await api(apiRoutes.setBroadcastMetadata(activeDomain, articleId, data));
      return Promise.resolve(result.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export function getBroadcastMetadata(articleId, params) {
  return async (dispatch, getState, apiHandler) => {
    const { landing: { activeDomain }} = getState();
    const { api, apiRoutes } = apiHandler;
    try {
      let result = await api(apiRoutes.getBroadcastMetadata(activeDomain, articleId, params));
      return Promise.resolve(result.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export function endBroadcast(articleId) {
  return async (dispatch, getState, apiHandler) => {
    const { landing: { activeDomain }} = getState();
    const { api, apiRoutes } = apiHandler;
    try {
      let result = await api(apiRoutes.endBroadcast(activeDomain, articleId));
      return Promise.resolve(result.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

/**--------------------------------------helper function----------------------------------*/
function piscoPreSave(file) {
  const formData = new FormData();
  formData.append("file", file, file.name);
  return formData;
}

function localUpload(file) {
  let mediaType = 'Image';
  if (file.type.startsWith('video')) mediaType = 'Video';
  if (file.type.startsWith('audio')) mediaType = 'Audio';
  const formData = new FormData();
  formData.append("file", new Blob());
  formData.append('filename', file.name);
  formData.append('title', file.name);
  formData.append('tags', ['quick-cms'])
  formData.append('imageSource', 'LOCAL');
  formData.append('type', mediaType);
  formData.append('dimensions', {});
  formData.append('caption', file.name);
  return formData;
}

export function deleteArticle(articleId, status) {
  return async (dispatch, getState, apiHandler) => {
    const {api, apiRoutes, actionTypes} = apiHandler;
    try {
      dispatch({type:actionTypes.DELETE.REQUESTED});
      
      const { landing: {activeDomain}} = getState();
      if(status === 4) {
        await api(apiRoutes.unpublishArticleApi(activeDomain, articleId));
      }else {
        await api(apiRoutes.deleteArticle(activeDomain, articleId));
      }
      dispatch({type:actionTypes.DELETE.DONE,articleId});
      return Promise.resolve();
    } catch (err) {
      dispatch({type:actionTypes.DELETE.DONE});
      return Promise.reject(err);
    } 
  }
}

export function getNewsletterAction() {

  return async (dispatch, getState, apiHandler) => {
    try {
      const { api, apiRoutes, actionTypes } = apiHandler;
      const activeDomain = getState().landing.activeDomain;
      const {data} = await api(apiRoutes.getNewsletterApi(activeDomain));
      return Promise.resolve(data)
    } catch (err) {
      // dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}));
      return Promise.reject(err);
    }
  }
}



export function getFullSections() {
  return async (dispatch, getState, apiHandler) => {
    const { landing: { activeDomain }} = getState();
    const { api, apiRoutes } = apiHandler;
    try {
      let {data:{result}} = await api(apiRoutes.getFullSection(activeDomain));
      return Promise.resolve(result);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export function cancelAPI (){
  calcelAPiCall().cancel("Request has been canceled");
}

export function getProductsDetails(){
  return async (dispatch, getState, apiHandler) => {
    const { landing: { activeDomain }} = getState();
    const { api, apiRoutes } = apiHandler;
    try {
      let {data:{result}} = await api(apiRoutes.getProductsInfo(activeDomain));
      return Promise.resolve(result);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export function updateSection(id,data){
  return async (dispatch, getState, apiHandler) => {
    const { landing: { activeDomain }} = getState();
    const { api, apiRoutes } = apiHandler;
    try {
      let {data:result} = await api(apiRoutes.updateSectionById(activeDomain,id,data));
      await dispatch(fetchCategories());
      return Promise.resolve(result);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export function createSection(data) {
  return async (dispatch, getState, apiHandler) => {
    const { landing: { activeDomain }} = getState();
    const { api, apiRoutes } = apiHandler;
    try {
      let {data:result} = await api(apiRoutes.createSection(activeDomain,data));
      await dispatch(fetchCategories());
      return Promise.resolve(result);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}
