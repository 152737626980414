import setAppMessage from "../../app-component/app-component-action";
import { ftruncate } from "fs-extra";

export function getCustomerOverview(obj){
  const queryParams = {
    page: obj.page || 1,
    perPage: obj.perPage || 50,
    customerType: obj.customerType || 'all',
    email: obj.email || '',
  };
  return async(dispatch,getState,apiHandler)=>{
    const { api, apiRoutes, actionTypes } = apiHandler;
    const { activeDomain } = getState().landing;
    try {
      let { data } = await api(apiRoutes.fetchCustomersOverviewApi(activeDomain,queryParams.page,queryParams.perPage,queryParams.customerType, obj.email));
      return Promise.resolve(data);
    }
    catch(err){
      dispatch(setAppMessage({ message: err.message, header: 'Error', type: 'alert' }))
    }
  }
}
export function getProductsDetails(){
  return async (dispatch, getState, apiHandler) => {
    const { landing: { activeDomain }} = getState();
    const { api, apiRoutes } = apiHandler;
    try {
      let {data} = await api(apiRoutes.getProducts(activeDomain));
      return Promise.resolve(data);
    } catch (err) {
      dispatch(setAppMessage({ message: err.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}

export function addGiftToSubscriber(payload,isEdit =false){
  return async (dispatch, getState, apiHandler) => {
    const { landing: { activeDomain }} = getState();
    const { api, apiRoutes } = apiHandler;
    try {
      let {data} = await api(apiRoutes.createGiftSub(activeDomain,payload));
      isEdit == false && dispatch(setAppMessage({ message: data.message, header: 'Success', type: 'alert' }))
      return Promise.resolve(data);
    } catch (err) {
      dispatch(setAppMessage({ message: err.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}

export function removeGiftToSubscriber(payload,isEdit=false){
  return async (dispatch, getState, apiHandler) => {
    const { landing: { activeDomain }} = getState();
    const { api, apiRoutes } = apiHandler;
    try {
      let {data} = await api(apiRoutes.removeGiftSub(activeDomain,payload));
      isEdit == false && dispatch(setAppMessage({ message: data.message, header: 'Success', type: 'alert' }))
      return Promise.resolve(data);
    } catch (err) {
      dispatch(setAppMessage({ message: err.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}
