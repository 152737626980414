import setAppMessage from "../../app-component/app-component-action";
import { LogglyTracker } from 'loggly-jslogger';

const logger = new LogglyTracker();
logger.push({ 'logglyKey': '142d21f5-10d3-47a9-aa5a-f83e0a3abed7', 'sendConsoleErrors' : true, 'tag' : 'ardbeg,article-action' });

export function fetchFeed(feedType, headerType = '',Component) {
  return async (dispatch, getState, apiHandler) => {
    const { api, apiRoutes ,actionTypes} = apiHandler;
    
    const { landing :{activeDomain, userProfile} ,article :{page,perPage,hasMoreData}} = getState();

    if(!hasMoreData) {
      return Promise.resolve()
    }
   
    try {
      dispatch({type:actionTypes.FETCH.REQUEST})
      let { data: ArticleFeed } = await api(apiRoutes.fetchArticles(activeDomain, feedType, headerType,page+1,perPage));
      dispatch({type:actionTypes.FETCH.SUCCESS,data:{ArticleFeed :ArticleFeed.result,nextPage : page+1}})
      return Promise.resolve(ArticleFeed);
    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      dispatch({type:actionTypes.FETCH.FAILURE})
      dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}))
      return Promise.reject(err);
    }
  }
}

export function fetchRawFeed(feedType, headerType = '',Component) {
  return async (dispatch, getState, apiHandler) => {
    const { api, apiRoutes ,actionTypes} = apiHandler;

    const { landing :{activeDomain, userProfile}} = getState();
    try {
      dispatch({type:actionTypes.FETCH.REQUEST})
      let { data: ArticleFeed } = await api(apiRoutes.fetchArticles(activeDomain, feedType, headerType,1,100));
      dispatch({type:actionTypes.FETCH.SUCCESS,data:{ArticleFeed: ArticleFeed.result}})
      return Promise.resolve(ArticleFeed);
    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      dispatch({type:actionTypes.FETCH.FAILURE})
      dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}))
      return Promise.reject(err);
    }
  }
}

export function resetFeeds (){
  return (dispatch, getState,apiHandler) => {
    const {actionTypes} = apiHandler;
    dispatch({type: actionTypes.FETCH.RESET})
  }
}
export function fetchLiveFeed(feedType, headerType = '',Component) {
  return async (dispatch, getState, apiHandler) => {
    const { api, apiRoutes ,actionTypes} = apiHandler;
    const { landing:{activeDomain, userProfile},article:{page} } = getState();
    try {
      let { data: {result} } = await api(apiRoutes.fetchArticlesApi(activeDomain, feedType, headerType));
      dispatch({type:actionTypes.FETCH.SUCCESS,data:{ArticleFeed :result,nextPage:page}})
      // return Promise.resolve(ArticleFeed);
    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}))
      return Promise.reject(err);
    }
  }
}

export function searchArticleAction(formData) {
  return async(dispatch, getState, apiHandler) => {
    const { landing: { activeDomain, userProfile },article:{page} } = getState();
    try {
      const { api, apiRoutes, actionTypes } = apiHandler;
      const {data:{ result}} = await api(apiRoutes.searchArticleApi(formData, activeDomain));
      dispatch({type:actionTypes.FETCH.SUCCESS,data:{ArticleFeed : result,nextPage:page }})
      return Promise.resolve();
    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      return Promise.reject(err);
    }
  }
}

export function deleteArticle(articleId, status, emailFlag = false) {
  return async (dispatch, getState, apiHandler) => {
    const { landing: {activeDomain, userProfile}} = getState();
    const {api, apiRoutes, actionTypes} = apiHandler;
    try {
      dispatch({type:actionTypes.DELETE.REQUESTED});
      
      if(status === 4) {
        await api(apiRoutes.unpublishArticleApi(activeDomain, articleId, emailFlag));
      }else {
        await api(apiRoutes.deleteArticle(activeDomain, articleId));
      }
      dispatch({type:actionTypes.DELETE.DONE,articleId});
      return Promise.resolve();
    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      dispatch({type:actionTypes.DELETE.DONE});
      return Promise.reject(err);
    } 
  }
}
export function checkWebmasterVerified() {
  return async (dispatch, getState, apiHandler) => {
    const {api, apiRoutes} = apiHandler;
    const {activeDomain, userProfile} = getState().landing;
    try {
      const {data} = await api(apiRoutes.checkWebmasterVerified(activeDomain));
      return Promise.resolve(data.result);
    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      dispatch(setAppMessage({ message: (err.data && err.data.error)|| 'Failed to fetch webmaster info', header: 'Error', type: 'alert' }));
      return Promise.reject(err);
    }
  }
}

export function showMessgage(msg){
  return async (dispatch, getState)=>{
    const {userProfile} = getState().landing;
    const data = {
        'error': msg,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
    dispatch(setAppMessage({message:msg,header :'Error',type:'alert'}))
  }

}

export function  getSubStatus (){
  return async(dispatch,getState,apiHandler)=>{
    const { api, apiRoutes ,actionTypes} = apiHandler;
    const { activeDomain, userProfile } = getState().landing;
    try{
      let {data } = await api(apiRoutes.getSubscriptionStatus(activeDomain));
      dispatch({type: 'SUBSCRIPTION_STATUS_STATUS_UPDATE', data, activeDomain});
      dispatch({type:actionTypes.SUBSCRIBE.FETCH.SUCCESS,data,activeDomain})
      return Promise.resolve(data);
    }
    catch(err){
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      dispatch(setAppMessage({ message: err.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}

export  function fetchDomainDetails() {
  return async (dispatch, getState, apiHandler) => {
    const {api, apiRoutes} = apiHandler;
    const { activeDomain, userProfile } = getState().landing;
    try {
    let { data:domainDetail } = await api(apiRoutes.getDomainDetailsApi(activeDomain));
    return Promise.resolve(domainDetail);
    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      dispatch(setAppMessage({ message: err.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}
