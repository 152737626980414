import React, { useState, useEffect, useRef } from "react";
import Dialog from "commons/muiCustomComponents/Dailog";
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from "react-redux";
import Grid from "commons/muiCustomComponents/Grid";
import Button from "commons/muiCustomComponents/Button";
import Box from "commons/muiCustomComponents/Box";
import MobileProvider from "commons/context/MobileProvider";
import Header from "./components/Header";
import Footer from "commons/commonWrapper/Footer";
import {
  saveMediaToPisco,
  getVideoThumbnail,
  createNewArticle,
  fetchArticleMeta,
  updateArticleMeta,
  clearCache,
  sendResetPasswordMail,
  cancelAPI,
  deleteArticle,
} from "./content-action";
import setAppMessage,{uploadMediaAction,abortMediaAlert} from "app-component/app-component-action";
import { LinearLoader, CircularLoader } from "commons/commonWrapper/Loaders";
import { useHistory, useLocation, useParams } from "react-router-dom";
import TextArticleQuickShare from "./TextArticleQuickShare";
import CloseIcon from '@material-ui/icons/Close';
import DraftjsEditor from "./components/DraftjsEditor";
import TextField from '@material-ui/core/TextField';
import WithFileUpload from "commons/hocs/with-file-upload";
import DeleteIcon from '@material-ui/icons/Delete';
import DraftsIcon from '@material-ui/icons/Drafts';
import ThumbnailDialogs from './ThumbnailDialog';
import VideoArticleMetaHome from "./contentMetaHome";
import ImageIcon from '@material-ui/icons/Image';
import AddIcon from '@material-ui/icons/Add';
import clsx from "clsx";
import CheckIcon from '@material-ui/icons/Check';
import CropImageDialog from "./CroppingDialog";
import InputAdornment from '@material-ui/core/InputAdornment';
import VideoArticlePreview from "./VideoArticlePreview";
import UseInfo from "../../commons/CustomComponents/UseInfo";
import InputBox from "commons/muiCustomComponents/InputBox";
import Tabs from '@material-ui/core/Tabs';
import {hideIntercom} from "../../utils/utils";
import VideoPlayer from 'react-video-js-player';
import { LogglyTracker } from 'loggly-jslogger';

const logger = new LogglyTracker();
logger.push({ 'logglyKey': '142d21f5-10d3-47a9-aa5a-f83e0a3abed7', 'sendConsoleErrors' : true, 'tag' : 'ardbeg,content,video-article-home' });


const WithUploadButton = WithFileUpload(Button);


const uploadImage = () => {
  return (
    <Box width={170} style={{
      border: '1px solid #e5e5e5',
      cursor: 'pointer',
      width: '170px !important', height: '90px',
    }} bgcolor="#e5e5e5">
      <Box width="100%" height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <Box position="relative">
          <AddIcon style={{
            position: 'absolute',
            top: '-7px',
            left: '7px',
          }} />
          <ImageIcon />
        </Box>
        <Box component="span" fontSize={12}> UPLOAD IMAGE </Box>
      </Box>
    </Box>
  )
}
const FileUploaderButton = WithFileUpload(uploadImage);

const useStyles = makeStyles(theme => ({
  inputRoot: {
    backgroundColor: theme.palette.pubWhite,
    "&:hover,&:focus": {
      backgroundColor: theme.palette.pubWhite,
    },
  },
  minHeight: {
    minHeight: '100%',
  },
  mobileMargin: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    width:'90%',
  },
  footer: {
    padding: theme.spacing(4),
    background: theme.palette.pubWhite,
    borderTop: '1px solid #e5e5e5',
    width: '100%',
    height: '100%',
  },
  bodyContainer: {
    position: 'relative',
    top: '75px',
  },
  cardDesgin: {
    border: '1px solid #e5e5e5',
    width: '170px !important',
    position: 'relative',
    height: '90px',
    borderRadius: theme.spacing(1),
    marginLeft: theme.spacing(3),
  },
  slected: {
    opacity: '0.2',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  unslected: {
    opacity: '0.7',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  checkIconClass: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    fontSize: theme.spacing(11),
    color: theme.palette.pubWhite,
  },
  mediaClass: {
    backgroundColor: 'grey',
  },
  thumbnailContainer: {
    display: 'inline-flex',
    // overflow:'scroll',
  },
  wrapperClass :{
    overflowX: 'scroll',
    flex: '1 1 auto',
    display: 'inline-block',
    position: 'relative',
    whiteSpace: 'nowrap',
    width:'100%',
    "&:-webkit-scrollbar": {
      display: 'none',
      width:'0px',
    },
    msOverflowStyle:'none',
  },
}));



function VideoHomePage() {
  const classes = useStyles();
  const history = useHistory();
  const { articleId } = useParams();
  const dispatch = useDispatch();
  const [showSelectDialog, setShowSelectDialog] = useState(true);
  const isMobile = React.useContext(MobileProvider);
  const [content, setContent] = useState({});
  const [wordCount, setWordCount] = useState(0);
  const [lastUpdate, setLastUpdate] = useState((new Date()).getTime());
  const [activeArticleId, setActiveArticleId] = useState(articleId);
  const [metadata, setMetadata] = useState({});
  const [status, setStatus] = useState(0);
  const [headerType, setHeaderType] = useState(null);
  const [title, setTitle] = useState('');
  const [errTitle, setErrTitle] = useState(false);
  const [file, setFile] = useState(null);
  const [fileURL, setFileURL] = useState(null);
  const [requesting, setRequesting] = useState(false);
  const [creatingArticle, SetCreatingArticle] = useState(false);
  const [savingMeta, setSavingMeta] = useState(false);
  const [delteVideo, setDeletingvideo] = useState(false);
  const [showMetaDialog, setShowMetaDialog] = useState(false);
  const { landing, header: { domainList } } = useSelector(state => state);
  const { activeDomain, userPermissions, userProfile } = landing;
  const [isUpLoadingThumbnail, setUploadingThumbnail] = useState(false);
  const [tileData, setTileData] = useState([1, 2, 3, 4]);
  const [thumb, setThumb] = useState(false);
  const [openThumbDialog, setThumbnailDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [thumbnailContent, setthumbnailContent] = useState(null);
  const [filename, setFileName] = useState(null);
  const [fetchingVideoArticle ,setFetchingVideoArticle] = useState(false);
  const [showPreviewDialog, setShowPreviewDialog] = useState(false);
  const [thumbURL ,setThumbURL] = useState(null);
  const [defaultThumb ,setDeafaultThumb] = useState(false);
  const [thumbIndex ,setThumbIndex] = useState(false);
  const [thumbMsg , setthumbMsg] = useState(false);
  const [sectionID, setSectionID] = useState(null);
  const [subSectionID, setSubSectionID] = useState(null);
  const [subsubsectionID, setSubSubSectionID] = useState(null);
  const [sectionDefaultvalue, setSectionDefaultVlaue] = useState(null);
  const [subSectionDefaultvalue, setSubSectionDefaultVlaue] = useState(null);
  const [subSubSectionDefaultvalue, setSubSubSectionDefaultVlaue] = useState(null);
  const [ savingTitle, setSavingTitle ] = useState(false);
  const [thumbURLArray ,setThumbURLArray] = useState([]);

  const domainInfo = domainList[activeDomain] && domainList[activeDomain][0];
  const [cashedContent ,setcashedContent] = useState(null);

  const childRef = useRef();


  useEffect(()=>{
    return hideIntercom();
  },[])

  useEffect(() => {
    if (articleId === 'new') {
      createArticle();
    } else {
      fetchArticleInfo();
    }
    
  }, [articleId]);

  const fetchArticleInfo = async () => {
    try {
      setFetchingVideoArticle(true);
      const {result:metaData} =  await 
        dispatch(fetchArticleMeta(articleId));
      
      if(metaData.headerType && metaData.headerType.indexOf('video') === -1) {
        dispatch(setAppMessage({ message: `Cannot be edited as video Article.`, header: 'Error', type: 'alert' }));
        history.push("/dash/create");
        return;
      }
      metaData.headerContent = JSON.parse(metaData.headerContent);
      
      setMetadata(metaData);
      setTitle(metaData.title);
      setFileURL(metaData.hlsUrl);
      if (metaData.thumbnail){
        setTileData([metaData.thumbnail,2,3,4,5]);
        setSelectedItem(0);
      }

      

      setHeaderType(metaData.headerType);
      setContent(metaData.richDescription);
      setcashedContent(metaData.richDescription)
      setRequesting(false);
      setFetchingVideoArticle(false);
    }catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      const { message } = err;
      setFetchingVideoArticle(false);
      dispatch(setAppMessage({ message: message || 'Unable to fetch article.', header: 'Error', type: 'alert' }));
      history.push('/dash/create');
    }
  }
  const createArticle = async () => {
    try {
      SetCreatingArticle(true)
      const { result: { metadata: { articleId } } } = await dispatch(createNewArticle());
      await dispatch(updateArticleMeta(articleId, { headerType: "video/mp4", title: '' }));
      setHeaderType('video/mp4');
      SetCreatingArticle(false)
      history.push(`/dash/create/video/${articleId}`);
    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      SetCreatingArticle(false)
      const { message } = err;
      dispatch(setAppMessage({ message: message || 'Unable to process article.', header: 'Error', type: 'alert' }));
      history.push('/dash/create/');
    }
  }

  const discardMedia = async () => {
    try {
      dispatch(abortMediaAlert(async ()=>{
        if(articleId){
          try {
          setSavingTitle(true);
          await dispatch(updateArticleMeta(articleId, {
            "richDescription": { html: cashedContent.html,
              objectMap: typeof cashedContent.objectMap === 'string' ? cashedContent.objectMap : JSON.stringify(cashedContent.objectMap),
            } }));
          setSavingTitle(false);
          setShowSelectDialog(false);
          history.push('/dash/create');
          }
          catch(err){
            const data = {
              'error': err,
              'email': (userProfile||{}).email || '',
              'url': (window.location||{}).href || '',
              'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
              'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
            };
            logger.push({
                'type': 'error',
                'data': data,
              });
            setSavingTitle(false);
            dispatch(setAppMessage({ message: `Something went wrong , Please click again on cross button`, header: 'Error', type: 'alert' }));
          }
        }
        else{
          setShowSelectDialog(false);
          history.push('/dash/create');
        }
        
      }))
    }
    catch(e){
      const data = {
        'error': e,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      console.log("e",e)
    } 
  }

  const handleMediaUpload = async (file, cb) => {
    const format = file.type.split('/')[0];
    if (format !== "image") return alert("Invalid File Format.");
    try {
      let res = await dispatch(saveMediaToPisco(file));
      const { localUploadResponse: { cdn } } = res;
      cb(null, cdn);
    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      const { message } = err;
      dispatch(setAppMessage({ message: message || 'Unable to upload media.', header: 'Error', type: 'alert' }));
      cb(err);
    }
  }


  // update the article content at backend
  const updateHtmlContent = async (html, blockMap, count) => {
    try {
      setContent({ html, blockMap });
      await dispatch(updateArticleMeta(articleId, { "richDescription": { html: html, objectMap: JSON.stringify(blockMap) } }));
      metadata.richDescription = { html: html, objectMap:JSON.stringify(blockMap) };
      // await dispatch(updateArticleContent(articleId, html, blockMap)); 
    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      dispatch(setAppMessage({ message: `Unable to update descripition.`, header: 'Error', type: 'alert' }));
    }
  }
  const handleTitle = (event) => {
    if (event.target.title.length <= 150) {
      setTitle(event.target.value);
      if (event.target.value) {
        setErrTitle(false)
      }
      else {
        setErrTitle(true)
      }
    }
  }

  const uploadMediaContent = async (target, type) => {
    const allowed = ['mp4','mpeg','wmv','m4v','3gp','mkv','avi','mov','quicktime','x-ms-wmv','x-matroska','3gpp'];
    const file = target && target.files[0];
    let extension = file && file.type.split("/")[1];
    const { size } = file;
   
    if(!extension){
      extension = file.name && file.name.split('.').pop()
    }
    
    setHeaderType(file.type)
    const sizeInMb = size / 1000000;
    if (sizeInMb > 1024 *10) {
      dispatch(setAppMessage({ message: 'Video larger than 10GB not allowed.', header: 'Error', type: 'alert' }));
      
      return;
    }
    if (allowed.indexOf(extension) < 0) {
      dispatch(setAppMessage({ message: 'Unsupported media format.', header: 'Error', type: 'alert' }));
    } else {
      setFile(file);

      try {
      setRequesting(true);
      
      const data=  await dispatch(uploadMediaAction(file,{
        filename: file && file.name,
        filetype: extension && extension,
        type : 'post',
        domain_id : activeDomain,
        post_id : parseInt(articleId,10),
        uniqueId : parseInt(articleId,10),
       },'video'));
       
      //  setthumbMsg(true)
      let thumbnailResponse = data.thumbnailUrls;
      thumbnailResponse.length >0 && thumbnailResponse.forEach((url)=>{ 
        tileData.unshift(url);
        if (selectedItem !== null) {
          setSelectedItem(selectedItem + 1)
        }
        if (isNumber(tileData)) {
          tileData.pop()
        }
        
        setTileData([...tileData]);
        
      });

      thumbnailResponse.length >0 && setThumbURLArray(thumbnailResponse)
      
      metadata.headerContent =  {
        cdn: data.uploadedURL,
      };


      metadata.hlsUrl = data.hlsUrl;
      setMetadata({
        ...metadata,
      });
      
    
      const dataObj = {
        title,
        thumbnail: (tileData && tileData[selectedItem])|| '',
        headerType: headerType ||'video/mp4',
        headerContent: JSON.stringify(metadata.headerContent),
        hlsUrl : data.hlsUrl || '',
      };
      dispatch(updateArticleMeta(articleId, dataObj));
      setFileURL(data.hlsUrl);
      setRequesting(false);
    }
    catch(err){
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      console.log("err",err)
      dispatch(setAppMessage({ message: err.message || 'Unable to save video.', header: 'Error', type: 'alert' }));
      setRequesting(false);
    }
      // uploadMediaToPisco(file);
    }
  }

  const goToNext = () => {
    if (!fileURL) return dispatch(setAppMessage({ message: 'video is required.', header: 'Error', type: 'alert' }));
    if (!title) return setErrTitle(true)
    setErrTitle(false);
    setShowMetaDialog(true);
  }
  const saveDraft = async () => {
    setSavingMeta(true);
    let tempArticleId = articleId;
    try {
      const data = {
        title,
        thumbnail: tileData[selectedItem],
        headerType: headerType ||'video/mp4',
        headerContent:  JSON.stringify(metadata.headerContent ||{}),
        hlsUrl : metadata.hlsUrl || '',
      };
      await dispatch(updateArticleMeta(tempArticleId, data));
      setSavingMeta(false);
      history.push("/dash/create");
    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      setSavingMeta(false);
      const { message } = err;
      dispatch(setAppMessage({ message: message || 'Unable to save video article.', header: 'Error', type: 'alert' }));
      // handle error
    }
  }
  async function handleDeleteArticle(status) {
    try {
      setDeletingvideo(true);
      await dispatch(deleteArticle(articleId,  metadata.status ||0 ));
      setDeletingvideo(false);
      setShowSelectDialog(true);
      history.push('/dash/create');
    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      setDeletingvideo(false);
      dispatch(setAppMessage({ message: err.message || 'unable to delete video article', header: 'Error', type: 'alert' }));
    }
  }

  const handleFileUpload = (target, type) => {
    const allowed = ['jpg', 'jpeg','png','bmp'];
    const file = target && target.files[0];
    const uploadType = type && type.split("/")[0];
    const extension = file && file.type.split("/")[1];
    if (allowed.indexOf(extension) < 0) {
      dispatch(setAppMessage({ message: 'Unsupported media format.', header: 'Error', type: 'alert' }));
      return;
    }
    const { size } = file;
    const sizeInMb = size / 1000000;
    if (sizeInMb > 1024) {
      dispatch(setAppMessage({ message: 'image larger than 1GB not allowed.', header: 'Error', type: 'alert' }));

      return;
    }
    if (file && type && !(file.type.indexOf(uploadType) > -1)) {
      //this.props.InvalidFileError();
      return;
    }
    if (uploadType === 'image' && extension === 'webp') {
      //this.props.InvalidFileError('Webp images are not allowed, as not supported by Safari browser.');
      return;
    }
    if (!file || !file.name) {
      return;
    }
    const fr = new FileReader();
    setFileName(file.name)
    fr.onload = (stream) => {
      setthumbnailContent(stream.target.result);
      setThumbnailDialog(true);
    }
    fr.readAsDataURL(file);


  }

  const IsUploadImage = () => {
    return (
      <Box width={170} style={{
        border: '1px solid #e5e5e5',
        width: '170px !important', height: '90px',
      }} bgcolor="#e5e5e5">
        <Box width="100%" height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <CircularLoader height="auto" size={20} message="uploading ..." />
        </Box>
      </Box>
    )
  }

  const setSelected = (item) => {
   
    if( thumbURLArray.includes(tileData[item])){
      setFileName(GetimageName(tileData[item]));
      setThumbIndex(item);
      setDeafaultThumb(true);
      setthumbnailContent(tileData[item]);
      setThumbnailDialog(true);
    }
    else {
      if (item === selectedItem) {
        setSelectedItem(null);
        return;
      }
      setSelectedItem(item);
    }
    
  }

  const removeVideo = () => {
    setFileURL(null);
  }
  const goToBack = ()=>{
    setShowSelectDialog(true);
    setShowMetaDialog(false);
    history.push(`/dash/create/video/${articleId}`);
  }
  
  const openPreview = ()=>{
    setShowPreviewDialog(true);
  }
  const closePreview= ()=>{
    setShowPreviewDialog(false);
  }

  const deleteArticlePopup = ()=>{
    dispatch(setAppMessage({ message: '',
    leftButtonText:'Close', 
    rightButtonText:'Delete', 
    handleSuccess: handleDeleteArticle, header: 'Are you sure you want to delete video post ?' }));      
  }

  return (
    <>
      <CropImageDialog
        ref={childRef}
        open={openThumbDialog}
        setThumbnailDialog={setThumbnailDialog}
        thumbnailContent={thumbnailContent}
        setTileData={setTileData}
        tileData={tileData}
        name={filename}
        defaultThumb = {defaultThumb}
        thumbIndex = {thumbIndex}
        setThumbURL ={setThumbURL}
        setThumbURLArray = {setThumbURLArray}
        thumbURLArray = {thumbURLArray}
        setDeafaultThumb ={setDeafaultThumb}
        selectedItem = {selectedItem}
        setSelectedItem = {setSelectedItem}
      />
      <VideoArticlePreview
        open={showPreviewDialog}
        metadata={metadata}
        title={title}
        status={status}
        closePreview ={closePreview}
        description={metadata.description}  
      />
      <Dialog
        fullScreen
        open={showSelectDialog} >
          { showMetaDialog &&
        <VideoArticleMetaHome
          openPreview = {openPreview}
          open={showMetaDialog}
          metadata={metadata}
          domainInfo={domainInfo}
          articleId={articleId}
          title={title}
          goToBack={goToBack}
          handleDeleteArticle ={handleDeleteArticle}
          headerType={headerType}
          thumbnail={tileData[selectedItem]}
          deleteLoader = {delteVideo}
          
          sectionID = {sectionID}
          setSectionID = {setSectionID}
          subSectionID ={subSectionID}
          setSubSectionID = {setSubSectionID}
          subsubsectionID = {subsubsectionID}
          setSubSubSectionID = {setSubSubSectionID}
          sectionDefaultvalue = {sectionDefaultvalue}
          setSectionDefaultVlaue = {setSectionDefaultVlaue}
          subSectionDefaultvalue = {subSectionDefaultvalue}
          setSubSectionDefaultVlaue = {setSubSectionDefaultVlaue}
          subSubSectionDefaultvalue = {subSubSectionDefaultvalue}
          setSubSubSectionDefaultVlaue = {setSubSubSectionDefaultVlaue}
        />
        }
        <Grid container className={classes.minHeight}>
          {/**----------------------------Header Section---------------------------- */}
          <Header position="fixed" top={0}>
            <Grid container justify="center">
              {<Grid item xs={12} md={12}>
                <Box display="flex" alignItems="center" justifyContent="space-between" ml={isMobile ? 4 : 25} mr={isMobile ? 4 : 25} >
                  <Box display="flex" alignItems="center" height="100%">
                    <CloseIcon style={{ color: 'white' }} onClick={discardMedia} />
                  </Box>
                  
                  <Box component="p" display="flex" justifyContent="center">VIDEO</Box>
                  <Box component="p" >Step 1 of 2</Box>
                </Box>
              </Grid>
              }
            </Grid>
          </Header>
          {(savingTitle) && <LinearLoader position="sticky" top={`${isMobile ? '70px': '75px'}`} width="100%"/>}
          {/**----------------------------End Header Section---------------------------- */}
          {creatingArticle || fetchingVideoArticle ?
            <Grid container justify="center" className={classes.bodyContainer}>
              <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
                <Box width="100%" height="100%">
                  <CircularLoader height="auto" message={fetchingVideoArticle ? `fetching video post ...`:`creating video post ...`} />
                </Box>
              </Box>
            </Grid>
            :
            <Grid container justify={isMobile ? "unset":"center"} className={classes.bodyContainer}>
              {/* <Grid container justify="center" alignItems="center"  className={classes.imgContainer}> */}
              <Grid item xs={12} md={8} className={isMobile ? classes.mobileMargin : {}}>

                <Box mt={6}>
                  <Box component="span" fontWeight="bold" fontSize={14}>{
                    requesting ?
                      'Uploading Video'
                      :
                      fileURL ? 'Video Uploaded' :
                        'Upload Video '}</Box>
                  <Box component="span" 
                  fontSize={12}>&nbsp;{requesting ? `${file && file.name}. Please wait` 
                  : fileURL ? '' : '(mp4,mpeg,wmv,m4v,3gp,mkv,avi and mov only, max file size 10GB)'}</Box>

                </Box>
                <Box mt={3}>
                  <Box position="relative" width={279} height={152} bgcolor="pubSecondary" display="flex" justifyContent="center" alignItems="center">
                    {
                      requesting ?
                        <>
                          <Box position="absolute" top={3} right={3} display="flex">
                            
                            {thumbMsg && "generating thumbnail ... "}
                            <CircularLoader height={"auto"} size={20}  />

                          </Box>
                          {/* <Button variant="contained" color="primary" onClick={() => { cancelAPI(); }}>CANCEL</Button> */}
                        </>
                        :
                        fileURL ? <Box display="flex" width="100%" height="100%">
                          <VideoPlayer src={fileURL} width="279" height="152" controls>
                          </VideoPlayer>
                          <DeleteIcon onClick={removeVideo} />
                        </Box> :
                          <WithUploadButton
                            color="primary"
                            variant="contained"
                            type="*" onChange={uploadMediaContent}
                          >
                            BROWSE
                   </WithUploadButton>
                    }


                  </Box>

                </Box>
                <Box mt={6}>
                  <Box component="span" fontWeight="bold" fontSize={14} display="flex" alignItems="center">
                    Thumbnail Image (jpg, png,bmp only)
                    <UseInfo info="Select and crop an image that shows what’s in your video. Recommended size 800x420." />
                  </Box>
                </Box>
                <Box display="flex" oveflow="hidden" width="100%">
                <Box className={classes.wrapperClass} id="scrollbarhide">
                <Box mt={3} display="flex" className={classes.thumbnailContainer}>

                  
                    <Box width={170}><FileUploaderButton
                      type="image/*"
                      onChange={handleFileUpload}
                    />
                    </Box>
                  {/* <Tabs indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"> */}
                  {tileData.map((tile, key) => {
                    return <Box key={key} className={clsx({ [classes.cardDesgin]: true })} >
                      {
                        tile && tile.toString().indexOf('http') == 0 ? <>
                          <img src={tile} alt="thumb-img" width="100%"
                            height="100%" style={{ objectFit: 'cover', cursor: 'pointer' }} onClick={() => setSelected(key)} />
                          {selectedItem !== null && selectedItem === key && <Box
                            className={classes.slected} width="100%" height="100%" bgcolor="pubBlack" onClick={() => setSelected(key)}>

                          </Box>}
                          {
                            selectedItem !== null && selectedItem === key && <CheckIcon className={classes.checkIconClass} onClick={() => setSelected(key)} />
                          }
                          {selectedItem !== null && selectedItem !== key &&
                            <Box width="100%" height="100%" bgcolor="pubWhite" className={classes.unslected} onClick={() => setSelected(key)} />}
                        </>
                          :
                          <Box width="100%" height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                            <ImageIcon style={{ opacity: '0.7' }} />
                          </Box>

                      }
                    </Box>

                  })}
                  {/* </Tabs> */}
                </Box>
                </Box>
                </Box>
                <Box mt={6}>
                  <InputBox
                    id="videotitle"
                    heading="Title*"
                    helperText={errTitle ? "Title can't empty" : ''}
                    onChange={handleTitle}
                    error={errTitle}
                    value={title || ''}
                    variant="standard"
                    placeholder="Enter Title Here"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {`${(title.length) || 0}/150`}
                        </InputAdornment>
                      ),
                    }}
                    headingStyle={{
                      component:"span",
                      fontWeight:"bold",
                      fontSize:14,
                      mb: 1,
                    }}
                    className={{ root: classes.inputRoot }}
                    fullWidth 
                    
                    />
                    
                    </Box>
                <Box mt={5} >

                  <DraftjsEditor
                    objectMap={content.objectMap}
                    htmlContent={content.html}
                    updateWordCount={(count) => setWordCount(count)}
                    lastUpdate={lastUpdate}
                    isMobile={isMobile}
                    wordCount={wordCount}
                    bodytitle={'Description'}
                    DraftPlaceholder = {"Write your description."}
                    handleMediaUpload={handleMediaUpload}
                    updateApi={updateHtmlContent} />

                </Box>
              </Grid>
            </Grid>

          }
          {/**----------------------------Footer Section-------------------------------------*/}
          <Grid item xs={12} md={12}>
            <Footer>
              <Box width="100%"

                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%">
                <Grid item md={8} xs={12} className={isMobile ? classes.mobileMargin : {}}>
                  <Box display="flex" justifyContent={metadata.status === 4?"flex-end":"space-between"}>
                    { metadata.status !== 4 && 
                    <Box display="flex">
                      <Box display="flex">
                      <Button
                        color="secondary"
                        variant="contained"
                        disabled={creatingArticle || delteVideo || requesting}
                        onClick={() => saveDraft()}>
                        {isMobile ? <DraftsIcon /> : 'Save Draft'}
                      </Button>
                      {savingMeta && <CircularLoader size={20} height="auto"/>}
                      </Box>

                      <Box display="flex" alignItems="center">
                        <Button variant="contained" disabled={creatingArticle || delteVideo || requesting} boxProps={{ ml: 3 }} color="secondary"
                          onClick={deleteArticlePopup}
                        >
                          <DeleteIcon />

                        </Button>
                        {delteVideo && <CircularLoader ml={4} size={20} height="auto" />}
                      </Box>
                    </Box>
                    }
                    <Box display="flex">
                      {/* <Button variant="contained"  color="secondary" onClick={openThumbnailDialoghandler}>
                      SET THUMBNAIL IMAGE
                    </Button> */}
                      <Button
                        variant="contained"
                        disabled={creatingArticle || delteVideo || requesting}
                        boxProps={{ ml: 4 }}
                        onClick={() => goToNext()}
                      >Next</Button>
                    </Box>
                  </Box>
                </Grid>
              </Box>
            </Footer>
          </Grid>
          {/**----------------------------End Footer Section-------------------------------------*/}
        </Grid>
      </Dialog>
    </>
  )
}
export default VideoHomePage;

function isNumber(arr) {
  let res = false;
  arr.forEach((item) => {
    if (typeof item === 'number') {
      res = true;
      return;
    }
  })
  return res;
}

function GetimageName(url)
{
   if (url)
   {
      const extension = url.split('.').reverse()
      let m = url.toString().match(/.*\/(.+?)\./);
      if (m && m.length > 1)
      {
         return m[1]+'.'+extension[0];
      }
   }
   return "";
}
