import React,{useCallback,useContext} from 'react';
import FullScreenPage from "commons/commonWrapper/FullScreenPage"; 
import Box from "commons/muiCustomComponents/Box";
import UserListHome from "../components/user-management/UserListHome";
import MobileProvider from "commons/context/MobileProvider";


function UserListPage (props) {
  const isMobile = useContext(MobileProvider);

  const barCallback = useCallback(() =>(<Box 
    display="flex"
    alignItems="center"
    color={isMobile ?'pubWhite':'pubBlack'}
    >Users List</Box>),[]);
  return(
    <FullScreenPage BarComponent={barCallback} isDesktop={false} isCloseIcon={true}>
          {(appBarHeight) => <Box mt={isMobile ?0:6}><UserListHome appbarheight={appBarHeight} /> </Box>}
  </FullScreenPage>
  );
}

export default UserListPage;