
import React,{useCallback,useContext} from "react";
import NewsletterHome from "components/social/Newsletter/Newsletter";
import FullScreenPage from '../commons/commonWrapper/FullScreenPage';
import Box from "../commons/muiCustomComponents/Box";
import MobileProvider from "commons/context/MobileProvider";


export default function NewsletterPage (){

    const isMobile = useContext(MobileProvider);

    const barCallback = useCallback(() =>(<Box 
        display="flex"
        alignItems="center"
        component="span"
        >Newsletter</Box>),[]);
        
    return(
        <FullScreenPage BarComponent={barCallback} isDesktop={false} isCloseIcon={true}>
            <Box mt={isMobile ?0:6}><NewsletterHome/> </Box>
        </FullScreenPage>
    )
}
