
import MONETIZATION from "../../constants/actions/monetization";

const monetizationDefault = {
  ads: {
  },
  subscribe: {
    
  },
  connectedStatus: {

  },
  defaultTab : 0,
}

export default function Earn(state = monetizationDefault, action) {
  const { ADS, SUBSCRIBE } = MONETIZATION;
  switch (action.type) {
    case ADS.FETCH.SUCCESS:
      state.ads = {
        ...state.ads,
        ...action.data,
      }
      return { ...state }

    case ADS.SET.SUCCESS:
      state.ads = {
        ...state.ads,
        ...action.data,
      }
      return { ...state }
    case "SUBSCRIPTION_STATUS_STATUS_UPDATE": {
      return {
        ...state,
        connectedStatus: action.data,
      }
    }
    case SUBSCRIBE.FETCH.SUCCESS:
      state.subscribe[action.activeDomain] = {
      };
      state.subscribe[action.activeDomain] = {
        ...state.subscribe[action.activeDomain],
        ...action.data,
      }
      return { ...state }
    case SUBSCRIBE.SET.SUCCESS:
      
      state.subscribe[action.activeDomain] = {
        ...state.subscribe[action.activeDomain],
        ...action.data,
      }
      return { ...state }

    case SUBSCRIBE.SET_TAB:{
      state['defaultTab'] = action.data;
      return { ...state }
    }

    default:
      return state;
  }
}