import React from "react";
import {useHistory} from "react-router-dom";
import Box from "commons/muiCustomComponents/Box";
import Button from "../commons/muiCustomComponents/Button";

export default function PageNotFound (props) {
    
    const history  = useHistory();

    const handleHomeButton = () => {
        history.push("/");
    }
    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="calc(100vh - 80px)">
        <p>Page Not Found</p>
        <Button onClick={handleHomeButton}>Go To Home</Button>
        </Box>
    )
}