
/**
 * All constatnt of EARN section
 */

const MONETIZATION = {
  ADS: {
    FETCH: {
      REQUEST: "AdsFetchRequest",
      FAILURE: "AdsFetchFailure",
      SUCCESS: "AdsFetchSuccess",
    },
    SET :{
      REQUEST: "AdsSetRequest",
      FAILURE: "AdsSetFailure",
      SUCCESS: "AdsSetSuccess",
    },
  },
  SUBSCRIBE: {
    FETCH: {
      REQUEST: "SubscribeFetchRequest",
      FAILURE: "SubscribeFetchFailure",
      SUCCESS: "SubscribeFetchSuccess",
    },
    SET :{
      REQUEST: "SubscribeSetRequest",
      FAILURE: "SubscribeSetFailure",
      SUCCESS: "SubscribeSetSuccess",
    },
    SET_TAB :{
      DEFAULT :'SubscribeSetTab',
    },
  },
  PAYOUT: {
    FETCH: {
      REQUEST: "PayoutFetchRequest",
      FAILURE: "PayoutFetchFailure",
      SUCCESS: "PayoutFetchSuccess",
    },
    GENERATE_OTP :{
      REQUEST: "GenerateFetchRequest",
      FAILURE: "GenerateFetchFailure",
      SUCCESS: "GenerateFetchSuccess",
    },
    VERIFY_OTP :{
      REQUEST: "payoutFetchRequest",
      FAILURE: "payoutFetchFailure",
      SUCCESS: "payoutFetchSuccess",
    },
  },
}

export default MONETIZATION;
