
import articleAction from "../../constants/actions/article";

const articleDefaultState = {
  isDeletingArticle:false,
  articleFeed : null,
  page :0,
  perPage :30,
  hasMoreData : true,
  isLoadingArticle : false,
}

/**
 *   getUniqueArticle 
 */

function getUniqueArticle(array, keyName) {
  const tempObj = {};
  const finalArr = [];
  for(let i=0;i<array.length;i++) {
   // eslint-disable-next-line no-prototype-builtins
   if(tempObj.hasOwnProperty(array[i][keyName])) continue;
   tempObj[array[i][keyName]] = true;
   finalArr.push(array[i])
  }
  return finalArr;
}


export default function article(state = articleDefaultState,action){
    
    switch(action.type) {
        case articleAction.FETCH.REQUEST:{
          return {
            ...state,
            isLoadingArticle: true,
          }
        }
        case articleAction.FETCH.SUCCESS:{
          if (state.articleFeed){
            let bufferArticle = state.articleFeed.concat((action.data && action.data.ArticleFeed)|| [])
            state.articleFeed = getUniqueArticle (bufferArticle,'articleId')
          }
          else {
            state.articleFeed =action.data.ArticleFeed;
          }
          return {
            ...state,
            isLoadingArticle : false,
            page: (action.data && action.data.nextPage) || 0,
            hasMoreData: action.data && action.data.ArticleFeed.length === 30,
          }
        }
        case articleAction.FETCH.FAILURE:{
          return {
            ...state,
            isLoadingArticle: false,
          }
        }
        case articleAction.FETCH.RESET:
          return {
            ...state,
            articleFeed: null,
            page :0,
            perPage :30,
            hasMoreData : true,
          }
        case articleAction.DELETE.REQUESTED:{
          return {
            ...state,
            isDeletingArticle: true,
          }
        }
        case articleAction.DELETE.DONE: {
          const {articleId} = action;
          if (articleId && state.articleFeed) {
            state.articleFeed = state.articleFeed.filter((item) => (
              item.articleId !== articleId
            ));
          }
          return {
            ...state,
            isDeletingArticle:false,
          }
        }
        default:
          return state;
      }
}

export function getUniques(arr) {
  return arr.filter((value, index, self) => self.indexOf(value) === index);
}




