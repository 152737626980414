import monetization from "../../constants/actions/monetization";

const intialState = {
  domainList: [],
  isChangingDomain:false,
};
function headerReducer(state=intialState, action) {
  switch(action.type) {
    case "DomainListFethSuccess": {
      return {
        ...state,
        domainList: action.domainList,
      }
    }
    case "ChangingActiveDomain": {
      return {
        ...state,
        isChangingDomain: action.value,
      }
    }
    
    case 'ClearDomainList': {
      return {
        ...state,
        domainList:[],
      }
    }
    case 'UpdateDomainList':{
      return {
        ...state,
        domainList: action.domainList,
      }
    }
    default: 
      return state;
  }
}

export default headerReducer;