import React, {useState, useContext} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Box from "../../commons/muiCustomComponents/Box";
import { makeStyles } from '@material-ui/core/styles';
import Input from "../../commons/muiCustomComponents/Input";
import Button from "../../commons/muiCustomComponents/Button";
import { resetPassword, sendPassResetOtp,forgotPassword } from "./auth-action";
import Divider from '@material-ui/core/Divider';
import setAppMessage from "app-component/app-component-action";
import { LinearLoader} from "commons/commonWrapper/Loaders";
import MobileProvider from "commons/context/MobileProvider";
import { LogglyTracker } from 'loggly-jslogger';

const logger = new LogglyTracker();
logger.push({ 'logglyKey': '142d21f5-10d3-47a9-aa5a-f83e0a3abed7', 'sendConsoleErrors' : true, 'tag' : 'ardbeg,auth,forget-password-home' });

const useStyles = makeStyles(theme => ({
  containerBox: {
    maxWidth:'400px',
    margin:"auto",
    height:"80vh",
  },
  signup: {
    position:"relative",
    top:'10%',
  },
  headerBarMobile: {
    width:'100%',
    height:theme.spacing(20),
    backgroundColor:theme.palette.pubBlack,
    color:theme.palette.pubWhite,
  },
  headerBarDesktop : {
    width:'100%',
    height:theme.spacing(20),
    borderBottom: `1px solid ${theme.palette.pubbrownGrey}`,
    textAlign:'left',
  },
  headingTextDesktop: {
    fontSize:theme.spacing(6),
    paddingLeft:theme.spacing(8),
  },
  headingTextMobile: {
    fontSize:theme.spacing(9),
    letterSpacing:theme.spacing(2),
  },
}));

function ForgotPassword (props) {

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useContext(MobileProvider);

  const [requesting, setRequesting] = useState(false);
  const [email, setEmail]  = useState('');
  const [otp, setOtp]  = useState('');
  const [requestToken, setReqestToken] = useState('');
  const [password, setPassword]  = useState('');
  const [confirmPassword, setConfirmPassword]  = useState('');
  const [step, setStep] = useState(0);
  const [isDirty,setIsDirty] = useState(false);
  const {landing: {userProfile}} = useSelector(state => state);
  
  const redirectToLogin = () => {
    history.push("/login");
  }

  const handleForgotPassword = async () => {
    setIsDirty(true);
    if(!email.length) {
      return;
    }
    if(!otp.length) {
      return;
    }
    setRequesting(true);
    try {
      const {data: {requestToken}} = await dispatch(forgotPassword(email, otp));
      console.log("requestToken", requestToken);
      setReqestToken(requestToken);
      setRequesting(false);
      setStep(2);
    } catch (err) {
      //show error message and onOk redirect to
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      dispatch(setAppMessage({message:err.message || 'Unable to login', header:'Error', type:'alert'}));
      setRequesting(false);
    } finally {
      setIsDirty(false);
    }
    
  }
  const handleResetPassword = async () => {
    try {
      setIsDirty(true);
      if(!email.length) {
        return;
      }
      if(!otp.length) {
        return;
      }
      if(password !== confirmPassword) {
        return;
      }
      setRequesting(true);
        await dispatch(resetPassword(password,requestToken));
        dispatch(setAppMessage({message:'Password changed successfully', header:'Success', type:'alert'}));
        setRequesting(false);
        redirectToLogin();
    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      dispatch(setAppMessage({message:err.message || 'Unable to login', header:'Error', type:'alert'}));
      setRequesting(false);
    }
  }
  const sendOtpForPasswordReset = async () => {
    setIsDirty(true);
    try {
      setRequesting(true);
      await dispatch(sendPassResetOtp(email));
      setStep(1);
      setRequesting(false);
    } catch(err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      dispatch(setAppMessage({message:err.message || 'Unable to login', header:'Error', type:'alert'}));
      setRequesting(false);
    } finally {
      setIsDirty(false);
    }
  }
  const handleInputChange = (event) => {
    const { value, name } = event.target;
    if(name === 'email') setEmail(value);
    if(name === 'otp') setOtp(value);
    if(name === 'password') setPassword(value);
    if(name === 'confirm_password') setConfirmPassword(value);
  }

  return (
    <>
      {renderHeader({classes, isMobile})}
      {requesting && <LinearLoader/>}
      <Box className={classes.containerBox}>
        {step === 0 && <>
            <Box ml={6} mr={6} mt={40}>
            <Input 
              type="email" 
              label="Enter email address"
              name="email" onChange={handleInputChange} 
              fullWidth 
              value={email}/>
          </Box>
          <Box ml={6} mr={6} mt={6}>
            <Button fullWidth size="large" variant="contained" onClick={sendOtpForPasswordReset} disabled={requesting}>Continue</Button>
          </Box>
          </>
        }
        { step ===1 && <>
          <Box ml={6} mr={6} mt={40}>
            <Input 
              type="email" 
              label="Email address"
              name="disabled_email" 
              onChange={handleInputChange} 
              fullWidth 
              disabled
              value={email}/>
              <br/><br/>
              <Input 
              type="text" 
              label="Enter otp"
              error={isDirty && !otp.length}
              helperText={(isDirty && !otp.length) ? "* OTP is required." : ""}
              name="otp" 
              onChange={handleInputChange} 
              fullWidth
              value={otp}/>
          </Box>
          <Box ml={6} mr={6} mt={6}>
            <Button fullWidth size="large" variant="contained" onClick={handleForgotPassword} disabled={requesting}>Continue</Button>
          </Box>
        </>

        }
        { step ===2 && <>
          <Box ml={6} mr={6} mt={40}>
            <Input 
              type="password" 
              label="Password"
              error={isDirty && (password !== confirmPassword)}
              helperText={(isDirty && (password !== confirmPassword)) ? "Password does not match." : ""}
              name="password" 
              onChange={handleInputChange} 
              fullWidth
              value={password}/>
              <br/><br/>
              <Input 
              type="password" 
              label="Confirm Password"
              name="confirm_password" 
              onChange={handleInputChange} 
              fullWidth 
              value={confirmPassword}/>
          </Box>
          <Box ml={6} mr={6} mt={6}>
            <Button fullWidth size="large" variant="contained" onClick={handleResetPassword} disabled={requesting}>Continue</Button>
          </Box>
        </>

        }
        <Box  mt={4} ml={4} mr={4} mb={6}>
        </Box>
      </Box>
    </>
  )
}

export default ForgotPassword;


function renderHeader(props) {
  const { isMobile, classes } = props;
  if(isMobile) {
    return (<Box display="flex" alignItems="center" justifyContent="center" className={classes.headerBarMobile}>
      <Box component="p" className={classes.headingTextMobile}>PUBNINJA</Box>
    </Box>)
  }
  return (
    <Box display="flex" alignItems="center" className={classes.headerBarDesktop}>
      <Box component="p" className={classes.headingTextDesktop}>Set a new password</Box>
    </Box>
  )
}