import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import Grid from "commons/muiCustomComponents/Grid";
import Box from "commons/muiCustomComponents/Box";
import Divider from '@material-ui/core/Divider';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Input from "../../commons/muiCustomComponents/Input";

import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';

import Paper from "commons/muiCustomComponents/Paper";
import Button from "commons/muiCustomComponents/Button";
import AddIcon from '@material-ui/icons/Add';
import  {LinearLoader, PageBlockLoader} from '../../commons/commonWrapper/Loaders';
import {getCustomerOverview,getProductsDetails,addGiftToSubscriber,removeGiftToSubscriber} from "./customers-action";
import { useDispatch, useSelector } from 'react-redux';
// import ProdutList from "./ProductList";
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SearchIcon from '@material-ui/icons/Search';
import { download,isValidEmailAddress } from '../../utils/utils';
import SelectGift from './SelectGift';
import { CircularLoader } from "commons/commonWrapper/Loaders";
import setAppMessage from "app-component/app-component-action";

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          return <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
            size="small"
          >
          {headCell.noSorting && headCell.label}
          {!headCell.noSorting &&
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          }
          </TableCell>
        })}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  tableRoot :{
    padding:theme.spacing(2),
  },
}));

const useStylesEnhancedTable = makeStyles((theme) => ({
  textdecoration: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color:'black',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));
const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Type', noSorting: 1 },
  { id: 'fullname', numeric: false, disablePadding: false, label: 'Name', noSorting: 1 },
  { id: 'email', numeric: false, disablePadding: false, label: 'Email', noSorting: 1 },
  { id: 'phone_number', numeric: false, disablePadding: false, label: 'Phone Number', noSorting: 1 },
  { id: 'date_created', numeric: false, disablePadding: false, label: 'Date Joined' },
  { id: 'gift_sub', numeric: false, disablePadding: false, label: 'Gift' ,noSorting: 1},
];
export default function EnhancedTable(props) {
  const classes = useStylesEnhancedTable();
  const [rows,setRows] =  React.useState(props.data.result);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('date_created');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [type, setType] = useState(props.type || 'ALL');
  const [email, setEmail] = useState(props.email||'');
  const [open, setOpen] = React.useState(false);
  const [storeAllProduct ,setStoreAllProduct] = React.useState([]);
  const [isLoading ,setLoading] = React.useState([]);
  const [changeProduct ,setChangeProduct] = React.useState({});
  const [name ,setName] =  React.useState('');
  const [emailAddress ,setEmailAddress] = React.useState('');
  const [isEdit , setEdit] = React.useState(false);
  const [isManagingGift ,setManagingGift] = useState(false);
  const [isLoader,setLoader] = useState(false);
  const [olderProductId ,setOlderProductId] = useState(null);
  const [disableButton ,setDisableButton] = useState(false);
  const [isDirty , setDirty] = useState(false);
  

  const {
    landing: { activeDomain },
  } = useSelector(state =>  state);
  const dispatch = useDispatch();

  useEffect(()=>{
    const getProdcutDetails = async()=>{
      try {
      setLoading(true);
      const result= await dispatch(getProductsDetails());
      setStoreAllProduct(result);
      setLoading(false);
      }
      catch(err){
       setLoading(false);
      }
    }
    getProdcutDetails();
  },[])
  
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    props.onPageChange(newPage+1);
    // setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  const isSelected = (name) => selected.indexOf(name) !== -1;
  
  function onChangeType(e) {
    props.onTypeChange(e.target.value, e.target.value);
  }
  function onChangeProduct (e){
    setChangeProduct(e.target.value)
    setDisableButton(false);
  }

  function handleSetEmail(e) {
    setEmail(e.target.value);
  }
  function getRefinedEmail(email){
    return email.replace(/(_)/g,'\\$1').replace(/(%)/g,'\\$1');
  }
  function callApiSearch() {
    const searchStr = getRefinedEmail(email);
    if (searchStr.length > 0) props.onSearch((props.data.result_info.page||1)-1, searchStr, email);
  }

  function downloadFile () {
    const token = localStorage.getItem('accessToken');
    fetch(`${process.env.REACT_APP_API_SERVER}/insight/${activeDomain}/customer/overview/export` + 
      `?customer_type=${type}${getRefinedEmail(email).length ? `&email=${getRefinedEmail(email)}` : ''}`,
      {
        method: 'GET',
        headers: {
          'Authorization': token,
        },
      })
      .then(response => {
        if (response.status !== 200 ) {
          return Promise.reject('Error in fetching file');
        }
        return response.blob();
      })
      .then(blob => {
        download(blob, 'customers.csv')    
      });
  }

  const handleClose = () => {
    setChangeProduct({});
    setName('');
    setEmailAddress('');
    setOlderProductId(null);
    setChangeProduct({})
    setOpen(false);
    setEdit(false);
    setDisableButton(false);
  };
  const creatingGift = async()=>{
    try {

      if (storeAllProduct.length ===0){
        setDisableButton(true);
        dispatch(setAppMessage({ message: 'All fields are mandatory', type: 'alert', header: 'Error' }))
        return;}
      if(!changeProduct.id) {
        setDisableButton(true);
        dispatch(setAppMessage({ message: 'All fields are mandatory', type: 'alert', header: 'Error' }))
        return;}
      if(!name) {
        setDisableButton(true);
        dispatch(setAppMessage({ message: 'All fields are mandatory', type: 'alert', header: 'Error' }))
        return;}
      if(!emailAddress) {
        setDisableButton(true);
        dispatch(setAppMessage({ message: 'All fields are mandatory', type: 'alert', header: 'Error' }))
        return;}

      setManagingGift(true);
      if (isEdit){
        await dispatch(removeGiftToSubscriber({
          emailIds :[emailAddress],
          product_id :+olderProductId,
         },isEdit)) ;
      }
      await dispatch(addGiftToSubscriber({
        details :[
          {email:emailAddress,name}],
        product_id :+changeProduct.id,
       },isEdit)) ;
      setManagingGift(false);
      handleClose();
      fetchDetail();
    }
    catch(err){
      setManagingGift(false);
    }
  }
 
  const fetchDetail = async()=>{
      try {
        setLoader(true);
        let data = await dispatch(getCustomerOverview({}));
        if (data.result && data.result.length) {
          data.result = data.result.map((obj) => {
            return {
              name: obj.type,
              date_created: obj.date_created,
              email: obj.email,
              fullname: `${obj.firstname || ''} ${obj.lastname || ''}`,
              phone_number: `${obj.phone_number || ''}`,
              giftedProducts : obj.giftedProducts,
            }
          });
        }
        setRows(data.result);
      } catch (err) {
        console.log(err);
      } finally {
        setLoader(false);
      } 
    }  
  
  
  return (
    <>
    <Box className={classes.root}>
      <Grid container>      
        <Grid item xs={12} sm={12} md={12} xl={12} >
          <Grid container>
            <Grid item xs={12} sm={12} md={6} xl={6}>
              <Box display="flex" alignItems="center">
                 <Box maxWidth={150}>
                   <Input
                    size="small"
                    color="secondary"
                    value={email}
                    onChange={handleSetEmail}
                    margin="none"
                    label="email" />
                    </Box>
                    <Box ml={2} mr={2}>
                    <Button  variant="contained" color="secondary"  onClick={callApiSearch}>
                      <SearchIcon/>
                    </Button>
                    </Box>
                    <FormControl className={classes.formControl} size="small">
            {/* <InputLabel id="demo-simple-select-label">Type</InputLabel> */}
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={type}
              onChange={onChangeType}
            >
              <MenuItem value={'all'}>ALL</MenuItem>
              <MenuItem value={'followers'}>FOLLOWERS</MenuItem>
              <MenuItem value={'subscribers'}>SUBSCRIBERS</MenuItem>
            </Select>
          </FormControl>
              </Box>
            </Grid>
            
            
            <Grid item xs={12} sm={12} md={6} xl={6}>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <Box mr={2}>
                <Button  color="secondary" variant="contained" onClick={()=>{setOpen(true)}}>
                  Add gift subscription
                </Button>
                </Box>
              
                <Button color="primary" variant="contained">
            {
              <a onClick={downloadFile}>
                <Box >
                  Export
                </Box>
              </a>
            }
            </Button>
          </Box>
            </Grid>
           
          </Grid>
        </Grid>
        {/* <Grid item xs={12} sm={12} md={4} xl={4} >
          
        </Grid> */}
      </Grid>
      <Box mt={5}><Divider /></Box>
      { isLoader ?
      <Box> Loading customer details....</Box>
      :
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size='medium'
          aria-label="enhanced table"
          classes = {{
            root :classes.tableRoot,
          }}
        >
          {rows.length ? <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          /> : <TableHead size="small"><TableRow><TableCell></TableCell></TableRow></TableHead>}
          <TableBody >
            {rows.length ? stableSort(rows, getComparator(order, orderBy))
                .map((row, index) => {
                const isItemSelected = isSelected(row.name);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    selected={isItemSelected}
                    classes = {{
                      root :classes.tableRoot,
                    }}
                  >
                    <TableCell size="small" component="th" id={labelId} scope="row" padding="none">
                      {row.name}
                    </TableCell>
                    <TableCell size="small" align="left">{row.fullname}</TableCell>
                    <TableCell size="small" align="left">{row.email}</TableCell>
                    <TableCell size="small" align="left">{row.phone_number}</TableCell>
                    <TableCell size="small" align="left">{moment(row.date_created).format('YYYY-MM-DD')}</TableCell>
                    {(row.name =='Follower'||(row.giftedProducts && row.giftedProducts.length >0) ) ?
                    <TableCell size="small" align="left">
                      <Box display="flex" alignItems="center" justifyContent="space-between">
                      <SelectGift 
                      setOpen ={setOpen} 
                      fetchDetail = {fetchDetail}
                      row = {{
                        name :row.name,
                        giftedProducts :  row.giftedProducts,
                       }
                      }
                      name = {row.fullname}
                      emailAddress = {row.email}
                      setName = {setName}
                      setEmailAddress = {setEmailAddress}
                      payload ={
                        {
                          emailIds:[row.email],
                          product_id :row.giftedProducts && row.giftedProducts[0] && row.giftedProducts[0].product_id,
                         }
                      }/>
                    
                     { row.name == "Subscriber" && row.giftedProducts && row.giftedProducts.length> 0 &&
                      <Box style={{cursor:'pointer',textDecoration:'underline'}}
                      onClick={()=>{
                        setName(row.fullname);
                        setEmailAddress(row.email);
                        setChangeProduct(storeAllProduct.find((val)=>val.id==row.giftedProducts[0].product_id))
                        setOlderProductId(row.giftedProducts && row.giftedProducts[0] && row.giftedProducts[0].product_id);
                        setEdit(true);setOpen(true)}}
                      >EditGift</Box>
                      }
                      </Box>
                    </TableCell>
                    : <TableCell size="small" align="left"></TableCell>
                  }
                  </TableRow>
                );
              }) : <TableRow><TableCell></TableCell></TableRow>}
          </TableBody>
        </Table>
      </TableContainer>
      }
      <TablePagination
            rowsPerPageOptions={[50]}
            component="div"
            count={props.data.result_info.total_count || 0}
            rowsPerPage={50}
            page={(props.data.result_info.page||1)-1}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
    </Box>
    <Dialog open={open} maxWidth={"xs"} width={"xs"} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title"> {isEdit ?"Edit Gift Subscription":"Add Gift Subscription"} </DialogTitle>
        <DialogContent>
        <TextField
            autoFocus
            margin="dense"
            id="entername"
            label="Enter Name"
            type="email"
            fullWidth
            disabled={isEdit}
            size="small"
            variant="outlined"
            value={name}
            onChange = {(e)=>{
              setName(e.target.value)
              if(!e.target.value){
                setDisableButton(true);
              }
              else setDisableButton(false);
             } 
            }
          />
          <TextField
            margin="dense"
            id="emailaddress"
            label="Email Address"
            type="email"
            disabled={isEdit}
            fullWidth
            size="small"
            variant="outlined"
            value = {emailAddress}
            error={isDirty }
            helperText={isDirty ? "* Invalid email." : ""}
            onChange = {(e)=>{
              setEmailAddress(e.target.value);
              if(!isValidEmailAddress(e.target.value)) {
                setDirty(true);
                return;
              }
              setDirty(false);
              if(!e.target.value){
                setDisableButton(true);
              }
              else setDisableButton(false);
             }
            }
          />
          {
          isLoading ?
           <Box>loading products ...</Box>
          : (storeAllProduct && storeAllProduct.length == 0) ? 
               <Box>No product found,Please create product indorder to give gift subscription</Box>
            :
           <FormControl className={classes.formControl} size="small" margin="dense" fullWidth>
            {/* <InputLabel id="demo-simple-select-label">Type</InputLabel> */}
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={changeProduct}
              onChange={onChangeProduct}
              variant="outlined"
              
            >
              {
                storeAllProduct &&storeAllProduct.map((val)=>{
                return <MenuItem key={val.id} value={val}>{val.name}</MenuItem>
                })
              }
            </Select>
          </FormControl>
          }
        </DialogContent>
        <DialogActions>
          <Button disabled={ isLoading || isManagingGift} onClick={handleClose} color="secondary" variant="contained">
            Cancel
          </Button>
          <Box display="flex" alignItems="center">
          <Button disabled={(storeAllProduct && storeAllProduct.length == 0) 
            || disableButton || isLoading || isManagingGift} onClick={creatingGift} color="primary" variant="contained">
            {isEdit ?'Save':'Gift' }
          </Button>
            {
              isManagingGift && <CircularLoader size={20} height="auto"/>
            }
          </Box>
          
        </DialogActions>
      </Dialog>
    </>
    
  );
}