const appEnv = process.env.REACT_APP_APP_ENV;

export function registerSignupEvent() {
  if(appEnv !== 'production') {
    // eslint-disable-next-line
    console.log('gtag event fired ', 'sign up');
    return;
  }
  try {
    window.gtag('event', 'conversion', {'send_to': 'AW-730627591/trHACPW3gcABEIf8sdwC'});
  } catch(err) {
    // eslint-disable-next-line
    console.error('unable to register adwords signup events')
  }
}

export function registerLoginEvent() {
  if(appEnv !== 'production') {
    // eslint-disable-next-line
    console.log('gtag event fired ', 'login');
    return;
  }
  try {
    window.gtag('event', 'conversion', {'send_to': 'AW-730627591/lC6OCOyp_KkBEIf8sdwC'});
  } catch (err) {
    // eslint-disable-next-line
    console.error('unable to register adwords login event');
  }
}

export function registerDomainCreation () {
  if(appEnv !== 'production') {
    // eslint-disable-next-line
    console.log('gtag event fired ', 'register domain');
    return;
  }
  try {
    window.gtag('event', 'conversion', {'send_to': 'AW-730627591/K1vcCOfBgcABEIf8sdwC'});
  } catch (err) {
    // eslint-disable-next-line
    console.error('unable to register Domain creation');
  }
}