import React, { useCallback ,useContext} from "react";
import WebmasterCustomDomain from "../components/settings/WebmasterCustomDomain";
import FullScreenPage from '../commons/commonWrapper/FullScreenPage';
import Box from "../commons/muiCustomComponents/Box";
import MobileProvider from "commons/context/MobileProvider";
import { useSelector }  from "react-redux";

export default function WebmasterDomainMap() {
  const barCallback = useCallback(() => (<Box
    display="flex"
    alignItems="center"
    component="span"
    fontSize ={14}
    >ENABLE SEO</Box>), []);

  return <FullScreenPage BarComponent={barCallback}>
      {(appBarHeight)=><WebmasterCustomDomain appBarHeight={appBarHeight} /> } 
  </FullScreenPage>
}