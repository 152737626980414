import React from "react";
import Articles from "../components/articles/articles";

/**
 * component to load when the user first come to the system.
 * will decide where to send the user.
 */
function HomePage () {
    return (
        <Articles/>
    )
}

export default HomePage;


