import React, { useEffect, useState, useContext, useRef } from 'react';
import Box from "commons/muiCustomComponents/Box";
import { makeStyles } from '@material-ui/core/styles';
import Button from "commons/muiCustomComponents/Button";
import Paper from 'commons/muiCustomComponents/MuiPaper';
import Grid from "commons/muiCustomComponents/Grid";
import { useHistory, useParams } from "react-router-dom";
import {
  generateBenefite, getBenefites,
  getProductsById,
  updateProductById,
  saveMediaToPisco,
  showMessage,
  updateBenefit,
  setDefaultTab,
} from "./monetization-action";
import { useDispatch, useSelector } from "react-redux";
import InputAdornment from '@material-ui/core/InputAdornment';
import { CircularLoader, LinearLoader, PageBlockLoader } from 'commons/commonWrapper/Loaders';
import withFileUpload from "commons/hocs/WithFileUpload";
import MobileProvider from "commons/context/MobileProvider";
import setAppMessage from 'app-component/app-component-action';
import EditPlanContainer from "./components/EditPlan";
import EditCustomBenefitContainer from "./components/EditCustomBenefitContainer";
import BenefitContainer from "./components/BenefitContainer";
import InputBox from "commons/muiCustomComponents/InputBox";
import DraftjsEditor from "components/content/components/DraftjsEditor";
import VideoPlayer from 'react-video-js-player';
import MediaUploadContinaer from "./components/MediaUpload";
import BarHeader from "./components/BarHeader";


const ImgButton = () => {
  return (
    <Button variant="contained" color="secondary">
      Change Image
    </Button>
  )
}
const ImgUpload = withFileUpload(ImgButton);
const ChangeImg = withFileUpload(Box);


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.pubWhite,
  },

}));


export default function EditProducts() {
  const classes = useStyles();
  const history = useHistory();
  const mediaRef = useRef(null);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { productId } = useParams();
  const isMobile = useContext(MobileProvider);
  const [wordCount, setWordCount] = useState(0);

  //product info
  const [productName, setProductName] = useState('');
  const [productNameError, setProductNameErr] = useState(false);
  const [productDescripition, setProductDescripition] = useState('');
  const [productNameEdit, setProductNameEdit] = useState('');
  const [productDescripitionEdit, setProductDescripitionEdit] = useState('');
  const [productDescripitionError, setProductDescripitionError] = useState(false);
  const [content, setContent] = useState({});

  const [productImage, setProductImage] = useState('');
  const [prodcutVideo, setProductVideo] = useState('');
  const [isLoadingProduct, setLoadingProduct] = useState(false);
  const [lastUpdate, setLastUpdate] = useState((new Date()).getTime());
  const [showSaveButton, setShowButton] = useState(false);

  const [plans, setPlans] = useState([]);
  const [benefits, setBenefits] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [totalBenefits, setTotalBenefits] = useState([]);
  const [isEdit, setEdit] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const [mediaContent, setMediaContent] = useState(false);
  const [currencyVal, setCurrencyVal] = useState('');
  const [isShippingAddress, setShippingAddress] = useState(false);
  const [isLoadingaddress, setLoadingAddress] = useState(false);
  const [viewDescription, setViewDescription] = useState(null);
  const [poster, setPoster] = useState(null);

  useEffect(() => {

    const getProductDetails = async () => {
      try {
        setLoading(true)
        const data = await dispatch(getProductsById(productId));
        const { name, description, plans, benefits, currencySymbol, image, cover_video,
          currency, productSummary, productDescriptionObjectMap, shipping_address_required,
        } = data;
        if (benefits.length > 0) {
          const systemBenefit = benefits.filter((item) => item.type == 'SYSTEM');
          setTotalBenefits(systemBenefit || []);
          const customBenefit = benefits.filter((item) => item.type == 'CUSTOM');
          setBenefits(customBenefit || []);
        }
        setProductName(name || '');
        setCurrencyVal(currency);

        setProductDescripition(productSummary || '');
        setContent({ html: description, objectMap: productDescriptionObjectMap });
        cover_video ? setPoster(image)
          : setProductImage(image);
        setProductVideo(cover_video);
        setViewDescription(description)
        setPlans(plans);
        setShippingAddress(shipping_address_required)

        setLoading(false);
      }
      catch (err) {
        setLoading(false)
      }
    }
    getProductDetails();
  }, [productId])

  const handleMediaUpload = async (file, cb) => {
    const format = file.type.split('/')[0];
    if (format !== "image") return alert("Invalid File Format.");
    try {
      let res = await dispatch(saveMediaToPisco(file));
      const { localUploadResponse: { cdn } } = res;
      cb(null, cdn);
    } catch (err) {
      const { message } = err;
      dispatch(setAppMessage({ message: message || 'Unable to upload media.', header: 'Error', type: 'alert' }));
      cb(err);
    }
  }
  const updateHtmlContent = async (html, blockMap, count) => {
    try {
      setContent({ html, blockMap });
      // metadata.richDescription = { html: html, objectMap:JSON.stringify(blockMap) };
      // await dispatch(updateArticleContent(articleId, html, blockMap)); 
    } catch (err) {
      // handle error
      dispatch(setAppMessage({ message: `Unable to update descripition.`, header: 'Error', type: 'alert' }));
    }
  }
  const uploadMediaContent = (target, type) => {
    const allowed = ['jpg', 'jpeg', 'png'];
    const file = target && target.files[0];
    const uploadType = type && type.split("/")[0];
    const extension = file && file.type.split("/")[1];

    if (allowed.indexOf(extension) < 0) {
      // dispatch(setAppMessage({ message: 'Unsupported media format.', header: 'Error', type: 'alert' }));
      return;
    }

    const { size } = file;
    const sizeInMb = size / 1000000;
    if (sizeInMb > 500) {
      // dispatch(setAppMessage({ message: 'image larger than 500MB not allowed.', header: 'Error', type: 'alert' }));
      return;
    }
    const fr = new FileReader();
    fr.onload = (stream) => {
      // setContentBase64(stream.target.result);
    }

    fr.readAsDataURL(file);
    uploadMediaToPisco(file);
  }
  const uploadMediaToPisco = async (file) => {

    try {
      setRequesting(true);
      let res = await dispatch(saveMediaToPisco(file));
      const { localUploadResponse: { cdn, s3Url } } = res;
      setMediaContent(`https:${cdn}`);
      setRequesting(false);
    } catch (err) {
      setRequesting(false);
      const { message } = err;
      // dispatch(setAppMessage({ message: message || 'Unable to upload media.', header: 'Error', type: 'alert' }));
      // do error handling
    }
  }

  const handleEdit = () => {
    setProductDescripitionEdit(productDescripition);
    setProductNameEdit(productName);
    setMediaContent(productImage);
    setEdit(true);
  }

  const handleSaveProdcut = async () => {
    if (!productNameEdit.trim()) {
      setProductNameErr(true)
      return;
    }
    if (!(mediaRef.current && mediaRef.current.video) && !(mediaRef.current && mediaRef.current.image)) {
      dispatch(showMessage("Video/Image is required."))
      return
    }
    if (mediaRef.current.video) {
      if (!(mediaRef.current && mediaRef.current.thumbnail)) {
        dispatch(showMessage("Please select atleast one thumbnail."))
        return
      }
    }
    try {
      setLoadingProduct(true)
      const data = await dispatch(updateProductById({
        "product": {
          description: content.html,
          description_object_map: JSON.stringify(content.blockMap),
          name: productNameEdit,
          summary: productDescripitionEdit,
          image: (mediaRef.current && mediaRef.current.image) || (mediaRef.current && mediaRef.current.thumbnail) || null,
          cover_video: (mediaRef.current && mediaRef.current.video) || null,
        },
      }, productId));


      setProductName(data.productName)
      setViewDescription(data.productDescription)
      mediaRef.current && mediaRef.current.video ?
        setPoster(data.image)
        : setProductImage(data.image);
      setProductVideo(data.cover_video)
      setProductDescripition(data.productSummary || '')
      setContent({
        html: data.productDescription,
        objectMap: data.productDescriptionObjectMap,
      })

      setEdit(false);
      setLoadingProduct(false)
    }
    catch (err) {
      console.log("err", err)
      setLoadingProduct(false)
    }

  }

  const addingAddress = async () => {
    try {
      setLoadingAddress(true)
      await dispatch(updateProductById({
        "product": {
          shipping_address_required: +isShippingAddress,
        },
      }, productId));
      setLoadingAddress(false)
    }
    catch (err) {
      setLoadingAddress(false)
    }
  }
  if (isLoading) {
    return (
      <Grid container>
        <Grid item md={9} xl={10} xs={12} >
          <Box mt={isMobile ? 2 : 6} mb={6} >
            <Paper>
              <Box height={300}>
                <CircularLoader height="auto" message="fetching product detail..." />
              </Box>

            </Paper>
          </Box>
        </Grid>
      </Grid>
    )
  }

  const goToProduct = () => {
    let isActive = false;

    plans.forEach((item) => {
      if (item.status === 1) {
        isActive = true;
      }
    })

    if (!isActive) {
      dispatch(setAppMessage({ message: `At least one active plan is required to show product in website.`, header: 'Error', type: 'alert' }));
      return;
    }
    history.push('/dash/products');
  }

  return (
    <>
      <Grid container>
        <Grid item md={9} xl={10} xs={12} >
          {!isMobile && <BarHeader goToProduct={goToProduct} taskname={"PRODUCTS"} />}
          <Box mt={isMobile ? 2 : 4} mb={4}>
            {
              isEdit ?
                <Paper>
                  <Box p={4}>
                    <Grid container>
                      <Grid item md={12} xl={12} xs={12} >
                        <MediaUploadContinaer
                          ref={mediaRef}
                          poster={poster}
                          prodcutImage={mediaContent} prodcutVideo={prodcutVideo} />
                      </Grid>
                      <Grid item md={12} xl={12} xs={12} >
                        <Box mt={2}>
                          <InputBox
                            id="productName"
                            heading="Name*"
                            // helperText={productNameError ? "Title can't be empty" : ''}
                            onChange={(e) => { setProductNameErr(false); setProductNameEdit(e.currentTarget.value) }}
                            // error={productNameError}
                            value={productNameEdit || ''}
                            variant="standard"
                            placeholder="Enter Title Here"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {`${(productNameEdit.length) || 0}`}
                                </InputAdornment>
                              ),
                            }}
                            headingStyle={{
                              component: "span",
                              fontWeight: "bold",
                              fontSize: 14,
                              mb: 1,
                            }}
                            className={{ root: classes.inputRoot }}
                            fullWidth

                          />
                        </Box>
                        <Box mt={3}>
                          <InputBox
                            id="productSummary"
                            heading="Summary"
                            helperText={productNameError ? "Summary can't empty" : ''}
                            error={productNameError}
                            value={productDescripitionEdit}
                            onChange={(e) => setProductDescripitionEdit(e.currentTarget.value)}
                            variant="standard"
                            placeholder="Enter Summary Here"

                            headingStyle={{
                              component: "span",
                              fontWeight: "bold",
                              fontSize: 14,
                              mb: 1,
                            }}
                            className={{ root: classes.inputRoot }}
                            fullWidth

                          />
                        </Box>
                        <Box mt={3}>
                          <DraftjsEditor
                            objectMap={content.objectMap}
                            htmlContent={content.html}
                            updateWordCount={(count) => setWordCount(count)}
                            lastUpdate={lastUpdate}
                            isMobile={isMobile}
                            wordCount={wordCount}
                            bodytitle={'Description'}
                            DraftPlaceholder={"Write your description."}
                            handleMediaUpload={handleMediaUpload}
                            updateApi={updateHtmlContent}
                          />

                        </Box>

                      </Grid>

                    </Grid>
                    <Grid item md={12} xl={12} xs={12} >
                      <Box display="flex" justifyContent="flex-end">
                        <Button variant="contained" color="secondary" onClick={() => { setEdit(false) }}>Cancel</Button>
                        <Box display="flex">
                          <Button variant="contained" disabled={isLoadingProduct} color="primary" boxProps={{ ml: 3 }} onClick={handleSaveProdcut}>Save</Button>
                          {
                            isLoadingProduct && <CircularLoader size={20} height="auto" />
                          }
                        </Box>
                      </Box>
                    </Grid>
                  </Box>
                </Paper>
                :
                <Paper>
                  <Box p={4}>
                    <Grid container>
                      {(prodcutVideo || productImage) &&
                        <Grid item md={3} xl={12} xs={12} >

                          <Box width={productImage ? 165 : 143} height={productImage ? 100 : 143}>
                            {prodcutVideo &&
                              <VideoPlayer src={prodcutVideo}
                                poster={poster}
                                width={143} height={143} controls>
                              </VideoPlayer>
                            }
                            {productImage &&
                              <img src={productImage} alt="prodcut_image" width="100%" height="100%" />
                            }
                          </Box>

                        </Grid>
                      }
                      <Grid item md={(prodcutVideo || productImage) ? 8 : 11} xl={12} xs={12} >
                        <Box >
                          <Box component="span" fontWeight="bold">Product Name</Box>
                          <Box fontSize={12} mt={3}>{productName}</Box>
                        </Box>
                        <Box mt={3}>
                          <Box component="span" fontWeight="bold">Summary</Box>
                          <Box fontSize={12} mt={3}>{productDescripition}</Box>
                        </Box>
                        <Box mt={3}>
                          <Box component="span" fontWeight="bold">Description</Box>
                          <Box fontSize={12} mt={3} style={{ wordBreak: "break-word" }} dangerouslySetInnerHTML={{ __html: viewDescription }}></Box>
                        </Box>
                      </Grid>
                      <Grid item md={1} xl={12} xs={12} >
                        <Button variant="contained" color="secondary" onClick={handleEdit}>Edit</Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>}
          </Box>
          <Box mt={isMobile ? 2 : 6} mb={6}>

            <Paper>

              <EditPlanContainer plans={plans} setPlans={setPlans} productId={productId} currencyVal={currencyVal} />

            </Paper>

          </Box>
          <Box mt={isMobile ? 2 : 6} mb={6}>
            <Paper>
              <Box p={4}>
                <BenefitContainer
                  setTotalBenefits={setTotalBenefits}
                  totalBenefits={totalBenefits}
                  isEdit={true}
                  productId={productId}
                />
              </Box>
            </Paper>

          </Box>
          <Box mt={isMobile ? 2 : 6} mb={6}>
            <Paper>
              <EditCustomBenefitContainer benefits={benefits} productId={productId} />
            </Paper>
          </Box>
          <Box mt={isMobile ? 2 : 6} mb={6}>
            <Paper>
              <Box p={4}>
                <Box component="span" color="primary" fontWeight="bold">Advanced Option</Box>
                <Box display="flex" justifyContent="space-between">

                  <Button color="primary" type="checkbox"
                    rightText="Enable shipping address" checked={isShippingAddress} onChange={(e) => {
                      setShowButton(true)
                      setShippingAddress(e.currentTarget.checked)
                    }} />
                  <Box display="flex">

                    {showSaveButton &&
                      <Button variant="contained" color="primary" onClick={addingAddress}>Save</Button>}
                    {
                      isLoadingaddress && <CircularLoader size={20} height="auto" />
                    }
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
