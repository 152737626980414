const insightsAction = {
  INSIGHTSREVENUE: {
    SUCCESS: "insightsRevenueSuccess",
  },
  INSIGHTSVIEWS: {
    SUCCESS: "insightsViewsSuccess",
  },
  INSIGHTSREVENUEFORHEADER: {
    SUCCESS: "insightsRevenueForHeaderSuccess",
  },
  INSIGHTSSUBSCRIPTION: {
    SUCCESS: "insightsSubscriptionSuccess",
  },
}
  
export default insightsAction;