import React, { useEffect, useCallback, useState } from 'react';
import { useHistory } from "react-router-dom";
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from "react-redux";
import Box from "../../commons/muiCustomComponents/Box";
import Paper from '@material-ui/core/Paper';
import ClearIcon from '@material-ui/icons/Clear';
import Draggable from 'react-draggable';
import setAppMessage ,{ abortMediaAction } from "../app-component-action";
import DoneIcon from '@material-ui/icons/Done';
import { CircularLoader } from "commons/commonWrapper/Loaders";



const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    fileNameClass :{
      width:theme.spacing(50),
      whiteSpace:'pre',
      overflow:"hidden",
    },
    anchorOriginBottomCenter :{
      bottom: theme.spacing(25),
    },
  },

}));

export default function CustomizedSnackbars() {
  const classes = useStyles();
  const history = useHistory();
  
  const { app: { storage: { fileMeta:
    { percentage, filename }, isOpen,status,processingPercentage ="" } } } = useSelector((state) => state);
    
  const dispatch = useDispatch();

  const handleStart = (e) => {
    console.log(e)
  }

  const handleDrag = (e) => {
    console.log(e)
  }
  const handleStop = (e) => {
    console.log(e)
  }

  useEffect(()=>{
    history.listen((location, action) => {
      if(action ==="POP" ||action ==="PUSH" ){
        localStorage.setItem("isRunningUpload", JSON.stringify([]));
        abortMedia();
      }
    });
     isOpen && ( window.onbeforeunload = () => {
      return "Are you sure you want to abort the media?";
      })

    return ()=>{
      window.onbeforeunload = null;
      localStorage.setItem("isRunningUpload", JSON.stringify([]));
    }
    
  },[isOpen]);

  const abortMediaAlert = ()=>{
    dispatch(setAppMessage({ message: '',
    leftButtonText:'Close', 
    rightButtonText:'Abort', 
    handleSuccess: abortMedia, header: 'Do you want to abort file upload?' }));      
  }

  const abortMedia = () => {
    dispatch(abortMediaAction(true))
  }

  const getLebel = useCallback(()=>{
    if(status==='PROGRESSING'){
      return <Box mt={2}>{processingPercentage ? `${processingPercentage} processing ... `
        :`processing media ...`}</Box>
    }
    if(status==='ERROR'){
        return <Box mt={2}>error in processing trying again...</Box>
    }
    if(status==='COMPLETE'){
      return <Box mt={2}>processing Complete </Box>
    }
    return (
    <Box mt={2}>
          {`${percentage || '0.00%'} ${percentage === '100.00%' ? 'uploaded' : 'uploading...'}`}
    </Box>
    )
  },[status,percentage]);

  const dragHandlers = {onStart: handleStart, onStop: handleStop};

  return (
    <>
    <Draggable 
    handle="strong"
    {...dragHandlers}
    >
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={isOpen}
      style={{bottom:100}}
      classes = {{anchorOriginBottomCenter : classes.anchorOriginBottomCenter}}
    >
      <Paper elevation={2} style={{position:'relative',minWidth:300}} >
        <Box display="flex" zIndex={1} justifyContent="space-between" alignItems="center"  minWidth={300} p={3}>
          <Box zIndex={1}>
            <Box fontWeight="bold"  width = {200}
      whiteSpace = 'pre'
      overflow = "hidden"className={classes.fileNameClass}>
              {filename && filename.length > 25 ?`...${filename.substr(filename && filename.length-18)}`:filename}
            </Box>
            {
              getLebel()
            }
          </Box>
          { status==="PROGRESSING" || status ==="ERROR" ?<CircularLoader size={20} height="auto" />:
            percentage === '100.00%' ||status==='COMPLETE' ?
            <DoneIcon  fontSize="small" style={{ color: 'black',zIndex:1 }} />
            : <ClearIcon  fontSize="small" style={{ color: 'black',zIndex:1 }} onClick={abortMediaAlert} />
          
            }
        </Box>
        <Box width={percentage} position="absolute" zIndex={0} height="100%" top={0} left={0} bgcolor="#CCDAD1">
        </Box>
      </Paper>
    </Snackbar>
    </Draggable>
    </>
  );
}
