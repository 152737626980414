import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import { useHistory } from "react-router-dom";
import Dialog from "commons/muiCustomComponents/Dailog";
import Grid from "commons/muiCustomComponents/Grid";
import Box from "commons/muiCustomComponents/Box";
import Button from "commons/muiCustomComponents/Button";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MobileProvider from "commons/context/MobileProvider";
import Header from "./components/Header";
import Footer from "./components/Footer";
import moment from "moment";
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import Switch from '@material-ui/core/Switch';
import {getFacebookPages, getTwitterPages} from "../../components/social/social-action";
import AddFacebookButton from "../../components/social/components/AddFacebookButton";
import AddTwitterButton from "../../components/social/components/AddTwitterButton";
import CheckBox from '@material-ui/core/Checkbox';
import ArrowForward from "@material-ui/icons/ArrowForward";
import Radio from '@material-ui/core/Radio';
import {
  scheduleFbPostsAction, 
  scheduleTweetAction, 
  sendNewsletterAction, 
  getNewsletterAction} from "../../components/social/scheduler/scheduler-action";
import Input from 'commons/muiCustomComponents/Input';
import DraftsIcon from '@material-ui/icons/Drafts';
import DraftjsEditor from "./components/DraftjsEditor";
import {saveMediaToPisco, fetchArticleMeta} from "./content-action";
import setAppMessage from "app-component/app-component-action";
import  {LinearLoader} from 'commons/commonWrapper/Loaders';
import { MentionsInput, Mention } from 'react-mentions';
import axios from "axios";
import CloseIcon from '@material-ui/icons/Close';
import { LogglyTracker } from 'loggly-jslogger';

const logger = new LogglyTracker();
logger.push({ 'logglyKey': '142d21f5-10d3-47a9-aa5a-f83e0a3abed7', 'sendConsoleErrors' : true, 'tag' : 'ardbeg,content,text-article-quick-share' });


const useStyles = makeStyles(theme => ({
  headerContainer: {
    borderBottom: '1px solid grey',
    height:theme.spacing(25),
  },
  bodyContainer: {
    height: `calc(100vh - ${theme.spacing(55)})`,
  },
  actionContainer: {
    borderTop: '1px solid grey',
    height:theme.spacing(30),
  },
  titleContainer: {
    background:'#f5faff',
    color:'#007aff',
    height:'50px',
    justifyContent:"center",
  },
  addNewCat: {
    textAlign:"center",
    margin:'auto',
  },
  subscriptionBox: {
    width: theme.isMobile ? '100%': '20%',
    display:'flex',
    height:theme.spacing(10),
    marginTop:theme.spacing(4),
    marginBottom:theme.spacing(4),
  },
  mainButton: {
    height: theme.spacing(14),
    width: "100%",
    alignItems: "center",
    fontSize: theme.spacing(5),
    color: theme.palette.pubWhite,
    background: theme.palette.secondary.main,
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(2.5),
    boxSizing: "border-box",
    textTransform: "capitalize",
  },
  nextButton: {
    paddingTop:'16px',
    paddingBottom:'16px',
    width:'200px',
    borderRadius:'32px',
    margin:'8px',
  },
  footerButtonMobile: {
    paddingTop:theme.spacing(2),
    paddingBottom:theme.spacing(2),
    borderRadius:theme.spacing(1),
  },
  mobileFooterContainer: {
    padding:theme.spacing(4),
  },
  footerContainer: {
    height: theme.spacing(30),
    width: "100%",
  },
  mobileFooterItem: {
    paddingLeft:theme.spacing(2),
    paddingRight:theme.spacing(2),
  },
  newsletterBtn: {
    background: theme.palette.pubGreen,
  },
  mainHeading: {
    marginLeft: theme.spacing(2.5),
  },
  pageContainer: {
    display: "flex",
    padding: theme.spacing(4),
    justifyContent: "space-between",
    alignItems: "center",
    background: theme.palette.pubLightGrey,
    marginTop: theme.spacing(1),
  },
  activeContainer: {
    background: theme.palette.pubPaleGreyTwo,
  },
  activeContainerNews: {
    background: theme.palette.publightGreen,
  },
  name: {
    color: theme.palette.PubGrey,
    fontSize: theme.spacing(5),
    textTransform: "capitalize",
  },
  activeName: {
    color: theme.palette.pubdeepSkyBlue,
  },
  activeNameNews: {
    color: theme.palette.pubGreen,
  },
  removeBtn: {
    background: theme.palette.pubWhite,
    color: theme.palette.pubdeepSkyBlue,
  },
  captionConatiner: {
    marginTop: theme.spacing(5),
  },
  captionText: {
    fontSize: theme.spacing(5),
    marginBottom:  theme.spacing(2),
    color: theme.palette.PubGrey,
  },
  captionBox: {
    background: theme.palette.pubGreywhite,
  },
  commonMargin: {
    marginTop: theme.spacing(3),
  },
  gridContainer: {
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  sectionContianer: {
    marginTop: theme.spacing(10),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(5),
      paddingTop: 0,
    },
  },
  sectionSeparator: {
    borderTop: "1px solid",
    borderColor: theme.palette.pubveryLightPinkTwo,
    paddingTop: theme.spacing(10),
    [theme.breakpoints.up('md')]: {
      paddingTop: 0,
      borderLeft: "1px solid",
      borderTop: 0,
      borderColor: theme.palette.pubveryLightPinkTwo,
    },
  },
  backgroundFullScreen:{
    backgroundColor:theme.palette.pubBackground,
  },
}));

const RadioBtn = withStyles(theme => ({
  root: {
    color: theme.palette.pubGreen,
    '&$checked': {
      color: theme.palette.pubGreen,
    },
  },
  checked: {},
}))(Radio);

const CheckBoxBtn = withStyles(theme => ({
  root: {
    color: theme.palette.pubdeepSkyBlue,
    '&$checked': {
      color: theme.palette.pubdeepSkyBlue,
    },
  },
  checked: {},
}))(CheckBox);

const FacebookSwitch = withStyles(theme => ({
  switchBase: {
    color: theme.palette.pubdeepSkyBlue,
    '&$checked': {
      color: theme.palette.pubWhite,
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.pubLightBlue,
    },
  },
  checked: {},
  track: {},
}))(Switch);

const TwitterSwitch = withStyles(theme => ({
  switchBase: {
    color: theme.palette.pubdeepSkyBlue,
    '&$checked': {
      color: theme.palette.pubWhite,
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.pubLightBlue,
    },
  },
  checked: {},
  track: {},
}))(Switch);

const NewsletterSwitch = withStyles(theme => ({
  switchBase: {
    color: theme.palette.pubdeepSkyBlue,
    '&$checked': {
      color: theme.palette.pubWhite,
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.pubLightGreen,
    },
  },
  checked: {},
  track: {},
}))(Switch);

function ArticlePreview(props) {

  const dispatch = useDispatch();
  const history = useHistory();
  const {articleId} = props;

  const classes = useStyles();
  const [showProgress, setShowProgress] = useState(false);
  const [fbCaption, setFbCaption] = useState("");
  const [twitterCaption, setTwitterCaption] = useState("");
  const [newsletterSubject, setNewsletterSubject] = useState("");
  const [newsletterParagraph, setNewsletterParagraph] = useState("");  
  const [selectedNewsletter, setSelectedNewsletter] = useState("");
  const [content, setContent] = useState({});
  const [pathName, setPathName] = useState("");
  const [fbComplete,setFbComplete] = useState(false);
  const [twitterComplete,setTwitterComplete] = useState(false);
  const [newsletterComplete,setNewsletterComplete] = useState(false);
  const [showAddBtn,setShowAddBtn] = useState(false);
  const [showSocial, setShowSocial] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const {landing: {userProfile}} = useSelector(state => state);

  const [state, setState] = useState({
    checkedFb: true,
    checkedTwitter: true,
    checkedNewsletter: true,
    selectedFbAccounts: [],
    selectedTwitterAccounts: [],
  });
  const isMobile = React.useContext(MobileProvider);
  const {
    social : {
      facebookPages,
      twitterPages,
    },
    scheduler: {
      newsletterData,
    },
    landing: {
      activeDomain,
      userPermissions,
    },
    header: {
      domainList,
    },
  } = useSelector(state =>  state);

  const fqdn = domainList && domainList[activeDomain] && domainList[activeDomain][0].fqdn;
  
  useEffect(() => {
    let isMounted = true;
    async function getPages(){
      try{
        setShowProgress(true);
        const response = await Promise.all([dispatch(getFacebookPages()), dispatch(getTwitterPages()), 
          dispatch(getNewsletterAction()), dispatch(fetchArticleMeta(articleId))]);
        isMounted && setPathName(response[3].result.pathname);
        isMounted && setShowProgress(false);
      } catch(err){
        isMounted && setShowProgress(false);
      }      
    }
    if(props.open){
      getPages();
    }
    return () => (isMounted = false);
  }, [props.open]);

  useEffect(() => {
    if(fbComplete && twitterComplete && newsletterComplete){
      setShowProgress(false);
      history.push('/dash/create');
    }
  }, [fbComplete, twitterComplete, newsletterComplete]);

  const scheduleFbPosts = async() =>{
    const selectedFbAccounts = state.selectedFbAccounts;
    const url = `https://${fqdn}${pathName}`;
    const payload = {
      data: {
        custommessage: fbCaption,
        message: fbCaption,
        time: +moment(new Date()).add(30, 'm').unix(),
        url: url,
      },
      page: {
        name: "",
      },
    }
    
    let response = 0;
    for(let i=0;i<selectedFbAccounts.length;i++){
      const item = selectedFbAccounts[i];
      const pageName = {
        page: {
          name: item.name,
        },
      }
      try{
        await dispatch(scheduleFbPostsAction({...payload, ...pageName}));
      } catch(err){
        const data = {
          'error': err,
          'email': (userProfile||{}).email || '',
          'url': (window.location||{}).href || '',
          'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
          'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
        };
        logger.push({
            'type': 'error',
            'data': data,
          });
        dispatch(setAppMessage({ message: `Can't schedule post for ${item.name}`, header: 'Error', type: 'alert' }));
      } finally{
        response++;
        if(response === selectedFbAccounts.length){
          setFbComplete(true);
        }
      }
    }
  }

  const scheduleTwitterPosts = async() => {
    const selectedTwitterAccounts = state.selectedTwitterAccounts;
    const url = `https://${fqdn}${pathName}`;
    const payload = {
      date: +moment(new Date()).add(30, 'm'),
      message: `${twitterCaption} ${url}`,
      token: "",
      tokenSecret: "",
      username: "",
    }

    let response = 0;
    for(let i=0;i<selectedTwitterAccounts.length;i++){
      const item = selectedTwitterAccounts[i];
      const userInfo = {};
      userInfo.token = item.oauth_token;
      userInfo.tokenSecret = item.oauth_token_secret;
      userInfo.username = item.screen_name;
      try{
        await dispatch(scheduleTweetAction({...payload, ...userInfo}));
      } catch(err){
        const data = {
          'error': err,
          'email': (userProfile||{}).email || '',
          'url': (window.location||{}).href || '',
          'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
          'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
        };
        logger.push({
            'type': 'error',
            'data': data,
          });
        dispatch(setAppMessage({ message: `Can't schedule tweet for ${item.name}`, header: 'Error', type: 'alert' }));
      } finally{
        response++;
        if(response === selectedTwitterAccounts.length){
          setTwitterComplete(true);
        }
      }
    }
  }

  const sendNewsletter = async() => {
    const segment = [];
    for(let i=0; i< newsletterData.segments.length;i++){
      if(selectedNewsletter === newsletterData.segments[i].name){
        segment.push(newsletterData.segments[i].id)
        break;
      }
    }
    const payload = {
      "article":[articleId],
      "segment": segment,
      "preamble": newsletterParagraph,
      "subject": newsletterSubject,
      "trialCampaign":0,
    }

    try{
      await dispatch(sendNewsletterAction(payload));
    } catch(err){
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      dispatch(setAppMessage({ message: `Newsletter ${err.message}`, header: 'Error', type: 'alert' }));
    } finally{
      setNewsletterComplete(true);
    }
  }

  const uploadArticle = () => {
    if(state.checkedNewsletter && showNewsletter){
      if(!newsletterSubject){
        setDirty(true);
        return;
      }
    }
    const selectedFbAccounts = state.selectedFbAccounts;
    const selectedTwitterAccounts = state.selectedTwitterAccounts;

    let fbError = false, twitterError = false, newsError = false;
    
    if(state.checkedFb && showSocial){
      if(!selectedFbAccounts.length){
        fbError = true;
        dispatch(setAppMessage({ message: 'Please select atleast one Facebook Page', header: 'Error', type: 'alert' }));
      }
    }
    
    if(state.checkedTwitter && showSocial){
      if(!selectedTwitterAccounts.length){
        twitterError = true;
        dispatch(setAppMessage({ message: 'Please select atleast one Twitter Account', header: 'Error', type: 'alert' }));
      }
    }
    
    if(state.checkedNewsletter && showNewsletter){
      if(!selectedNewsletter){
        newsError = true;
        dispatch(setAppMessage({ message: 'Please select recipients', header: 'Error', type: 'alert' }));
      }
    }

    if(fbError || twitterError || newsError){
      return;
    } else{
      setShowProgress(true);
    }
    //make api call for every account added in fbAccount and twitterAccount

    if(state.checkedFb && showSocial){
      scheduleFbPosts();
    } else{
      setFbComplete(true);
    }
    if(state.checkedTwitter && showSocial){
      scheduleTwitterPosts();
    } else{
      setTwitterComplete(true);
    }
    if(state.checkedNewsletter && showNewsletter){
      sendNewsletter();
    } else{
      setNewsletterComplete(true);
    }
  }

  const selectFbAccount = (item) => {
    const items = state.selectedFbAccounts;
    let found = 0, foundIndex;
    items.forEach((el,index) => {
      if(item.name === el.name){
        found = 1;
        foundIndex = index
        return false;
      }
    });
    if(found){
      items.splice(foundIndex,1);
    } else{
      items.push(item);
    }
    setState({ ...state, selectedFbAccounts: items});
  }

  const selectTwitterAccount = (item) => {
    const items = state.selectedTwitterAccounts;
    let found = 0, foundIndex;
    items.forEach((el,index) => {
      if(item.screen_name === el.screen_name){
        found = 1;
        foundIndex = index
        return false;
      }
    });
    if(found){
      items.splice(foundIndex,1);
    } else{
      items.push(item);
    }
    setState({ ...state, selectedTwitterAccounts: items});
  }

  const handleSwitchChange = name => event => {
    setState({ ...state, [name]: event.target.checked });
  }

  const handleFbCaption = (e) => {
    setFbCaption(e.target.value)
  }

  function fetchUsers(type, query, callback){
    if (!query) return
    let getType = type === 0 ? `hashtags` : `users`;
    let url =  `${process.env.REACT_APP_API_SERVER}/schedule/twitter/data?type=${getType}&query=${query}`;
    axios({
      method: 'get',
      url: url,
      })
      .then(function (response) {
          //handle success
        const res = response.data;
        if(type === 0) {
          return res.hashtags.map(user => {return {display: `${user.hashtag}`, id: user.hashtag}})
        }else{
          return res.users.map(user => ({display: `${user.name}`, id: `@${user.screen_name}`}))
        }
      })
      .then(res=>{
        if (res.length){
          return callback(res);
        }
      })
      .catch(function (response) {
        const data = {
          'error': response,
          'email': (userProfile||{}).email || '',
          'url': (window.location||{}).href || '',
          'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
          'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
        };
        logger.push({
            'type': 'error',
            'data': data,
          });
      });
  }

  const handleTwitterCaption = (e) => {
    let mentionData = twitterCaption;
    mentionData = e.target.value.replace(/[()]/g,'');
    setTwitterCaption(mentionData);
  }

  const handleNewsletterSubject = (e) => {
    setNewsletterSubject(e.target.value);
  }

  const handleNewsletterType = type => {
    setSelectedNewsletter(type);
  }

  const handleMediaUpload = async(file, cb) => {
    const format = file.type.split('/')[0];
    if (format !== "image") return alert("Invalid File Format.");
    try {
      let res = await dispatch(saveMediaToPisco(file));
      const { localUploadResponse: { cdn } } = res;
      cb(null, cdn);
    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      const { message } = err;
      dispatch(setAppMessage({ message: message || 'Unable to upload media.', header: 'Error', type: 'alert' }));
      cb(err);
    }
  }
  const updateHtmlContent = (html, blockMap, count) => {
    setNewsletterParagraph(html);
  }

  if(userPermissions.includes("SHARE:MANAGE_ACCOUNTS") && !showAddBtn){
    setShowAddBtn(true);
  }

  if(userPermissions.includes("SHARE:SOCIAL_SCHEDULE") && !showSocial){
    setShowSocial(true);
  }
  const showNewsletter = (Boolean(newsletterData && newsletterData.website_visible) && 
    userPermissions.includes("SHARE:NEWSLETTER"));

  if(!props.open){
    return null;
  }

  return (
    <>
      <Dialog fullScreen open={props.open} onClose={props.closeDialog} classes={{paper:classes.backgroundFullScreen}}>    
      <Header>
      <Grid container justify="center">
      {<Grid item xs={12} md={12}>
                    <Box display="flex" alignItems="center" justifyContent="space-between" ml={isMobile ?4:25} mr={isMobile ?4:25} >
                      <Box display="flex" alignItems="center" height="100%">
                          <CloseIcon style={{color:'white'}} onClick={props.closeQuickShareDialog}/>
                      </Box>
                      <Box component="p" display="flex" justifyContent="center">Quick Share</Box>
                      <Box  component="p" ></Box>
                      </Box>
                  </Grid>
                }
      </Grid>
      </Header>
      {showProgress && <LinearLoader mt={isMobile ?"auto":0}height="auto"/>}
        <Grid container>
          <Grid item xs={false} md={2}></Grid>
          <Grid item xs={12} md={8} className={classes.gridContainer}>
            {showSocial && <Grid item xs={12} md={4} className={classes.fbContainer + " " + classes.sectionContianer}>
              <Box className={classes.mainButton}>
                <Box display="flex">
                  <Box><FacebookIcon/></Box>
                  <Box className={classes.mainHeading}>Share on Facebook</Box>
                </Box>              
                <Box>
                  <FacebookSwitch
                    checked={state.checkedFb}
                    onChange={handleSwitchChange('checkedFb')}
                    value="checkedFb"
                  />
                </Box>
              </Box>
              {state.checkedFb && <Box>
                <Box className={classes.captionConatiner}>
                  <Box className={classes.captionText}>Caption</Box>
                  <Input fullWidth multiline className={classes.captionBox} rows="2" label="" placeholder="Write a caption for your facebook post." 
                    value={fbCaption} onChange={handleFbCaption} />
                </Box>
                {
                  facebookPages && facebookPages.length>0 && facebookPages.map((item, index) => {
                    let checked = false;
                    const selected = state.selectedFbAccounts;
                    selected.forEach(el => {
                      if(item.name === el.name){
                        checked = true;
                        return false;
                      }
                    });
                    return(
                      <Box className={classes.pageContainer + " " + classes.commonMargin + " " +(checked ? classes.activeContainer : '')}
                        key={index}
                        onClick={()=>{selectFbAccount(item)}}
                      >
                        <Box className={classes.name + " " + (checked ? classes.activeName : '')}>{item.name}</Box>                    
                        <CheckBoxBtn
                          checked={checked}
                          value="a"
                          name={"CheckBox-button-fb-"+index}
                          inputProps={{ 'aria-label': 'A' }}
                        />             
                      </Box>
                    )
                  })
                }
                {showAddBtn && <Box className={classes.commonMargin}>
                  <AddFacebookButton setLoader={setShowProgress} />
                </Box>}
              </Box> }             
            </Grid>}
            
            {showSocial && <Grid item xs={12} md={4} className={classes.twitterContainer + " " + classes.sectionContianer + " " + classes.sectionSeparator}>
              <Box className={classes.mainButton}>
                <Box display="flex">
                  <Box><TwitterIcon/></Box>
                  <Box className={classes.mainHeading}>Share on Twitter</Box>
                </Box>              
                <Box>
                  <TwitterSwitch
                    checked={state.checkedTwitter}
                    onChange={handleSwitchChange('checkedTwitter')}
                    value="checkedTwitter"
                  />
                </Box>
              </Box>
              {state.checkedTwitter && <Box>
                <Box className={classes.captionConatiner + " quick-share-twitter"}>
                  <Box className={classes.captionText}>Caption</Box>
                  <MentionsInput
                    onChange={handleTwitterCaption}
                    value={twitterCaption}
                    placeholder="Mention any twitter user by typing `@` followed by at least one char and `#` for hashtags "
                    className="mentions"
                  >
                    <Mention
                      trigger="#"
                      data={fetchUsers.bind(null,0)}
                      markup="(__id__)"
                      className="mentions__mention"
                    />
                    <Mention
                      trigger="@"
                      data={fetchUsers.bind(null,1)}
                      markup="(__id__)"
                      className="mentions__mention"
                    />        
                  </MentionsInput>
                </Box>
                {
                  twitterPages && twitterPages.length>0 && twitterPages.map((item, index) => {
                    let checked = false;
                    const selected = state.selectedTwitterAccounts;
                    selected.forEach(el => {
                      if(item.screen_name === el.screen_name){
                        checked = true;
                        return false;
                      }
                    });
                    return(
                      <Box className={classes.pageContainer + " " + classes.commonMargin + " " +(checked ? classes.activeContainer : '')}
                        key={index}
                        onClick={()=>{selectTwitterAccount(item)}}
                      >
                        <Box className={classes.name + " " + (checked ? classes.activeName : '')}>{item.screen_name}</Box>                    
                        <CheckBoxBtn
                          checked={checked}
                          value="a"
                          name={"CheckBox-button-twitter-"+index}
                          inputProps={{ 'aria-label': 'A' }}
                        />          
                      </Box>
                    )
                  })
                }
                {showAddBtn && <Box className={classes.commonMargin}>
                  <AddTwitterButton setLoader={setShowProgress} />
                </Box>}
              </Box>   }           
            </Grid>}
            
            {false && 
            <Grid item xs={12} md={4} className={classes.newsletterContainer + " " + classes.sectionContianer + " " + classes.sectionSeparator}>
              <Box>
                <Box className={classes.mainButton + " " + classes.newsletterBtn}>
                  <Box display="flex">
                    <Box><DraftsIcon/></Box>
                    <Box className={classes.mainHeading}>Newsletter</Box>
                  </Box>              
                  <Box>
                    <NewsletterSwitch
                      checked={state.checkedNewsletter}
                      onChange={handleSwitchChange('checkedNewsletter')}
                      value="checkedNewsletter"
                    />
                  </Box>
                </Box>
                {state.checkedNewsletter && <Box>
                  <Box className={classes.captionConatiner}>
                  <Box className={classes.captionText}>Subject</Box>
                    <Input fullWidth className={classes.captionBox} label="" placeholder="Newsletter Subject" 
                      value={newsletterSubject} onChange={handleNewsletterSubject} 
                      error={isDirty && !newsletterSubject.length}           
                      helperText={(isDirty && !newsletterSubject.length) && "Subject cannot be empty."} 
                    />
                  </Box>
                  <Box className={classes.captionConatiner}>
                    <Box className={classes.captionText}>Paragraph</Box>
                  </Box>
                  {/**---------------DraftJS Starts----------------------- */}
                  <Box mt={0} mb={0} className={"quick-share-draftjs"}>
                    <DraftjsEditor
                      DraftPlaceholder="Write here"
                      objectMap={content.objectMap}
                      htmlContent={content.html}
                      lastUpdate={''}
                      handleMediaUpload={handleMediaUpload}
                      updateApi={updateHtmlContent}/>
                  </Box>
                  {/**---------------DraftJS Ends----------------------- */}
                  <Box className={classes.captionConatiner}>
                    <Box className={classes.captionText}>Select Recipients</Box>
                  </Box>
                  {newsletterData.segments.map(item => {
                    let checked = selectedNewsletter === item.name;
                    return (
                      <Box key={item.name} className={classes.pageContainer + " " + classes.commonMargin + " " +(checked ? classes.activeContainerNews : '')}
                        onClick={()=>{handleNewsletterType(item.name)}}
                      >
                        <Box className={classes.name + " " + (checked ? classes.activeNameNews : '')}>{item.name}</Box>                    
                        <RadioBtn
                          className={classes.newsletterRadio}
                          checked={checked}
                          value={item.name}
                          name={"radio-button-newsletter"}
                          inputProps={{ 'aria-label': item.name }}
                        />          
                      </Box>
                    )
                  })}
                </Box>}
              </Box>
            </Grid>}
            
          </Grid>
          <Grid item xs={false} md={2}></Grid>
          <Box className={classes.footerContainer}></Box>
        </Grid>
        
        
        <Footer>
          {!isMobile && <Grid container justify="center">
                <Grid item md={8} xs={12}>
                  <Box display="flex" >
                  <Button 
                  color="secondary"
                  disabled={showProgress} 
                  onClick={props.closeQuickShareDialog}>Close</Button>
                  <Button 
                  variant="contained" 
                  disabled={showProgress}
                  onClick={uploadArticle} 
                  boxProps={{ml:4}}
                  endIcon={<ArrowForward/>}>Next</Button>
                  </Box>
                </Grid>
              </Grid>}
              {isMobile && <Grid container justify="center" className={classes.mobileFooterContainer}>
                <Grid item xs={6} sm={6} className={classes.mobileFooterItem}>
                  <Button
                  fullWidth
                  color="secondary"
                  disabled={showProgress} 
                  onClick={props.closeQuickShareDialog}>Close</Button>
                </Grid>
                <Grid item xs={6} sm={6} className={classes.mobileFooterItem}>
                  <Button 
                  fullWidth
                  variant="contained" 
                  disabled={showProgress}
                  onClick={uploadArticle} 
                  endIcon={<ArrowForward/>}>Next</Button>
                </Grid>
              </Grid>
              }
        </Footer>
      </Dialog>
    </>
  )
}

export default ArticlePreview;
