/**
 * This Page for mobile
 */


import React, { useContext } from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '../muiCustomComponents/AppBar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import RootRef from '@material-ui/core/RootRef';
import MobileProvider from "commons/context/MobileProvider";
import CloseIcon from '@material-ui/icons/Close';
import Paper from "commons/muiCustomComponents/MuiPaper";
import Box from "commons/muiCustomComponents/Box";
import Grid from "commons/muiCustomComponents/Grid";
import Button from "commons/muiCustomComponents/Button";

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: theme.isMobile ? theme.palette.primary.main : theme.palette.pubWhite,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    top: theme.isMobile ? 0 : 80,
    minHeight: theme.spacing(64 * 0.25),
    zIndex:99,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  bottomBar: {
    bottom: 0,
  },
  iconStyle: {
    position: 'absolute',
    left: theme.spacing(7),
    top: '50%',
    transform: ' translateY(-50%)',
    color: theme.isMobile ? theme.palette.pubWhite : theme.palette.primary.main,
  },
  background: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.pubWhite,
    height: theme.spacing(11),
    borderRadius: theme.spacing(4 * 0.25),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  backgroundButtonMatch :{
    backgroundColor:theme.palette.primary.main,
    "&:hover":{
      backgroundColor:'black !important',
    },
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.pubWhite,
    marginRight: theme.spacing(6),
  },
  cursorClass: {
    cursor: 'pointer',
  },
}));

export default function FullScreenPage({ children, title, BarComponent, isDesktop = true, isCloseIcon = false,
hideGoBack = false,
}) {
  const classes = useStyles();
  const domRef = React.useRef();
  const history = useHistory();
  const isMobile = useContext(MobileProvider);

  const [appBarHeight, setHeight] = React.useState(0);

  React.useEffect(() => {
    if (domRef.current !== null) {
      isMobile ?
        setHeight(domRef.current.getBoundingClientRect().height)
        : setHeight(domRef.current.getBoundingClientRect().height + 80)
    }
  }, []);

  const handleClose = () => {
    if (window.location.pathname.includes("/live/schedule")) {
      history.push('/dash/create');
    } else {
      history.goBack();
    }
  };

  return (<>
    <RootRef rootRef={domRef}>
      <>
      {isMobile && <AppBar position='sticky' className={classes.appBar}>
        <IconButton edge="start" className={classes.iconStyle} onClick={handleClose} aria-label="close">
          {isCloseIcon ? <CloseIcon style={{ fontSize: '1.5rem' }} /> : <ArrowBackIcon style={{ fontSize: '1.5rem' }} />}
        </IconButton>
        {
          typeof BarComponent === 'function' && BarComponent()
        }
      </AppBar>
      }
      {
        (isDesktop &&  !isMobile) ? <Grid container>
          <Grid item xs={12} md={9} xl={10}>
            <Box mt={6}>
            <Paper classes={{ root: classes.background }} square elevation={0}>
              <Box component="span" ml={6}>{
                typeof BarComponent === 'function' && BarComponent()
              }</Box>

              {!hideGoBack && <Button variant="contained" className={classes.backgroundButtonMatch} onClick={handleClose}> Go back</Button>}

            </Paper>
            </Box>
          </Grid>
        </Grid> : <></>
      }
      </>
    </RootRef>

    <>
      {typeof children === "function" ? children(appBarHeight) : children}
    </>
  </>
  )
}

