import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Dialog from "commons/muiCustomComponents/Dailog";
import { useHistory } from "react-router-dom";
import Grid from "commons/muiCustomComponents/Grid";
import Box from "commons/muiCustomComponents/Box";
import Button from "commons/muiCustomComponents/Button";
import { makeStyles } from '@material-ui/core/styles';
import Input from "commons/muiCustomComponents/Input";
import MobileProvider from "commons/context/MobileProvider";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { LinearLoader } from "commons/commonWrapper/Loaders";
import {
  changeCoverPicture,
  saveMediaToPisco,
  updateArticleMeta,
  publishArticle,
  sendVerificationLink,
  getProductsDetails,
  updateSection,
  createSection,
  clearCache,
} from "./content-action";
import { CircularLoader } from "commons/commonWrapper/Loaders";
import setAppMessage from "app-component/app-component-action";
import ImageEditor from "./components/ImageEditor";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from "moment";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import kebabCase from "lodash.kebabcase";
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DraftsIcon from '@material-ui/icons/Drafts';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { LogglyTracker } from 'loggly-jslogger';

const logger = new LogglyTracker();
logger.push({ 'logglyKey': '142d21f5-10d3-47a9-aa5a-f83e0a3abed7', 'sendConsoleErrors' : true, 'tag' : 'ardbeg,content,content-meta-home' });


const imageDefaultImage = 'https://cdn.pubninja.com/assets/images/images2-01.png';
const articleDefaultImaage = 'https://cdn.pubninja.com/assets/images/Article-01.png';
const videoDefaultImage = 'https://cdn.pubninja.com/assets/images/video-01.png';
const audioDefaultImage = 'https://cdn.pubninja.com/assets/images/Audio-01.png';

const useStyles = makeStyles(theme => ({
  addNewCat: {
    textAlign: "center",
    margin: 'auto',
  },
  titleArea: {
    background: theme.palette.pubPaleGrey,
    color: theme.palette.primary.main,
    fontSize: theme.spacing(6),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  subscriptionBox: {
    width: theme.isMobile ? '100%' : '20%',
  },
  mobileTitle: {
    justifyContent: 'center',
    display: 'flex',
  },
  subscriptionButton: {
    height: theme.spacing(10),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  sectionPadding :{
    paddingTop:theme.spacing(10*0.25),
    paddingBottom:theme.spacing(10*0.25),
  },
  formControl :{
    width:'100%',
  },
  nextButton: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    width: '200px',
    borderRadius: theme.spacing(8),
    margin: theme.spacing(2),
  },
  footerButtonMobile: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },
  stepSection: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  mobileFooterContainer: {
    padding: theme.spacing(4),
  },
  mobileFooterItem: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  coverImageSection: {
    display: theme.isMobile ? 'none' : 'block',
    marginLeft: 'auto',
    marginBottom: '80px',
  },
  AutoCompleteRoot: {
    border: '1px solid #585858',
  },
  contentContainer: {
    padding: theme.spacing(2),
    height: '80vh',
    alignItems: 'center',
  },
  backgroundFullScreen: {
    backgroundColor: theme.palette.pubBackground,
  },
  coverImageContainer: {
    borderRadius: theme.spacing(1),
    border: `1px solid #707070`,
  },
  mobileLayout: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
}));

function TextArticleMetaHome(props) {
  const classes = useStyles();
  const isMobile = React.useContext(MobileProvider);
  const { landing } = useSelector(state => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const { sections } = landing;
  const [finalSection, setFinalSection] = useState([]);
  const [coverFile, setCoverFile] = useState(null);
  const [status, setStatus] = useState(0);
  const [uploadingCover, setUploadingCover] = useState(false);
  const [showImageEditor, setShowImageEditor] = useState(false);
  const [imageContent, setImageContent] = useState(null);
  const [subSection, setSubSection] = useState([]);
  const [subsubSection, setSubSubSection] = useState([]);
  const [openDatePicker, setDatePicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [productInfo, setProductInfo] = useState(null);
  const [savingMeta, setSavingMeta] = useState(false);
  const [paidContent, setPaidContent] = useState(false);
  const [isPublishing, setPublishing] = useState(false);
  const [allProductInfo, setAllProductInfo] = useState(null);
  const [sectionvalue, renameSection] = useState('');
  const [activeOption, setActiveOption] = useState(null);
  const [opennew, setNew] = useState(false);
  const [openSecDialog, setOpenSecDialog] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [level, setLevel] = useState(0);
  
  const [checked, setChecked] = useState(false);
  const [metaDescripition, setMetaDiscription] = useState('');
  const [isSendEmail, setSendEmail] = useState(false);
  const [lastSectionVal ,setLastSectionVal] = useState(null);
  const [lastSubsectionVal ,setLastSubSectionVal] = useState(null);
  const [lastSubSubSectionVal ,setLastSubSubSectionVal] = useState(null);


  const { landing: { activeDomain, userPermissions, userProfile }, header: { domainList } } = useSelector(state => state);
  const domainInfo = domainList[activeDomain] && domainList[activeDomain][0];


  useEffect(() => {
    try {
      let content = null;
      if (props.headerType && props.headerType.indexOf('video') >= 0) {
        content = 'video';
      }
      if (props.headerType && props.headerType.indexOf('audio') >= 0) {
        content = 'audio';
      }
      if (props.headerType && props.headerType.indexOf('image') >= 0) {
        content = 'image';
      }
      if (props.headerType && props.headerType.indexOf('text') >= 0) {
        content = null;
      }
      content ?
        setMetaDiscription(`Check out the ${content} by ${domainInfo.fqdn} - Get access to more exclusive content.`) :
        setMetaDiscription(null);
    }
    catch (err) {
      console.log("err", err)
    }
  }, [activeDomain, props.headerType]);


  useEffect(() => {
    try {
      setFinalSection([...sections]);
    }
    catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      console.log("err", err)
    }

  }, [activeDomain,sections.length]);



  useEffect(() => {
    try {

      if (Object.keys(props.metadata).length > 0 && finalSection.length >0) {
        let { sectionId = 0,
           subSectionId = 0, subSubSectionId = 0, paidContent, productIdArr, scheduleTime } = props.metadata;
        
        if(props.sectionID){
          sectionId = props.sectionID
        }
        if(props.subsubsectionID){
          subSubSectionId = props.subsubsectionID
        }
        if(props.subSectionID){
          subSectionId = props.subSectionID
        }
        
        if (scheduleTime) {
          setSelectedDate(scheduleTime);
        }

        setPaidContent(!!paidContent);

        if (productIdArr.length > 0) {
          setChecked(true);
        }
        props.setSubSubSectionID(subSubSectionId);
        
        const sectionItem = sectionId ? finalSection.find((item) => item.id === sectionId) :
          finalSection[0];
        
        sectionId ? props.setSectionID(sectionId) :
          props.setSectionID(finalSection[0].id);

        props.setSectionDefaultVlaue(sectionItem);

        const { sub_section } = sectionItem;

        if (sub_section.length > 0) {
          subSectionId ? props.setSubSectionID(subSectionId) :
            props.setSubSectionID(null);
          subSectionId ?
            props.setSubSectionDefaultVlaue(sub_section.find((item) => item.id === subSectionId)) :
            props.setSubSectionDefaultVlaue(null);
          try {
            if (sub_section.length > 0) {
              const { sub_sub_section } = subSectionId ? sub_section.find((item) => item.id === subSectionId) :
                sub_section[0];
              if (sub_sub_section.length > 0) {
                subSubSectionId ?
                  props.setSubSubSectionDefaultVlaue(sub_sub_section.find((item) => item.id === subSubSectionId))
                  : props.setSubSubSectionDefaultVlaue(null)
              }
              else {
                props.setSubSubSectionDefaultVlaue('')
              }

            }
          }
          catch (err) {
            const data = {
              'error': err,
              'email': (userProfile||{}).email || '',
              'url': (window.location||{}).href || '',
              'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
              'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
            };
            logger.push({
                'type': 'error',
                'data': data,
              });
            console.log("err", err)
          }

        }
        else {
          props.setSubSectionID(null);
          props.setSubSectionDefaultVlaue('');
        }

        setSubSection([ ...sub_section || []]);
        if (sub_section.length > 0) {
          const { sub_sub_section } = subSectionId ? sub_section.find((item) => item.id === subSectionId) :
            sub_section[0];
          setSubSubSection([ ...sub_sub_section || []]);
        }
      }
    }
    catch (err) {
      // eslint-disable-next-line no-console
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      console.log('err', err)
    }

  }, [finalSection.length]);

  useEffect(() => {
    const getSub = async () => {
      try {
        const data = await dispatch(getProductsDetails());
        if (data) {
          const { products = [] } = data;
          setAllProductInfo(products);
          const res = products.find((ele) => ele.productName === 'PAID_ACCESS');
          if (res && res.productId) {
            setSendEmail(true)
            paidContent ?
              setProductInfo(products.find((ele) => ele.productName === 'PAID_ACCESS'))
              : setProductInfo(products.find((ele) => ele.productName === 'PUBLIC_ACCESS'));
          }
          else {
            setSendEmail(false)
          }
        }
      }
      catch (err) {
        const data = {
          'error': err,
          'email': (userProfile||{}).email || '',
          'url': (window.location||{}).href || '',
          'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
          'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
        };
        logger.push({
            'type': 'error',
            'data': data,
          });
        console.log("err", err)
      }

    }
    userPermissions.includes('SHARE:NEWSLETTER') && getSub();

  }, [])


  const handleDateChange = (date) => {
    setSelectedDate(moment(date).toDate());
  };

  const getThumbnail = () => {
    if (props.headerType && props.headerType.indexOf('video') >= 0) {
      return videoDefaultImage
    }
    if (props.headerType && props.headerType.indexOf('audio') >= 0) {
      return audioDefaultImage
    }
    if (props.headerType && props.headerType.indexOf('image') >= 0) {
      return imageDefaultImage
    }
    if (props.headerType && props.headerType.indexOf('text') >= 0) {
      return articleDefaultImaage
    }
  }

  const getLabel = () => {
    if (props.headerType && props.headerType.indexOf('video') >= 0) {
      return 'video post'
    }
    if (props.headerType && props.headerType.indexOf('audio') >= 0) {
      return 'audio post'
    }
    if (props.headerType && props.headerType.indexOf('image') >= 0) {
      return 'image post'
    }
    if (props.headerType && props.headerType.indexOf('text') >= 0) {
      return 'article'
    }
  }

  const closeImageEditor = () => {
    setShowImageEditor(false);
    setCoverFile(null);
  }

  const getCropData = (cropData) => {
    handleCoverImageUpload(cropData);
  }
  const handleCoverImageUpload = async (crop) => {
    setUploadingCover(true);
    const file = coverFile;
    let cropData = {
      x: crop.x,
      y: crop.y,
      scale: parseFloat(crop.scale.toFixed(4)),
    }
    try {
      const { localUploadResponse } = await dispatch(saveMediaToPisco(file));
      const { cdn } = localUploadResponse;
      let { data: {
        result: {
          coverPicture,
          coverPictureSmall,
          imgExtension,
        },
      },
      } = await dispatch(changeCoverPicture(props.articleId, { source: `https:${cdn}`, cropData }));
      props.updateCoverImageSmall(coverPicture, coverPictureSmall, imgExtension);
    } catch (err) {
      const { message } = err;
      dispatch(setAppMessage({ message: message || 'Unable to change cover image.', header: 'Error', type: 'alert' }));
      // handle error
    } finally {
      setUploadingCover(false);
      setCoverFile(null);
    }
  }

  const openDatePickerFun = (val = true) => {
    setDatePicker(val)
  }

  const enablePrice = (event) => {
    try {
    setPaidContent(true);
    setProductInfo(allProductInfo.find((ele) => ele.productName === 'PAID_ACCESS'))
    }
    catch(err){
      console.log("err",err)
    }
  }

  const disablePrice = (event) => {
    try {
    setPaidContent(false);
    setProductInfo(allProductInfo.find((ele) => ele.productName === 'PUBLIC_ACCESS'));
    }
    catch(err){
      console.log("err",err)
    }
  }
  const CustomDatePickerField = (props) => {
    return (
      <span onClick={() => props.onClickHandler(true)}>{}</span>
    )
  }


  const emailChanges = (e) => {
    setChecked(e.target.checked)
  }

  const setMeta = async (e) => {

    e && e.stopPropagation();
    e && setSavingMeta(true);
    let tempArticleId = props.articleId;
    try {

      const data = {
        title: props.title,
        section_id: props.sectionID,
        sub_section_id: props.subSectionID,
        sub_sub_section_id: props.subsubsectionID,
        paidContent: paidContent,
        thumbnail: props.thumbnail || getThumbnail(),
        headerType: props.headerType,
        description: metaDescripition,
        headerContent: props.headerType === 'text/html' ? '{}' : JSON.stringify(props.metadata && props.metadata.headerContent),
        hlsUrl: (props.metadata && props.metadata.hlsUrl) || '',
      };
      if ((typeof props.headerType === 'object' && props.headerType === null) || (typeof props.headerType === 'string' && props.headerType.length < 2)){
        delete data.headerType;
      }
      if(props.headerType === 'text/html'){
        data.summary = props.summary;
      } 
      await dispatch(updateArticleMeta(tempArticleId, data));
      e && setSavingMeta(false);
      e && history.push('/dash/create/');

    } catch (err) {
      e && setSavingMeta(false);
      const { message } = err;
      dispatch(setAppMessage({ message: message || 'Unable to save image article.', header: 'Error', type: 'alert' }));
      return Promise.reject(err);
      // handle error
    }
  }
  const publish = async () => {
    try {
      setPublishing(true);
      await setMeta(false);
      if (props.metadata && props.metadata.status === 4) {
        (props.metadata.productIdArr && props.metadata.productIdArr.length > 0 ?
          await dispatch(clearCache(props.articleId))
          :
          await dispatch(clearCache(props.articleId, {
            productIdArr: (checked && isSendEmail ? [productInfo && productInfo.productId] : []),
          })))
        setPublishing(false);
        dispatch(setAppMessage({
          message: 'Article published' || '',
          handleSuccess: () => { history.push('/dash/create'); }, header: 'Success', type: 'alert',
        }));
        return;
      }
      const dataObj =
        { publishTime: moment(selectedDate).unix(), productIdArr: [productInfo && productInfo.productId] }
      if (!selectedDate) {
        delete dataObj.selectedDate
      }
      if (!checked) {
        dataObj.productIdArr = [];
      }

      if (!userPermissions.includes('SHARE:NEWSLETTER')) {
        dataObj.productIdArr = [];
      }
      if (!isSendEmail) {
        dataObj.productIdArr = [];
      }
      const res = await dispatch(publishArticle(props.articleId, dataObj));
      dispatch(setAppMessage({
        message: res.data.result || '',
        handleSuccess: () => { history.push('/dash/create'); }, header: 'Success', type: 'alert',
      }));
      setPublishing(false);
    }
    catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      setPublishing(false);
      const { message, data:{code} } = err;
      if (code && code === 'ERR_NO_VERIFY') {
        dispatch(setAppMessage({ 
          message: message,
          leftButtonText: 'Cancel',
          rightButtonText: 'Verify email',
          handleSuccess: async () => {
            try {
              await dispatch(sendVerificationLink());
              dispatch(setAppMessage({ message: 'Verfication link sent to your mail.', header: 'Success', type: 'alert' }));
            } catch (err) {
              dispatch(setAppMessage({ message: err.message|| 'unable to send verification link', header: 'Error', type: 'alert' }));
            }
          },
          header: 'Error' }));
      } else {
        dispatch(setAppMessage({ message: message, header: 'Error', type: 'alert' }));
      }
    }
  }

  const setActiveSection = (e,val) => {
    let option = e ? e.target.value :val;
    try {
      const { sub_section = [] } = option;
      setSubSection([ ...sub_section]);
      props.setSubSectionDefaultVlaue('');
      props.setSubSubSectionDefaultVlaue('');
      setSubSubSection([]);
      props.setSectionDefaultVlaue(option);
      setActiveOption(option);
      props.setSectionID(option.id);
      props.setSubSectionID(null);
      props.setSubSubSectionID(null);
    }
    catch (err) {
      console.log("err", err)
    }

  }
  const setActiveSubSection = (e,val) => {
    let option = e ? e.target.value : val;
    try {
      const { sub_sub_section = [] } = option;
      setSubSubSection([ ...sub_sub_section]);
      props.setSubSectionDefaultVlaue(option);
      props.setSubSubSectionDefaultVlaue('');
      setActiveOption(option);
      props.setSubSectionID(option.id);
      props.setSubSubSectionID(null);
    }
    catch (err) {
      console.log("err",err)
    }
  }

  const setActiveSubSubSection = (e,val) => {
    let option = e ? e.target.value :val;
    setActiveOption(option);
    props.setSubSubSectionID(option.id);
    props.setSubSubSectionDefaultVlaue(option);
  }

  const openSectionDialog = () => {
    setOpenSecDialog(true);
  }
  const handleClickEditOpen = (option, lvl) => {
    setLevel(lvl)
    setActiveOption(option)
    renameSection(option.name);
    setOpenSecDialog(true);
  }
  const handleClose = () => {
    if(level===1){
      props.setSectionDefaultVlaue(lastSectionVal)
    }
    if(level===2){
      props.setSubSectionDefaultVlaue(lastSubsectionVal)
    }
    if(level===3){
      props.setSubSubSectionDefaultVlaue(lastSubSubSectionVal)
    }
    setOpenSecDialog(false);
    setNew(false);
  }
  const onUpdateSection = async () => {
    try {
      setLoading(true)
      await dispatch(updateSection(activeOption.id, { name: sectionvalue, slug: kebabCase(sectionvalue) }));
      finalSection.forEach((ele) => {
        if (ele.id === activeOption.id) {
          ele.name = sectionvalue;
        }
      })

      subSection.forEach((ele) => {
        if (ele.id === activeOption.id) {
          ele.name = sectionvalue;
        }
      })

      subsubSection.forEach((ele) => {
        if (ele.id === activeOption.id) {
          ele.name = sectionvalue;
        }
      })

      renameSection('');

      setLoading(false)
      setOpenSecDialog(false);
      setNew(false);
    }
    catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  const getID = () => {
    if (level === 1) {

      return 0
    }
    if (level === 2) {

      return props.sectionID
    }
    if (level === 3) {

      return props.subSectionID
    }
  }

  const onCreateSection = async () => {
    try {
      if (!sectionvalue) {
        return;
      }
      setLoading(true);
      const { metadata } = await dispatch(createSection({
        name: sectionvalue,
        type: kebabCase(sectionvalue),
        parent_section: getID(),
        hierarchy: level - 1,
        status: 1,
      }));
      setFinalSection([ ...sections]);

      if (level == 1) {
        setFinalSection([...finalSection, metadata]);
        setActiveSection('',metadata)
      }
      if (level == 2) {
        finalSection.forEach((item) => {
          if (item.id === props.sectionID) {
            item.sub_section = [...subSection, metadata];
          }
        })
        setFinalSection([...finalSection])
        setSubSection([...subSection, metadata]);
        setActiveSubSection('',metadata)
        
      }
      if (level == 3) {
        finalSection.forEach((item) => {
          if (item.id === props.sectionID) {
            const { sub_section } = item;
            sub_section.forEach((subitem) => {
              if (subitem.id === props.subSectionID) {
                subitem.sub_sub_section = [...subsubSection, metadata];
              }
            });
            item.sub_section = sub_section;
          }
        });
        setActiveSubSubSection('',metadata);
        setFinalSection([...finalSection])
        setSubSubSection([...subsubSection, metadata]);
      }
      renameSection('');
      setLoading(false)
      setOpenSecDialog(false);
      setNew(false);
    }
    catch (err) {
      console.log("Err",err)
      setLoading(false)
      dispatch(setAppMessage({ message: err.message || 'something went wrong!!!.', header: 'Error', type: 'alert' }));
    }
  }

  const addSections = (lvl) => {
    
    if(lvl===1){
      setLastSectionVal(props.sectionDefaultvalue)
    }
    if(lvl ===2){
      setLastSubSectionVal(props.subSectionDefaultvalue)
    }
    if(lvl===3){
      setLastSubSubSectionVal(props.subSubSectionDefaultvalue)
    }

    setNew(true);
    setLevel(lvl);
    openSectionDialog();
  }
  const getTitle = () => {
    if (opennew) {
      if (level === 2) return "Add Subsection"
      if (level === 3) return "Add SubSubsection"
      return "Add section"

    }
    else {

      if (level === 2) return "Edit Subsection"
      if (level === 3) return "Edit SubSubsection"
      return "Edit Section"
    }
  }

  const removeDate = (e) => {
    e.stopPropagation();
    setSelectedDate(null);
  }

  const deleteArticlePopup = () => {
    dispatch(setAppMessage({
      message: '',
      leftButtonText: 'Close',
      rightButtonText: 'Delete',
      handleSuccess: props.handleDeleteArticle, header: `Are you sure you want to delete ${getLabel()} ?`,
    }));
  }

  return (
    <>
      <ImageEditor
        open={showImageEditor}
        closeDialog={closeImageEditor}
        getCropData={getCropData}
        baseContent={imageContent} />
      <Dialog fullScreen open={props.open} onClose={props.closeDialog} >
        <Grid container>
          {/**----------------------------Header Section---------------------------- */}
          <Header>
            <Grid container justify="center">
              <Grid item xs={12} md={12}>
                <Box display="flex" alignItems="center" justifyContent="space-between" ml={isMobile ? 4 : 25} mr={isMobile ? 4 : 25} >
                  <Box display="flex" alignItems="center" height="100%">
                    <ArrowBackIcon style={{ color: 'white' }} onClick={props.goToBack} />
                  </Box>
                  <Box component="p" display="flex" justifyContent="center">AUDIENCE & VISIBILITY</Box>
                  <Box component="p" >Step 2 of 2</Box>
                </Box>
              </Grid>
            </Grid>
          </Header>

          {/**----------------------------End Header Section---------------------------- */}


          {/**---------------------------Main Container Section--------------------------*/}
          {props.savingMeta && <LinearLoader position="sticky" top={`${isMobile ? '70px' : '75px'}`} width="100%" />}
          <Grid container justify="center" >
            <Grid container justify="center" >
              <Grid item xs={12} md={8}>
                <Grid container>
                  <Grid item md={7} xs={12} className={isMobile ? classes.mobileLayout : {}}>
                    <Box mt={6} mb={3}>
                      <Box component="span" fontSize={14} fontWeight="bold" color="primary.main" >Select Section</Box>
                    </Box>
                    
                    <Box display="flex" flexWrap={isMobile ? "wrap" : "nowrap"}>

                      <FormControl className={classes.formControl} variant="outlined" size="small">
                        {/* <InputLabel id="section-simple-select-helper-label">Section</InputLabel> */}
                        <Select
                          labelId="section-simple-select-helper-label"
                          id="section-first-level"
                          value={props.sectionDefaultvalue ||''}
                          onChange={setActiveSection}
                          label="Section"
                          renderValue ={(item)=>item.name}
                        >
                          <MenuItem value={""}  >
                            <Box onClick={() => addSections(1)} width="100%" height="100%">
                              <AddIcon fontSize="small" /> Add section
                            </Box>
                          </MenuItem>
                          {finalSection.map((option, key) => <MenuItem 
                          key={key} value={option}><Box display="flex" width="100%" height="100%" alignItems="center"
                            justifyContent="space-between">
                            <Box width="100%" height="100%">{option.name}</Box>
                            <IconButton>
                              <EditIcon fontSize="small"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleClickEditOpen(option, 1)
                                }}
                              />
                            </IconButton>
                          </Box></MenuItem>)}
                        </Select>
                        {/* <FormHelperText>Some important helper text</FormHelperText> */}
                      </FormControl>

                      {/* <Box display="flex" width="100%">
                        <Autocomplete
                          id="section"
                          size="small"
                          fullWidth
                          disableClearable
                          options={finalSection}
                          defaultValue={sectionDefaultvalue}
                          classes={{ paper: classes.AutoCompleteRoot }}
                          style={{ width: isMobile ? '100%' : '100%' }}
                          getOptionLabel={(option) => option.name === 'create' ? option.name : option.name}
                          renderOption={option => (option.name === 'create' ? <Box position="sticky"
                            onClick={() => addSections(1)} width="100%" display="flex" alignItems="center">
                            <AddIcon fontSize="small" /> Add section
                        </Box> : <Box display="flex" width="100%" height="100%" alignItems="center"
                              justifyContent="space-between">
                              <Box width="100%" height="100%" onClick={() => setActiveSection(option)}>{option.name}</Box>
                              <IconButton>
                                <EditIcon fontSize="small"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handleClickEditOpen(option, 1)
                                  }}
                                />
                              </IconButton>
                            </Box>

                          )}
                          renderInput={params => <Input {...params} fullWidth label="section*" variant="outlined" />}
                        />
                      </Box> */}

                      {/* <Box display="flex" width="100%" ml={isMobile ? 0 : 2} mt={isMobile ? 3 : 0}>
                        <Autocomplete
                          id="subsection"
                          size="small"
                          disableClearable
                          disabled={subSection && subSection.length === 0}
                          options={subSection}
                          defaultValue={subSectionDefaultvalue}
                          value={subSectionDefaultvalue}
                          getOptionLabel={(option) => option.name === 'create' ? option.name : option.name}

                          classes={{ paper: classes.AutoCompleteRoot }}
                          style={{ width: isMobile ? '100%' : '100%' }}
                          renderOption={option => option.name === 'create' ? <Box position="sticky"
                            onClick={() => addSections(2)} width="100%" display="flex" alignItems="center">
                            <AddIcon fontSize="small" /> Add subsection
                        </Box> : <Box display="flex" width="100%" height="100%" alignItems="center"
                              justifyContent="space-between"><Box width="100%" height="100%"
                                onClick={(event) => { setActiveSubSection(option) }}>{option.name}{
                                }</Box>

                              <IconButton>
                                <EditIcon fontSize="small"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handleClickEditOpen(option, 2)
                                  }}
                                />
                              </IconButton>

                            </Box>}
                          renderInput={params => <Input {...params} fullWidth label="subsection" variant="outlined" />}
                        />
                      </Box> */}
                      <FormControl className={classes.formControl}  variant="outlined" disabled={!finalSection.length} size="small">
                        {/* <InputLabel id="demo-simple-select-helper-label">Subsection</InputLabel> */}
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="subsection-first-level"
                          value={props.subSectionDefaultvalue||''}
                          onChange={setActiveSubSection}
                          renderValue ={(item)=>item.name}
                        >
                          <MenuItem value={""}  >
                            <Box onClick={() => addSections(2)}  width="100%" height="100%">
                              <AddIcon fontSize="small" /> Add Subsection
                            </Box>
                          </MenuItem>
                          {subSection.map((option, key) => <MenuItem 
                          key={key} value={option}><Box display="flex" width="100%" height="100%" alignItems="center"
                            justifyContent="space-between">
                            <Box width="100%" height="100%">{option.name}</Box>
                            <IconButton>
                              <EditIcon fontSize="small"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleClickEditOpen(option, 2)
                                }}
                              />
                            </IconButton>
                          </Box></MenuItem>)}
                        </Select>
                        {/* <FormHelperText>Some important helper text</FormHelperText> */}
                      </FormControl>
                      <FormControl className={classes.formControl}variant="outlined"  
                      disabled={(!subSection.length || !props.subSectionDefaultvalue)} size="small">
                        {/* <InputLabel id="demo-simple-select-helper-label">SubSubsection</InputLabel> */}
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="subsubsection-first-level"
                          value={props.subSubSectionDefaultvalue||''}
                          onChange={setActiveSubSubSection}                          
                          renderValue ={(item)=>item.name}
                        >
                          <MenuItem value={""}  >
                            <Box onClick={() => addSections(3)}  width="100%" height="100%">
                              <AddIcon fontSize="small" /> Add SubSubsection
                            </Box>
                          </MenuItem>
                          {subsubSection.map((option, key) => <MenuItem 
                          key={key} value={option}><Box display="flex" width="100%" height="100%" alignItems="center"
                            justifyContent="space-between">
                            <Box width="100%" height="100%">{option.name}</Box>
                            <IconButton>
                              <EditIcon fontSize="small"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleClickEditOpen(option, 3)
                                }}
                              />
                            </IconButton>
                          </Box></MenuItem>)}
                        </Select>
                        {/* <FormHelperText>Some important helper text</FormHelperText> */}
                      </FormControl>
                    </Box>
                    <Box>
                      {!!(props.domainInfo && props.domainInfo.subscription_enabled) && <Grid item xs={12} md={8}>
                        <Box mt={6} mb={2}>
                          <Box component="span" fontSize={14} fontWeight="bold" color="primary.main" >
                            Subscriber's Only
                              </Box>
                        </Box>
                        <Box className={classes.subscriptionBox} >
                          <Button type="group"
                            groupButton={
                              {
                                number: 2,
                                texts: ['YES', 'NO'],
                                buttonColors: [`${paidContent ? 'primary' : 'secondary'}`, `${!paidContent ? 'primary' : 'secondary'}`],
                                onClickListeners: [enablePrice, disablePrice],
                                variants: ['contained', 'contained'],
                              }
                            }
                          />

                        </Box>
                        <Box mt={1} color="primary.dark" fontSize={12}>
                          {
                            paidContent ? <>
                              This post will be accessible to only paid subscribers of your subscription products.</>
                              : <>This post will be accessible to everyone</>
                          }

                        </Box>
                        <Box>

                        </Box>
                      </Grid>}
                    </Box>
                    {!!(props.domainInfo && props.domainInfo.subscription_enabled) && productInfo && !!productInfo.subscribeCount &&
                      !(props.metadata && props.metadata.status === 4 && props.metadata.productIdArr && props.metadata.productIdArr.length > 0) &&
                      userPermissions.includes('SHARE:NEWSLETTER') && isSendEmail &&
                      <Box mt={4}>
                        <Box color="primary.main" fontWeight="bold" fontSize={14}>Notify by Email</Box>
                        <Box mt={1} color="primary.dark" fontSize={12}>
                          Kindly review all settings. Email can be sent only once for this post.
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Button type="checkbox" size="small" onChange={emailChanges} checked={checked} />
                          <Box component="span" color="primary.main" fontWeight="bold"
                            fontSize={12}>{paidContent ? 'Send email to paid subscribers' : 'Send email to all subscribers'}</Box>
                        </Box>
                      </Box>
                    }
                    {props.metadata.status !== 4 &&
                      <Box mt={6}>
                        <Box>
                          <Box fontSize={14} component="span" fontWeight="bold" color="primary.main">Schedule Post</Box>
                        </Box>
                        <Box mt={4}>
                          <Button color="secondary" variant="contained" onClick={openDatePickerFun}>
                            {selectedDate ? <>{moment(selectedDate).format('LLLL')}
                              <CancelOutlinedIcon fontSize="small" onClick={removeDate} /></> : 'Schedule'}</Button>

                        </Box>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <KeyboardDateTimePicker
                            open={openDatePicker}
                            disablePast
                            onOpen={() => openDatePickerFun(true)}
                            onClose={() => openDatePickerFun(false)}
                            margin="normal"
                            id="date-picker-dialog"
                            label="Date picker dialog"
                            format='DD MMM YYYY'
                            value={selectedDate}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                            onError={console.log}
                            TextFieldComponent={CustomDatePickerField}

                          />
                        </MuiPickersUtilsProvider>

                      </Box>
                    }
                  </Grid>
                  <Grid item md={5} xs={12} className={classes.coverImageSection}>
                    <Box mt={6} mb={3}>
                      <Box component="span" fontSize={14} fontWeight="bold" color="primary.main" >Card Preview</Box>
                    </Box>
                    <Box width={397} height={215} className={classes.coverImageContainer}>
                      <img src={props.thumbnail || getThumbnail()} width='100%' height='100%' alt="image_article" />
                    </Box>
                    <Box fontSize={16} color={"primary.main"} fontWeight="bold" mt={4}>
                      {props.title}
                    </Box>
                    <Box fontSize={12} color={"primary.dark"} mt={3}>
                      {props.summary}
                      {props.metadata && props.metadata.richDescription &&
                       <Box dangerouslySetInnerHTML={{ __html: props.metadata.richDescription.html }}></Box>}
                    </Box>
                    <Box>

                    </Box>
                    <Box mt={3} width={isMobile ? "100%" : 397}>
                      <Button variant="contained" fullWidth color="secondary" onClick={props.openPreview}>
                        Preview
                      </Button>
                    </Box>

                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Box height={80} width="100%">

            </Box>
          </Grid>
          {/**---------------------------End Main Container Section--------------------------*/}

          {/**----------------------------Footer Section-------------------------------------*/}
          <Grid item xs={12} md={12}>
            <Footer>
              {<Grid container justify="center">
                <Grid item md={8} xs={12} className={isMobile ? classes.mobileLayout : {}}>
                  <Box display="flex" justifyContent={props.metadata.status === 4 ? "flex-end" : "space-between"}>
                    {props.metadata.status !== 4 &&
                      <Box display="flex">
                        <Box display="flex">
                          <Button
                            color="secondary"
                            variant="contained"
                            disabled={savingMeta || uploadingCover || props.deleteLoader}

                            onClick={setMeta}
                          > {isMobile ? <DraftsIcon /> : 'Save Draft'}</Button>
                          {savingMeta && <CircularLoader height="auto" size={20} />}
                        </Box>

                        <Box display="flex">
                          <Button variant="contained" disabled={props.deleteLoader} boxProps={{ ml: 3 }} color="secondary" onClick={deleteArticlePopup}>
                            <DeleteIcon />
                          </Button>
                          {props.deleteLoader && <CircularLoader height="auto" size={20} />}
                        </Box>
                      </Box>
                    }
                    <Box display="flex">
                      <Button
                        variant="contained"
                        disabled={isPublishing || savingMeta || uploadingCover}
                        onClick={publish}
                        boxProps={{ ml: 4 }}
                      >{props.metadata.status === 4 ? 'SAVE CHANGES' : selectedDate ? 'Publish later' : 'Publish'}</Button>
                      {isPublishing && <CircularLoader size={20} height="auto" />}
                    </Box>
                  </Box>



                </Grid>
              </Grid>}


            </Footer>
          </Grid>
          {/**----------------------------End Footer Section-------------------------------------*/}
        </Grid>

      </Dialog>
      <Dialog
        open={openSecDialog}
      >
        <DialogTitle id="alert-dialog-title">{getTitle()}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Input
              value={sectionvalue}
              onChange={(e) => renameSection(e.target.value)}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose} color="default">
            CLOSE
          </Button>
          <Button disabled={isLoading} variant="contained" onClick={opennew ? onCreateSection : onUpdateSection} color="primary">
            OK
          </Button>
          {isLoading && <CircularLoader size={20} height="auto" />}
        </DialogActions>

      </Dialog>
    </>
  )
}
export default TextArticleMetaHome;
