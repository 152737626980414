import React from "react";
import {useDispatch} from "react-redux";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { makeStyles } from '@material-ui/core/styles';
import TwitterLogin from "react-twitter-auth";
import {getTwitterPages, updateUser} from '../social-action';
import Box from "../../../commons/muiCustomComponents/Box";

const useStyles = makeStyles(theme => ({
  addButton: {
    background: theme.palette.pubLightGrey,
    color: theme.palette.pubGrey,
    height: theme.spacing(10),
    fontWeight: "bold",
    width: "100%",
    border: 0,
    marginTop: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  circleIcon: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    color: theme.palette.pubGrey,
    borderRadius: "50%",
  },
  addText: {
    paddingLeft: theme.spacing(3),
    fontSize: theme.spacing(3.5),
  },
}));

const AddTwitterButton = (props) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const {setLoader} = props;

  // const loginUrl = `https://dev.pubninja.com/schedule/auth/twitter`, 
  //   requestTokenUrl = `https://dev.pubninja.com/schedule/15/auth/twitter/reverse`;

  const loginUrl = `${process.env.REACT_APP_API_SERVER}/schedule/auth/twitter`, 
    requestTokenUrl = `${process.env.REACT_APP_API_SERVER}/schedule/15/auth/twitter/reverse`;

  const onFailed = () => {
    // failed
  }

  const onSuccess = (response) => {
    response.json().then(user => {
      if(user){        
        updateTwitterUser(user);
      }
    });
  }

  async function updateTwitterUser(user){
    try{
      setLoader(true);
      await dispatch(updateUser(user));
      await dispatch(getTwitterPages());
      setLoader(false);
    } catch(err){
      setLoader(false);
    }
  }

  return (
    <TwitterLogin
      loginUrl={loginUrl}
      onFailure={onFailed}
      onSuccess={onSuccess}
      requestTokenUrl={requestTokenUrl}
      className={classes.addButton}  
      ref={props.setRef}    
    >
      <Box component={"span"}><AddCircleIcon className={classes.circleIcon} /></Box>
      <Box component={"span"} className={classes.addText}>Add Twitter Account</Box>
    </TwitterLogin>
  )
}

export default AddTwitterButton;