import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from "../../commons/muiCustomComponents/Button";
import AppBar from '../../commons/muiCustomComponents/AppBar';
import Box from "../../commons/muiCustomComponents/Box";
import DomainList from "./components/DomainList";
import { useDispatch, useSelector } from "react-redux";
import {useHistory}  from "react-router-dom";
import Grid from "commons/muiCustomComponents/Grid";
import { fetchDomainList, updateActiveDomain, fetchCategories } from "./header-action";
import { LogglyTracker } from 'loggly-jslogger';

const logger = new LogglyTracker();
logger.push({ 'logglyKey': '142d21f5-10d3-47a9-aa5a-f83e0a3abed7', 'sendConsoleErrors' : true, 'tag' : 'ardbeg,header,header-home' });

const useStyles = makeStyles(theme => ({
  AppBar: {
    top: 0,
    backgroundColor:theme.palette.pubBackground,
  },
  root: {
    flexGrow: 1,
  },
  toolbar: {
    display:'flex',
    justifyContent:'space-between',
    minHeight:'80px',
    // borderBottom:'1px solid grey',
    // padding:`0px ${theme.spacing(5)}px`,
  },
  goTOWebsite :{
    backgroundColor:theme.palette.secondary.light,
    border:'none',
    "&:hover,&:focus": {
      backgroundColor:theme.palette.secondary.light,
      border:'none',
    },
  },
  earnings: {
    // textAlign: "center",
    //  marginRight: theme.spacing(2),
  },
  profile: {
    alignContent: "center",
    justifyContent: "center",
    cursor:'pointer',
  },
  title: {
    textAlign: "center",
  },
  paper: {
    paddingBottom: 15,
  },
  avatorColor :{
    backgroundColor:theme.palette.secondary.main,
  },
  bigAvator :{
    width: theme.spacing(9),
    height : theme.spacing(9),
    fontSize:theme.spacing(3),
  },
  profileContainer :{
    display:'flex',
    justifyContent:'flex-end',
  },
}));


export default function HeaderHome(props) {

  const classes = useStyles();
  const dispatch = useDispatch();
  const { header, landing: { 
    activeDomain, 
    userProfile: { profilePicture, firstName, lastName, email }, 
    userPermissions,
    featurePermissions,
  }, insights: {
    revenueHeader,
  } } = useSelector(state => state);
  const { domainList } = header;
  const history = useHistory();

  const createName = () => {
    if(firstName && lastName) {
      return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase()
    }
    if(firstName) {
      return `${firstName.charAt(0)}${firstName.charAt(1)}`.toUpperCase()
    }
    return 'User'
  }
  const updateCurrentDomain = (activeDomain) => {
    return dispatch(updateActiveDomain(activeDomain));
  }

  const fetchCategoriesList = () => {
    return dispatch(fetchCategories());
  }
  function fetchDomainNames() {
    dispatch(fetchDomainList());
  }
  function goToProfile(){
    if(! featurePermissions.includes('QUICK_CMS')) return;
    history.push("/dash/user/profile")
  }
  function goToInsights(){
    if(! featurePermissions.includes('QUICK_CMS')) return;
    history.push("/dash/analytics/earnings");
  }

  useEffect(() => {
    fetchDomainNames();
  }, []);

  let totalRevenue = 0;
  const getReveue = () => {
    let sum = 0;

    for(let i=0; i<revenueHeader.length;i++){
      sum = sum + revenueHeader[i].adsRev + revenueHeader[i].subsRevUsd;
    }
    return(sum);
  }

  if(revenueHeader){
    totalRevenue = getReveue();
  }

  const openWebsite = ()=>{
    try{
      window.open(`https://${domainList[activeDomain][0].fqdn}`, '_blank');
    }
    catch(err){
      // eslint-disable-next-line no-console
      console.log("err")
      const data = {
        'error': err,
        'email': email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
    }
  }
  
  const showInsights = userPermissions.includes("EARN:READ_INSIGHTS_REVENUE");

  return (
    <React.Fragment>
      <AppBar elevation={0} position="fixed" className={classes.AppBar}>
        <Toolbar className={classes.toolbar} disableGutters>
          <Box className={classes.title} display="flex">
            <Box width={300}>
              <Box ml={12.5} mr={9}>
              <DomainList
              domains={domainList}
              activeDomain={activeDomain}
              fetchCategories={fetchCategoriesList}
              updateCurrentDomain={updateCurrentDomain} 
              fqdn = {(domainList &&
                domainList[activeDomain]  && 
             domainList[activeDomain][0] &&  domainList[activeDomain][0].fqdn)}
              />
              </Box>
            
            </Box>
          </Box>
          <Grid container>
            <Grid item xs={12} md={9} xl={10}>
              <Box display="flex" justifyContent="space-between">
              {showInsights && <Box className={classes.earnings}>
              <Box display="inline-block"  onClick={goToInsights}>
                <Button color="secondary" variant="contained" > SEE EARNINGS </Button>
              </Box>
            </Box>}
            <Button variant="outlined" className={classes.goTOWebsite} onClick={openWebsite}>
             GO TO WEBSITE
           </Button>
            </Box>
            </Grid>
            <Grid item xs ={12} md={3} xl={2} className={classes.profileContainer}>
            
           
          <Box display="inline-block" className={classes.profile} onClick={goToProfile} mr={12.5}>
            <Avatar  className={classes.bigAvator} classes ={{colorDefault : classes.avatorColor}}  src={profilePicture}>{createName()}</Avatar>
          </Box>
         
            </Grid>
          </Grid>
       
         
          
        </Toolbar>
      </AppBar>

    </React.Fragment>
  );
}
