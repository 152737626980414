import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from "../../commons/muiCustomComponents/Box";
import InputBox from "../../commons/muiCustomComponents/InputBox";
import Button from "../../commons/muiCustomComponents/Button";
import { CircularLoader, PageBlockLoader,LinearLoader } from '../../commons/commonWrapper/Loaders';
import fetchDomainDetails, { setDomainDetails } from "./settings-action";
import { useDispatch } from "react-redux";
import MobileProvider from "commons/context/MobileProvider";
import Grid from "../../commons/muiCustomComponents/Grid";
import setAppMessage from "../../app-component/app-component-action";
import Footer from "commons/commonWrapper/Footer";
import Paper from "commons/muiCustomComponents/Paper";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  iconWidth: {
    width: '100%',
    height: '100%',
  },
}));

export default function GoogleAnalytics({ appbarheight }) {

  const classes = useStyles();
  const isMobile = useContext(MobileProvider);
  const [isFetching, setfetching] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [domainDetail, setDomainDetail] = useState({});
  const [gaId, setGAId] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    let isMounted = true;
    const getDetails = async () => {
      try {
        setfetching(true)
        const detail = await dispatch(fetchDomainDetails())
        // eslint-disable-next-line camelcase
        const { ga_id } = detail;
        isMounted && setDomainDetail(detail)
        isMounted && setGAId(ga_id)
        isMounted && setfetching(false)
      } catch (err) {
        isMounted && setfetching(false)
      }
    }
    getDetails()
    return () => isMounted = false
  }, []);

  const handleGaId = (e) => {
    const ga = e.currentTarget.value;
    setGAId(ga);
  }

  const saveDomainDetails = async () => {
    if (!gaId){
      dispatch(setAppMessage({ message: "GA id can't be empty", header: 'Error', type: 'alert' }))
      return 
    } 
    try {
      setLoading(true)
      // eslint-disable-next-line camelcase
      await dispatch(setDomainDetails({ ...domainDetail, ...{ ga_id: gaId } }))
      setLoading(false)
    }
    catch (err) {
      setLoading(false)
    }
  }

  return (
    <>
    <Grid container>
    {isMobile && isFetching && <LinearLoader top={appbarheight} />}
      <Grid item xs={12} md={9} xl={10}>
          <Box m ={isMobile ?2 : 0} mt={4} mb={4} >
            <Paper>
                  {!isMobile && isFetching && <PageBlockLoader/>}
      <Grid container>
        <Grid item xs={12} md={4}>
          <Box className={classes.root} >
            <Box>
              <InputBox
                heading="Your Google analytics ID"
                fullWidth
                color="secondary"
                value={gaId || ''}
                onChange={handleGaId}
                headingStyle={{
                  fontSize: 14,
                  color: "pubGrey",
                  fontWeight: 500,
                  mb: 1,
                  mt:0,
                }}
              />
              <Box component="p" color="pubbrownGrey" fontSize={12}>
                Connect your google analytics account with
                your website.
          </Box>
            </Box>
            {isMobile ?<Footer>
            <Box display="flex" width="80%" >
              <Button fullWidth disabled={isLoading} variant="contained" onClick={saveDomainDetails}>
                Submit
             </Button>
              {isLoading && <CircularLoader height="auto" size={20} ml={1} />}
            </Box>
            </Footer>:
            <Box display="flex" mt={3} >
            <Button fullWidth disabled={isLoading} variant="contained" onClick={saveDomainDetails}>
              Submit
           </Button>
            {isLoading && <CircularLoader height="auto" size={20} ml={1} />}
          </Box>
            }
          </Box>
        </Grid>
      </Grid>
      </Paper>
          </Box>
      </Grid>
    </Grid>
    </>
  );
}
