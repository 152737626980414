
import React, {useState} from "react";
import FullScreenDialog from "../../../commons/commonWrapper/FullScreenDialog";
import Button from "../../../commons/muiCustomComponents/Button";
import Box from "../../../commons/muiCustomComponents/Box";
import MobileSearch from "../../../commons/commonWrapper/MobileSearch";
import { makeStyles } from '@material-ui/core/styles';
import Search from "../../common/Search/Search";

const useStyles = makeStyles(theme => ({
  buttons: {
    borderRadius: theme.spacing(4),
    margin:theme.spacing(1),
  },
  filterContainer: {
    width:'95%',
    overflow: "auto",
    whiteSpace: "nowrap",
  },
}));

export default function Filters(props){
  const classes = useStyles();
  const [filters, setFilters] = useState({
    text: true,
    video: true,
    audio: true,
    image: true,
  });

  const changeFilter = async (filter) => {
    if(props.isLoadingArticle)return;
    setFilters({...filters, [filter]: !filters[filter]});
    props.applyFilters({...filters, [filter]: !filters[filter]});
  }

  return(
    <Box
      className={classes.filterContainer}
      display="flex"
      alignItems="center"
      pt={4}
      m="auto"
      ml={4}
      mr={4}
    >
      <FullScreenDialog type="search" BarComponent={MobileSearch} buttonText="search" size="small" color="secondary" style={{ background: 'white' }} >
         { (appBarHeight)=> <Search appBarHeight ={appBarHeight}/> }
      </FullScreenDialog>

      <Button variant="contained" color={filters['text'] ? 'secondary':'default'}
        className={classes.buttons}
        onClick={()=>changeFilter('text')}
      >Article</Button>
      <Button variant="contained" color={filters['video'] ?  'secondary':'default'}
        className={classes.buttons}
        onClick={()=>changeFilter('video')}
      >Video</Button>
      <Button variant="contained" color={filters['image'] ?  'secondary':'default'}
        className={classes.buttons}
        onClick={()=>changeFilter('image')}
      >Image</Button>
      <Button variant="contained" color={filters['audio'] ?  'secondary':'default'}
        className={classes.buttons}
        onClick={()=>changeFilter('audio')}
      >Audio</Button>
    </Box>
  );
}
