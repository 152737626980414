import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Button from "commons/muiCustomComponents/Button";
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import { LinearLoader } from '../../../commons/commonWrapper/Loaders';
import FullScreenDialog from "commons/commonWrapper/FullScreenDialog";
import DNSform from "./DNSForm";
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import MobileProvider from "commons/context/MobileProvider";
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from "../../../commons/muiCustomComponents/Box";
import AddIcon from '@material-ui/icons/Add';

const columns = [
  { id: 'type', label: 'Type' },
  { id: 'name', label: 'Name' },
  {
    id: 'content',
    label: 'Content',
    align: 'left',
    format: value => value.toLocaleString(),
  },
  {
    id: 'ttl_val',
    label: 'TTL',
    align: 'left',
    format: value => value.toLocaleString(),
  },
  {
    id: 'proxied',
    label: 'Proxy Status',
    align: 'left',
    format: value => value.toFixed(2),
  },
  {
    id: "editable",
    label: "Actions",
    align: 'left',
    format: value => value.toFixed(2),
  },
];

const useStyles = makeStyles((theme)=>({
  root: {
    width: '100%',
    backgroundColor: theme.palette.pubWhite,
    boxShadow:theme.shadows[1],
    borderRadius:theme.spacing(6*0.25),
  },
  container: {
    maxHeight: 400,
  },
  cursor: {
    cursor: 'pointer',
  },
  footerPadding :{
    // paddingRight: theme.isMobile ? 0 :theme.spacing(25),
    // display:'flex',
    // flexWrap:theme.isMobile?'wrap':'nowrap',
  },
  uppercontainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },
  productBackground :{
    backgroundColor:theme.palette.secondary.light,
    border:'none',
    "&:hover,&:focus": {
      backgroundColor:theme.palette.secondary.light,
      border:'none',
    },
  },
}));

export default function DNSRecord({ dnsData, 
  deleteSingalRecords,
  updateRecords,
  host ,
  addRecords,
  updateContent,
}) {
  const classes = useStyles();
  const isMobile = useContext(MobileProvider);
  const [page, setPage] = React.useState(0);
  const [isLoading, setLoading] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isOpenDialog, setOpenDialog] = React.useState(false);
  const [currentItem ,setCurrentItem] = React.useState(null);
  const [isOpenAddDNS ,setOpenADDDNS] =  React.useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const deleteRecord = async (id) => {
    try {
      setLoading(true)
      await deleteSingalRecords({ id })
      updateContent();
      setLoading(false)
    }
    catch (err) {
      setLoading(false)
    }
  }
  const editRecord = async (id) => {
    const formData = dnsData.find((item)=> item.id ===id);
    setCurrentItem (formData)
    setOpenDialog(true)
  }

  const handleClose = ()=>{
    setOpenDialog(false)
  }

  const handleAddNewDNS = ()=>{
    setOpenADDDNS(true)
  }
  const handleAddClose = ()=>{
    setOpenADDDNS(false)
  }

  return (
    <>
       <Box mt={4} className={classes.root}>
        {isLoading && <LinearLoader width="100%" />}

          <Box bgcolor="pubPrimary" height="75px" className={classes.uppercontainer}>
          <Box component="span" color="primary.main" display="flex" flexDirection="column">
          Manage Your Domain
                {/* <Box component="span" fontSize={12} color="primary.main">
              Create a flexible list for your audience, add or customise benefits and create payment plans.
                </Box> */}
          </Box>
          <Button variant="outlined" className={classes.productBackground} onClick={handleAddNewDNS} >
            <AddIcon fontSize="small" />
              Add DNS Entry
            </Button>
        </Box>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth ,fontWeight: 600}}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {dnsData && dnsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map(column => {
                      const value = row[column.id];
                      if (column.id === "editable" && value) {
                        return <TableCell key={column.id} align={column.align}>
                          <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Button color="secondary" disabled={isLoading} className={classes.cursor} onClick={()=>editRecord(row.id)}>
                            <EditIcon />
                          </Button>
                          <Button color="secondary" className={classes.cursor} disabled={isLoading} onClick={() => deleteRecord(row.id)}>
                            <ClearIcon />
                          </Button>
                          </Box>
                        </TableCell>
                      }

                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number' ? column.format(value) : `${value}`}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={dnsData.length}
          classes={{toolbar :classes.footerPadding}}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />

      </Box>
      {
        isMobile ?  <FullScreenDialog isOpen={isOpenDialog} clearPageData={handleClose}>
        <Box  ml={2} mr={2}>
        <DNSform  handleClose ={handleClose} updateContent ={updateContent} item={currentItem} host={host} updateRecords={updateRecords}/>
        </Box>
      </FullScreenDialog>:
        <Dialog
        disableEscapeKeyDown
        onClose={handleClose}
        
        maxWidth="xs"
        fullWidth
        aria-labelledby="confirmation-dialog-title"
        open={isOpenDialog}
      >
        <DialogTitle id="confirmation-dialog-title">Edit DNS Entry</DialogTitle>
        <DialogContent dividers>
            <DNSform handleClose= {handleClose}  updateContent ={updateContent} item={currentItem} host={host} updateRecords={updateRecords}/>
        </DialogContent>
      </Dialog>
      }

{
        isMobile ?  <FullScreenDialog isOpen={isOpenAddDNS} clearPageData={handleAddClose}>
        <Box ml={2} mr={2}>
        <DNSform 
        updateContent ={updateContent}
        handleClose ={handleAddClose} 
        host={host} 
        updateRecords={addRecords}/>
        </Box>
      </FullScreenDialog>:
        <Dialog
        disableEscapeKeyDown
        onClose={handleAddClose}
        maxWidth="xs"
        fullWidth
        aria-labelledby="confirmation-dialog-title"
        open={isOpenAddDNS}
      >
        <DialogTitle id="confirmation-dialog-title">Add DNS Entry</DialogTitle>
        <DialogContent dividers>
            <DNSform updateContent ={updateContent} handleClose= {handleAddClose}  host={host} updateRecords={addRecords}/>
        </DialogContent>
      </Dialog>
      }    
    </>
  );
}
