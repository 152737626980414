/* eslint-disable max-len */

import React, { useEffect, useState, useContext } from 'react';
import { getSubStatus } from "./monetization-action";
import { useDispatch, useSelector } from "react-redux";
import InputBox from "commons/muiCustomComponents/Input";
import Box from "commons/muiCustomComponents/Box";
import Dialog from "commons/muiCustomComponents/Dailog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "commons/muiCustomComponents/Grid";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Button from "commons/muiCustomComponents/Button";
import InputAdornment from '@material-ui/core/InputAdornment';
import AddIcon from '@material-ui/icons/Add';
import MobileProvider from "commons/context/MobileProvider";
import { makeStyles } from '@material-ui/core/styles';
import { connectWithStripe } from "./monetization-action";
import { useLocation } from "react-router-dom";
import { CircularLoader, PageBlockLoader } from 'commons/commonWrapper/Loaders';
import setAppMessage from 'app-component/app-component-action';


function getURL() {
  // eslint-disable-next-line no-undef
  if (process.env.REACT_APP_APP_ENV === 'development') {
    return `https://new-devdash.pubninja.com`;
  }
  // eslint-disable-next-line no-undef
  if (process.env.REACT_APP_APP_ENV === 'production') {
    return `https://dash.pubninja.com`;
  }
  if (process.env.REACT_APP_APP_ENV === 'stage') {
    return `https://stagedash.pubninja.com`;
  }
  return `http://localhost:3000`;
}

const useStyles = makeStyles(theme => ({
  stripeButton: {
    width: theme.spacing(50),
    cursor: 'pointer',
  },
  uppercontainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },
  root: {
    //width: '100%',
    backgroundColor: theme.palette.pubWhite,
  },
  providerBox: {
    marginTop: theme.spacing(4),
  },
  cashfreeForm: {
    width: '90%',
  },
  inputs: {
    padding: theme.spacing(2),
    width:'95%',
  },
  inputsFullwidth: {
    width:'98%',
    padding: theme.spacing(2),
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export default function Payments() {
  const classes = useStyles();
  const [stripeButton, showStripeButton] = useState(false);
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [clinetId, setClientId] = useState(false);
  const [showCashfreeForm, setshowCashfreeForm] = useState(false);
  const [name, setName] = useState('');
  const [accountHolderName, setAccountHolderName] = useState('');
  const [bankAccountNumber, setBankAccountNumber] = useState('');
  const [ifscCode, setIfscCode] = useState('');
  const [panNo, setPanNo] = useState('');
  const [aadharNo, setAadharNo] = useState('');
  const [gstin, setGstin] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [pincode, setPincode] = useState('');
  const [tokenID  , setToken] = useState('');
  const { landing: { activeDomain, userProfile: {email} }, monetization: {
    subscribe,
    connectedStatus,
  } } = useSelector((state) => state);
  let query = useQuery();
  const token = (query && query.get("code"));
  useEffect(()=>{
    setToken(token)
  },[token])
  const isMobile = React.useContext(MobileProvider);
  const { stripeConnected,
    stripeClientId,
    stripeAccountId = '',
  } = (subscribe && Object.keys(subscribe || {}).length > 0 && Object.keys(subscribe[activeDomain] || {}).length > 0 && subscribe[activeDomain]) || {};

  const togglesCashfreeForm = () => {
    setshowCashfreeForm(!showCashfreeForm);
  }
  useEffect(() => {
    const createAccount = async () => {
      if (stripeButton) return;
      window.history.pushState({}, document.title, "/" + "dash/monetization/subscripition");
      try {
        setLoading(true)
        await dispatch(connectWithStripe({code: token, providerName:'stripe_connected'}));
        await dispatch(getSubStatus());
        setToken('');
        setLoading(false)
      }
      catch (err) {
        setLoading(false)
      }
    }
    if (tokenID) createAccount();
    return ()=>{
      setToken('')
    }
  }, [activeDomain, tokenID, stripeButton]);

  useEffect(() => {
    setClientId(stripeClientId);
    showStripeButton(!!stripeConnected);
  }, [stripeConnected, stripeClientId, connectedStatus]);

  const handleFormInputChange = (event) => {
    if(event.target.id === 'name')  {setName(event.target.value);return;}
    if(event.target.id === 'accountHolderName') {setAccountHolderName(event.target.value);return;}
    if(event.target.id === 'bankAccountNumber') {setBankAccountNumber(event.target.value);return;}
    if(event.target.id === 'ifscCode') {setIfscCode(event.target.value);return;}
    if(event.target.id === 'panNo') {setPanNo(event.target.value);return;}
    if(event.target.id === 'aadharNo') {setAadharNo(event.target.value);return;}
    if(event.target.id === 'gstin') {setGstin(event.target.value);return;}
    if(event.target.id === 'phoneNo') {setPhoneNo(event.target.value);return;}
    if(event.target.id === 'addressLine1') {setAddressLine1(event.target.value);return;}
    if(event.target.id === 'addressLine2') {setAddressLine2(event.target.value);return;}
    if(event.target.id === 'city') {setCity(event.target.value);return}
    if(event.target.id === 'state') {setState(event.target.value);return}
    if(event.target.id === 'pincode') {setPincode(event.target.value);return;}
  }

  const resetCashfreeForm = () => {
    setAadharNo('');
    setAccountHolderName('');
    setAddressLine1('');
    setAddressLine2('');
    setBankAccountNumber('');
    setCity('');
    setGstin('');
    setIfscCode('');
    setName('');
    setPanNo('');
    setPincode('');
    setState('');
    setPhoneNo('');
  }
  const addVendorDetails = async () => {
    if (!name || !bankAccountNumber || !accountHolderName || !ifscCode || !phoneNo || !addressLine1 || !addressLine2 || !city || !state || !pincode) {
      dispatch(setAppMessage({ message: 'Please fill all the mandatory fields', header: 'Error', type: 'alert' }));
      return;
    }
    if (isNaN(phoneNo) || (phoneNo.length !== 10)) {
      dispatch(setAppMessage({ message: 'Phone number is invalid', header: 'Error', type: 'alert' }));
      return;
    }
    if (isNaN(pincode) || (pincode.length !== 6)) {
      dispatch(setAppMessage({ message: 'Pincode is invalid', header: 'Error', type: 'alert' }));
      return;
    }
    const data = {
      'name': name,
      'phone': `+91${phoneNo}`,
      'email': email,
      'bankAccount': bankAccountNumber,
      'accountHolder': accountHolderName,
      'ifsc': ifscCode,
      'aadharNo': aadharNo,
      'panNo': panNo,
      'gstin': gstin,
      'address1': addressLine1,
      'address2': addressLine2,
      'city': city,
      'state': state,
      'pincode':pincode,
    };
    try {
      togglesCashfreeForm();
      setLoading(true);
      await dispatch(connectWithStripe({code: token, providerName:'cashfree', cashfreeData: data}));
      await dispatch(getSubStatus());
      dispatch(setAppMessage({ message: 'Successfully connected to cashfree account', header: 'Success', type: 'alert' }))
       setLoading(false);
    } catch (err) {
      const msg = (err && err.data && err.data.status && err.data.status.message) || 'Unable to connect cashfree account';
      resetCashfreeForm();
      togglesCashfreeForm();
      setLoading(false);
      dispatch(setAppMessage({ message: msg, header: 'Error', type: 'alert' }))
    }
  }
  return (
    <Box mt={4}>
      {isLoading && <PageBlockLoader />}
      {
        (connectedStatus.stripe && connectedStatus.stripe.stripeConnected) ?
          <Box mt={4} className={classes.root}>
            <Box display="flex" flexDirection="column">
              <Box bgcolor="pubPrimary" height="75px" className={classes.uppercontainer}>
                <Box component="span" color="primary.main"  display="flex" flexDirection="column">
                  Successfully connected to Stripe
          </Box>
              </Box>
              <Box m={4}>
                <Box component="span">
                  Congratulations! You are now set to accept payments. We are connected to your Stripe account ({stripeAccountId}). Reach out to our team using chat or email us at hello@pubninja.com, if you have any queries.
           </Box>
                <Box pt={2}>
                  <Box component="span"> <strong>Next Steps</strong> - Setup and manage products you offer as part of your subscriptions.</Box>
                </Box>
              </Box>
            </Box>
          </Box>
          : <Box mt={4} >
            <Box p={4} mt={8} className={classes.root}>
                <h3>Collect Payments using Cards</h3>
                <Box component="span">
                Supports recurring & one time plans. 
                Accept Visa, Mastercard and American Express payments.
                We use Stripe to securely process payments . Earnings will be paid out directly to your Stripe account minus PubNinja Fees.
                Stripe pays out directly to your bank account every week (Refer Payouts). You need to setup Stripe account before you can start collecting payments using Stripe. This will only takes 5 minutes. Get started by clicking on the button below. If you have any queries, reach out to our team using chat or email us at support@pubninja.com.
                </Box>
                {
                  clinetId && !isLoading && <Box className={classes.stripeButton} mt={4}>
                    <a href={`https://connect.stripe.com/oauth/authorize?client_id=${clinetId}&redirect_uri=${getURL()}/dash/monetization/subscripition&scope=read_write&response_type=code`} target="_blank">
                      <img className="stripe-connect" width="100%" src="https://pubninja.com/assets/images/connect-with-stripe.png"
                        alt="Stripe img" />
                    </a>
                  </Box>
                }
            </Box>
            </Box>
      }
      {connectedStatus.cashfree  ? <> {(connectedStatus.cashfree && connectedStatus.cashfree.connected) ?
          <Box mt={4} className={classes.root}>
            <Box display="flex" flexDirection="column">
              <Box bgcolor="pubPrimary" height="75px" className={classes.uppercontainer}>
                <Box component="span" color="primary.main"  display="flex" flexDirection="column">
                  Successfully connected to Cashfree
          </Box>
              </Box>
              <Box m={4}>
                <Box component="span">
                  Congratulations! You are now set to accept payments using cashfree. Reach out to our team using chat or email us at hello@pubninja.com, if you have any queries.
           </Box>
                <Box pt={2}>
                  <Box component="span"> <strong>Next Steps</strong> - Setup and manage products you offer as part of your subscriptions.</Box>
                </Box>
              </Box>
            </Box>
          </Box>
          : <Box p={4} mt={8} className={classes.root}>
                <h3>Collect Payments Using Cashfree</h3>
                <Box component="span">
                Supports one time plans. Accept all major cards, 
                UPI and Wallet payments. Cashfree payments are also 
                used to support UPI and Wallets in recurring plans. We use Cashfree to securely process payments. Earnings will be paid out directly to your bank account minus PubNinja Fees. Cashfree pays out directly to your bank account every week. You will need to setup payout details before you can start collecting payments using Cashfree. This will only take 5 minutes. Get started by clicking on the button below. If you have any queries, reach out to our team using chat or email us at support@pubninja.com.
                </Box>
                <Box mt={4}>
                <Button variant="contained" color="default" disabled={isLoading} onClick={togglesCashfreeForm}>Edit Payout</Button>
                </Box>
            </Box>
            }</>: <></>
      }
      <Dialog fullScreen={isMobile} open={showCashfreeForm} maxWidth={'md'} >
        <DialogContent >
              {<Box >
                  <h3>Edit Payouts</h3>
                  <h4>
                  This step is mandatory to allow your customers to pay using UPI/Wallets. Payouts for earnings through UPI/Wallets will be transferred to your bank accounts directly.
                  </h4>
                  <Grid container>
                    <Grid item xs={12}  md={6}>
                      <InputBox fullWidth className={classes.inputs} placeholder="Name*" value={name} id="name" onChange={handleFormInputChange}/>
                    </Grid>
                    <Grid item xs={12}  md={6}>
                    <InputBox fullWidth className={classes.inputs} placeholder="Account Holder Name*" value={accountHolderName} id="accountHolderName" onChange={handleFormInputChange}/>
                    </Grid>
                    <Grid item xs={12}  md={6}>
                    <InputBox fullWidth className={classes.inputs} placeholder="Bank Account Number*" value={bankAccountNumber} id="bankAccountNumber" onChange={handleFormInputChange}/>
                    </Grid>
                    <Grid item xs={12}  md={6} >
                    <InputBox fullWidth className={classes.inputs} placeholder="IFSC*" value={ifscCode} id="ifscCode" onChange={handleFormInputChange}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                    <InputBox fullWidth className={classes.inputs} placeholder="PAN" value={panNo} id="panNo" onChange={handleFormInputChange}/>
                    </Grid>
                    <Grid item xs={12} md={6} >
                    <InputBox fullWidth className={classes.inputs} placeholder="Aadhar Number" value={aadharNo} id="aadharNo" onChange={handleFormInputChange}/>
                    </Grid>
                    <Grid item xs={12} md={6} >
                    <InputBox fullWidth className={classes.inputs} placeholder="GSTIN" value={gstin} id="gstin" onChange={handleFormInputChange}/>
                    </Grid>
                    <Grid item xs={12} md={6} >
                    <InputBox 
                      fullWidth 
                      className={classes.inputs} 
                      placeholder="Phone Number*" 
                      value={phoneNo} 
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            +91
                          </InputAdornment>
                        ),
                      }}
                      id="phoneNo" 
                      onChange={handleFormInputChange}/>
                    </Grid>
                    <Grid item xs={12} md={12} >
                    <InputBox fullWidth className={classes.inputsFullwidth} placeholder="Address line 1*" value={addressLine1} id="addressLine1" onChange={handleFormInputChange}/>
                    </Grid>
                    <Grid item xs={12}  md={12}>
                    <InputBox fullWidth className={classes.inputsFullwidth} placeholder="Address line 2*" value={addressLine2} id="addressLine2" onChange={handleFormInputChange}/>
                    </Grid>
                    <Grid item xs={12}  md={4} >
                    <InputBox fullWidth className={classes.inputs} placeholder="City*" value={city} id="city" onChange={handleFormInputChange}/>
                    </Grid>
                    <Grid item xs={12}  md={4}>
                    <InputBox fullWidth className={classes.inputs} placeholder="State*" value={state} id="state" onChange={handleFormInputChange}/>
                    </Grid>
                    <Grid item xs={12}  md={4}>
                    <InputBox fullWidth className={classes.inputs} placeholder="Pincode*" value={pincode} id="pincode" onChange={handleFormInputChange}/>
                    </Grid>
                  </Grid>
                  <Box>
                    <Button variant="contained" size="medium" color="default" style={{margin:'8px'}} onClick={togglesCashfreeForm}>Cancel</Button>
                    <Button variant="contained" size="medium" color="primary" style={{margin:'8px'}} onClick={addVendorDetails}>Save</Button>
                  </Box>
                </Box>}
      </DialogContent>
    </Dialog>
    </Box>
  );
}
