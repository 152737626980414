import React,{useCallback,useContext} from "react";
import EditProduct from "../components/monetization/EditProduct";
import MobileProvider from "commons/context/MobileProvider";
import Box from "../commons/muiCustomComponents/Box";
import FullScreenPage from '../commons/commonWrapper/FullScreenPage';


function EditProducts () {
  const isMobile = useContext(MobileProvider);

  const barCallback = useCallback(() =>(<Box 
    display="flex"
    alignItems="center"
    component="span"
    >Edit Products</Box>),[]);
  return (

    <FullScreenPage BarComponent={barCallback} isDesktop={false}>
      <Box m={isMobile ?2:0}><EditProduct/> </Box>
    </FullScreenPage >

  )
}

export default EditProducts;
