import React, {useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Box from "commons/muiCustomComponents/Box";
import {
  publishEmailCampaign,
} from "../../scheduler/scheduler-action";
import Radio from '@material-ui/core/Radio';
import Header from "../../../content/components/Header";
import Footer from "../../../content/components/Footer";
import setAppMessage from "../../../../app-component/app-component-action";
import Grid from "commons/muiCustomComponents/Grid";
import { LinearLoader } from 'commons/commonWrapper/Loaders';
import MobileProvider from "commons/context/MobileProvider";
import Button from "commons/muiCustomComponents/Button";
import CloseIcon from '@material-ui/icons/Close';


const useStyles = makeStyles(theme => ({
  pageContainer: {
    display: "flex",
    padding: theme.spacing(4),
    justifyContent: "space-between",
    alignItems: "center",
    background: theme.palette.pubLightGrey,
    marginTop: theme.spacing(1),
  },
  activeContainer: {
    background: theme.palette.pubPaleGreyTwo,
  },
  activeContainerNews: {
    background: theme.palette.publightGreen,
  },
  name: {
    color: theme.palette.PubGrey,
    fontSize: theme.spacing(5),
    textTransform: "capitalize",
  },
  activeName: {
    color: theme.palette.pubdeepSkyBlue,
  },
  activeNameNews: {
    color: theme.palette.pubdeepSkyBlue,
  },
  commonMargin: {
    marginTop: theme.spacing(3),
  },
  footerButtonMobile: {
    paddingTop:theme.spacing(2),
    paddingBottom:theme.spacing(2),
    borderRadius:theme.spacing(1),
  },
  nextButton: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    width: theme.spacing(50),
    borderRadius: theme.spacing(8),
    margin: theme.spacing(2),
  },
  newsletterBodyContainer: {
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    marginTop: theme.spacing(10),
  },
  mobileTitle:{
    justifyContent:'center',
    display:'flex',
  },
  desktopTitle: {
    display:'flex',
    marginLeft: theme.spacing(12),
  },
  backBtn: {
    position: "absolute",
    left: 0,
    top: "50%",
    transform: "translateY(-50%)",
    cursor: "pointer",
    marginLeft: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      marginLeft: 0,
    },
  },
  relative: {
    position: "relative",
  },
}));
const RadioBtn = withStyles(theme => ({
  root: {
    color: theme.palette.pubGreen,
    '&$checked': {
      color: theme.palette.pubGreen,
    },
  },
  checked: {},
}))(Radio);

const CampaignSegmentList = (props) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState('free');
  const [publishing, setPublishing] = useState(false);
  const isMobile = React.useContext(MobileProvider)
  const {
    campaignId,
  } = props;

  const {
    scheduler: {
      segments,
     },
  } = useSelector(state => state);

  const handleNewsletterType = (value) => {
    setChecked(value);
  }

  const getProductName = (item) => {
    if(item.productName === 'PAID_ACCESS') return 'Paid';
    if(item.productName === 'FREE_ACCESS') return 'Free';
    if(item.productName === 'PUBLIC_ACCESS') return 'Total';
    return item.productName;
  }
  const doPublishEmailCampaign = async () => {
    setPublishing(true);
    let pd = [checked];
    try {
      await dispatch(publishEmailCampaign(campaignId, pd));
      dispatch(setAppMessage({message: 'Campaign Successfully Created.',header :'Success',type:'alert'}));
    } catch (err) {
      // handle error
    } finally {
      setPublishing(false);
      props.getCampaigns();
      closeDialog();
    }
  }

  const handleNewsletter = value => {
    handleNewsletterType(value);
  };

  const closeDialog = ()=>{
    props.closeRecepientsDialog();
    props.closeCreateCampaignDialog();    
  }

  return (
    <>
      <Header>
          <Grid container justify="center">            
          <Grid container justify="center">
                {<Grid item xs={12} md={12}>
                    <Box display="flex" alignItems="center" justifyContent="space-between" ml={isMobile ?4:25} mr={isMobile ?4:25} >
                      <Box display="flex" alignItems="center" height="100%">
                          <CloseIcon style={{color:'white'}} onClick={closeDialog}/>
                      </Box>
                      <Box component="p" display="flex" justifyContent="center">Recipients List</Box>
                      <Box  component="p" ></Box>
                      </Box>
                  </Grid>
                }
              </Grid>
          </Grid>
      </Header>
      <Grid item xs={12} md={12}>
      {publishing && <LinearLoader />}
        <Grid container justify="center">
          <Grid item md={8} xs={12}>
            <Box className={classes.newsletterBodyContainer}>
                  {segments.map( item => {
                    if (!item.subscribeCount) return null;
                    return (
                      <Box key={item.productId}>
                        <Box 
                        className={classes.pageContainer + " " + classes.commonMargin + " " +(checked === item.productId ? classes.activeContainerNews : '')}
                          onClick={()=>{handleNewsletter(item.productId)}}
                        >
                        <Box className={classes.name + " " + (checked ? classes.activeNameNews : '')}>
                          <b>{getProductName(item)}</b> (No. of subscribers {item.subscribeCount})
                        </Box>                    
                        <RadioBtn
                          className={classes.newsletterRadio}
                          checked={checked === item.productId}
                          value={item.productId}
                          name={"radio-button-newsletter"}
                          inputProps={{ 'aria-label': item.productName }}
                        />          
                        </Box>
                    </Box>
                    )
                  })
                  }
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <Footer>
          {!isMobile && <Grid container justify="center">
            <Grid item md={8} xs={12}>
              <Box display="flex">
                <Button 
                  variant="contained"
                  disabled={publishing}
                  onClick={doPublishEmailCampaign} 
                  >Finish</Button>
              </Box>
            </Grid>
          </Grid>}
            {isMobile && <Grid container justify="center" className={classes.mobileFooterContainer}>
              <Button 
                fullWidth
                disabled={publishing}
                variant="contained" 
                onClick={doPublishEmailCampaign}>
                  Finish
              </Button>
            </Grid>}
        </Footer>
      </Grid>
    </>
  )
}

export default CampaignSegmentList;