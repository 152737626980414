
import React,{useCallback,useContext} from "react";
import MapCustomDomain from "components/settings/MapCustomDomain";
import FullScreenPage from '../commons/commonWrapper/FullScreenPage';
import Box from "../commons/muiCustomComponents/Box";
import MobileProvider from "commons/context/MobileProvider";


export default function MapCustomDomainHome (){
    const isMobile = useContext(MobileProvider);
    const barCallback = useCallback(() =>(<Box 
        display="flex"
        alignItems="center"
        component="span"
        fontSize ={14}
        >{ isMobile ?'CONNECT A DOMAIN YOU OWN' :`SETTINGS / WEBSITES / CONNECT A DOMAIN YOU OWN`}</Box>),[]);
        
    return(
        <>
        <FullScreenPage BarComponent={barCallback} hideGoBack={true}>
        {(appBarHeight)=><MapCustomDomain appBarHeight={appBarHeight}/>}
        </FullScreenPage>
        </>
    )
}
