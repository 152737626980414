import React, { useState, useEffect,useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from "commons/muiCustomComponents/Box";
import Grid from "commons/muiCustomComponents/Grid";
import Paper from "commons/muiCustomComponents/Paper";
import AdOnboard from "./AdOnboard";
import Button from "commons/muiCustomComponents/Button";
import AdTab from "./Components/AdTab";
import getAdsDetail, { setAdsDetail } from "./advertisement-action";
import { useDispatch, useSelector } from "react-redux";
import { LinearLoader, PageBlockLoader, CircularLoader } from 'commons/commonWrapper/Loaders';
import MobileProvider from "commons/context/MobileProvider";
import Alert from './Components/Alert';
import { LogglyTracker } from 'loggly-jslogger';

const logger = new LogglyTracker();
logger.push({ 'logglyKey': '142d21f5-10d3-47a9-aa5a-f83e0a3abed7', 'sendConsoleErrors' : true, 'tag' : 'ardbeg,advertisement,overview' });


const useStyles = makeStyles(theme => ({

  textClass: {
    textTransform: 'capitalize',
  },
  subheading: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  textColor: {
    fontSize: theme.spacing(14 * 0.25),
    color: theme.palette.primary.dark,
    marginTop: 0,
  },
}));

export default function AdOverview({ appBarHeight }) {
  const isMobile = useContext(MobileProvider);

  const classes = useStyles();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [isSetAdLoader, setAdLoader] = useState(false);
  const [isAd, setAd] = useState(true);
  const [bankInfo, setBankInfo] = useState({});
  const [businessInfo, setBusinessInfo] = useState({});
  const [personalInfo, setPersonalInfo] = useState({});
  const [country, setCountryInfo] = useState(null);
  const [personalNumber, setPersonalNumber] = useState(null);
  const [adsComment ,setAdsComment] = useState('');
  const [flag ,setFlag] =useState(0);
  const {landing: {userProfile}} = useSelector(state => state);

  useEffect(() => {
    let isMounted = true;
    const getAdsDetails = async () => {
      try {
        setLoading(true);
        const { ads, bank={}, business={}, personal={}, location,adsComment,flag } = await dispatch(getAdsDetail());
        isMounted && setAd(ads);
        isMounted &&  setBankInfo(bank)
        isMounted &&  setBusinessInfo(business)
        isMounted && setPersonalInfo(personal)
        isMounted && setCountryInfo(location.country)
        isMounted && setPersonalNumber(personal.contact)
        isMounted &&  setLoading(false)
        isMounted && setAdsComment(adsComment)
        isMounted && setFlag(flag)
      }
      catch (err) {
        const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
        isMounted && setLoading(false);
      }
    }
    getAdsDetails();

   return ()=>{isMounted =false}

  }, []);

  const changeAdDetails = async (e) => {
    const value = e.target.checked;

    try {
      setAdLoader(true)
      const data = await dispatch(setAdsDetail());
      setAd(value);
      setAdLoader(false);
    }
    catch(err){
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      setAdLoader(false);
    }
  }
  return (
    <Box>
      {isLoading && <PageBlockLoader />}
      {
      !isMobile &&
      <Grid container>
        <Grid item xs={12} md={9} xl={10}>
          <AdTab taskname="ADVERTISEMENT SETUP" />
        </Grid>
      </Grid>
      } 
      <Grid container >
        <Grid item xs={12} md={9} xl={10}>
          <Box mt={4}>
            <Paper >
              {
                adsComment ? 
                flag ===1 ? <Alert severity="error">{adsComment}</Alert> :
                <Alert severity="info">{adsComment}</Alert>
                :
              <>
              <Box display="flex">
                <Button type="toggle" disabled={isSetAdLoader || isLoading} value={isAd} checked={isAd}
                  style={{ marginBottom: '10px' }}
                  onChange={changeAdDetails} leftText="Disable Ads" rightText="Enable Ads" />
                {isSetAdLoader && <CircularLoader height="auto" size={20} ml={2} />}
              </Box>
              <Box component="p" className={classes.textColor}>
                Ads is enabled by default.
              </Box>
              </>
              }
            </Paper>
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={9} xl={10}>
          <Box mt={4} mb={4}>
            <Paper >
              <AdOnboard appBarHeight={appBarHeight}
                countryVal={country}
                personalNumber={personalNumber}
                personalInfo={personalInfo}
                businessInfo={businessInfo}
                bankInfo={bankInfo}
              />
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
