
import React, {useEffect, useState} from "react";
import {logoutUser } from "../auth/auth-action";
import {useDispatch} from "react-redux";
import { useHistory } from "react-router-dom";
import { CircularLoader } from "commons/commonWrapper/Loaders";

function LogoutHome () {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(()=> {
    logOutUser();
  }, []);

  const logOutUser = async () => {
    try {
      setLoading(true)
      localStorage.removeItem('accessToken');
      localStorage.removeItem('active_domain_id');
      sessionStorage.removeItem('active_domain_id');
      //await dispatch(logoutUser());
      setLoading(false)
      history.push('/login')
    }
    catch (err) {
      setLoading(false)
    }
  }

  return (
    <>
    {loading && <CircularLoader message="Getting everything ready..."/>}
    </>
  )
}
export default LogoutHome;