import React from "react";
import SideBar from "../components/sidebar/SideBar";
import Header from "../components/header/HeaderHome";
import Box from "../commons/muiCustomComponents/Box";
import { useSelector } from "react-redux";
import { Switch, Route, useLocation } from "react-router-dom";
import DashRoutes from "../routes/main-routes";
import { makeStyles } from '@material-ui/core/styles';
import PrivateRoute from "../utils/privateRoutes";
import PageNotFound from "./PageNotFound";
import MobileProvider from "commons/context/MobileProvider";

const drawerWidth = 300;
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor : theme.palette.pubBackground,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    backgroundColor : theme.palette.pubBackground,
    minHeight: '100vh',
  },
  toolbar: theme.mixins.toolbar,
  headerHeight :{
    height:'80px',
  },
}));

function DashboardPage (props){

  const { 
    landing: { 
      isAllAuthComplete,
      featurePermissions,
      userPermissions,
    },
  } = useSelector(state =>  state);
  const location = useLocation();
  const isMobile = React.useContext(MobileProvider);
  const classes = useStyles();
  const {pathname} = location;
  
  /**
   * function for registering the routes
   * @param {RouteObject} item 
   */
  const routingHandler  = (item) => {
    const Component = item.component;
    if(item.isAuthRequired) {
      return (
        <PrivateRoute 
          exact
          key={item.path}
          path={item.path}
          requiredUserPermissions={item.requiredUserPermissions}
          permissionsCheckType={item.permissionsCheckType}
          requiredFeaturePermission={item.requiredFeaturePermission}
          isAllAuthComplete={isAllAuthComplete}
          userPermissions={userPermissions}
          featurePermissions={featurePermissions}
        >
          <Component/>
        </PrivateRoute>
      )
    }
    return (
      <Route exact key={item.path} path={item.path}>
        <Component/>
      </Route>
    )
  }

  return (
    isMobile && !pathname.includes("dash/live/transmission") ? <Switch>
    {DashRoutes.map((item) => routingHandler(item))}
    <Route path="*"><PageNotFound /></Route>
    </Switch> :
    <Box className={classes.root} id="mainPanel" >
    <Box position="fixed" className={classes.appBar}>
      <Header/>
    </Box>
      <Box className={classes.toolbar} />
      {(!(pathname === "/dash/old-dashboard") && !isMobile) && <SideBar/>}
      <main className={classes.content} >
        <Box className={classes.toolbar +" "+ classes.headerHeight}/>
        <Switch>
        {DashRoutes.map((item) => routingHandler(item))}
        <Route path="*"><PageNotFound /></Route>
        </Switch>
      </main>
  </Box>
  );
}

export default DashboardPage;