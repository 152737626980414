import { Paper } from "@material-ui/core";
import { common } from "@material-ui/core/colors";

const apiRoutes  = {

  /** Register new user without any activeDomain */
  registerNewUser: (email, password, phoneNo, phoneCountry, phoneOtp) => {
    return {
      method: "POST",
      endpoint: "/auth/register",
      data: {
        email,
        password,
        "phone_number": phoneNo,
        "phone_country_code": phoneCountry,
        "phone_otp": phoneOtp,
        role:'creator',
      },
    }
  },
  /** login a user using email password */
  loginUserApi: (email = null, commonInput, password = null, otp = null, phoneNo = null, phoneCountry = null) => {
    let data = {email, password, otp, "common_input": commonInput, role:"creator", phoneNo, phoneCountry};
    return {
      method: "POST",
      endpoint: '/auth/login',
      data,
    }
  },

  /** fetch the user info */
  fetchUserApi: () => {
    return {
      method: "GET",
      endpoint: '/users',
    }
  },

    /** fetch the user profile */
    fetchUserProfileApi: (domainId) => {
      return {
        method: "GET",
        endpoint: `/users/${domainId}/profile`,
      }
    },

  /** update the user profile*/
  updateUserInfoApi: (domainId, data) => {
    return {
      method: "PUT",
      endpoint: `/users/${domainId}/profile`,
      data:data,
    }
  },

  /** fetch the user permission corresponding to a domain */
  fetchUserPermissionsApi: (domainId) => {
    return {
      method:"GET",
      endpoint: `/acl/domain/${domainId}/user/permissions`,
    }
  },

  /** fetch feature permissions for a particular domain */
  fetchDomainFeaturePermissionsApi: (domainId) => {
    return {
      method: "GET",
      endpoint: `/acl/domain/${domainId}/features/?type=flat`,
    }
  },

  forgotPasswordApi: (email, otp) => {
    return {
      method:"POST",
      endpoint: `/auth/forgot-password`,
      data: {
        email,
        otp,
        role: 'creator',
      },
    }
  },

  /** logout the user */
  logoutUserApi: () => {
    return {
      method:"POST",
      endpoint: `/user/logout`,
    }
  },

  updateUserApi: () => {
    return {
      method: "POST",
      endpoint: `/schedule/update/user`,
    }
  },

  getCreateTypeApi: () => {
    return {
      method: "GET",
      endpoint: `/user/store`,
    }
  },

  updateCreateTypeApi: (data) => {
    return {
      method: "PUT",
      endpoint: `/user`,
      data: {
        store: data,
      },
    }
  },

  createDomainApi: (data) => {
    return {
      method: "POST",
      endpoint: `/domain/create`,
      data: data,
    }
  },

  updateDomainDetailsApi: (data, domainId) => {
    return {
      method: "PUT",
      endpoint: `/domain/${domainId}`,
      data: data,
    }
  },
  addNewStoryListApi: (data, domainId) => {
    return {
      method: "POST",
      endpoint: `/stories/${domainId}/list`,
      data: data,
    }
  },
  getDomainDetailsApi :(domainId)=>{
    return {
      method: "GET",
      endpoint: `/domain/${domainId}`,
    }
  },
  enableNewsletterApi: (data, domainId) => {
    return {
      method: "POST",
      endpoint: `newsletter/${domainId}`,
      data: data,
    }
  },

  scheduleUpdateUser: (data) => {
    return {
      method: "POST",
      endpoint: `/schedule/update/user`,
      data: data,
    }
  },

  fetchDomainList: () => {
    return  {
      method: "GET",
      endpoint: "/acl/domains",
    }
  },
  createNewArticleApi: (activeDomain) => {
    return {
      method:'POST',
      endpoint:`/quick-article/${activeDomain}`,
      data: {
        contentType: 2,
      },
    }
  },
  fetchArticleMetaApi: (activeDomain, articleId) => {
    return {
      method:'GET',
      endpoint: `/quick-article/${activeDomain}/meta/${articleId}`,
    }
  },
  fetchArticleContentApi: (activeDomain, articleId) => {
    return {
      method:'GET',
      endpoint: `/quick-article/${activeDomain}/draft/${articleId}`,
    }
  },
  sendEmailVerificationLink: (email) => {
    return {
      method:'POST',
      endpoint: `/auth/email-verification`,
      data: {
        email,
        role: "creator",
      },
    }
  },
  publishArticleApi: (activeDomain, articleId,data) => {
    return {
      method:'POST',
      endpoint: `/quick-article/${activeDomain}/publish/${articleId}`,
      data: {
        shadow:0,
        ...data,
      },
    }
  },
  updateCoverImageApi: (articleId, activeDomain, changeCoverForm) => {
    return {
      method:"POST",
      endpoint: `/article/${activeDomain}/cover/${articleId}`,
      data:changeCoverForm,
    }
  },
  fetchCategoriesApi: (activeDomain) => {
    return {
      method:'GET',
      endpoint: `/category/${activeDomain}`,
    }
  },
  updateArticleContentApi: (activeDomain, articleId, html, objectMapString) => {
    return {
      method:'PUT',
      endpoint:`/quick-article/${activeDomain}/draft/${articleId}`,
      data: {
        "html": html,
        "objectMap": objectMapString,
      },
    }
  },
  updateArticleMetaApi: (activeDomain, articleId, data) => {
    return {
      method: 'PUT',
      endpoint: `/quick-article/${activeDomain}/meta/${articleId}`,
      data,
    }
  },
  fetchArticles :(activeDomain,feedType,headerType,page=1,perPage=30)=>{
    return  {
      method: "GET",
      endpoint: `/quick-article/${activeDomain}/?${feedType}&page=${page}&perPage=${perPage}&header_type=${headerType}&orderBy=date_created`,
    }
  },
  fetchArticlesApi :(activeDomain,feedType,headerType,page=1,perPage=30)=>{
    return  {
      method: "GET",
      endpoint: `/article/${activeDomain}/?${feedType}&page=${page}&perPage=${perPage}&header_type=${headerType}`,
    }
  },
  fetchTwitterPages: (domainId) => {
    return  {
      method: "GET",
      endpoint: `/schedule/get/accounts?domain_id=${domainId}`,
    }
  },
  updateUser: (userData) => {
    return  {
      method: "POST",
      endpoint: `/schedule/update/user`,
      data: userData,
    }
  },
  deleteTwitterPage: (data) => {
    return  {
      method: "GET",
      endpoint: `/schedule-manage/remove/accounts?id=${data.id}`,
    }
  },
  fetchFacebookPages: (domainId) => {
    return  {
      method: "GET",
      endpoint: `/schedule-manage/${domainId}/page`,
    }
  },
  storeFacebookPage: (userData, domainId) => {
    return  {
      method: "POST",
      endpoint: `/schedule-manage/${domainId}/page`,
      data: userData,
    }
  },
  deleteFacebookPage: (page, activeDomain) => {
    return  {
      method: "DELETE",
      endpoint: `/schedule-manage/${activeDomain}/token`,
      data: page,
    }
  },
  getScheduledFbPosts: (activeDomain) => {
    return {
      method: "GET",
      endpoint: `/schedule-manage/${activeDomain}`,
    }
  },
  deleteScheduledFbPosts: (postData,activeDomain) => {
    return {
      method: "DELETE",
      endpoint: `/schedule-manage/${activeDomain}`,
      data: postData,
    }
  },
  scheduleFbPostsApi: (postData, activeDomain) => {
    return {
      method: "POST",
      endpoint: `/schedule-manage/${activeDomain}`,
      data: postData,
    }
  },
  rescheduleFbPostsApi: (postData, activeDomain) => {
    return {
      method: "PUT",
      endpoint: `/schedule-manage/${activeDomain}`,
      data: postData,
    }
  },
  getScheduledTweets: (activeDomain) => {
    return {
      method: "GET",
      endpoint: `/schedule/get/tweets?domain_id=${activeDomain}`,
    }
  },
  deleteScheduledTweetApi: (postData) => {
    return {
      method: "POST",
      endpoint: `/schedule/delete/tweet`,
      data: postData,
    }
  },
  scheduleTweetApi: (postData) => {
    return {
      method: "POST",
      endpoint: `/schedule/post/tweet`,
      data: postData,
    }
  },
  rescheduleTweetApi: (postData) => {
    return {
      method: "POST",
      endpoint: `/schedule/update/tweet`,
      data: postData,
    }
  },
  getNewsletterApi: (activeDomain) => {
    return {
      method: "GET",
      endpoint: `/newsletter/${activeDomain}`,
    }
  },
  sendNewsletterApi: (postData, activeDomain) => {
    return {
      method: "POST",
      endpoint: `/newsletter/${activeDomain}/campaigns/create`,
      data: postData,
    }
  },
  piscoPreSaveApi: (formData, activeDomain) => {
    return {
      method: "POST",
      endpoint: `/pisco/preSave?domainId=${activeDomain}`,
      data: formData,
    }
  },
  localUploadApi: (formData, activeDomain) => {
    return {
      method: "POST",
      endpoint: `/pisco/local/upload?domainId=${activeDomain}`,
      data: formData,
    }
  },
  blurImageApi: (source, activeDomain) => {
    return {
      method:'POST',
      endpoint: `/quick-article/${activeDomain}/blur`,
      data: { source },
    }
  },
  videoThumbnailApi: (source, activeDomain) => {
    return {
      method: 'POST',
      endpoint: `/quick-article/${activeDomain}/thumb`,
      data: {source},
    }
  },
  addNewStory: (content, croppedData, heading, linkAction, activeDomain) => {
    return {
      method: "POST",
      endpoint: `/stories/${activeDomain}/story/default-quick-cms-stories_${activeDomain}`,
      data: {
        content, croppedData, heading, linkAction,
      },
    }
  },
  
  //TODO : If we need to support TMC modal We should change the value of storyType param

  fetchStories: (activeDomain,storyType = -1) => {
    return {
      method: 'GET',
      endpoint: `/stories/${activeDomain}/story/${storyType}/default-quick-cms-stories_${activeDomain}`,
    }
  },
  deleteStory: (storyId, activeDomain) => {
    return {
      method: 'DELETE',
      endpoint: `/stories/${activeDomain}/story/${storyId}`,
    }
  },
  createCampaignApi: (payload, activeDomain) => {    
    return {
      method: "POST",
      endpoint: `/newsletter/${activeDomain}/campaigns/create`,
      data: payload,
    }
  },
  getAllDomainCampaignsApi: (activeDomain) => {    
    return {
      method: "GET",
      endpoint: `/newsletter/${activeDomain}/campaigns?orderBy=datecreated&order=desc`,
    }
  },
  getAllDomainCampaignsDateApi: (startDate, endDate, activeDomain) => {    
    return {
      method: "GET",
      endpoint: `/newsletter/${activeDomain}/campaigns?startDate=${startDate}&endDate=${endDate}&orderBy=datecreated&order=desc`,
    }
  },
  getCampaignFullDetailApi: (campaignId, activeDomain) => {    
    return {
      method: "GET",
      endpoint: `/newsletter/${activeDomain}/campaigns?campaignId=${campaignId}`,
    }
  },
  importSubscribersApi: (formData, activeDomain) => {
    return {
      method: "POST",
      endpoint: `/newsletter/${activeDomain}/bulkSubscribe`,
      data: formData,
    }
  },
  searchArticleApi: (formData ,activeDomain) => {
    return {
      method: "POST",
      endpoint: `/article/${activeDomain}/search`,
      data: formData,
    }
  },
  searchQuickArticleApi :(activeDomain,data)=>{
    return {
      method: "POST",
      endpoint: `/article/${activeDomain}/search`,
      data: data,
    }
  },
  getSubscriptionDetail :(activeDomain)=>{
    return {
      method:"GET",
      endpoint :`/subscription/${activeDomain}/package`, 
    }
  },
  setSubscriptionDetail :(activeDomain,data)=>{
    return {
      method:"POST",
      endpoint :`/subscription/${activeDomain}/package`, 
      data:data,
    }
  },
  adDetails :(activeDomain)=>{
    return  {
      method: "GET",
      endpoint: `setup-payouts

/${activeDomain}/details`,
    }
  },
  setConfigureAds :(activeDomain,data)=>{
    return  {
      method: "PUT",
      endpoint: `setup-payouts

/${activeDomain}/toggle`,
    }
  },
  getOTP :(activeDomain,data)=>{
    return {
      method: "POST",
      endpoint : `/bank/${activeDomain}/generate_otp`,
      data:data,
    }
  },
  setOTP :(activeDomain,data)=>{
    return {
      method :"POST",
      endpoint:`bank/${activeDomain}/verify_otp`,
      data:data,
    }
  },
  getBankDetail :(activeDomain)=>{
    return {
      method :"GET",
      endpoint:`bank/${activeDomain}`,
    }     
  },
  getBlackListedBank :(activeDomain)=>{
    return {
      method :"POST",
      endpoint:`bank/${activeDomain}/blacklisted_banks`,
    }
  },
  setBankDetail:(activeDomain,data)=>{
    return {
      method :"POST",
      endpoint:`bank/${activeDomain}`,
      data:data,
    }
  },
  fillAgreement :(activeDomain)=>{
    return {
      method : "POST",
      endpoint:`bank/${activeDomain}/agree`,
      data :{},
    }
  },

  clearCache: (articleId, activeDomain,data) => {
    return {
      method: "POST",
      endpoint: `/article/${activeDomain}/clearCache/${articleId}`,
      data,
    }
  },

  addUserPermission: (activeDomain, userPermission, email) => {
      return{
        method: "POST",
        endpoint: `/acl/domain/${activeDomain}/user/${email}/permission/${userPermission}`,
      }
  },

  deleteUserPermission: (activeDomain, userPermission, email) => {
    return{
      method: "DELETE",
      endpoint: `/acl/domain/${activeDomain}/user/${email}/permission/${userPermission}`,
    }
  },

  userListApi: (activeDomain) => {
    return{
      method: "GET",
      endpoint: `/acl/domain/${activeDomain}/users`,
    }
  },

  simplifiedPermissionsApi: (activeDomain) => {
    return { 
      method: "GET",
      endpoint : `/acl/domain/${activeDomain}/permissions?type=simplified`,
    }
  },

  userPermissionsApi: (activeDomain,email) => {
    return {
      method: "GET",
      endpoint : `/acl/domain/${activeDomain}/user/${email}/permissions`,
    }
  },

  addSubCategory :(activeDomain,categoryId,data)=>{
    return {
      method:"POST",
      endpoint:`category/${activeDomain}/y/${categoryId}`,
      data:data,
    }
  },
  
  editSubCategory :(activeDomain,subCategoryId,data)=>{
    return {
      method:"PUT",
      endpoint:`category/${activeDomain}/ycat/${subCategoryId}`,
      data:data,
    }
  },

  addCategory :(activeDomain,data)=>{
    return {
      method:"POST",
      endpoint:`category/${activeDomain}`,
      data:data,
    }
  },

  editCategory :(activeDomain,categoryId,data)=>{
    return {
      method:"PUT",
      endpoint:`category/${activeDomain}/cat/${categoryId}`,
      data:data,
    }
  },

  deleteWebsite :(activeDomain)=>{
    return{
      method:"POST",
      endpoint:`/domain/${activeDomain}/delete_my_domain`,
    }
  },

  mapGetWebsite :(activeDomain)=>{
    return{
      method:"GET",
      endpoint:`/domain/${activeDomain}/mapping`,
    }
  },

  deleteMapWebsite :(activeDomain,payload)=>{
    return{
      method:"DELETE",
      endpoint:`/domain/${activeDomain}/mapping`,
      data:payload,
    }
  },

  mapPostWebsite:(activeDomain,data)=>{
    return{
      method:"POST",
      endpoint:`/domain/${activeDomain}/mapping`,
      data:data,
    }
  },

  dnsRecords :(activeDomain)=>{
    return{
      method:"GET",
      endpoint:`/domain/${activeDomain}/dns_records`,
    }
  },
  delteDnsRecords :(activeDomain,payload)=>{
    return{
      method:"DELETE",
      endpoint:`/domain/${activeDomain}/dns_records`,
      data:payload,
    }
  },
  
  updateDnsRecords :(activeDomain,payload)=>{
    return{
      method:"PUT",
      endpoint:`/domain/${activeDomain}/dns_records`,
      data:payload,
    }
  },
  addDnsRecords :(activeDomain,payload)=>{
    return{
      method:"POST",
      endpoint:`/domain/${activeDomain}/dns_records`,
      data:payload,
    }
  },
  getPages :(activeDomain)=>{
    return{
      method:"GET",
      endpoint:`/page/${activeDomain}`,
    }
  },

  addPages :(activeDomain,slug)=>{
    return{
      method:"POST",
      endpoint:`/page/${activeDomain}/${slug}`,
    }
  },
  
  deletePage :(activeDomain,pageId)=>{
    return{
      method:"DELETE",
      endpoint:`/page/${activeDomain}/page/${pageId}`,
    }
  },

  getpageDetails :(activeDomain,pageId)=>{
    return{
      method:"GET",
      endpoint:`/page/${activeDomain}/page/${pageId}`,
    }
  },

  updatePageDetails :(activeDomain,pageId,data)=>{
    return{
      method:"PUT",
      endpoint:`/page/${activeDomain}/page/${pageId}`,
      data:data,
    }
  },

  publishPage :(activeDomain,pageId)=>{
    return {
      method:'POST',
      endpoint:`/page/${activeDomain}/page/${pageId}/publish`,
    }
  },

  addUserToDomain: (activeDomain, email, firstName, lastName) => {
    return {
      method:'POST',
      endpoint: `/acl/domain/${activeDomain}/add-user`,
      data: {
        email,
        firstName,
        lastName,
        newDashboard: true,
      },
    }
  },

  createPage :(activeDomain) =>{
    return {
      method:'POST',
      endpoint:`/page/${activeDomain}`,
    }
  },

  deleteArticle:(activeDomain, articleId) => {
    return {
      method:'POST',
      endpoint:`/quick-article/${activeDomain}/delete/${articleId}`,
      data: {
        reason:"user deleted",
      },
    }
  },
  unpublishArticleApi:(activeDomain, articleId, sendLiveEmail = false) => {
    return {
      method:'POST',
      endpoint:`/quick-article/${activeDomain}/unpublish/${articleId}`,
      data : {
        reason: "user deleted",
        sendLiveEmail,
      },
    }
  },
  fetchInsightsRevenue: (activeDomain, startDate, endDate) => {
    return {
      method: 'GET',
      endpoint: `/insight/${activeDomain}/revenue?startDate=${startDate}&endDate=${endDate}`,
    }
  },
  fetchInsightsViews: (activeDomain, startDate, endDate, limit=50,page=1) => {
    return {
      method: 'GET',
      endpoint: `/insight/${activeDomain}/pageviews?limit=${limit}&startDate=${startDate}&endDate=${endDate}&page=${page}&order=count&orderBy=desc`,
    }
  },
  fetchSubscriptionTransaction: (activeDomain, startUnixTimestamp, endUnixTimestamp, type, l20) => {
    return {
      method: 'GET',
      endpoint: `/insight/${activeDomain}/transaction?` +
        `fromTime=${startUnixTimestamp}&toTime=${endUnixTimestamp}&type=${type}${l20 ? '&lastTwenty=true' : ''}`,
    }
  },
  resetPasswordApi: (password, tokenId) => {
    return {
      method:'POST',
      endpoint: `/auth/reset-password?token=${tokenId}`,
      data: {
        "password": password,
      },
    }
  },
  deleteUser: (activeDomain, email) => {
    return {
      method:'DELETE',
      endpoint :`/acl/domain/${activeDomain}/user/${email}`,
    }
  },
  updateBankDetails :(activeDomain,data)=>{
    return {
      method:'PUT',
      endpoint: `setup-payouts

/${activeDomain}/bank_details`,
      data: data,
  }
  },
  updateAdsInfo:(activeDomain,data)=>{
    return {
      method:'PUT',
      endpoint: `setup-payouts

/${activeDomain}/update_info`,
      data: data,
    }
  },
  checkStripeAccountApi: (activeDomain) => {
    return {
      method: 'GET',
      endpoint: `/payments/${activeDomain}/list/accounts`,
    }
  },
  createStripeAccountApi: (activeDomain, token) => {
    return {
      method: 'GET',
      endpoint: `/payments/${activeDomain}/account/create/${token}`,
    }
  },
  getSubscriptionPlanApi: (activeDomain) => {
    return {
      method: 'GET',
      endpoint: `/payments/${activeDomain}/list/plans`,
    }
  },
  createSubscriptionPlanApi: (activeDomain, data) => {
    return {
      method: 'POST',
      endpoint: `/payments/${activeDomain}/update/plan`,
      data: data,
    }
  },
  createStripePlanApi: (activeDomain, data) => {
    return {
      method: 'POST',
      endpoint: `/payments/${activeDomain}/create/plan`,
      data: data,
    }
  },
  checkWebmasterVerified: (activeDomain) => {
    return {
      method: 'GET',
      endpoint: `seo/${activeDomain}/webmaster-verified`,
    }
  },
  createWebmasterToken: (activeDomain, token, site, userEmail) => {
    return {
      method: 'POST',
      endpoint: `seo/${activeDomain}/webmaster-token`,
      data: {
        token,
        site, 
        verfMethod:'FILE',
        userEmail,
      },
    }
  },
  updateWebmasterMapStatus: (activeDomain, status) => {
    return {
      method:'PUT',
      endpoint: `seo/${activeDomain}/webmaster-map-status`,
      data: {
        status,
      },
    }
  },
  toggleSubscripition :(activeDomain)=>{
    return {
      method :'PUT',
      endpoint:`/payments/${activeDomain}/toggle-subscription`,
    }
  },


  //  New subscription flow api endpoints

  getSubscriptionStatus :(activeDomain)=>{
    return {
      method :'GET',
      endpoint :`/subscription/creator_subscription_management/${activeDomain}/subscription_status`,
    }
  },

  createProducts :(activeDomain,data)=>{
   return { 
    method :'POST',
    endpoint :`subscription/creator_subscription_management/${activeDomain}/product`,
    data:data,
    }
  },
  createGiftSub :(activeDomain,data)=>{
    return {
      method :'POST',
      endpoint :`/subscription/audience_subscription_management/${activeDomain}/gift_subscription`,
      data :data,
    }
  },
  removeGiftSub :(activeDomain,data)=>{
    return {
      method :'POST',
      endpoint :`/subscription/audience_subscription_management/${activeDomain}/remove_gifted_subscription`,
      data :data,
    }
  },
  getProducts :(activeDomain)=>{
    return{
      method :'GET',
      endpoint:`/subscription/creator_subscription_management/${activeDomain}/products`,
    } 
  },

  updateProducts :(activeDomain,productId,data)=>{
    return {
      method:'PUT',
      endpoint :`subscription/creator_subscription_management/${activeDomain}/products/${productId}`,
      data:data,
    }
  },
  getBenefits :(activeDomain)=>{
    return {
      method:'GET',
      endpoint:`/subscription/creator_subscription_management/${activeDomain}/benefits`,
    }
  },
  createBenefits :(activeDomain,payload)=>{
    return {
      method:'POST',
      endpoint:`/subscription/creator_subscription_management/${activeDomain}/benefit`,
      data:payload,
    }
  },
  updateBenefits :(activeDomain,benefitId,payload)=>{
    return {
      method:'POST',
      endpoint:`subscription/creator_subscription_management/${activeDomain}/benefit/${benefitId}/update`,
      data:payload,
    }
  },
  deleteProduct :(activeDomain,payload)=>{
    return {
      method:'DELETE',
      endpoint:`/subscription/creator_subscription_management/${activeDomain}/products/${payload.product_id}`,
      data:payload,
    }
  },
  getProductById :(activeDomain,productId)=>{
    return {
      method:'GET',
      endpoint :`/subscription/creator_subscription_management/${activeDomain}/products/${productId}`,
    }
  },
  deletePlan :(activeDomain,productId,planId)=>{
    return {
      method :'DELETE',
      endpoint:`/subscription/creator_subscription_management/${activeDomain}/products/${productId}/plans/${planId}`,
      data :{
        product_id :+productId,
        plan_id : +planId,
      },
    }
  },
  addPlan :(activeDomain,payload)=>{
    return {
      method:'POST',
      endpoint:`/subscription/creator_subscription_management/${activeDomain}/plan`,
      data:payload,
    }
  },
  deleteBenefit :(activeDomain,productId,benefitId)=>{
    return {
      method:'POST',
      endpoint:`subscription/creator_subscription_management/${activeDomain}/benefit/detach`,
      data :{
        product_id :+productId,
        benefit_id : +benefitId,
      },
    }
  },

  connectStripe :(activeDomain,payload)=>{
    return {
      method:'POST' ,
      endpoint:`/subscription/creator_subscription_management/${activeDomain}/connect`,
      data:payload,
    }
  },
  attachBenefits :(activeDomain,payload)=>{
    return {
      method :'POST',
      endpoint:`subscription/creator_subscription_management/${activeDomain}/benefit/attach`,
      data:payload,
    }
  },
  updateProduct :(activeDomain ,payload,productId)=>{
    return {
      method:'PUT',
      endpoint:`/subscription/creator_subscription_management/${activeDomain}/products/${productId}`,
      data:payload,
    }
  },
  sendLoginOtp: (commonInput, phoneNo, phoneCountry) => {
    return {
      method: 'POST',
      endpoint: '/auth/send-otp',
      data: {
        "common_input": commonInput,
        "phone_number": phoneNo,
        "phone_country_code": phoneCountry,
        role:'creator',
      },
    }
  },


  sendPasswordResetOtp: (email) => {
    return {
      method: 'POST',
      endpoint: '/auth/send-otp',
      data: {
        "email": email,
        "type": "reset",
        role:'creator',
      },
    }
  },

  createEmailCampaignDraft: (activeDomain, subject) => {
    return {
      method: 'POST',
      endpoint: `/subscription/email/${activeDomain}/draft`,
      data: {
        subject,
      },
    }
  },

  updateEmailCampaignContent: (activeDomain, campaignId, contentHtml, contentObjectMap) => {
    return {
      method: 'PUT',
      endpoint: `/subscription/email/${activeDomain}/draft/${campaignId}`,
      data: {
        content: contentHtml,
        objectMapString: contentObjectMap,
      },
    }
  },

  publishEmailCampaignApi: (activeDomain, campaignId, productIds) => {
    return {
      method: 'POST',
      endpoint: `/subscription/email/${activeDomain}/publish`,
      data: {
        campaignId: campaignId,
        productIds,
      },
    }
  },

  listEmailSegments: (activeDomain) => {
    return {
      method: 'GET',
      endpoint: `/subscription/email/${activeDomain}/segments`,
    }
  },

  listEmailCampaigns: (activeDomain) => {
    return {
      method: 'GET',
      endpoint: `/subscription/email/${activeDomain}/list`,
    }
  },

  importEmailUserList: (activeDomain, formData) => {
    return {
      method: 'POST',
      endpoint: `/subscription/email/${activeDomain}/import`,
      data: formData,
    }
  },

  exporEmailUserList: (activeDomain) => {
    return {
      method: 'GET',
      endpoint: `/subscription/email/${activeDomain}/export`,
    }
  },

  //live stream
  createBroadcast: (activeDomain, articleId) => {
    return {
      method: 'POST',
      endpoint: `/glen/${activeDomain}/broadcast/${articleId}`,
    }
  },

  uploadBroadcastFile: (activeDomain, articleId, data) => {
    return {
      method: 'POST',
      endpoint: `/glen/${activeDomain}/broadcast/upload/${articleId}`,
      data: data,
    }
  },

  uploadBroadcastDefaultFile: (activeDomain, articleId, data={}) => {
    return {
      method: 'POST',
      endpoint: `/glen/${activeDomain}/broadcast/uploaddefault/${articleId}`,
      data: data,
    }
  },

  switchToBlankPdf: (activeDomain, articleId, data={}) => {
    return {
      method: 'POST',
      endpoint: `/glen/${activeDomain}/broadcast/switchblank/${articleId}`,
      data: data,
    }
  },

  switchToMainPdf: (activeDomain, articleId, data) => {
    return {
      method: 'POST',
      endpoint: `/glen/${activeDomain}/broadcast/switchMainPdf/${articleId}`,
      data: data,
    }
  },

  setBroadcastMetadata: (activeDomain, articleId, data) => {
    return {
      method: 'POST',
      endpoint: `/glen/${activeDomain}/broadcast/meta/${articleId}`,
      data: data,
    }
  },

  getBroadcastMetadata: (activeDomain, articleId, data) => {
    return {
      method: 'GET',
      endpoint: `/glen/${activeDomain}/broadcast/meta/${articleId}?${data}`,
    }
  },

  makeBroadcastLive: (activeDomain, articleId, data={}) => {
    return {
      method: 'POST',
      endpoint: `/glen/${activeDomain}/broadcast/makeLive/${articleId}`,
      data,
    }
  },

  endBroadcast: (activeDomain, articleId) => {
    return {
      method: 'DELETE',
      endpoint: `/glen/${activeDomain}/broadcast/${articleId}`,
    }},
    getFullSection :(activeDomain)=>{
      return {
        method:'GET',
        endpoint : `/settings/${activeDomain}/section/list`,
      }
    },
  getProductsInfo :(activeDomain)=>{
    return{
      method:'GET',
      endpoint:`/subscription/email/${activeDomain}/segments`,
    }
  },
  updateSectionById:(activeDomain,id,data)=>{
    return{
      method:'PUT',
      endpoint:`/settings/${activeDomain}/section/${id}`,
      data :data,
    }
  },
  createSection :(activeDomain,data)=>{
    return {
      method:'POST',
      endpoint:`settings/${activeDomain}/section`,
      data :data,
    }
  },
  fetchCustomersOverviewApi :(activeDomain,page=1,perPage=50,customerType='all',emailFilter)=>{
    let filter = emailFilter ? `&email=${emailFilter}` : '';
    return  {
      method: "GET",
      endpoint: `/insight/${activeDomain}/customer/overview?page=${page}&per_page=${perPage}&customer_type=${customerType}${filter}`,
    }
  },
  fetchLiveStreamPost :(activeDomain,page=1,perPage=100)=>{
    return {
      method:"GET",
      endpoint:`/quick-article/${activeDomain}/?live&isShowLive=0,1,2&page=page&perPage=perPage&header_type=liveStream&orderBy=date_created`,
    }
  },
  unpublishProduct :(activeDomain,productId)=>{
    return  {
      method: "PUT",
      endpoint: `/subscription/creator_subscription_management/${activeDomain}/products/${productId}/unpublish`,
    }
  },
  publishProduct :(activeDomain,productId)=>{
    return {
      method:"PUT",
      endpoint:`/subscription/creator_subscription_management/${activeDomain}/products/${productId}/publish`,
    }
  },
  unpublishPlan :(activeDomain,productId,planId)=>{
    return {
      method:"PUT",
      endpoint:`/subscription/creator_subscription_management/${activeDomain}/products/${productId}/plans/${planId}/unpublish` ,
    }
  },
  publishPlan:(domainId,productId,planId)=>{
    return{
      method:"PUT",
      endpoint:`/subscription/creator_subscription_management/${domainId}/products/${productId}/plans/${planId}/publish`,
    }
  },
  createandattatch:(activeDomain,payload)=>{
    return{
      method :"POST",
      endpoint :`subscription/creator_subscription_management/${activeDomain}/create_attach_benefit`,
      data:payload,
    }
  },
};

export default apiRoutes;
