
import Common from "../../constants/actions/common-component";

const commonDefault = {
  search: [],
  searchMeta: {
    isLoading: false,
    searchQuery: '',
    page: 0,
    perPage: 30,
    hasMoreData: true,
    isSearch: false,
  },
}

/**
 *   getUniqueArticle 
 */

function getUniqueArticle(array, keyName) {
  const tempObj = {};
  const finalArr = [];
  for(let i=0;i<array.length;i++) {
   // eslint-disable-next-line no-prototype-builtins
   if(tempObj.hasOwnProperty(array[i][keyName])) continue;
   tempObj[array[i][keyName]] = true;
   finalArr.push(array[i])
  }
  return finalArr;
}



export default function CommonComponent(state = commonDefault, action) {
  const { SEARCH } = Common;

  switch (action.type) {
    case SEARCH.FETCH.SUCCESS: {
      const bufferArticle = state.search.concat([
        ...action.data.data,
      ])
      state.search = getUniqueArticle (bufferArticle,'articleId')
      state.searchMeta = {
        ...state.searchMeta,
        isLoading: false,
        page: action.data.nextPage,
        hasMoreData: action.data.data.length === 30,
      }
      return { ...state }
    }
    case SEARCH.FETCH.REQUEST:

      state.searchMeta = {
        ...state.searchMeta,
        isLoading: true,
      }
      return { ...state }
    case SEARCH.FETCH.FAILURE:

      state.searchMeta = {
        ...state.searchMeta,
        isLoading: false,
      }
      return { ...state }

    case SEARCH.RESET.META:
      state.searchMeta = {
        ...state.searchMeta,
        isLoading: false,
        page: 0,
        perPage: 30,
        hasMoreData: true,
        searchQuery: '',
      }
      state.search = []
      return { ...state }
    case SEARCH.SET.META:
      state.searchMeta = {
        ...state.searchMeta,
        searchQuery: action.data,
        isSearch: true,
      }
      return { ...state }

    case SEARCH.DELETE.ARTICLE: {
      const { articleId } = action;
      if (articleId) {
        state.search = state.search.filter((item) => (
          item.articleId !== articleId
        ));
      }
      return { ...state }
    }
    default:
      return state;
  }
}
