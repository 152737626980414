
import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Box from "commons/muiCustomComponents/Box";
import Button from "commons/muiCustomComponents/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MobileProvider from "commons/context/MobileProvider";
import Divider from '@material-ui/core/Divider';
import Input from "commons/muiCustomComponents/Input";
import InputBox from "commons/muiCustomComponents/InputBox";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import { createandattachBenefit, removeBenefit ,updateBenefit} from "../monetization-action";
import { useDispatch } from "react-redux";
import { CircularLoader, LinearLoader } from 'commons/commonWrapper/Loaders';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import MoreIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from "commons/muiCustomComponents/MenuItem";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  textdecoration: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  heighPowerFont: {
    fontWeight: 600,
  },
  flexClasses: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  productBackground: {
    backgroundColor: theme.palette.secondary.light,
    border: 'none',
    "&:hover,&:focus": {
      backgroundColor: theme.palette.secondary.light,
      border: 'none',
    },
  },
}));


function EditBenefitContainer({ benefits, productId }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [finalBenefits, setFinalBenefits] = useState([]);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [editname, setEditName] = useState('');
  const [editdescription, setEditDescription] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [isDeletetingBenefit, setDeletingBenefit] = useState(false);
  const [activeRow, setActiveRow] = useState(false);
  const [open, setOpen] = useState(false);
  const [rowData, setRow] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isEdit, setEdit] = useState(false);
  const [savingBenefit ,setSavingBenefit] = useState(false);

  useEffect(() => {
    setFinalBenefits(benefits)
  }, [benefits.length])

  const handleCloseDialog = () => {
    setOpenDialog(false)
  }
  const handleSaveBenefit = async () => {
    try {
      const tempBenefits = finalBenefits;
      setLoading(true)
      const data = await dispatch(createandattachBenefit({
        name, description,
        sections: [],
        contents: [],
        type: 'CUSTOM',
        product_id: productId,
      }))
      tempBenefits.push(data);
      setFinalBenefits(tempBenefits);
      setName('');
      setDescription('');
      setOpenDialog(false)
      setLoading(false)
    }
    catch (err) {
      setLoading(false)
    }
  }

  const deleteBenefit = async (id) => {
    try {
      let tempBenefits = finalBenefits;
      setActiveRow(id)
      setDeletingBenefit(true)
      await dispatch(removeBenefit(productId, id));
      tempBenefits = tempBenefits.filter((row) => row.id !== id)
      setFinalBenefits(tempBenefits);
      setDeletingBenefit(false)
      handleClose()
    }
    catch (err) {
      setDeletingBenefit(false)
      console.log("err", err)
    }
  }
  const handleOpen = (e, val) => {
    setOpen(true);
    setRow(val)
    setAnchorEl(e.currentTarget);
  }
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  }
  const goToEditBenefit = () => {
    setEdit(true)
    handleClose()
    setEditName(rowData.name)
    setEditDescription(rowData.description)
  }
  const closeCustom = ()=>{
    setEdit(false);
    setEditName('')
    setEditDescription('')
    handleClose()
  }
  const saveCustom = async()=>{
    try {
    setSavingBenefit(true)
    await dispatch(updateBenefit(rowData.id,{
      "type": "CUSTOM",
      "sections": [],
      "contents": [],
      name:editname,
      description:editdescription,
  },
  ))
  closeCustom()
  setSavingBenefit(false)
  }
  catch(err){
    setSavingBenefit(false)
  }

  }
  return (
    <>
      <Box p={3}>
        <Box mt={3} mb={2}>
          <Box component="span" fontWeight="bold">Custom Benefits (Not displayed on website)</Box>
        </Box>
        {finalBenefits && finalBenefits.length > 0 &&
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.heighPowerFont}>Benefit Name</TableCell>
                  <TableCell className={classes.heighPowerFont}>Description</TableCell>
                  <TableCell className={classes.heighPowerFont}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>

                {
                  finalBenefits.map(row => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {
                          (isEdit && rowData &&rowData.id == row.id) ?
                            <Input
                              variant="outlined"
                              size="small"
                              value={editname}
                              onChange={(e) => {
                                setEditName(e.currentTarget.value)
                              }}
                            />
                            :
                            row.name}
                      </TableCell>
                      <TableCell >
                        {

                          (isEdit && rowData && rowData.id == row.id) ?
                            <Input
                              variant="outlined"
                              size="small"
                              value={editdescription}
                              onChange={(e) => {
                                setEditDescription(e.currentTarget.value)
                              }}
                            /> : row.description
                        }
                      </TableCell>
                      <TableCell >
                        {!isEdit && <IconButton color="primary" onClick={(e) => handleOpen(e, row)}>
                          <MoreIcon aria-controls="simple-menu" aria-haspopup="true" />
                        </IconButton>}
                        {/* <Box display="flex"> 
                        <DeleteIcon size="small" disabled ={row.id === activeRow} onClick={()=>deleteBenefit(row.id)}/>
                        {isDeletetingBenefit && row.id === activeRow && <CircularLoader size={20} height="auto"/>}
                      </Box> */}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        }
      </Box>
      <Divider />
      <Box p={3}>
        { isEdit ?
        <Box display="flex" justifyContent="flex-end">
          <Button variant="contained" disabled ={savingBenefit} color="secondary" onClick={closeCustom}> close</Button>
          <Box display="flex">
          <Button variant="contained" disabled ={savingBenefit} color="primary" boxProps={{ml:3}} onClick={saveCustom}> save</Button>
          {savingBenefit && <CircularLoader size={20} height="auto"/>}
          </Box>
        </Box>
        :
        <Box display="flex" justifyContent="flex-end">
        <Button color="secondary" variant="contained" onClick={() => setOpenDialog(true)} >Add</Button>
        </Box>
        }
      </Box>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Custom Benefits</DialogTitle>
        <DialogContent>
          <Box display="flex">
            <Box>
              <InputBox
                variant="outlined"
                size="small"
                value={name}
                onChange={(e) => {
                  setName(e.currentTarget.value)
                }}
                heading="Name" headingStyle={{ mt: 0 }} />
            </Box>
            <Box ml={3}>
              <InputBox
                variant="outlined"
                size="small"
                value={description}
                onChange={(e) => {
                  setDescription(e.currentTarget.value)
                }}
                heading="Description"
                headingStyle={{ mt: 0 }} />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary" variant="contained">
            Close
          </Button>
          <Button onClick={handleSaveBenefit} color="primary" variant="contained" autoFocus>
            save
          </Button>
          {
            isLoading && <CircularLoader size={20} height="auto" />
          }
        </DialogActions>
      </Dialog>
      <Menu id="postmenu" open={open} anchorEl={anchorEl} style={{ zIndex: '1500' }} keepMounted onClose={handleClose}>
        <MenuList autoFocus >
          <MenuItem onClick={goToEditBenefit} >Edit </MenuItem>
          <MenuItem onClick={() => deleteBenefit(rowData.id)} >Delete
        {isDeletetingBenefit && rowData.id === activeRow && <CircularLoader size={20} height="auto" />}
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  )
}
export default EditBenefitContainer;
