import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import Grid from "commons/muiCustomComponents/Grid";
import Paper from "commons/muiCustomComponents/Paper";
import Button from "commons/muiCustomComponents/Button";
import ButtonGroup from "commons/muiCustomComponents/ButtonGroup";
import Typography from '@material-ui/core/Typography';
import Box from "commons/muiCustomComponents/Box";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import ArrowForward from "@material-ui/icons/ArrowForward";

import TablePagination from '@material-ui/core/TablePagination';

import {
  setSubscription,
  getSubcriptionTransactionAction,
} from "./insights-action";
import MobileProvider from "commons/context/MobileProvider";
import { makeStyles } from '@material-ui/core/styles';
import { CircularLoader } from "commons/commonWrapper/Loaders";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { LogglyTracker } from 'loggly-jslogger';

const logger = new LogglyTracker();
logger.push({ 'logglyKey': '142d21f5-10d3-47a9-aa5a-f83e0a3abed7', 'sendConsoleErrors' : true, 'tag' : 'ardbeg,analytics,earnings' });

const useStyles = makeStyles(theme => ({
  quickViewSection: {
    margin: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    height: theme.isMobile ? '50px' : '80px',
    padding: theme.spacing(4),
  },
  detailViewSection: {
    // margin: theme.spacing(2),
  },
  revenueNumber: {
    fontSize: theme.spacing(3),
    color: 'grey',
  },
  trafficNumber: {
    fontSize: theme.spacing(3),
    color: 'grey',
  },
  tabPanel: {
  },
  timeRangeSection: {
    paddingTop: theme.spacing(4),
    // paddingBottom:theme.spacing(4),
    margin: theme.spacing(2),
  },
  timeRangePreviewContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    margin: theme.spacing(2),
  },
  timeRangePreviewSection: {
    fontWeight: '600',
    padding: theme.spacing(2),
    fontSize: theme.spacing(3.5),
    display:'flex',
    justifyContent:'center',
    cursor:'pointer',
    textTransform:'uppercase',
  },
  chartTitle: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    fontWeight: 'bold',
    padding: theme.spacing(2),
  },
  quickViewContainer: {
    marginTop: theme.spacing(4),
  },
  disclaimerContainer: {
    fontSize: theme.spacing(3.5),
  },
  customToolbarContainer: {
    textAlign: 'center',
    background: theme.palette.primary.main,
    color: 'white',
    padding: theme.spacing(2),
  },
  detailViewContainer: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  loaderBox: {
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '100%',
    zIndex: 20,
    background: "rgba(255,255,255,0.5)",
  },
  grossContainer: {
    marginTop:theme.spacing(6),
    fontSize: theme.spacing(4),
    fontWeight: '600',
    color:theme.palette.primary.main,
  },
  tabIndicator :{
    display:'none',
  },
  datRangeGridContainer :{
    justifyContent:'space-between',
  },
  table: {
  },
  textdecoration: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  heighPowerFont: {
    fontWeight: 600,
  },
  flexClasses: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  productBackground :{
    backgroundColor:theme.palette.secondary.light,
    border:'none',
    "&:hover,&:focus": {
      backgroundColor:theme.palette.secondary.light,
      border:'none',
    },
  },
  wordBreak :{
    wordBreak:'break-word',
  },

}));

function getDateRange(startDateUnix, endDateUnix, data) {
  const startDate = moment.unix(startDateUnix).format("YYYY-MM-DD");
  const endDate = moment.unix(endDateUnix).format("YYYY-MM-DD");
  const temp = {};
  let uniqCurrencies = [];
  let netTotalByCurrency = {};
  data.forEach((obj) => {
    if (obj.date_created && obj.currency) {
      const date = moment(obj.date_created).format("YYYY-MM-DD");
      temp[date] = temp[date] || { date };
      temp[date][obj.currency] = temp[date][obj.currency] || 0;
      temp[date][obj.currency] = temp[date][obj.currency] + obj.amount;
      if (uniqCurrencies.indexOf(obj.currency) === -1) uniqCurrencies.push(obj.currency);
      netTotalByCurrency[obj.currency] = netTotalByCurrency[obj.currency] || 0;
      netTotalByCurrency[obj.currency] = netTotalByCurrency[obj.currency] + obj.amount;
    }
  });
  let final = [];
  if (uniqCurrencies.length === 0 ) {
    uniqCurrencies=['USD'];
    netTotalByCurrency['USD'] = 0;
  }
  for (var i = endDate; moment(i).diff(moment(startDate), 'days') >=0; i=moment(i).subtract(1, 'day').format("YYYY-MM-DD")) {
    if (temp[i]) {
      netTotalByCurrency[temp[i].currency] = netTotalByCurrency[temp[i].currency] || 0;
      netTotalByCurrency[temp[i].currency] += temp[i].amount;
      final.push(temp[i]);
    } else {
      const tempDate = {
        date: i,
        [ uniqCurrencies[0] || 'USD']: 0,
      };
      final.push(tempDate);
    }
  }
  if (final.length && final[final.length-1].date !== 'Net Total') {
    final.push({
      date: "Net Total",
      ...netTotalByCurrency,
    });
  }
  return {final, uniqCurrencies};
}

const Earnings = ({ appbarheight }) => {

  const isMobile = useContext(MobileProvider);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();
  const {landing: {userProfile}} = useSelector(state => state);

  const defaultDateRange = {
    startDateTime: moment().subtract(1, 'week').startOf('day').unix(),
    endDateTime: moment().unix(),
    key: 'selection',
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchInitialData = async () => {
    try {
      const startTime = moment().subtract(1, 'week').unix();
      const endTime = moment().unix();
      dispatch(setSubscription({ startDate: startTime, endDate: endTime, data: [] }));
      let res = await dispatch(getSubcriptionTransactionAction(startTime, endTime));
      const dataNCurr = getDateRange(startTime, endTime, res);
      dispatch(setSubscription({ startDateTime: startTime, endDateTime: endTime, data: dataNCurr }));
    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Grid container className={classes.detailViewContainer}>
      <Grid item md={12} xl={12} xs={12}>
         <Box ml={isMobile?2:0} mr={isMobile?2:0}>
          {RenderChartSection({ isMobile, defaultDateRange, isLoading })}
        </Box>
      </Grid>
    </Grid>
  )
}

function RenderTabularTransactionWithTimeRange(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const isMobile = useContext(MobileProvider);
  const [isLoading, setIsLoading] = useState(false);
  const [inLoader, setInLoader] = useState(false);
  const [activeFilter, setActiveFilter] = useState("Last 20");
  const filters = isMobile ? ['Last 20', '1d', '1w', '4w', '1y', 'mtd', 'ytd'] :
    ['Last 20', '1d', '1w', '4w', '1y', 'mtd', 'ytd', 'custom'];
  const [data, setData] = useState([]);
  const [dateRange, setDateRange] = useState({
    startDateTime: moment().subtract(1, 'week').endOf('day').unix(),
    endDateTime: moment().unix(),
    key: 'selection',
  });

  useEffect(() => {
    fetchSubscriptionTrasaction('Last 20', {
      startDateTime: moment().subtract(1, 'week').startOf('day').unix(),
      endDateTime: moment().unix(),
    });
  }, []);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchSubscriptionTrasaction = async (activeFilter, dateRange) => {
    setInLoader(true);
    setData([]);
    const l20 = activeFilter === 'Last 20' ? 'lastTwenty' : '';
    try {
      const starTime = dateRange.startDateTime;
      const endTime = dateRange.endDateTime;
      let res = await dispatch(getSubcriptionTransactionAction(starTime, endTime, 'RECURRING', l20));
      if (res.length) {
        setDateRange({
          startDateTime: moment(res[res.length-1]['date_created']).unix(),
          endDateTime: moment(res[0]['date_created']).unix(),
          key: 'selection',
        });
      } else {
        setDateRange({
          startDateTime: moment().subtract(1, 'week').unix(),
          endDateTime: moment().unix(),
          key: 'selection',
        });
      }
      setPage(0);
      setData(res);
    } catch (err) {
      // handle error
    } finally {
      setInLoader(false);
    }
  }
  const handleFilterChange = (value, range) => {
    setActiveFilter(value);
    let newDateRange = dateRange;
    switch (value) {
      case 'Last 20':
        newDateRange = {
          startDateTime: moment().startOf('day').unix(),
          endDateTime: moment().unix(),
          key: 'selection',
        }
        break;
      case '1d':
        newDateRange = {
          startDateTime: moment().startOf('day').unix(),
          endDateTime: moment().unix(),
          key: 'selection',
        }
        break;
      case '1w':
        newDateRange = {
          startDateTime: moment().subtract(1, 'week').startOf('day').unix(),
          endDateTime: moment().unix(),
          key: 'selection',
        }
        break;
      case '4w':
        newDateRange = {
          startDateTime: moment().subtract(4, 'weeks').startOf('day').unix(),
          endDateTime: moment().unix(),
          key: 'selection',
        }
        break;
      case '1y':
        newDateRange = {
          startDateTime: moment().subtract(1, 'year').startOf('day').unix(),
          endDateTime: moment().unix(),
          key: 'selection',
        }
        break;
      case 'mtd':
        newDateRange = {
          startDateTime: moment().startOf('month').unix(),
          endDateTime: moment().unix(),
          key: 'selection',
        }
        break;
      case 'ytd':
        newDateRange = {
          startDateTime: moment().startOf('year').unix(),
          endDateTime: moment().unix(),
          key: 'selection',
        }
        break;
      case 'custom':
        newDateRange = range;
        break;
      default: return;
    }
    setDateRange(newDateRange);
    fetchSubscriptionTrasaction(value, newDateRange);
  }
  return <Box>
    <Box style={{ position: 'relative'}}>
      {(isLoading || inLoader) &&
        <Box display="flex" justifyContent="center" alignItems="center" className={classes.loaderBox}>
          <CircularLoader></CircularLoader>
        </Box>
      }
      {
        TimeRangeSelector({
          allowedFilters: filters,
          activeFilter: activeFilter,
          handleFilterChange,
          dateRange,
          setDateRange,
          showSelectedDates: true,
        })
      }
      {isLoading === false && inLoader === false &&
        <Box mt={5}>
          <TableContainer>
            <Table size={'small'} aria-label="simple table" style={{width: "700"}}>
              <TableHead>
                <TableRow>
                  <TableCell >Date</TableCell>
                  <TableCell >Email</TableCell>
                  <TableCell >Product</TableCell>
                  <TableCell >Amount {data.length > 0 ? `(${data[0].currency})`: ''}</TableCell>
                </TableRow>
              </TableHead>
              {data.length === 0 && <TableBody>
                  <TableRow >
                      <TableCell align="center" colSpan={6}>You don’t have any live transactions</TableCell>
                  </TableRow>

                </TableBody>}
              {data.length >0 && <TableBody>
                {data.length >0 && data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((obj, i)=>{
                  return <TableRow key={i}>
                    <TableCell style={{whiteSpace:'nowrap'}}>{moment(obj.date_created).format(`MMM DD, HH:mm`)}</TableCell>
                    <TableCell >{obj.email}</TableCell>
                    <TableCell style={{whiteSpace:'nowrap'}}>{obj.name}</TableCell>
                    <TableCell >{(Math.round(obj.amount * 100) / 100).toFixed(2)}</TableCell>
                  </TableRow>
                })}
                {data.length === 0 && <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>}
              </TableBody>}
            </Table>
          </TableContainer>
          {data.length >0 && <TablePagination
            rowsPerPageOptions={[20]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />}
          
        </Box>
      }
    </Box>
  </Box>;
}

function RenderChartSection(props) {
  const classes = useStyles();
  const { insights: {subscriptionTranasaction} } = useSelector(state => state);
  return (
    <Box>
      <Grid item xs={12} md={12} >
        <Box mt={4}>
          <Paper>
            {RenderTabularWithTimeRange({
              allowedFilters: ['1d', '1w', '4w'],
              defaultDateRange: props.defaultDateRange,
              data: subscriptionTranasaction,
              isLoading: props.isLoading,
            })}
          </Paper>
        </Box>
        <Box mt={4}>
          <Paper>
            {
              RenderTabularTransactionWithTimeRange(props)
            }
          </Paper>
        </Box>
        <Box mt={4}>
          <Paper>
            <Box className={classes.disclaimerContainer}>
              <strong>Disclaimer:&nbsp;</strong>
              All the amounts above are gross amounts and payouts are settled directly after platform fees & payment
              processing fees as per schedule (Refer <a rel="noreferrer" target="_blank" href="https://pubninja.com/pricing.html">Pricing</a>).
              Earnings are updated every 5 mins
              and are shown from 10th may 2020.
              <br/>Analytics is in beta. We are adding new features every week.
              For any queries & requests, email us at support@pubninja.com. You can also reach out to us using chat option.
            </Box>
          </Paper>
        </Box>
      </Grid>
    </Box>
  )
}

function RenderTabularWithTimeRange(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [activeFilter, setActiveFilter] = useState("1w");
  const [isLocalLoading, setisLocalLoading] = useState(false);
  const isMobile = useContext(MobileProvider);
  const filters = isMobile ? ['1d', '1w', '4w', '1y', 'mtd', 'ytd'] : ['1d', '1w', '4w', '1y', 'mtd', 'ytd', 'custom']
  const [dateRange, setDateRange] = useState(props.defaultDateRange);

  let subscriptionData = [];
  let uniqCurrencies = [];
  if (props.data && props.data.data && props.data.data.final) {
    subscriptionData = props.data.data.final;
    uniqCurrencies = props.data.data.uniqCurrencies;
  }

  const fetchSubscriptionTrasaction = async (activeFilter, dateRange) => {
    setisLocalLoading(true);
    try {
      const starTime = dateRange.startDateTime;
      const endTime = dateRange.endDateTime;
      dispatch(setSubscription({ startDate: starTime, endDate: endTime, data: [] }));
      let res = await dispatch(getSubcriptionTransactionAction(starTime, endTime));
      let dataNCurr = getDateRange(starTime, endTime, res);
      dispatch(setSubscription({ startDate: starTime, endDate: endTime, data: dataNCurr }));
    } catch (err) {
      // handle error
    } finally {
      setisLocalLoading(false);
    }
  }
  const handleFilterChange = (value, range) => {
    setActiveFilter(value);
    let newDateRange = dateRange;
    switch (value) {
      case '1d':
        newDateRange = {
          startDateTime: moment().startOf('day').unix(),
          endDateTime: moment().unix(),
          key: 'selection',
        }
        break;
      case '1w':
        newDateRange = {
          startDateTime: moment().subtract(1, 'week').startOf('day').unix(),
          endDateTime: moment().unix(),
          key: 'selection',
        }
        break;
      case '4w':
        newDateRange = {
          startDateTime: moment().subtract(4, 'weeks').startOf('day').unix(),
          endDateTime: moment().unix(),
          key: 'selection',
        }
        break;
      case '1y':
        newDateRange = {
          startDateTime: moment().subtract(1, 'year').startOf('day').unix(),
          endDateTime: moment().unix(),
          key: 'selection',
        }
        break;
      case 'mtd':
        newDateRange = {
          startDateTime: moment().startOf('month').unix(),
          endDateTime: moment().unix(),
          key: 'selection',
        }
        break;
      case 'ytd':
        newDateRange = {
          startDateTime: moment().startOf('year').unix(),
          endDateTime: moment().unix(),
          key: 'selection',
        }
        break;
      case 'custom':
        newDateRange = range;
        break;
      default: return;
    }
    setDateRange(newDateRange);
    fetchSubscriptionTrasaction(value, newDateRange);
  }
  return (
    <Box>
      <Box style={{ position: 'relative' }}>
        {(props.isLoading || isLocalLoading) ? <Box display="flex" justifyContent="center" alignItems="center" className={classes.loaderBox}>
          <CircularLoader></CircularLoader>
        </Box> : null}
        <Box>
          {
            TimeRangeSelector({
              allowedFilters: filters,
              activeFilter,
              handleFilterChange,
              dateRange,
              setDateRange: setDateRange,
              showSelectedDates: true,
            })
          }
          <Box mt={3}>
           <TableContainer>
             <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {subscriptionData && subscriptionData.length > 0 && <TableCell className={classes.heighPowerFont}>DATE</TableCell>}
                  {uniqCurrencies.map((currency, i) => {
                    return <TableCell  className={classes.heighPowerFont} key={i}>{currency}</TableCell>
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  subscriptionData && subscriptionData.length > 0 && subscriptionData.map((obj, i) => {
                    return <TableRow key={i}>
                      <TableCell component="th" scope="row" className={classes.wordBreak}>
                        {obj.date}
                      </TableCell>
                      {
                        uniqCurrencies.map((currency, j) => {
                          return typeof obj[currency] !== 'undefined' ?
                            <TableCell  className={classes.wordBreak} key={j}>{(Math.round(obj[currency] * 100) / 100).toFixed(2)}</TableCell> :
                            <TableCell  className={classes.wordBreak} key={j}>-</TableCell>
                        })
                      }
                    </TableRow>
                  })
                }
              </TableBody>
             </Table>
           </TableContainer>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

function TimeRangeSelector(props) {

  const classes = useStyles();
  const { allowedFilters, dateRange } = props;
  dateRange.startDate = moment.unix(dateRange.startDateTime).format("YYYY-MM-DD");
  dateRange.endDate = moment.unix(dateRange.endDateTime).format("YYYY-MM-DD");
  const [showStartDatePicker, setStartShowDatePicker] = useState(false);
  const [showEndDatePicker, setShowEndDatePicker] = useState(false);

  const isMobile = useContext(MobileProvider);

  const handleFilterChange = (event) => {
    const value = event.currentTarget.value;
    if (value === 'custom') {
      setStartShowDatePicker(true);
      return;
    }
    props.handleFilterChange(value);
  }
  const handleStartSelect = (moment) => {
    props.setDateRange({
      startDateTime: moment.startOf('day').unix(),
      endDateTime: moment.unix(),
      key: 'selection',
    });
    setTimeout(() => {
      setStartShowDatePicker(false);
      setShowEndDatePicker(true);
    }, 200);
  }

  const handleEndSelect = (momentDate) => {
    props.setDateRange({
      startDateTime: dateRange.startDateTime,
      endDateTime: dateRange.endDateTime,
      key: 'selection',
    });
    props.handleFilterChange('custom', {
      startDateTime: dateRange.startDateTime,
      endDateTime: momentDate.unix(),
      key: 'selection',
    });
    setShowEndDatePicker(false);
  }

  const CustomDatePickerField = (props) => {
    return (
      <span onClick={() => props.onClickHandler(true)}>{props.value}</span>
    )
  }

  const CustomToolbar = (data, props) => {
    return (
      <Box className={classes.customToolbarContainer}>
        <Typography variant="h4">{data}</Typography>
        <Typography variant="h5">{moment(props.date).format('DD,MMM YYYY')}</Typography>
      </Box>
    )
  }
  return (
    <Grid container className={classes.datRangeGridContainer}>
      <Grid item xs={12} md={8} lg={6}>
        <ButtonGroup  size="small" aria-label="small button group">
          {
            allowedFilters.map((item) => {
              return (
                <Button style={{'font-size': '11px'}} key={item}
                  variant={props.activeFilter === item ? 'contained' : 'outlined'} value={item} onClick={handleFilterChange}>
                  {item.toUpperCase()}
                </Button>
              )
            })
          }
        </ButtonGroup>
      </Grid>
      <Grid item md={4} lg={4}>
        {props.showSelectedDates && !isMobile && dateRange && <Box >
          <Paper className={classes.timeRangePreviewSection}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                open={showStartDatePicker}
                autoOk
                disableFuture
                onClickHandler={() => { setStartShowDatePicker(true) }}
                onOpen={() => setStartShowDatePicker(true)}
                onClose={() => setStartShowDatePicker(false)}
                maxDate={moment()}
                format='DD MMM YYYY'
                TextFieldComponent={CustomDatePickerField}
                ToolbarComponent={CustomToolbar.bind(null, 'Start Date')}
                value={dateRange.startDateTime ? moment.unix(dateRange.startDateTime) : moment().subtract(1, 'week')}
                onChange={handleStartSelect}
                variant="inline"
              />
              <ArrowForward style={{ fontSize: '14px' }} />
              <KeyboardDatePicker
                open={showEndDatePicker}
                autoOk
                disableToolbar={false}
                onClickHandler={(event) => { setShowEndDatePicker(true) }}
                onOpen={() => setShowEndDatePicker(true)}
                onClose={() => setShowEndDatePicker(false)}
                minDate={dateRange.startDateTime ? moment.unix(dateRange.startDateTime) : moment().subtract(1, 'week')}
                maxDate={moment()}
                format='DD MMM YYYY'
                disableFuture
                value={dateRange.endDateTime ? moment.unix(dateRange.endDateTime) : moment()}
                TextFieldComponent={CustomDatePickerField}
                ToolbarComponent={CustomToolbar.bind(null, 'End Date')}
                onChange={handleEndSelect}
                variant="inline"
              />
            </MuiPickersUtilsProvider>
          </Paper>
        </Box>}
      </Grid>
    </Grid>
  )
}

export default Earnings;
