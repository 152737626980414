import setAppMessage from "../../../app-component/app-component-action";



export function createEmailCampaignDraft(subject) {
  return async (dispatch, getState, apiHandler) => {
    try {
      const { api, apiRoutes } = apiHandler;
      const activeDomain = getState().landing.activeDomain;
      const {data:{result}} = await api(apiRoutes.createEmailCampaignDraft(activeDomain, subject));
      return Promise.resolve(result);
    } catch (err) {
      dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}));
      return Promise.reject(err);
    }
  }
}

export function updateEmailCampaignContent(campaignId, contentHtml, contentObjectMap) {
  return async (dispatch, getState, apiHandler) => {
    try {
      const {api, apiRoutes } = apiHandler;
      const activeDomain = getState().landing.activeDomain;
      const res = await api(apiRoutes.updateEmailCampaignContent(activeDomain, campaignId, contentHtml, contentObjectMap));
      return Promise.resolve(res);
    } catch (err) {
      dispatch(setAppMessage({message:err.message || 'Unable to update campaign content.',header :'Error',type:'alert'}));
      return Promise.reject(err);
    }
  }
}

export function publishEmailCampaign(campaignId, productIds) {
  return async (dispatch, getState, apiHandler) => {
    try {
      const {api, apiRoutes } = apiHandler;
      const activeDomain = getState().landing.activeDomain;
      const res = await api(apiRoutes.publishEmailCampaignApi(activeDomain, campaignId, productIds));
      return Promise.resolve(res);
    } catch (err) {
      dispatch(setAppMessage({message:err.message || 'Unable to update campaign content.',header :'Error',type:'alert'}));
      return Promise.reject(err);
    }
  }
}

export function getEmailCampaignList() {
  return async (dispatch, getState, apiHandler) => {
    try {
      const {api, apiRoutes } = apiHandler;
      const activeDomain = getState().landing.activeDomain;
      const {data:{result}} = await api(apiRoutes.listEmailCampaigns(activeDomain));
      dispatch({type:'UPDATE_EMAIL_CAMPAIGN_LIST', data:result});
      return Promise.resolve(result);
    } catch (err) {
      dispatch(setAppMessage({message:err.message || 'Unable to update campaign content.',header :'Error',type:'alert'}));
      return Promise.reject(err);
    }
  }
}

export function getEmailSegmentsList() {
  return async (dispatch, getState, apiHandler) => {
    try {
      const {api, apiRoutes } = apiHandler;
      const activeDomain = getState().landing.activeDomain;
      const {data:{result}} = await api(apiRoutes.listEmailSegments(activeDomain));
      dispatch({type:'UPDATE_EMAIL_SEGMENTS', data:result});
      return Promise.resolve(result);
    } catch (err) {
      dispatch(setAppMessage({message:err.message || 'Unable to update campaign content.',header :'Error',type:'alert'}));
      return Promise.reject(err);
    }
  }
}

export function importEmailUserList(formData) {
  return async (dispatch, getState, apiHandler) => {
    try {
      const {api, apiRoutes } = apiHandler;
      const activeDomain = getState().landing.activeDomain;
      const res = await api(apiRoutes.importEmailUserList(activeDomain, formData));
      return Promise.resolve(res);
    } catch (err) {
      dispatch(setAppMessage({message:err.message || 'Unable to update campaign content.',header :'Error',type:'alert'}));
      return Promise.reject(err);
    }
  }
}

export function exportEmailUsersList() {
  return async (dispatch, getState, apiHandler) => {
    try {
      const {api, apiRoutes } = apiHandler;
      const activeDomain = getState().landing.activeDomain;
      const res = await api(apiRoutes.exporEmailUserList(activeDomain));
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${activeDomain}-users.csv`) //or any other extension
      document.body.appendChild(link);
      link.click();
      return Promise.resolve(res);
    } catch (err) {
      dispatch(setAppMessage({message:err.message || 'Unable to update campaign content.',header :'Error',type:'alert'}));
      return Promise.reject(err);
    }
  }
}

export function getScheduledTweets() {

  return async (dispatch, getState, apiHandler) => {
    try {
      const { api, apiRoutes, actionTypes } = apiHandler;
      const activeDomain = getState().landing.activeDomain;
      const {data} = await api(apiRoutes.getScheduledTweets(activeDomain));
      dispatch(scheduledTweets(actionTypes.FETCHSCHEDULEDTWEETS.SUCCESS, data));
    } catch (err) {
      dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}));
      return Promise.reject(err);
    }
  }
}

export function deleteScheduledTweet(postData) {

  return async (dispatch, getState, apiHandler) => {
    try {
      const { api, apiRoutes } = apiHandler;
      await api(apiRoutes.deleteScheduledTweetApi(postData));
      return Promise.resolve();
    } catch (err) {
      dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}));
      return Promise.reject(err);
    }
  }
}

export function scheduleTweetAction(postData) {

  return async (dispatch, getState, apiHandler) => {
    try {
      const { api, apiRoutes } = apiHandler;
      const activeDomain = getState().landing.activeDomain;
      postData.domain_id = activeDomain;
      await api(apiRoutes.scheduleTweetApi(postData));
      return Promise.resolve();
    } catch (err) {
      dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}));
      return Promise.reject(err);
    }
  }
}

export function rescheduleTweetAction(postData) {

  return async (dispatch, getState, apiHandler) => {
    try {
      const { api, apiRoutes } = apiHandler;
      await api(apiRoutes.rescheduleTweetApi(postData));
      return Promise.resolve();
    } catch (err) {
      dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}));
      return Promise.reject(err);
    }
  }
}

export function getScheduledFbPosts() {

  return async (dispatch, getState, apiHandler) => {
    try {
      const { api, apiRoutes, actionTypes } = apiHandler;
      const activeDomain = getState().landing.activeDomain;
      const {data} = await api(apiRoutes.getScheduledFbPosts(activeDomain));
      dispatch(scheduledFbPosts(actionTypes.FETCHSCHEDULEDFBPOSTS.SUCCESS, data));
    } catch (err) {
      dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}));
      return Promise.reject(err);
    }
  }
}

export function deleteScheduledFbPosts(postData) {

  return async (dispatch, getState, apiHandler) => {
    try {
      const { api, apiRoutes } = apiHandler;
      const activeDomain = getState().landing.activeDomain;
      await api(apiRoutes.deleteScheduledFbPosts(postData, activeDomain));
      return Promise.resolve();
    } catch (err) {
      dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}));
      return Promise.reject(err);
    }
  }
}

export function scheduleFbPostsAction(postData) {

  return async (dispatch, getState, apiHandler) => {
    try {
      const { api, apiRoutes } = apiHandler;
      const activeDomain = getState().landing.activeDomain;
      await api(apiRoutes.scheduleFbPostsApi(postData, activeDomain));
      return Promise.resolve();
    } catch (err) {
      dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}));
      return Promise.reject(err);
    }
  }
}

export function rescheduleFbPostsAction(postData) {

  return async (dispatch, getState, apiHandler) => {
    try {
      const { api, apiRoutes } = apiHandler;
      const activeDomain = getState().landing.activeDomain;
      await api(apiRoutes.rescheduleFbPostsApi(postData, activeDomain));
      return Promise.resolve();
    } catch (err) {
      dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}));
      return Promise.reject(err);
    }
  }
}

export function getNewsletterAction() {

  return async (dispatch, getState, apiHandler) => {
    try {
      const { api, apiRoutes, actionTypes } = apiHandler;
      const activeDomain = getState().landing.activeDomain;
      const {data} = await api(apiRoutes.getNewsletterApi(activeDomain));
      dispatch(scheduledFbPosts(actionTypes.FETCHNEWSLETTER.SUCCESS, data));
    } catch (err) {
      dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}));
      return Promise.reject(err);
    }
  }
}

export function sendNewsletterAction(postData) {

  return async (dispatch, getState, apiHandler) => {
    try {
      const { api, apiRoutes } = apiHandler;
      const activeDomain = getState().landing.activeDomain;
      await api(apiRoutes.sendNewsletterApi(postData, activeDomain));
      return Promise.resolve();
    } catch (err) {
      dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}));
      return Promise.reject(err);
    }
  }
}

export function createCampaign(payload) {

  return async (dispatch, getState, apiHandler) => {
    try {
      const { api, apiRoutes } = apiHandler;
      const activeDomain = getState().landing.activeDomain;
      await api(apiRoutes.createCampaignApi(payload, activeDomain));
      return Promise.resolve();
    } catch (err) {
      dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}));
      return Promise.reject(err);
    }
  }
}

export function getAllDomainCampaigns(postData) {

  return async (dispatch, getState, apiHandler) => {
    try {
      const { api, apiRoutes, actionTypes } = apiHandler;
      const activeDomain = getState().landing.activeDomain;
      const {data} = await api(apiRoutes.getAllDomainCampaignsApi(activeDomain));
      dispatch(scheduledFbPosts(actionTypes.FETCHDOMAINCAMPAIGNS.SUCCESS, data));
      return Promise.resolve();
    } catch (err) {
      dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}));
      return Promise.reject(err);
    }
  }
}

export function getAllDomainCampaignsDate(startDate, endDate) {

  return async (dispatch, getState, apiHandler) => {
    try {
      const { api, apiRoutes } = apiHandler;
      const activeDomain = getState().landing.activeDomain;
      await api(apiRoutes.getAllDomainCampaignsDateApi(startDate, endDate, activeDomain));
      return Promise.resolve();
    } catch (err) {
      dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}));
      return Promise.reject(err);
    }
  }
}

export function getCampaignFullDetail(campaignId) {

  return async (dispatch, getState, apiHandler) => {
    try {
      const { api, apiRoutes } = apiHandler;
      const activeDomain = getState().landing.activeDomain;
      await api(apiRoutes.getCampaignFullDetailApi(campaignId, activeDomain));
      return Promise.resolve();
    } catch (err) {
      dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}));
      return Promise.reject(err);
    }
  }
}

export function importSubscribers(campaignId) {

  return async (dispatch, getState, apiHandler) => {
    try {
      const { api, apiRoutes } = apiHandler;
      const activeDomain = getState().landing.activeDomain;
      await api(apiRoutes.importSubscribersApi(campaignId, activeDomain));
      return Promise.resolve();
    } catch (err) {
      dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}));
      return Promise.reject(err);
    }
  }
}

export function saveMediaToPisco(file) {
  return async (dispatch, getState, apiHandler) => {
    try {
      if (!file) {
        return Promise.reject(new Error('Invalid Media File'));
      }
      const { api, apiRoutes } = apiHandler;
      let name = file.name.replace(/[^a-zA-Z.0-9]/g, '');
      file = new File([file], name, { type: file.type });
      const { landing: { activeDomain }} = getState();
      const preSaveData = piscoPreSave(file);
      let {data:preSaveResponse} = await api(apiRoutes.piscoPreSaveApi(preSaveData, activeDomain));
      const localUploadData = localUpload(file);
      let {data:localUploadResponse} = await api(apiRoutes.localUploadApi(localUploadData, activeDomain));
      return Promise.resolve({ preSaveResponse, localUploadResponse });
    } catch (err) {
      dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}));
      return Promise.reject(err);
    }
  }
}

function piscoPreSave(file) {
  const formData = new FormData();
  formData.append("file", file, file.name);
  return formData;
}

function localUpload(file) {
  let mediaType = 'Image';
  if (file.type.startsWith('video')) mediaType = 'Video';
  if (file.type.startsWith('audio')) mediaType = 'Audio';
  const formData = new FormData();
  formData.append("file", new Blob());
  formData.append('filename', file.name);
  formData.append('title', file.name);
  formData.append('tags', ['quick-cms'])
  formData.append('imageSource', 'LOCAL');
  formData.append('type', mediaType);
  formData.append('dimensions', {});
  formData.append('caption', file.name);
  return formData;
}


function scheduledTweets(actionName, data){
  return{
    type: actionName,
    data: data,
  }
}

function scheduledFbPosts(actionName, data){
  return{
    type: actionName,
    data: data,
  }
}