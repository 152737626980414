import insightsAction from "../../constants/actions/insights";

const initalState = {
  earnings:{
    yesterdayEarnings: {
      ads:0,
      sub:0,
    },
    total: {
      startDate: null,
      endDate: null,
      data:[],
    },
    adsOnly: {
      startDate: null,
      endDate: null,
      data:[],
    },
    subOnly: {
      currency: '$',
      startDate: null,
      endDate: null,
      data:[],
    },
  },
  traffic: {
    yesterDayTraffic:0,
    startDate:null,
    endDate: null,
    data:[],
  },
  pageViews: {
    startDate: null,
    endDate: null,
    data:[],
  },
  subscriptionTranasaction: {
    startDate: null,
    endDate: null,
    data:[],
  },
  currency:'',
  currencySymbol:'$',
};
  
/**
 * This reducer function handle the insigts related actions functionalites
 * @param {*} state 
 * @param {*} action 
 */
export default function insightsReducer (state=initalState, action) {
  switch(action.type) {
    case "SET_DEFAULT_INSIGHTS":
      return {
        ...state,
        ...action.data,
      }
    case "SET_EARNINGS_INSIGHTS": {
      const { earnings } = state;
      earnings[action.chartName] = action.data;
      return  {
        ...state,
        ...earnings,
      }
    }
    case "SET_TRAFFIC_INSIGHTS": {
      let { traffic } = state;
      traffic = {...traffic, ...action.data};
      return  {
        ...state,
        traffic,
      }
    }
    case "SET_PAGE_VIEW": {
      let { pageViews } = state;
      pageViews = {...pageViews, ...action.data};
      return {
        ...state,
        pageViews,
      }
    }
    case "SET_SUBSCRIPTION_TRANSACTION": {
      let { subscriptionTranasaction } = state;
      subscriptionTranasaction = {...subscriptionTranasaction, ...action.data};
      return {
        ...state,
        subscriptionTranasaction,
      }
    }
    case "SET_CURRENCY_DATA": {
      let { currency, currencySymbol } = action.data;
      return {
        ...state,
        currencySymbol,
        currency,
      };
    }
    case insightsAction.INSIGHTSREVENUEFORHEADER.SUCCESS:
      return {
        ...state, ...{revenueHeader: action.data},
      }
    case "UpdateActiveDomainAndPermission":
      return initalState;
    default:
      return state;
  }
}