import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import InputBase from '@material-ui/core/InputBase';
import Box from "../muiCustomComponents/Box";
import Button from '../muiCustomComponents/Button';
import getQuickArticle from "../../components/common/common-action";
import { useDispatch } from "react-redux";
import Gird from "../muiCustomComponents/Grid";
import ClearIcon from '@material-ui/icons/Clear';
import InputAdornment from '@material-ui/core/InputAdornment';
import {fetchFeedForSearch,setSearchQueryReducer} from "../../components/common/common-action";


const useStyles = makeStyles(theme => ({
  search: {
    width:'100%',
    position: 'relative',
    borderRadius: theme.spacing(4),
    marginRight: theme.spacing(2),
    marginLeft: 0,
    margin: theme.spacing(4),
    backgroundColor: theme.palette.pubGreywhite,
  },
  searchIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.isMobile ? 10 :"auto",
  },

  inputRoot: {
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(7 / 2),
    paddingRight: theme.spacing(5),
    transition: theme.transitions.create('width'),
    width: '85%',
  },
  searchIconButton: {
    fontWeight: '800',
    fontSize: theme.spacing(4),
    cursor: 'pointer',
  },
  toolBarWidth :{
    width:'100%',
  },
  iconCSS :{
    cursor:'pointer',
  },
}));


export default function MobileSearch() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = err => {
    const value = err.currentTarget.value;
    setSearchQuery(value)
  }
  
  const searchOnEnter =(e)=>{
    if (e.keyCode === 13) {
      startSearch();
    }
  }
  const startSearch = async () => {

    dispatch(setSearchQueryReducer(searchQuery.trim()))
    try {
      dispatch(getQuickArticle())
    }
    catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
    }
  }

  const clearSearch =()=>{
    setSearchQuery('')
    dispatch(fetchFeedForSearch())
  }

  return <Gird container>
    <Gird item xs={12} md={6}>
      <Box display="flex">
      <Toolbar className={classes.toolBarWidth}>
        <Box className={classes.search} >
          <InputBase
            placeholder="Search feed ..."
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            onKeyUp ={searchOnEnter}
            value={searchQuery}
            onChange={handleSearchChange}
            endAdornment={
              <InputAdornment position="start">
                <ClearIcon onClick={clearSearch} className={classes.iconCSS}/>
              </InputAdornment>
            }
          />
        </Box>
      </Toolbar>
      <Box className={classes.searchIcon} onClick={startSearch}>
        <Button type="chips" color="secondary" label="Go" className={classes.searchIconButton} />
      </Box>
      </Box>
    </Gird>

  </Gird>
}