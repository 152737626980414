import React from "react";
import Box from "commons/muiCustomComponents/Box";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import Portal from '@material-ui/core/Portal';

const useStyles = makeStyles(theme => ({
  message: {
    position: 'relative',
    top: theme.spacing(1.5),
    color: theme.palette.pubGreyishBrown,
  },
  pageBlockLoader: {
    width: '100%',
    // touchAction: 'none',
    // height: '100vh',
    // background: '#ffffffba',
    // opacity:'0.7',
  },
}));

/**
 * The function will return Circular progress, you can pass message as props
 * to show message below loader. Also, the size of the loader can be changed.
 * @param {Object} props
 * @return {Component} CircularProgress 
 */
export function CircularLoader(props) {
  const { ...rest } = props;
  const classes = useStyles();
  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      height="100vh" {...rest} >
      <CircularProgress size={props.size || 40} variant="indeterminate" />
      {props.message && <strong className={classes.message}>{props.message}</strong>}
    </Box>
  )
}

/**
 * The function will return Linear Progress
 * @param {Objet} props 
 * @return {Component} LinderProgress
 */
export function LinearLoader(props) {

  const { ...rest } = props;
  if (Object.prototype.hasOwnProperty.call(props, 'position')) {
    return (
      <Box top={"80px"} {...rest} >
        <LinearProgress />
      </Box>
    )
  }
  return (
    <Box {...rest} >
      <LinearProgress />
    </Box>
  )
}

export function PageBlockLoader(props) {
  const classes = useStyles();
  const {...rest} = props;
  return (
    <Portal >
      <Box position="fixed" zIndex={99999} width="100%" top={0} {...rest}>
      {/* <Box className={classes.pageBlockLoader}> */}
        <LinearProgress />
      {/* </Box> */}
      </Box>
    </Portal>
  )
}