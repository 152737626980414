
const onboardAction = {
  CREATE: {
    SUCCESS: "createTypeSuccess",
  },
  ENABLENEWSLETTER: {
    SUCCESS: "enableNewsletterSuccess",
  },
}
  
export default onboardAction;