import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import Grid from "commons/muiCustomComponents/Grid";
import Paper from "commons/muiCustomComponents/Paper";
import Button from "commons/muiCustomComponents/Button";
import ButtonGroup from "commons/muiCustomComponents/ButtonGroup";
import Typography from '@material-ui/core/Typography';
import Box from "commons/muiCustomComponents/Box";
import TrafficTable from "./components/TrafficTable";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import ArrowForward from "@material-ui/icons/ArrowForward";
import { LinearLoader, PageBlockLoader } from 'commons/commonWrapper/Loaders';
import {
  ResponsiveContainer,
  Line,
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';
import {
  getInsightsRevenueAction,
  getInsightsViewsAction,
  setTrafficData,
  setPageViews,
} from "./insights-action";
import MobileProvider from "commons/context/MobileProvider";
import { makeStyles } from '@material-ui/core/styles';
import { CircularLoader } from "commons/commonWrapper/Loaders";
import { LogglyTracker } from 'loggly-jslogger';

const logger = new LogglyTracker();
logger.push({ 'logglyKey': '142d21f5-10d3-47a9-aa5a-f83e0a3abed7', 'sendConsoleErrors' : true, 'tag' : 'ardbeg,analytics,visitorcomponent' });

const useStyles = makeStyles(theme => ({
  quickViewSection: {
    margin: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    height: theme.isMobile ? '50px' : '80px',
    padding: theme.spacing(4),
  },
  detailViewSection: {
    // margin: theme.spacing(2),
  },
  revenueNumber: {
    fontSize: theme.spacing(3),
    color: 'grey',
  },
  trafficNumber: {
    fontSize: theme.spacing(3),
    color: 'grey',
  },
  tabPanel: {
  },
  timeRangeSection: {
    paddingTop: theme.spacing(4),
    // paddingBottom:theme.spacing(4),
    margin: theme.spacing(2),
  },
  timeRangePreviewContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    margin: theme.spacing(2),
  },
  timeRangePreviewSection: {
    fontWeight: '600',
    padding: theme.spacing(2),
    fontSize: theme.spacing(3.5),
    display:'flex',
    justifyContent:'center',
    cursor:'pointer',
    textTransform:'uppercase',
  },
  chartTitle: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    fontWeight: 'bold',
    padding: theme.spacing(2),
  },
  quickViewContainer: {
    marginTop: theme.spacing(4),
  },
  disclaimerContainer: {
    fontSize: theme.spacing(3.5),
  },
  customToolbarContainer: {
    textAlign: 'center',
    background: theme.palette.primary.main,
    color: 'white',
    padding: theme.spacing(2),
  },
  detailViewContainer: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  loaderBox: {
    position: 'absolute',
    top: 0,
    height: '100px',
    width: '100%',
    zIndex: 20,
    background: "rgba(255,255,255,0.5)",
  },
  grossContainer: {
    marginTop:theme.spacing(6),
    fontSize: theme.spacing(4),
    fontWeight: '600',
    color:theme.palette.primary.main,
  },
  tabIndicator :{
    display:'none',
  },
  datRangeGridContainer :{
    justifyContent:'space-between',
  },
  table: {
    minWidth: 650,
  },
  textdecoration: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  heighPowerFont: {
    fontWeight: 600,
  },
  flexClasses: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  productBackground :{
    backgroundColor:theme.palette.secondary.light,
    border:'none',
    "&:hover,&:focus": {
      backgroundColor:theme.palette.secondary.light,
      border:'none',
    },
  },
  wordBreak :{
    wordBreak:'break-word',
  },

}));

const getFormattedDate = (date) => {
  return moment(date).format('YYYY-MM-DD');
}

function getDateRange(startDate, endDate) {
  let dateList = [];
  let tempDate = startDate;
  while (moment(tempDate).isSameOrBefore(endDate)) {
    dateList.push(getFormattedDate(tempDate));
    tempDate = moment(tempDate).add(1, 'day');
  }
  return dateList;
}

const getFinalData = (dateList, res) => {
  let resObj = {}
  for (let i = 0; i < res.length; i++) {
    if (!resObj[res[i]['date']]) { resObj[res[i]['date']] = res[i]; }
  }
  let finalData = dateList.map(item => {
    if (resObj[item]) {
      return resObj[item]
    }
    return {
      "date": item,
      "traffic": 0,
      "adsRev": 0,
      "subsRevInr": 0,
      "subsRevUsd": 0,
    }
  })
  return finalData;
};

const VisitorComponent = ({ appbarheight }) => {

  const isMobile = useContext(MobileProvider);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();
  const {landing: {userProfile}} = useSelector(state => state);

  const defaultDateRange = {
    startDate: moment().subtract(1, 'week').toDate(),
    endDate: moment().toDate(),
    key: 'selection',
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchInitialData = async () => {
    try {
      let res = await dispatch(getInsightsViewsAction(moment().subtract(1, 'week').format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")))
      dispatch(setPageViews({ startDate: moment().subtract(1, 'week').format("YYYY-MM-DD"), endDate: moment().format("YYYY-MM-DD"), data: res }));
      const dateRange = {
        startDate: new Date(moment().subtract(1, 'week').format("YYYY-MM-DD")),
        endDate: new Date(moment().format("YYYY-MM-DD")),
      };
      const dateList = getDateRange(getFormattedDate(dateRange.startDate), getFormattedDate(dateRange.endDate));
      let res2 = await dispatch(getInsightsRevenueAction(getFormattedDate(dateRange.startDate), getFormattedDate(dateRange.endDate)));
      const data = getFinalData(dateList, res2);
      dispatch(setTrafficData({ startDate: dateRange.startDate, endDate: dateRange.endDate, data: data }, 'traffic'));
    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Grid container className={classes.detailViewContainer}>
      <Grid item md={12} xl={12} xs={12}>
         <Box ml={isMobile?2:0} mr={isMobile?2:0}>
          {RenderChartSection({ isMobile, defaultDateRange, isLoading })}
        </Box>
      </Grid>
    </Grid>
  )
}

export default VisitorComponent;



function RenderChartSection(props) {
  const { insights } = useSelector(state => state);
  const { traffic, pageViews } = insights;
  return (
    <Box>
      <Grid container >
        <Grid item md={12} xs={12}>
          <Box mt={3}>
          <Paper>
          {RenderLineChartWithTimeRange({
            title: 'Website Sessions',
            defaultDateRange: props.defaultDateRange,
            data: traffic,
            chartName: 'traffic',
            isLoading: props.isLoading,
          })}
          </Paper>
          </Box>
        </Grid>
        <Grid item md={12} xs={12}>
        <Box mt={3}>
          <Paper>
          {RenderTrafficTableWithTimeRange({
            allowedFilters: ['1d', '1w', '4w'],
            defaultDateRange: props.defaultDateRange,
            data: pageViews,
            isLoading: props.isLoading,
          })}
           </Paper>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}


function RenderLineChartWithTimeRange(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [activeFilter, setActiveFilter] = useState("1w");
  const [isLocalLoading, setisLocalLoading] = useState(false);
  const isMobile = useContext(MobileProvider);
  const filters = isMobile ? ['1d', '1w', '4w', '1y', 'mtd', 'ytd'] : ['1d', '1w', '4w', '1y', 'mtd', 'ytd', 'custom']
  const [dateRange, setDateRange] = useState(props.defaultDateRange);
  const fetchRevenueAndTrafficData = async (activeFilter, dateRange) => {
    setisLocalLoading(true);
    try {
      const dateList = getDateRange(getFormattedDate(dateRange.startDate), getFormattedDate(dateRange.endDate));
      let res = await dispatch(getInsightsRevenueAction(getFormattedDate(dateRange.startDate), getFormattedDate(dateRange.endDate)));
      const data = getFinalData(dateList, res);
      dispatch(setTrafficData({ startDate: dateRange.startDate, endDate: dateRange.endDate, data: data }, props.chartName));
    } catch (err) {
      // handle error
    } finally {
      setisLocalLoading(false);
    }
  }

  const handleFilterChange = (value, range) => {
    setActiveFilter(value);
    let newDateRange = dateRange;
    switch (value) {
      case '1d':
        newDateRange = {
          startDate: moment().toDate(),
          endDate: moment().toDate(),
          key: 'selection',
        }
        break;
      case '1w':
        newDateRange = {
          startDate: moment().subtract(1, 'week').toDate(),
          endDate: moment().toDate(),
          key: 'selection',
        }
        break;
      case '4w':
        newDateRange = {
          startDate: moment().subtract(28, 'days').toDate(),
          endDate: moment().toDate(),
          key: 'selection',
        }
        break;
      case '1y':
        newDateRange = {
          startDate: moment().subtract(365, 'days').toDate(),
          endDate: moment().toDate(),
          key: 'selection',
        }
        break;
      case 'mtd':
        newDateRange = {
          startDate: moment().startOf('month').toDate(),
          endDate: moment().toDate(),
          key: 'selection',
        }
        break;
      case 'ytd':
        newDateRange = {
          startDate: moment().startOf('year').toDate(),
          endDate: moment().toDate(),
          key: 'selection',
        }
        break;
      case 'custom':
        newDateRange = range;
        break;
      default: return;
    }
    setDateRange(newDateRange);
    fetchRevenueAndTrafficData(value, newDateRange);
  }


  return (
    <Box>
      <Box style={{ position: 'relative' }}>
        {(props.isLoading || isLocalLoading) ? <Box display="flex" justifyContent="center" alignItems="center" className={classes.loaderBox}>
          <CircularLoader></CircularLoader>
        </Box> : null}
        <Box>
          {
            TimeRangeSelector({
              allowedFilters: filters,
              activeFilter,
              handleFilterChange,
              dateRange,
              setDateRange: setDateRange,
              showSelectedDates: true,
            })
          }
          {DrawLineChart({ title: props.title, data: props.data, chartName: props.chartName, currency: props.currency })}
        </Box>
      </Box>
    </Box>
  )
}

function RenderTrafficTableWithTimeRange(props) {
  const dispatch = useDispatch();
  const [activeFilter, setActiveFilter] = useState("1w");
  const [isLoading, setLoading] = useState(props.isLoading||false);
  const classes = useStyles();
  useEffect(() => {
    setLoading(props.isLoading);
  }, [props.isLoading]);
  const fetchPageViews = async (startDate, endDate) => {
    try {
      setLoading(true);
      dispatch(setPageViews({ startDate: startDate, endDate: endDate, data: [] }));
      let res = await dispatch(getInsightsViewsAction(getFormattedDate(startDate), getFormattedDate(endDate)))
      dispatch(setPageViews({ startDate: startDate, endDate: endDate, data: res }));
    } catch (err) {
      // handle error
    } finally {
      setLoading(false);
    }
  }
  const handleFilterChange = (value) => {
    setActiveFilter(value);
    let newDateRange = {};
    switch (value) {
      case '1d':
        newDateRange = {
          startDate: moment().toDate(),
          endDate: moment().toDate(),
          key: 'selection',
        }
        break;
      case '1w':
        newDateRange = {
          startDate: moment().subtract(1, 'week').toDate(),
          endDate: moment().toDate(),
          key: 'selection',
        }
        break;
      case '4w':
        newDateRange = {
          startDate: moment().subtract(4, 'weeks').toDate(),
          endDate: moment().toDate(),
          key: 'selection',
        }
        break;
      default: return;
    }
    fetchPageViews(newDateRange.startDate, newDateRange.endDate);
  }

  const { data: { data } } = props;
  return (
    <Box>
      {isLoading && <PageBlockLoader />}
      {isLoading && <Box style={{ position: 'relative'}}>
        <Box display="flex" justifyContent="center" alignItems="center" className={classes.loaderBox}>
          <CircularLoader></CircularLoader>
        </Box>
      </Box>}
      {TimeRangeSelector({ allowedFilters: props.allowedFilters, activeFilter, handleFilterChange, dateRange: null, showSelectedDates: false })}
      <TrafficTable data={data} />
    </Box>
  )
}

function groupDataByWeek(realData) {
  try {
    const data = [...realData];
    const len = data.length;
    const finalResult = [];
    const parts = Math.ceil(len / 7);
    let result = [];
    for (let i = parts; i > 0; i--) {
      result.push(data.splice(0, Math.ceil(data.length / i)));
    }
    for (let i = 0; i < result.length; i++) {
      let item = result[i];
      const fe = item[0];
      const le = item[item.length - 1];
      const range = `${moment(fe.date).format('DD MMM')}-${moment(le.date).format('DD MMM')}`;
      /**------------------- calculate the sum----------------------*/
      const data = item.reduce((total, currentValue, currentIndex, arr) => {
        return {
          date: total.date,
          traffic: total.traffic + currentValue.traffic,
          adsRev: total.adsRev + currentValue.adsRev,
          subsRevInr: total.subsRevInr + currentValue.subsRevInr,
          subsRevUsd: total.subsRevUsd + currentValue.subsRevUsd,
          total: total.total + currentValue.total,
        }
      }, {
        "date": range,
        "traffic": 0,
        "adsRev": 0,
        "subsRevInr": 0,
        "subsRevUsd": 0,
        "total": 0,
      });
      finalResult.push(data);
      /**----------------------end of sum------------------------------*/
    }
    return finalResult;
  } catch (err) {
    //handle error
  }
}

const monthMapping = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

function groupDataByMonths(data) {
  try {
    let monthObj = {};
    for (let i = 0; i < data.length; i++) {
      const obj = data[i];
      let month = moment(obj.date).month();
      const year = moment(obj.date).year();
      month = monthMapping[month];
      const dd = `${month},${year}`;
      if (monthObj[dd]) {
        const item = monthObj[dd];
        const newData = {
          date: dd,
          traffic: item.traffic + obj.traffic,
          adsRev: item.adsRev + obj.adsRev,
          subsRevInr: item.subsRevInr + obj.subsRevInr,
          subsRevUsd: item.subsRevUsd + obj.subsRevUsd,
          total: item.total + obj.total,
        }
        monthObj[dd] = newData;
      } else {
        const newData = {
          date: dd,
          traffic: obj.traffic,
          adsRev: obj.adsRev,
          subsRevInr: obj.subsRevInr,
          subsRevUsd: obj.subsRevUsd,
          total: obj.total,
        }
        monthObj[dd] = newData;
      }
    }
    let finalResult = [];
    const keysObj = Object.keys(monthObj);
    for (let i = 0; i < keysObj.length; i++) {
      finalResult.push(monthObj[keysObj[i]]);
    }
    return finalResult;
  } catch (err) {
    //handle error
  }
}

function DrawLineChart(props) {
  const isMobile = useContext(MobileProvider);
  const classes = useStyles();
  let { data: { data } } = props;

  let dataKey = 'total';
  if (props.chartName === 'total') {
    data = data.map((item) => {
      let dd = item.subsRevUsd;
      if (props.currency === 'INR') {
        dd = item.subsRevInr / 70;
      }
      return {
        ...item,
        total: item.adsRev + dd,
      }
    });
  } else if (props.chartName === 'adsOnly') {
    dataKey = 'adsRev';
  } else if (props.chartName === 'subOnly') {
    if (props.currency === 'INR') {
      dataKey = 'subsRevInr';
    } else {
      dataKey = "subsRevUsd";
    }
  } else if (props.chartName === 'traffic') {
    dataKey = 'traffic';
  }
  let tickFormat = 'daily';
  if (data.length > 12 && data.length <= 84) {
    data = groupDataByWeek(data);
    tickFormat = 'custom';
  }
  if (data.length > 84) {
    data = groupDataByMonths(data);
    tickFormat = 'custom';
  }
  data = data.map(item => {
    return {
      ...item,
      adsRev: parseFloat(item.adsRev),
      subsRevInr: parseFloat(item.subsRevInr),
      subsRevUsd: parseFloat(item.subsRevUsd),
      total: parseFloat(item.total || 0),
    }
  });
  let grossValue = 0;
  const finalData = [...data];
  grossValue = finalData.reduce((total, currentValue, currentIndex, arr) => {
    return total + currentValue[dataKey];
  }, 0);
  grossValue = props.chartName === 'traffic' ? grossValue : parseFloat(grossValue).toFixed(2);
  return (
    <Box>
      <Box className={classes.grossContainer}>Gross: {props.chartName === 'total' ? 'USD' : props.currency} {grossValue}</Box>
      <ResponsiveContainer width='100%' height={300}>
        <LineChart data={data} margin={{ top: 30, bottom: 5, left: 17, right: 17 }}>
          <XAxis
            dataKey="date"
            height={80}
            interval={0}
            scale="point"
            minTickGap={7}
            tick={<CustomizedXAxisTick isMobile={isMobile} tickFormat={tickFormat} />}
          />
          <YAxis
            axisLine={false}
            width={50}
            hide={isMobile ? true : false}
            tick={<CustomizedYAxisTick  chartname={props.chartName}/>}
          />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip formatter={(value, name) => {
            let ll = 'Amount';
            if (name === 'traffic') {
              ll = 'Sessions'
            }
            return [name === 'traffic' ? parseInt(value) :parseFloat(value).toFixed(2), ll];
          }} />
          <Line
            dataKey={dataKey}
            dot
            label={!isMobile ? false : <CustomizedLabel />}
          />
        </LineChart>
      </ResponsiveContainer>
      <Box className={classes.chartTitle}>{props.title}&nbsp;{props.chartName === 'total' ? '(USD)' : (props.currency ? `(${props.currency})` : null)}</Box>
    </Box>
  )
}


function CustomizedLabel(props) {
  const { x, y, stroke, value } = props;
  return <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">{parseFloat(value).toFixed(2)}</text>

}

function CustomizedYAxisTick(props) {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} textAnchor="end" style={{ fontSize: '12px' }} fill="#666">
        {props.chartname ? parseInt(payload.value) : parseFloat(payload.value).toFixed(2)}</text>
    </g>
  );
}

function CustomizedXAxisTick(props) {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={10}
        textAnchor="end"
        style={{ fontSize: props.isMobile ? '10px' : '12px' }}
        fill="#666"
        transform={`rotate(${props.isMobile ? '-75' : '-55'})`}
      >
        {FormattedTickValue(props.tickFormat, payload.value)}
      </text>
    </g>
  );
}

function TimeRangeSelector(props) {

  const classes = useStyles();
  const { allowedFilters, dateRange } = props;
  const [showStartDatePicker, setStartShowDatePicker] = useState(false);
  const [showEndDatePicker, setShowEndDatePicker] = useState(false);

  const isMobile = useContext(MobileProvider);

  const handleFilterChange = (event) => {
    const value = event.currentTarget.value;
    if (value === 'custom') {
      setStartShowDatePicker(true);
      return;
    }
    props.handleFilterChange(value);
  }
  const handleStartSelect = (moment) => {
    props.setDateRange({
      startDate: moment.toDate(),
      endDate: moment.toDate(),
      key: 'selection',
    });
    setTimeout(() => {
      setStartShowDatePicker(false);
      setShowEndDatePicker(true);
    }, 200);
  }

  const handleEndSelect = (momentDate) => {
    props.setDateRange({
      startDate: moment(dateRange.startDate).toDate(),
      endDate: momentDate.toDate(),
      key: 'selection',
    });
    setShowEndDatePicker(false);
    props.handleFilterChange('custom', {
      startDate: moment(dateRange.startDate).toDate(),
      endDate: momentDate.toDate(),
      key: 'selection',
    });
  }

  const CustomDatePickerField = (props) => {
    return (
      <span onClick={() => props.onClickHandler(true)}>{props.value}</span>
    )
  }

  const CustomToolbar = (data, props) => {
    return (
      <Box className={classes.customToolbarContainer}>
        <Typography variant="h4">{data}</Typography>
        <Typography variant="h5">{moment(props.date).format('DD,MMM YYYY')}</Typography>
      </Box>
    )
  }
  return (
    <Grid container className={classes.datRangeGridContainer}>
      <Grid item xs={12} md={8} lg={6}>
        <ButtonGroup  size="small" aria-label="small button group">
          {
            allowedFilters.map((item) => {
              return (
                <Button key={item} variant={props.activeFilter === item ? 'contained' : 'outlined'} value={item} onClick={handleFilterChange}>
                  {item.toUpperCase()}
                </Button>
              )
            })
          }
        </ButtonGroup>
      </Grid>
      <Grid item md={4} lg={4}>
        {props.showSelectedDates && !isMobile && dateRange && <Box >
          <Paper className={classes.timeRangePreviewSection}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                open={showStartDatePicker}
                autoOk
                disableFuture
                onClickHandler={() => { setStartShowDatePicker(true) }}
                onOpen={() => setStartShowDatePicker(true)}
                onClose={() => setStartShowDatePicker(false)}
                maxDate={moment()}
                format='DD MMM YYYY'
                TextFieldComponent={CustomDatePickerField}
                ToolbarComponent={CustomToolbar.bind(null, 'Start Date')}
                value={moment(dateRange.startDate)}
                onChange={handleStartSelect}
                variant="inline"
              />
              <ArrowForward style={{ fontSize: '14px' }} />
              <KeyboardDatePicker
                open={showEndDatePicker}
                autoOk
                disableToolbar={false}
                onClickHandler={(event) => { setShowEndDatePicker(true) }}
                onOpen={() => setShowEndDatePicker(true)}
                onClose={() => setShowEndDatePicker(false)}
                minDate={moment(dateRange.startDate)}
                maxDate={moment()}
                format='DD MMM YYYY'
                disableFuture
                value={moment(dateRange.endDate)}
                TextFieldComponent={CustomDatePickerField}
                ToolbarComponent={CustomToolbar.bind(null, 'End Date')}
                onChange={handleEndSelect}
                variant="inline"
              />
            </MuiPickersUtilsProvider>
          </Paper>
        </Box>}
      </Grid>
    </Grid>
  )
}

function FormattedTickValue(conversion, data) {

  switch (conversion) {
    case 'daily': {
      let [year, month, date] = data.split('-');
      let formattedMonth = moment([year, month - 1, date]).format('DD-MMM');
      return formattedMonth;
    }
    default: {
      return data;
    }
  }
}
