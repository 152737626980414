import React, { useState, useEffect, useRef,useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LinearLoader,CircularLoader } from 'commons/commonWrapper/Loaders';
import Grid from "commons/muiCustomComponents/Grid";
import Box from "commons/muiCustomComponents/Box";
import { makeStyles } from '@material-ui/core/styles';
import { fetchFeedForSearch } from "../common-action";
import { deleteArticle } from "../../articles/article-action";
import setAppMessage from "app-component/app-component-action";
import debounce from "lodash.debounce";
import RootRef from '@material-ui/core/RootRef';
import getQuickArticle from "../common-action";
import MobileProvider from "commons/context/MobileProvider";
import MobileMediaCard from "../../articles/components/MobileMediaCard";
import MediaCard from "../../articles/components/MediaCard";
import Paper from "commons/muiCustomComponents/Paper";
import { LogglyTracker } from 'loggly-jslogger';

const logger = new LogglyTracker();
logger.push({ 'logglyKey': '142d21f5-10d3-47a9-aa5a-f83e0a3abed7', 'sendConsoleErrors' : true, 'tag' : 'ardbeg,common,search' });

const useStyles = makeStyles(theme => ({
  container: {
    paddingLeft: theme.isMobile ?0:theme.spacing(4),
    marginBottom:theme.spacing(2),
    height:'85vh',
    overflow:'scroll',
  },
}));


export default function Search({ appBarHeight,setAnchorEl,handleSearchDeleteMobileArticle }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const searchContainerRef  = useRef(null);
  const isMobile =  useContext(MobileProvider);
  const [isFetching, setFetching] = useState(false);
  const [isDeleting ,setDeleteting] = useState(false);
  const { commonComponent: { search, searchMeta} } = useSelector(state => state);
  const { isLoading,searchQuery ,page} = searchMeta;
  const {landing: {userProfile}} = useSelector(state => state);

  useEffect(() => {
    let isMounted = true;
    const getFeed = async () => {

      try {
        setFetching(true)
        await dispatch(fetchFeedForSearch())
        isMounted && setFetching(false)
      }
      catch (err) {
        const data = {
              'error': err,
              'email': (userProfile||{}).email || '',
              'url': (window.location||{}).href || '',
              'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
              'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
            };
            logger.push({
                'type': 'error',
                'data': data,
              });
        isMounted && setFetching(false);
      }

    }
    getFeed()
    return () => isMounted = false;
  }, [])

  const callNewArticle =debounce(()=>{
    if (searchContainerRef.current.scrollTop + searchContainerRef.current.clientHeight >= searchContainerRef.current.scrollHeight - 200){
       !isLoading && dispatch(getQuickArticle());
    }
  },200)

  useEffect(()=>{
    searchContainerRef.current.addEventListener("scroll",callNewArticle)
  },[]);
  
  return (<>
  {isDeleting &&  <LinearLoader position="sticky" top={appBarHeight} width={"100%"} /> }
  <Grid container >
    {(isLoading ||isFetching) && page===0 &&  <LinearLoader position="sticky" top={appBarHeight} width={"100%"} />}
    <RootRef rootRef={searchContainerRef} >
    <Grid item xs={12} md={12} className={classes.container} >
      {
        searchQuery &&
        <Box
          width="85%"
          m="auto"
          mt={6} mb={6}
          fontSize={20}
          fontWeight={500}
          color={"pubGreyishBrown"}>Showing results for "{searchQuery}"</Box>
      }
      <Grid item xs={12} md={8} xl={8}>
      {search.length > 0 &&  search.map((item, key) => {
        return <Box mt={4}>
          <Paper>
              {
                  isMobile ?<MobileMediaCard key={key} item={item} handleSearchDeleteMobileArticle={handleSearchDeleteMobileArticle} /> :
                  <MediaCard item={item} setAnchorEl={setAnchorEl} />
              }
          </Paper>
        </Box>
      })
      }
      { page >0  && isLoading &&  <CircularLoader mt={3} mb={3} height="auto" message="loading more article..."/> }
      </Grid>
      {
        search.length === 0 &&  <Box width="85%"
         m="auto"
         mt={6}
       >
        { (!isLoading && !isFetching ) && `No Result found`}
       </Box>
      }
    </Grid>
    </RootRef>
    </Grid>
  </>
  )
}
