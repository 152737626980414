import onboardAction from "../../constants/actions/onboard";

const initalState = {};
  
/**
 * This reducer function handle the onboard related
 * functionalites
 * @param {*} state 
 * @param {*} action 
 */
export default function onBoardReducer (state=initalState, action) {
  const store = action.data;
  switch(action.type) {
    case onboardAction.CREATE.SUCCESS:
      return {
        ...state, ...store,
      }
    case onboardAction.ENABLENEWSLETTER.SUCCESS:
      return {
        ...state, ...store,
      }
    default:
      return state;
  }
}