import App from "../constants/actions/app";

const initState = {
  defaultMessageState: {
    header: 'Are you sure ?',
    message: '',
    param: {},
    isOpen: false,
    handleSuccess: () => { },
    leftButtonText: 'YES',
    rightButtonText: 'NO',
    type: 'normal',
  },
  MediaStorage :{
    fileMeta :{},
    isOpen : false,
    status :'',
    isAbort : false,
  },
}

export default function app(state = { msg: initState.defaultMessageState ,storage :initState.MediaStorage }, action) {
  switch (action.type) {
    case App.MessageAction.SET:
      state.msg = {
        ...state.msg,
        ...action.data,
        isOpen: true,
      }
      return {
        ...state,
      }
    case App.MessageAction.CLEAR: {
      state.msg = initState.defaultMessageState
      return {
        ...state,
      }
    }
    case App.MediaAction.SET :{
      
      state.storage.fileMeta = action.fileMeta;
      return {
          ...state,
      }
    }
    case App.MediaControl.IS_OPEN:{
      state.storage.isOpen = action.data;
      return{
        ...state,
      }
    }
    case App.MediaControl.IS_ABORT:{
      state.storage.isAbort = action.data;
      return{
        ...state,
      }
    }
    case App.MediaControl.IS_STATUS:{
      state.storage.status = action.status;
      return{
        ...state,
      }
    }
    case App.MediaControl.PROCESSING_PERCENTAGE :{
      state.storage.processingPercentage = action.processingPercentage;
      return{
        ...state,
      }
    }
    case App.MediaControl.RESET :{
      state.storage ={
        fileMeta :{},
        isOpen : false,
        status :'',
        isAbort : false,
      }
      return {
        ...state,
      }
    }
    default:
      return state;
  }
}
