import React, { useEffect, useState, useContext } from "react";
import { fetchUserList, deleteUserFromDomain } from "./user-list-action";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Box from "commons/muiCustomComponents/Box";
import Button from "commons/muiCustomComponents/Button";
import Grid from "commons/muiCustomComponents/Grid";
import Menu from "commons/muiCustomComponents/Menu";
import MenuItem from "commons/muiCustomComponents/MenuItem";
import setAppMessage from "app-component/app-component-action";
import { LinearLoader ,PageBlockLoader} from "commons/commonWrapper/Loaders";
import MoreVert from "@material-ui/icons/MoreVert";
import ActionSheet from "commons/muiCustomComponents/ActionSheet";
import MobileProvider from "commons/context/MobileProvider";
import Edit from "@material-ui/icons/Edit";
import Paper from "commons/muiCustomComponents/Paper";
import { LogglyTracker } from 'loggly-jslogger';

const logger = new LogglyTracker();
logger.push({ 'logglyKey': '142d21f5-10d3-47a9-aa5a-f83e0a3abed7', 'sendConsoleErrors' : true, 'tag' : 'ardbeg,user-management,user-list-home' });


const useStyles = makeStyles(theme => ({
  addUserSection: {
    height: '100px',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(4),
  },
  addUserButton: {
    padding: theme.spacing(3),
    borderRadius: theme.spacing(8),
    width: theme.spacing(34),
  },
  child: {
    height: '100%',
  },
  listItem: {
    padding: theme.spacing(4),
    borderBottom: '1px solid grey',
    background: theme.palette.pubGreywhite,
  },
  linkSection: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
  },
  optionSection: {
    justifyContent: "center",
    display: 'flex',
    alignItems: 'center',
  },
}));

function UserListHome(props) {

  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = useContext(MobileProvider);
  const { usersManage: { list }, landing: { userProfile } } = useSelector((state) => state);
  const classes = useStyles();
  const [requesting, setRequesting] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeEmail, setActiveEmail] = useState(null);

  useEffect(() => {
    fetchUserEffectHandler();
  }, []);

  const fetchUserEffectHandler = async () => {
    try {
      setRequesting(true);
      await dispatch(fetchUserList());
      setRequesting(false);
    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      dispatch(setAppMessage({ message: err.message || `Unable to fetch user list.`, header: 'Error', type: 'alert' }));
      setRequesting(false);
    }
  }
  const handleLinkTo = (link) => {
    history.push(link);
  }
  const handleClick = event => {
    setActiveEmail(event.target.getAttribute('email'));
    event.persist();
    setAnchorEl(event.currentTarget);
  };

  const handleGoToPermAction = (activeEmail) => {
    handleLinkTo(`/dash/settings/users/permissions/${activeEmail}`);
  }
  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteUserMobile = async (activeEmail) => {
    try {
      setRequesting(true);
      await dispatch(deleteUserFromDomain(activeEmail));
    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      dispatch(setAppMessage({ message: err.message || `Unable to delete user.`, header: 'Error', type: 'alert' }));
    } finally {
      setRequesting(false);
    }
  };

  const goToPermissions = () => {
    if (activeEmail === null) return;
    handleLinkTo(`/dash/settings/users/permissions/${activeEmail}`);
    handleClose();
  }
  const deleteUserDektop = async () => {
    handleClose();
    try {
      setRequesting(true);
      await dispatch(deleteUserFromDomain(activeEmail));
    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      dispatch(setAppMessage({ message: err.message || `Unable to delete user.`, header: 'Error', type: 'alert' }));
      // handle error
    } finally {
      setRequesting(false);
    }

  }

  const createName = (firstName, lastName) => {
    if (firstName && lastName) {
      return `${firstName.charAt(0)}${lastName.charAt(0)}`
    }
    if (firstName) {
      return `${firstName.charAt(0)}${firstName.charAt(1)}`
    }
    return 'User'
  }
  return (
    <>
      {isMobile && requesting && <LinearLoader />}
      {!isMobile && requesting && <PageBlockLoader />}
      <Grid container>
        <Grid item xs={12} md={9} xl={10}>
          <Box m={isMobile?2:0}>
          <Paper>
            <Button
              onClick={handleLinkTo.bind(null, '/dash/settings/users/new')}
              variant="contained"
              color="primary"
              className={classes.addUserButton}>Add New User</Button>
          </Paper>
          </Box>
        </Grid>
      </Grid>
      <Box m={isMobile?2:0} mt={4}>
        <Grid container>
          <Grid item xs={12} md={9} xl={10}>
            <Paper>
            {!requesting && list.map(item => {
              return (
                <Grid container key={item.userUuid} className={classes.listItem}>
                  <Grid item md={1} xs={3} >
                    <Avatar className={classes.orange} src={item.profilePicture}>{createName(item.firstName, item.lastName)}</Avatar>
                  </Grid>
                  <Grid item md={9} xs={7} className={classes.linkSection}>
                    <Box key={item.userUuid}>{item.firstName} {item.lastName}</Box>
                  </Grid>
                  <Grid className={classes.optionSection} item md={2} xs={2}>
                    {!isMobile ? <MoreVert aria-controls="simple-menu" aria-haspopup="true" email={item.email} onClick={handleClick} />
                      : <ActionSheet type="trippledot"
                        color="secondary" parentStyle={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                        {(handleClose) => (
                          <>
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="space-evenly"
                              mb={4}
                              mt={4}
                              ml={4}
                              mr={4}>
                              <Button variant="contained" color="secondary"
                                style={{ padding: '8px' }}
                                 onClick={() => { handleClose(); handleGoToPermAction(item.email) }} fullWidth>Edit Permissions</Button>
                            </Box>
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="space-evenly"
                              mb={4}
                              ml={4}
                              mr={4}>
                              <Button variant="contained" color="secondary"
                                style={{ padding: '8px' }} onClick={() => { handleClose(); deleteUserMobile(item.email) }} fullWidth>Delete User</Button>
                            </Box>
                          </>
                        )}
                      </ActionSheet>}
                  </Grid>
                </Grid>
              )
            })}
            </Paper>
        </Grid> 
        </Grid>
      </Box>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={goToPermissions}>Edit Permissions</MenuItem>
        <MenuItem onClick={deleteUserDektop}>Delete User</MenuItem>
      </Menu>
    </>
  )
}

export default UserListHome;