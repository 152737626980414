export function fetchUserList() {
  return async(dispatch, getState, apiHandler) => {
    const { api, apiRoutes } = apiHandler;
    try {
      const { landing:{ activeDomain }} = getState();
      let {data} = await api(apiRoutes.userListApi(activeDomain));
      dispatch({ type:'fetchUserListSuccess', data});
      return Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export function addNewUser(email, firstName, lastName) {
  return  async (dispatch, getState, apiHandler) => {
    const { api, apiRoutes } = apiHandler;
    try {
      const {landing: {activeDomain}} = getState();
      let res = await api(apiRoutes.addUserToDomain(activeDomain, email, firstName, lastName));
      return Promise.resolve(res);
    } catch(err) {
      return Promise.reject(err);
    }
  }
}


export function fetchSimplifiedPermissionList() {
  return async(dispatch, getState, apiHandler) => {
    const { api, apiRoutes } = apiHandler;
    try {
      const { landing:{activeDomain }} = getState();
      let {data} = await api(apiRoutes.simplifiedPermissionsApi(activeDomain));
      return Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export function fetchUserDomainPermissions(emailId) {
  return async (dispatch, getState, apiHandler) => {
    try {
      const { api, apiRoutes }  = apiHandler;
      const { landing: { activeDomain }} = getState();
      let {data} = await api(apiRoutes.userPermissionsApi(activeDomain, emailId));
      dispatch({type:"setUserPermissions", data});
      return Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export function deleteUserFromDomain(emailId) {
  return async (dispatch, getState, apiHandler) => {
    try {
      const { api, apiRoutes }  = apiHandler;
      const { usersManage:{list} } = getState();
      const { landing: { activeDomain }} = getState();
      let {data} = await api(apiRoutes.deleteUser(activeDomain, emailId));
      const newlist = list.filter((item) => {
        return !(item.email === emailId);
      });
      dispatch({type:'updateUserList', newlist});
      return Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export function updateDomainPermissions(event, email) {
  return async (dispatch, getState, apiHandler) => {
    const { landing:{activeDomain},usersManage:{userPermissions}} = getState();
    const permCopy = [...userPermissions];
    try {
      const { api, apiRoutes } = apiHandler;
      if(event.target.checked) {
        if(userPermissions.indexOf(event.target.value) === -1) {
          userPermissions.push(event.target.value)
        }
      } else {
        for( var i = 0; i < userPermissions.length; i++) { 
          if ( userPermissions[i] === event.target.value) {
            userPermissions.splice(i, 1);
          }
        }
      }
      dispatch({type:"setUserPermissions", data:userPermissions});
      if(event.target.checked) await api(apiRoutes.addUserPermission(activeDomain, event.target.value, email));
      if(!event.target.checked) await api(apiRoutes.deleteUserPermission(activeDomain, event.target.value, email));
      return Promise.resolve();
    } catch (err) {
      dispatch({type:"setUserPermissions", data:permCopy});
      return Promise.reject(err);
    }
  }
}