import React from 'react';
import MuiPaper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  outlined: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
  },
}));
export default function Paper(props) {
  const classes = useStyles();
  const { variant, ...rest } = props;
  // having issue in material ui
  if (variant === 'outlined') {
    return <MuiPaper variant={variant} {...rest} elevation={0} className={classes.outlined} 
    />
  }
  return <MuiPaper variant={variant} {...rest} />
}