import React from "react";
import link from "assets/editor-icons/link.svg";
import bold from "assets/editor-icons/bold.svg";
import h2 from "assets/editor-icons/h2.svg";
import h3 from "assets/editor-icons/h3.svg";
import bq from "assets/editor-icons/bq.svg";
import embed from "assets/editor-icons/embed.svg";
import italics from "assets/editor-icons/italics.svg";
import ol from "assets/editor-icons/ol.svg";
import ul from "assets/editor-icons/ul.svg";
import media from "assets/editor-icons/media.svg";
import underline from "assets/editor-icons/underline.svg";
import ConvertToHtmlNew from "./export-html";
import Box from "commons/muiCustomComponents/Box";

import "draft-js/dist/Draft.css";
import {
  Editor,
  EditorState,
  RichUtils,
  getDefaultKeyBinding,
  convertToRaw,
  convertFromRaw,
  CompositeDecorator,
  EditorBlock,
  ContentState,
  Modifier,
  SelectionState,
  AtomicBlockUtils,
  genKey,
  ContentBlock,
} from "draft-js";
import { Map, List } from 'immutable';
import isSoftNewlineEvent from 'draft-js/lib/isSoftNewlineEvent';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import withUpload from "commons/hocs/with-file-upload";

const Block = {
  UNSTYLED: 'unstyled',
  PARAGRAPH: 'unstyled',
  OL: 'ordered-list-item',
  UL: 'unordered-list-item',
  H1: 'header-one',
  H2: 'header-two',
  H3: 'header-three',
  H4: 'header-four',
  H5: 'header-five',
  H6: 'header-six',
  CODE: 'code-block',
  BLOCKQUOTE: 'blockquote',
  PULLQUOTE: 'pullquote',
  ATOMIC: 'atomic',
  BLOCKQUOTE_CAPTION: 'block-quote-caption',
  CAPTION: 'caption',
  TODO: 'todo',
  IMAGE: 'atomic:image',
  BREAK: 'atomic:break',
};

class StyleButton extends React.Component {

  constructor() {
    super();
    this.onToggle = (e) => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
    this.icons = {
      "link": link,
      "bq": bq,
      "bold": bold,
      "media": media,
      "h2": h2,
      "h3": h3,
      "italics": italics,
      "embed": embed,
      "ul": ul,
      "ol": ol,
      "underline":underline,
    };
  }
  

  render() {
    let className = 'RichEditor-styleButton';
    if (this.props.active) {
      className += ' RichEditor-activeButton';
    }
    return (
      <span className={className} onMouseDown={this.onToggle} title={this.props.title}>
        {this.props.icon ? 
        <img alt="img" src={this.icons[this.props.icon]} width="30" height="30" style={{ border: `${this.props.active ? 'blue 2px solid': ''}`}} /> 
        : <p><b>{this.props.label}</b></p> }
      </span>
    );
  }
}
const WithUplodStyleButton = withUpload(StyleButton);


export default class DraftJsEditor extends React.Component {
  constructor(props) {
    super(props);
    this.decorator = new CompositeDecorator([
      {
        strategy: findLinkEntities,
        component: Link,
      },
      {
        strategy: findEmbedEntities,
        component: Embed,
      },
      {
        strategy: mediaUploadEntities,
        component: Media,
      },
      {
        strategy: findImageEntities,
        component: Image,
      },
    ]);

    this.state = {
      editorState: EditorState.createEmpty(this.decorator),
      showLinkDialog: false,
      showEmbedDialog: false,
      markup: '',
      doUpdate:true,
    };

    this.focus = () => this.refs.editor.focus();

    // make the api call every 2 seconds
    this.doUpdate = throttle( this.doUpdateData.bind(this), 2000);

    this.getWordCount = (editorState) => {
      const plainText = editorState.getCurrentContent().getPlainText('');
      const regex = /(?:\r\n|\r|\n)/g;  // new line, carriage return, line feed
      const cleanString = plainText.replace(regex, ' ').trim(); // replace above characters w/ space
      const wordArray = cleanString.match(/\S+/g);  // matches words according to whitespace
      return wordArray ? wordArray.length : 0;
    }

    this.onChange = (editorState) => {
      const markup = draftToHtml(
        convertToRaw(editorState.getCurrentContent()),
        {},
        false,
        customEntityTransform,
      );
      let newMarkup = markup;
      try {
        newMarkup = ConvertToHtmlNew(editorState.getCurrentContent());
      } catch(err) {
        //handle error
      }
      this.props.updateWordCount && this.props.updateWordCount(this.getWordCount(editorState));
      this.setState({ editorState, newMarkup });
      this.doUpdate(newMarkup, convertToRaw(editorState.getCurrentContent()), this.getWordCount(editorState));
    };
    this.handleKeyCommand = this._handleKeyCommand.bind(this);
    this.mapKeyToEditorCommand = this._mapKeyToEditorCommand.bind(this);
    this.toggleBlockType = this._toggleBlockType.bind(this);
    this.toggleInlineStyle = this._toggleInlineStyle.bind(this);
  }

  doUpdateData(htmlData, mapObject, count) {
    this.props.updateApi(htmlData, mapObject, count);
  }
  componentDidMount() {
    try {
      if (this.props.objectMap === '{}' && this.props.htmlContent.length > 0) {
        const newMap = convertToDraftMap(this.props.htmlContent);
        const contentState = convertFromRaw(newMap);
        this.setState({
          editorState: EditorState.createWithContent(contentState, this.decorator),
          markup: '',
          doUpdate: false,
        });
      } else {
        const contentState = convertFromRaw(JSON.parse(this.props.objectMap));
        this.setState({
          editorState: EditorState.createWithContent(contentState, this.decorator),
          markup: this.props.htmlContent,
          doUpdate: false,
        });
      }
      setTimeout(() => {
        this.props.updateWordCount && this.props.updateWordCount(this.getWordCount(this.state.editorState));
        this.focus();
      }, 1000);
      
    } catch (e) {
      this.setState({
        editorState: EditorState.createEmpty(this.decorator),
        markup: this.props.htmlContent,
        doUpdate: false,
      });
    }
  }

  componentWillUnmount() {
    this.setState({
      editorState:EditorState.createEmpty(this.decorator),
    })
  }
  _handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  }
  _mapKeyToEditorCommand(e) {
  
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(
        e,
        this.state.editorState,
        4, /* maxDepth */
      );
      if (newEditorState !== this.state.editorState) {
        this.onChange(newEditorState);
      }
      return;
    }
    return getDefaultKeyBinding(e);
  }
  _toggleBlockType(blockType) {
    const currentBlock = this.getCurrentBlock(this.state.editorState);
    const type = currentBlock.getType();
    if (type==='atomic:image') return;
    this.onChange(
      RichUtils.toggleBlockType(
        this.state.editorState,
        blockType,
      ),
    );
  }
  _toggleInlineStyle(inlineStyle) {
    this.onChange(
      RichUtils.toggleInlineStyle(
        this.state.editorState,
        inlineStyle,
      ),
    );
  }

  getEditorState = () => this.state.editorState;

  setEditorState = (editorState) => {
    this.onChange(editorState);
  }
  customRenderer = (contentBlock) => {
    const type = contentBlock.getType();
  switch (type) {
    case 'atomic:image': return {
        component: ImageBlock,
        props: {
          setEditorState: this.setEditorState,
          getEditorState: this.getEditorState,
          getCurrentBlock: this.getCurrentBlock,
          placeholder: 'default place holder',
        },
      };
      default: return null;
    }
  };
  getDefaultBlockData = (blockType, initialData = {}) => {
    return initialData;
  };
  getCurrentBlock = (editorState) => {
    const selectionState = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const block = contentState.getBlockForKey(selectionState.getStartKey());
    return block;
  };
  addNewBlock = (editorState, newType = 'atomic:image', initialData = {}) => {
    
    editorState = this.state.editorState;
    const selectionState = editorState.getSelection();
    if (!selectionState.isCollapsed()) {
      return editorState;
    }
    const contentState = editorState.getCurrentContent();
    const key = selectionState.getStartKey();
    const blockMap = contentState.getBlockMap();
    const currentBlock = this.getCurrentBlock(editorState);
    if (!currentBlock) {
      return editorState;
    }
    if (currentBlock.getLength() === 0) {
      if (currentBlock.getType() === newType) {
        return editorState;
      }
      const newBlock = currentBlock.merge({
        type: newType,
        data: this.getDefaultBlockData(newType, initialData),
      });
      const newContentState = contentState.merge({
        blockMap: blockMap.set(key, newBlock),
        selectionAfter: selectionState,
      });
      return EditorState.push(editorState, newContentState, 'change-block-type');
    }
    return editorState;
  };
  _openLinkembed = () => {
    const { editorState } = this.state;
    let selectionState = editorState.getSelection();
    const start = selectionState.getStartOffset();
    const end = selectionState.getEndOffset();
    if( start === end)
      return;
    this.setState({ showLinkDialog: true });
  }

  _toggleEmbedInput = () => {
    this.setState({ showEmbedDialog: true });
  }


  handleLinkDialog = (link) => {
    const { editorState } = this.state;
    const contentState = editorState.getCurrentContent();
    let selectionState = editorState.getSelection();
    const anchorKey = selectionState.getAnchorKey();
    const start = selectionState.getStartOffset();
    const end = selectionState.getEndOffset();
    const focusKey = selectionState.getFocusKey();
    selectionState = selectionState.merge({
      anchorKey: anchorKey,
      anchorOffset: start,
      focusOffset: end,
      focusKey: focusKey,
      isBackward: false,
    });
    
    //return;
    const contentStateWithEntity = contentState.createEntity(
      'LINK',
      'MUTABLE',
      {url: link.link, newTab: link.newTab, noFollow: link.noFollow },
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const contentStateWithLink = Modifier.applyEntity(
      contentStateWithEntity,
      selectionState,
      entityKey,
    );
    const newEditorState = EditorState.push(editorState, contentStateWithLink);
    this.setState({ editorState: newEditorState, showLinkDialog: false });
    this.onChange(newEditorState);
  }

  resetBlockWithType = (editorState, newType = Block.UNSTYLED, overrides = {}) => {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const key = selectionState.getStartKey();
    const blockMap = contentState.getBlockMap();
    const block = blockMap.get(key);
    const newBlock = block.mergeDeep(overrides, {
      type: newType,
      data: this.getDefaultBlockData(newType),
    });
    const newContentState = contentState.merge({
      blockMap: blockMap.set(key, newBlock),
      selectionAfter: selectionState.merge({
        anchorOffset: 0,
        focusOffset: 0,
      }),
    });
    return EditorState.push(editorState, newContentState, 'change-block-type');
  };

  getCurrentBlock = (editorState) => {
    const selectionState = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const block = contentState.getBlockForKey(selectionState.getStartKey());
    return block;
  };

  addNewBlockAt = (
    editorState,
    pivotBlockKey,
    newBlockType = Block.UNSTYLED,
    initialData = {},
  ) => {
    const content = editorState.getCurrentContent();
    const blockMap = content.getBlockMap();
    const block = blockMap.get(pivotBlockKey);
    if (!block) {
      throw new Error(`The pivot key - ${pivotBlockKey} is not present in blockMap.`);
    }
    const blocksBefore = blockMap.toSeq().takeUntil((v) => (v === block));
    const blocksAfter = blockMap.toSeq().skipUntil((v) => (v === block)).rest();
    const newBlockKey = genKey();

    const newBlock = new ContentBlock({
      key: newBlockKey,
      type: newBlockType,
      text: '',
      characterList: List(),
      depth: 0,
      data: Map(this.getDefaultBlockData(newBlockType, initialData)),
    });

    const newBlockMap = blocksBefore.concat(
      [[pivotBlockKey, block], [newBlockKey, newBlock]],
      blocksAfter,
    ).toOrderedMap();

    const selection = editorState.getSelection();

    const newContent = content.merge({
      blockMap: newBlockMap,
      selectionBefore: selection,
      selectionAfter: selection.merge({
        anchorKey: newBlockKey,
        anchorOffset: 0,
        focusKey: newBlockKey,
        focusOffset: 0,
        isBackward: false,
      }),
    });
    return EditorState.push(editorState, newContent, 'split-block');
  };
  
  handleReturn = (e) => {
  
    const { editorState } = this.state;
    if (isSoftNewlineEvent(e)) {
      this.onChange(RichUtils.insertSoftNewline(editorState));
      return "handled";
    }
    if (!e.altKey && !e.metaKey && !e.ctrlKey) {
      const currentBlock = this.getCurrentBlock(editorState);
      const blockType = currentBlock.getType();

      if (blockType.indexOf(Block.ATOMIC) === 0) {
        this.onChange(this.addNewBlockAt(editorState, currentBlock.getKey()));
        return "handled";
      }

      const selection = editorState.getSelection();

      if (selection.isCollapsed() && currentBlock.getLength() === selection.getStartOffset()) {
        return "not_handled";
      }
      return "not_handled";
    }
    return "not_handled";
  }

  _socialEmbed = (src) => {
    const { editorState } = this.state;
    const entityKey = editorState
      .getCurrentContent()
      .createEntity('EMBED', 'IMMUTABLE', { src })
      .getLastCreatedEntityKey();
    const newEditorState = AtomicBlockUtils.insertAtomicBlock(
      editorState,
      entityKey,
      ' ',
    );
    this.setState({ editorState: newEditorState, showEmbedDialog: false });
  }
  _mediaUpload = (target, type) => {
    
    const file = target.files[0];
    this.props.handleMediaUpload(file, (err, data) => {
      if (err) {
        this.setState({ showEmbedDialog: false });
      } else {
        const src = data;
        this.setEditorState(this.addNewBlock(
          this.getEditorState(),
          'atomic:image', {
          src,
        },
        ));
        this.setState({ showEmbedDialog: false });
      }
    });
    
  }

  handleEmbedDialog = (data) => {
    if (!data.link) return;
    this._socialEmbed(data.link);
  }

  _handlePastedText = (text, html, editorState, onChange) => {
    return false;
    // try {
    //   let newHtml = `<div id="wrapped_div">${html}</div>`;
    //   const dom = new JSDOM(newHtml);
    //   const dd = dom.window.document.getElementById("wrapped_div").childNodes;
    //   const mainDiv = document.createElement('DIV');
    //   mainDiv.setAttribute('id', 'paste_div');
    //   mainDiv.style.display = 'none';
    //   document.body.appendChild(mainDiv);
    //   const arr = ['p','h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'ol', 'ul','b','strong', 'div', 'span', 'i', 'a'];
    //   for(var i=0; i<dd.length; i++) {
    //     let name = dd[i].nodeName;
    //     if(arr.indexOf(name.toLowerCase()) === -1) {
    //       let node = document.createElement('P');
    //       node.innerHTML = '';
    //       document.getElementById('paste_div').appendChild(node);
    //     } else {
    //       if(name === 'SPAN') name = 'P';
    //       let node = document.createElement(name);
    //       node.innerHTML = dd[i].innerHTML;
    //       document.getElementById('paste_div').appendChild(node);
    //     }
    //   }
    //   newHtml =  document.getElementById('paste_div').innerHTML.toString();
    //   if (newHtml) {
    //     newHtml = newHtml + `<div></div>`;
    //     const contentBlock = htmlToDraft(newHtml);
    //     const cbnew = contentBlock.contentBlocks.filter((item) => {
    //       return (item.type !== 'atomic')
    //     })
    //     let contentState = editorState.getCurrentContent();
    //     contentBlock.entityMap.forEach((value, key) => {
    //       if(value.type !== 'IMAGE') {
    //         contentState = contentState.mergeEntityData(key, value);
    //       }
    //     });
    //     contentState = Modifier.replaceWithFragment(
    //       contentState,
    //       editorState.getSelection(),
    //       new List(cbnew),
    //     );
    //     this.setState({editorState: EditorState.push(editorState, contentState, "insert-characters")});
    //     document.body.removeChild(mainDiv);
    //     return false;
    //   }
    //   return false;
    // } catch (err) {
    //   console.log("err", err);
    //   //handle error
    //   return false;
    // }
  }
  render() {
    const {editorState} = this.state;
    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    let className = 'RichEditor-editor';
    var contentState = editorState.getCurrentContent();
    const currentBlock = this.getCurrentBlock(editorState);
    const currentBlockLength = currentBlock.getLength();
    const blockType = currentBlock.getType();
    let onlyInline = false;
    if (blockType==="atomic:image") {
      onlyInline = true;
    }
    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== 'unstyled') {
        className += ' RichEditor-hidePlaceholder';
      }
    }
    return (
      <div className="RichEditor-root">
        


         <div style={{
         position:'sticky',zIndex:'999',
         top:`${this.props.isMobile ?'70px':'75px'}`,
         borderBottom:'1px solid #ddd',background:'white'}}>

        <Box display="flex" alignItems="center">
          {/* <Box mb={4} mt={4}>Last saved at {getLastUpdate(lastUpdate)}</Box> */}
         <Box component="span" fontWeight="bold" >{this.props.bodytitle ?`${this.props.bodytitle} ${this.props.descriptionCompulsary ?'*':''}`:'Body*'}</Box>
          {/* <Box mb={4} mt={4} >({this.props.wordCount} words)</Box> */}
        </Box>
        <div style={{display:'flex',
         flexWrap:'wrap'}}>
        {!onlyInline && <BlockStyleControls
          editorState={editorState}
          onToggle={this.toggleBlockType}
        />}
        <InlineStyleControls
          editorState={editorState}
          onToggle={this.toggleInlineStyle}
        />
       
        <span className="RichEditor-controls" onClick={this._openLinkembed}>
          <StyleButton
            key={'link'}
            label={"Link"}
            icon="link"
            onToggle={() => { }}
            title="Insert Link"
          />
        </span>
        {!onlyInline && !currentBlockLength && this.props.bodytitle!=='Description' && <span className="RichEditor-controls" onClick={this._toggleEmbedInput}>
          <StyleButton
            key={'embed'}
            label={"Embed"}
            icon="embed"
            title="Embed"
            onToggle={() => { }}
          />
        </span>}
        {!onlyInline && !currentBlockLength && this.props.bodytitle!=='Description' && <span className="RichEditor-controls">
          <WithUplodStyleButton
            key={'media'}
            label={"Media"}
            icon="media"
            onToggle={() => { }}
            onChange={this._mediaUpload}
            title="Insert Media"
          />
        </span>}
        </div>
        </div>
        <div className={className} onClick={this.focus}>
          <Editor
            blockStyleFn={getBlockStyle}
            blockRendererFn={this.customRenderer}
            handlePastedText={this._handlePastedText}
            handleDrop={() =>  true}
            customStyleMap={styleMap}
            editorState={editorState}
            spellCheck={false}
            onFocus={() =>  {window.scrollTo(0,document.body.scrollHeight);}}
            handleKeyCommand={this.handleKeyCommand}
            keyBindingFn={this.mapKeyToEditorCommand}
            handleReturn={this.handleReturn}
            onChange={this.onChange}
            placeholder={this.props.DraftPlaceholder || "Write your article."}
            ref="editor"
          />
        </div>
        <LinkDialog
          showDialog={this.state.showLinkDialog}
          closeDialog={()=>{this.setState({ showLinkDialog: false })}}
          handleDialog={this.handleLinkDialog}
        />
        <EmbedDialog
          showDialog={this.state.showEmbedDialog}
          closeDialog={()=>{this.setState({ showEmbedDialog: false })}}
          handleDialog={this.handleEmbedDialog}
        />
      </div>
    );
  }
}
// Custom overrides for "code" style.
const styleMap = {
  CODE: {

  },
};
function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote': return 'RichEditor-blockquote';
    default: return null;
  }
}

const BLOCK_TYPES = [
  {label: 'H2', style: 'header-two', icon:"h2", title: 'H2'},
  {label: 'H3', style: 'header-three',icon:"h3", title: 'H3'},
  {label: 'BQ', style: 'blockquote', icon:"bq", title: 'Blockquote' },
  {label: 'UL', style: 'unordered-list-item', icon:"ul", title: 'Unordered List'},
  {label: 'OL', style: 'ordered-list-item', icon:"ol", title: 'Ordered List'},
];
const BlockStyleControls = (props) => {
  const {editorState} = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  return (
    <span className="RichEditor-controls">
      {BLOCK_TYPES.map((type) =>
        <StyleButton
          key={type.label}
          icon={type.icon}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
          title={type.title}
        />,
      )}
    </span>
  );
};
var INLINE_STYLES = [
  {label: 'B', style: 'BOLD', icon:"bold", title: 'Bold'},
  {label: 'I', style: 'ITALIC', icon:"italics", title: 'Italic'},
  {label: 'U', style: 'UNDERLINE',icon:"underline", title: 'Underline'},
];
const InlineStyleControls = (props) => {
  const currentStyle = props.editorState.getCurrentInlineStyle();
  return (
    <span className="RichEditor-controls">
      {INLINE_STYLES.map((type) =>
        <StyleButton
          key={type.label}
          icon={type.icon}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
          title={type.title}
        />,
      )}
    </span>
  );
};

const Image = () => {
  return (<p></p>)
}

const Link = (props) => {
  let { url, newTab, noFollow, innerText, title } = props.contentState.getEntity(props.entityKey).getData();
  const text = props.children && props.children[0].props && props.children[0].props.text;
  const titleValue = title ? title: text===" " ? innerText : props.children;
  return (
    <a href={url} style={styles.link} target={newTab ? '_blank' :'_self'} rel={noFollow ? 'nofollow' : 'dofollow'}>
      {titleValue}
    </a>
  );
};
const Embed = (props) => {
  const { src } = props.contentState.getEntity(props.entityKey).getData();
  return (
      <blockquote className="embedly-card blockquote-container" style={{width:'95%', height:"100%"}}>
          <a  className="embedly-card" href={src} >{src}</a>
      </blockquote>
  );
};
const Media = (props) => {
  const { src, caption, isLink } = props.contentState.getEntity(props.entityKey).getData();
  const cap = caption !== 'undefined' ? caption : '';
  return (
      <figure className="image">
        <div style={{width:'100%', textAlign:"center"}}>
          <img alt="img" id="figure-image" src={src} style={{ width: "40%", height: "55%", objectFit: "contain", textAlign:"center" }} />
        </div>
        <div style={{ width: '100%' }}>
        {cap && 
        <figcaption style={{ background: '#d3d3d3', minHeight: '25px', width: '40%', paddingLeft:'30%' }} editable="true">
          {isLink ? <a href={caption}>{caption}</a> : cap}
        </figcaption>}
        </div>
      </figure>
  );
};

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'LINK'
      );
    },
    callback,
  );
}
function findEmbedEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'EMBED'
      );
    },
    callback,
  );
}
function mediaUploadEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'MEDIA_UPLOAD'
      );
    },
    callback,
  );
}
function findImageEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'IMAGE'
      );
    },
    callback,
  );
}
const customEntityTransform = (entity, text) => {

  switch (entity.type) {
    case 'LINK': {
      const { innerText } = entity.data;
      return `<a 
      href=${entity.data.url} 
      target=${entity.data.newTab ? '_blank' : '_self'} 
      rel=${entity.data.noFollow ? 'nofollow' : 'dofollow'}>${text || innerText}</a>`;
    }
    case 'EMBED': {
      return `<blockquote class="embedly-card blockquote-container"><a class="embedly-card" href="${entity.data.src}" >${entity.data.src}</a></blockquote>`;
    }
    case 'MEDIA_UPLOAD': {
      const cap = entity.data.isLink ? `<a href=${entity.data.caption}>${entity.data.caption}</a>`: entity.data.caption;
      return `<figure><img id="figure-image" src="${entity.data.src}" /><figcaption>${cap===undefined ? '' : cap}</figcaption></figure>`
    }
    case 'IMAGE': {
      return `<p></p>`
    }
    default: return
  }
};

const styles = {
  link: {
    color: '#3b5998',
    textDecoration: 'underline',
  },
}


/* ---------------------------------------------------------- -----------------------------------------*/

class LinkDialog extends React.Component {
  state = {
    link: '',
    title: '',
    newTab: true,
    noFollow: true,
  }

  handleChange = (event, field) => {
    let value = (event.target.value === 'false') ? true : ((event.target.value === 'true') ? false : event.target.value);
    this.setState({ [field]: value });
  }

  submitDialog = () => {
    this.props.handleDialog(this.state);
    this.setState({
      link: '',
      title: '',
      newTab: true,
      noFollow: true,
    });
  }

  render() {
    const { showDialog, closeDialog } = this.props;
    return (
      <>
        {
          (showDialog) &&
            <div style={DialogStyles.dialogOuter}>
              <div style={DialogStyles.dialog}>
                <div style={DialogStyles.dialogTitle}>
                  Insert Link
                  <div onClick={closeDialog} style={DialogStyles.closeIcon}>
                    <img  alt="img" src="https://image.flaticon.com/icons/svg/59/59836.svg" width="13px" height="13px"/>
                  </div>
                </div>
                <div style={DialogStyles.dialogBody}>
                  <form>
                    <div style={DialogStyles.fieldContainer}>
                      <div style={DialogStyles.fieldTitle}>
                        Link Url
                      </div>
                      <div style={DialogStyles.fieldInput}>
                        <input style={DialogStyles.textInput} 
                        type="text" 
                        value={this.state.link} 
                        required 
                        onChange={(event)=>this.handleChange(event, 'link')} placeholder="Enter the link url" />
                      </div>
                    </div>
                    <div style={DialogStyles.fieldContainer}>
                      <div style={DialogStyles.radioInput}>
                        <input 
                        type="checkbox" 
                        name="newTab" 
                        value={this.state.newTab} 
                        onChange={(event)=>this.handleChange(event, 'newTab')} 
                        checked={this.state.newTab} />
                      </div>
                      <div>
                        Opens in New Tab
                      </div>
                      <div style={DialogStyles.radioInput}>
                        <input 
                        type="checkbox" 
                        name="noFollow" 
                        value={this.state.noFollow} 
                        onChange={(event)=>this.handleChange(event, 'noFollow')} 
                        checked={this.state.noFollow} />
                      </div>
                      <div>
                        No Follow
                      </div>
                    </div>
                  </form>
                </div>
                <div style={DialogStyles.dialogActions}>
                  <button onClick={this.submitDialog} style={DialogStyles.actionButton}>
                    Confirm
                  </button>
                  <button style={{...DialogStyles.actionButton, ...DialogStyles.actionButtonSecondary}}
                    onClick={closeDialog}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
        }
      </>
    )
  }
}

class EmbedDialog extends React.Component {
  state = {
    link: '',
  }

  handleChange = (event, field) => {
    this.setState({ [field]: event.target.value });
  }

  submitDialog = () => {
    this.props.handleDialog(this.state);
    this.setState({
      link: '',
    });
  }

  render() {
    const { showDialog, closeDialog } = this.props;
    return (
      <>
        {
          (showDialog) &&
            <div style={DialogStyles.dialogOuter}>
              <div style={DialogStyles.dialog}>
                <div style={DialogStyles.dialogTitle}>
                  Insert Embed
                  <div onClick={closeDialog} style={DialogStyles.closeIcon}>
                    <img alt="img" src="https://image.flaticon.com/icons/svg/59/59836.svg" width="13px" height="13px"/>
                  </div>
                </div>
                <div style={DialogStyles.dialogBody}>
                  <form>
                    <div style={DialogStyles.fieldContainer}>
                      <div style={DialogStyles.fieldTitle}>
                        Embed Link Url
                      </div>
                      <div style={DialogStyles.fieldInput}>
                        <input 
                        style={DialogStyles.textInput} 
                        type="text" 
                        value={this.state.link} 
                        required 
                        onChange={(event)=>this.handleChange(event, 'link')} 
                        placeholder="Enter the embed url" />
                      </div>
                    </div>
                  </form>
                </div>
                <div style={DialogStyles.dialogActions}>
                  <button onClick={this.submitDialog} style={DialogStyles.actionButton}>
                    Confirm
                  </button>
                  <button style={{...DialogStyles.actionButton, ...DialogStyles.actionButtonSecondary}}
                    onClick={closeDialog}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
        }
      </>
    )
  }
}

class ImageBlock extends React.Component {

  focusBlock = () => {
    const { block, blockProps } = this.props;
    const { getEditorState, setEditorState, getCurrentBlock} = blockProps;
    const key = block.getKey();
    const editorState = getEditorState();
    const currentblock = getCurrentBlock(editorState);
    if (currentblock.getKey() === key) {
      return;
    }
    const newSelection = new SelectionState({
      anchorKey: key,
      focusKey: key,
      anchorOffset: 0,
      focusOffset: 0,
      isBackward:false,
    });
    setEditorState(EditorState.forceSelection(editorState, newSelection));
  };


  render() {
    const { blockProps, block } = this.props;
    const data = block.getData();
    const src = data.get('src');
    const showPlaceholder = block.getLength() === 0 && blockProps.placeholder;
    if (src !== null) {
      const extraProps = {};

      if (showPlaceholder) {
        extraProps['data-placeholder'] = blockProps.placeholder;
      }

      return (
        <div>
          <div onClick={this.focusBlock}>
            <img alt="img" role="presentation" src={src} style={{ width: "40%", height: "55%", objectFit: "contain" }} />
          </div>
          <figcaption {...extraProps} style={{ background: '#d3d3d3', minHeight: '25px', width: '40%' }}>
            <EditorBlock placeholder="Enter caption" {...this.props}/>
          </figcaption>
        </div>
      );
    }
    return null;
  }
}
const DialogStyles = {
  dialogOuter: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '1232',
  },
  dialog: {
    flex: '0 0 40%',
    minWidth: '300px',
    zIndex: '1233',
    backgroundColor: 'white',
    padding: '20px 35px',
    border: 'solid 2px #cecece',
    position: 'relative',
  },
  dialogTitle: {
    fontSize: '1.5em',
    fontWeight: 'bold',
  },
  closeIcon: {
    position: 'absolute',
    right: '20px',
    top: '15px',
    width: '13px',
    height: '13px',
    cursor: 'pointer',
  },
  dialogBody: {
    padding: '15px 0 0 0',
  },
  dialogActions: {
    padding: '20px 0 0 0',
    display: 'flex',
    justifyContent:'flex-end',
  },
  actionButton: {
    marginLeft: '10px',
    padding: '9px 10px',
    textTransform: 'uppercase',
    backgroundColor: '#2b2d42',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
  },
  actionButtonSecondary: {
    color: 'inherit',
    backgroundColor: '#white',
  },
  fieldContainer: {
    display: 'flex',
    padding: '10px 0',
  },
  fieldTitle: {
    flex: '0 0 30%',
    marginTop: '7px',
  },
  fieldInput: {
    flex: '0 0 70%',
    display: 'flex',
  },
  textInput: {
    border: 'solid 1px #cecece',
    padding: '5px 10px',
    width: '100%',
  },
  radioInput: {
    padding: '0 5px 0 15px',
  },
}

/****------------------------------------------------------------------------------------------------******/
/****------------------------------------------------------------------------------------------------******/
/****------------------------------------------------------------------------------------------------******/
/****------------------------------------------------------------------------------------------------******/
// functions for converting old article to new artices format

function convertToDraftMap(html) {

  html = html.replace(/\/([a-zA-Z]+)>((.|\n)*?)</g, (a, c, b) => /^[ \n]*$/.test(b) ? `/${c}><` : `/${c}><span>${b}</span><`);
  html = html.replace(/^((.|\n)*?)</, '<p>$1</p><');
  const lastTag = html.lastIndexOf(">");
  const htmlLen = html.length - 1;
  if (lastTag !== htmlLen) {
    html = `${html.slice(0, lastTag + 1)}<span>${html.slice(lastTag + 1, htmlLen + 1)}</span>`;
  }
  const mainDiv = document.createElement('DIV');
  mainDiv.setAttribute('id', 'test_id');
  mainDiv.style.display = 'none';
  document.body.appendChild(mainDiv);
  if (document.getElementById('test_id')) document.getElementById('test_id').innerHTML = '';
  var parser = new DOMParser();
  var doc = parser.parseFromString(html, "text/html");
  const children = doc.getElementsByTagName("body")[0] && doc.getElementsByTagName("body")[0].children;
  for (var i = 0; i < children.length; i++) {
    try {
      // simple conversion
      if (children[i].nodeName==='BLOCKQUOTE' || children[i].nodeName==='H2') {
        document.getElementById('test_id').appendChild(children[i].cloneNode(true));
        continue;
      }
      if (children[i].nodeName==='SPAN') {
        let node = document.createElement("p");
        node.innerHTML = children[i].innerText;
        document.getElementById('test_id').appendChild(node);
        continue;
      }
      if (children[i].nodeName==='A') {
        const subchild = children[i].children;
        if (subchild[0] && subchild[0].nodeName==='FIGURE' && subchild[0].className==='image') {
          var node = document.createElement("div");
          const subchild = children[i].children;
          node.setAttribute('class', 'media-upload');
          node.setAttribute('src', subchild[0].children[0].src);
          node.setAttribute('caption', children[i].href);
          node.setAttribute('isLink', "true");
          document.getElementById('test_id').appendChild(node);
        } else {
          document.getElementById('test_id').appendChild(children[i].cloneNode(true));
        }
        continue;
      }
      if (children[i].nodeName==='P') {
        if (children[i].children && children[i].children[0] && children[i].children[0].nodeName==='A') {
          node = document.createElement("div");
          node.setAttribute('class', 'links');
          node.setAttribute('href', children[i].children[0].href);
          node.innerText = children[i].children[0].innerText;
          document.getElementById('test_id').appendChild(node);
        } else {
          document.getElementById('test_id').appendChild(children[i].cloneNode(true));
        }
        continue;
      }
      //for embeds
      if (children[i].nodeName==='DIV' && children[i].className==='blockquote-container') {
        const subchild = children[i].children;
        if (subchild[1] && subchild[1].nodeName && (subchild[1].nodeName==='IFRAME' || subchild[1].nodeName==='BLOCKQUOTE')) {
          node = document.createElement("div");
          node.setAttribute('class', 'embed');
          if (subchild[1] && subchild[1].nodeName && subchild[1].nodeName==='IFRAME') {
            node.setAttribute('src', subchild[1].src);
          }
          if (subchild[1] && subchild[1].nodeName && subchild[1].nodeName==='BLOCKQUOTE') {
            if (subchild[1] && subchild[1].children[1] && subchild[1].children[1].href) {
              node.setAttribute('src', subchild[1].children[1].href);
            }
          }
          document.getElementById('test_id').appendChild(node);
        } else {
          document.getElementById('test_id').appendChild(children[i].cloneNode(true));
        }
        continue;
      }
      //for image uploads
      if (children[i].nodeName==='FIGURE') {
        node = document.createElement("div");
        const subchild = children[i].children;
        if (subchild && subchild[0].nodeName==='IMG') {
          node.setAttribute('class', 'media-upload');
          node.setAttribute('src', subchild[0].src);
          node.setAttribute('caption', subchild[1] && subchild[1].innerText);
          document.getElementById('test_id').appendChild(node);
        }
        continue;
      }
      document.getElementById('test_id').appendChild(children[i].cloneNode(true));
    } catch (err) {
      continue;
    }
  }
  const newHtml = document.getElementById('test_id') && document.getElementById('test_id').innerHTML;
  document.body.removeChild(document.getElementById('test_id'));
  if (newHtml && newHtml.length > 0) {
    const blocksFromHtml = htmlToDraft(newHtml, (nodeName, node) => {
      if (node.className==='media-upload') {
        return {
          type: 'MEDIA_UPLOAD',
          mutability: 'MUTABLE',
          data: {
            src: node.getAttribute('src'),
            caption: node.getAttribute('caption'),
            isLink: node.getAttribute('isLink'),
          },
        };
      }
      if (node.className==='links') {
        return {
          type: 'LINK',
          mutability: 'MUTABLE',
          data: {
            url: node.getAttribute('href'),
            innerText: node.innerText,
          },
        };
      }
      if (node.className==='embed') {
        return {
          type: 'EMBED',
          mutability: 'MUTABLE',
          data: {
            src: node.getAttribute('src'),
          },
        };
      }
    });
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);
    return convertToRaw(editorState.getCurrentContent());
  } 
}


function throttle (func, limit) {
  let lastFunc
  let lastRan
  return function() {
    const context = this
    const args = arguments
    if (!lastRan) {
      func.apply(context, args)
      lastRan = Date.now()
    } else {
      clearTimeout(lastFunc)
      lastFunc = setTimeout(function() {
        if ((Date.now() - lastRan) >= limit) {
          func.apply(context, args)
          lastRan = Date.now()
        }
      }, limit - (Date.now() - lastRan))
    }
  }
}