import React, { useState } from 'react';
import { useSelector } from "react-redux";
import Input from "../../../commons/muiCustomComponents/Input";
import Box from "../../../commons/muiCustomComponents/Box";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Grid from "commons/muiCustomComponents/Grid";
import Button from "commons/muiCustomComponents/Button";
import { LinearLoader,PageBlockLoader, CircularLoader } from 'commons/commonWrapper/Loaders';
import { LogglyTracker } from 'loggly-jslogger';

const logger = new LogglyTracker();
logger.push({ 'logglyKey': '142d21f5-10d3-47a9-aa5a-f83e0a3abed7', 'sendConsoleErrors' : true, 'tag' : 'ardbeg,advertisement,bankinforow' });

/**
 * Handle Error part 
 * Transfer only successpart to parent
 */

function BankInfoRow(props, ref) {
  
  const {
    nameRow, setNameRow,
    accountNumberRow, 
    setAccountNumberRow,
    confirmAccountNumberRow, 
    setConfirmAccountNumberRow,
    accountRow, setAccountRow,
    routingNumber ,setRoutingNumber,
    swiftCode,setSwiftCode,
    updateBankDetails,
    setActiveStepItem,
    setCompletedItem,
    forthStepComplete,
    matchAccountNumberRow, 
    setMatchAccountNumberRow,
  } = props;
  const [isLoading ,setLoading] = useState(false);
  const [errAcName , setErrAcName]  = useState(false);
  const [errAcNumber ,setErrAcNumber] = useState(false);
  const [errConfirmAcNumber,setErrConfirmAcNUmber] = useState(false);
  const {landing: {userProfile}} = useSelector(state => state);

  const handleName = (e) => {
    setNameRow(e.currentTarget.value)
  }
  const handleIFSC = (e) => {
    setSwiftCode(e.currentTarget.value)
  }
  const handleAccountNumber = (e) => {
    setAccountNumberRow(e.currentTarget.value)
  }
  const handleConfirmAccountNumber = (e) => {
    setConfirmAccountNumberRow(e.currentTarget.value)
    if (e.currentTarget.value === accountNumberRow) setMatchAccountNumberRow(true)
    else setMatchAccountNumberRow(false)
  }
  const handleAccount = (e) => {
    setAccountRow(e.target.value)
  }
  const saveBankInfo = async ()=>{
    if (!nameRow) return setErrAcName(true)
    if (!accountNumberRow) return setErrAcNumber(true)
    if (!confirmAccountNumberRow) return setErrConfirmAcNUmber(true)
    if (!matchAccountNumberRow) return setErrConfirmAcNUmber(true)
    
    setErrAcName(false)
    setErrAcNumber(false)
    setErrConfirmAcNUmber(false)
  
    try{
      setLoading(true)
      await updateBankDetails({
        account_holder_name :nameRow,
        account_number:accountNumberRow,
        account_type :accountRow,
        routing_code:routingNumber,
      });
      if (!forthStepComplete){
        setActiveStepItem && setActiveStepItem(4);
        setCompletedItem && setCompletedItem({0:true,1:true,2:true,3:true});
      }
      
      setLoading(false)

    }
    catch(err){
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      setLoading(false)
    }
    
  }
  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Box m={2}>
          <Input
            fullWidth
            color="secondary"
            onChange={handleName}
            value={nameRow}
            error={errAcName}
            label="Account Owner Name *">
          </Input>
          </Box>
        </Grid >
        <Grid item xs={12} md={6}>
        <Box m={2}>
          <Input
            fullWidth
            color="secondary"
            onChange={handleIFSC}
            value={swiftCode}
            label="ABA/Sort Code/Swift Code"
          >
          </Input>
          </Box>
        </Grid>
      </Grid >


      <Grid container>
        <Grid item xs={12} md={6}>
        <Box m={2}>
          <Input
            fullWidth
            color="secondary"
            value={accountNumberRow}
            onChange={handleAccountNumber}
            error={errAcNumber}
            label="Account Number *">
          </Input>
          </Box>
        </Grid >
        <Grid item xs={12} md={6}>
        <Box m={2}>
          <Input
            fullWidth
            color="secondary"
            error={errConfirmAcNumber}
            value={confirmAccountNumberRow}
            onChange={handleConfirmAccountNumber}
            label="Confirm Account Number *"
            InputProps={matchAccountNumberRow && {
              endAdornment: <InputAdornment position="end"><CheckCircleIcon /></InputAdornment>,
            }}
          >
          </Input>
          </Box>
        </Grid >
      </Grid >
      <Box m={2}>
          <Input
            fullWidth
            color="secondary"
            onChange={(e)=>setRoutingNumber(e.currentTarget.value)}
            value={routingNumber}
            label="Routing Number"
          >
          </Input>
        </Box>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Box m={2}>
            <FormControl fullWidth>
              <InputLabel > Account Type*</InputLabel>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={accountRow}  
                onChange={handleAccount}
              >
                <MenuItem value={'saving'}>Savings Account</MenuItem>
                <MenuItem value={'checking'}>Checking Account</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
      <Box display="flex">
        <Button variant="contained" onClick={saveBankInfo}>Save</Button>
        {isLoading && <CircularLoader height="auto" size={20} ml={2}/>}
      </Box>
    </>
  )
}
export default BankInfoRow;
