import React, {useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { doAllAuthDataFetch, fetchCategories } from "./landing-action";
import {getInsightsRevenueActionForHeader} from "../insights/insights-action";
import { useHistory, useLocation } from "react-router-dom";
import { CircularLoader } from "commons/commonWrapper/Loaders";
import moment from 'moment';
import { LogglyTracker } from 'loggly-jslogger';

const logger = new LogglyTracker();
logger.push({ 'logglyKey': '142d21f5-10d3-47a9-aa5a-f83e0a3abed7', 'sendConsoleErrors' : true, 'tag' : 'ardbeg,landing,landing-home' });

function LandingHome (props) {

  const history = useHistory();
  const location = useLocation();
  const { state } = location;
  let pathname = (state && state.from && state.from !== "/" && !state.from.pathname.includes('/dash/create'))  ? state.from.pathname : '/dash/create';
  //let pathname = (state && state.from && state.from !== "/")  ? state.from.pathname : '/dash/create';
  const dispatch = useDispatch();
  const {landing: {userProfile}} = useSelector(state => state);

  const getFormattedDate = (date) => {
    return moment(date).format('YYYY-MM-DD');
  }
  
  useEffect(() => {
    async function doAuth() {
      const search = state && state.from && state.from.search ? state.from.search : '';
      try {
        let { hasDomain,notQuickCms, userPermissions, featurePermissions } = await dispatch(doAllAuthDataFetch());
       if(notQuickCms) pathname = '/dash/old-dashboard';
        if(!hasDomain) pathname = "/onboard";
        pathname = pathname + search;
        if(!notQuickCms && hasDomain){
          dispatch(fetchCategories());
          if(userPermissions.includes("EARN:READ_INSIGHTS_REVENUE") && featurePermissions.includes("STATISTICS")){            
            // dispatch(getInsightsRevenueActionForHeader(getFormattedDate(moment(new Date()).subtract(7, 'days').toDate()),
            // getFormattedDate(new Date())));
          }          
        }
        history.push(pathname);
      } catch (err) {
        const data = {
          'error': err,
          'email': (userProfile||{}).email || '',
          'url': (window.location||{}).href || '',
          'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
          'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
        };
        logger.push({
            'type': 'error',
            'data': data,
          });
        history.push(`/login/${search}`);
      }
    }
    doAuth();
  },[]);
  return (
    <CircularLoader message="Getting everything ready..."/>
  )
}

export default LandingHome;