import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import * as axios from "axios";
import Box from "../../commons/muiCustomComponents/Box";
import Stepper from '../../commons/muiCustomComponents/Stepper';
import Step from '../../commons/muiCustomComponents/Step';
import StepLabel from '../../commons/muiCustomComponents/StepLabel';
import StepContent from '../../commons/muiCustomComponents/StepContent';
import Button from "../../commons/muiCustomComponents/Button";
import { CircularLoader, PageBlockLoader,LinearLoader } from '../../commons/commonWrapper/Loaders';
import { 
  checkWebmasterVerified, 
  createWebmasterToken, 
  updateWebmasterMapStatus,
} from "./settings-action";
import { fetchDomainList} from "../header/header-action";
import { useDispatch, useSelector } from "react-redux";
import MobileProvider from "commons/context/MobileProvider";
import Grid from "../../commons/muiCustomComponents/Grid";
import setAppMessage from "../../app-component/app-component-action";
import Paper from "commons/muiCustomComponents/Paper";
import Typography from "commons/muiCustomComponents/Typography";
import GoogleIcon from "assets/icons/google-16.svg";
const useStyles = makeStyles(theme => ({
  stepperRoot: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  helperText: {
    fontSize: theme.spacing(3),
    //marginLeft: theme.spacing(8),
  },
  labelText: {
    fontSize: theme.spacing(4),
  },
  signupButton: {
    padding: theme.spacing(1),
    marginTop:theme.spacing(6),
  },
}));

const SCOPE = 'https://www.googleapis.com/auth/siteverification https://www.googleapis.com/auth/webmasters';


export default function WebmasterCustomDomain({ appbarheight }) {
  const classes = useStyles();
  const isMobile = useContext(MobileProvider);
  const dispatch = useDispatch();
  const [isFetching, setfetching] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [isMapped, setIsMapped] = useState(false);
  const [fqdn, setFqdn] = useState(null);
  const {landing:{activeDomain}, header:{domainList}} = useSelector(state => state);
  const steps = [
    'Sign in with your Google Account',
    'Establish Site Ownership on Google',
    'Submit Sitemap To Google',
    `Your website https://${fqdn} is SEO ready!`,
    ];
  useEffect(() => {
    try {
      let d = domainList[activeDomain] && domainList[activeDomain][0].fqdn;
      setFqdn(d);
    } catch (err) {
      //handle error
    }
  },[activeDomain, domainList,fqdn]);


  useEffect(() => {
    let isMounted = true;
    if(isMobile) {
      dispatch(fetchDomainList());
    }
    const getDetails = async () => {
      try {
        setfetching(true)
        let {result} = await dispatch(checkWebmasterVerified());
        setIsMapped(result.isMapped);
        // to mark the process complete need to increase the active step by 1 when status is 3 else set it to 0
        setActiveStep(result.status === 3 ? 4: 0);
        isMounted && setfetching(false);
      } catch (err) {
        isMounted && setfetching(false)
      }
    }
    getDetails();
  }, []);


  const doWebmasterConnect = async (dd) => {
    try {
        handleNext();
        let res = await getToken(fqdn, dd);
        await dispatch(createWebmasterToken(res.token, fqdn));
        await dispatch(updateWebmasterMapStatus(1));
        await verifyDomain(fqdn, dd);
        await dispatch(updateWebmasterMapStatus(2));
        handleNext();
        await addSiteToUserAccount(fqdn, dd);
        await updateDomainSiteMap(fqdn, dd);
        await dispatch(updateWebmasterMapStatus(3));
        handleNext();
        handleNext();
        
    } catch (err) {
      setActiveStep(0);
      dispatch(setAppMessage({ message: err.message || 'Unable to connect to webmaster', header: 'Error', type: 'alert' }));
      // handle error
    }
  }

  const handleAuthClick = async() => {
    window.gapi.load('auth2', function(){
      window.gapi.auth2.authorize({
        "client_id": '401728937069-tgsh046ar3jhdar0d7tpdhetfk8r2ug1.apps.googleusercontent.com',
        "scope": SCOPE,
        "prompt":'consent',
        "response_type": 'id_token permission',
      }, function(response) {
        if (response.error) {
          setActiveStep(0);
          dispatch(setAppMessage({ message: response.error.message || 'Unable to connect to webmaster', header: 'Error', type: 'alert' }));  
          return;
        }
        doWebmasterConnect(response);
      });
    });
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return ``;
      case 1:
        return 'We will add a verification token to your site and establish ownership';
      case 2:
        return `We will submit your website's sitemap to google`;
      case 3:
        return 'Your site is now ready to appear on google';
      default:
        return 'Unknown step';
    }
  }

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }
  if(!isMapped && !isFetching) {
    return (
      <Grid item xs={12} md={9} xl={10}>
          <Box mt={4} mb={4} >
            <Paper>
              <p>Your website <strong>{`https://${fqdn}`}</strong> is SEO ready!</p>
            </Paper>
          </Box>
        </Grid>
    )   
  }
  return (
    <>
    <Grid container>
    {!isMobile && isFetching && <PageBlockLoader/>}
    {isMobile && isFetching &&<LinearLoader top={appbarheight} />}
    {!isFetching && <>
      <Grid item xs={12} md={9} xl={10}>
          <Box mt={4} mb={4} >
            <Paper>
              <Box component="p" mt={0}>Sign in with your Google account and follow on screen instructions.
                 You will now Authorize Pubninja to establish your website ownership and submit to Google.</Box>
              <Stepper activeStep={activeStep} className={classes.stepperRoot} orientation="vertical">
                  {steps.map((label, index) => (
                    <Step key={label}>
                      <StepLabel><Typography className={classes.labelText}>{label}</Typography></StepLabel>
                      <StepContent>
                        <Typography className={classes.helperText}>{getStepContent(index)}</Typography>
                        <div className={classes.actionsContainer}>
                         { !isFetching && (activeStep === 0) && <Button 
                            className={classes.signupButton}
                            onClick={handleAuthClick} 
                            variant="outlined">
                           <img src={GoogleIcon} alt="G"/>&nbsp;
                           SIGN IN WITH GOOGLE 
                           </Button>}
                         {(activeStep=== 1 || activeStep === 2) && <Box mt={4} display="flex">
                           <CircularLoader size={18} height="auto"/>
                          </Box>}
                        </div>
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
            </Paper>
          </Box>
      </Grid>
      </>}
    </Grid>
    </>
  );
}


async function getToken(fqdn, dd) {
  let site = `https://${fqdn}`;
  try {
    let {data} = await axios.post(
      `https://www.googleapis.com/siteVerification/v1/token?access_token=${dd.access_token}`,
      {
        "verificationMethod": "FILE",
        "site": {
          "identifier": site,
          "type": "SITE",
        },
      },
    );
    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
}

async function verifyDomain(fqdn, dd) {
  const site = `https://${fqdn}`;
  try {
    let {data:insertData} = await axios.post(
      `https://www.googleapis.com/siteVerification/v1/webResource?verificationMethod=FILE&access_token=${dd.access_token}`,
      {
        "site": {
          "identifier": site,
          "type": "SITE",
        },
      },
      {
        headers: {
          Authorization: `Bearer ${dd.access_token}`,
          Accept: 'application/json',
        },
      },
    )
    return Promise.resolve(insertData);
  } catch (err) {
    return Promise.reject(err);
  }
}

async function updateDomainSiteMap(fqdn, dd) {
  const site = `https://${fqdn}`;
  try {
    const encodedSite = encodeURIComponent(site);
    const encodedSiteMap = encodeURIComponent(`${site}/xml/sitemap.xml`);
    let {data} = await axios.put(
      //eslint-disable-next-line
      `https://www.googleapis.com/webmasters/v3/sites/${encodedSite}/sitemaps/${encodedSiteMap}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${dd.access_token}`,
          Accept: 'application/json',
        },
      },
    )
    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
}

async function addSiteToUserAccount(fqdn, dd) {
  const site = `https://${fqdn}`;
  try {
    const encodedSite = encodeURIComponent(site);
    let {data} = await axios.put(
      //eslint-disable-next-line
      `https://www.googleapis.com/webmasters/v3/sites/${encodedSite}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${dd.access_token}`,
          Accept: 'application/json',
        },
      },
    )
    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
}