import React, {useState, useEffect, useRef, useCallback} from "react";
import Dialog from "commons/muiCustomComponents/Dailog";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from "react-redux";
import Grid from "commons/muiCustomComponents/Grid";
import Button from "commons/muiCustomComponents/Button";
import Box from "commons/muiCustomComponents/Box";
import MobileProvider from "commons/context/MobileProvider";
import Header from "../components/Header";
import { CircularLoader } from "commons/commonWrapper/Loaders";
import {
  fetchArticleMeta,
  clearCache,
  createBroadcast,
  makeBroadcastLive,
  endBroadcast,
  setBroadcastMetadata,
  getBroadcastMetadata,
  uploadBroadcastFile,
  uploadDefaultFile,
  switchToBlank,
  switchToMainPdf,
} from "../content-action";
import setAppMessage from "app-component/app-component-action";
import { useHistory, useParams } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';
import PanToolRoundedIcon from '@material-ui/icons/PanToolRounded';
import PanToolTwoToneIcon from '@material-ui/icons/PanToolTwoTone';
import ZoomInRoundedIcon from '@material-ui/icons/ZoomInRounded';
import ZoomOutRoundedIcon from '@material-ui/icons/ZoomOutRounded';
import DeleteSweepRoundedIcon from '@material-ui/icons/DeleteSweepRounded';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import FormControl from '@material-ui/core/FormControl';
import Input from "commons/muiCustomComponents/Input";
import WebRTCAdaptor from "utils/webrtc-adapter.js";
import adapter from 'webrtc-adapter';
import { StreamChat } from 'stream-chat';
import RootRef from '@material-ui/core/RootRef';
import VideocamIcon from '@material-ui/icons/Videocam';
import WebAssetTwoToneIcon from '@material-ui/icons/WebAsset';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import Unmute from "@material-ui/icons/VolumeUp";
import Mute from "@material-ui/icons/VolumeOff";
import UnmuteVideo from "@material-ui/icons/Videocam";
import MuteVideo from "@material-ui/icons/VideocamOff";
import Copy from "clipboard-copy";
import PocChat from './Chat';
import {
  Chat,
  Channel,
  MessageList,
  MessageInput,
  MessageInputLarge,
  ChannelHeader,
  Window,
} from 'stream-chat-react';
import 'stream-chat-react/dist/css/index.css';
import { LogglyTracker } from 'loggly-jslogger';
import {hideIntercom} from "../../../utils/utils";
import ShareIcon from '@material-ui/icons/Share';
import PostAddIcon from '@material-ui/icons/PostAdd';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import WithFileUpload from "commons/hocs/with-file-upload";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import BrushIcon from '@material-ui/icons/Brush';
import ColorSelectButton from '@material-ui/icons/Stop';
import TocIcon from '@material-ui/icons/Toc';
import ChatIcon from '@material-ui/icons/Chat';
import GroupIcon from '@material-ui/icons/Group';
import PanToolIcon from '@material-ui/icons/PanTool';
import EraserButton from "assets/icons/eraser.svg";
import EraserButtonSmall from "assets/icons/eraser-small.svg";
import EraserButtonMedium from "assets/icons/eraser-medium.svg";
import EraserButtonLarge from "assets/icons/eraser-large.svg";
import DropDownLarge from "assets/icons/dropdown-large.svg";
import DropDownMedium from "assets/icons/dropdown-medium.svg";
import DropDownSmall from "assets/icons/dropdown-small.svg";
import superagent from 'superagent';
import List from "@material-ui/core/List";
import ListItem from '@material-ui/core/ListItem';
import { simplifyLine } from './utils/simplifyLine'
import ReplayIcon from '@material-ui/icons/Replay';
import UndoIcon from '@material-ui/icons/Undo';
import SettingsIcon from '@material-ui/icons/Settings';
import Tooltip from '@material-ui/core/Tooltip';
import { disableBodyScroll,
  enableBodyScroll,
   clearAllBodyScrollLocks } from 'body-scroll-lock';
import Iframe from "react-iframe";
import WebSocketUtil from '../../../utils/webSocketUtil';
const debounce = require('lodash.debounce');
const simplify = require("utils/simplify").default;

const UploadButton = ()=>{
  return (
      <PostAddIcon fontSize={"small"} />
  )
}
const WithUploadButton = WithFileUpload(UploadButton);
const chatAllowedFormat = ['.jpg', '.jpeg','.png','.gif'];

const useStyles = makeStyles(theme => ({
  headerSelectorHolder: {
    background: '#e5e5e5',
    padding: '0.6em',
    paddingRight: '0em',
    paddingLeft: '0em',
    borderRadius: '4px',
    height: '0.75em',
    width: '8em',
  },
  mobileTitle: {
    justifyContent: 'center',
    display: 'flex',
  },
  footerButtonDesktop: {

  },
  footerButtonMobile: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },
  stepSection: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: theme.spacing(25),
  },
  mobileFooterContainer: {
    padding: theme.spacing(4),
  },
  mobileFooterItem: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  imgContainer: {
    minHeight: `calc(100vh - 180px)`,
  },
  selectedImage: {
    objectFit: 'contain',
  },
  backgroundFullScreen: {
    backgroundColor: theme.palette.pubBackground,
  },
  colorBackground: {
    background: 'lightgray',
  },
  mobileMargin: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  formControl: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  paddingControl: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  whiteboardVideo :{
    position:'absolute',
    width :theme.spacing(150 * 0.25),
    bottom:theme.spacing(2),
    right:theme.spacing(2),
    zIndex:1500,
  },
  landscapeHeader: {
    position: 'absolute',
    background: 'rgb(10,10,10, 0.7)',
    padding: '12px',
    zIndex:999999,
  },
  potraitHeader: {
    padding: theme.spacing(2),
  },
  redButton :{
      background: "#f92d2c",
      color: theme.palette.pubWhite,
      "&:hover,&:focus": {
        background: "#f92d2c !important",
        color: theme.palette.pubWhite,
      },
      whiteSpace:'nowrap',
    },
  borderStyle :{
    padding: theme.spacing(1),
  },
  crossChatButton :{
    fill: 'white',
    position: 'absolute',
    top: '22px',
    right: '10px',
  },
  squareButton :{
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    minWidth: 'unset',
  },
  chatLayout:{
    height: `calc(100vh - 60px)`,
    top: `60px`,
    position: `fixed`,
    minWidth:'320px',
  },
  messageImageContainer: {
    maxWidth: '270px',
    margin: '3px',
    objectFit:'contain',
  },
  dialogForParticipants :{
    width: '320px',
    top: '4.2%',
    left: '12%',
    height: '90vh',
  },
  dialogForParticipantsOBS :{
    width: '400px',
    top: '3.8%',
    left: '0.4%',
    height: '89vh',
  },
  chatMicIcon: {
    float: 'right',
    marginRight: '1em',
  },
  eraser: {    
    height: `10px`,
    width: `10px`,
    display:'block',
  },
}));

function LiveMessageComponent(props) {
  const { message } = props;
  const classes = useStyles();

  return (<>
    <Box margin='1em' lineHeight='2em' color='white' fontSize='0.9rem' style={{wordBreak:'break-all'}}>
      <Box component="span" color='gray' marginRight='1em' fontSize='0.8rem' fontWeight='bold'>{message && message.user && message.user.name}</Box>
      {message && message.text}
    </Box>
    {
      Array.isArray(message && message.attachments) &&
      <Box display='flex' flexDirection='column'>
        {
          message.attachments.map((attachment, key) => (
            attachment.type === 'image' && <img className={classes.messageImageContainer}
              src={attachment.image_url || attachment.asset_url} alt={attachment.thumb_url} key={key}></img>
          ))
        }
      </Box>
    }
  </>);
}

class ChatErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error,  errorInfo)
    this.props.onError();
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}


function LiveTransmission(props) {
  const logger = new LogglyTracker();
  logger.push({ 'logglyKey': '142d21f5-10d3-47a9-aa5a-f83e0a3abed7', 'sendConsoleErrors' : true, 'tag' : 'live-stream' });
  const classes = useStyles();
  const params = useParams();
  const isMobile = React.useContext(MobileProvider);
  const history = useHistory();
  const dispatch = useDispatch();
  const { landing, header: { domainList } } = useSelector(state => state);
  const { activeDomain, userProfile } = landing;
  const [openLiveDialog, setOpenLiveDialog] = useState(false);
  const [availableVideoSource, setAvailableSource] = useState([])
  const [availableAudioSource, setAvailableAudioSource] = useState([]);
  const [serverURL, setServerURL] = useState('');
  const [streamKey, setStreamKey] = useState('');
  const [isVideoProgress, setVideoProgress] = useState(false);
  const [isGoingLive, setIsGoingLive] = useState(true);
  const [isCreatingBroadcast, setCreatingBroadcast] = useState(true);
  const [goToNextPage, setGoToNextPage] = useState(false);
  const [defaultVideoSource, setDefaultVideoSource] = useState(null);
  const [defaultAudioSource, setDeafultAudioSource] = useState(null);
  const [resumeStream, setResumeStream] = useState(false);
  const [endingLiveStream, setEndingLiveStream] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [currentPage, setCurrentPage] = useState();
  const [totalPages, setTotalPages] = useState();
  const [emailCheck, setEmailCheck] = useState(false);
  const [showDialog, setShowDailog] = useState(false);
  const [isShowLive, setIsShowLive] = useState(false);
  const [isSocketConnected, setIsSocketConnected] = useState(false);
  const stoppingWebcam = useRef();
  const [openShareAnchorlEl ,setOpenShareAnchorlEl] = useState(null);
  const [isShowBrush ,setShowBrush] = useState(false);
  const [isOpenChat ,setOpenChat] = useState(false);
  const [isOpenMember , setOpenMember] = useState(false);
  const [brushColor, setBrushColor] = useState('black');
  const [showPaintBrush, setShowPaintBrush] = useState(-1);// -1 - not set, 0 - hide, 1 - show
  const [canvasImage, setCanvasImage] = useState(null);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [orientation, setOrientation] = useState(null);
  const [showHeader, setShowHeader] = useState(false);
  const [isResized, setIsResized] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isVideoMuted, setIsVideoMuted] = useState(false);
  const [enableDrawing, setEnableDrawing] = useState(false);
  const [counter, setCountrer] =  useState(0);
  const [whiteboardPdfCount, setWhiteboardPdfCount] = useState(1);
  const [openFirsTootlTip ,setOpenFirstToolTip] = useState(false);
  const [openSecondTootlTip ,setOpenSecondToolTip] = useState(false);
  const [appHeight, setHeight] = React.useState(0);

  const [isShowEraser ,setShowEraser] = useState(false);
  const [currentScale, setCurrentScale] = useState(1);
  const [displacement, setDisplacement] = useState({x: 0, y: 0});
  const [allFilesPagesConf, setAllFilesPagesConf] = useState({});
  const [menuHeight ,setMenuHeight] = useState(0);
  const [currentPageConf, setCurrentPageConf] = useState({
    "filename": "",
    "pageNo": 1,
    "scale": 1,
    "displacement": {
        "x": 0,
        "y": 0,
    },
    "type": "currentPageMeta",
  });
  const [isPanMode, setIsPanMode] = useState(false);
  const [isEraserMode, setIsEraserMode] = useState(false);
  const [eraserSize, setIsEraserSize] = useState({h: 0, w: 0, text: 'None'});

  let currentImageDimension = useRef({width: 0, height: 0});
  const canvaRef = useRef(null);
  const canvaRefDraw = useRef(null);
  const canvaRefDrawTemp = useRef(null);
  const memCanvaRef = useRef(null);
  const [noImage, setNoImage] = useState(true);
  const [isDefaultUploaded, setIsDefaultUploaded] = useState(false);
  const [isBlankPage, setIsBlankPage] = useState(false);
  const [switchingBlankPage, setSwitchingBlankPage] = useState(false);
  const [updatingPage, setUpdatingPage] = useState(false);
  const [showRTMPInfo ,setShowRTMPInfo] = useState(false);
  const [userList ,setUserList] = useState([]);
  const [chatMembers ,setChatMembers] = useState(0);
  const [nwayAudioCounter, setNwayAudioCounter] = useState((new Date()).getTime());

  const isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') === -1 &&
    navigator.userAgent.indexOf('FxiOS') === -1;
  const streamConfigs = useRef();

  let currentStreamConfig = useRef();

  let firstinput = useRef();
  let secondinput = useRef();

  let webRTCAdaptor = useRef();
  let broadcastInfo = useRef();
  let chatClient = useRef();
  let chatChannel = useRef();
  let videoDevices = useRef();
  let audioDevices = useRef();
  const [shareUrl, setShareUrl] = useState();
  let articleMeta = useRef();
  let liveMeta = useRef();
  let autoPublish = useRef();
  let webcamAllowed = useRef();
  const targetRef = useRef();
  const containerTargetRef = useRef();
  const memberContainerRef = useRef();
  const chatContainerRef = useRef();
  let [allowAudioChannels, setAllowAudioChannels] = useState();

  let [streamMode, setStreamMode] = useState('webcam');
  let pdfInfo = useRef();
  let [gettingMetadata, setGettingMetadata] = useState(false);
  let canvasLines = useRef();
  let linesCount = useRef();
  let drawingsCache = useRef();
  const headerRef = useRef();
  const canSendSocketData = useRef();
  let reload =  false;
  let startTime = "";

  useEffect(()=>{
    drawingsCache.current = {};
    reload = true;
    window.addEventListener('resize', function () {
      setIsResized((new Date()).getTime());
    });
    return hideIntercom();
  },[]);


  useEffect(()=>{
    if(orientation === 'landscape'){
       hideIntercom();
       isMobile && (document.body.style.overscrollBehavior = "contain")
    }
  },[orientation])

  useEffect(()=>{
    document.body.style.overscrollBehavior = "contain";
    return function cleanup() {
      document.body.style.overscrollBehavior = "auto";
    }
  },[]);

  let orientationTimer = useRef();

  useEffect(() => {
    if (headerRef.current && headerRef.current !== null) {
      setHeight(headerRef.current.getBoundingClientRect().height);
    }
  }, [headerRef]);

  useEffect(()=>{
    targetRef && targetRef.current && disableBodyScroll(targetRef.current);

    try {
      if (navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2 &&
      /MacIntel/.test(navigator.platform) ){
        isSafari && containerTargetRef &&  containerTargetRef.current &&
        disableBodyScroll(containerTargetRef.current) ;

        isSafari && document && document.body && disableBodyScroll(document.body);
      }
    }
    catch(err){
      console.log("err",err)
    }


    if(isSafari && containerTargetRef && containerTargetRef.current){
      containerTargetRef.current.addEventListener('touchmove', function (event) {
        if (event.scale !== 1) { event.preventDefault(); }
      }, false);   
      let lastTouchEnd = 0;
      containerTargetRef.current.addEventListener('touchend', function (event) {
        const now = (new Date()).getTime();
        if (now - lastTouchEnd <= 300) {
          event.preventDefault();
        }
        lastTouchEnd = now;
      }, false); 
      containerTargetRef.current.style &&
      (containerTargetRef.current.style.touchAction = "manipulation")
    }
    if(isSafari && headerRef.current ){
      headerRef.current.addEventListener('touchmove', function (event) {
        if (event.scale !== 1) { event.preventDefault(); }
      }, false);   
      let lastTouchEnd = 0;
      headerRef.current.addEventListener('touchend', function (event) {
        const now = (new Date()).getTime();
        if (now - lastTouchEnd <= 300) {
          event.preventDefault();
        }
        lastTouchEnd = now;
      }, false); 
      headerRef.current.style &&
      (headerRef.current.style.touchAction = "manipulation")
    }
    return (()=>{
      clearAllBodyScrollLocks();
    })

  },[targetRef.current,orientation,streamMode,(window.outerHeight-window.innerHeight)]);

  useEffect(()=>{
    if(isSafari && chatContainerRef && chatContainerRef.current){
      chatContainerRef.current.addEventListener('touchmove', function (event) {
        if (event.scale !== 1) { event.preventDefault(); }
      }, false);   
      let lastTouchEnd = 0;
      chatContainerRef.current.addEventListener('touchend', function (event) {
        const now = (new Date()).getTime();
        if (now - lastTouchEnd <= 300) {
          event.preventDefault();
        }
        lastTouchEnd = now;
      }, false); 
      chatContainerRef.current.style &&
      (chatContainerRef.current.style.touchAction = "manipulation")
    }
    if(isSafari && memberContainerRef && memberContainerRef.current){
      memberContainerRef.current.addEventListener('touchmove', function (event) {
        if (event.scale !== 1) { event.preventDefault(); }
      }, false);   
      let lastTouchEnd = 0;
      memberContainerRef.current.addEventListener('touchend', function (event) {
        const now = (new Date()).getTime();
        if (now - lastTouchEnd <= 300) {
          event.preventDefault();
        }
        lastTouchEnd = now;
      }, false); 
      memberContainerRef.current.style &&
      (memberContainerRef.current.style.touchAction = "manipulation")
    }
  },[
    chatContainerRef.current ,
    memberContainerRef.current,
  ])

  useEffect(()=>{
    if(isSafari){
      try{
        setMenuHeight(window.outerHeight - window.innerHeight)
      }
      catch(err){
        console.log("Err",err)
      }
    }
    return ()=>{
      setMenuHeight(0);
    }
  },[window.outerHeight - window.innerHeight]);

  useEffect(() => {
    try {
        if(isMobile) {
          if('onorientationchange' in window) {
            if(window &&window.screen && window.screen.orientation && window.screen.orientation.angle === 0) {
              setOrientation('potrait');
              setShowHeader(true);
            } else if (window.orientation && (window.orientation === 0 || window.orientation === 180)) {
                setOrientation('potrait');
                setShowHeader(true);
            } else if (window.orientation && (window.orientation === -90 || window.orientation === 90)){
              if(window.innerHeight >= 768 || !isMobile) {
                setOrientation('potrait');
                setShowHeader(true);
              } else {
                setOrientation('landscape');
                setShowHeader(false);
              }
            } else {
                setOrientation('potrait');
                setShowHeader(true);
            }
            window.addEventListener("orientationchange", function(event) {
              console.log('Orientation change, event')
              clearTimeout(orientationTimer.current)
              !isSafari &&(orientationTimer.current = setTimeout(() => updateSource(), 2000));
              if(window &&window.screen && window.screen.orientation && window.screen.orientation.angle === 0) {
                setOrientation('potrait');
                setShowHeader(true);
              } else if (window.orientation && (window.orientation === 0 || window.orientation === 180)) {
                  setOrientation('potrait');
                  setShowHeader(true);
              } else if (window.orientation && (window.orientation === -90 || window.orientation === 90)){
                if(window.innerHeight >= 768 || !isMobile) {
                  setOrientation('potrait');
                  setShowHeader(true);
                } else {
                  setOrientation('landscape');
                  setShowHeader(false);
                }
              } else {
                  setOrientation('potrait');
                  setShowHeader(true);
              }
            });
          }
        } else {
          setOrientation('potrait');
          setShowHeader(true);
        }
      if(currentPage && totalPages) {
        setNewPage(currentPage);
      }
    } catch (err) {
      console.log(err)
    }
  }, [isResized]);

  const selectEraserSize = (value = {}) => {
    setShowPaintBrush(0);
    setIsPanMode(false);
    if(!isEraserMode) {           
      setIsEraserMode(true);
    }
    setIsEraserSize(value);
    document.getElementById('myCanvaDrawTemp').style.cursor = `url(${getEraseimage(value.text)}), auto`;        
  }

  function saveConfigInsideAll(scale, displacement) {
    let allConfig = allFilesPagesConf || {};
    let fileAllPagesConfig = allConfig[pdfInfo.current.filename] || {};
    let pageConfig = {
      ...(fileAllPagesConfig[currentPageRef.current] || {}),
      scale, displacement,
    };
    fileAllPagesConfig[currentPageRef.current] = pageConfig;
    allConfig[pdfInfo.current.filename] = fileAllPagesConfig;
    setAllFilesPagesConf(allConfig);
    delete allConfig.type;
    saveMetadata('currentPageMeta', allConfig);
  }

  const onChangeScale = async (val) => {
    let scale = (currentPageConf.scale||1)+val;
    const scaleLimit = (scale - 1)/scale;
    let displacement = currentPageConf.displacement || {x:0, y:0};
    if (displacement.x > scaleLimit) {
      displacement.x = scaleLimit;
    }
    if (displacement.y > scaleLimit) {
     displacement.y = scaleLimit;
    }
    if ((currentPageConf.scale||1)+val < 1) {
      scale = 1;
      displacement = {x: 0, y: 0};
    }
    let temp = {
      ...currentPageConf,
      scale,
      displacement,
    };
    saveConfigInsideAll(scale, displacement);
    setCurrentPageConf(temp);
    sendSocketData('scaleCanvas', {scale, displacement});
    document.getElementById('myCanvaDrawTemp').style.cursor = 'default'
  };

  const onChangePan = () => {
    if (!isPanMode) {
      setShowPaintBrush(0);
      setIsPanMode(true);
    } else {
      setIsPanMode(false);
    }
    document.getElementById('myCanvaDrawTemp').style.cursor = 'default'
  };

  const setWhiteboardConfigs = () => {
    if (isSafari) {
      streamConfigs.current = [
        {
          width: 144,
          bandwidth: 200,
        },
      ]
    } else {
      streamConfigs.current = [
        {
          width: 144,
          bandwidth: 200,
        },
      ]
    }
  }

  const setNonWhiteboardConfigs = () => {
    if (isSafari) {
      streamConfigs.current = [
        {
          width: 480,
          bandwidth: 900,
          upBandwidth: 500,
        },
      ];
    } else {
      streamConfigs.current = [
        {
          width: 480,
          bandwidth: 900,
          frameRate: {ideal: 15},
        },
      ];
    }
  }

  const doDispatch = async (f, name) => {
    let loggerBaseData = {
      'email': ((landing||{}).userProfile||{}).email || '',
      'streamId': broadcastInfo.current && broadcastInfo.current.streamId,
      'userAgent': navigator.userAgent,
      'name': name,
    }
    const log = (type) => {
      loggerBaseData.type = type;
      logger.push(loggerBaseData);
    }
    log("REQUEST_START");
    const r = await dispatch(f());
    log("REQUEST_END");
    return r;
  }

  const startPublish = (streamId, token) => {
    if (webRTCAdaptor.current) {
      webRTCAdaptor.current.publish(streamId, token);
    }
  };

  const histListener = history.listen((location, action) => {
    if (location !== '/dash/create/livestream/new') {
      stopBroadcast();
      histListener();
    }
  });

  const isVideoEnabled = useRef();
  const isAudioEnabled = useRef();

  useEffect(() => {
    if (typeof isVideoEnabled.current != "boolean") {
      isVideoEnabled.current = true;
    }
    if (typeof isAudioEnabled.current != "boolean") {
      isAudioEnabled.current = true;
    }
  }, []);

  const toggleVideoTimer = useRef();
  const toggleVideoBroadcast = (ignoreSwitch) => {
    try {
      if (toggleVideoTimer.current) {
        return;
      }
      toggleVideoTimer.current = true;
      setTimeout(() => toggleVideoTimer.current = false, 3000);
      console.log(isVideoEnabled.current, isVideoMuted)
      if (!(webRTCAdaptor.current || usePoison.current)) {
        return;
      }
      try {
        if ((isVideoEnabled.current && !ignoreSwitch) || (!isVideoEnabled.current && ignoreSwitch)) {
          setIsVideoMuted(true);
          if (usePoison.current) {
            window.autoJoinVideoChannel = false;
            window.rc.closeProducer(window.RoomClient.mediaType.video);
          } else {
            webRTCAdaptor.current.turnOffLocalCamera()
          }
        } else {
          setIsVideoMuted(false);
          if (usePoison.current) {
            window.autoJoinVideoChannel = true;
            window.rc.produce(window.RoomClient.mediaType.video)
          } else {
            webRTCAdaptor.current.turnOnLocalCamera()
          }
        }
      } catch (e) {
        console.error(e);
      }
      if (!ignoreSwitch) {
        isVideoEnabled.current = !isVideoEnabled.current;
      }
    } catch (err) {
      console.error(err);
    }
  }

  const toggleAudioTimer = useRef();
  const toggleAudioBroadcast = (ignoreSwitch) => {
    try {
      if (toggleAudioTimer.current) {
        return;
      }
      toggleAudioTimer.current = true;
      setTimeout(() => toggleAudioTimer.current = false, 3000);
      if (!(webRTCAdaptor.current || usePoison.current)) {
        return;
      }
      if ((isAudioEnabled.current && !ignoreSwitch) || (!isAudioEnabled.current && ignoreSwitch)) {
        setIsMuted(true);
        if (usePoison.current) {
          window.rc.pauseProducer(window.RoomClient.mediaType.audio)
        } else {
          webRTCAdaptor.current.muteLocalMic()
        }
      } else {
        setIsMuted(false);
        if (usePoison.current) {
          window.rc.resumeProducer(window.RoomClient.mediaType.audio)
        } else {
          webRTCAdaptor.current.unmuteLocalMic()
        }
      }
      if (!ignoreSwitch) {
        isAudioEnabled.current = !isAudioEnabled.current;
      }
    } catch (err) {
      console.error(err);
    }
  }


  const participantHandler = (members) => { 
    setChatMembers(members.length)
    setUserList(members)
  }

  const stopBroadcast = async () => {
    const tryFn = (f) => {
      try {
        f()
      } catch (e) {
        console.log(e)
      }
    }
    setIsSocketConnected(false);
    setAllowAudioChannels(false);
    tryFn(() => stopPoisonBroadcast());
    if (!webRTCAdaptor.current) {
      return ;
    }
    stoppingWebcam.current = true;
    tryFn(() => webRTCAdaptor.current.disableStats());
    tryFn(() => webRTCAdaptor.current.closeStream());
    tryFn(() => webRTCAdaptor.current.stop(streamKey));
    tryFn(() => webRTCAdaptor.current.closeWebSocket());
    setTimeout(() => stoppingWebcam.current = false, 2000)
  };

  const initBroadcastInfo = async (showMessage) => {
    try {
      showMessage && setCreatingBroadcast(true)
      broadcastInfo.current = await doDispatch(() => createBroadcast(params.articleId), 'createBroadcast');
      if (broadcastInfo.current.endpoints) {
        setServerURL(broadcastInfo.current.endpoints.rtmp);
        setStreamKey(broadcastInfo.current.endpoints.streamKey);
      }
      showMessage && setCreatingBroadcast(false)
    }
    catch (err) {
      err.fff = 'initBroadcastInfo';
      err.articleId = params.articleId;
      console.log(err)
      logsErrorMessage(err);
      showMessage && setCreatingBroadcast(false)
      dispatch(setAppMessage({ message: 'Failed to create brodcast', type: 'alert', header: 'Alert' }))
    }

  };

  const getMediaConstraints = () => {
    if (!currentStreamConfig.current) {
      currentStreamConfig.current = {
        index: 0,
        changeAt: new Date(),
      }
    }
    return streamConfigs.current[currentStreamConfig.current.index];
  };

  const updateMediaConstraints = (diff) => {
    const newIndex = currentStreamConfig.current.index - diff
    if (newIndex >= 0 && newIndex < streamConfigs.current.length) {
      currentStreamConfig.current = {
        index: newIndex,
        changeAt: new Date(),
      }
    }
    return streamConfigs.current[currentStreamConfig.current.index];
  };

  let oldStatsTime = new Date();
  let oldStats;
  let degradeRequests = -1;
  let upgradeRequests = 0;

  const handleStats = (newStats) => {
    if (!webRTCAdaptor.current) {
      return;
    }
    const newStatsTime = new Date();
    if (!oldStats) {
      oldStats = JSON.parse(JSON.stringify(newStats));
      return;
    }
    const fps = (newStats.framesEncoded - oldStats.framesEncoded) * 1000.0 / (newStatsTime - oldStatsTime);
    const limitationReason = newStats.qualityLimitationReason === "bandwidth" || newStats.qualityLimitationReason === "cpu";
    let downgrade = false;
    let upgrade = false;
    const m = getMediaConstraints();
    if (fps < 10 || limitationReason) {
        downgrade = true;
    } else {
        upgrade = true;
    }

    oldStatsTime = newStatsTime;
    oldStats = JSON.parse(JSON.stringify(newStats));

    if (downgrade) {
      degradeRequests += 1;
    }
    if (upgrade) {
      upgradeRequests += 1;
    }
    let change = false;
    if (degradeRequests >= 3) {
      degradeRequests = -1;
      upgradeRequests = 0;
      if (currentStreamConfig.current.index === streamConfigs.current.length - 1) {
        return;
      }
      console.log("DOWNGRADING")
      change = true;
      updateMediaConstraints(-1);
    }  else if (upgradeRequests > 2) {
      upgradeRequests = 0;
      degradeRequests = -1;
      if (currentStreamConfig.current.index === 0) {
        return;
      }
      console.log("UPGRADING")
      change = true;
      updateMediaConstraints(1);
    }
    if (change) {
      const m = getMediaConstraints();
      const data = {
        'email': ((landing||{}).userProfile||{}).email || '',
        'downgrade': downgrade,
        'upgrade': upgrade,
        'reason': newStats.qualityLimitationReason,
        'fps': fps,
        'constraints': JSON.stringify(m),
        'streamId': broadcastInfo.current.streamId,
        'currentBitrate': newStats.currentOutgoingBitrate,
        'averageBitrate': newStats.averageOutgoingBitrate,
        'currentExpectedBitrate': newStats.currentExpectedBitrate,
        'movingAverage': newStats.movingAverage,
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
        'type': 'broadcastDowngradeConnection',
        'data': data,
      });
      let mediaConstraints = {
        video: {
          deviceId: videoDevices.current[getVideoCaptureDeviceId()].deviceId,
        },
        audio: {
          deviceId: audioDevices.current[getAudioCaptureDeviceId()].deviceId,
        },
      };
      mediaConstraints.video = Object.assign(mediaConstraints.video, getMediaConstraints());
      webRTCAdaptor.current.applyConstraints(broadcastInfo.current.streamId,  mediaConstraints);
      webRTCAdaptor.current.changeBandwidth(m.bandwidth, broadcastInfo.current.streamId);
    }
  }

  const initCount = useRef();
  const audioAvailMap = useRef();
  const audioCache = useRef();
  const audioAvailRequestCount = useRef();
  const [isGlobalAudioAvail, setIsGlobalAudioAvail] = useState(false);
  const updateGlobalAudioAvailMap = (val) => {
    setIsGlobalAudioAvail(val);
    Object.keys(userList).forEach(m => {
      audioAvailMap.current[userList[m].UserID] = val;
      activeAudioRequests.current[userList[m].UserID] = false;
    })
    audioAvailRequestCount.current = 0;
    sendPoisonSocketData('audioAvail', audioAvailMap.current);
    setNwayAudioCounter((new Date()).getTime());
  }

  const updateAudioAvailMap = (user, val) => {
    audioAvailMap.current[user.UserID] = val;
    audioAvailMap.current.date = new Date();
    activeAudioRequests.current[user.UserID] = false;
    audioAvailRequestCount.current = audioAvailRequestCount.current - 1;
    sendPoisonSocketData('audioAvail', audioAvailMap.current);
    setNwayAudioCounter((new Date()).getTime());
  }

  const sendPoisonSocketData = (type, data) => {
    if (window.rc && window.rc.socket) {
      try {
        window.rc.socket.emit(type, data);
      } catch (e) {
        console.err("Could not send socket message: ", e)
      }
    }
  }

  let activeAudioRequests = useRef();
  const handleSocketData = (dataStr) => {
    const data = JSON.parse(dataStr);
    if (data.type == 'audioAvailRequest') {
      if (!activeAudioRequests.current[data.data.userId]) {
        audioAvailRequestCount.current = audioAvailRequestCount.current + 1;
      }
      activeAudioRequests.current[data.data.userId] = data.data;
      setNwayAudioCounter((new Date()).getTime());
    }
  }

  const joinPoisonRoom = () => {
    window.autoJoinAudioChannel = true;
    window.autoJoinVideoChannel = false;
    window.localVideoId = 'localVideo';
    window.poisonSocketHandler = {
      'audioAvailRequest': (data) => {
        if (!activeAudioRequests.current[data.data.userId]) {
          audioAvailRequestCount.current = audioAvailRequestCount.current + 1;
        }
        activeAudioRequests.current[data.data.userId] = data.data;
        setNwayAudioCounter((new Date()).getTime());
      },
    };
    if (!window.rc) {
      window.joinRoom(`${params.articleId}_creator`, params.articleId);
    }
    setIsSocketConnected(true);
  }

  const initPoisonWebcamBroadcast = () => {
    console.log("initPoisonWebcamBroadcast");
    joinPoisonRoom();
  }

  const stopPoisonBroadcast = () => {
    window.autoJoinAudioChannel = false;
    window.autoJoinVideoChannel = false;
    try {
      if(window.rc){
        window.rc.exit()
      }
    } catch (e) {
      console.error(e);
    }
    window.rc = null;
  }

  const initWebcamBroadcast = () => {
    if (usePoison.current) {
      initPoisonWebcamBroadcast();
      return;
    }
    if (webRTCAdaptor.current) {
      return;
    }
    const mode = localStorage.getItem('streamMode');
    console.log("INITING WEBCAM BROADCAST")
    setVideoProgress(true);
    const sdpConstraints = {
      OfferToReceiveAudio : false,
      OfferToReceiveVideo : false,
      VoiceActivityDetection: true,
    };

    const turnConfig = broadcastInfo.current.webrtcTurnConfig;
    const isRelay = turnConfig && Array.isArray(turnConfig) && (turnConfig.length > 0);

    const pc_config = {
      bundlePolicy: 'max-bundle',
      sdpSemantics: 'plan-b',
      iceTransportPolicy: isRelay ? 'relay': 'all',
      iceServers: turnConfig,
    };
    let mediaConstraints = {
      video: {deviceId: videoDevices.current[getVideoCaptureDeviceId()].deviceId},
      audio: mode !== 'whiteboard' &&
              {deviceId: audioDevices.current[getAudioCaptureDeviceId()].deviceId},
    };
    mediaConstraints.video = Object.assign(mediaConstraints.video, getMediaConstraints());
    const websocketURL = mode === 'webcam' ?
      broadcastInfo.current.webcamBroadcastEndpoint: broadcastInfo.current.whiteboardBroadcastEndpoint;
    try {
      webRTCAdaptor.current = new WebRTCAdaptor({
        websocket_url: websocketURL,
        mediaConstraints: mediaConstraints,
        peerconnection_config: pc_config,
        sdp_constraints: sdpConstraints,
        localVideoId: "localVideo",
        bandwidth: streamConfigs.current[0].bandwidth,
        debug: false,
        safari: isSafari,
        callback: (info, obj) => {
          if (info === "initialized") {
            console.log("initialized - ", broadcastInfo.current);
            autoPlayLocalVideo();
            if (autoPublish.current &&
              (!webRTCAdaptor.current.remotePeerConnection || !webRTCAdaptor.current.remotePeerConnection[broadcastInfo.current.streamId])) {
              console.log("STARTING PUBLISH AGAIN");
              tryPublish();
            }
            canSendSocketData.current = false;
            setIsSocketConnected(true);
          } else if (info === "publish_started") {
            console.log("publish started");
            setTimeout(() => canSendSocketData.current = true, 2000);
            toggleVideoBroadcast(true);
            toggleAudioBroadcast(true);
            setTimeout(() => canSendSocketData.current = true, 1000);
            autoPublish.current = true;
            setAllowAudioChannels(true);
          } else if (info === "publish_finished") {
            //stream is being finished
            stopBroadcast();
            setTimeout(() => initWebcamBroadcast(), 2000);
            console.log("publish finished");
          } else if (info === "closed") {
            if ((localStorage.getItem('streamMode') !== 'rtmp') && !endingLiveStream && !stoppingWebcam.current) {
              stopBroadcast();
              setTimeout(() => initWebcamBroadcast(), 2000);
            }
            console.log("Connection closed");
            if (typeof obj !== "undefined") {
              console.log("Connecton closed: " + JSON.stringify(obj));
            }
          } else if (info === "updated_stats") {
            //TODO: handle stats for callstats later
            handleStats(obj);
          } else if (info === "data_channel_opened") {
            autoPublish.current = true;
          } else if (info == "data_received") {
            console.log(obj);
            if (obj.event.data) {
              handleSocketData(obj.event.data);
            }
          }
          setVideoProgress(false);
        },
        callbackError: (error, message) => {
          console.error("ERROR", "error: "+ error, "message: " + message, "stoppingWebcam.current: " + stoppingWebcam.current)
          let errorMessage = JSON.stringify(error);
          if (typeof message !== "undefined") {
            errorMessage = message;
          }
          if (errorMessage.includes("WebSocketNotConnected") && !stoppingWebcam.current) {
            stopBroadcast();
            setTimeout(() => initWebcamBroadcast(), 2000);
          } else if (error === 'streamIdInUse' && !stoppingWebcam.current) {
            stopBroadcast();
            setTimeout(() => initWebcamBroadcast(), 2000);
          } else if (error === 'publishTimeoutError' && !stoppingWebcam.current) {
            stopBroadcast();
            setTimeout(() => initWebcamBroadcast(), 2000);
          } else if (!((message && (message.includes("satisfy")) || (errorMessage && errorMessage.includes("already")))) && !stoppingWebcam.current) {
            alert("Something went wrong, please try closing other apps on your device and reload the page: ", message || error)
          }

          // if (typeof error === 'function' && error.indexOf("NotFoundError") != -1) {
          //   errorMessage = "Camera or Mic are not found or not allowed in your device";

          const data = {
            'error': errorMessage,
            'email': ((landing||{}).userProfile||{}).email || '',
            'url': (window.location||{}).href || '',
            'streamId': broadcastInfo.current.streamId,
            'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
            'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
          };
          logger.push({
            'type': 'webrtcError',
            'data': data,
          })
        },
      }, adapter);
    }
    catch (e) {
      const data = {
        'email': ((landing||{}).userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
        'error': e,
      };
      logger.push({
        'type': 'websocketError',
        'data': data,
      });
      console.log("WEBSOCKET ERROR: ", e)
    }
  };

  const usePoison = useRef(false);
  const setStream = (mode) => {
    localStorage.setItem('streamMode', mode);
    usePoison.current = mode === 'whiteboard';
    isVideoEnabled.current = !usePoison.current;
    setIsVideoMuted(usePoison.current);
    setStreamMode(mode);
  };

  const switchStreamMode = (mode, metaData = {}) => {
    if (mode !== 'rtmp' && !webcamAllowed.current) {
      alert("Cannot access webcam or mic. Please verify you have permission for both webcam and mic on the browser. Close all apps and try again.");
      return;
    }

    const startWebcam = () => {
      stopBroadcast();
      initWebcamBroadcast();
    }

    if (mode === 'webcam') {
      setStream(mode);
      setNonWhiteboardConfigs();
      setTimeout(() => startWebcam(), 500);
      saveMetadata('mode', mode)
      sendSocketData('mode', mode, {"retry": true});
      stopPoisonBroadcast();
    } else if (mode === 'rtmp') {
      stopBroadcast();
      setStream(mode);
      setNonWhiteboardConfigs();
      saveMetadata('mode', mode)
      sendSocketData('mode', mode, {"retry": true});
    } else {
      setStream('whiteboard');
      setWhiteboardConfigs();
      setTimeout(() => startWebcam(), 500);
      saveMetadata('mode', 'whiteboard')
      sendSocketData('mode', 'whiteboard', {"retry": true});      
    }
  }

  const updateShareUrl = () => {
    if (domainList && activeDomain && domainList[activeDomain] && articleMeta.current) {
      setShareUrl(`https://${domainList[activeDomain][0].fqdn}/live${articleMeta.current.pathname}`)
    }
  }
  useEffect(() => {
    updateShareUrl();
    if (localStorage.getItem('streamMode')) {
      setStream(localStorage.getItem('streamMode'));
    }
    let popit = true;
    window.onbeforeunload = () => {
      if(popit === true) {
        popit = false;
        return "Are you sure you want to leave?";
      }
    }
    return ()=>{
      window.onbeforeunload = null;
    }
  }, [activeDomain, domainList, params, articleMeta.current, openLiveDialog]);

  const initLoad = useRef();
  useEffect(() => {
    permissionDenied.current = 0;
    if (!params.articleId) {
      return;
    }
    console.log("params", params.articleId);
    setNonWhiteboardConfigs();
    activeAudioRequests.current = {};
    audioAvailRequestCount.current = 0;
    const checkLiveStreams = async () => {
      const {result: meta} = await doDispatch(() => fetchArticleMeta(params.articleId), 'fetchArticleMeta');
      articleMeta.current = meta;
      updateShareUrl();
      let isMounted = true;
      initLoad.current =  () => {
        const handleNoWebcamAccess = () => {
          webcamAllowed.current = false;
          setStream(true);
          initBroadcast(true);
        };

        try {
            webcamAllowed.current = true;
            navigator.mediaDevices.enumerateDevices()
              .then((deviceInfos) => {
                videoDevices.current = [];
                audioDevices.current = [];
                for (let i = 0; i !== deviceInfos.length; ++i) {
                  const deviceInfo = deviceInfos[i];
                  const option = document.createElement('option');
                  option.value = deviceInfo.deviceId;
                  if (deviceInfo.kind === 'audioinput') {
                    option.text = deviceInfo.label || 'microphone ' + (parseInt(i) + 1);
                    availableAudioSource.push(option);
                    audioDevices.current.push(deviceInfo);
                    isMounted && setAvailableAudioSource(availableAudioSource);
                  } else if (deviceInfo.kind === 'videoinput') {
                    option.text = deviceInfo.label || 'camera ' + (parseInt(i) + 1);
                    availableVideoSource.push(option);
                    videoDevices.current.push(deviceInfo);
                    isMounted && setAvailableSource(availableVideoSource)
                  }
                }
                setDefaultVideoSource(availableVideoSource[getVideoCaptureDeviceId()].text);
                setDeafultAudioSource(availableAudioSource[getAudioCaptureDeviceId()].text);
                isMounted && initBroadcast(true);
              })
        } catch (e) {
            handleNoWebcamAccess();
        }
      }
      setTimeout(() => initLoad.current(), 500);
    }
    checkLiveStreams();
  }, [params.articleId]);

  const permissionDenied = useRef();
  const [permissionLoading, setPermissionLoading] = useState(false);

  let feniWS = useRef();
  let broadCastInfo = useRef({});
  let socketEventHandlers = {
    'onMessage': (socketData) => {     
      if(socketData.payload.type === 'onConnect') {  
        getMetadata(socketData.payload.data)
      } else if(socketData.event === "whiteboardEventCallback") {
        if(socketData.payload.page) {          
          currentPageRef.current = socketData.payload.page;
        }
        if(socketData.payload.type === 'getBroadcastMetadata') {         
          if(socketData.payload.data && socketData.payload.data.mode) {
            setStream(socketData.payload.data.mode);
          }
          broadcastMetadata = socketData.payload.data;
        } else if( socketData.payload.type === 'currentPage' && 
          socketData.payload.data && socketData.payload.page && socketData.payload.data.fileName
          && !drawingsCache.current[`page=${socketData.payload.page}&fileName=${socketData.payload.data.fileName}`]) {  
              drawingsCache.current = {
              ...drawingsCache.current,
              [`page=${socketData.payload.page}&fileName=${socketData.payload.data.fileName}`]: socketData.payload.data.draw,
            }
            loadDrawingFromSaved()
        }
      }
    },

  }

  const syncDrawingsOnConnection = () =>{
    const pageParams = getPageParams();
    const drawings = drawingsCache.current && drawingsCache.current[pageParams] ? drawingsCache.current[pageParams] : []
    const fileName = pdfInfo.current ? pdfInfo.current.filename : ''
    if(fileName !== '') {
      sendSocketData('lineSync',{line: drawings, currentPage: currentPageRef.current, filename:pdfInfo.current.filename},{"retry": true})
    }
  }

  const initBroadcast = (showMessage = false) => {
    initBroadcastInfo(showMessage)
      .then(() => {
        const handle = async () => {
          if((typeof feniWS.current === 'object' && feniWS.current === null) || !feniWS.current) {
            const user = JSON.parse(sessionStorage.getItem("user"));
            const userName = [user.firstName, user.lastName].filter(a => a).join(" ");
            const streamDetails = {
              emailID : user.email,
              userName,
              sectionId : articleMeta.current.sectionId,
              title: articleMeta.current.title,
              desc: articleMeta.current.summary,
              domainId: activeDomain,
              pathName: articleMeta.current.pathname, 
              articleId: `${articleMeta.current.articleId}`,
            }
            feniWS.current = new WebSocketUtil({url: `wss://feni.pubninja.com/stream/${params.articleId}/socket`}, 
            syncDrawingsOnConnection,socketEventHandlers, streamDetails)
            feniWS.current.connect();
          }
          sendSocketData('scaleCanvas', {scale: 1, displacement: {"x": 0, "y": 0}}, {"retry": true});
          sendSocketData('viewPort', {"x": 0, "y": 0}, {"retry": true});
            
          const meta = broadcastMetadata;
          const stream = (meta && meta.mode) || localStorage.getItem('streamMode');
          if (stream !== 'rtmp' && !webcamAllowed.current) {
            permissionDenied.current = permissionDenied.current + 1;
            if (permissionDenied.current < 15) {
              initLoad.current();
              setPermissionLoading(true);
              return;
            } else {
              alert("Could not start broadcast. Cannot access webcam or mic. Close all apps and try again.")
            }
          } else {
            switchStreamMode(stream, meta)
          }
          setPermissionLoading(false);
          if (articleMeta.current.isShowLive === 1) {
            setTimeout(() => {setIsShowLive(!!articleMeta.current.isShowLive);goLive()}, 500);
          } else {
            setIsGoingLive(false);
          }
        }
        handle();
      }).catch((err) => {
        err.fff = 'initBroadcast';
        err.showMessage = showMessage;
        logsErrorMessage(err);
        dispatch(setAppMessage({ message: JSON.stringify(err) || 'something went wrong', type: 'alert', header: 'Error' }))
    })
  };

  const logsErrorMessage = (err) => {
    const data = {
      'error': err,
      'email': ((landing||{}).userProfile||{}).email || '',
      'url': (window.location||{}).href || '',
      'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
      'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
    };
    try {
      logger.push({
        'type': 'initBroadcastError',
        'data': data,
      });
    } catch (e) {
      console.error(e)
    }
  }

  const initChat = () => {
    setShowChat(false);
    const info = liveMeta.current;
    if (info.chatToken) {
      const user = JSON.parse(sessionStorage.getItem("user"));
      const userDetails = {
        UserName: [user.firstName, user.lastName].filter(a => a).join(" "),
        EmailID: user.email,
        Profile: user.profilePicture || `https://getstream.io/random_svg/?id=${user.id}`,//&name=${name}`,
      }
      setShowChat(true)
      setTimeout(() => {
        setNwayAudioCounter((new Date()).getTime())
      }, 2500);
    } else {
      alert("Could not load chat. Please reload the page.");
    }
  };

  const tryPublish = () => {
    const interval = setInterval(() => {
      if (webRTCAdaptor.current && webRTCAdaptor.current.webSocketAdaptor && webRTCAdaptor.current.webSocketAdaptor.isConnected()) {
        clearInterval(interval)
        setTimeout(() => startPublish(broadcastInfo.current.streamId, broadcastInfo.current.tokens.publishToken), 500);
        setIsGoingLive(false);
      }
    }, 2000)
  }

  const goLive = async () => {
    var liveStart = localStorage.getItem(`live_startTime_${articleMeta.current.articleId}`)
    if(!liveStart){
      startTime = new Date();
      localStorage.setItem(`live_startTime_${articleMeta.current.articleId}`, startTime)
      liveStart = startTime;
    }
    console.log("startTime:::: ", liveStart)
    const data = {};
    if (emailCheck) data.sendLiveEmail = true;
    setShowDailog(false);
    try {
      setResumeStream(true);
      setIsGoingLive(true);
      const info = await doDispatch(() => makeBroadcastLive(params.articleId, data), 'makeBroadcastLive');
      liveMeta.current = info;
      autoPublish.current = true;
      dispatch(clearCache(params.articleId, {skip: true}));
      if (webRTCAdaptor.current) {
        tryPublish()
      } else {
        setIsGoingLive(false);
      }

      initChat();
      setResumeStream(false);
      setOpenLiveDialog(true);
      getMetadata();
    }
    catch (err) {
      setResumeStream(false);
      stopBroadcast()
      setGoToNextPage(false);
      err.articleId = params.articleId;
      err.custom_data = data;
      if (sessionStorage && sessionStorage.getItem) {
        err.sessionStorage = sessionStorage.getItem('active_domain_id');
      }
      err.fff = 'golive';
      logsErrorMessage(err);
      dispatch(setAppMessage({ message: JSON.stringify(err) || 'something went wrong', type: 'alert', header: 'Error' }))
    }
  };

  const convertToLocalTime = (date) =>{
    const localTime = date.toLocaleTimeString()
    const offset = date.getTimezoneOffset()
    date = new Date(date.getTime() - (offset*60*1000))
    const isoDate = date.toISOString().split('T')[0]
    return `${isoDate}T${localTime}`
  }

  const endVideoBroadcast = async () => {
    var liveStart = new Date(localStorage.getItem(`live_startTime_${articleMeta.current.articleId}`))
    const liveStartLocal = convertToLocalTime(liveStart)
    console.log("start::::", liveStartLocal)
    var endTime = new Date();
    var streamDuration = endTime - liveStart;
    var duration = Math.floor(streamDuration /1000/60);
    const endTimeLocal = convertToLocalTime(endTime)
    console.log("endTime:: ", endTimeLocal);
    sendSocketData('attendance', {startTime:liveStartLocal, endTime: endTimeLocal, duration})
    setEndingLiveStream(true);
    setOpenLiveDialog(false);
    setCreatingBroadcast(true);
    stopBroadcast();
    stopPoisonBroadcast();
    await doDispatch(() => endBroadcast(params.articleId), 'endBroadcast');
    await doDispatch(() => clearCache(params.articleId), 'clearCache');
    setEndingLiveStream(false);
    setCreatingBroadcast(false);    
    feniWS.current.disconnect();
    window.onbeforeunload = null;
    window.open('/dash/live/schedule', '_self');
  };

  const updateSource = () => {
    let mediaConstraints = {
      video: {deviceId: videoDevices.current[getVideoCaptureDeviceId()].deviceId},
      audio: streamMode !== 'whiteboard' &&
              {deviceId: audioDevices.current[getAudioCaptureDeviceId()].deviceId},
    };
    mediaConstraints.video = Object.assign(mediaConstraints.video, getMediaConstraints());
    const text = availableVideoSource[getVideoCaptureDeviceId()].text.toLowerCase()
    if (text.includes('back')) {
      mediaConstraints.video.facingMode = {exact: 'environment'}
      delete mediaConstraints.video.deviceId
    } else if (text.includes('front')) {
      mediaConstraints.video.facingMode = {exact: 'user'}
      delete mediaConstraints.video.deviceId
    }
    webRTCAdaptor.current.switchVideoSource(broadcastInfo.current.streamId,  mediaConstraints, () => {})
    autoPlayLocalVideo();
  }

  const autoPlayLocalVideo = () => {
    setTimeout(() => {
      try {
        document.getElementsByTagName('video')[0].pause();
        document.getElementsByTagName('video')[0].play();
      } catch (e) {
        console.log(e)
      }
    }, 1000)
  }

  const changeVideoCaptureDevice = (_e, f) => {
    sessionStorage.setItem('selectedVideoDevice', f.key);
    setDefaultVideoSource(availableVideoSource[f.key].text)
    updateSource();
  };

  const changeAudioCaptureDevice = (_e, f) => {
    sessionStorage.setItem('selectedAudioDevice', f.key);
    setDeafultAudioSource(availableAudioSource[f.key].text)
    updateSource();
  };

  const getVideoCaptureDeviceId = () => {
    const key = sessionStorage.getItem('selectedVideoDevice');
    return (key && parseInt(key)) || 0;
  }

  const getAudioCaptureDeviceId = () => {
    const key = sessionStorage.getItem('selectedAudioDevice');
    return (key && parseInt(key)) || 0;
  }

  const copyToClipboard = (e) => {
    if (e==='firstinput'){
      Copy(serverURL)
    } else if(e==='secondinput'){
      Copy(streamKey)
    } else if(e==='liveUrl'){
      Copy(shareUrl)
    }
  };

  const closeLiveVideo = ()=>{
    dispatch(setAppMessage({ message: '',
      leftButtonText:'Resume',
      rightButtonText:'End LIVE video',
      handleSuccess: endVideoBroadcast, header: 'Are you sure you want to end the LIVE Video?' }));
  };

  const switchBlankPage = async (bp) => {
    try {
      let broadcastInfo = {};
      setSwitchingBlankPage(true);
      if (bp) {
        broadcastInfo = await dispatch(switchToBlank(params.articleId));
        setIsBlankPage(true);
      } else {
        broadcastInfo = await dispatch(switchToMainPdf(params.articleId));
        setIsBlankPage(false);
      }
      let pageNo = 1;
      if (typeof broadcastInfo.currentPage === 'number' && broadcastInfo.currentPage > 1) {
        pageNo = broadcastInfo.currentPage;
      } else if (typeof broadcastInfo.currentPage === 'object' && broadcastInfo.currentPage.value &&
        broadcastInfo.currentPage !== null &&
        +broadcastInfo.currentPage.value > 1
      ) {
        pageNo = +broadcastInfo.currentPage.value;
      }
      pdfInfo.current = broadcastInfo.pdfInfo || {};
      setSwitchingBlankPage(false);      
      sendSocketData('pdfInfo', pdfInfo.current);
      setTotalPages(pdfInfo.current.totalPages||1);
      setNewPage(pageNo);
    } catch (e) {
      setSwitchingBlankPage(false);
      setUploadingFile(false);
      throw e;
    }
  };
  const toBase64 = file => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
  });

  function base64MimeType(encoded) {
    var result = null;
    if (typeof encoded !== 'string') {
      return result;
    }
    var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    if (mime && mime.length) {
      result = mime[1];
    }
    return result;
  }

  const canvasOnFileChange = async(target) => {
    setEnableDrawing(false);
    const file = target.files[0];
    setUploadingFile(true);
    await saveMetadata('currentPage', 1);
    setCurrentPage(1);
    currentPageRef.current = 1;
    try {
      const fileBaseString = await toBase64(file);
      const mimeType = base64MimeType(fileBaseString);
      const extension = mimeType.split('/')[1];

      fetch(fileBaseString)
        .then(res => res.blob())
        .then(async blob => {
          const file2 = new File([blob], `${Math.round(Math.random()*100000000000)}.${extension}`,{ type: mimeType })
          const apiResponse = await superagent
              .post(`https://absinthe.pubninja.com/glen/${activeDomain}/broadcast/upload/preprocess/${params.articleId}`)
              .attach('file', file2)
              .then(d=> d.body)
              .catch(e=>{throw e;});

          const data = {
            'api-response': apiResponse,
            'article-id': params.articleId,
            'email': ((landing||{}).userProfile||{}).email || '',
            'url': (window.location||{}).href || '',
            'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
            'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
          };
          logger.push({
            'type': 'uploadSuccess',
            'data': {
              'email': ((landing || {}).userProfile || {}).email || '',
              apiResponse,
              'article_id': params.articleId,
            },
          });
          getMetadata({}, true);
        });
    } catch (e) {
      const data = {
        'article-id': params.articleId,
        'error': e,
        'file': file,
        'email': ((landing||{}).userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
        'type': 'uploadError',
        'data': data,
      });
      setUploadingFile(false);
      alert(JSON.stringify(e));
      throw e;
    }
  };

  const togglePaintBrush = async() => {
    if(showPaintBrush === 0 || showPaintBrush === -1) {
      setShowPaintBrush(1);
      setIsPanMode(false);
      if(orientation === 'landscape'){        
        setShowBrush(true)
        setIsEraserMode(false)
      }
    }
    if(showPaintBrush === 1) {
      setShowPaintBrush(0);
      if(orientation === 'landscape'){
        setShowBrush(false)
      }
    }
    document.getElementById('myCanvaDrawTemp').style.cursor = 'default'
  }
  
  useEffect(()=>{
    if(!showPaintBrush){
        setShowBrush(false)
    }
  },[showPaintBrush]);

  function getPointerPos(e) {
    const myCanva = document.getElementById('myCanva');
    var rect = myCanva.getBoundingClientRect();
    var clientX = e.clientX;
    var clientY = e.clientY;

    if (e.changedTouches && e.changedTouches.length > 0) {
      clientX = e.changedTouches[0].clientX;
      clientY = e.changedTouches[0].clientY;
    }

    return {
      x: clientX - rect.left,
      y: clientY - rect.top,
    };
  }

  let isDrawing = useRef(false);
  let x = useRef(0);
  let y = useRef(0);

  let line = {
    points: [],
    brushColor: brushColor || 'black',
    brushRadius: 2,
    eraserSize:0,
    type:'',
    key:'',
  };

  let panMoves = useRef([]);
  const points = useRef([]);
  const tempPoints = useRef([]);
  let eraseLine = {
    points: [],
    eraserSize: null,
  };

  let movesTracker = useRef([]);

  function startDrawing(e) {
    if (enableDrawing && openLiveDialog && (showPaintBrush > 0 || isPanMode || isEraserMode)) {
      e.preventDefault();
      isDrawing.current = true;
      const pos = getPointerPos(e);
      if (showPaintBrush && !isPanMode) {
        e.preventDefault();

        points.current.push({
          x: pos.x,
          y: pos.y,
        })

        line = {
          points: [],
          brushColor: brushColor,
          brushRadius: 2,
        };
        x.current = pos.x;
        y.current = pos.y;
        line.points.push({
          x: Math.round((((pos.x + (currentPageConf.displacement.x * canvaRef.current.width * currentPageConf.scale)) /
            currentPageConf.scale) / canvaRef.current.width) * currentImageDimension.current.width),
          y: Math.round((((pos.y + (currentPageConf.displacement.y * canvaRef.current.height * currentPageConf.scale)) /
            currentPageConf.scale) / canvaRef.current.height) * currentImageDimension.current.height),
        });
      } else if (showPaintBrush < 1 && isPanMode) {
        x.current = pos.x + (currentPageConf.displacement.x * canvaRef.current.width * currentPageConf.scale);
        y.current = pos.y + (currentPageConf.displacement.y * canvaRef.current.height * currentPageConf.scale);
        panMoves.current = [];
      }
    }
  }

  function drawMove(e) {
    e.preventDefault();
    const ctxDraw = canvaRefDraw.current.getContext('2d');
    if (isDrawing.current) {
      const pos = getPointerPos(e);
      if (showPaintBrush && !isPanMode) {
        const ctx = canvaRefDrawTemp.current.getContext('2d');
        points.current.push({
          x: pos.x,
          y: pos.y,
        });
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        drawPoints(ctx, points.current, brushColor, 2 * currentPageConf.scale);

        line.points.push({
          x: Math.round((((pos.x + (currentPageConf.displacement.x * canvaRef.current.width*currentPageConf.scale)) /
            currentPageConf.scale) / canvaRef.current.width) * currentImageDimension.current.width),
          y: Math.round((((pos.y + (currentPageConf.displacement.y * canvaRef.current.height*currentPageConf.scale)) /
            currentPageConf.scale) / canvaRef.current.height) * currentImageDimension.current.height),
        });
        line.type = "line";

        x.current = pos.x;
        y.current = pos.y;
      } else if (showPaintBrush < 1 && isPanMode) {
        let newTranslationObj = getXYboundary(currentPageConf.scale, canvaRef.current.width,
            canvaRef.current.height, x.current - pos.x, y.current - pos.y);
        const displacement = {
          x: (newTranslationObj.x/canvaRef.current.width)/currentPageConf.scale,
          y: (newTranslationObj.y/canvaRef.current.height)/currentPageConf.scale,
        };
        let temp = {
          ...currentPageConf,
          displacement,
        };
        panMoves.current.push(displacement);
        setCurrentPageConf(temp);
      } else if ( showPaintBrush < 1 && !isPanMode && isEraserMode) {
        const c1 = Math.round((((pos.x + (currentPageConf.displacement.x * canvaRef.current.width*currentPageConf.scale)) /
        currentPageConf.scale) / canvaRef.current.width) * currentImageDimension.current.width);
        const c2 = Math.round((((pos.y + (currentPageConf.displacement.y * canvaRef.current.height*currentPageConf.scale)) /
        currentPageConf.scale) / canvaRef.current.height) * currentImageDimension.current.height);

        line.points.push({x: c1, y: c2});
        line.eraserSize = eraserSize.w;
        line.type = "eraseLine";

        ctxDraw.clearRect(pos.x / currentPageConf.scale, pos.y / currentPageConf.scale, eraserSize.h, eraserSize.w);
      }
    }
  }

  let drawingSaveTimers = useRef();

  function endDrawing(e) {
    if (isDrawing.current && enableDrawing && openLiveDialog) {
      const pageParams = getPageParams();
      if (showPaintBrush && !isPanMode) {        
        e.preventDefault();
        const drawingCanvasCtx = canvaRefDraw.current.getContext('2d')
        const tempCtx = canvaRefDrawTemp.current.getContext('2d');
        drawingCanvasCtx.drawImage(canvaRefDrawTemp.current, 0, 0,
            canvaRefDraw.current.width/currentPageConf.scale, canvaRefDraw.current.height/currentPageConf.scale);
        tempCtx.clearRect(0, 0, tempCtx.canvas.width, tempCtx.canvas.height);
        points.current = []
        line.points = simplifyLine(line.points)
        line.key = `lines_${pdfInfo.current.filename}_${currentPage}_${params.articleId}`;
        sendSocketData('line', {line, filename: pdfInfo.current.filename, currentPage})

        console.log(`Page params on end of drawing`, pageParams);
        drawingsCache.current[pageParams] = drawingsCache.current[pageParams] || [];
        drawingsCache.current[pageParams].push(copy(line)); 
        linesCount.current = canvasLines.current.length;
      } else if (showPaintBrush < 1 && isPanMode) {
        const pos = getPointerPos(e);
        let newTranslationObj = getXYboundary(currentPageConf.scale, canvaRef.current.width,
            canvaRef.current.height, x.current - pos.x, y.current - pos.y);
        let displacement = {
          x: (newTranslationObj.x/canvaRef.current.width)/currentPageConf.scale,
          y: (newTranslationObj.y/canvaRef.current.height)/currentPageConf.scale,
        };
        panMoves.current.push(displacement);
        sendSocketData('viewPort', displacement);
        let temp = {
          ...currentPageConf,
          displacement,
        };
        saveConfigInsideAll(currentPageConf.scale, displacement);
        setCurrentPageConf(temp);
      } else if (showPaintBrush < 1 && !isPanMode && isEraserMode) {  
        line.key = `lines_${pdfInfo.current.filename}_${currentPage}_${params.articleId}`;
        sendSocketData('line', {line, filename: pdfInfo.current.filename, currentPage})
        drawingsCache.current[pageParams] = drawingsCache.current[pageParams] || [];
        drawingsCache.current[pageParams].push(copy(line)); 
      }
      isDrawing.current = false;
    }
  }

  function erasePoints(ctx, points, eraserSize) {    
    points.forEach(point => {            
      ctx.clearRect(point.x, point.y, eraserSize, eraserSize);
    })
  }

  function getXYboundary(scale, cw, ch, translationX, translationY) {
    let x = translationX;
    let y = translationY;
    const currentScalePercent = ((scale - 1) * 100);
    const boundayRight = (currentScalePercent * cw) / 100;
    const boundaryUp = (currentScalePercent * ch) / 100;

    if (boundayRight < x) {
      x = boundayRight;
    } else if (x < 0) {
      x = 0;
    }
    if (boundaryUp < y) {
      y = boundaryUp;
    } else if (y < 0) {
      y = 0;
    }

    return {x, y};
  }

  function filterIfNull(arr) {
    if (typeof arr === 'object' && arr.length) {
      arr.forEach((line) => {
        let newLinePoint = [];
        if (line.points && line.points.length === 0) {
          newLinePoint = [];
        } else if (line.points && line.points &&
          (
            (typeof line.points[0].x !== 'undefined' && line.points[0].x === null) ||
            (typeof line.points[0].y !== 'undefined' && line.points[0].y === null)
          )
        ) {
          console.log('stripping blank coordinate');
        } else {
          newLinePoint = line.points;
        }
        line.points = newLinePoint;
      });
      return arr;
    } else return [];
  }

  function midPointBtw(p1, p2) {
      return {
        x: p1.x + (p2.x - p1.x) / 2,
        y: p1.y + (p2.y - p1.y) / 2,
      };
    }

  function drawPoints(ctx, points, brushColor="black", brushRadius = 2) {
    if (points.length < 2) return true;
    ctx.lineJoin = "round";
    ctx.lineCap = "round";
    ctx.strokeStyle = brushColor;
    ctx.lineWidth = brushRadius / currentScale;
    let p1 = points[0];
    let p2 = points[1];
    ctx.moveTo(p2.x, p2.y);
    ctx.beginPath();
    for (var i = 1, len = points.length; i < len; i++) {
      var midPoint = midPointBtw(p1, p2);
      ctx.quadraticCurveTo(p1.x, p1.y, midPoint.x, midPoint.y);
      p1 = points[i];
      p2 = points[i + 1];
    }
    ctx.lineTo(p1.x, p1.y);
    ctx.stroke();
  }


  const drawAllLines = (lines, action = -1, counter = -1) => {
    const ctx = canvaRefDraw.current.getContext('2d');
    const ctxTemp = canvaRefDrawTemp.current.getContext('2d');
    ctxTemp.clearRect(0, 0, ctxTemp.canvas.width, ctxTemp.canvas.height);
    if(action === 1 && lines) {      
      const line = lines[counter];   
      if(line && line.points)  {
        drawPoints(ctx, line.points, line.brushColor, line.brushRadius);
      }
    } else if(action === 2 && lines) {      
      const line = lines[counter]; 
      if(line && line.points)  {
        erasePoints(ctx, line.points, line.eraserSize);
      }   
    }
  };

  const loadDrawingFromSaved = async () => {
    const p = getPageParams();
    const localDrawingCache = drawingsCache.current[p];    
    linesCount.current = 0;
    let lines = [];
    const ctx = canvaRefDraw.current.getContext('2d');
    const ctxTemp = canvaRefDrawTemp.current.getContext('2d');
    ctxTemp.clearRect(0, 0, ctxTemp.canvas.width, ctxTemp.canvas.height);

    if(localDrawingCache) {
      localDrawingCache.map(async (event) => {
        let pointList = event.points.map(p=>{
          return {
            x: (Math.round(p.x / currentImageDimension.current.width * canvaRef.current.width) -
              currentPageConf.displacement.x * canvaRef.current.width),
            y: (Math.round(p.y / currentImageDimension.current.height * canvaRef.current.height) -
              currentPageConf.displacement.y * canvaRef.current.height),
          };
        })
        if(event.type === "line"){
          lines.push(event);
          drawPoints(ctx, pointList, event.brushColor, event.brushRadius);
        } else {
          erasePoints(ctx, pointList, event.eraserSize);
        } 
      })
      linesCount.current = lines.length;
    }
  };

  useEffect(() => {
    var myCanva = document.getElementById('myCanva');
    if (typeof memCanvaRef.current === 'object' && memCanvaRef.current === null) {
      memCanvaRef.current = document.createElement('canvas')
    }
    if (myCanva && canvasImage) {
      loadImg(currentPageConf.scale, currentPageConf.displacement);
    }
  });

  const loadImg = (scaleLevel=1, displacement={x:0, y:0}) => {
    if (canvaRef.current) {
      const context = canvaRef.current.getContext('2d');
      context.setTransform(1, 0, 0, 1, 0, 0);

      const contextDraw = canvaRefDraw.current.getContext('2d');
      contextDraw.setTransform(1, 0, 0, 1, 0, 0);

      context.clearRect(0, 0, canvaRef.current.width, canvaRef.current.height);
      contextDraw.clearRect(0, 0, canvaRefDraw.current.width, canvaRefDraw.current.height);

      context.rect(0, 0, canvaRef.current.width, canvaRef.current.height);
      context.fillStyle = 'white';
      context.fill();

      context.scale(scaleLevel, scaleLevel);
      contextDraw.scale(scaleLevel, scaleLevel);

      contextDraw.drawImage(canvaRefDraw.current,
        0, 0, canvasImage.width, canvasImage.height,
        (0 - (displacement.x * canvaRefDraw.current.width)),
        (0 - (displacement.y * canvaRefDraw.current.height)),
        canvaRefDraw.current.width, canvaRefDraw.current.height,
      );

      context.drawImage(canvasImage,
        0, 0, canvasImage.width, canvasImage.height,
        (0 - (displacement.x * canvaRef.current.width)),
        (0 - (displacement.y * canvaRef.current.height)),
        canvaRef.current.width, canvaRef.current.height,
      );
      loadDrawingFromSaved();
      setEnableDrawing(true);
    }
  };

  const setDefaultConfForPageOfTheFile = (filename, pageNo, scale, displacementX, displacementY) => {
    let t = {
      "filename": filename,
      "pageNo": pageNo,
      "scale": scale||1,
      "displacement": {
          "x": displacementX||0,
          "y": displacementY||0,
      },
    };
    setCurrentPageConf(t);
    return t;
  };

  const setPageTimer = useRef();


  const setNewPage = (p, useApiForImage = false, allFileConfigFromApi={}) => {
    setEnableDrawing(false);
    let count = counter
    setCountrer(count++);
    const myCanva = canvaRef.current;
    const ctx = myCanva.getContext('2d');

    const myCanvaDraw = canvaRefDraw.current;
    const ctxDraw = myCanvaDraw.getContext('2d');

    const myCanvaDrawTemp = canvaRefDrawTemp.current;
    const ctxDrawTemp = myCanvaDrawTemp.getContext('2d');

    ctx.clearRect(0, 0, myCanva.width, myCanva.height);
    ctxDraw.clearRect(0, 0, myCanvaDraw.width, myCanvaDraw.height);

    const img = new Image();
    img.crossOrigin = "anonymous";
    canvasLines.current = [];
    img.onload = () => {
      try {
        const canvaswapper = document.getElementById('canvas_wrapper');
        const hRatio = canvaswapper.offsetWidth  / img.width;
        const vRatio =  canvaswapper.offsetHeight / img.height;
        const nRatio  = Math.min ( hRatio, vRatio );
        myCanva.width = img.width*nRatio;
        myCanva.height = img.height*nRatio;

        myCanvaDraw.width = img.width*nRatio;
        myCanvaDraw.height = img.height*nRatio;

        myCanvaDrawTemp.width = img.width*nRatio;
        myCanvaDrawTemp.height = img.height*nRatio;

        memCanvaRef.current.width = img.width*nRatio;
        memCanvaRef.current.height = img.height*nRatio;

        currentImageDimension.current = {
          width: img.width,
          height: img.height,
        };
        const filenName = pdfInfo.current.filename;
        const configs = Object.keys(allFileConfigFromApi).length ? allFileConfigFromApi : allFilesPagesConf;
        console.log("configs:::", configs, allFileConfigFromApi, allFilesPagesConf)
        if (configs && configs[filenName] && configs[filenName][p]) {
          setCurrentPageConf(configs[filenName][p]);
          sendSocketData('scaleCanvas', {scale: configs[filenName][p].scale, displacement: configs[filenName][p].displacement});
          sendSocketData('viewPort', configs[filenName][p].displacement);
        } else {
          setDefaultConfForPageOfTheFile(filenName, p);
        }
        setCanvasImage(img);
        sendSocketData('pdfInfo', pdfInfo.current);
        sendSocketData('currentPage', {p, fileName: pdfInfo.current.filename});
        
        setUpdatingPage(false);
        setUploadingFile(false);
        if(isMobile && counter === 2) {
          setNewPage(p, useApiForImage);
          setCountrer(0);
        }
      } catch (err) {
        console.log("err in img load", err);
      }

    };

    img.onerror = (e) => {
      console.log(`Error in loading image`, e);
      setPageTimer.current = setTimeout(setNewPage.bind(null, p, true), 1000);
    };
    img.src = (!isBlankPage && useApiForImage) ? `${process.env.REACT_APP_API_SERVER}/glen/${activeDomain}/broadcast/upload/${params.articleId}?pageNo=${p}` :
        `${pdfInfo.current.endpoint_dashboard}${p}.png`;
    setCurrentPage(p);
    currentPageRef.current = p;
  }

  const getCanvasHolderWidth = () => {
    const holder = document.getElementById("jj");
    return (holder && holder.clientWidth - 20) || 100;
  }

  const currentPageRef = useRef();
  const updatePageTimer = useRef();

  const updatePage = (i, noLoad) => {
    setEnableDrawing(false);
    if(!noLoad) setUpdatingPage(true);
    const newPage = currentPage + i;
    if (newPage <= 0 || newPage > totalPages) {
      setUpdatingPage(false);
      return;
    }
    clearTimeout(updatePageTimer.current)
    updatePageTimer.current = setTimeout(() => {
      setNewPage(newPage);
    }, 500);
    currentPageRef.current = newPage;
    setCurrentPage(newPage);
  };

  const copy = (obj) => {
    return JSON.parse(JSON.stringify(obj));
  }

  const clearPen = () => {
    const pageParams = getPageParams()    
    //drawingsCache.current[pageParams] =  {"draw": [], "erase": [], "moveStack": []};
    drawingsCache.current[pageParams] = [];
    //movesTracker.current = [];
    updatePage(0);
    sendSocketData('clearLines', true);
    document.getElementById('myCanvaDrawTemp').style.cursor = 'default'
  };

  const undoPen = () => {
    const pageParams = getPageParams()
    //let type;
    //let moveStack = drawingsCache.current[pageParams]["moveStack"] ? drawingsCache.current[pageParams]["moveStack"] : null;
    const len = drawingsCache.current[pageParams].length;
    drawingsCache.current[pageParams] = drawingsCache.current[pageParams].slice(0, len-1); 
    updatePage(0, true);
    sendSocketData('undoDraw', {
      line: drawingsCache.current[pageParams],                
      filename: pdfInfo.current.filename,
      currentPage,
    });
  };

  const sendSocketData = (type, data, config) => {
    if(feniWS.current) {
      feniWS.current.sendData(
        {
          "event":"whiteboardEvent",
          "type":"cast",
          "handler":"ardbeg",
          "payload": {type, data},
        }, config)
    }
  }

  const saveMetadata = (type , data) => {
    try {
      dispatch(setBroadcastMetadata(params.articleId, {type, data}));
    } catch (e) {
      console.error(e)
    }
  }

  const uploadFile = async (file) => {
    try {
      await dispatch(uploadBroadcastFile(params.articleId, file));
    } catch (e) {
      alert("Failed to upload document")
      console.error(e)
    }
  }

  const getPageParams = () => {
    if(pdfInfo.current) {
      return `page=${currentPageRef.current}&fileName=${pdfInfo.current.filename}`;
    }
    return ``
  }  

  let broadcastMetadata = {};
  const getMetadata = async (metaData={}, forceApi = false) => {    
    setGettingMetadata(true);
    try {
      if (metaData && Object.keys(metaData).length) {        
        broadcastMetadata = metaData;        
        // if(metaData.lineData.moveStack) {          
        //   movesTracker.current = metaData.lineData.moveStack;
        // }
      } else {
        if(forceApi) {
          broadcastMetadata = await dispatch(getBroadcastMetadata(params.articleId));
        } else {
          feniWS.current.sendData(
            {
              "event":"getBroadcastMetadata",
              "type":"cast",
              "handler":"ardbeg",
            })
        }
      }
      if (broadcastMetadata.pdfInfo) {
        broadcastMetadata.pdfInfo.totalPages = parseInt(broadcastMetadata.pdfInfo.totalPages);
        pdfInfo.current = broadcastMetadata.pdfInfo;
        setTotalPages(broadcastMetadata.pdfInfo.totalPages);
        let pageNo = 1;
        if (typeof broadcastMetadata.currentPage === 'number' && broadcastMetadata.currentPage > 1) {
          pageNo = broadcastMetadata.currentPage;
        } else if (typeof broadcastMetadata.currentPage === 'object' &&
          broadcastMetadata.currentPage !== null &&
          broadcastMetadata.currentPage.value &&
          +broadcastMetadata.currentPage.value > 1
        ) {
          pageNo = +broadcastMetadata.currentPage.value;
        }
        const fileName = (broadcastMetadata.pdfInfo||{}).filename;
        const currentPageNumber = broadcastMetadata.currentPage || 1;
        let allFilesConfig = {}
        allFilesConfig = (broadcastMetadata.currentPageMeta||{}).data
        setAllFilesPagesConf(allFilesConfig);
        if (allFilesConfig &&
          allFilesConfig[fileName] &&
          allFilesConfig[fileName][currentPageNumber]
        ) {
          setCurrentPageConf(allFilesConfig[fileName][currentPageNumber]);
        } else {
          setDefaultConfForPageOfTheFile((broadcastMetadata.pdfInfo||{}).filename, broadcastMetadata.currentPage || 1);
        }
        setNewPage(pageNo, false, allFilesConfig);
        setWhiteboardPdfCount(Object.keys(broadcastMetadata.pdfInfo.fileNameArr||{}).length);
        if(broadcastMetadata.pdfInfo && broadcastMetadata.pdfInfo.filename === '1px.png') {
          setIsBlankPage(true);
        } else {
          setIsBlankPage(false);
        }
      } else if (isDefaultUploaded === false && streamMode === 'whiteboard') {
        const defaultUpload = await dispatch(uploadDefaultFile(params.articleId));
        setIsDefaultUploaded(true);
        setIsBlankPage(true);
        getMetadata({}, true);
      }
    } catch (e) {
      console.error(e)
    }
    setGettingMetadata(false);
  }

  const handleCanvasResize = async (h, ignoreRepaint) => {
    var canvas = document.getElementById('myCanva');
    canvas.width = getCanvasHolderWidth();
  };

  useEffect(() => {
    audioAvailMap.current = {};
    audioCache.current = {};
    const chatPruner = setInterval(() => {
      if (chatChannel.current) {
        chatChannel.current.state.messages = chatChannel.current.state.messages.slice(-200);
      }
    }, 5000)
    let previousSize = null;
    const detector = setInterval(() => {
      if(pdfInfo.current && pdfInfo.current.filename)  {
        sendSocketData('currentPage', {p:currentPageRef.current, fileName: pdfInfo.current.filename});
        const pageParams = getPageParams();
        const drawings = drawingsCache.current && drawingsCache.current[pageParams] ? drawingsCache.current[pageParams] : []
        sendSocketData('lineSync',{line: drawings, currentPage: currentPageRef.current, filename:pdfInfo.current.filename},{"retry": true})
      }
      sendSocketData('pdfInfo', pdfInfo.current);
      sendSocketData('broadcastStatus', {video: isVideoEnabled.current, audio: isAudioEnabled.current});
      sendPoisonSocketData('audioAvail', audioAvailMap.current);
      const newSize = window.innerWidth +  window.innerHeight
      if (previousSize && previousSize !== newSize) {
        previousSize = newSize;
        handleCanvasResize();
      }
    }, 5000)
    return () => {
      clearInterval(detector);
      clearInterval(chatPruner);
    }
  }, [])

  const [inputSettingsAnchorEl, setInputSettingsAnchorEl] = React.useState(null);
  const handleInputSettingsClick = (event) => {
    setInputSettingsAnchorEl(event.currentTarget);
  };

  const handleInputSettingsClose = () => {
    setInputSettingsAnchorEl(null);
  };
  const inputSettingsOpen = Boolean(inputSettingsAnchorEl);


  const getLocalVideoHeight = () => {
    try {
      return (document.getElementById('right_chat_bar').offsetHeight - document.getElementById('localVideo').offsetHeight - 43);
    } catch (err) {
      return window.innerHeight*2;
    }
  }
 const getChat = ()=>{
  const chatHeight = `calc(100vh - ${menuHeight}px)`;
  return  <RootRef rootRef={chatContainerRef}><Grid item xs={12} md={streamMode !== 'whiteboard' ? 5: 3} id="right_chat_bar">
  <Box bgcolor="primary.main"  
  width={"100%"} height={isMobile?`${isSafari && orientation=='landscape'?chatHeight:'100%'}`:'auto'} maxWidth='inherit'
   position={isMobile ? isSafari && orientation =='landcape'?"unset":"absolute":streamMode !== 'whiteboard' ?"relative":"fixed"} 
   top={isMobile?0:65} bottom={isMobile?0:5}>
 { !isMobile && streamMode === 'whiteboard' && <Box boxShadow={3} mt={3} width={streamMode === 'whiteboard'? isMobile?'auto':'100%':'90%'}
  style={{visibility: ((isCreatingBroadcast || endingLiveStream) && 'hidden') || 'visible',
  display:'flex',justifyContent:'center',position:'relative',height: 'calc(100vh - 90%)'}}>
    <video id="localVideo" muted autoPlay playsInline width="90%"></video>
    {openLiveDialog && (streamMode === 'whiteboard') &&
      <Box bgcolor="red"p={1} color="white" borderRadius={4} position="absolute" left="2%" bottom="5%">Live</Box>}
  </Box>}
  {openLiveDialog && (streamMode === 'whiteboard') &&
    <Box 
    style ={{
      marginTop: `${isSafari && isMobile && orientation=='landscape' ?(
        menuHeight + 'px'
      ):isMobile ?0:'16px'}`,
    }}
    mb={isMobile?0:4} height={isMobile ?"100%": getLocalVideoHeight()}
    >
      <Box border={1} borderColor="primary" borderRadius="borderRadius" height={'100%'}>
        <ChatErrorBoundary onError={initChat}>
            {
              <PocChat   participantHandler = {(data)=> participantHandler(data)} articleId = {articleMeta.current.articleId} 
              headerComponent={() => {
                return <>
                  {isMobile && <CloseIcon onClick={() => setOpenChat(false)} className={classes.crossChatButton} />}
                  {!isMobile &&
                    <Box style={{ display: 'flex', justifyContent: 'flex-end', padding: '8px', position: 'relative' }}>
                      <Button
                        variant="contained" className={classes.squareButton} color="secondary" boxProps={{ ml: 2 }} >
                        {
                          !isMuted ?
                            <Unmute fontSize={"small"} onClick={toggleAudioBroadcast.bind(null, false)} /> :
                            <Mute fontSize={"small"} onClick={toggleAudioBroadcast.bind(null, false)} />
                        }
                      </Button>
                      <Button className={classes.squareButton} variant="contained" color="secondary" boxProps={{ ml: 2 }} >
                        {
                          !isVideoMuted ?
                            <UnmuteVideo fontSize={"small"} onClick={toggleVideoBroadcast.bind(null, false)} /> :
                            <MuteVideo fontSize={"small"} onClick={toggleVideoBroadcast.bind(null, false)} />
                        }
                      </Button>
                      <Button color="secondary"
                        className={classes.squareButton}
                        variant="contained"   boxProps={{ ml: 2 }} style={{ padding: '2px' }} onClick={() => setOpenMember(true)}
                      >
                        <GroupIcon />
                        {
                          chatMembers
                        }
                      </Button>
                      {
                        streamMode === 'whiteboard' &&
                        <Box style={{ marginLeft: '0.5em',color:'white',paddingTop:'0.1em' }}>
                        {audioAvailRequestCount.current > 0 && <><PanToolIcon />{audioAvailRequestCount.current}</>}
                        </Box>
                      }
                    </Box>
                  }
                </>
              }} />
            }
        </ChatErrorBoundary>
      </Box>
    </Box>
  }
  {
  isOpenMember &&
    <Box width={'inherit'} position= "absolute" zIndex= "99" height="100%"
    top= "0">
      {
        getMember()
      }
    </Box>

  }
  </Box>
  {getMemInfo()}
</Grid>
</RootRef>
 }

 const getMemInfo = () => {
  const memInfo = document.getElementById('str-chat__header-livestream-left--members');
  if(memInfo) {
    memInfo.style.display = 'none';
  }
 }
 const getMembers =()=>{

  return(

    <Box height={isMobile?"100vh":"100%"} id="users_online_list"
    style ={{
      marginTop: `${isSafari && isMobile && orientation=='landscape' ?(
        menuHeight + 'px'
      ):0}`,
    }}
    >
      <RootRef rootRef={memberContainerRef}>
        <Box bgcolor="#1b1b1b" height={57} display= "flex"
    justifyContent="space-between"
    alignItems ="center" >
          <Box display="flex" flexDirection="column" ml={2}>
            <Box component="span" color="white">
              Users
            </Box>
            <Box component="span" fontSize={12} color="white">
                {
                  chatMembers
                } online
            </Box>
          </Box>
          <CloseIcon fontSize="small" onClick={()=> setOpenMember(false)} style={{
            color:'white',
            marginRight:'12px',
          }}/>
        </Box>
        <Box bgcolor="black" color="white" minHeight={ isMobile?`calc(100vh - 107px)`:'unset'} display="flex"
        flexDirection="column" style={{ overflowY: 'auto', height: 'calc(100% - 57px)' }}>
          {usePoison.current && <Box color="white" mt={2} ml={2}>
            All Users
            {<Button className={classes.chatMicIcon} variant="contained" color="primary" onClick={() => updateGlobalAudioAvailMap(false)}>
              Mute All <MicOffIcon></MicOffIcon>
            </Button>}
          </Box>}
        {
         userList && Object.keys(userList).map((member,key)=>{
            return (<Box color="white" key={key} mt={2} ml={2}>
              {userList[member].UserName || userList[member].UserID}
              {usePoison.current && <>
                {streamMode === 'whiteboard' && activeAudioRequests.current[userList[member].UserID] && <>
                  <span style={{marginLeft: '4em'}}>
                    <PanToolIcon></PanToolIcon>
                  </span>
                </>}
              {audioAvailMap.current[userList[member].UserID] && <>
                <Button className={classes.chatMicIcon} variant="contained" color="primary" onClick={() => updateAudioAvailMap(userList[member], false)}>
                  <MicIcon></MicIcon>
                </Button>
              </>}
              {!audioAvailMap.current[userList[member].UserID] && <>
                <Button className={classes.chatMicIcon} variant="contained" color="primary" onClick={() => updateAudioAvailMap(userList[member], true)}>
                  <MicOffIcon></MicOffIcon>
                </Button>
              </>}
            </>}
            </Box>)
          })
        }
        </Box>
        </RootRef>
      </Box>
  )
 }

 const getMember = useCallback(getMembers, [chatMembers,userList, 
 nwayAudioCounter,
 (isSafari && menuHeight),
])

const reloadWhiteBoard = ()=>{
    dispatch(setAppMessage({
      leftButtonText:'No',
      rightButtonText:'Yes',
      handleSuccess: ()=>{window.location.reload()}, header: 'Refresh Page',message:'Would you like to refresh this page ?' }));
};

const togglePage = () => {  
  switchBlankPage(!isBlankPage);
  document.getElementById('myCanvaDrawTemp').style.cursor = 'default';
}
 const getHeader = (isLandscaping = orientation === 'landscape')=>{

   if(isLandscaping && !showHeader) return null;
  return <RootRef rootRef={headerRef}><Header 
  size="large"
  className={isLandscaping ? classes.landscapeHeader: classes.potraitHeader} style={
    isLandscaping && isSafari&& streamMode === 'whiteboard' ?{
      marginTop: menuHeight + 'px',
    }:(isLandscaping && streamMode !== 'whiteboard'?
    {background:'#101010'}:{})}
  >
    <Grid container justify="center" >
      {<Grid item xs={12} md={12}>
        <Box display="flex" justifyContent={"space-between"} ml={isMobile ? 0 : 25} mr={isMobile ? 0 : 25} >
          {isLandscaping && <Button className ={classes.squareButton}
            variant="contained" color="secondary" onClick={() => {setShowHeader(false)}}>
             <KeyboardArrowUpIcon />
          </Button>}

          {isMobile && (streamMode === 'whiteboard') && <Button style={{marginLeft: '1px'}}
              className ={classes.squareButton} variant="contained" color="secondary" size="small">
            <WithUploadButton
              isLive={true}
              accept="*"
              type="file"
              onChange={canvasOnFileChange}/>
          </Button>}

          {isMobile && (streamMode === 'whiteboard') && whiteboardPdfCount > 1 && <Button className ={classes.squareButton}
                variant="contained" color="secondary" style={{marginLeft: '2px'}} size="small">
            <WebAssetTwoToneIcon fontSize={"small"} alt="blankpage"
                onClick={() => togglePage()}
                style={{cursor:'pointer', backgroundColor: isBlankPage ? 'grey':'transparent', borderRadius:'5px'}}/>
          </Button>}
          <Box display="flex" alignItems="center" height="100%" ml={0.5}>
                {isMobile &&  streamMode === 'whiteboard' && <Button className ={classes.squareButton}
                 variant="contained" color="secondary" onClick={() => reloadWhiteBoard()}>
                   <ReplayIcon />
                </Button>}
          </Box>
          <Box display="flex" flexWrap="wrap" width={isMobile?'100%':'auto'} justifyContent={isMobile && !openLiveDialog?'space-between':'flex-start'}>
            {!openLiveDialog && !isGoingLive && !endingLiveStream &&
            <>
              <Box display="flex" alignItems="center" height="100%" ml='2px'>
                {!isLandscaping && <Button className ={classes.squareButton}
                 variant="contained" color="secondary" onClick={() => { stopBroadcast() && history.push("/dash/live/schedule") }}>
                   <CloseIcon />
                </Button>}
              </Box>
              <Box display="flex" style={isMobile ? {width: '100px'} : {}}
                  alignItems="center" height="100%" className={`${!isLandscaping ? classes.headerSelectorHolder: null}`} ml={isMobile?0:3}>

                {!isLandscaping && <FormControl style={{ width: '100%' }}>
                  <Select
                    labelId="video-source-label"
                    value={streamMode}
                    variant="outlined"
                    size="small"
                    style={ isMobile ? {fontSize: '10px'} : {}}
                    onChange={(e) => switchStreamMode(e.target.value)}
                  >
                    {
                      [{text: 'Camera', key: 'webcam'}, {text: 'Whiteboard', key: 'whiteboard'}, {
                        text: 'Software',
                        key: 'rtmp',
                      }]
                        .map((option, key) =>
                          <MenuItem key={key} value={option.key}>{option.text}</MenuItem>,
                        )
                    }

                  </Select>
                </FormControl>}
              </Box>
            </>
            }
            {(streamMode === 'rtmp') &&
              <Box display="flex" alignItems="center" height="100%">
              </Box>
            }



            {openLiveDialog &&
              <Box display="flex" alignItems="center" height="100%">
              </Box>
            }
          </Box>
          <Box  display="flex" justifyContent="flex-end">

            {
              !openLiveDialog && (!resumeStream && !isVideoProgress &&
                ((streamMode !== 'rtmp' && isSocketConnected) ||
                  ((streamMode === 'rtmp') && serverURL))) &&
              <Box width={"100%"} display="flex" justifyContent="flex-end" ml={1} mr={1}>
                <Button color="secondary"
                        size="small"
                        style={{fontSize: '10px', width: '64px'}}
                        variant="contained" className={classes.redButton}
                        onClick={()=>{setIsShowLive(false);setShowDailog(true); setShowHeader(false)}}>Go LIVE</Button>
                {goToNextPage && <CircularLoader size={20} height="auto" style={{ marginLeft: '8px' }} />}
              </Box>
            }
            {(streamMode !== 'rtmp') &&
              <Box display="flex" alignItems="center" height="100%" ml={isMobile?0:3}>
                <Button className ={classes.squareButton} style={{marginLeft :`${orientation === 'landscape' ? '12px': '0px'}`}}
                variant="contained" color="secondary"
                        onClick={handleInputSettingsClick}><TocIcon/></Button>
                <Popover
                  open={inputSettingsOpen}
                  anchorEl={inputSettingsAnchorEl}
                  onClose={handleInputSettingsClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Box mt={5} mb={5} pl={5} pr={5}>

                    <Box width={isMobile ? '70%' : '90%'}>

                      <FormControl className={classes.formControl}>
                        <Box mr={4}> <VideocamIcon/> </Box>
                        <Select
                          labelId="video-source-label"
                          id="video-source"
                          classes={{outlined: classes.paddingControl}}
                          value={defaultVideoSource}
                          style={{textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "pre"}}
                          variant="outlined"
                          size="small"
                          onChange={changeVideoCaptureDevice}
                        >
                          {
                            availableVideoSource.map((option, key) => <MenuItem
                             key={key}
                             style={{marginTop:`${isSafari && key==0 && isLandscaping ? menuHeight +'px':
                             0}`}}                                                   
                             value={option.text}>{
                                option.text
                              }</MenuItem>,
                            )
                          }

                        </Select>
                      </FormControl>

                    </Box>

                    <Box mt={5} width={isMobile ? '70%' : '90%'}>
                      <FormControl className={classes.formControl}>
                        <Box mr={4}> <MicIcon/> </Box>
                        {/* <InputLabel id="audio-source-label">Select audio source</InputLabel> */}
                        <Select
                          labelId="audio-source-label"
                          id="audio-source"
                          style={{textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "pre"}}
                          classes={{outlined: classes.paddingControl}}
                          size="small"
                          onChange={changeAudioCaptureDevice}
                          value={defaultAudioSource}
                          variant="outlined"
                        >
                          {
                            availableAudioSource.map((option, key) => <MenuItem key={key}
                                                                                value={option.text}>{
                                option.text
                              }</MenuItem>,
                            )
                          }

                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                </Popover>
              </Box>
            }
            {
              <Box display="flex" alignItems="center" ml={(isMobile && !openLiveDialog) ?0:3} >
                {
                  streamMode === 'rtmp'  && openLiveDialog&& <Button boxProps={{mr:2}}
                  onClick={()=>{
                    setShowRTMPInfo(!showRTMPInfo);
                  }}
                   className={classes.squareButton}
                   variant="contained"
                   color="secondary">

                      <SettingsIcon />

                  </Button>
                }
                {openLiveDialog && shareUrl &&
                <Button  className ={classes.squareButton}
                  color="secondary" variant="contained" onClick={(e)=>setOpenShareAnchorlEl(e.currentTarget)}>
                    <ShareIcon />
                </Button>
                 }
                <Popover
                  open={Boolean(openShareAnchorlEl)}
                  anchorEl={openShareAnchorlEl}
                  onClose={(e)=>setOpenShareAnchorlEl(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Box  border={1} m={2} borderColor="primary" borderRadius="borderRadius"
                       style={{padding: '0.5em'}}>
                    <Grid container>
                      <Grid item xs={9} style={{margin: 'auto',wordBreak:'break-all'}}>
                        {shareUrl}
                      </Grid>
                      <Grid item xs={3}>
                        <Button style={{marginLeft: '1em'}} variant="contained"
                                color="primary" size="small" onClick={() => copyToClipboard('liveUrl')}>
                          Copy
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Popover>
              </Box>
            }
            {
              openLiveDialog &&
              <Box width={"100%"} display="flex" justifyContent="flex-end" ml={2} mr={2}>
                <Button color="secondary" variant="contained"
                  className={classes.redButton} onClick={closeLiveVideo}>END</Button>
              </Box>
            }
          </Box>
        </Box>
      </Grid>
      }
    </Grid>
  </Header>
  </RootRef>
 }

 function getEraseimage(eraseType){
  if(eraseType) {
    let eraserType = eraseType.toLowerCase()
    switch (eraserType) {
      case 'small':      
        return EraserButtonSmall;           
      case 'medium':
        return EraserButtonMedium;      
      case 'large':
        return EraserButtonLarge;            
      default:
        break;
    }  
  }
}

function getEraseimageForDropDown(eraseType){
  if(eraseType) {
    let eraserType = eraseType.toLowerCase()
    switch (eraserType) {
      case 'small':      
        return DropDownSmall;           
      case 'medium':
        return DropDownMedium;      
      case 'large':
        return DropDownLarge;            
      default:
        break;
    }  
  }
}


function getRTMPInfo(){

  return  <Card style={{
            zIndex:'9999',
            position:'absolute',
            top:'50px',
            right:`${openLiveDialog ?'30px':'unset'}`,
            left:`${!openLiveDialog ?'0%':'unset'}`,
            margin: '8px',
            }} elevation={3}>
         <Box p={4}>
           {/* {openLiveDialog &&
              <Box mt={2} border={1} borderColor="primary" borderRadius="borderRadius" style={{padding: '0.5em'}}>
                <Grid container>
                  <Grid item xs={9} style={{margin: 'auto'}}>
                    {shareUrl}
                  </Grid>
                  <Grid item xs={3}>
                    <Button style={{ marginLeft: '1em' }} variant="contained" color="primary" size="small" onClick={() => copyToClipboard('liveUrl')} >
                      Copy
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              } */}
            <Box component="span" fontSize={12}>
              Copy and paste these settings into your streaming software.
            </Box>
            <Box mt={4} display="flex" flexDirection="column">
              <Box component="span"> Server URL</Box>
              <Box display="flex" >
                <RootRef ref={firstinput}>
                  <Input
                    color="secondary"
                    placeholder=""
                    size="small"
                    style={{ width: '80%'}}
                    value={serverURL} onChange={(e) => setServerURL(e.currentTarget.value)} >
                  </Input>
                </RootRef>
                <Tooltip
                  open={openFirsTootlTip}
                  placement="right-end"
                  title="Copied!"
                  onClose={()=>{
                    setTimeout(()=>{
                      setOpenFirstToolTip(false)
                    },2000)
                  }}
                  >
                <Button variant="contained" color="primary" size="small"  onClick={() => {
                  copyToClipboard('firstinput')
                  setOpenFirstToolTip(true)
                  }} >Copy</Button>
                  </Tooltip>
              </Box>
            </Box>
              <Box mt={4} display="flex" flexDirection="column">
                <Box component="span">Stream Key</Box>
                <Box display="flex">
                  <Input
                    ref={secondinput}
                    color="secondary"
                    placeholder=""
                    style={{ width: '80%' }}
                    size="small"
                  value={streamKey} onChange={(e) => setStreamKey(e.currentTarget.value)} >
                  </Input>
                  <Tooltip
                  open={openSecondTootlTip}
                  placement="right-end"
                  title="Copied!"
                  onClose={()=>{
                    setTimeout(()=>{
                      setOpenSecondToolTip(false)
                    },2000)
                  }}
                  >
                  <Button variant="contained" color="primary"  size="small" onClick={() => {
                    copyToClipboard('secondinput')
                    setOpenSecondToolTip(true)
                    }
                    } >Copy</Button>
                  </Tooltip>
                </Box>
              </Box>
          </Box>
  </Card>
}

const getHeight  = ()=>{
  if (isMobile && isSafari && orientation=='landscape'){
    return `calc(100vh - ${menuHeight}px)`;
  }
  if (isMobile && orientation =='landscape') {
    return '100vh';
  }
  return "90%";
}

const getParentHeight = ()=> {
  if(isMobile && isSafari && orientation=='landscape'){
    return `calc(100vh - ${menuHeight}px)`;
  }
  if(isMobile && orientation=='landscape'){
     return '100vh';
  }
  if (isMobile){
    return 'calc(100vh - 100px)';
  }
  return `calc(100vh - 60px)`;
}

  return (
    <>
      <Dialog fullScreen open={true} style={{overscrollBehavior: 'contain'}}>
       <RootRef rootRef={containerTargetRef}>
        <Grid container>
          { (streamMode === 'rtmp') && !openLiveDialog && serverURL ?
           getHeader(false)
          :
          getHeader()}
          <Grid container justify="center"
          style={streamMode==="webcam" && isMobile ?orientation==='landscape'?{height:'100vh'}:{height:`calc(100vh - 50px)`}:{}}>
            {
               (streamMode === 'rtmp') && !openLiveDialog &&
               <Box display="flex" justifyContent="center">
                  {serverURL && getRTMPInfo()}
                </Box>
            }
            {(streamMode === 'rtmp') &&
            <Grid item xs={12} md={5}
                  style={{ paddingLeft: '0.5em', paddingRight: '0.5em'}}>

              {
                (streamMode === 'rtmp') &&
                  <>
                    { !serverURL &&
                      <Box>Please send an email to &nbsp;
                        <a href="mailto:support@pubninja.com">support@pubninja.com</a>&nbsp;
                        to start a live video using streaming softwares like OBS.
                      </Box>
                    }
                  </>
              }

            </Grid>
            }
            {
              (streamMode === 'rtmp') && !openLiveDialog &&
                <Grid item xs={12} md={5} >

                </Grid>
            }

            {/**-----------------------------------------------Live streaming sections----------------------------------- */}
            <Dialog
              open={permissionLoading}
              maxWidth="xs">
              <DialogContent>
                <CircularLoader size={20} color="secondary" height="auto" message="loading...." style={{ margin: 'auto' }} />
              </DialogContent>
            </Dialog>
            {(streamMode === 'whiteboard') && !endingLiveStream &&

            <Grid item md={7} xs={12} style={{ paddingLeft: `${isMobile?'0em':'0.5em'}`, paddingRight: `${isMobile?'0em':'0.5em'}` ,position:'relative',
            height: `${getParentHeight()}`}}>
              {/* {(isGoingLive || endingLiveStream) && <CircularLoader size={20}
              color="secondary" height="auto" style={{ margin: 'auto', marginTop: '2em' }} />} */}
              <div
                ref = {targetRef}
                id="canvas-holder" 
                style={{
                    height: `${getHeight()}`,
                    marginTop : `${isMobile && isSafari && orientation=='landscape' ?
                    menuHeight + 'px'
                    :'0px'}`,
                    marginLeft: `${isMobile ?'0px':'16px'}`,
                    marginRight: `${isMobile ?'0px':'16px'}`,
                  }}
                className={classes.canvasHolderContainer}
              >
                <Box width="100%" height="100%" pt={isMobile?0:2}>
                {orientation !== 'landscape' &&
                <><Box  id="controal_bar" display="flex" className={classes.borderStyle} justifyContent="space-between" 
                alignItems="center" >
                  <Box style={{position:'relative'}}>
                  {!isMobile && <Button className ={classes.squareButton}
                                    variant="contained" color="secondary">
                    <WithUploadButton
                      isLive={true}
                      accept="*"
                      type="file"
                      onChange={canvasOnFileChange}/>

                  </Button>}
                  <Button className ={classes.squareButton}
                                    variant="contained" color="secondary" style={{marginLeft:'5px'}}>
                    <BrushIcon fontSize={"small"} onClick={()=>(togglePaintBrush())}
                    style={{cursor:'pointer', background: showPaintBrush === 1 ? 'grey':'transparent', borderRadius:'5px'}}/>
                  </Button>
                  <Button className ={classes.squareButton}
                                    variant="contained" color="secondary" style={{marginLeft:'5px'}}>
                    <ColorSelectButton fontSize={"small"} onClick={()=>(setShowBrush(!isShowBrush))}
                    style={{color: brushColor,cursor:'pointer'}}/>
                  </Button>
                  <Button className ={classes.squareButton}
                                    variant="contained" color="secondary" style={{marginLeft:'5px'}}>
                    <UndoIcon fontSize={"small"} onClick={()=>(undoPen())} style={{cursor:'pointer'}}/>
                  </Button>
                  <Button className ={classes.squareButton}
                                    variant="contained" color="secondary" style={{marginLeft:'5px'}}>
                    <DeleteSweepRoundedIcon fontSize={"small"} onClick={() => clearPen()}/>
                  </Button>
                  <Button className ={classes.squareButton}
                                    variant="contained" color="secondary" style={{marginLeft:'5px'}}>
                    <ZoomInRoundedIcon fontSize={"small"} onClick={onChangeScale.bind(null, 0.1)}/>
                  </Button>
                  <Button className ={classes.squareButton}
                                    variant="contained" color="secondary" style={{marginLeft:'5px'}}>
                    <ZoomOutRoundedIcon fontSize={"small"} onClick={onChangeScale.bind(null, -0.1)}/>
                  </Button>
                  <Button className ={classes.squareButton}
                                    variant="contained" color="secondary" style={{marginLeft:'5px'}}>
                    <PanToolTwoToneIcon fontSize={"small"} onClick={onChangePan}
                      style={{cursor:'pointer', background: isPanMode ? 'grey':'transparent', borderRadius:'5px'}}/>
                  </Button>

                  {!isMobile && whiteboardPdfCount > 1 && <Button className ={classes.squareButton}
                                    variant="contained" color="secondary" style={{marginLeft:'10px'}}>
                      <WebAssetTwoToneIcon fontSize={"small"} alt="blankpage"
                    onClick={() => togglePage()}
                    style={{cursor:'pointer', backgroundColor: isBlankPage ? 'grey':'transparent', borderRadius:'5px'}}/>
                  </Button>}

                <Button className ={classes.squareButton}
                  variant="contained" color="secondary" onClick={()=>setShowEraser(!isShowEraser)} style={{marginLeft:'5px',position:'relative'}}>
                    <img src={EraserButton} alt="button"
                    style={{cursor:'pointer', fill: isShowEraser ? 'grey':'transparent', borderRadius:'5px'}}
                    />
                  </Button>
                {
                  isShowEraser && <Card style={{background:'white',
                  position:'absolute',right:0,marginTop:'5px',
                  zIndex:'9999', padding:' 0.3em'}} elevation={3}>
                      {
                      [
                        {text: 'Small', key: {h: 10, w: 10, text: 'small'}},
                        {text: 'Medium', key: {h: 15, w: 15, text: 'medium'}},
                        {text: 'Large', key: {h: 20, w: 20, text: 'large'}},
                      ]
                      .map((option, key) =>
                        <Box key={key}
                        p={2}

                        style={{borderBottom:`${option.text === 'Large' ?'none':'1px solid grey'}`,cursor:'pointer'}}
                        onClick={()=>{
                          selectEraserSize(option.key)
                          setShowEraser(!isShowEraser)
                        }}><><img 
                        src={getEraseimageForDropDown(option.text)} alt="small"/>{!isMobile && option.text}</></Box>,
                      )
                    }
                  </Card>
                }
                  </Box>
                  {!isBlankPage && pdfInfo.current && pdfInfo.current.totalPages && pdfInfo.current.totalPages > 1 &&
                    <Box>
                      <FormControl className={classes.formControl}>
                        {currentPage && currentPage > 1 && <Button style={{marginRight:'2px'}}
                        className ={classes.squareButton} variant="outlined" color="primary" size="small" onClick={() => updatePage(-1)}>
                        <ArrowBackIcon fontSize={"small"} />
                        </Button>}
                        {!isMobile && <Select
                          value={currentPage||1}
                          autoWidth
                          size="small"
                          onChange={(e) => {updatePage(e.target.value - currentPage)}}
                        >
                        {
                          [...Array(pdfInfo.current.totalPages).keys()].map((option, key) => <MenuItem value={option + 1} key={key}>{
                              option + 1
                            }</MenuItem>,
                          )
                        }
                        </Select>}
                        {currentPage && currentPage < pdfInfo.current.totalPages && <Button style={{marginLeft:'2px'}}
                        className ={classes.squareButton} variant="outlined" color="primary" size="small" onClick={() => updatePage(1)}>
                        <ArrowForwardIcon fontSize={"small"} />
                        </Button>}
                      </FormControl>
                    </Box>
                  }
                </Box>
                <Box style={{margin: 'auto', marginTop: '1em'}} display="block" id="jj">
                  { isShowBrush &&
                  <Card style={{background:'white',position:'absolute',left:'1em',zIndex:'9999', padding:' 0.3em'}}>
                    {
                      [
                        'Red',
                        'Blue',
                        'Black',
                        'White',
                        'Orange',
                      ].map((val, index) => {
                        return (<div key={index} onClick={() => {setBrushColor(val); setShowBrush(!isShowBrush)}}
                                     style={{backgroundColor: val,
                                       borderWidth: '0.5px',
                                       borderColor: 'black',
                                       display: 'inline',
                                       height: '1em',
                                       width: '1em',
                                       border: 'solid',
                                       marginLeft: '1em'}}>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                        </div>)
                      })
                    }
                  </Card>
                  }
                </Box></>}
                  {
                  <Dialog
                      open={uploadingFile || (isGoingLive || endingLiveStream) || switchingBlankPage || updatingPage}
                      maxWidth="xs">
                    <DialogContent>
                      <CircularLoader size={20} color="secondary" height="auto" message="loading...." style={{ margin: 'auto' }} />
                    </DialogContent>
                  </Dialog>}
                  {orientation !== 'landscape' ?
                    <Box id="canvas_wrapper"
                    style={{
                      width: '100%',
                      height: isMobile ? window.innerHeight*0.7 : (window.innerHeight-140),
                      backgroundColor:'lightgrey',
                      touchAction: 'none',
                      display:'block',
                      position:'relative'}}>
                      <canvas id='myCanva' ref={canvaRef}
                      width={(window.innerWidth)}
                      style={{position:'absolute', top:'0px', bottom:'0px', right:'0px', left:'0px', margin:'auto', touchAction: 'none'}}
                      onTouchStart={startDrawing}
                      onTouchMove={drawMove}
                      onTouchEnd={endDrawing}
                      onTouchCancel={endDrawing}
                      onMouseDown={startDrawing}
                      onMouseMove={drawMove}
                      onMouseUp={endDrawing}
                      onMouseOut={endDrawing}
                      height= {window.innerHeight*0.7}>
                      </canvas>
                      <canvas id='myCanvaDraw' ref={canvaRefDraw}
                      width={(window.innerWidth)}
                      style={{position:'absolute', top:'0px', bottom:'0px', right:'0px', left:'0px', margin:'auto', touchAction: 'none'}}
                      onTouchStart={startDrawing}
                      onTouchMove={drawMove}
                      onTouchEnd={endDrawing}
                      onTouchCancel={endDrawing}
                      onMouseDown={startDrawing}
                      onMouseMove={drawMove}
                      onMouseUp={endDrawing}
                      onMouseOut={endDrawing}
                      height= {window.innerHeight*0.7}>
                      </canvas>
                      <canvas id='myCanvaDrawTemp' ref={canvaRefDrawTemp}
                      width={(window.innerWidth)}
                      style={{position:'absolute', top:'0px', bottom:'0px', right:'0px', left:'0px', margin:'auto', touchAction: 'none'}}
                      onTouchStart={startDrawing}
                      onTouchMove={drawMove}
                      onTouchEnd={endDrawing}
                      onTouchCancel={endDrawing}
                      onMouseDown={startDrawing}
                      onMouseMove={drawMove}
                      onMouseUp={endDrawing}
                      onMouseOut={endDrawing}
                      height= {window.innerHeight*0.7}>
                      </canvas>
                      </Box>
                      :
                      <>
                      <Grid container >
                      <Grid item xs={1} style={{display:'block', height:'98vh', overflow:'scroll'}}>
                          <List style={{paddingTop: window.innerHeight < 361  ? '4px' : '8px'}}>
                              <ListItem button alignItems="center" style={{justifyContent:'center', padding:window.innerHeight < 361  ? '2px' : '4px'}}>
                                <Button className ={classes.squareButton}
                                    variant="contained" color="secondary" onClick={() => {setShowHeader(true)}}>
                                      <KeyboardArrowDownIcon fontSize={"small"} />
                                  </Button>
                              </ListItem>

                              <ListItem button alignItems="center" style={{justifyContent:'center', padding:window.innerHeight < 361 ? '2px' : '4px'}}>
                                <Button className ={classes.squareButton}
                                    variant="contained" color="secondary">
                                      <BrushIcon fontSize={"small"} onClick={()=>{togglePaintBrush()                                    
                                    }}
                                          style={{cursor:'pointer', background: showPaintBrush === 1 ? 'grey':'transparent', borderRadius:'5px'}}/>
                                </Button>
                              </ListItem>
                              <ListItem button alignItems="center" style={{justifyContent:'center', padding:window.innerHeight < 361  ? '2px' : '4px'}}>
                                <Button className ={classes.squareButton}
                                    variant="contained" color="secondary">
                                  <ColorSelectButton fontSize={"small"} onClick={()=>(setShowBrush(!isShowBrush))}
                                      style={{color: brushColor, cursor:'pointer'}}/>
                                </Button>
                                { isShowBrush &&
                                  <Card style={{background:'white',position:'fixed',left:'7%',top:'10%',zIndex:'9999', padding:' 0.3em'}}>
                                    {
                                      [
                                        'Red',
                                        'Blue',
                                        'Black',
                                        'White',
                                        'Orange',
                                      ].map((val, index) => {
                                        return (<div key={index} onClick={(e) => {
                                          e.stopPropagation();
                                          setBrushColor(val);}}
                                                    style={{
                                                      backgroundColor: val,
                                                      border: '4px solid lightgrey',
                                                      display: 'block',
                                                      height: '1em',
                                                      width: '1em',
                                                      marginLeft: '0.5em'}}>
                                          &nbsp;&nbsp;&nbsp;&nbsp;
                                        </div>)
                                      })
                                    }
                                  </Card>
                                }
                              </ListItem>
                              <ListItem button alignItems="center" style={{justifyContent:'center',padding:window.innerHeight < 361  ? '2px' : '4px'}}>
                                  <Button className ={classes.squareButton}
                                    variant="contained" color="secondary" onClick={()=>{
                                      setShowPaintBrush(0);
                                      setShowEraser(!isShowEraser)}}>
                                      <img src={EraserButton} alt="button"
                                      style={{cursor:'pointer', background: isShowEraser ? 'grey':'transparent', borderRadius:'5px'}}
                                      />
                                    </Button>
                                  {
                                    isShowEraser && <Card style={{background:'white',
                                    position:'fixed',left:'4%',zIndex:'9999', 
                                    padding:' 0.3em', marginLeft:'30px'}} elevation={4}>

                                        {
                                        [
                                          {text: 'Small', key: {h: 10, w: 10, text: 'small'}},
                                          {text: 'Medium', key: {h: 15, w: 15, text: 'medium'}},
                                          {text: 'Large', key: {h: 20, w: 20, text: 'large'}},
                                        ]
                                        .map((option, key) =>
                                          <Box key={key}
                                          value={option.key} 
                                          onClick={()=>{
                                            selectEraserSize(option.key)
                                            setShowEraser(!isShowEraser)
                                          }}><><img 
                                          src={getEraseimage(option.text)} alt="small"/>{!isMobile && option.text}</></Box>,
                                        )
                                      }
                                    </Card>
                                  }
                              </ListItem>
                              <ListItem button alignItems="center" style={{justifyContent:'center',padding:window.innerHeight < 361  ? '3px' : '4px'}}>
                                  <Button className ={classes.squareButton}
                                    variant="contained" color="secondary">
                                  <UndoIcon fontSize={"small"} onClick={()=>(undoPen())} style={{cursor:'pointer'}}/>
                                  </Button>
                              </ListItem>
                              <ListItem button alignItems="center" style={{justifyContent:'center',padding:window.innerHeight < 361  ? '2px' : '4px'}}>
                                <Button className ={classes.squareButton}
                                    variant="contained" color="secondary">
                                  <DeleteSweepRoundedIcon fontSize={"small"} onClick={() => clearPen()}/>
                                </Button>
                              </ListItem>
                              <ListItem style={{justifyContent:'center', padding:window.innerHeight < 361  ? '2px' : '4px'}}>
                                <Button className ={classes.squareButton}
                                    variant="contained" color="secondary">
                                  <ZoomInRoundedIcon fontSize={"small"} onClick={onChangeScale.bind(null, 0.1)}/>
                                </Button>
                              </ListItem>
                              <ListItem style={{justifyContent:'center', padding:window.innerHeight < 361  ? '2px' : '4px'}}>
                                <Button className ={classes.squareButton}
                                                  variant="contained" color="secondary">
                                  <ZoomOutRoundedIcon fontSize={"small"} onClick={onChangeScale.bind(null, -0.1)}/>
                                </Button>
                              </ListItem>
                              <ListItem style={{justifyContent:'center', padding:window.innerHeight < 361  ? '2px' : '4px'}}>
                                <Button className ={classes.squareButton}
                                    variant="contained" color="secondary" >
                                  <PanToolTwoToneIcon fontSize={"small"} onClick={onChangePan}
                                    style={{cursor:'pointer', background: isPanMode ? 'grey':'transparent', borderRadius:'5px'}}/>
                                </Button>
                              </ListItem>
                              {!isBlankPage && pdfInfo.current && <>
                              <ListItem style={{justifyContent:'center', padding:window.innerHeight < 361  ? '2px' : '4px'}}>
                              {currentPage && currentPage > 1 && <Button className ={classes.squareButton}
                                    variant="contained" color="secondary" size="small" onClick={() => updatePage(-1)}>
                              <ArrowBackIcon fontSize={"small"}/>
                              </Button>}
                              </ListItem>
                              <ListItem style={{justifyContent:'center', padding:window.innerHeight < 361  ? '2px' : '4px'}}>
                              {currentPage && currentPage < pdfInfo.current.totalPages && <Button className ={classes.squareButton}
                                    variant="contained" color="secondary" size="small" onClick={() => updatePage(1)}>
                              <ArrowForwardIcon fontSize={"small"}/>
                              </Button>}
                              </ListItem></>}
                          </List>
                      </Grid>
                      <Grid item xs={1} >
                        {openLiveDialog && <Box bgcolor="red" color="white" p={2} borderRadius={2} mr={2}
                        style={{width:'25px', height:'10px', display:'flex',
                          position:'relative',
                          top:window.innerHeight < 361 ? '70%':'80%',
                          alignItems:'center', justifyContent:'center'}}>
                          Live
                        </Box>}
                      </Grid>
                      <Grid item xs={8} style={{touchAction:'none'}}>
                      <Box display="block" id="jj">
                          <Box id="canvas_wrapper"
                          style={{width:"99%",
                          height:(window.innerHeight-10), display:'block', position:'relative', backgroundColor:'lightgrey'}}>
                          <canvas id='myCanva'
                          style={{position:'absolute',
                          top:'0px', bottom:'0px',
                          right:'0px', left:'0px', margin:'auto', touchAction: 'none'}}
                          ref={canvaRef}
                          width='inherit'
                          onTouchStart={startDrawing}
                          onTouchMove={drawMove}
                          onTouchEnd={endDrawing}
                          onTouchCancel={endDrawing}
                          onMouseDown={startDrawing}
                          onMouseMove={drawMove}
                          onMouseUp={endDrawing}
                          onMouseOut={endDrawing}
                          height='inherit'>
                          </canvas>
                          <canvas id='myCanvaDraw'
                          style={{
                            position:'absolute',
                            top:'0px', bottom:'0px',
                            right:'0px', left:'0px', margin:'auto', touchAction: 'none',
                          }}
                          ref={canvaRefDraw}
                          width='inherit'
                          onTouchStart={startDrawing}
                          onTouchMove={drawMove}
                          onTouchEnd={endDrawing}
                          onTouchCancel={endDrawing}
                          onMouseDown={startDrawing}
                          onMouseMove={drawMove}
                          onMouseUp={endDrawing}
                          onMouseOut={endDrawing}
                          height='inherit'>
                          </canvas>
                          <canvas id='myCanvaDrawTemp'
                          style={{position:'absolute',
                          top:'0px', bottom:'0px',
                          right:'0px', left:'0px', margin:'auto', touchAction: 'none'}}
                          ref={canvaRefDrawTemp}
                          width='inherit'
                          onTouchStart={startDrawing}
                          onTouchMove={drawMove}
                          onTouchEnd={endDrawing}
                          onTouchCancel={endDrawing}
                          onMouseDown={startDrawing}
                          onMouseMove={drawMove}
                          onMouseUp={endDrawing}
                          onMouseOut={endDrawing}
                          height='inherit'>
                          </canvas>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={2} id="landscape_right_bar" style={{touchAction:'none',height:'98vh',maxHeight:'98vh'}}>
                        <List style={{marginTop:'120px'}}>
                        <ListItem  style={{justifyContent:'center', padding:window.innerHeight < 361  ? '4px' : '4px'}}>
                          <Button className ={classes.squareButton}
                                    variant="contained" color="secondary" size="small" onClick={()=>setOpenChat(true)}>
                            <ChatIcon/>
                          </Button>
                        </ListItem>
                        <ListItem style={{justifyContent:'center', padding:window.innerHeight < 361  ? '4px' : '8px'}}>
                          <Button className ={classes.squareButton}
                                    variant="contained" color="secondary" size="small" >
                            { !isMuted ? <Unmute fontSize={"small"} onClick={toggleAudioBroadcast.bind(null, false)}/> :
                            <Mute fontSize={"small"} onClick={toggleAudioBroadcast.bind(null, false)}/>}
                          </Button>
                        </ListItem>
                        <ListItem style={{justifyContent:'center', padding:window.innerHeight < 361  ? '4px' : '8px'}}>
                            <Button className ={classes.squareButton}
                                    variant="contained" color="secondary" size="small">
                          { !isVideoMuted ? <UnmuteVideo fontSize={"small"} onClick={toggleVideoBroadcast.bind(null, false)}/> :
                          <MuteVideo fontSize={"small"} onClick={toggleVideoBroadcast.bind(null, false)}/>}
                        </Button>
                        </ListItem>
                        <ListItem style={{justifyContent:'center', padding:window.innerHeight < 361  ? '4px' : '8px'}}>
                          <Button className ={classes.squareButton}
                                    variant="contained" color="secondary" size="small" onClick={()=>setOpenMember(true)}
                          >
                            <GroupIcon />
                            {
                              chatChannel &&
                              chatChannel.current &&
                              chatChannel.current.data &&
                              chatChannel.current.data.member_count
                            }
                          </Button>
                        </ListItem>
                        </List>
                      </Grid>
                    </Grid>
                    </>
                  }
                </Box>

                <>
                {
                  isMobile && <>
                  { <Box  position={orientation === 'landscape' ?"absolute":"fixed"}
                      style={orientation === 'landscape' ? {top:'10px', right:'10px'}: {bottom:'5px', right:'18px'}}
                        width={
                          orientation !== 'landscape' ? window.innerWidth*0.3:
                        (document.getElementById('landscape_right_bar') &&
                        (document.getElementById('landscape_right_bar').offsetWidth - 10))
                        || window.innerWidth*0.1} height={window.innerHeight*0.2} zIndex={9999}>
                    <video id="localVideo" muted autoPlay playsInline width="100%"></video>
                  </Box>}
                  </>
                }

                </>
              </div>
              {
                (orientation !== 'landscape') &&isMobile && openLiveDialog &&
                <Box position="fixed" bottom={0} zIndex={999} width="100%" style={{borderTop:'1px solid #e5e5e5',background:'white'}}>
                  <Box display="flex"  p={'4px'}>
                    <Box bgcolor="red" color="white" p={2} borderRadius={2} mr={1}
                    style={{width:'25px', height:'10px', display:'flex', alignItems:'center', justifyContent:'center'}}>
                      Live
                    </Box>
                      {isMobile && <><Button color="secondary" variant="contained"  className={classes.squareButton} 
                      boxProps={{ml:'5px'}}>
                      { !isMuted ? <Unmute fontSize={"small"} onClick={toggleAudioBroadcast.bind(null, false)}/> :
                      <Mute fontSize={"small"} onClick={toggleAudioBroadcast.bind(null, false)}/>}
                    </Button>
                    <Button color="secondary" variant="contained" className={classes.squareButton} boxProps={{ml:'5px'}}>
                      { !isVideoMuted ? <UnmuteVideo fontSize={"small"} onClick={toggleVideoBroadcast.bind(null, false)}/> :
                      <MuteVideo fontSize={"small"} onClick={toggleVideoBroadcast.bind(null, false)}/>}
                    </Button></>}
                    <Button color="secondary" variant="contained" className={classes.squareButton}  boxProps={{ml:'5px'}} 
                    onClick={()=>setOpenChat(true)}>
                      <ChatIcon fontSize={"small"}/>
                    </Button>
                    <Button color="secondary" variant="contained" className={classes.squareButton} style={{ padding:'2px' }} boxProps={{ml:'5px'}} 
                    onClick={()=>setOpenMember(true)}>
                      <GroupIcon fontSize={"small"}/>
                      {
                          chatMembers
                      }
                    </Button>
                    {
                      streamMode === 'whiteboard' &&
                      <Box style={{marginLeft: '5px', paddingTop: '2px'}}>
                      {audioAvailRequestCount.current > 0 && <><PanToolIcon />{audioAvailRequestCount.current}</>}
                      </Box>
                    }
                    <Box>

                    </Box>

                  </Box>

                </Box>
              }
            </Grid>
            }

            { streamMode === 'webcam' &&
            <Grid item
            xs={orientation === 'landscape' && streamMode === 'webcam' ?6 :12}
             md={5} style={{height:`${isMobile && orientation !== 'landscape'?'40%':'100%'}`,background: `${isMobile?'black':'white'}`,overflow:'hidden'}}>
               { orientation === 'landscape' && streamMode === 'webcam' &&
               <Button className ={classes.squareButton}
               boxProps={{m:2}}
                                    variant="contained" color="secondary" onClick={() => {setShowHeader(true)}}>
                                      <KeyboardArrowDownIcon fontSize={"small"} />
                </Button>
                }
               <Box mt={isMobile?0:4} mb={isMobile?0:4}height={"100%"}>
                <video id="localVideo" muted autoPlay playsInline width="100%" height={isMobile?"100%":'auto'} ></video>
               </Box>
              </Grid>
            }


            {
              (streamMode === 'whiteboard') &&
              <>
              {
                isMobile ?
                <Dialog keepMounted={true} fullScreen open={isOpenChat}>
                  {/* {
                  getHeader()} */}
                  {
                  getChat()}
              </Dialog>
                : getChat()
              }
              </>
            }

            {openLiveDialog && (streamMode !== 'whiteboard') &&
            <Grid item md={streamMode === 'webcam' ? 5 : 12}
            xs={orientation === 'landscape'?6:12} style={{
              height:`${isMobile  ? streamMode==='rtmp'?
              `${orientation == 'landscape'?'100vh':'90vh'}`:
              `${orientation == 'landscape'?'100vh':'60%'}`:'auto'}`,
              overflow:'hidden' }}>

              <Box mt={isMobile?0:4} mb={isMobile?0:4}
              display={streamMode !== 'webcam' && !isMobile ?'flex':'block'}
              justifyContent="center"
              zIndex={streamMode==='rtmp'&& orientation==='landscape'? '99': 'auto'}
              height={isMobile?"100%":"auto"} ml={isMobile?'auto':2} m={streamMode==='landscape' && !isMobile ?"auto":''}>

                <Box border={1} borderColor="primary"
                 borderRadius="borderRadius"
                 className={isMobile?{}:classes.chatLayout}height={isMobile? '100%': '80vh'}
                 width={streamMode !== 'webcam' && !isMobile ?'400px':'auto'}

                 >
                  <ChatErrorBoundary onError={initChat}>
                  {
              <PocChat  participantHandler = {(data)=> participantHandler(data)} articleId = {articleMeta.current.articleId} headerComponent={() => {
                return <>
                  {isMobile && <CloseIcon onClick={() => setOpenChat(false)} className={classes.crossChatButton} />}
                  {!isMobile &&
                    <Box style={{ display: 'flex', justifyContent: 'flex-end', padding: '8px', position: 'relative' }}>
                      <Button
                        variant="contained" className={classes.squareButton} color="secondary" >
                        {
                          !isMuted ?
                            <Unmute fontSize={"small"} onClick={toggleAudioBroadcast.bind(null, false)} /> :
                            <Mute fontSize={"small"} onClick={toggleAudioBroadcast.bind(null, false)} />
                        }
                      </Button>
                      <Button className={classes.squareButton} variant="contained" color="secondary" >
                        {
                          !isVideoMuted ?
                            <UnmuteVideo fontSize={"small"} onClick={toggleVideoBroadcast.bind(null, false)} /> :
                            <MuteVideo fontSize={"small"} onClick={toggleVideoBroadcast.bind(null, false)} />
                        }
                      </Button>
                      <Button color="secondary"
                        className={classes.squareButton}
                        variant="contained" boxProps={{ ml: 2 }}  onClick={() => setOpenMember(true)}
                      >
                        <GroupIcon />
                        {
                          chatMembers
                        }
                      </Button>
                      {
                        streamMode === 'whiteboard' &&
                        <Box style={{ marginLeft: '1em' }}>
                          {audioAvailRequestCount.current > 0 && <><PanToolIcon />{audioAvailRequestCount.current}</>}
                        </Box>
                      }
                    </Box>
                  }
                </>
              }} />
            }
                  </ChatErrorBoundary>
                </Box>
              </Box>
            </Grid>
            }
          </Grid>
        </Grid >
        </RootRef>
      </Dialog>
      {<Dialog open={showDialog}  aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Go Live</DialogTitle>
        <DialogContent>
          <Box display="flex" alignItems="center">
          <Checkbox
            checked={emailCheck}
            onChange={(e)=>{setEmailCheck(e.target.checked)}}
            name="checkedB"
            color="primary"
          />
          <Box component="span" color="primary.main" fontWeight="bold"
              fontSize={12}>{'Send email to notify your audience.'}</Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{setShowDailog(false)}} color="primary">
            Cancel
          </Button>
          <Button onClick={()=>{goLive()}} color="primary">
            Go live
          </Button>
        </DialogActions>
      </Dialog>}

      {isMobile &&
          <Dialog open ={isOpenMember} fullScreen>
            {/* {getHeader()} */}
            {getMember()}
          </Dialog>
      }
      {!isMobile && streamMode === 'webcam'&&
          <Dialog open ={isOpenMember} maxWidth={'xs'} classes={{
            paperScrollPaper:classes.dialogForParticipants,
      }} >
            {getMember()}
        </Dialog>
      }

      {
        !isMobile && streamMode ==='rtmp' && <Dialog open ={isOpenMember} maxWidth={'xs'} classes={{
          paperScrollPaper:classes.dialogForParticipantsOBS,
    }} >
          {getMember()}
      </Dialog>
      }
      {
        streamMode==='rtmp' && showRTMPInfo && getRTMPInfo()
      }
    </>
  )
}

export default LiveTransmission;
