import setAppMessage from "../../app-component/app-component-action";
import {useSelector} from 'react-redux';
import { LogglyTracker } from 'loggly-jslogger';

const logger = new LogglyTracker();
logger.push({ 'logglyKey': '142d21f5-10d3-47a9-aa5a-f83e0a3abed7', 'sendConsoleErrors' : true, 'tag' : 'ardbeg,common,common-action' });



export  function setSearchQueryReducer(searchQuery){
  return async (dispatch,getState, apiHandler)=>{
    const { actionTypes } = apiHandler;
    dispatch({type:actionTypes.SEARCH.RESET.META})
    dispatch(({type:actionTypes.SEARCH.SET.META,data:searchQuery}))
  }
}

export default function getQuickArticle() {
  return async (dispatch, getState, apiHandler) => {
    const { api, apiRoutes, actionTypes } = apiHandler;
    const { landing :{activeDomain, userProfile},commonComponent :{searchMeta} } = getState();
    const {searchQuery,page,perPage} = searchMeta;
    if(!searchMeta.hasMoreData || !searchQuery) {
      return Promise.resolve()
    }
  
    try {
        dispatch({ type:actionTypes.SEARCH.FETCH.REQUEST})
         let { data } = await api(apiRoutes.searchQuickArticleApi(activeDomain, {searchQuery,page:page+1,perPage}));
         dispatch({ type:actionTypes.SEARCH.FETCH.SUCCESS, data:{data:data.result,nextPage:page+1}})
      return Promise.resolve(data);
    } catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      dispatch({ type:actionTypes.SEARCH.FETCH.FAILURE})
      dispatch(setAppMessage({ message: err.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}

export function fetchFeedForSearch( headerType = '') {
    return async (dispatch, getState, apiHandler) => {
      const { api, apiRoutes ,actionTypes} = apiHandler;
      dispatch({type:actionTypes.SEARCH.RESET.META})
      const { landing :{activeDomain, userProfile},commonComponent :{searchMeta} } = getState();
      dispatch({ type:actionTypes.SEARCH.FETCH.REQUEST})
      try {
        let { data: ArticleFeed } = await api(apiRoutes.fetchArticles(activeDomain, "live", headerType,searchMeta.page +1 ,searchMeta.perPage));
        dispatch({ type:actionTypes.SEARCH.FETCH.SUCCESS, data:{data:ArticleFeed.result,searchQuery:'',nextPage :searchMeta.page +1}})
        return Promise.resolve(ArticleFeed);
      } catch (err) {
        const data = {
          'error': err,
          'email': (userProfile||{}).email || '',
          'url': (window.location||{}).href || '',
          'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
          'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
        };
        logger.push({
            'type': 'error',
            'data': data,
          });
        dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}))
        return Promise.reject(err);
      }
    }
  }
  
export function deleteSearchArticle (articleId){
  return async (dispatch, getState, apiHandler) => {
    const { actionTypes} = apiHandler;
    dispatch({type:actionTypes.SEARCH.DELETE.ARTICLE,articleId})
  }
}
