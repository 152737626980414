import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from "../../commons/muiCustomComponents/Box";
import Input from "../../commons/muiCustomComponents/Input";
import Button from "../../commons/muiCustomComponents/Button";
import { LinearLoader, PageBlockLoader } from '../../commons/commonWrapper/Loaders';
import { useDispatch, useSelector } from "react-redux";
import MobileProvider from "commons/context/MobileProvider";
import Avatar from '@material-ui/core/Avatar';
import { doAllAuthDataFetch, updateUser } from "../landing/landing-action";
import { logoutUser } from "../auth/auth-action";
import { useHistory } from 'react-router-dom';
import withFileUpload from "../../commons/hocs/WithFileUpload";
import Grid from "../../commons/muiCustomComponents/Grid";
import setAppMessage from "../../app-component/app-component-action";
import Paper from "commons/muiCustomComponents/Paper";
import { LogglyTracker } from 'loggly-jslogger';

const logger = new LogglyTracker();
logger.push({ 'logglyKey': '142d21f5-10d3-47a9-aa5a-f83e0a3abed7', 'sendConsoleErrors' : true, 'tag' : 'ardbeg,profile' });


const ProfilUpload = withFileUpload(Button);

const useStyles = makeStyles(theme => ({
  iconWidth: {
    width: theme.spacing(21),
    height: theme.spacing(21),
    marginTop: theme.spacing(8),
  },
  avatorContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  editButtonStyle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(8),
    width: theme.spacing(21),
  },
  editButtonCoverStyle: {
    marginTop: theme.spacing(3),
    width: theme.spacing(21),
  },
  coverImage: {
    backgroundColor: theme.palette.pubWhite,
    "&:hover,&:focus": {
      backgroundColor: theme.palette.pubWhite,
    },
  },
  logoutButton: {
    lineHeight: theme.isMobile ? "auto" : 3,
    backgroundColor: theme.palette.pubPaleGrey,
    "&:hover,&:focus": {
      backgroundColor: theme.palette.pubPaleGrey,
    },
  },
  coverStyle: {
    width: '100%',
    height: ' 100%',
    objectFit: 'contain',
  },
  container: {
    display: 'flex',
  },
  desktopMargin: {
    marginLeft: theme.spacing(5),
  },
  submitButton :{
    lineHeight: theme.isMobile ? "auto" : 3,
  },
}));

export default function Profile({ appbarheight }) {

  const classes = useStyles();
  const isMobile = useContext(MobileProvider);
  const history = useHistory();
  const { landing: { userProfile } } = useSelector(state => state);

  const [isFetching, setfetching] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [firstName, setFirstname] = useState('');
  const [lastName, setLastname] = useState('');
  const [aboutMe, setAboutMe] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [fbProfile, setFbProile] = useState('');
  const [twitterProfile, setTwitterProfile] = useState('');
  const [profilePicture, setProfilePicture] = useState('');
  const [bufferAvator, setBufferAvator] = useState(null);
  const [blog, setBolg] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    let isMounted = true;

    const getDetails = async () => {
      try {
        setfetching(true)
        dispatch(doAllAuthDataFetch())
        isMounted && setfetching(false)
      } catch (err) {
        isMounted && setfetching(false)
      }
    }
    if (Object.keys(userProfile).length > 0) {
      const {
        firstName,
        lastName,
        profilePicture,
        twitterProfile,
        fbProfile,
        phoneNumber,
        ownWebsite,
        description,
        coverPicture,
      } = userProfile;
      isMounted && setProfilePicture(profilePicture);
      isMounted && setTwitterProfile(twitterProfile);
      isMounted && setFbProile(fbProfile);
      isMounted && setMobileNumber(phoneNumber);
      isMounted && setBolg(ownWebsite);
      isMounted && setFirstname(firstName);
      isMounted && setLastname(lastName);
      isMounted && setAboutMe(description);
    }
    else {
      getDetails()
    }
    return () => isMounted = false
  }, []);

  const updateUserInfo = async () => {
    const payload = new FormData();
    payload.set("firstName", firstName || '');
    payload.set("lastName", lastName || '');
    payload.set("description", aboutMe || '');
    payload.set("profilePicture", bufferAvator || null);
    payload.set("phoneNumber", mobileNumber || '');
    payload.set("fbProfile", fbProfile || '');
    payload.set("twitterProfile", twitterProfile || '');
    payload.set("ownWebsite", blog || '');
    try {
      setLoading(true)
      await dispatch(updateUser(payload, profilePicture))
      setLoading(false)
    }
    catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      setLoading(false)
    }
  }

  const logOutUser = () => {
    try {
      setLoading(true);
      localStorage.removeItem('accessToken');
      localStorage.removeItem('active_domain_id');
      sessionStorage.removeItem('active_domain_id');
      //dispatch(logoutUser());
      setLoading(false)
      history.push('/login')
    }
    catch (err) {
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      setLoading(false)
    }
  }

  const handleProfileUpload = async (target) => {
    const file = target && target.files[0];
    let reader = new FileReader();
    let fileSize = (file) && (file.size / 1024 / 1024);
    reader.readAsDataURL(file);
    if (fileSize > 2) {
      dispatch(setAppMessage({ message: 'Image size exceeds 2 MB', header: 'Error', type: 'alert' }))
      setAppMessage()
      return
    }
    reader.onloadend = () => {
      setProfilePicture(reader.result);
    }
    setBufferAvator(file);
  }


  const handleChange = (e, type) => {
    const value = e.currentTarget.value;
    if (type === "firstName") {
      setFirstname(value)
    }
    if (type === "lastName") {
      setLastname(value)
    }
    if (type === "aboutme") {
      setAboutMe(value)
    }
    if (type === 'mobilenumber') {
      setMobileNumber(value)
    }
    if (type === 'twitterprofile') {
      setTwitterProfile(value)
    }
    if (type === 'fbprofile') {
      setFbProile(value)
    }
    if (type === 'blog') {
      setBolg(value)
    }
  }

  return (

    <Grid container>
    <Grid item xs={12} md={9} xl={10}>
        <Box m ={isMobile ?4 : 0} mt={!isMobile?6:4} mb={4} >
          <Paper>
    <Box className={classes.root} m={'auto'} height={isMobile ? `calc(100vh - ${appbarheight}px)` : 'auto'}>
      {(isLoading || isFetching) && isMobile && <LinearLoader position="sticky" top={appbarheight} />}
      {(isLoading || isFetching) && !isMobile && <PageBlockLoader />}

      <Box
        bgcolor="pubGreywhite"
      >
        <Grid
          container
        >

          <Grid item
            md={4}
            xs={12}
            className={classes.avatorContainer}
          >
            <Avatar alt="profile pic" src={profilePicture || ''} className={classes.iconWidth} />
            <ProfilUpload
              disabled={isLoading}
              onChange={handleProfileUpload}
              type="chips"
              color="secondary"
              className={classes.editButtonStyle}
              label="Edit" />
          </Grid>
        </Grid>
      </Box>
      <Box width="90%" m="auto">
        <Grid container >
          <Grid item xs={12} md={8} >
            <Box width="100%">
              <Box mt={3}>
                <Input onChange={(e) => handleChange(e, "firstName")} color="secondary" value={firstName || ''} fullWidth label="First Name" />
              </Box>
              <Box mt={3}>
                <Input onChange={(e) => handleChange(e, "lastName")} color="secondary" value={lastName || ''} fullWidth label="Last Name" />
              </Box>
              <Box mt={3}>
                <Input onChange={(e) => handleChange(e, "aboutme")} color="secondary" value={aboutMe || ''} fullWidth label="About Me" />
              </Box>
              {/* <Box mt={3}>
                <Input 
                onChange={(e) => handleChange(e, "mobilenumber")}
                 color="secondary" value={mobileNumber || ''} fullWidth 
                 label="Mobile Number" 
                 placeholder=" Eg (+1 2025550190)"/>
              </Box> */}
              <Box mt={3}>
                <Input onChange={(e) => handleChange(e, "fbprofile")} color="secondary" value={fbProfile || ''} fullWidth label="Facebook Profile" />
              </Box>
            </Box>
            <Box width="100%">
              <Box mt={3}>
                <Input onChange={(e) => handleChange(e, "twitterprofile")} color="secondary" value={twitterProfile || ''} fullWidth label="Twitter Profile" />
              </Box>
              <Box mt={3}>
                <Input onChange={(e) => handleChange(e, "blog")} color="secondary" value={blog || ''} fullWidth label="Blog Profile" />
              </Box>
              <Box mt={3}>
                <Button disabled={isLoading} fullWidth onClick={logOutUser} className={classes.logoutButton}>
                  Logout
             </Button>
              </Box>
            </Box>

          </Grid>
        </Grid>
        <Grid container>
        <Grid item xs={12} md={8}>
          <Box mt={3} mb={3} >
            <Button disabled={isLoading} fullWidth variant="contained" onClick={updateUserInfo} className={classes.submitButton}>
              Submit
             </Button>
          </Box>
        </Grid>
      </Grid>
      </Box>
    </Box>
    </Paper>
          </Box>
      </Grid>
    </Grid>
  );
}
