import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Box from "commons/muiCustomComponents/Box";
import { useDispatch, useSelector } from "react-redux";
import Button from "commons/muiCustomComponents/Button";
import { CircularLoader, LinearLoader, PageBlockLoader } from 'commons/commonWrapper/Loaders';
import moment from "moment"
import { useHistory } from "react-router-dom";
import AddIcon from '@material-ui/icons/Add';



function Gettoggle(disableProduct, row) {
  const [status, setStatus] = useState(row.status);
  return <Button type="toggle"
    checked={status}
    onChange={(e) => setStatus(e.target.value)}
    onClick={() => disableProduct(row)} />
}

const useStyles = makeStyles((theme)=> ({
  table: {
    minWidth: 650,
  },
  textdecoration: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  heighPowerFont: {
    fontWeight: 600,
  },
  flexClasses: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  productBackground :{
    backgroundColor:theme.palette.secondary.light,
    border:'none',
    "&:hover,&:focus": {
      backgroundColor:theme.palette.secondary.light,
      border:'none',
    },
  },
  wordBreak :{
    wordBreak:'break-word',
  },
}));


export default function SimpleTable({activeCampaigns}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [rows, setRows] = React.useState([]);
  const [fetchingProduct, setFetchingProduct] = useState(false);
  const { landing: { activeDomain, userProfile } } = useSelector((state) => state);
  const [isLoading, setLoading] = useState(false);
  const [activeprodcutId, setActiveproductId] = useState(null);


  const goToEditPage = (row) => {
    history.push(`/dash/subscripition/edit-product/${row.id}`);
  }
  const manageProd = () => {
    history.push('/dash/subscripition/manage-product');
  }


  return (
    <TableContainer >
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.heighPowerFont}>SUBJECT</TableCell>
            <TableCell  className={classes.heighPowerFont}>OPENED</TableCell>
            {/* <TableCell  className={classes.heighPowerFont}>CLICKED</TableCell> */}
            <TableCell  className={classes.heighPowerFont}>UNSUBSCRIBED</TableCell>
            <TableCell className={classes.heighPowerFont}>DELIVERED</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

          {
            activeCampaigns && activeCampaigns.map(row => (
              row.status !== 2 ? null :
              <TableRow key={row.subject}>
                <TableCell component="th" scope="row" className={classes.wordBreak}>
                  {row.subject}
                </TableCell>
                <TableCell >
                  {row.insights.openCount}
                </TableCell>
                {/* <TableCell >
                  {row.clicked || 'N/A'}
                </TableCell> */}
                <TableCell  >
                  {row.insights.unsubscribedCount}
                </TableCell>
                <TableCell  >
                  {row.insights.receivedCount}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
