import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import Button from "commons/muiCustomComponents/Button";
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Input from "commons/muiCustomComponents/Input";
import Box from "commons/muiCustomComponents/Box";
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import ClearIcon from '@material-ui/icons/Clear';

import { useSelector, useDispatch } from "react-redux";
import {
  createNewProduct, generateBenefite, getBenefites,
  showMessage,
  saveMediaToPisco,
  updateBenefit,
  getLiveStreamPost,
  searchArticlesearch,
  createandattachBenefit,

} from "../monetization-action";
import MobileProvider from "commons/context/MobileProvider";
import { fetchFeed } from "../monetization-action"
import { Divider } from '@material-ui/core';
import VideocamIcon from '@material-ui/icons/Videocam';
import ImageIcon from '@material-ui/icons/Image';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { CircularLoader,LinearLoader } from 'commons/commonWrapper/Loaders';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  bodyContainer: {
    position: 'relative',
    top: '75px',
  },
  minHeight: {
    height: '100%',
  },
  textdecoration: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  paperWidthMd :{
    maxWidth: theme.spacing(648 * 0.25),
    width:theme.spacing(648 * 0.25),
  },
}));


function AddBenefit({ open = false, benefitId,handleClose ,totalBenefits,
  setTotalBenefits,isEdit=false}, ref) {
  const [searchName, setSearchName] = useState('');
  const classes = useStyles();
  const [isLoadingSearch, setLoadingSearch] = useState(false);
  const [searchresult, setSearchresult] = useState(null);
  const dispatch = useDispatch();
  const { landing } = useSelector(state => state);
  const { sections } = landing;
  const [finalBenefits, setFinalBenefits] = useState([]);
  const [selectedSections, setSelectedSection] = useState([]);
  const [selectedPosts, setSelectedPosts] = useState([]);
  const [localChecked ,setLocalChecked] = useState([]);
  const [isUpdating ,setUpdating] = useState(false);
  const isMobile = React.useContext(MobileProvider);

  useEffect(()=>{
    try {
      totalBenefits && totalBenefits.length>0 && open &&
      setLocalChecked(totalBenefits)
      totalBenefits && open  && totalBenefits.forEach((item)=>{
        if(item.title && item.articleId){
          selectedPosts.push(item)    
        }
        if(item.name && item.id){
          selectedSections.push(item)
        }
      })
      totalBenefits && totalBenefits.length>0 && open  && setSelectedSection([...selectedSections])
      totalBenefits && totalBenefits.length>0 && open  && setSelectedPosts([...selectedPosts])  
    }
    catch(err){
      console.log("err",err)
    }
  },[open])



  useEffect(() => {
    const getFeed = async () => {
      if (sections.length > 0) {
        const data = await Promise.all([dispatch(fetchFeed()), dispatch(getLiveStreamPost())]);
        setSearchresult([...sections,...data[1].result])
        setFinalBenefits([...sections, ...data[1].result, ...data[0].result]);
      }
    }
    getFeed();
  }, [sections.length]);

  const getIcon = (headerType) => {
    if (headerType && headerType.includes('audio')) {
      return <MusicNoteIcon color="secondary" fontSize="small" />
    }
    if (headerType && headerType.includes('video')) {
      return <VideocamIcon color="secondary" fontSize="small" />
    }
    if (headerType && headerType.includes('image')) {
      return <ImageIcon color="secondary" fontSize="small" />
    }
    if (headerType && headerType.includes('text')) {
      return <InsertDriveFileIcon color="secondary" fontSize="small" />
    }
    if (headerType && headerType.includes('liveStream')) {
      return <LiveTvIcon color="secondary" fontSize="small" />
    }
  }


  const handleSearch = async () => {
    try {
      setLoadingSearch(true)
      const data = await dispatch(searchArticlesearch({
        page: 1,
        perPage: 30,
        searchQuery: searchName,
      }));
      setLoadingSearch(false)
      setFinalBenefits([...data.result])
    }
    catch (err) {
      setLoadingSearch(false)

      console.log(err)
    }
  }

  useImperativeHandle(ref, () => ({
    selectedSections,
    selectedPosts,
  }));

  const handleSelectedSection = (e, section) => {
    let tempsection = selectedSections;
    if (e.target.checked) {
      section.type ="section"
      tempsection.push(section)
      const uniqueId = [...new Set(tempsection.map(item => item.id))];
      const uniqueSeciton = sections.filter((item)=>uniqueId.includes(item.id));
      setSelectedSection(uniqueSeciton)
      localChecked.push(section)
      setLocalChecked([...localChecked])
    }
    else{
     const temp =  localChecked.filter((item)=>item.id != section.id)
      setLocalChecked([...temp])
      tempsection = tempsection.filter((item)=>item.id != section.id)
      setSelectedSection([...tempsection])
    }
  }

  const handleSelectedPost = (e, post) => {
    let tempPosts = selectedPosts;
    if (e.target.checked) {
      post.type ="content"
      tempPosts.push(post)
      const uniqueId = [...new Set(tempPosts.map(item => item.articleId))];
      const uniquePost = finalBenefits.filter((item)=>uniqueId.includes(item.articleId));
      setSelectedPosts(uniquePost);
      localChecked.push(post)
      setLocalChecked([...localChecked])
    }
    else{
      tempPosts = tempPosts.filter((item)=>item.articleId != post.articleId)
      setSelectedPosts([...tempPosts]);
      const temp =  localChecked.filter((item)=>item.articleId != post.articleId)
      setLocalChecked([...temp])
    }
  }
  const createandupdate = async()=>{
    try{
    setTotalBenefits([...selectedSections,...selectedPosts]);
    setSelectedSection([]);
    setSelectedPosts([]);
    handleClose();
    }
      catch(err){
        setUpdating(false)
        console.log("Err",err)
      }
  }
  const clearSearch = async()=>{
    setSearchName('');
    try {
    setLoadingSearch(true)
      const data = await dispatch(searchArticlesearch({
        page: 1,
        perPage: 30,
        searchQuery: '',
      }));
      setLoadingSearch(false)
      setFinalBenefits([...searchresult,...data.result])
    }
    catch(err){
      setLoadingSearch(false)
    }
  }
  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth
      classes ={!isMobile ? {paperWidthMd:classes.paperWidthMd}:{}}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Add Access</DialogTitle>
      <DialogContent>
        <Box mt={3} display="flex" width="100%">
          <Input
            id="Search"
            size="small"
            placeholder="Search posts"
            value={searchName}
            onChange={(e) => {
              setSearchName(e.currentTarget.value)
            }}
            headingStyle={{
              component: "span",
              fontWeight: "bold",
              fontSize: 14,
              mb: 1,

            }}
            
          InputProps={{
            endAdornment: <InputAdornment position="end"><ClearIcon style={{cursor:"pointer"}} onClick={clearSearch}/></InputAdornment>,
          }}
            fullWidth

          />
          <Button variant="contained" color="secondary" boxProps={{ ml: 3 }} onClick={handleSearch}>
            <IconButton color="secondary" size="small">
              <SearchIcon />
            </IconButton>
          </Button>
        </Box>
        <Box>
          { isLoadingSearch ?
           <Box>
             <CircularLoader height="auto"/>
           </Box>
          :
          <TableContainer component={Paper}>
            <Table aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell ><Box fontWeight="bold">ACCESS TO</Box></TableCell>
                  <TableCell ><Box fontWeight="bold">TYPE</Box></TableCell>
                  <TableCell >

                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>

                {
                  finalBenefits.map(row => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        {row.name || row.title}
                      </TableCell>
                      <TableCell >
                        {row.name ? "Section" : <Box display="flex" alignItems="center"> {getIcon(row.headerType)} Post</Box>}
                      </TableCell>
                      <TableCell >
                        {
                          row.title ?
                            <Box ml={3} mr={3} display="flex" alignItems="center" justifyContent="center">
                              <Box display="flex">
                                {row.paidContent ? <LockIcon fontSize="small" /> : <LockOpenIcon fontSize="small" />}
                              </Box>
                              <Button type="checkbox" 
                              checked = {localChecked.find((item)=>item.articleId==row.articleId)} onChange={(e) => handleSelectedPost(e, row)} />
                            </Box>
                            :
                            <Box display="flex" alignItems="center" justifyContent="center">
                              <Button type="checkbox" 
                              checked = {localChecked.find((item)=>item.id==row.id)}onChange={(e) => handleSelectedSection(e, row)} /></Box>
                        }
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
           }
          <Divider />
          <Box p={3} display="flex" justifyContent="flex-end">
            <Button variant="contained" color="secondary" onClick={()=>{
              setSelectedSection([]);
              setSelectedPosts([]);
              setLocalChecked([]);
              handleClose();
            }}>close</Button>
            <Box>
            <Button variant="contained" color="primary" onClick={()=>{
              isEdit ?
              createandupdate()
              :
              setTotalBenefits([...selectedSections,...selectedPosts]);
              handleClose()
              }} boxProps={{ ml: 2 }}>{isEdit?'Update':'add'}</Button>
              {isUpdating && <CircularLoader size={20} height="auto"/>}
              </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default forwardRef(AddBenefit);
