import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from "../../commons/muiCustomComponents/Box";
import InputBox from "../../commons/muiCustomComponents/InputBox";
import Button from "../../commons/muiCustomComponents/Button";
import { CircularLoader } from '../../commons/commonWrapper/Loaders';
import { dltWebsite } from "./settings-action";
import { useDispatch, useSelector } from "react-redux";
import MobileProvider from "commons/context/MobileProvider";
import Grid from "../../commons/muiCustomComponents/Grid";
import { useHistory } from "react-router-dom";
import Footer from "commons/commonWrapper/Footer";
import Paper from "commons/muiCustomComponents/Paper";


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  child: {
    backgroundColor: theme.palette.pubVeryLightPink,
    margin: theme.spacing(16),
  },
  iconWidth: {
    width: '100%',
    height: '100%',
  },
  buttonColor: {
    backgroundColor: theme.palette.pubRed,
    color: theme.palette.pubWhite,
    "&:hover,&:focus": {
      backgroundColor: theme.palette.pubRed,
      color: theme.palette.pubWhite,
    },
  },
}));

export default function GoogleAnalytics({ appbarheight }) {

  const classes = useStyles();
  const history = useHistory();
  const isMobile = useContext(MobileProvider);
  const [isLoading, setLoading] = useState(false);
  const [website, setWebsiteName] = useState('');
  const dispatch = useDispatch();
  const { header: { domainList }, landing: { activeDomain } } = useSelector(state => state);


  const handleDeleteWebsite = (e) => {
    const website = e.currentTarget.value;
    setWebsiteName(website);
  }

  const saveDomainDetails = async () => {
    try {
      setLoading(true)
      // eslint-disable-next-line camelcase
      await dispatch(dltWebsite())
      setLoading(false);
      localStorage.removeItem("active_domain_id");
      sessionStorage.removeItem("active_domain_id");
      history.push("/");
    }
    catch (err) {
      setLoading(false)
    }
  }
  const isEnabled = () => {
    try {
      return !(domainList[activeDomain][0]['fqdn'] === website)
    }
    catch (err) {
      return true
    }
  }

  const disablePaste = (e) => {
    e.preventDefault();
    return false;
  }

  return (
    <Grid container>
      <Grid item xs={12} md={9} xl={10}>
          <Box m ={isMobile ?2 : 0} mt={4} mb={4} >
            <Paper>
    <Grid container><Grid item xs={12} md={4}>
      <Box className={classes.root} height={isMobile ? `calc(100vh - ${appbarheight}px)` : 'auto'}>
        <Box>
          <InputBox
            heading="Type your website name"
            fullWidth
            color="secondary"
            value={website}
            onChange={handleDeleteWebsite}
            onPaste={disablePaste}
            headingStyle={{
              fontSize: 14,
              color: "pubGrey",
              fontWeight: 500,
              mb: 1,
              mt:0,
            }}
          />
          <Box component="p" color="pubbrownGrey" fontSize={12}>
            Once you delete your website, there is
            no going back. This will disable your website and delete all
             your website content and subscribers. We recommended exporting
              your newsletter email list if you want to reach out to your audience at
               any later time. Your account will still remain active and continue to work on other websites you are part of, or create a new one.
        </Box>
        </Box>
        {!isMobile && <Box display="flex" mb={3} mt={3}>
          <Button disabled={isEnabled() || isLoading} className={classes.buttonColor} fullWidth variant="contained" onClick={saveDomainDetails}>
            Delete my website
        </Button>
          {isLoading && <CircularLoader height="auto" size={20} ml={1} />}
        </Box>}
      </Box>
      {
        isMobile && <Footer>
          <Box display="flex" m={"auto"} mb={3} mt={3} width="80%">
            <Button disabled={isEnabled() || isLoading} className={classes.buttonColor} fullWidth variant="contained" onClick={saveDomainDetails}>
              Delete my website
        </Button>
            {isLoading && <CircularLoader height="auto" size={20} ml={1} />}
          </Box>
        </Footer>
      }
    </Grid></Grid>
    </Paper>
          </Box>
      </Grid>
    </Grid>
  );
}
