import setAppMessage from "../../app-component/app-component-action";
import {fetchDomainList} from "../header/header-action";

export function getCreateTypeAction() {
  return async (dispatch, getState, apiHandler) => {
    try {
      const { api, apiRoutes, actionTypes } = apiHandler;
      const { data: store } = await api(apiRoutes.getCreateTypeApi());
      dispatch(CreateType(actionTypes.CREATE.SUCCESS, store));
    } catch (err) {
      dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}));
      return Promise.reject(err);
    }      
  }
}

export function updateCreateTypeAction(data) {
  const payload = JSON.stringify(data);
  return async (dispatch, getState, apiHandler) => {
    try { 
      const { api, apiRoutes } = apiHandler;
      await api(apiRoutes.updateCreateTypeApi(payload));
      return Promise.resolve({});
    } catch(err) {
      dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}));
      return Promise.reject(err);
    }
  }
}

export function createDomainAction(payload) {
  return async (dispatch, getState, apiHandler) => {
    try{
      const { api, apiRoutes } = apiHandler;
      const {data} = await api(apiRoutes.createDomainApi(payload));
      dispatch(UpdateActiveDomainAndPermission("UpdateActiveDomainAndPermission", data));
      sessionStorage.setItem('active_domain_id', (data.data && data.data.domainId));
      localStorage.setItem('active_domain_id', (data.data && data.data.domainId));
      await dispatch(fetchDomainList());
      return Promise.resolve({});
    } catch(err){
      if (err.message) {
        return Promise.resolve(err.message);
      }
      dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}));
      return Promise.reject(err);
    }      
  }
}

export function updateDomainDetailsAction(payload) {
  return async (dispatch, getState, apiHandler) => {
    try{
      const activeDomain = getState().landing.activeDomain;
      const { api, apiRoutes } = apiHandler;
      await api(apiRoutes.updateDomainDetailsApi(payload, activeDomain));
      return Promise.resolve({});
    } catch(err){
      dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}));
      return Promise.reject(err);
    }
      
  }
}

export function addNewStoryList(payload) {
  return async (dispatch, getState, apiHandler) => {
    try{
      const activeDomain = getState().landing.activeDomain;
      const { api, apiRoutes } = apiHandler;
      await api(apiRoutes.addNewStoryListApi(payload, activeDomain));
      return Promise.resolve({});
    } catch(err){
      dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}));
      return Promise.reject(err);
    }
      
  }
}

export function enableNewsletterAction(payload) {
  return async (dispatch, getState, apiHandler) => {
    try{
      const activeDomain = getState().landing.activeDomain;
      const { api, apiRoutes, actionTypes } = apiHandler;
      const data = await api(apiRoutes.enableNewsletterApi(payload, activeDomain));
      dispatch(EnableNewsletter(actionTypes.ENABLENEWSLETTER.SUCCESS, data));
      return Promise.resolve(data);
    } catch(err){
      dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}));
      return Promise.reject(err);
    }      
  }
}

export function scheduleUpdateUser(payload) {
  return async (dispatch, getState, apiHandler) => {
    if(!payload.screen_name && !payload.oauth_token && !payload.oauth_token_secret){
      return;
    }
    try{
      const activeDomain = getState().landing.activeDomain;
      payload.domain_id = activeDomain;
      const { api, apiRoutes } = apiHandler;
      await api(apiRoutes.scheduleUpdateUser(payload));
      return Promise.resolve({});
    } catch(err){
      dispatch(setAppMessage({message:err.message,header :'Error',type:'alert'}));
      return Promise.reject(err);
    }      
  }
}

function CreateType(actionName, data) {
  return {
    type: actionName,
    data,
  }
} 
function EnableNewsletter(actionName, data) {
  return {
    type: actionName,
    data,
  }
} 
function UpdateActiveDomainAndPermission(actionName, data) {
  const payload = {};
  payload.activeDomain = data.data.domainId;
  return {
    type: actionName,
    data: payload,
  }
} 
