import React, { useEffect, useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import {removeGiftToSubscriber} from "./customers-action";
import {useDispatch} from 'react-redux';
import setAppMessage from "app-component/app-component-action";



function SelectGift({ setOpen ,
  payload,
  fetchDetail,
  row ={},
  name ,
  emailAddress ,
  setName ,
  setEmailAddress ,
}) {
  const [checked, setChecked] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const dispatch =  useDispatch();

  useEffect(()=>{
    if(row.name=="Follower"){
      setChecked(false);
    }
    if (row.name == "Subscriber" && row.giftedProducts && row.giftedProducts.length> 0) {
      setChecked(true);
    }
  },[Object.keys(row).length]);

  function removeSub (){
    (async()=>{
      try {
      await dispatch(removeGiftToSubscriber(payload));
      await dispatch (fetchDetail());
      }
      catch(err){
        console.log("err")
      }
    })()
  }

  const makeSubscriber = async (e) => {
    try {
      
      if (e.target.checked){ 
        setName(name);
        setEmailAddress(emailAddress);
        setOpen(true);
      }
      else {
        setLoading(true);
        await dispatch(setAppMessage({
          leftButtonText:'No',
          rightButtonText:'Yes',
          handleSuccess:  ()=>{
            setLoading(true);
            removeSub();
          }, header: 'Remove gift subscriber',message:'Would you like to remove the gift subscriber ?' }));
        setChecked(e.target.checked);
        setLoading(false);
        
      }

    }
    catch (err) {
      setLoading(false);
    }

  }
  return (<Checkbox
    disabled={isLoading}
    checked={checked}
    onChange={makeSubscriber}
    inputProps={{ 'aria-label': 'primary checkbox' }}
  />
  )
}
export default SelectGift;