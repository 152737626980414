import React,{useContext,useCallback} from "react";
import SettingHome from "components/settings/SettingHome";
import MobileProvider from "commons/context/MobileProvider";
import Box from "../commons/muiCustomComponents/Box";
import FullScreenPage from '../commons/commonWrapper/FullScreenPage';


function SettingsPage() {
  const isMobile = useContext(MobileProvider);
    const barCallback = useCallback(() =>(<Box 
        display="flex"
        alignItems="center"
        >Website Settings</Box>),[]);

    return(
        <FullScreenPage BarComponent={barCallback} isDesktop={false} isCloseIcon={true}>
                        <Box mt={isMobile ?0:6}> <SettingHome /> </Box> 
        </FullScreenPage> 
    )
}

export default SettingsPage;
