/* eslint-disable */
export default [
  {
    "code": "376",
    "country": "AD"
  },
  {
    "code": "971",
    "country": "AE"
  },
  {
    "code": "93",
    "country": "AF"
  },
  {
    "code": "+1-268",
    "country": "AG"
  },
  {
    "code": "+1-264",
    "country": "AI"
  },
  {
    "code": "355",
    "country": "AL"
  },
  {
    "code": "374",
    "country": "AM"
  },
  {
    "code": "244",
    "country": "AO"
  },
  {
    "code": "",
    "country": "AQ"
  },
  {
    "code": "54",
    "country": "AR"
  },
  {
    "code": "+1-684",
    "country": "AS"
  },
  {
    "code": "43",
    "country": "AT"
  },
  {
    "code": "61",
    "country": "AU"
  },
  {
    "code": "297",
    "country": "AW"
  },
  {
    "code": "+358-18",
    "country": "AX"
  },
  {
    "code": "994",
    "country": "AZ"
  },
  {
    "code": "387",
    "country": "BA"
  },
  {
    "code": "+1-246",
    "country": "BB"
  },
  {
    "code": "880",
    "country": "BD"
  },
  {
    "code": "32",
    "country": "BE"
  },
  {
    "code": "226",
    "country": "BF"
  },
  {
    "code": "359",
    "country": "BG"
  },
  {
    "code": "973",
    "country": "BH"
  },
  {
    "code": "257",
    "country": "BI"
  },
  {
    "code": "229",
    "country": "BJ"
  },
  {
    "code": "590",
    "country": "BL"
  },
  {
    "code": "+1-441",
    "country": "BM"
  },
  {
    "code": "673",
    "country": "BN"
  },
  {
    "code": "591",
    "country": "BO"
  },
  {
    "code": "599",
    "country": "BQ"
  },
  {
    "code": "55",
    "country": "BR"
  },
  {
    "code": "+1-242",
    "country": "BS"
  },
  {
    "code": "975",
    "country": "BT"
  },
  {
    "code": "",
    "country": "BV"
  },
  {
    "code": "267",
    "country": "BW"
  },
  {
    "code": "375",
    "country": "BY"
  },
  {
    "code": "501",
    "country": "BZ"
  },
  {
    "code": "1",
    "country": "CA"
  },
  {
    "code": "61",
    "country": "CC"
  },
  {
    "code": "243",
    "country": "CD"
  },
  {
    "code": "236",
    "country": "CF"
  },
  {
    "code": "242",
    "country": "CG"
  },
  {
    "code": "41",
    "country": "CH"
  },
  {
    "code": "225",
    "country": "CI"
  },
  {
    "code": "682",
    "country": "CK"
  },
  {
    "code": "56",
    "country": "CL"
  },
  {
    "code": "237",
    "country": "CM"
  },
  {
    "code": "86",
    "country": "CN"
  },
  {
    "code": "57",
    "country": "CO"
  },
  {
    "code": "506",
    "country": "CR"
  },
  {
    "code": "53",
    "country": "CU"
  },
  {
    "code": "238",
    "country": "CV"
  },
  {
    "code": "599",
    "country": "CW"
  },
  {
    "code": "61",
    "country": "CX"
  },
  {
    "code": "357",
    "country": "CY"
  },
  {
    "code": "420",
    "country": "CZ"
  },
  {
    "code": "49",
    "country": "DE"
  },
  {
    "code": "253",
    "country": "DJ"
  },
  {
    "code": "45",
    "country": "DK"
  },
  {
    "code": "+1-767",
    "country": "DM"
  },
  {
    "code": "+1-809 and 1-829",
    "country": "DO"
  },
  {
    "code": "213",
    "country": "DZ"
  },
  {
    "code": "593",
    "country": "EC"
  },
  {
    "code": "372",
    "country": "EE"
  },
  {
    "code": "20",
    "country": "EG"
  },
  {
    "code": "212",
    "country": "EH"
  },
  {
    "code": "291",
    "country": "ER"
  },
  {
    "code": "34",
    "country": "ES"
  },
  {
    "code": "251",
    "country": "ET"
  },
  {
    "code": "358",
    "country": "FI"
  },
  {
    "code": "679",
    "country": "FJ"
  },
  {
    "code": "500",
    "country": "FK"
  },
  {
    "code": "691",
    "country": "FM"
  },
  {
    "code": "298",
    "country": "FO"
  },
  {
    "code": "33",
    "country": "FR"
  },
  {
    "code": "241",
    "country": "GA"
  },
  {
    "code": "44",
    "country": "GB"
  },
  {
    "code": "+1-473",
    "country": "GD"
  },
  {
    "code": "995",
    "country": "GE"
  },
  {
    "code": "594",
    "country": "GF"
  },
  {
    "code": "+44-1481",
    "country": "GG"
  },
  {
    "code": "233",
    "country": "GH"
  },
  {
    "code": "350",
    "country": "GI"
  },
  {
    "code": "299",
    "country": "GL"
  },
  {
    "code": "220",
    "country": "GM"
  },
  {
    "code": "224",
    "country": "GN"
  },
  {
    "code": "590",
    "country": "GP"
  },
  {
    "code": "240",
    "country": "GQ"
  },
  {
    "code": "30",
    "country": "GR"
  },
  {
    "code": "",
    "country": "GS"
  },
  {
    "code": "502",
    "country": "GT"
  },
  {
    "code": "+1-671",
    "country": "GU"
  },
  {
    "code": "245",
    "country": "GW"
  },
  {
    "code": "592",
    "country": "GY"
  },
  {
    "code": "852",
    "country": "HK"
  },
  {
    "code": " ",
    "country": "HM"
  },
  {
    "code": "504",
    "country": "HN"
  },
  {
    "code": "385",
    "country": "HR"
  },
  {
    "code": "509",
    "country": "HT"
  },
  {
    "code": "36",
    "country": "HU"
  },
  {
    "code": "62",
    "country": "ID"
  },
  {
    "code": "353",
    "country": "IE"
  },
  {
    "code": "972",
    "country": "IL"
  },
  {
    "code": "+44-1624",
    "country": "IM"
  },
  {
    "code": "+91",
    "country": "IN"
  },
  {
    "code": "246",
    "country": "IO"
  },
  {
    "code": "964",
    "country": "IQ"
  },
  {
    "code": "98",
    "country": "IR"
  },
  {
    "code": "354",
    "country": "IS"
  },
  {
    "code": "39",
    "country": "IT"
  },
  {
    "code": "+44-1534",
    "country": "JE"
  },
  {
    "code": "+1-876",
    "country": "JM"
  },
  {
    "code": "962",
    "country": "JO"
  },
  {
    "code": "81",
    "country": "JP"
  },
  {
    "code": "254",
    "country": "KE"
  },
  {
    "code": "996",
    "country": "KG"
  },
  {
    "code": "855",
    "country": "KH"
  },
  {
    "code": "686",
    "country": "KI"
  },
  {
    "code": "269",
    "country": "KM"
  },
  {
    "code": "+1-869",
    "country": "KN"
  },
  {
    "code": "850",
    "country": "KP"
  },
  {
    "code": "82",
    "country": "KR"
  },
  {
    "code": "965",
    "country": "KW"
  },
  {
    "code": "+1-345",
    "country": "KY"
  },
  {
    "code": "7",
    "country": "KZ"
  },
  {
    "code": "856",
    "country": "LA"
  },
  {
    "code": "961",
    "country": "LB"
  },
  {
    "code": "+1-758",
    "country": "LC"
  },
  {
    "code": "423",
    "country": "LI"
  },
  {
    "code": "94",
    "country": "LK"
  },
  {
    "code": "231",
    "country": "LR"
  },
  {
    "code": "266",
    "country": "LS"
  },
  {
    "code": "370",
    "country": "LT"
  },
  {
    "code": "352",
    "country": "LU"
  },
  {
    "code": "371",
    "country": "LV"
  },
  {
    "code": "218",
    "country": "LY"
  },
  {
    "code": "212",
    "country": "MA"
  },
  {
    "code": "377",
    "country": "MC"
  },
  {
    "code": "373",
    "country": "MD"
  },
  {
    "code": "382",
    "country": "ME"
  },
  {
    "code": "590",
    "country": "MF"
  },
  {
    "code": "261",
    "country": "MG"
  },
  {
    "code": "692",
    "country": "MH"
  },
  {
    "code": "389",
    "country": "MK"
  },
  {
    "code": "223",
    "country": "ML"
  },
  {
    "code": "95",
    "country": "MM"
  },
  {
    "code": "976",
    "country": "MN"
  },
  {
    "code": "853",
    "country": "MO"
  },
  {
    "code": "+1-670",
    "country": "MP"
  },
  {
    "code": "596",
    "country": "MQ"
  },
  {
    "code": "222",
    "country": "MR"
  },
  {
    "code": "+1-664",
    "country": "MS"
  },
  {
    "code": "356",
    "country": "MT"
  },
  {
    "code": "230",
    "country": "MU"
  },
  {
    "code": "960",
    "country": "MV"
  },
  {
    "code": "265",
    "country": "MW"
  },
  {
    "code": "52",
    "country": "MX"
  },
  {
    "code": "60",
    "country": "MY"
  },
  {
    "code": "258",
    "country": "MZ"
  },
  {
    "code": "264",
    "country": "NA"
  },
  {
    "code": "687",
    "country": "NC"
  },
  {
    "code": "227",
    "country": "NE"
  },
  {
    "code": "672",
    "country": "NF"
  },
  {
    "code": "234",
    "country": "NG"
  },
  {
    "code": "505",
    "country": "NI"
  },
  {
    "code": "31",
    "country": "NL"
  },
  {
    "code": "47",
    "country": "NO"
  },
  {
    "code": "977",
    "country": "NP"
  },
  {
    "code": "674",
    "country": "NR"
  },
  {
    "code": "683",
    "country": "NU"
  },
  {
    "code": "64",
    "country": "NZ"
  },
  {
    "code": "968",
    "country": "OM"
  },
  {
    "code": "507",
    "country": "PA"
  },
  {
    "code": "51",
    "country": "PE"
  },
  {
    "code": "689",
    "country": "PF"
  },
  {
    "code": "675",
    "country": "PG"
  },
  {
    "code": "63",
    "country": "PH"
  },
  {
    "code": "92",
    "country": "PK"
  },
  {
    "code": "48",
    "country": "PL"
  },
  {
    "code": "508",
    "country": "PM"
  },
  {
    "code": "870",
    "country": "PN"
  },
  {
    "code": "+1-787 and 1-939",
    "country": "PR"
  },
  {
    "code": "970",
    "country": "PS"
  },
  {
    "code": "351",
    "country": "PT"
  },
  {
    "code": "680",
    "country": "PW"
  },
  {
    "code": "595",
    "country": "PY"
  },
  {
    "code": "974",
    "country": "QA"
  },
  {
    "code": "262",
    "country": "RE"
  },
  {
    "code": "40",
    "country": "RO"
  },
  {
    "code": "381",
    "country": "RS"
  },
  {
    "code": "7",
    "country": "RU"
  },
  {
    "code": "250",
    "country": "RW"
  },
  {
    "code": "966",
    "country": "SA"
  },
  {
    "code": "677",
    "country": "SB"
  },
  {
    "code": "248",
    "country": "SC"
  },
  {
    "code": "249",
    "country": "SD"
  },
  {
    "code": "46",
    "country": "SE"
  },
  {
    "code": "65",
    "country": "SG"
  },
  {
    "code": "290",
    "country": "SH"
  },
  {
    "code": "386",
    "country": "SI"
  },
  {
    "code": "47",
    "country": "SJ"
  },
  {
    "code": "421",
    "country": "SK"
  },
  {
    "code": "232",
    "country": "SL"
  },
  {
    "code": "378",
    "country": "SM"
  },
  {
    "code": "221",
    "country": "SN"
  },
  {
    "code": "252",
    "country": "SO"
  },
  {
    "code": "597",
    "country": "SR"
  },
  {
    "code": "211",
    "country": "SS"
  },
  {
    "code": "239",
    "country": "ST"
  },
  {
    "code": "503",
    "country": "SV"
  },
  {
    "code": "599",
    "country": "SX"
  },
  {
    "code": "963",
    "country": "SY"
  },
  {
    "code": "268",
    "country": "SZ"
  },
  {
    "code": "+1-649",
    "country": "TC"
  },
  {
    "code": "235",
    "country": "TD"
  },
  {
    "code": "",
    "country": "TF"
  },
  {
    "code": "228",
    "country": "TG"
  },
  {
    "code": "66",
    "country": "TH"
  },
  {
    "code": "992",
    "country": "TJ"
  },
  {
    "code": "690",
    "country": "TK"
  },
  {
    "code": "670",
    "country": "TL"
  },
  {
    "code": "993",
    "country": "TM"
  },
  {
    "code": "216",
    "country": "TN"
  },
  {
    "code": "676",
    "country": "TO"
  },
  {
    "code": "90",
    "country": "TR"
  },
  {
    "code": "+1-868",
    "country": "TT"
  },
  {
    "code": "688",
    "country": "TV"
  },
  {
    "code": "886",
    "country": "TW"
  },
  {
    "code": "255",
    "country": "TZ"
  },
  {
    "code": "380",
    "country": "UA"
  },
  {
    "code": "256",
    "country": "UG"
  },
  {
    "code": "1",
    "country": "UM"
  },
  {
    "code": "1",
    "country": "US"
  },
  {
    "code": "598",
    "country": "UY"
  },
  {
    "code": "998",
    "country": "UZ"
  },
  {
    "code": "379",
    "country": "VA"
  },
  {
    "code": "+1-784",
    "country": "VC"
  },
  {
    "code": "58",
    "country": "VE"
  },
  {
    "code": "+1-284",
    "country": "VG"
  },
  {
    "code": "+1-340",
    "country": "VI"
  },
  {
    "code": "84",
    "country": "VN"
  },
  {
    "code": "678",
    "country": "VU"
  },
  {
    "code": "681",
    "country": "WF"
  },
  {
    "code": "685",
    "country": "WS"
  },
  {
    "code": "",
    "country": "XK"
  },
  {
    "code": "967",
    "country": "YE"
  },
  {
    "code": "262",
    "country": "YT"
  },
  {
    "code": "27",
    "country": "ZA"
  },
  {
    "code": "260",
    "country": "ZM"
  },
  {
    "code": "263",
    "country": "ZW"
  }];
  /* eslint-disable */