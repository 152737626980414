import setAppMessage from "../../app-component/app-component-action";
import {calcelAPiCall} from "../../utils/apiClient";
import {fetchCategories} from "../landing/landing-action";


function piscoPreSave(file) {
  const formData = new FormData();
  formData.append("file", file, file.name);
  return formData;
}

export function updateDomainList(key,value){
  return async (dispatch, getState) => {
    const { landing:{activeDomain}, header:{domainList}}  = getState();
    try {
    if (domainList[activeDomain]) domainList[activeDomain][0][key] = value ;
    dispatch({ type:"UpdateDomainList", domainList});
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export default function fetchDomainDetails() {
    return async (dispatch, getState, apiHandler) => {
      const {api, apiRoutes} = apiHandler;
      const { activeDomain } = getState().landing;
      try {
      let { data:domainDetail } = await api(apiRoutes.getDomainDetailsApi(activeDomain));
      return Promise.resolve(domainDetail);
      } catch (err) {
        dispatch(setAppMessage({ message: err.message, header: 'Error', type: 'alert' }))
        return Promise.reject(err);
      }
    }
}

export function setDomainDetails(payload){
  return async (dispatch, getState, apiHandler) => {
    const {api, apiRoutes} = apiHandler;
    const { activeDomain } = getState().landing;
    try {
    let { data } = await api(apiRoutes.updateDomainDetailsApi(payload,activeDomain));
    dispatch(setAppMessage({ message: data.message, header: 'Success', type: 'alert' }));
    return Promise.resolve(data);
    } catch (err) {
      dispatch(setAppMessage({ message: err.data.error, header: 'Error', type: 'alert' }));
      return Promise.reject(err);
    }
  }
}

export function checkWebmasterVerified() {
  return async (dispatch, getState, apiHandler) => {
    const {api, apiRoutes} = apiHandler;
    const {activeDomain} = getState().landing;
    try {
      const {data} = await api(apiRoutes.checkWebmasterVerified(activeDomain));
      return Promise.resolve(data);
    } catch (err) {
      dispatch(setAppMessage({ message: (err.data && err.data.error)|| 'Failed to fetch webmaster info', header: 'Error', type: 'alert' }));
      return Promise.reject(err);
    }
  }
}
export function createWebmasterToken (token, fqdn) {
  return async (dispatch, getState, apiHandler) => {
    const site = `https://${fqdn}`;
    const {api, apiRoutes} = apiHandler;
    const {activeDomain, userProfile:{email}} = getState().landing;
    try {
      const {data} = await api(apiRoutes.createWebmasterToken(activeDomain, token, site, email));
      return Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    } 
  }
}

export function updateWebmasterMapStatus(status) {
  return async (dispatch, getState, apiHandler) => {
    const {api, apiRoutes} = apiHandler;
    const {activeDomain} = getState().landing;
    try {
      const {data} = await api(apiRoutes.updateWebmasterMapStatus(activeDomain, status));
      return Promise.resolve(data);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}
export function saveMediaToPisco(file) {
  return async (dispatch, getState, apiHandler) => {
    try {
      if (!file) {
        return Promise.reject(new Error('Invalid Media File'));
      }
      const { api, apiRoutes } = apiHandler;
      let name = file.name.replace(/[^a-zA-Z.0-9]/g, '');
      file = new File([file], name, { type: file.type });
      const { landing: { activeDomain }} = getState();
      const preSaveData = piscoPreSave(file);
      let {data:preSaveResponse} = await api(apiRoutes.piscoPreSaveApi(preSaveData, activeDomain));
      return Promise.resolve({ preSaveResponse });
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export function addSubCat ({categoryId,payload}){
  return async (dispatch, getState, apiHandler) => {
    const {api, apiRoutes} = apiHandler;
    const { activeDomain } = getState().landing;
    try {
    let { data } = await api(apiRoutes.addSubCategory(activeDomain,categoryId,payload));
    await dispatch(fetchCategories())
    dispatch(setAppMessage({ message: "added sub category", header: 'Success', type: 'alert' }))
    return Promise.resolve(data.result);
    } catch (err) {
      dispatch(setAppMessage({ message: err.data.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}

export function editSubCat({subCategoryId,payload}){
  return async (dispatch, getState, apiHandler) => {
    const {api, apiRoutes} = apiHandler;
    const { activeDomain } = getState().landing;
    try {
    let { data } = await api(apiRoutes.editSubCategory(activeDomain,subCategoryId,payload));
    await dispatch(fetchCategories())
    dispatch(setAppMessage({ message: "successfully edited Subcategory", header: 'Success', type: 'alert' }))
    return Promise.resolve(data);
    } catch (err) {
      dispatch(setAppMessage({ message: err.data.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}

export function addCat(payload){
  return async (dispatch, getState, apiHandler) => {
    const {api, apiRoutes} = apiHandler;
    const { activeDomain } = getState().landing;
    try {
    let { data } = await api(apiRoutes.addCategory(activeDomain,payload));
    await dispatch(fetchCategories())
    dispatch(setAppMessage({ message: "successfully added category", header: 'Success', type: 'alert' }))
    return Promise.resolve(data);
    } catch (err) {
      dispatch(setAppMessage({ message: err.data.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}

export function dltWebsite (){
  return async (dispatch, getState, apiHandler) => {
    const {api, apiRoutes} = apiHandler;
    const { activeDomain } = getState().landing;
    try {
    let { data } = await api(apiRoutes.deleteWebsite(activeDomain));
    dispatch(setAppMessage({ message: "successfully deleted website", header: 'Success', type: 'alert' }))
    return Promise.resolve(data);
    } catch (err) {
      dispatch(setAppMessage({ message: err.data.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}

export function editCat (categoryId,payload){
  return async (dispatch, getState, apiHandler) => {
    const {api, apiRoutes} = apiHandler;
    const { activeDomain } = getState().landing;
    try {
    let { data } = await api(apiRoutes.editCategory(activeDomain,categoryId,payload));
    await dispatch(fetchCategories())
    dispatch(setAppMessage({ message: data && data.result && data.result.message, header: 'Success', type: 'alert' }))
    return Promise.resolve(data);
    } catch (err) {
      dispatch(setAppMessage({ message: err.data.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}

export function getMapDomainStatus(){
  return async (dispatch, getState, apiHandler) => {
    const {api, apiRoutes} = apiHandler;
    const { activeDomain } = getState().landing;
    try {
    let { data } = await api(apiRoutes.mapGetWebsite(activeDomain));
    return Promise.resolve(data);
    } catch (err) {
      dispatch(setAppMessage({ message: err.data.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}
export function postMapDomainStatus(payload,domainName,showStatus=true){
  return async (dispatch, getState, apiHandler) => {
    const {api, apiRoutes} = apiHandler;
    const { activeDomain } = getState().landing;
    try {
    let { data } = await api(apiRoutes.mapPostWebsite(activeDomain,payload));
    const { status } = data;

    if (status === 'active'){
      domainName && dispatch(updateDomainList('fqdn',domainName));
    }

    if (showStatus && status ==='pending'){
      dispatch(setAppMessage({ message: `${domainName}‘s nameservers have not yet 
      been successfully transferred from your previous registrar management service. 
      This normally takes a little time so no need to worry! Please check back in a few hours. 
      If the verification has not been completed within 24 hours, then please make sure the nameservers were correctly replaced. If you have any questions, 
      you can reach our customer success team through the chat box in the corner right of your screen!`, 
      header: 'Verifying setup', type: 'alert' }));
    }
    
    return Promise.resolve(data);
    } catch (err) {
      dispatch(setAppMessage({ message: err.data.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}

export function getDnsRecords (){
  return async (dispatch, getState, apiHandler) => {
    const {api, apiRoutes} = apiHandler;
    const { activeDomain } = getState().landing;
    try {
    let { data } = await api(apiRoutes.dnsRecords(activeDomain));
    return Promise.resolve(data);
    } catch (err) {
      dispatch(setAppMessage({ message: err.data.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}
export function deleteDnsRecords (payload){
  return async (dispatch, getState, apiHandler) => {
    const {api, apiRoutes} = apiHandler;
    const { activeDomain } = getState().landing;
    try {
    let { data } = await api(apiRoutes.delteDnsRecords(activeDomain,payload));
    dispatch(setAppMessage({ message: data.message, header: 'Success', type: 'alert' }))
    return Promise.resolve(data);
    } catch (err) {
      dispatch(setAppMessage({ message: err.data.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}

export function updateDnsRecords (payload){
  return async (dispatch, getState, apiHandler) => {
    const {api, apiRoutes} = apiHandler;
    const { activeDomain } = getState().landing;
    try {
    let { data } = await api(apiRoutes.updateDnsRecords(activeDomain,payload));
    dispatch(setAppMessage({ message: data.message, header: 'Success', type: 'alert' }))
    return Promise.resolve(data);
    } catch (err) {
      dispatch(setAppMessage({ message: err.data.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}
export function addNewDnsRecords (payload){
  return async (dispatch, getState, apiHandler) => {
    const {api, apiRoutes} = apiHandler;
    const { activeDomain } = getState().landing;
    try {
    let { data } = await api(apiRoutes.addDnsRecords(activeDomain,payload));
    dispatch(setAppMessage({ message: data.message, header: 'Success', type: 'alert' }))
    return Promise.resolve(data);
    } catch (err) {
      dispatch(setAppMessage({ message: err.data.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}

export function getAboutPages(){
  return async (dispatch, getState, apiHandler) => {
    const {api, apiRoutes} = apiHandler;
    const { activeDomain } = getState().landing;
    try {
    let { data } = await api(apiRoutes.getPages(activeDomain));
    return Promise.resolve(data.result);
    } catch (err) {
      dispatch(setAppMessage({ message: err.data.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}

export function addAboutPages(slug){
  return async (dispatch, getState, apiHandler) => {
    const {api, apiRoutes} = apiHandler;
    const { activeDomain } = getState().landing;
    try {
    let { data } = await api(apiRoutes.addPages(activeDomain,slug));
    // dispatch(setAppMessage({ message: data.result.message, header: 'Success', type: 'alert' }))
    return Promise.resolve(data.result);
    } catch (err) {
      dispatch(setAppMessage({ message: err.data.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}

export function deleteAboutPages (pageId){
  return async (dispatch, getState, apiHandler) => {
    const {api, apiRoutes} = apiHandler;
    const { activeDomain } = getState().landing;
    try {
    let { data } = await api(apiRoutes.deletePage(activeDomain,pageId));
    // dispatch(setAppMessage({ message: data.result.message, header: 'Success', type: 'alert' }))
    return Promise.resolve(data.result);
    } catch (err) {
      dispatch(setAppMessage({ message: err.data.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}

export function  getCurrentPageDetail(pageId){
  return async (dispatch, getState, apiHandler) => {
    const {api, apiRoutes} = apiHandler;
    const { activeDomain } = getState().landing;
    try {
    let { data } = await api(apiRoutes.getpageDetails(activeDomain,pageId));
    return Promise.resolve(data.result);
    } catch (err) {
      dispatch(setAppMessage({ message: err.data.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}

export function updatePageDetails (pageId,payload){
  return async (dispatch, getState, apiHandler) => {
    const {api, apiRoutes} = apiHandler;
    const { activeDomain } = getState().landing;
    try {
    let { data } = await api(apiRoutes.updatePageDetails(activeDomain,pageId,payload));
    return Promise.resolve(data.result);
    } catch (err) {
      dispatch(setAppMessage({ message: err.data.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}
export function publishPage(pageId,gotToPages){
  return async (dispatch, getState, apiHandler) => {
    const {api, apiRoutes} = apiHandler;
    const { activeDomain } = getState().landing;
    try {
    let { data } = await api(apiRoutes.publishPage(activeDomain,pageId));
    dispatch(setAppMessage({ message: data.result.message, header: 'Success', type: 'alert',handleSuccess:gotToPages }))
    return Promise.resolve(data.result);
    } catch (err) {
      dispatch(setAppMessage({ message: err.data.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}

export function createNewPage (){
  return async (dispatch, getState, apiHandler) => {
    const {api, apiRoutes} = apiHandler;
    const { activeDomain } = getState().landing;
    try {
    let { data } = await api(apiRoutes.createPage(activeDomain));
    return Promise.resolve(data.result);
    } catch (err) {
      dispatch(setAppMessage({ message: err.data.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}

function localUpload(file) {
  let mediaType = 'Image';
  if (file.type.startsWith('video')) mediaType = 'Video';
  if (file.type.startsWith('audio')) mediaType = 'Audio';
  const formData = new FormData();
  formData.append("file", new Blob());
  formData.append('filename', file.name);
  formData.append('title', file.name);
  formData.append('tags', ['quick-cms'])
  formData.append('imageSource', 'LOCAL');
  formData.append('type', mediaType);
  formData.append('dimensions', {});
  formData.append('caption', file.name);
  return formData;
}

export function savePageMediaToPisco(file) {
  return async (dispatch, getState, apiHandler) => {
    try {
      if (!file) {
        return Promise.reject(new Error('Invalid Media File'));
      }
      const { api, apiRoutes } = apiHandler;
      let name = file.name.replace(/[^a-zA-Z.0-9]/g, '');
      file = new File([file], name, { type: file.type });
      const { landing: { activeDomain }} = getState();
      const preSaveData = piscoPreSave(file);
      let {data:preSaveResponse} = await api(apiRoutes.piscoPreSaveApi(preSaveData, activeDomain));
      const localUploadData = localUpload(file);
      let {data:localUploadResponse} = await api(apiRoutes.localUploadApi(localUploadData, activeDomain));
      return Promise.resolve({ preSaveResponse, localUploadResponse });
    } catch (error) {
      return Promise.reject(error);
    }
  }
}


export function EditMapDomain(payload){
  return async (dispatch, getState, apiHandler) => {
    const {api, apiRoutes} = apiHandler;
    const { activeDomain } = getState().landing;
    try {
    let { data } = await api(apiRoutes.deleteMapWebsite(activeDomain,payload));
    return Promise.resolve(data.result);
    } catch (err) {
      dispatch(setAppMessage({ message: err.data.message, header: 'Error', type: 'alert' }))
      return Promise.reject(err);
    }
  }
}


export function updateSection(id,data){
  return async (dispatch, getState, apiHandler) => {
    const { landing: { activeDomain }} = getState();
    const { api, apiRoutes } = apiHandler;
    try {
      let {data:result} = await api(apiRoutes.updateSectionById(activeDomain,id,data));
      await dispatch(fetchCategories());
      dispatch(setAppMessage({ message: result.message, header: 'Success', type: 'alert'}))
      return Promise.resolve(result);
    } catch (err) {
      dispatch(setAppMessage({ message: err.message, header: 'Error', type: 'alert'}))
      return Promise.reject(err);
    }
  }
}


export function createSection(data) {
  return async (dispatch, getState, apiHandler) => {
    const { landing: { activeDomain }} = getState();
    const { api, apiRoutes } = apiHandler;
    try {
      let {data:result} = await api(apiRoutes.createSection(activeDomain,data));
      await dispatch(fetchCategories());
      return Promise.resolve(result);
    } catch (err) {
      dispatch(setAppMessage({ message: err.message, header: 'Error', type: 'alert'}))
      return Promise.reject(err);
    }
  }
}
