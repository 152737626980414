
import React from "react";
import { CircularLoader } from "commons/commonWrapper/Loaders";
import Dialog from "commons/muiCustomComponents/Dailog";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "commons/muiCustomComponents/Box";
import { makeStyles } from '@material-ui/core/styles';
import CheckCircle from "@material-ui/icons/CheckCircle";
import MobileProvider from "commons/context/MobileProvider";


const useStyles = makeStyles(theme =>({
  content : {
    width:`${theme.isMobile ? '100%':'400px'}`,
    height:`${theme.isMobile ? '100vh':'400px'}`, 
    display: 'flex', 
    justifyContent:'center', 
    alignItems:'center',
  },
  loadersection:{
    height:'100%',
  },
  successBox: {
    width:'100%',
    height:'100%',
    display:'flex',
    backgroundColor:theme.palette.pubGreenBlue,
    justifyContent:'center', 
    alignItems:'center',
    color:'white',
    flexDirection:'column',
  },
  message: {
    position:'relative',
    top:theme.spacing(1.5),
    fontSize:theme.spacing(5),
  },
}));

function ProcessingBox(props) {
  const classes = useStyles();
  const isMobile = React.useContext(MobileProvider);
  return(
    <Dialog fullScreen={isMobile} open={props.open} maxWidth={'xs'} >
      <DialogContent style={{padding:'0px'}}>
        <Box className={classes.content}>
            {props.savingarticle && <CircularLoader className={classes.loadersection} message="Processing..." />}
            {!props.savingarticle && <Box className={classes.successBox}>
                <Box><CheckCircle style={{fontSize:'40px'}} /></Box>
                <Box className={classes.message}>Successful</Box>
              </Box>}
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default ProcessingBox;