import adapter from 'webrtc-adapter';

export default function WebRTCAdaptor (initialValues) {
  class PeerStats {
    constructor(streamId) {
      this.streamId = streamId;
      this.totalBytesReceivedCount = 0;
      this.totalBytesSent = 0;
      this.packetsLost = 0;
      this.fractionLost = 0;
      this.startTime = 0;
      this.lastBytesReceived = 0;
      this.lastBytesSent = 0;
      this.currentTimestamp = 0;
      this.lastTime = 0;
      this.timerId = 0;
      this.firstByteSentCount = 0;
      this.firstBytesReceivedCount = 0;
      this.lastCurrentBitrates = [];
      this.totalEncodedBytesTarget = 0;
      this.lastEncodedBytesTarget = 0;
      this.encodedBytesPerSecond = 0;
    }
    get averageOutgoingBitrate() {
      return Math.floor(8 * (this.totalBytesSentCount - this.firstByteSentCount) / (this.currentTimestamp - this.startTime));
    }
    get averageIncomingBitrate() {
      return Math.floor(8 * (this.totalBytesReceivedCount - this.firstBytesReceivedCount) / (this.currentTimestamp - this.startTime));
    }
    get currentOutgoingBitrate() {
      return Math.floor(8 * (this.totalBytesSentCount - this.lastBytesSent) / (this.currentTimestamp - this.lastTime));
    }
    get currentExpectedBitrate() {
      return this.encodedBytesPerSecond;
    }
    get movingAverageOutgoingBitrate() {
      return Math.floor(8 * (this.totalBytesSentCount - this.lastBytesSent) / (this.currentTimestamp - this.lastTime));
    }
    get currentIncomingBitrate() {
      return Math.floor(8 * (this.totalBytesReceivedCount - this.lastBytesReceived) / (this.currentTimestamp - this.lastTime));
    }
    get movingAverage() {
      return this.lastCurrentBitrates.reduce((a, b) => a + b, 0) / this.lastCurrentBitrates.length;
    }
    set currentTime(timestamp) {
      this.lastTime = this.currentTimestamp;
      this.currentTimestamp = timestamp;
      if (this.startTime == 0) {
        this.startTime = timestamp - 1;
      }
    }
    set totalBytesReceived(bytesReceived) {
      this.lastBytesReceived = this.totalBytesReceivedCount;
      this.totalBytesReceivedCount = bytesReceived;
      if (this.firstBytesReceivedCount == 0) {
        this.firstBytesReceivedCount = bytesReceived;
      }
    }
    set totalBytesSent(bytesSent) {
      this.lastBytesSent = this.totalBytesSentCount;
      this.encodedBytesPerSecond = (this.totalEncodedBytesTarget - this.lastEncodedBytesTarget) * 8 / 1024;
      this.lastEncodedBytesTarget = this.totalEncodedBytesTarget;
      this.totalBytesSentCount = bytesSent;
      if (this.firstByteSentCount == 0) {
        this.firstByteSentCount = bytesSent;
      }
      if (!this.lastCurrentBitrates) {
        return;
      }
      this.lastCurrentBitrates.unshift(this.currentOutgoingBitrate);
      this.lastCurrentBitrates = this.lastCurrentBitrates.slice(0, 20)
    }
  }
  console.log(this)
  var thiz = this;
  thiz.peerconnection_config = null;
  thiz.sdp_constraints = null;
  thiz.remotePeerConnection = new Array();
  thiz.remotePeerConnectionStats = new Array();
  thiz.remoteDescriptionSet = new Array();
  thiz.iceCandidateList = new Array();
  thiz.webSocketAdaptor = null;
  thiz.roomName = null;
  thiz.videoTrackSender = null;
  thiz.audioTrackSender = null;
  thiz.playStreamId = new Array();
  thiz.micGainNode = null;
  thiz.localStream = null;
  thiz.bandwidth = 900;
  thiz.isPlayMode = false;
  thiz.debug = false;
  thiz.camera_location = "top"
  thiz.camera_margin = 15;
  thiz.camera_percent = 15;
  thiz.allowsZoom = false;
  thiz.safari = false;
  thiz.defaultZoomCallback = () => {};
  thiz.zoomStatusCallback = () => {};

  for (var key in initialValues) {
    if (initialValues['hasOwnProperty'](key)) {
      this[key] = initialValues[key];
    }
  }
  thiz.localVideo = document.getElementById(thiz.localVideoId);
  thiz.remoteVideo = document.getElementById(thiz.remoteVideoId);
  if (!("WebSocket" in window)) {
    console.log("WebSocket not supported.");
    thiz.callbackError("WebSocketNotSupported");
    return;
  }
  if (typeof navigator.mediaDevices == "undefined" && thiz.isPlayMode == false) {
    console.log("Cannot open camera and mic because of unsecure context. Please Install SSL(https)");
    thiz.callbackError("UnsecureContext");
    return;
  }
  var audioStream;
  this.getUserMedia = function(mediaConstraints, audioConstraint) {
    if (!thiz.safari) {
      thiz.videoWidth = {width: mediaConstraints.video.width}
      delete mediaConstraints.video.width
      if (mediaConstraints.audio) {
        mediaConstraints.audio.optional = [
          {deviceId: mediaConstraints.audio.deviceId},
          {echoCancellation: true}, {noiseSuppression: true}, {autoGainControl: false}, {googEchoCancellation: true}, {googAutoGainControl: true},
          {googNoiseSuppression: true}, {googHighpassFilter: true}, {googExperimentalNoiseSuppression: true},
          {googExperimentalEchoCancellation: true}, {googExperimentalAutoGainControl: true},
        ]
        delete mediaConstraints.audio.deviceId
      }
      mediaConstraints.video.frameRate = {ideal: 15};
    }

    console.log(mediaConstraints)

    navigator.mediaDevices.getUserMedia(mediaConstraints).then(function (stream) {
      setTimeout(() => {
        const track = stream.getVideoTracks()[0];
        const capabilities = track.getCapabilities();
        const settings = track.getSettings();
        if ('zoom' in capabilities) {
          thiz.allowsZoom = true;
          thiz.zoomStatusCallback(true);
          thiz.defaultZoomCallback(settings.zoom);
        }
      }, 2500);

      thiz.gotStream(stream);
    }).catch(function (error) {
      thiz.callbackError(error.name, error.message);
    });
  }
  this.openScreen = function(audioConstraint, openCamera) {
    var callback = function(message) {
      if (message.data == "rtcmulticonnection-extension-loaded") {
        console.debug("rtcmulticonnection-extension-loaded parameter is received");
        window.postMessage("get-sourceId", "*");
      } else if (message.data == "PermissionDeniedError") {
        console.debug("Permission denied error");
        thiz.callbackError("screen_share_permission_denied");
      } else if (message.data && message.data.sourceId) {
        var mediaConstraints = {
          audio: false,
          video: {
            mandatory: {
              chromeMediaSource: 'desktop',
              chromeMediaSourceId: message.data.sourceId,
            },
            optional: [],
          },
        };
        thiz.getUserMedia(mediaConstraints, audioConstraint);
        window.removeEventListener("message", callback);
      }
    }
    window.addEventListener("message", callback, false);
    window.postMessage("are-you-there", "*");
  }

  this.initDataChannel = function(streamId, dataChannel) {
    dataChannel.onerror = (error) => {
      console.log("Data Channel Error:", error );
      var obj = {
        streamId: streamId,
        error: error,
      };
      console.log("channel status: ", dataChannel.readyState);
      if (dataChannel.readyState != "closed") {
        thiz.callbackError("data_channel_error", obj);
      }
    };

    dataChannel.onmessage = (event) => {
      var obj = {
        streamId: streamId,
        event: event,
      };
      thiz.callback("data_received", obj);
    };

    dataChannel.onopen = () => {
      thiz.remotePeerConnection[streamId].dataChannel = dataChannel;
      console.log("Data channel is opened");
      thiz.callback("data_channel_opened", streamId)
    };

    dataChannel.onclose = () => {
      console.log("Data channel is closed");
      thiz.callback("data_channel_closed", streamId);
    };

  }

  this.openStream = function(mediaConstraints) {
    thiz.mediaConstraints = mediaConstraints;
    var audioConstraint = false;
    if (typeof mediaConstraints.audio != "undefined" && mediaConstraints.audio != false) {
      audioConstraint = mediaConstraints.audio;
    }
    if (typeof mediaConstraints.video != "undefined") {
      if (mediaConstraints.video == "screen+camera" || mediaConstraints.video == "screen") {
        this.openScreen(audioConstraint);
      } else {
        thiz.getUserMedia(mediaConstraints, audioConstraint);
      }
    } else {
      console.error("MediaConstraint video is not defined");
      thiz.callbackError("media_constraint_video_not_defined");
    }
  }
  this.closeStream = function() {
    thiz.localStream.getVideoTracks().forEach(function(track) {
      track.onended = null;
      track.stop();
    });
    thiz.localStream.getAudioTracks().forEach(function(track) {
      track.onended = null;
      track.stop();
    });
  }
  this.checkExtension = function() {
    var callback = function(message) {
      if (message.data == "rtcmulticonnection-extension-loaded") {
        thiz.callback("screen_share_extension_available");
        window.removeEventListener("message", callback);
      }
    };
    window.addEventListener("message", callback, false);
    window.postMessage("are-you-there", "*");
  };
  thiz.checkExtension();
  if (!this.isPlayMode && typeof thiz.mediaConstraints != "undefined" && this.localStream == null) {
    if (typeof thiz.mediaConstraints.video != "undefined" && thiz.mediaConstraints.video != false) {
      if (thiz.mediaConstraints.audio.mandatory) {
        navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        }).then(function(micStream) {
          navigator.mediaDevices.getUserMedia(thiz.mediaConstraints).then(function(stream) {
            var audioContext = new AudioContext();
            var desktopSoundGainNode = audioContext.createGain();
            desktopSoundGainNode.gain.value = 1;
            var audioDestionation = audioContext.createMediaStreamDestination();
            var audioSource = audioContext.createMediaStreamSource(stream);
            audioSource.connect(desktopSoundGainNode);
            thiz.micGainNode = audioContext.createGain();
            thiz.micGainNode.gain.value = 1;
            var audioSource2 = audioContext.createMediaStreamSource(micStream);
            audioSource2.connect(thiz.micGainNode);
            desktopSoundGainNode.connect(audioDestionation);
            thiz.micGainNode.connect(audioDestionation);
            stream.removeTrack(stream.getAudioTracks()[0]);
            audioDestionation.stream.getAudioTracks().forEach(function(track) {
              stream.addTrack(track);
            });
            console.debug("Running gotStream");
            thiz.gotStream(stream);
          }).catch(function(error) {
            thiz.callbackError(error.name, error.message);
          });
        }).catch(function(error) {
          thiz.callbackError(error.name, error.message);
        });
      } else {
        thiz.openStream(thiz.mediaConstraints, thiz.mode);
      }
    } else {
      var media_audio_constraint = {
        audio: thiz.mediaConstraints.audio,
      };
      navigator.mediaDevices.getUserMedia(media_audio_constraint).then(function(stream) {
        thiz.gotStream(stream);
      }).catch(function(error) {
        thiz.callbackError(error.name, error.message);
      });
    }
  } else {
    if (thiz.webSocketAdaptor == null || thiz.webSocketAdaptor.isConnected() == false) {
      thiz.webSocketAdaptor = new WebSocketAdaptor();
    }
  }
  this.enableMicInMixedAudio = function(enable) {
    if (thiz.micGainNode != null) {
      if (enable) {
        thiz.micGainNode.gain.value = 1;
      } else {
        thiz.micGainNode.gain.value = 0;
      }
    }
  }
  this.publish = function(streamId, token) {
    var jsCmd = {
      command: "publish",
      streamId: streamId,
      token: token,
      video: thiz.localStream.getVideoTracks().length > 0 ? true : false,
      audio: thiz.localStream.getAudioTracks().length > 0 ? true : false,
    };
    console.log(thiz.webSocketAdaptor)
    console.log(jsCmd);
    thiz.webSocketAdaptor.send(JSON.stringify(jsCmd));
  }
  this.joinRoom = function(roomName, streamId) {
    thiz.roomName = roomName;
    var jsCmd = {
      command: "joinRoom",
      room: roomName,
      streamId: streamId,
    }
    thiz.webSocketAdaptor.send(JSON.stringify(jsCmd));
  }
  this.play = function(streamId, token, roomId) {
    thiz.playStreamId.push(streamId);
    var jsCmd = {
      command: "play",
      streamId: streamId,
      token: token,
      room: roomId,
    }
    thiz.webSocketAdaptor.send(JSON.stringify(jsCmd));
  }
  this.stop = function(streamId) {
    thiz.closePeerConnection(streamId);
    var jsCmd = {
      command: "stop",
      streamId: streamId,
    };
    thiz.webSocketAdaptor.send(JSON.stringify(jsCmd));
  }
  this.join = function(streamId) {
    var jsCmd = {
      command: "join",
      streamId: streamId,
    };
    thiz.webSocketAdaptor.send(JSON.stringify(jsCmd));
  }
  this.leaveFromRoom = function(roomName) {
    thiz.roomName = roomName;
    var jsCmd = {
      command: "leaveFromRoom",
      room: roomName,
    };
    console.log("leave request is sent for " + roomName);
    thiz.webSocketAdaptor.send(JSON.stringify(jsCmd));
  }
  this.leave = function(streamId) {
    var jsCmd = {
      command: "leave",
      streamId: streamId,
    };
    thiz.webSocketAdaptor.send(JSON.stringify(jsCmd));
    thiz.closePeerConnection(streamId);
  }
  this.getStreamInfo = function(streamId) {
    var jsCmd = {
      command: "getStreamInfo",
      streamId: streamId,
    };
    this.webSocketAdaptor.send(JSON.stringify(jsCmd));
  }
  this.gotStream = function(stream) {
    thiz.localStream = stream;
    thiz.localVideo.srcObject = stream;
    if (thiz.webSocketAdaptor == null || thiz.webSocketAdaptor.isConnected() == false) {
      thiz.webSocketAdaptor = new WebSocketAdaptor();
    }
  };
  this.switchVideoCapture = function(streamId) {
    var mediaConstraints = {
      video: true,
      audio: false,
    };
    thiz.switchVideoSource(streamId, mediaConstraints, null);
  }
  this.switchDesktopCapture = function(streamId) {
    var screenShareExtensionCallback = function(message) {
      if (message.data == "rtcmulticonnection-extension-loaded") {
        console.debug("rtcmulticonnection-extension-loaded parameter is received");
        window.postMessage("get-sourceId", "*");
      } else if (message.data == "PermissionDeniedError") {
        console.debug("Permission denied error");
        thiz.callbackError("screen_share_permission_denied");
      } else if (message.data && message.data.sourceId) {
        var mediaConstraints = {
          audio: false,
          video: {
            mandatory: {
              chromeMediaSource: 'desktop',
              chromeMediaSourceId: message.data.sourceId,
            },
            optional: [],
          },
        };
        thiz.switchVideoSource(streamId, mediaConstraints, function(event) {
          thiz.callback("screen_share_stopped");
          thiz.switchVideoCapture(streamId);
        });
        window.removeEventListener("message", screenShareExtensionCallback);
      }
    }
    window.addEventListener("message", screenShareExtensionCallback, false);
    window.postMessage("are-you-there", "*");
  }
  thiz.arrangeStreams = function(stream, onEndedCallback) {
    var videoTrack = thiz.localStream.getVideoTracks()[0];
    thiz.localStream.removeTrack(videoTrack);
    videoTrack.stop();
    thiz.localStream.addTrack(stream.getVideoTracks()[0]);
    // stream.getVideoTracks()[0].start();
    console.log(stream.getVideoTracks())
    thiz.localVideo.srcObject = thiz.localStream;
    if (onEndedCallback != null) {
      stream.getVideoTracks()[0].onended = function(event) {
        onEndedCallback(event);
      }
    }
  }
  this.applyConstraints = function(streamId, mediaConstraints) {
    if (!thiz.safari) {
      thiz.videoWidth = {width: mediaConstraints.video.width}
      delete mediaConstraints.video.width
      mediaConstraints.audio.optional = [
        {deviceId: mediaConstraints.audio.deviceId},
        {echoCancellation: true}, {noiseSuppression: true}, {autoGainControl: false}, {googEchoCancellation: true}, {googAutoGainControl: true},
        {googNoiseSuppression: true}, {googHighpassFilter: true}, {googExperimentalNoiseSuppression: true},
        {googExperimentalEchoCancellation: true}, {googExperimentalAutoGainControl: true},
      ]
      mediaConstraints.video.frameRate = {ideal: 15};
      delete mediaConstraints.audio.deviceId
    }
    try {
      // alert(JSON.stringify(mediaConstraints))
      const track = thiz.localStream.getVideoTracks()[0];
      track.applyConstraints(mediaConstraints.video)
        .then(() => {})
        .catch(e => {
          thiz.callbackError(e, e.message);
          if (e.message.includes("satisfy")) {
            thiz.localStream.getVideoTracks()[0].stop();
            thiz.switchVideoSource(streamId, mediaConstraints, () => {})
          }
        });
    } catch(e) {
      console.error(e);
    }
  }

  this.switchVideoSource = function(streamId, mediaConstraints, onEndedCallback) {
    if (!thiz.safari) {
      thiz.videoWidth = {width: mediaConstraints.video.width}
      delete mediaConstraints.video.width
      mediaConstraints.audio.optional = [
        {deviceId: mediaConstraints.audio.deviceId},
        {echoCancellation: true}, {noiseSuppression: true}, {autoGainControl: false}, {googEchoCancellation: true}, {googAutoGainControl: true},
        {googNoiseSuppression: true}, {googHighpassFilter: true}, {googExperimentalNoiseSuppression: true},
        {googExperimentalEchoCancellation: true}, {googExperimentalAutoGainControl: true},
      ]
      mediaConstraints.video.frameRate = {ideal: 15};
      delete mediaConstraints.audio.deviceId
    }
    thiz.localStream.getVideoTracks()[0].stop();
    setTimeout(() => {
      navigator.mediaDevices.getUserMedia(mediaConstraints).then(function(stream) {
        if (thiz.remotePeerConnection[streamId] != null) {
          var videoTrackSender = thiz.remotePeerConnection[streamId].getSenders().find(function(s) {
            return s.track.kind == "video";
          });

          videoTrackSender.replaceTrack(stream.getVideoTracks()[0]).then(function(result) {
            thiz.arrangeStreams(stream, onEndedCallback);
          }).catch(function(error) {
            console.log(error.name);
          });

        var audioTrackSender = thiz.remotePeerConnection[streamId].getSenders().find(function(s) {
          return s.track.kind == "audio";
        });
        if (audioTrackSender) {
          audioTrackSender.replaceTrack(stream.getAudioTracks()[0]).then(function(result) {
            // no need to arrange for audio stream
            // thiz.arrangeStreams(stream, onEndedCallback);
          }).catch(function(error) {
            console.log(error.name);
          });
        }
      } else {
        thiz.arrangeStreams(stream, onEndedCallback);
      }
    }).catch((error) => {
      thiz.callbackError(error.name);
    });
  })
  }
  this.onTrack = function(event, streamId) {
    console.log("onTrack");
    if (thiz.remoteVideo != null) {
      if (thiz.remoteVideo.srcObject !== event.streams[0]) {
        thiz.remoteVideo.srcObject = event.streams[0];
        console.log('Received remote stream');
      }
    } else {
      var dataObj = {
        track: event.streams[0],
        streamId: streamId,
      }
      thiz.callback("newStreamAvailable", dataObj);
    }
  }
  this.iceCandidateReceived = function(event, streamId) {
    if (event.candidate) {
      var jsCmd = {
        command: "takeCandidate",
        streamId: streamId,
        label: event.candidate.sdpMLineIndex,
        id: event.candidate.sdpMid,
        candidate: event.candidate.candidate,
      };
      if (thiz.debug) {
        console.log("sending ice candiate for stream Id " + streamId);
        console.log(JSON.stringify(event.candidate));
      }
      thiz.webSocketAdaptor.send(JSON.stringify(jsCmd));
    }
  }
  this.initPeerConnection = function(streamId, dataChannelMode) {
    if (thiz.remotePeerConnection[streamId] == null) {
      var closedStreamId = streamId;
      console.log("stream id in init peer connection: " + streamId + " close dstream id: " + closedStreamId);
      thiz.remotePeerConnection[streamId] = new RTCPeerConnection(thiz.peerconnection_config, {
        optional: [
          { googIPv6: true },
          { googImprovedWifiBwe: true },
          { googDscp: true },
          { googSuspendBelowMinBitrate: true },
          { googCombinedAudioVideoBwe: true },
          { googCpuOveruseDetection: true },
          { googCpuOveruseEncodeUsage: true },
          { googCpuUnderuseThreshold: 55 },
          { googCpuOverUseThreshold: 85 },
          { googHighStartBitrate: 0 },

        ],
      });
      thiz.remoteDescriptionSet[streamId] = false;
      thiz.iceCandidateList[streamId] = new Array();
      if (!thiz.playStreamId.includes(streamId)) {
        thiz.remotePeerConnection[streamId].addStream(thiz.localStream);
      }
      thiz.remotePeerConnection[streamId].onicecandidate = function(event) {
        thiz.iceCandidateReceived(event, closedStreamId);
      }
      thiz.remotePeerConnection[streamId].ontrack = function(event) {
        thiz.onTrack(event, closedStreamId);
      }
      if (!thiz.isPlayMode) {
        thiz.remotePeerConnection[streamId].oniceconnectionstatechange = function(event) {
          if (thiz.remotePeerConnection[streamId] && thiz.remotePeerConnection[streamId].iceConnectionState == "connected") {
            // allow auto bandwidth
            thiz.changeBandwidth(thiz.bandwidth, streamId).then(() => {
              console.log("Bandwidth is changed to " + thiz.bandwidth);
            }).catch(e => console.error(e));
          }
        }
      }
      if (dataChannelMode == "publish") {
        //open data channel if it's publish mode peer connection
        const dataChannelOptions = {
          ordered: true,
          priority: 'high',
        };

        var dataChannel = thiz.remotePeerConnection[streamId].createDataChannel(streamId, dataChannelOptions);
        thiz.initDataChannel(streamId, dataChannel);

      } else if(dataChannelMode == "play") {
        //in play mode, server opens the data channel
        thiz.remotePeerConnection[streamId].ondatachannel = function(ev) {
          thiz.initDataChannel(streamId, ev.channel);
        };
      }
      else {
        //for peer mode do both for now
        const dataChannelOptions = {
          ordered: true,
        };

        var dataChannelPeer = thiz.remotePeerConnection[streamId].createDataChannel(streamId, dataChannelOptions);
        thiz.initDataChannel(streamId, dataChannelPeer);

        thiz.remotePeerConnection[streamId].ondatachannel = function(ev) {
          thiz.initDataChannel(streamId, ev.channel);
        };
      }

    }
  }

  this.sendData = function(streamId, message) {
    var dataChannel = thiz.remotePeerConnection[streamId].dataChannel;
    if (dataChannel && dataChannel.readyState == 'open') {
      dataChannel.send(message);
    } else {
      console.error("Could not send data channel message ", streamId, message);
    }
  }

  this.closePeerConnection = function(streamId) {
    if (thiz.remotePeerConnection[streamId] != null && thiz.remotePeerConnection[streamId].signalingState != "closed") {
      thiz.remotePeerConnection[streamId].close();
      thiz.remotePeerConnection[streamId] = null;
      delete thiz.remotePeerConnection[streamId];
      var playStreamIndex = thiz.playStreamId.indexOf(streamId);
      if (playStreamIndex != -1) {
        thiz.playStreamId.splice(playStreamIndex, 1);
      }
    }
    if (thiz.remotePeerConnectionStats[streamId] != null) {
      clearInterval(thiz.remotePeerConnectionStats[streamId].timerId);
      delete thiz.remotePeerConnectionStats[streamId];
    }
  }
  this.signallingState = function(streamId) {
    if (thiz.remotePeerConnection[streamId] != null) {
      return thiz.remotePeerConnection[streamId].signalingState;
    }
    return null;
  }
  this.iceConnectionState = function(streamId) {
    if (thiz.remotePeerConnection[streamId] != null) {
      return thiz.remotePeerConnection[streamId].iceConnectionState;
    }
    return null;
  }
  this.gotDescription = function(configuration, streamId) {
    thiz.remotePeerConnection[streamId].setLocalDescription(configuration).then(function(responose) {
      console.debug("Set local description successfully for stream Id " + streamId);
      var jsCmd = {
        command: "takeConfiguration",
        streamId: streamId,
        type: configuration.type,
        sdp: configuration.sdp,
      };
      if (thiz.debug) {
        console.debug("local sdp: ");
        console.debug(configuration.sdp);
      }
      thiz.webSocketAdaptor.send(JSON.stringify(jsCmd));
    }).catch(function(error) {
      console.error("Cannot set local description. Error is: " + error);
    });
  }
  this.turnOffLocalCamera = function() {
    if (thiz.remotePeerConnection != null) {
      var track = thiz.localStream.getVideoTracks()[0];
      track.enabled = false;
    } else {
      this.callbackError("NoActiveConnection");
    }
  }
  this.turnOnLocalCamera = function() {
    if (thiz.remotePeerConnection != null) {
      var track = thiz.localStream.getVideoTracks()[0];
      track.enabled = true;
    } else {
      this.callbackError("NoActiveConnection");
    }
  }
  this.muteLocalMic = function() {
    if (thiz.remotePeerConnection != null) {
      var track = thiz.localStream.getAudioTracks()[0];
      track.enabled = false;
    } else {
      this.callbackError("NoActiveConnection");
    }
  }
  this.unmuteLocalMic = function() {
    if (thiz.remotePeerConnection != null) {
      var track = thiz.localStream.getAudioTracks()[0];
      track.enabled = true;
    } else {
      this.callbackError("NoActiveConnection");
    }
  }
  this.takeConfiguration = function(idOfStream, configuration, typeOfConfiguration) {
    var streamId = idOfStream
    var type = typeOfConfiguration;
    var conf = configuration;
    thiz.initPeerConnection(streamId);
    thiz.remotePeerConnection[streamId].setRemoteDescription(new RTCSessionDescription({
      sdp: conf,
      type: type,
    })).then(function(response) {
      if (thiz.debug) {
        console.debug("set remote description is succesfull with response: " + response + " for stream : " +
          streamId + " and type: " + type);
        console.debug(conf);
      }
      thiz.remoteDescriptionSet[streamId] = true;
      var length = thiz.iceCandidateList[streamId].length;
      console.debug("Ice candidate list size to be added: " + length);
      for (var i = 0; i < length; i++) {
        thiz.addIceCandidate(streamId, thiz.iceCandidateList[streamId][i]);
      }
      thiz.iceCandidateList[streamId] = [];
      if (type == "offer") {
        console.log("try to create answer for stream id: " + streamId);
        thiz.remotePeerConnection[streamId].createAnswer(thiz.sdp_constraints).then(function(configuration) {
          console.log("created answer for stream id: " + streamId);
          console.log(configuration)
          configuration.sdp = configuration.sdp.replace('useinbandfec=1', 'useinbandfec=1; stereo=1; maxaveragebitrate=300000; usedtx=1');
          thiz.gotDescription(configuration, streamId);
        }).catch(function(error) {
          console.error("create answer error :" + error);
        });
      }
    }).catch(function(error) {
      if (thiz.debug) {
        console.error("set remote description is failed with error: " + error);
      }
      if (error.toString().indexOf("InvalidAccessError") > -1 || error.toString().indexOf("setRemoteDescription") > -1) {
        thiz.callbackError("notSetRemoteDescription");
      }
    });
  }
  this.takeCandidate = function(idOfTheStream, tmpLabel, tmpCandidate) {
    var streamId = idOfTheStream;
    var label = tmpLabel;
    var candidateSdp = tmpCandidate;
    var candidate = new RTCIceCandidate({
      sdpMLineIndex: label,
      candidate: candidateSdp,
    });
    thiz.initPeerConnection(streamId);
    if (thiz.remoteDescriptionSet[streamId] == true) {
      thiz.addIceCandidate(streamId, candidate);
    } else {
      // console.debug("Ice candidate is added to list because remote description is not set yet");
      thiz.iceCandidateList[streamId].push(candidate);
    }
  };
  this.addIceCandidate = function(streamId, candidate) {
    thiz.remotePeerConnection[streamId].addIceCandidate(candidate).then(function(response) {
      if (thiz.debug) {
        console.log("Candidate is added for stream " + streamId);
      }
    }).catch(function(error) {
      console.error("ice candiate cannot be added for stream id: " + streamId + " error is: " + error);
      console.error(candidate);
    });
  };
  this.startPublishing = function(idOfStream) {
    var streamId = idOfStream;
    thiz.initPeerConnection(streamId, "publish");
    thiz.remotePeerConnection[streamId].createOffer(thiz.sdp_constraints).then(function(configuration) {
      console.log(configuration)
      configuration.sdp = configuration.sdp.replace('useinbandfec=1', 'useinbandfec=1;stereo=1;maxaveragebitrate=300000;usedtx=1');
      thiz.gotDescription(configuration, streamId);
    }).catch(function(error) {
      console.error("create offer error for stream id: " + streamId + " error: " + error);
    });
  };
  this.getVideoSender = function(streamId) {
    var videoSender = null;
    if ((adapter.browserDetails.browser === 'chrome' || (adapter.browserDetails.browser === 'firefox' && adapter.browserDetails.version >= 64))
      && 'RTCRtpSender' in window && 'setParameters' in window.RTCRtpSender.prototype) {
      const senders = thiz.remotePeerConnection[streamId].getSenders();
      for (let i = 0; i < senders.length; i++) {
        if (senders[i].track != null && senders[i].track.kind == "video") {
          videoSender = senders[i];
          break;
        }
      }
    }
    return videoSender;
  }

  this.getAudioSender = function(streamId) {
    var audioSender = null;
    if ((adapter.browserDetails.browser === 'chrome' || (adapter.browserDetails.browser === 'firefox' && adapter.browserDetails.version >= 64))
      && 'RTCRtpSender' in window && 'setParameters' in window.RTCRtpSender.prototype) {
      const senders = thiz.remotePeerConnection[streamId].getSenders();
      console.log("SENDERS: ", senders)
      for (let i = 0; i < senders.length; i++) {
        if (senders[i].track != null && senders[i].track.kind == "audio") {
          audioSender = senders[i];
          break;
        }
      }
    }
    return audioSender;
  }
  this.changeBandwidth = function(bandwidth, streamId) {
    thiz.bandwidth = bandwidth;
    if (!thiz.remotePeerConnection[streamId]) {
      return Promise.reject("Cannot change bandwidth, most likely publish not started");
    }
    var errorDefinition = "";
    var videoSender = thiz.getVideoSender(streamId);
    var audioSender = thiz.getAudioSender(streamId);
    //console.log("AUDIO SENDER< ", audioSender.getParameters())
    if (videoSender != null) {
      const videoParameters = videoSender.getParameters();
      console.log(videoParameters)
      if (!videoParameters.encodings) {
        videoParameters.encodings = [{}];
      }
      delete videoParameters.encodings[0].maxBitrate;
      videoParameters.encodings[0].maxBitrate = bandwidth * 1000;
      console.log(thiz.localStream.getVideoTracks()[0].getSettings(), thiz.videoWidth.width || thiz.videoWidth)
      videoParameters.encodings[0].scaleResolutionDownBy =
        thiz.localStream.getVideoTracks()[0].getSettings().width / (thiz.videoWidth.width || thiz.videoWidth);
      videoParameters.encodings[0].priority = 'medium';
      videoParameters.encodings[0].networkPriority = 'medium';
      videoParameters.degradationPreference = 'maintain-resolution';
      videoSender.setParameters(videoParameters);
      if (audioSender != null) {
        const audioParameters = audioSender.getParameters();
        if (!audioParameters.encodings) {
          audioParameters.encodings = [{}];
        }
        delete audioParameters.encodings[0].maxBitrate;
        audioParameters.encodings[0].maxBitrate = 40 * 1000;
        audioParameters.encodings[0].priority = 'high';
        audioParameters.encodings[0].networkPriority = 'high';
        audioSender.setParameters(audioParameters)
      }
      return Promise.resolve();
    } else {
      errorDefinition = "Video sender not found to change bandwidth";
    }
    return Promise.reject(errorDefinition);
  };
  this.getStats = function(streamId) {
    thiz.remotePeerConnection[streamId].getStats(null).then(stats => {
      var bytesReceived = 0;
      var packetsLost = 0;
      var fractionLost = 0;
      var currentTime = 0;
      var bytesSent = 0;
      stats.forEach(value => {
        if (value.type == "inbound-rtp") {
          bytesReceived += value.bytesReceived;
          packetsLost += value.packetsLost;
          fractionLost += value.fractionLost;
          currentTime = value.timestamp;
        } else if (value.type == "outbound-rtp") {
          bytesSent += value.bytesSent
          currentTime = value.timestamp
          if (value.qualityLimitationReason) {
            thiz.remotePeerConnectionStats[streamId].qualityLimitationReason = value.qualityLimitationReason;
            thiz.remotePeerConnectionStats[streamId].framesEncoded = value.framesEncoded;
          }
          if (thiz.remotePeerConnectionStats[streamId] && value.totalEncodedBytesTarget) {
            thiz.remotePeerConnectionStats[streamId].totalEncodedBytesTarget = value.totalEncodedBytesTarget;
          }
        }
      });
      thiz.remotePeerConnectionStats[streamId].totalBytesReceived = bytesReceived;
      thiz.remotePeerConnectionStats[streamId].packetsLost = packetsLost;
      thiz.remotePeerConnectionStats[streamId].fractionLost = fractionLost;
      thiz.remotePeerConnectionStats[streamId].currentTime = currentTime;
      thiz.remotePeerConnectionStats[streamId].totalBytesSent = bytesSent;
      thiz.callback("updated_stats", thiz.remotePeerConnectionStats[streamId]);
    });
  }
  this.enableStats = function(streamId) {
    thiz.remotePeerConnectionStats[streamId] = new PeerStats(streamId);
    thiz.remotePeerConnectionStats[streamId].lastCurrentBitrates = [];
    thiz.remotePeerConnectionStats[streamId].timerId = setInterval(() => {
      try{
        thiz.getStats(streamId);
      } catch (_e) {
        clearInterval(thiz.remotePeerConnectionStats[streamId].timerId)
      }
    }, 2000);
  }

  this.disableStats = function (streamId) {
    thiz.remotePeerConnectionStats[streamId] && clearInterval(thiz.remotePeerConnectionStats[streamId].timerId)
  }

  this.closeWebSocket = function() {
    for (var key in thiz.remotePeerConnection) {
      thiz.remotePeerConnection[key].close();
    }
    thiz.remotePeerConnection = new Array();
    thiz.webSocketAdaptor.close();
  }

  function WebSocketAdaptor() {
    var wsConn = new WebSocket(thiz.websocket_url);
    var connected = false;
    var pingTimerId = -1;
    var clearPingTimer = function() {
      if (pingTimerId != -1) {
        if (thiz.debug) {
          console.debug("Clearing ping message timer");
        }
        clearInterval(pingTimerId);
        pingTimerId = -1;
      }
    }
    var sendPing = function() {
      var jsCmd = {
        command: "ping",
      };
      wsConn.send(JSON.stringify(jsCmd));
    }
    this.close = function() {
      wsConn.close();
    }
    wsConn.onopen = function() {
      if (thiz.debug) {
        console.log("websocket connected");
      }
      pingTimerId = setInterval(() => {
        sendPing();
      }, 3000);
      connected = true;
      thiz.callback("initialized");
    }
    this.send = function(text) {
      if (wsConn.readyState == 0 || wsConn.readyState == 2 || wsConn.readyState == 3) {
        thiz.callbackError("WebSocketNotConnected");
        return;
      }
      wsConn.send(text);
      // console.log("sent message:" + text);
    }
    this.isConnected = function() {
      return connected;
    }
    wsConn.onmessage = function(event) {
      var obj = JSON.parse(event.data);
      if (obj.command == "start") {
        if (thiz.debug) {
          console.debug("received start command");
        }
        thiz.startPublishing(obj.streamId);
      } else if (obj.command == "takeCandidate") {
        if (thiz.debug) {
          console.debug("received ice candidate for stream id " + obj.streamId);
          console.debug(obj.candidate);
        }
        thiz.takeCandidate(obj.streamId, obj.label, obj.candidate);
      } else if (obj.command == "takeConfiguration") {
        if (thiz.debug) {
          console.log("received remote description type for stream id: " + obj.streamId + " type: " + obj.type);
        }
        obj.sdp = obj.sdp.replace('useinbandfec=1', 'useinbandfec=1; stereo=1; maxaveragebitrate=300000; usedtx=1');
        thiz.takeConfiguration(obj.streamId, obj.sdp, obj.type);
      } else if (obj.command == "stop") {
        // console.debug("Stop command received");
        thiz.closePeerConnection(obj.streamId);
      } else if (obj.command == "error") {
        thiz.callbackError(obj.definition);
      } else if (obj.command == "notification") {
        thiz.callback(obj.definition, obj);
        if (obj.definition == "play_finished" || obj.definition == "publish_finished") {
          thiz.closePeerConnection(obj.streamId);
        }
      } else if (obj.command == "streamInformation") {
        thiz.callback(obj.command, obj);
      } else if (obj.command == "pong") {
        thiz.callback(obj.command);
      }
    }
    wsConn.onerror = function(error) {
      // console.log(" error occured: " + JSON.stringify(error));
      clearPingTimer();
      thiz.callbackError(error)
    }
    wsConn.onclose = function(event) {
      connected = false;
      // console.log("connection closed.");
      clearPingTimer();
      thiz.callback("closed", event);
    }
  }
  return this;
}
