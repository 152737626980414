import React from "react";
import AppBar from "@material-ui/core/AppBar";
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  subheader: {
    height:`${theme.isMobile ? '70px': '90px'}`,
    display:'flex',
    alignItems:'center',
    justifyContent:"center",
    color:theme.palette.pubGrey,
    top:'auto',
    bottom:'0',
    backgroundColor:theme.palette.pubWhite,
    borderTop: '1px solid #e5e5e5',
    boxShadow:'none',
  },
}));

function Footer (props) {
  const {...rest} = props;
  const classes = useStyles();
  return (
    <AppBar className={classes.subheader} {...rest}>
      {props.children}
    </AppBar>
  )
}

export default Footer;
