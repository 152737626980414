import React, {useContext} from 'react';
import Button from '../../../commons/muiCustomComponents/Button';
import Box from '../../../commons/muiCustomComponents/Box';
import MobileProvider from "commons/context/MobileProvider";
import { makeStyles } from '@material-ui/core/styles';
import ArrowForward from '@material-ui/icons/ArrowForward';

const useStyles = makeStyles(theme => ({
  footer: {
    boxShadow: "0 -3px 4px 0 rgba(0, 0, 0, 0.25)",
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    height: theme.spacing(14),
    background: theme.palette.pubWhite,
    zIndex: 100,
    padding: theme.spacing(2.5),
    boxSizing: "border-box",
    [theme.breakpoints.up('sm')]: {
      right: "40%",
      height: theme.spacing(20),
    },
  },
  footerButton: {
    background: theme.palette.pubdeepSkyBlue,
    color: theme.palette.pubWhite,
    border: "1px solid",
  },
  nextButton: {
    color: theme.palette.pubWhite,
    background: theme.palette.pubGreyishBrown,
    [theme.breakpoints.up('sm')]: {
      height: theme.spacing(12),
      width: theme.spacing(30),
      display: "flex",
      justifyContent: "space-between",
    },
  },
  prevButton: {
    color: theme.palette.pubGreyishBrown,
    background: theme.palette.pubWhite,
    borderColor: theme.palette.pubGreyishBrown,
  },
  footerButtonDesktop: {
    paddingTop:theme.spacing(4),
    paddingBottom:theme.spacing(4),
    width:theme.spacing(50),
    borderRadius:theme.spacing(8),
    margin:theme.spacing(2),
  },
}));

const OnBoardFooter = (props) => {
  const {primaryHandler, disabled} = props;

  const isMobile = useContext(MobileProvider);
  const classes = useStyles();

  return (
    <Box className={classes.footer} display="flex" alignItems="center">
      {isMobile ? <Button variant="contained" fullWidth className={classes.footerButton} onClick={primaryHandler}>Get Started</Button>:
        <Box display="flex" justifyContent="flex-end" width="100%">
          <Button 
            variant="contained" 
            disabled={disabled}
            className={classes.footerButtonDesktop} 
            onClick={primaryHandler}
            endIcon={<ArrowForward/>}>Get Started
          </Button>
        </Box> 
      }
    </Box>
  )
}

export default OnBoardFooter;
