
const storyAction = {
  FETCH: {
    REQUEST: "storyListFetchRequest",
    FAILURE: "storyListFetchFailure",
    SUCCESS: "storyListFetchSuccess",
  },
  WIDTH :{
    SET :"setstorywidth",  
  },
}

export default storyAction;
