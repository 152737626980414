import React, {useState} from 'react';
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Input from '../../../commons/muiCustomComponents/Input';
import OnBoardHeader from './OnBoardHeader';
import OnBoardFooter from './OnBoardFooter';
import Box from '../../../commons/muiCustomComponents/Box';
import Typography from '../../../commons/muiCustomComponents/Typography';
import {createDomainAction, updateDomainDetailsAction, addNewStoryList} from '../onboard-action';
import { makeStyles } from '@material-ui/core/styles';
import { LinearLoader } from "commons/commonWrapper/Loaders";
import OnBoardDomainImg from 'assets/images/onboard_domain.svg';
import { fbPixelEvent } from "utils/facebook-pixel-client";
import { registerDomainCreation } from "utils/adwords-client";

const useStyles = makeStyles(theme => ({
  body: {
    marginTop: theme.spacing(25),
    marginBottom: theme.spacing(28),
    marginLeft: theme.spacing(6),
    marginRight: theme.spacing(6),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(20),
      marginRight: theme.spacing(20),
      marginTop: theme.spacing(30),
    },
  },
  widthObj: {
    width: "100%",
    [theme.breakpoints.up('sm')]: {
      width: "360px",
    },
  },
  nameContainer: {
    paddingTop: "24px",
  },
  headingText: {
    fontSize: "16px",
  },
  subTitleText: {
    fontSize: "12px",
    color: "#909aa8",
  },
  onboardContainer: {
    display: "flex",
  },
  onboardContainerLeft: {
    width: "100%",
    [theme.breakpoints.up('sm')]: {
      width: "60%",
    },
  },
  onboardContainerRight: {
    display: "none",
    width: "40%",
    minHeight: "100vh",
    flexGrow: 1,
    background: theme.palette.pubdeepSkyBlue,
    [theme.breakpoints.up('sm')]: {
      display: "block",
    },
  },
  loader: {
    position: "fixed",
    top: theme.spacing(14),
    zIndex: 101,
    left: 0,
    right: 0,
    [theme.breakpoints.up('sm')]: {
      top: theme.spacing(20),
      right: "40%",
    },      
  },
  dialogSite: {
    marginRight: theme.spacing(2.5),
    marginBottom: theme.spacing(6),
    border: "solid 1px",
    borderColor: theme.palette.pubdeepSkyBlue,
    backgroundColor: theme.palette.pubPaleGrey,
    fontSize: theme.spacing(4),
    padding: theme.spacing(3),
    textAlign: "center",
    color: theme.palette.pubdeepSkyBlue,
    wordBreak: "break-word",
  },
  dialogContainer:{
    marginBottom: theme.spacing(8),
    marginLeft: theme.spacing(10),
    marginRight: theme.spacing(10),
  },
  brandNameText: {
    textAlign: "right",
  },
  textSection:{
    fontSize:theme.spacing(11),
    color: theme.palette.pubWhite,
    fontWeight:'700',
    textAlign:'center',
  },
  imageBox: {
    marginLeft:'20%',
    width:'80%',
    height:'20%',
  },
  girlImage: {
    width:'100%',
    objectFit:'cover',
  },
}));

const OnBoardDomain = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const { search } = useLocation();
  const [brandName, setBrandName] = useState("");
  const [subdomainName, setsubdomain] = useState("");
  const [subdomainErrorMessage, setsubdomainErrorMessage] = useState("");
  const [tagline, setTagline] = useState("");
  const [oneLineDescription, setOneLineDescription] = useState("");
  const [requesting, setRequesting] = useState(false);
  const [isDirty, setDirty] = useState(false);

  const query = new URLSearchParams(search);
  const utmSource = query.get("utm_source");
  const utmCampaign = query.get('utm_campaign');
  const utmMedium = query.get('utm_medium');

  const handleBrandName = (e) => {
    setsubdomainErrorMessage('');
    let val = e.target.value;
    let name = val.split(" ").join("").toLowerCase().replace(/[^a-z0-9]/g, '-');
    setsubdomain(name);
    setTagline("Welcome to " + val);
    setBrandName(val);
  }

  const handleSubdomain = (e) => {
    setsubdomainErrorMessage('');
    let val = e.target.value;
    let name = val.split(" ").join("").toLowerCase().replace(/[^a-z0-9]/g, '-');
    setsubdomain(name);
  }

  const handleTagline = (e, foo) => {
    setTagline(e.target.value);
  }

  const handleOneLineDescription = (e) => {
    setOneLineDescription(e.target.value);
  }

  const goNext = async (e) => {
    if(subdomainName && brandName && oneLineDescription && tagline){
      const subdomain = {subdomain: subdomainName};
      const domainData = {title: brandName, oneline_description: oneLineDescription, tagline: tagline};
      const addStory = {
        name: 'default-quick-cms-stories',
        expiry: 99999,
      }
      try{
        setRequesting(true);
        const d = await dispatch(createDomainAction(subdomain));
        if (typeof d === 'string') {
          setsubdomainErrorMessage(d);
          setRequesting(false);
          return;
        }
        try {
          if(utmSource === 'fbpaid') {
            fbPixelEvent(`${utmMedium}-domainCreated`, {});
          }
          if(utmSource === 'sempaid') {
            registerDomainCreation();
          }
        } catch (err) {
          // handle via sentry
        }
        await dispatch(updateDomainDetailsAction(domainData));
        await dispatch(addNewStoryList(addStory));
        setRequesting(false);
        history.push(`/`);
      } catch(err){
        //err handling
        setRequesting(false);
      }
    } else{
      setDirty(true);
    }
  }

  return (
    <Box className={classes.onboardContainer}>
      <Box className={classes.onboardContainerLeft}>
        <OnBoardHeader/>
        <Box className={classes.loader}>
          {requesting && <LinearLoader />}
        </Box>  
        <Box className={classes.body}>
          <Box className={classes.widthObj}>
            <Typography className={classes.headingText} variant="h3" component="h3" gutterBottom>
              Your brand name*
            </Typography>
            <Input
              size="small"
              error={isDirty && !brandName.length}
              helperText={(isDirty && !brandName.length) && "This field cannot be empty"}
              fullWidth label="" 
              value={brandName}
              onChange={handleBrandName} />
          </Box>
          <Box className={classes.widthObj} mt="32px">
            <Typography className={classes.headingText} variant="h3" component="h3" gutterBottom>
              Web URL*
            </Typography>
            <Box mb="5px">
              <Typography className={classes.subTitleText}>
                Already own a domain? You can set it up later
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Input 
                size="small"
                error={(isDirty && !subdomainName.length) || subdomainErrorMessage.length > 0}
                helperText={ ((isDirty && !subdomainName.length) && "This field cannot be empty") || subdomainErrorMessage}
                fullWidth 
                inputProps={{ style: {textAlign: 'right'} }} 
                label="" 
                value={subdomainName}
                onChange={handleSubdomain} />
              <Box>.pubninja.com</Box>
            </Box>
            <Box className={classes.widthObj} mt="32px">
              <Typography className={classes.headingText} variant="h3" component="h3" gutterBottom>
                Tagline*
              </Typography>
              <Box mb="5px">
                <Typography className={classes.subTitleText}>
                  Welcome message for your audience
                </Typography>
              </Box>
              <Input
                size="small"
                error={isDirty && !tagline.length}
                helperText={(isDirty && !tagline.length) && "This field cannot be empty"}
                fullWidth
                label="" 
                value={tagline}
                onChange={handleTagline} />
            </Box>
            <Box className={classes.widthObj} mt="32px">
              <Typography className={classes.headingText} variant="h3" component="h3" gutterBottom>
                What’s it about*
              </Typography>
              <Box mb="5px">
                <Typography className={classes.subTitleText}>
                   Describe your offering for your audience
                </Typography>
              </Box>
              <Input
                size="small"
                error={isDirty && !oneLineDescription.length}
                helperText={(isDirty && !oneLineDescription.length) && "This field cannot be empty"}
                placeholder="I am starting new classes to help you learn fishing"
                fullWidth multiline rows="3"
                value={oneLineDescription}
                onChange={handleOneLineDescription} />
            </Box>
          </Box>
        </Box>
        <OnBoardFooter primaryHandler={goNext} disabled={requesting} />
      </Box>
      <Box className={classes.onboardContainerRight}>
        <Box component="p" className={classes.textSection}>The Creator's<br/>Platform</Box>
        <Box className={classes.imageBox}><img src={OnBoardDomainImg} className={classes.girlImage} alt="login"/></Box>        
      </Box>
    </Box>
  )
}

export default OnBoardDomain;