import React from "react";
import Dialog from "@material-ui/core/Dialog";

function CustomDialog (props) {
  const {...rest} = props;
  return (
    <Dialog {...rest}/>
  )
}

export default CustomDialog;