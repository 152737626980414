import React from "react";
import AppBar from "@material-ui/core/AppBar";
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  subheader: {
    height:`${theme.isMobile ? '70px': '75px'}`,
    display:'flex',
    alignItems:'center',
    justifyContent:"center",
    color:theme.palette.pubWhite,
    backgroundColor:theme.palette.primary.main,
    boxShadow:'none',
  },
  largeSize :{
    height:`${theme.isMobile ? '52px': '60px'}`,
  },
}));

function Header (props) {
  const {...rest} = props;
  const classes = useStyles();
  return (
    <>
    <AppBar position="sticky"
     className={clsx({[classes.subheader]:true,
     [classes.largeSize]:props.size==="large"})} {...rest}>
      {props.children}
    </AppBar>
    </>
  )
}

export default Header;