import React, { useEffect, useState, useContext,useRef } from 'react';
import Box from "commons/muiCustomComponents/Box";
import { makeStyles } from '@material-ui/core/styles';
import Button from "commons/muiCustomComponents/Button";
import { useHistory } from "react-router-dom";
import Paper from "commons/muiCustomComponents/Paper";
import setAppMessage from "app-component/app-component-action";
import { useDispatch, useSelector } from 'react-redux';
import {
    getEmailSegmentsList,
    importEmailUserList,
    exportEmailUsersList,
  } from "../scheduler/scheduler-action";
import MobileProvider from "commons/context/MobileProvider";
import { LinearLoader, PageBlockLoader } from 'commons/commonWrapper/Loaders';
// import InfoIcon from '@material-ui/icons/Info';
import Papa from "papaparse";
import CSVPreview from "./components/CSVPreview";
import CSVFakePreview from "./components/CSVFakePreview";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.pubWhite,
  },
  uppercontainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },
  productBackground :{
    backgroundColor:theme.palette.secondary.light,
    border:'none',
    "&:hover,&:focus": {
      backgroundColor:theme.palette.secondary.light,
      border:'none',
    },
  },
  textdecoration: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color:'black',
  },
}));
const validFirstName = ['first name', 'firstname', 'first_name', 'first  name'];
const validLastName = ['last name', 'lastname', 'last_name', 'last  name'];

const fakeData = [{
    0: 'jhon.doe@gmail.com',
    1: 'Jhon',
    2: 'Doe',
  }];

export default function EmailList() {
  const classes = useStyles();
  const isMobile = useContext(MobileProvider);
  const [openCSVPreview, setOpenCSVPreview] = useState(false);
  const [totalSignups, setTotalSignups] = useState(0);
  const [freeSignups, setFreeSignups] = useState(0);
  const [paidSignups, setPaidSignups] = useState(0);

  const fileField = useRef(null);
  const {
    scheduler,
    landing: { activeDomain },
  } = useSelector(state =>  state);
  const { 
    segments,
  } = scheduler;
  const dispatch = useDispatch();
  const [isLoader,setLoader] = useState(false);
  const [state, setState] = useState({
    uploadCSVPreview: null,
    formData: null,
  });

  const [openCSVFakeModal, setOpenCSVFakeModal] = useState(false);

  useEffect(() => {
    let isMounted = true;
    // async function getPages(){
    //   try{
    //     setLoader(true);
    //     await dispatch(getEmailSegmentsList());
    //     isMounted && setLoader(false);
    //   } catch(err){
    //     isMounted && setLoader(false);
    //   }
      
    // }
    // getPages();
    return () => (isMounted = false);
  }, []);

  useEffect(() => {
    segments && segments.map(item => {
      if (item.productName === 'PAID_ACCESS') setPaidSignups(item.subscribeCount);
      if (item.productName === 'FREE_ACCESS') setFreeSignups(item.subscribeCount);
      if (item.productName === 'PUBLIC_ACCESS') setTotalSignups(item.subscribeCount);
      return true;
    });
  }, [segments]);
  const _handleClick = () => {
    fileField.current.click();
  }


  const closeCSVFakeModal = () => {
    setOpenCSVFakeModal(false);
  }
  const closePreviewDialog = () => {
    setOpenCSVPreview(false);
  }

  const confirmUpload = async() => {
    const csvFile = state.formData;
    try{
      setLoader(true);
      await dispatch(importEmailUserList(csvFile));
      await dispatch(getEmailSegmentsList());
      dispatch(setAppMessage({ message: 'List has been processed. Users list will be updated in some time.', header: 'Success', type: 'alert' }));
      setOpenCSVPreview(false);
      setLoader(false);
    } catch(err){
      // err handling
      setLoader(false);
    }    
  }

  const read = (file, callback) => {
    var reader = new FileReader();

    reader.onload = function () {
      callback(Papa.parse(reader.result, { skipEmptyLines: true }));
    }

    reader.readAsText(file);
  }

  const downloadList = () => {
    dispatch(exportEmailUsersList());
  }
  const _onUpload = (event) => {
    let formData = new FormData(); // Currently empty
    formData.append('file', event.target.files[0])

    if (event.target.files[0].name.split('.').pop() !== 'csv') {
      // File format should be csv
      event.target.value = null;
      return;
    }

    read(event.target.files[0], (data) => {
      if (data.data.length === 0) {
        // csv can't be empty
        return;
      }
      else {
        const keyArray = data.data[0];
        if (Array.isArray(keyArray)) {
          const refactorArray = keyArray.map((ele) => ele && ele.trim().toLowerCase())
          if (!refactorArray.includes('email')) {
            // email is required
            return;
          }
          const emailIndex = refactorArray.indexOf('email');
          refactorArray.splice(emailIndex, 1);
          let exitOnUpload = false;
          refactorArray.map((ele) => {
            if (!(validFirstName.indexOf(ele) >= 0 || validLastName.indexOf(ele) >= 0)) {
              // key should be firstName or lastName
              exitOnUpload = true;
              return null;
            }
            return ele;
          });
          if (exitOnUpload) {
            exitOnUpload = false;
            return null;
          }
        }
      }
      setState({ ...state, uploadCSVPreview: data.data, formData });
      setOpenCSVPreview(true);
    })

    event.target.value = null
  }

  
  return (
    <>
     {isLoader && !isMobile && <PageBlockLoader />}
      {isLoader && isMobile && <LinearLoader />}
    <Box mt={4}>
         <Paper>
            <Box display="flex" alignItems="center">
                Total Signups : {totalSignups}
                {
                    <Box onClick={downloadList} className={classes.textdecoration} ml={4}>
                      Export
                    </Box>
                }
            </Box>
            <Box>
              Free Signups : {freeSignups}
            </Box>
            <Box>
              Paid Signups : {paidSignups}
            </Box>
              {segments && segments.map(item => {
              if(item.productName === 'PUBLIC_ACCESS' 
              || item.productName === 'PAID_ACCESS' 
              || item.productName === 'FREE_ACCESS') return null;
               return (
                 <Box key={item.productId}>
                   <>{item.productName} : {item.subscribeCount}</>
                 </Box>
               )
              })
              }
            <Box mt={3}>
              <Box component="span">
                The above lists are updated to exclude complaints and unsubscribes.
              </Box>
            </Box>
        </Paper>
    </Box>
      <Box mt={4}>
      <Paper>
          <Box component="span"
              color="primary.main"
              display="flex"
              flexDirection="column"
              fontWeight="bold" >
                  Import Free Signups
          </Box>
          <Box >
            <Box component="span" fontSize={12} color="secondary.main">
            Import from mailchimp or any other tool via CSV. We suggest importing 
            a clean email list - avoid inactive recipients, unsubscribes, bounces and complaints.
            </Box>
          </Box>
          {
                 
                 <Box>
                 <input type="file" ref={fileField} accept=".csv" onChange={_onUpload} id="file-upload" style={{ display: 'none' }} />            
                 <Button type="file" onClick={_handleClick} variant="contained" color="primary" disableFocusRipple
                 boxProps ={{mt:2}}
                 >Import via CSV </Button>
                 {/* <InfoIcon onClick={()=> setOpenCSVFakeModal(true)} /> */}
               </Box>                

                    }
        </Paper>
      </Box>
      {state.uploadCSVPreview && <CSVPreview 
              uploadCSVPreview={state.uploadCSVPreview}
              confirmUpload={confirmUpload}
              open={openCSVPreview}
              closePreviewDialog={closePreviewDialog}
            />}
            <CSVFakePreview
              closeCSVFakeModal={closeCSVFakeModal}
              open={openCSVFakeModal}
              renderData={fakeData}
            />
    </>
  );
}
