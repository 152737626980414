
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../muiCustomComponents/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Box from "../muiCustomComponents/Box";
import RootRef from '@material-ui/core/RootRef';
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: theme.palette.pubWhite,
    flexDirection: 'row',
  },
  appBarMobile:{
    backgroundColor: theme.palette.primary.main,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  iconStyle :{
    top: '50%',
    transform:' translateY(-50%)',
    marginLeft:'0px',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({
   children, isShowIcon = true, 
   isMenu=false,
   isOpen, backButton = true, buttonText, type, BarComponent = <></>, clearPageData,...rest }) {
  const classes = useStyles();
  const domDialogRef = React.useRef();
  const [open, setOpen] = React.useState(false);
  const [appBarHeight, setHeight] = React.useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };
  React.useEffect(() => {
    if (domDialogRef.current && domDialogRef.current !== null) {
      setHeight(domDialogRef.current.getBoundingClientRect().height);
    }
  }, [domDialogRef]);
  const handleClose = () => {
    if (typeof clearPageData ==="function") clearPageData()
    setOpen(false);
  };
  return (
    <Box>
     {type &&  <Button type={type} onClick={handleClickOpen} color="secondary" {...rest}  >
        {buttonText}
      </Button>}
      <Dialog fullScreen open={isOpen || open} 
      onClose={handleClose} TransitionComponent={Transition}>
      <>
      <RootRef rootRef={domDialogRef}>
        <AppBar position='sticky' className={clsx({[classes.appBar]:true,[classes.appBarMobile]:isMenu})}  >
         <Box  ml={1}>
         { isShowIcon && <IconButton edge="start" color="secondary" className={classes.iconStyle} onClick={handleClose} aria-label="close">
            {
              backButton ? <ArrowBackIcon style={{ fontSize: '1.5rem' }} />
                : <CloseIcon style={{ fontSize: '1.5rem' ,color:isMenu?"white":'inherit'}} />
            }
          </IconButton>}
          </Box>
          
          {
            typeof BarComponent === 'function' && BarComponent()
          }
         
        </AppBar>
        </RootRef>
        </>
        {typeof children === "function" ? children(appBarHeight) : children}
      </Dialog>
    </Box>
  );
}
