/**
 * App message constant
 */

const app = {
  MessageAction: {
    SET: "Set.app.message",
    CLEAR: "Clear.app.message",
  },
  MediaAction :{
    SET: "Set.app.media",
    CLEAR: "Clear.app.media",
  },
  MediaControl :{
    IS_OPEN :'Set.app.MediaControl',
    IS_ABORT :'Set.app.MediaControl.isAbort',
    IS_STATUS :'Set.app.MediaControl.isStatus',
    RESET :'Set.app.MediaControl.Reset',
    PROCESSING_PERCENTAGE : "Set.app.MediaControl.processingPercentage",
  },
}
export default app;
