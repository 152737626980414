import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';
import Box from "commons/muiCustomComponents/Box";
import useScrollTrigger from '@material-ui/core/useScrollTrigger';


const useStyles = makeStyles(theme => ({
    root: {
      position: 'fixed',
      bottom: theme.isMobile ? theme.spacing(140 * 0.25) : theme.spacing(85 * 0.25),
      right: theme.spacing(29 * 0.25 ),
    },
  }));
  
export default function ScrollTop(props) {
    const { children } = props;
    
    const classes = useStyles();
    const trigger = useScrollTrigger(()=>({
      disableHysteresis: true,
      threshold: 100,
    }));  
    
    const handleClick = event => {
      const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');
      if (anchor) {
        anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    };
  
    return (
      <Zoom in={trigger}>
        <Box onClick={handleClick} role="presentation" className={classes.root}>
          {children}
        </Box>
      </Zoom>
    );
  }
  
  