import React, { useState , useContext, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from "../../../commons/muiCustomComponents/Box";
import InputBox from "../../../commons/muiCustomComponents/InputBox";
import Button from "../../../commons/muiCustomComponents/Button";
import { CircularLoader } from '../../../commons/commonWrapper/Loaders';
import Footer from "commons/commonWrapper/Footer";
import MobileProvider from "commons/context/MobileProvider";
import kebabCase from "lodash.kebabcase";
import ImageIcon from '@material-ui/icons/Image';
import AddIcon from '@material-ui/icons/Add';
import { useDispatch } from 'react-redux';
import setAppMessage from "app-component/app-component-action";
import WithFileUpload from "commons/hocs/with-file-upload";
import CropImageDialog from "../../content/CroppingDialog";


const uploadImage = () => {
  return (
    <Box width={170} style={{
      border: '1px solid #e5e5e5',
      cursor: 'pointer',
      width: '170px !important', height: '90px',
    }} bgcolor="#e5e5e5">
      <Box width="100%" height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <Box position="relative">
          <AddIcon style={{
            position: 'absolute',
            top: '-7px',
            left: '7px',
          }} />
          <ImageIcon />
        </Box>
        <Box component="span" fontSize={12}> UPLOAD IMAGE </Box>
      </Box>
    </Box>
  )
}

const FileUploaderButton = WithFileUpload(uploadImage);

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    border: `1px solid ${theme.palette.pubveryLightPinkTwo}`,
    backgroundColor: theme.palette.pubGreywhite,
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  textStyle: {
    color: theme.palette.pubBlack,
    textTransform: "capitalize",
    fontWeight: "500",
  },
}));

export default function CategoryForm({ addCategory, toggleCat }) {
  const classes = useStyles();
  const childRef = useRef();
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [subDec, setSubDec] = useState('');
  const [subCat, setSubCat] = useState('');
  const [error,setError] = useState(false);
  const isMobile = useContext(MobileProvider);
  const [headerType, setHeaderType] = useState(null);
  const [openThumbDialog, setThumbnailDialog] = useState(false);
  const [thumbnailContent, setthumbnailContent] = useState('');
  const [filename, setFileName] = useState(null);

  const handleSubDec = (e) => {
    const value = e.currentTarget.value;
    setSubDec(value);
  }
  const handleSubCat = (e) => {
    const value = e.currentTarget.value;
    setSubCat(value);
    if (!value) {setError(true)}
    else{setError(false)}
  }

  const submitSubCat = async () => {
    const submitData = {
      type: kebabCase(subCat),
      name: subCat.trim(),
      description:subDec ,
      thumbnail:childRef &&childRef.current && childRef.current.getThumbnail(),
      parent_section :0,
      hierarchy :0,
      status:1,
    }
    if (!subCat.trim()){
      setError(true)
      return;
    }
    try {
      setLoading(true)
      await addCategory(submitData)
      setLoading(false)
      toggleCat()
    }
    catch (err) {
      setLoading(false)
    }

  }

  const handleFileUpload = (target, type) => {
    const allowed = ['jpg','jpeg', 'png','bmp'];
    const file = target && target.files[0];
    const uploadType = type && type.split("/")[0];
    const extension = file && file.type.split("/")[1];
    if (allowed.indexOf(extension) < 0) {
      dispatch(setAppMessage({ message: 'Unsupported media format.', header: 'Error', type: 'alert' }));
      return;
    }
    const { size } = file;
    const sizeInMb = size / 1000000;
    if (sizeInMb > 1024) {
      dispatch(setAppMessage({ message: 'image larger than 1GB not allowed.', header: 'Error', type: 'alert' }));

      return;
    }
    if (file && type && !(file.type.indexOf(uploadType) > -1)) {
      //this.props.InvalidFileError();
      return;
    }
    if (uploadType === 'image' && extension === 'webp') {
      //this.props.InvalidFileError('Webp images are not allowed, as not supported by Safari browser.');
      return;
    }
    if (!file || !file.name) {
      return;
    }
    setHeaderType(file.type);
    
    setFileName(file.name);

    const fr = new FileReader();
    fr.onload = (stream) => {
      setthumbnailContent(stream.target.result);
      setThumbnailDialog(true);
    }
    fr.readAsDataURL(file);


  }


  
  return (
    <>
    <CropImageDialog
        ref={childRef}
        open={openThumbDialog}
        setThumbnailDialog={setThumbnailDialog}
        thumbnailContent={thumbnailContent}
        name={filename}
      />
      <Box className={classes.root}>
        <Box>
          <InputBox
            error={error}
            heading="Section name"
            fullWidth
            color="secondary"
            value={subCat}
            onChange={handleSubCat}
            placeholder="Write here..."
            helperText={error ? "Section can't be empty" :''} 
            headingStyle={{
              fontSize: 14,
              color: "pubGrey",
              fontWeight: 500,
              mb: 1,
            }}
          />
        </Box>
        <Box>
          <InputBox
            heading="section description"
            fullWidth
            color="secondary"
            value={subDec}
            onChange={handleSubDec}
            multiline
            rows={5}
            placeholder="Write here..."
            headingStyle={{
              fontSize: 14,
              color: "pubGrey",
              fontWeight: 500,
              mb: 1,
            }}
          />
        </Box>
        <Box mt={3} display="flex" className={classes.thumbnailContainer}>
            <Box width={170}>{
              childRef &&childRef.current && childRef.current.getThumbnail() ?
              <img src={childRef &&childRef.current && childRef.current.getThumbnail()} width="100%" alt="thumbnail_image"/>
              :
              <FileUploaderButton
              type="image/*"
              onChange={handleFileUpload}
            /> }
            
          </Box>
        </Box>
        {
         !isMobile && <Box mb={3} mt={3} display="flex" >
         <Button disabled={isLoading} variant="contained" fullWidth onClick={submitSubCat}>Submit</Button>
         {isLoading && <CircularLoader height="auto" ml={1} size={20} />}
       </Box>
        }
      </Box>
      {
        isMobile && <Footer>
             <Box mb={3} mt={3} display="flex" width="80%">
        <Button disabled={isLoading} variant="contained" fullWidth onClick={submitSubCat}>Submit</Button>
        {isLoading && <CircularLoader height="auto" ml={1} size={20} />}
      </Box>
        </Footer>
      }  
    </>
  );
}
