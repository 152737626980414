
const initalState = {
  list:[],
  userPermissions:[],
};
  
/**
 * This reducer function handle the user list functions
 * @param {*} state 
 * @param {*} action 
 */
export default function socialReducer(state=initalState, action) {
  switch(action.type) {
    case "fetchUserListSuccess": 
      return {
        ...state, 
        list: action.data,
      }
    case "setUserPermissions":
      return {
        ...state,
        userPermissions:action.data,
      }
    case "updateUserList":
      return {
        ...state,
        list:action.newlist,
      }
    default:
      return state;
  }
}