export function fetchDomainList() {
  return async (dispatch, getState, apiHandler) => {
    const {api, apiRoutes} = apiHandler;
    try {
    let { data:domainList } = await api(apiRoutes.fetchDomainList());
    dispatch({ type:"DomainListFethSuccess", domainList});
    return Promise.resolve(domainList);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export function updateActiveDomain(activeDomain) {
  return async (dispatch, getState, apiHandler) => {
    dispatch({ type:"ChangingActiveDomain", value:true});
    const { api, apiRoutes } = apiHandler;
    let isQuickCms = true;
    try {
      let [{ data: userPermissions }, {data:featurePermissions}] = await Promise.all(
        [
          api(apiRoutes.fetchUserPermissionsApi(activeDomain)),
          api(apiRoutes.fetchDomainFeaturePermissionsApi(activeDomain)),
        ]);
      if(!featurePermissions.includes("QUICK_CMS")) {
        isQuickCms = false;
      }
      dispatch({ type:"UpdateActiveDomainAndPermission", data:{activeDomain, userPermissions, featurePermissions} });
      return Promise.resolve({isQuickCms, userPermissions, featurePermissions});
    } catch (err) {
      return Promise.reject();
    } finally {
      dispatch({ type:"ChangingActiveDomain", value:false});
    }
  }
}


export function fetchCategories() {
  return async(dispatch, getState, apiHandler) => {
    try {
      const { api, apiRoutes, actionTypes } = apiHandler;
      const { landing: { activeDomain } } = getState();
      const {data:{ result}} = await api(apiRoutes.fetchCategoriesApi(activeDomain));
      dispatch(fetchCategoriesSuccess(actionTypes.CATEGORY.SUCCESS,result));
      return Promise.resolve();
    } catch (err) {
      return Promise.reject();
    }
  }
}

function fetchCategoriesSuccess(actionName, data) {
  return {
    type:actionName,
    data,
  }
}
