import React,{useCallback, useEffect,useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Box from "commons/muiCustomComponents/Box";
import CountrySelect from './Components/Country';
import PersonaInfo from './Components/PersonaInfo';
import clsx from "clsx";
import {genereateOTP,verifyOTP,setUserbankdetail,updateAdsInfo} from "./advertisement-action";
import { useDispatch, useSelector } from "react-redux";
import BusinessInfo from "./Components/BusinessInfo";
import BusinessInfoRow from "./Components/BusinessInfoRow";

import BankInfo from "./Components/BankInfo";
import BankInfoRow from "./Components/BankInfoRow";
import { LogglyTracker } from 'loggly-jslogger';

const logger = new LogglyTracker();
logger.push({ 'logglyKey': '142d21f5-10d3-47a9-aa5a-f83e0a3abed7', 'sendConsoleErrors' : true, 'tag' : 'ardbeg,advertisement,adonboard' });


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  
  textClass :{
    textTransform:'capitalize',
  },

  subheading :{
    paddingTop: theme.spacing(2),
    paddingBottom:theme.spacing(4),
  },

  stepperRoot :{
    paddingLeft: 0,
    paddingRight:0,
    paddingTop:theme.spacing(4),
    paddingBottom:theme.spacing(4),
  },

  pointerClass :{
    cursor:'pointer',
  },

  iconColor :{
    "& >* svg":{
      color: theme.palette.pubdeepSkyBlue,
      cursor:'pointer',
    },
  },

  textColor :{
    cursor:'pointer',
    color: 'rgba(0, 0, 0, 0.87)',
  },
}));

function getSteps() {
  return [' Where are you based of? ', 'Personal Details', 'Business Details','Bank Details'];
}


const getStepContent = (step,passingProps,isSetCountry,...rest)=> {
  switch (step) {
    case 0:
    return <CountrySelect setCurrentCountry ={passingProps} isSetCountry={isSetCountry} {...rest[0]}/>;
    case 1:
      return <PersonaInfo  country ={passingProps} {...rest[0]}/>;
    case 2:{
      if (passingProps.code === 'IN') return <BusinessInfo country ={passingProps} {...rest[0]} /> 
      return <BusinessInfoRow country ={passingProps} {...rest[0]}/>
    }
    case 3 : {
        if (passingProps.code=== 'IN') return <BankInfo country ={passingProps} {...rest[0]}/>
        return <BankInfoRow country ={passingProps} {...rest[0]}/>
      }
    default:
      return 'Unknown step';
  }
}


export default function VerticalLinearStepper({appBarHeight,personalNumber=null,countryVal='',personalInfo,businessInfo,bankInfo}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [country ,setCountry] =  useState('');
  const [finalNumber , setFinalNumber] = useState(null);
  const [isSetCountry ,setCountryLoader] = useState(false);
  const [completed ,setCompleted] = useState({});

  //  personal details
  const [firstName, setfirstName] = useState('');
  const [secondName, setSecondName] = useState('');
  const [PAN, setPAN] = useState('');
  const [securityNumber ,handleSecurityNumber ] = useState('');
        

  // business info
  const [businessName ,setBusinessName] = useState('');
  const [businessType ,setBusinessType] = useState('');
  const [businessPan,setBusinessPan] = useState('');
  const [GSTNumber,setGSTNumber] = useState('');
  const [TAXNumber,setTaxNumber] = useState('');
  const [addressLine1,setaddressLine1] = useState('');
  const [addressLine2,setaddressLine2] = useState('');
  const [city,setCity] = useState('');
  const [pinCode ,setPinCode] = useState('');
  const [state,setState] = useState('');
  const [businessEmail,setBusinessEmail] = useState('');

  // businessinfo for rest of world
  const [businessNameRow ,setBusinessNameRow] = useState('');
  const [businessTypeRow ,setBusinessTypeRow] = useState('');
  const [addressLine1Row,setaddressLine1Row] = useState('');
  const [addressLine2Row,setaddressLine2Row] = useState('');
  const [cityRow,setCityRow] = useState('');
  const [pinCodeRow ,setPinCodeRow] = useState('');
  const [stateRow,setStateRow] = useState('');
  const [businessEmailRow,setBusinessEmailRow] = useState('');

  // bank info
  const [name, setName] = useState('');
  const [ifsc, setIFSC] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [confirmAccountNumber, setConfirmAccountNumber] = useState('');
  const [account, setAccount] = useState('current');
  const [matchAccountNumber, setMatchAccountNumber] = useState(false);

  //bank info row

  const [nameRow,setNameRow] = useState('');
  const [accountNumberRow,setAccountNumberRow] = useState('');
  const [confirmAccountNumberRow,setConfirmAccountNumberRow]= useState('');
  const [accountRow,setAccountRow] = useState('saving');
  const [routingNumber,setRoutingNumber] = useState('');
  const [swiftCode,setSwiftCode] = useState('');
  const [matchAccountNumberRow, setMatchAccountNumberRow] = useState(false);


  // steperTracking
  const [firstStepComplete ,setFirstStepComplete] = useState(false);
  const [secondStepComplete ,setSecondStepComplete] = useState(false);
  const [thirdStepComplete ,setThridStepComplete] = useState(false);
  const [forthStepComplete ,setForthStepComplete]  = useState(false);

  // track Editing

  const [isEditing ,setEditing] = useState(false);
  const {landing: {userProfile}} = useSelector(state => state);

  const steps = getSteps();

  useEffect(()=>{
    if (isEditing) return ;
    if (personalNumber){
      setFinalNumber(personalNumber)
      if (personalNumber) {
        setFirstStepComplete(true)
      }
    }

    if (countryVal) {
      const newCountry = countries.find((item)=>item.label ===countryVal)
      setCountry(newCountry)
    }
    if (Object.keys(personalInfo).length >0 ){
      const {firstname,lastname,panNumber,socialSecurity}= personalInfo;
      setfirstName(firstname);
      setSecondName(lastname);
      setPAN(panNumber);
      handleSecurityNumber(socialSecurity);
      if (countryVal && countryVal.code === 'IN'){
        if (firstName && lastname && panNumber) {
          setSecondStepComplete (true);
        }
      }
      else {
        if (firstName && lastname ) {
          setSecondStepComplete (true);
        }
      }

    }

    if (Object.keys(businessInfo).length > 0 ){
        const { name ,
          addressLine1 ,
          addressLine2 ,
          gstIDNumber,
        entityType,
        email,	
        state ,
        pinCode,
        panNumber ,
        taxDeductionNumber,
        city} = businessInfo;

        setBusinessName(name);
        setBusinessType(entityType);
        setBusinessTypeRow(entityType);
        setBusinessNameRow(name);
        setBusinessPan(panNumber);
        setGSTNumber(gstIDNumber);
        setTaxNumber(taxDeductionNumber);
        setaddressLine1(addressLine1);
        setaddressLine1Row(addressLine1);
        setaddressLine2(addressLine2);
        setaddressLine2Row(addressLine2);
        setCity(city);
        setCityRow(city);
        setPinCode(pinCode);
        setPinCodeRow(pinCode);
        setState(state);
        setStateRow(state);
        setBusinessEmail(email); 
        setBusinessEmailRow(email); 
        if (addressLine1 && addressLine2 && city && state){
          setThridStepComplete(true)
        }
    }

    if (Object.keys(bankInfo).length >0) {
      const {
        accountHolderName,
        accountNumber,
        accountType,
        ifsc,
        name,
        routingCode,
        swift,
      } = bankInfo;
      setName(accountHolderName);
      setNameRow(accountHolderName)
      setIFSC(ifsc);
      setAccountNumber(accountNumber);
      setAccountNumberRow(accountNumber);
      setMatchAccountNumber(true);
      setConfirmAccountNumber(accountNumber);
      setConfirmAccountNumberRow(accountNumber)
      setAccount(accountType);
      setAccountRow(accountType);
      setSwiftCode(swift);
      setRoutingNumber(routingCode)
      setMatchAccountNumberRow(true);
      if (accountHolderName && accountNumber && accountType){
        setForthStepComplete(true)
      }
    }
    if (firstStepComplete){
      setActiveStep(1);
      setCompleted({0:true});   
    }
    if (secondStepComplete){
      setActiveStep(2);
      setCompleted({0:true,1:true});   
    }
    if (thirdStepComplete){
      setActiveStep(3);
      setCompleted({0:true,1:true,2:true});   
    }
    if (forthStepComplete){
      setActiveStep(4);
      setCompleted({0:true,1:true,2:true,3:true});   
    }

  },[personalNumber,countryVal,personalInfo,
    businessInfo,
    firstStepComplete,
    secondStepComplete,
    thirdStepComplete,
    forthStepComplete,
    isEditing])

  
  const updateCountry = async (country)=>{
   return dispatch(updateAdsInfo({country:country.label}))
  }

  const setCurrentCountry = (cunt)=>{

    if (country === null){
      setCountry(cunt)
    }
    try{
      if ( cunt.code !== country.code){
        setCountry(cunt)
      }
    }
    catch(err){
      console.log(err)
    }
    
  }
  const handleStepper = (index)=>{
    if (completed[index]){
      if (index === activeStep) {setActiveStep(4)}
      else{ 
        setActiveStep(index)}
    }
    if (Object.keys(completed).length === index) {
      setActiveStep(index)
    }
  }

  function checkCompleted() {

    if (Object.keys(completed).length < 4) {
      return false
    }

    let isCompleted = true;
    Object.keys(completed).forEach((item) => {
      if (completed[item] === false) {
        isCompleted = false
      }
    })
    return isCompleted
  }

  const setMobileNUmber = (number)=>{
    return dispatch(genereateOTP(number))
  }  
  
  const setCompletedItem = (item)=>{
    setCompleted(item)
  }
  const verifyMobileNumber = (payload)=>{
   return  dispatch(verifyOTP(payload))
  }
  const setActiveStepItem = (item)=>{
    setActiveStep(item)
  }

  const setPersonalDetails = (payload)=>{
    return dispatch (updateAdsInfo( payload))
  }

  const updateBusinessDetails = (payload)=>{
    return dispatch(updateAdsInfo(payload))
  }

  const updateBankDetails = (payload)=>{
    return dispatch(setUserbankdetail(payload))
  }

  const resetAllStep  = ()=>{
    try {
    setEditing(true);
    setFirstStepComplete (false);
    setSecondStepComplete (false);
    setThridStepComplete(false);
    setForthStepComplete(false);
    }
    catch(err){
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      console.log("err",err)
    }
  }

  const getContent = useCallback((index)=>{
    if (index ===0) {
      return getStepContent(index,setCurrentCountry,isSetCountry,{country,setMobileNUmber,
        appBarHeight,
        verifyMobileNumber,
        setCompletedItem,
        setActiveStepItem,
        setFinalNumber,
        finalNumber,
        updateCountry,
        firstStepComplete,
        secondStepComplete,
        resetAllStep,
      })
    }
    if (index ===1 ){
      return getStepContent(index,country,isSetCountry,{
        setCompletedItem,
        setActiveStepItem,
        setPersonalDetails,
        firstName,
        secondName,
        PAN,
        setfirstName,
        setSecondName,
        setPAN,
        handleSecurityNumber,
        securityNumber,
        secondStepComplete,
        thirdStepComplete,
        
      })
    }
    if (index ===2) {
      return getStepContent(index,country,isSetCountry,{
        businessType,setBusinessType,
        businessTypeRow ,setBusinessTypeRow,
        businessName ,setBusinessName,
        businessPan,setBusinessPan,
        GSTNumber,setGSTNumber,
        TAXNumber,setTaxNumber,
        addressLine1,setaddressLine1,
        addressLine2,setaddressLine2,
        city,setCity,
        pinCode ,setPinCode,
        state,setState,
        businessEmail,setBusinessEmail,
        updateBusinessDetails,
        setCompletedItem,
        setActiveStepItem,
        businessNameRow,setBusinessNameRow,
        addressLine1Row,setaddressLine1Row,
        addressLine2Row,setaddressLine2Row,
        cityRow,setCityRow,
        pinCodeRow,setPinCodeRow,
        stateRow ,setStateRow,
        businessEmailRow,setBusinessEmailRow,
        thirdStepComplete,
        forthStepComplete,
      })
    }
    if (index ===3) {
      return getStepContent(index,country,isSetCountry,{
        name,
        setName,
        ifsc, 
        setIFSC,
        accountNumber, 
        setAccountNumber,
        confirmAccountNumber, 
        setConfirmAccountNumber,
        account, 
        setAccount,
        matchAccountNumber, 
        setMatchAccountNumber,
        updateBankDetails,
        setCompletedItem,
        setActiveStepItem,
        nameRow, setNameRow,
        accountNumberRow, 
        setAccountNumberRow,
        confirmAccountNumberRow, 
        setConfirmAccountNumberRow,
        accountRow, setAccountRow,
        routingNumber ,setRoutingNumber,
        swiftCode,setSwiftCode,
        forthStepComplete,
        matchAccountNumberRow, 
        setMatchAccountNumberRow,
      })
    }
    return getStepContent(index)
  },[activeStep,
    country,
    businessTypeRow,
    setCountry,
    finalNumber,
    firstName,
    secondName,
    PAN,
    businessName,
    businessPan,
    businessNameRow,
    addressLine1Row,
    addressLine2Row,
    cityRow,
    pinCodeRow,
    stateRow ,
    businessEmailRow,
    GSTNumber,
    TAXNumber,
    addressLine1,
    addressLine2,
    city,
    pinCode,
    state,
    businessEmail,
    name,
    ifsc, 
    securityNumber,
    accountNumber,
    confirmAccountNumber,
    account,
    matchAccountNumber,
    nameRow, 
    accountNumberRow, 
    confirmAccountNumberRow, 
    accountRow, 
    routingNumber,
    swiftCode,
    firstStepComplete,
    secondStepComplete,
    thirdStepComplete,
    forthStepComplete,
    matchAccountNumberRow,
  ])
  return (
    <div className={classes.root}>
      <Box component="p" mt={0} mb={0} color="primary.main">
      Complete the steps below to get funds in your bank account.
      </Box>
      <Stepper activeStep={activeStep}   className ={classes.stepperRoot} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label} 
          className={classes.pointerClass}
          completed={completed[index]}
          >
            <StepLabel 
            className={classes.pointerClass}
            classes={{ root : clsx({[classes.iconColor]:Object.keys(completed).length === index})}}
            onClick={()=>handleStepper(index)}><Box component="p"
            className={clsx({[classes.textColor]:Object.keys(completed).length === index})}
            >{label}</Box></StepLabel>
            <StepContent>
              {getContent(index)}
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {checkCompleted()  && (
           <Box component="span" m={3}>All steps completed - You have completed all the steps</Box>
      )}
    </div>
  );
}



const countries = [
  { code: 'AD', label: 'Andorra', phone: '376' },
  { code: 'AE', label: 'United Arab Emirates', phone: '971' },
  { code: 'AF', label: 'Afghanistan', phone: '93' },
  { code: 'AG', label: 'Antigua and Barbuda', phone: '1-268' },
  { code: 'AI', label: 'Anguilla', phone: '1-264' },
  { code: 'AL', label: 'Albania', phone: '355' },
  { code: 'AM', label: 'Armenia', phone: '374' },
  { code: 'AO', label: 'Angola', phone: '244' },
  { code: 'AQ', label: 'Antarctica', phone: '672' },
  { code: 'AR', label: 'Argentina', phone: '54' },
  { code: 'AS', label: 'American Samoa', phone: '1-684' },
  { code: 'AT', label: 'Austria', phone: '43' },
  { code: 'AU', label: 'Australia', phone: '61', suggested: true },
  { code: 'AW', label: 'Aruba', phone: '297' },
  { code: 'AX', label: 'Alland Islands', phone: '358' },
  { code: 'AZ', label: 'Azerbaijan', phone: '994' },
  { code: 'BA', label: 'Bosnia and Herzegovina', phone: '387' },
  { code: 'BB', label: 'Barbados', phone: '1-246' },
  { code: 'BD', label: 'Bangladesh', phone: '880' },
  { code: 'BE', label: 'Belgium', phone: '32' },
  { code: 'BF', label: 'Burkina Faso', phone: '226' },
  { code: 'BG', label: 'Bulgaria', phone: '359' },
  { code: 'BH', label: 'Bahrain', phone: '973' },
  { code: 'BI', label: 'Burundi', phone: '257' },
  { code: 'BJ', label: 'Benin', phone: '229' },
  { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
  { code: 'BM', label: 'Bermuda', phone: '1-441' },
  { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
  { code: 'BO', label: 'Bolivia', phone: '591' },
  { code: 'BR', label: 'Brazil', phone: '55' },
  { code: 'BS', label: 'Bahamas', phone: '1-242' },
  { code: 'BT', label: 'Bhutan', phone: '975' },
  { code: 'BV', label: 'Bouvet Island', phone: '47' },
  { code: 'BW', label: 'Botswana', phone: '267' },
  { code: 'BY', label: 'Belarus', phone: '375' },
  { code: 'BZ', label: 'Belize', phone: '501' },
  { code: 'CA', label: 'Canada', phone: '1', suggested: true },
  { code: 'CC', label: 'Cocos (Keeling) Islands', phone: '61' },
  { code: 'CD', label: 'Congo, Republic of the', phone: '242' },
  { code: 'CF', label: 'Central African Republic', phone: '236' },
  { code: 'CG', label: 'Congo, Democratic Republic of the', phone: '243' },
  { code: 'CH', label: 'Switzerland', phone: '41' },
  { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
  { code: 'CK', label: 'Cook Islands', phone: '682' },
  { code: 'CL', label: 'Chile', phone: '56' },
  { code: 'CM', label: 'Cameroon', phone: '237' },
  { code: 'CN', label: 'China', phone: '86' },
  { code: 'CO', label: 'Colombia', phone: '57' },
  { code: 'CR', label: 'Costa Rica', phone: '506' },
  { code: 'CU', label: 'Cuba', phone: '53' },
  { code: 'CV', label: 'Cape Verde', phone: '238' },
  { code: 'CW', label: 'Curacao', phone: '599' },
  { code: 'CX', label: 'Christmas Island', phone: '61' },
  { code: 'CY', label: 'Cyprus', phone: '357' },
  { code: 'CZ', label: 'Czech Republic', phone: '420' },
  { code: 'DE', label: 'Germany', phone: '49', suggested: true },
  { code: 'DJ', label: 'Djibouti', phone: '253' },
  { code: 'DK', label: 'Denmark', phone: '45' },
  { code: 'DM', label: 'Dominica', phone: '1-767' },
  { code: 'DO', label: 'Dominican Republic', phone: '1-809' },
  { code: 'DZ', label: 'Algeria', phone: '213' },
  { code: 'EC', label: 'Ecuador', phone: '593' },
  { code: 'EE', label: 'Estonia', phone: '372' },
  { code: 'EG', label: 'Egypt', phone: '20' },
  { code: 'EH', label: 'Western Sahara', phone: '212' },
  { code: 'ER', label: 'Eritrea', phone: '291' },
  { code: 'ES', label: 'Spain', phone: '34' },
  { code: 'ET', label: 'Ethiopia', phone: '251' },
  { code: 'FI', label: 'Finland', phone: '358' },
  { code: 'FJ', label: 'Fiji', phone: '679' },
  { code: 'FK', label: 'Falkland Islands (Malvinas)', phone: '500' },
  { code: 'FM', label: 'Micronesia, Federated States of', phone: '691' },
  { code: 'FO', label: 'Faroe Islands', phone: '298' },
  { code: 'FR', label: 'France', phone: '33', suggested: true },
  { code: 'GA', label: 'Gabon', phone: '241' },
  { code: 'GB', label: 'United Kingdom', phone: '44' },
  { code: 'GD', label: 'Grenada', phone: '1-473' },
  { code: 'GE', label: 'Georgia', phone: '995' },
  { code: 'GF', label: 'French Guiana', phone: '594' },
  { code: 'GG', label: 'Guernsey', phone: '44' },
  { code: 'GH', label: 'Ghana', phone: '233' },
  { code: 'GI', label: 'Gibraltar', phone: '350' },
  { code: 'GL', label: 'Greenland', phone: '299' },
  { code: 'GM', label: 'Gambia', phone: '220' },
  { code: 'GN', label: 'Guinea', phone: '224' },
  { code: 'GP', label: 'Guadeloupe', phone: '590' },
  { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
  { code: 'GR', label: 'Greece', phone: '30' },
  { code: 'GS', label: 'South Georgia and the South Sandwich Islands', phone: '500' },
  { code: 'GT', label: 'Guatemala', phone: '502' },
  { code: 'GU', label: 'Guam', phone: '1-671' },
  { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
  { code: 'GY', label: 'Guyana', phone: '592' },
  { code: 'HK', label: 'Hong Kong', phone: '852' },
  { code: 'HM', label: 'Heard Island and McDonald Islands', phone: '672' },
  { code: 'HN', label: 'Honduras', phone: '504' },
  { code: 'HR', label: 'Croatia', phone: '385' },
  { code: 'HT', label: 'Haiti', phone: '509' },
  { code: 'HU', label: 'Hungary', phone: '36' },
  { code: 'ID', label: 'Indonesia', phone: '62' },
  { code: 'IE', label: 'Ireland', phone: '353' },
  { code: 'IL', label: 'Israel', phone: '972' },
  { code: 'IM', label: 'Isle of Man', phone: '44' },
  { code: 'IN', label: 'India', phone: '91' },
  { code: 'IO', label: 'British Indian Ocean Territory', phone: '246' },
  { code: 'IQ', label: 'Iraq', phone: '964' },
  { code: 'IR', label: 'Iran, Islamic Republic of', phone: '98' },
  { code: 'IS', label: 'Iceland', phone: '354' },
  { code: 'IT', label: 'Italy', phone: '39' },
  { code: 'JE', label: 'Jersey', phone: '44' },
  { code: 'JM', label: 'Jamaica', phone: '1-876' },
  { code: 'JO', label: 'Jordan', phone: '962' },
  { code: 'JP', label: 'Japan', phone: '81', suggested: true },
  { code: 'KE', label: 'Kenya', phone: '254' },
  { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
  { code: 'KH', label: 'Cambodia', phone: '855' },
  { code: 'KI', label: 'Kiribati', phone: '686' },
  { code: 'KM', label: 'Comoros', phone: '269' },
  { code: 'KN', label: 'Saint Kitts and Nevis', phone: '1-869' },
  { code: 'KP', label: "Korea, Democratic People's Republic of", phone: '850' },
  { code: 'KR', label: 'Korea, Republic of', phone: '82' },
  { code: 'KW', label: 'Kuwait', phone: '965' },
  { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
  { code: 'KZ', label: 'Kazakhstan', phone: '7' },
  { code: 'LA', label: "Lao People's Democratic Republic", phone: '856' },
  { code: 'LB', label: 'Lebanon', phone: '961' },
  { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
  { code: 'LI', label: 'Liechtenstein', phone: '423' },
  { code: 'LK', label: 'Sri Lanka', phone: '94' },
  { code: 'LR', label: 'Liberia', phone: '231' },
  { code: 'LS', label: 'Lesotho', phone: '266' },
  { code: 'LT', label: 'Lithuania', phone: '370' },
  { code: 'LU', label: 'Luxembourg', phone: '352' },
  { code: 'LV', label: 'Latvia', phone: '371' },
  { code: 'LY', label: 'Libya', phone: '218' },
  { code: 'MA', label: 'Morocco', phone: '212' },
  { code: 'MC', label: 'Monaco', phone: '377' },
  { code: 'MD', label: 'Moldova, Republic of', phone: '373' },
  { code: 'ME', label: 'Montenegro', phone: '382' },
  { code: 'MF', label: 'Saint Martin (French part)', phone: '590' },
  { code: 'MG', label: 'Madagascar', phone: '261' },
  { code: 'MH', label: 'Marshall Islands', phone: '692' },
  { code: 'MK', label: 'Macedonia, the Former Yugoslav Republic of', phone: '389' },
  { code: 'ML', label: 'Mali', phone: '223' },
  { code: 'MM', label: 'Myanmar', phone: '95' },
  { code: 'MN', label: 'Mongolia', phone: '976' },
  { code: 'MO', label: 'Macao', phone: '853' },
  { code: 'MP', label: 'Northern Mariana Islands', phone: '1-670' },
  { code: 'MQ', label: 'Martinique', phone: '596' },
  { code: 'MR', label: 'Mauritania', phone: '222' },
  { code: 'MS', label: 'Montserrat', phone: '1-664' },
  { code: 'MT', label: 'Malta', phone: '356' },
  { code: 'MU', label: 'Mauritius', phone: '230' },
  { code: 'MV', label: 'Maldives', phone: '960' },
  { code: 'MW', label: 'Malawi', phone: '265' },
  { code: 'MX', label: 'Mexico', phone: '52' },
  { code: 'MY', label: 'Malaysia', phone: '60' },
  { code: 'MZ', label: 'Mozambique', phone: '258' },
  { code: 'NA', label: 'Namibia', phone: '264' },
  { code: 'NC', label: 'New Caledonia', phone: '687' },
  { code: 'NE', label: 'Niger', phone: '227' },
  { code: 'NF', label: 'Norfolk Island', phone: '672' },
  { code: 'NG', label: 'Nigeria', phone: '234' },
  { code: 'NI', label: 'Nicaragua', phone: '505' },
  { code: 'NL', label: 'Netherlands', phone: '31' },
  { code: 'NO', label: 'Norway', phone: '47' },
  { code: 'NP', label: 'Nepal', phone: '977' },
  { code: 'NR', label: 'Nauru', phone: '674' },
  { code: 'NU', label: 'Niue', phone: '683' },
  { code: 'NZ', label: 'New Zealand', phone: '64' },
  { code: 'OM', label: 'Oman', phone: '968' },
  { code: 'PA', label: 'Panama', phone: '507' },
  { code: 'PE', label: 'Peru', phone: '51' },
  { code: 'PF', label: 'French Polynesia', phone: '689' },
  { code: 'PG', label: 'Papua New Guinea', phone: '675' },
  { code: 'PH', label: 'Philippines', phone: '63' },
  { code: 'PK', label: 'Pakistan', phone: '92' },
  { code: 'PL', label: 'Poland', phone: '48' },
  { code: 'PM', label: 'Saint Pierre and Miquelon', phone: '508' },
  { code: 'PN', label: 'Pitcairn', phone: '870' },
  { code: 'PR', label: 'Puerto Rico', phone: '1' },
  { code: 'PS', label: 'Palestine, State of', phone: '970' },
  { code: 'PT', label: 'Portugal', phone: '351' },
  { code: 'PW', label: 'Palau', phone: '680' },
  { code: 'PY', label: 'Paraguay', phone: '595' },
  { code: 'QA', label: 'Qatar', phone: '974' },
  { code: 'RE', label: 'Reunion', phone: '262' },
  { code: 'RO', label: 'Romania', phone: '40' },
  { code: 'RS', label: 'Serbia', phone: '381' },
  { code: 'RU', label: 'Russian Federation', phone: '7' },
  { code: 'RW', label: 'Rwanda', phone: '250' },
  { code: 'SA', label: 'Saudi Arabia', phone: '966' },
  { code: 'SB', label: 'Solomon Islands', phone: '677' },
  { code: 'SC', label: 'Seychelles', phone: '248' },
  { code: 'SD', label: 'Sudan', phone: '249' },
  { code: 'SE', label: 'Sweden', phone: '46' },
  { code: 'SG', label: 'Singapore', phone: '65' },
  { code: 'SH', label: 'Saint Helena', phone: '290' },
  { code: 'SI', label: 'Slovenia', phone: '386' },
  { code: 'SJ', label: 'Svalbard and Jan Mayen', phone: '47' },
  { code: 'SK', label: 'Slovakia', phone: '421' },
  { code: 'SL', label: 'Sierra Leone', phone: '232' },
  { code: 'SM', label: 'San Marino', phone: '378' },
  { code: 'SN', label: 'Senegal', phone: '221' },
  { code: 'SO', label: 'Somalia', phone: '252' },
  { code: 'SR', label: 'Suriname', phone: '597' },
  { code: 'SS', label: 'South Sudan', phone: '211' },
  { code: 'ST', label: 'Sao Tome and Principe', phone: '239' },
  { code: 'SV', label: 'El Salvador', phone: '503' },
  { code: 'SX', label: 'Sint Maarten (Dutch part)', phone: '1-721' },
  { code: 'SY', label: 'Syrian Arab Republic', phone: '963' },
  { code: 'SZ', label: 'Swaziland', phone: '268' },
  { code: 'TC', label: 'Turks and Caicos Islands', phone: '1-649' },
  { code: 'TD', label: 'Chad', phone: '235' },
  { code: 'TF', label: 'French Southern Territories', phone: '262' },
  { code: 'TG', label: 'Togo', phone: '228' },
  { code: 'TH', label: 'Thailand', phone: '66' },
  { code: 'TJ', label: 'Tajikistan', phone: '992' },
  { code: 'TK', label: 'Tokelau', phone: '690' },
  { code: 'TL', label: 'Timor-Leste', phone: '670' },
  { code: 'TM', label: 'Turkmenistan', phone: '993' },
  { code: 'TN', label: 'Tunisia', phone: '216' },
  { code: 'TO', label: 'Tonga', phone: '676' },
  { code: 'TR', label: 'Turkey', phone: '90' },
  { code: 'TT', label: 'Trinidad and Tobago', phone: '1-868' },
  { code: 'TV', label: 'Tuvalu', phone: '688' },
  { code: 'TW', label: 'Taiwan, Province of China', phone: '886' },
  { code: 'TZ', label: 'United Republic of Tanzania', phone: '255' },
  { code: 'UA', label: 'Ukraine', phone: '380' },
  { code: 'UG', label: 'Uganda', phone: '256' },
  { code: 'US', label: 'United States', phone: '1', suggested: true },
  { code: 'UY', label: 'Uruguay', phone: '598' },
  { code: 'UZ', label: 'Uzbekistan', phone: '998' },
  { code: 'VA', label: 'Holy See (Vatican City State)', phone: '379' },
  { code: 'VC', label: 'Saint Vincent and the Grenadines', phone: '1-784' },
  { code: 'VE', label: 'Venezuela', phone: '58' },
  { code: 'VG', label: 'British Virgin Islands', phone: '1-284' },
  { code: 'VI', label: 'US Virgin Islands', phone: '1-340' },
  { code: 'VN', label: 'Vietnam', phone: '84' },
  { code: 'VU', label: 'Vanuatu', phone: '678' },
  { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
  { code: 'WS', label: 'Samoa', phone: '685' },
  { code: 'XK', label: 'Kosovo', phone: '383' },
  { code: 'YE', label: 'Yemen', phone: '967' },
  { code: 'YT', label: 'Mayotte', phone: '262' },
  { code: 'ZA', label: 'South Africa', phone: '27' },
  { code: 'ZM', label: 'Zambia', phone: '260' },
  { code: 'ZW', label: 'Zimbabwe', phone: '263' },
];