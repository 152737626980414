import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from "../../commons/muiCustomComponents/Box";
import Input from "../../commons/muiCustomComponents/Input";
import Button from "../../commons/muiCustomComponents/Button";
import { CircularLoader, LinearLoader, PageBlockLoader } from '../../commons/commonWrapper/Loaders';
import {
  getMapDomainStatus,
  postMapDomainStatus,
  getDnsRecords,
  updateDnsRecords,
  addNewDnsRecords,
  deleteDnsRecords,
  EditMapDomain,
} from "./settings-action";
import { useDispatch,useSelector } from "react-redux";
import MobileProvider from "commons/context/MobileProvider";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import ShowMapDomain from "./components/ShowMapDomain";
import Grid from "../../commons/muiCustomComponents/Grid";
import DNSRecord from "./components/DNSRecords";
import { useHistory } from "react-router-dom";




const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  uppercontainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
  },
  mapRoot: {
    root: '100%',
    backgroundColor: theme.palette.pubWhite,
    boxShadow:theme.shadows[1],
  },
  child: {
    backgroundColor: theme.palette.pubVeryLightPink,
    margin: theme.spacing(16),
  },
  iconWidth: {
    width: '100%',
    height: '100%',
  },
  marginLeft :{
    marginLeft:theme.spacing(3),
  },
}));

export default function MapCustomDomain({ appBarHeight }) {

  const classes = useStyles();
  const isMobile = useContext(MobileProvider);
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const [isFatching, setFatching] = useState(false);
  const [status, setStatus] = useState('');
  const [domainName, setDomainName] = useState('');
  const [mapData, setMapData] = useState({});
  const [dnsData, setDnsData] = useState([]);
  const [host, setHost] = useState('');
  const {
    social : {
      facebookPages,
      twitterPages,
    },
    scheduler: {
      newsletterData,
    },
    landing: {
      activeDomain,
      userPermissions,
    },
    header: {
      domainList,
    },
  } = useSelector(state =>  state);
  const dispatch = useDispatch();

    const fqdn = domainList && domainList[activeDomain] && domainList[activeDomain][0].fqdn;

  const handleDomainChange = (e) => {
    const domain = e.currentTarget.value;
    setDomainName(domain);
  }
  useEffect(() => {
    let isMounted = true;
    const getStatus = async () => {
      try {
        setFatching(true)
        const data = await dispatch(getMapDomainStatus());
        // eslint-disable-next-line camelcase
        const { status, map_domain = '' } = data;
        if (status === "active") {
          try {
            const data = await dispatch(getDnsRecords())
            isMounted && setDnsData(data.data);
            isMounted && setHost(data.host)
          }
          catch (err) {
            // eslint-disable-next-line no-console
            console.log("err")
          }

        }
        isMounted && setStatus(status);
        isMounted && setMapData(data);
        // eslint-disable-next-line camelcase
        setDomainName(map_domain || domainName);
        setFatching(false)
      }
      catch (err) {
        isMounted && setFatching(false)
      }

    }
    getStatus()
    return () => (isMounted = false)
  }, [status]);

  const updateContent = async () => {
    try {
      setFatching(true)
      const data = await dispatch(getDnsRecords())
      setDnsData(data.data);
      setHost(data.host)
      setFatching(false)
    }
    catch (err) {
      setFatching(false)
    }
  }
  const saveDomainMapping = async (showStatus) => {
    if (!domainName) return;
    try {
      setLoading(true)
      // eslint-disable-next-line camelcase
      const data = await dispatch(postMapDomainStatus({ url: domainName },domainName,showStatus))
      const { status } = data;
      setMapData(data);
      setLoading(false)
      setStatus(status)
    }
    catch (err) {
      setLoading(false)
    }
  }

  const deleteSingalRecords = async (payload) => {
    return dispatch(deleteDnsRecords(payload))
  }

  const updateRecords = (payload) => {
    return dispatch(updateDnsRecords(payload))
  }
  const addRecords = (payload) => {
    return dispatch(addNewDnsRecords(payload))
  }
  const EditMapDomainName = async () => {
    try {
      setFatching(true)
      await dispatch(EditMapDomain(
        { url: domainName },
      ));
      setStatus("Invite");
      setFatching(false)
    }
    catch (err) {
      setFatching(false)
    }
  }
  const goToWebsite = ()=>{
    history.goBack();
  }

  if (isFatching) return <Grid container ><Grid item xs={12} md={9} xl={10}>
    {isMobile ? <LinearLoader />
      : <PageBlockLoader />
    }
  </Grid></Grid>

  if (status === "active") {
    return <Grid container> <Grid container ><Grid item xs={12} md={9} xl={10}>
      <Box m={isMobile ? 2 : 0} mt={4} mb={4}>

        <DNSRecord dnsData={dnsData}
          updateContent={updateContent}
          host={host} addRecords={addRecords} deleteSingalRecords={deleteSingalRecords} updateRecords={updateRecords} />

      </Box>
    </Grid>
    </Grid>
    </Grid>
  }
  return (
    <Grid container ><Grid item xs={12} md={9} xl={10}>
      <Box m={isMobile ? 2 : 0} mt={4} >
        <Box mt={4} className={classes.mapRoot}>
          <Box bgcolor="pubPrimary"  className={classes.uppercontainer}>
            <Box component="span" color="primary.main" display="flex" flexDirection="column" pt={4} pb={4}>
            { status === "Invite"?`Replace ${fqdn ||''} with 
            a domain you own. You can also manage your domain 
            settings in PubNinja, once your domain is transferred here.`
          : `${domainName} is currently in ${status} stage. You need to
           change your nameservers in your registrar account (godaddy, namecheap etc ) to complete the verification steps. `  
          }
          </Box>
          </Box>

          <Grid container> <Grid item xs={12} md={12}>
            <Box className={classes.root} m={4} >
              {status === "Invite" ? <Box pt={8} pb={8}><Grid container>
               
                <Grid item xs={12}  md={4} xl={5}>
                  <Box>
                    <Box component="span" fontSize={12} fontWeight="bold">
                      Domain Name
                    </Box>
                  </Box>
                  <Box pb={isMobile ?4 :'auto'}>
                  <Box component="span" fontSize={12}  color="secondary.main">
                      Requried  
                  </Box>
                  </Box>
                  
                </Grid>
                <Grid item xs={12}  md={8} xl={7} >
                    <Input color="secondary"
                      size="small"
                      fullWidth
                      value={domainName || ''}
                      onChange={handleDomainChange}
                      width="80%"
                      label="Enter name of domain you own" />
                </Grid>
                

                
              </Grid>
                {/* {isMobile &&
                  <Footer >
                    <Box display="flex" width="80%">
                      <Button fullWidth disabled={isLoading} variant="contained" onClick={saveDomainMapping}>
                        Next <ArrowRightAltIcon />
                      </Button>
                      {isLoading && <CircularLoader height="auto" size={20} ml={1} />}
                    </Box>
                  </Footer>

                } */}
              </Box>
                :
                <>
                <ShowMapDomain
                  mapData={mapData}
                  saveDomainMapping={saveDomainMapping}
                  isLoading={isLoading}
                  EditMapDomainName={EditMapDomainName}
                />
                
                </>

              }
            </Box>
            
          </Grid>

          </Grid>
        </Box>
      </Box>
      <Box bgcolor="pubPrimary"pt={4} pb={4}  display="flex" justifyContent="space-between" alignItems="center" pr={4} mb={4}>
              { status === 'Invite' ? <><Box></Box><Box  display="flex">
                <Button onClick={goToWebsite}  variant="contained" color="default">Close</Button>
                <Button  disabled={isLoading} variant="contained" classes={{root:classes.marginLeft}}
                 onClick={()=>saveDomainMapping(false)}>
                Next <ArrowRightAltIcon />
              </Button>
               {isLoading && <CircularLoader height="auto" size={20} ml={1} />}
               </Box>
               </>
               :<>
               
                <Box component="span" ml={4}>
                Please click on verify to check once you have completed the above steps. It usually takes upto 24 hours to process these updates. 
                </Box>
                <Box display="flex">
                 <Button onClick={EditMapDomainName}   variant="contained" color="default" >Edit</Button>
               <Button disabled={isLoading}  variant="contained" 
               classes={{root:classes.marginLeft}}
               onClick={saveDomainMapping}>verify</Button>
               {isLoading && <CircularLoader height="auto" ml={1} size={20} />}
               </Box>
             </>
              }
            </Box>
    </Grid></Grid>
  );
}
