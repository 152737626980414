import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import {NavLink}  from "react-router-dom";
import Box from "../../../commons/muiCustomComponents/Box";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  navlink :{
    textDecoration:'none',
    color:theme.palette.pubGreyishBrown,
  },
  iconPosition :{
    justifyContent:theme.isMobile ?'flex-end' :'flex-start',
  },
}));

export default function SimpleList({ settingsList }) {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <List component="nav" aria-label="main mailbox folders">
        {
          settingsList.map(({name,link}, key) => (
            <NavLink key={key} to ={link} className={classes.navlink}>
            <ListItem button >
              <ListItemText primary={name} />
              <ListItemIcon className={classes.iconPosition}>
                <ArrowForwardIosIcon />
              </ListItemIcon>
            </ListItem>
            <Divider />
            </NavLink>
          ))
        }
      </List>
    </Box>
  );
}