
import React,{useCallback,useContext} from "react";
import Ads from "components/advertisement/Overview";
import FullScreenPage from '../commons/commonWrapper/FullScreenPage';
import Box from "../commons/muiCustomComponents/Box";
import MobileProvider from "commons/context/MobileProvider";


export default function AdsPage (){
    const isMobile = useContext(MobileProvider);

    const barCallback = useCallback(() =>(<Box 
        display="flex"
        alignItems="center"
        component="span"
        >Advertisement setup</Box>),[]);
        
    return(
        <FullScreenPage BarComponent={barCallback} isDesktop={false} isCloseIcon={true}>
            <Box mt={isMobile ?0:6}><Ads/></Box>
        </FullScreenPage>
    )
}
