import React from 'react';
import Step from '@material-ui/core/Step';

class  CustomStep extends React.Component {
  render() {
    const {...rest} = this.props;
    return (<Step {...rest}  />)
  }
}

export default CustomStep;