import schedulerAction from "../../../constants/actions/scheduler";

const initalState = {
  freeSignups:{
    subscribeCount: 0,
  },
  paidSignups:{
    subscribeCount: 0,
  },
  totalSignups: {
    subscribeCount: 0,
  },
  emailCampaigns: [],
  segments: [],
};
  
/**
 * This reducer function handle the scheduler related
 * functionalites
 * @param {*} state 
 * @param {*} action 
 */
export default function schedulerReducer(state=initalState, action) {
  const activeCampaignsObj = {
    result: [],
  };
  switch(action.type) {
    case schedulerAction.FETCHSCHEDULEDTWEETS.SUCCESS:
      return {
        ...state, 
        scheduledTweets: action.data,
      }
    case schedulerAction.FETCHSCHEDULEDFBPOSTS.SUCCESS:
      return {
        ...state, 
        scheduledFbPosts: action.data,
      }
    case schedulerAction.FETCHNEWSLETTER.SUCCESS:
      return {
        ...state,
        newsletterData: action.data,
      }
    case schedulerAction.FETCHDOMAINCAMPAIGNS.SUCCESS:
      return {
        ...state,
        activeCampaigns: action.data,
      }
    case 'UPDATE_EMAIL_SEGMENTS':
      return {
        ...state,
        segments: action.data.products,
        freeSignups: action.data.free ? action.data.free: {subscribeCount:0},
        paidSignups: action.data.paid ? action.data.paid: {subscribeCount:0},
        totalSignups: action.data.all ? action.data.all : {subscribeCount:0},
      }
    case `UPDATE_EMAIL_CAMPAIGN_LIST`:
      return {
        ...state,
        emailCampaigns: action.data.data,
      }
    case "UpdateActiveDomainAndPermission":
      return {
        ...state,
        activeCampaigns: activeCampaignsObj,
        newsletterData: {},
      }
    case "ChangingActiveDomain":
      return {
        ...state,
        freeSignups:{subscribeCount:0},
        paidSignups:{subscribeCount:0},
        totalSignups:{subscribeCount:0},
        emailCampaigns: [],
        segments: [],
      }
    default:
      return state;
  }
}