import React, { useState, useEffect ,useRef} from "react";
import Dialog from "commons/muiCustomComponents/Dailog";
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from "react-redux";
import Grid from "commons/muiCustomComponents/Grid";
import Button from "commons/muiCustomComponents/Button";
import Box from "commons/muiCustomComponents/Box";
import MobileProvider from "commons/context/MobileProvider";
import Header from "./components/Header";
import Footer from "commons/commonWrapper/Footer";
import {
  saveMediaToPisco,
  getVideoThumbnail,
  createNewArticle,
  fetchArticleMeta,
  updateArticleMeta,
  clearCache,
  sendResetPasswordMail,
  cancelAPI,
  deleteArticle,
  getBlurImage,
} from "./content-action";
import setAppMessage,{uploadMediaAction,abortMediaAlert} from "app-component/app-component-action";
import { LinearLoader, CircularLoader } from "commons/commonWrapper/Loaders";
import { useHistory, useLocation, useParams } from "react-router-dom";
import TextArticleQuickShare from "./TextArticleQuickShare";
import CloseIcon from '@material-ui/icons/Close';
import DraftjsEditor from "./components/DraftjsEditor";
import TextField from '@material-ui/core/TextField';
import WithFileUpload from "commons/hocs/with-file-upload";
import DeleteIcon from '@material-ui/icons/Delete';
import DraftsIcon from '@material-ui/icons/Drafts';
import ThumbnailDialogs from './ThumbnailDialog';
import PodcastArticleMetaHome from "./contentMetaHome";
import CropImageDialog from "./CroppingDialog";
import ImageIcon from '@material-ui/icons/Image';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import clsx from "clsx";
import AudioRecorder from "./components/AudioRecorder";
import MicTwoTone from "@material-ui/icons/MicTwoTone";
import InputAdornment from '@material-ui/core/InputAdornment';
import PodcastArticlePreview from "./PodcastArticlePreview";
import UseInfo from "../../commons/CustomComponents/UseInfo";
import InputBox from "commons/muiCustomComponents/InputBox";
import {hideIntercom} from "../../utils/utils";
import VideoPlayer from  'react-video-js-player';
import { LogglyTracker } from 'loggly-jslogger';


const WithUploadButton = WithFileUpload(Button);



const uploadImage = () => {
  return (
    <Box width={170} style={{
      border: '1px solid #e5e5e5',
      cursor: 'pointer',
      width: '170px !important', height: '90px',
    }} bgcolor="#e5e5e5">
      <Box width="100%" height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <Box position="relative">
          <AddIcon style={{
            position: 'absolute',
            top: '-7px',
            left: '7px',
          }} />
          <ImageIcon />
        </Box>
        <Box component="span" fontSize={12}> UPLOAD IMAGE </Box>
      </Box>
    </Box>
  )
}
const FileUploaderButton = WithFileUpload(uploadImage);

const useStyles = makeStyles(theme => ({
  inputRoot: {
    backgroundColor: theme.palette.pubWhite,
    "&:hover,&:focus": {
      backgroundColor: theme.palette.pubWhite,
    },
  },
  minHeight: {
    minHeight: '100%',
  },
  mobileMargin: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    width:'90%',
  },
  footer: {
    padding: theme.spacing(4),
    background: theme.palette.pubWhite,
    borderTop: '1px solid #e5e5e5',
    width: '100%',
    height: '100%',
  },
  bodyContainer :{
    position:'relative',
    top:'75px',
  },
  cardDesgin: {
    border: '1px solid #e5e5e5',
    width: '170px !important',
    position: 'relative',
    height: '90px',
    borderRadius:theme.spacing(1),
    marginLeft:theme.spacing(3),
  },
  slected: {
    opacity: '0.2',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  unslected: {
    opacity: '0.7',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  checkIconClass: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    fontSize: theme.spacing(11),
    color: theme.palette.pubWhite,
  },
  mediaClass: {
    backgroundColor: 'grey',
  },
  thumbnailContainer :{
    display:'inline-flex',
    // overflow:'scroll',
  },
  wrapperClass :{
    overflowX: 'scroll',
    flex: '1 1 auto',
    display: 'inline-block',
    position: 'relative',
    whiteSpace: 'nowrap',
    width:'100%',
    "&:-webkit-scrollbar": {
      display: 'none',
      width:'0px',
    },
    msOverflowStyle:'none',
  },
}));




function PodcastHomePage() {
  const classes = useStyles();
  const history = useHistory();
  const { articleId } = useParams();
  const dispatch = useDispatch();
  const [showSelectDialog, setShowSelectDialog] = useState(true);
  const isMobile = React.useContext(MobileProvider);
  const [content, setContent] = useState({});
  const [wordCount, setWordCount] = useState(0);
  const [lastUpdate, setLastUpdate] = useState((new Date()).getTime());
  const [contentBase64, setContentBase64]  = useState(null);
  const [errTitle ,setErrTitle] = useState(false);
  const [metadata, setMetadata] = useState({});
  const { landing, header:{domainList}} = useSelector(state => state);
  const {activeDomain, userPermissions,userProfile } = landing;
  const  domainInfo = domainList[activeDomain] && domainList[activeDomain][0];
  const [status, setStatus] = useState(0);
  const [headerType, setHeaderType] = useState(null);
  const [title, setTitle] = useState('');
  const [file, setFile] = useState(null);
  const [requesting, setRequesting] = useState(false);
  const [creatingArticle, SetCreatingArticle] = useState(false);
  const [savingMeta, setSavingMeta] = useState(false);
  const [delteVideo, setDeletingvideo] = useState(false);
  const [showMetaDialog, setShowMetaDialog] = useState(false);
  const [openThumbDialog,setThumbnailDialog]= useState(false);
  const [thumbnailContent ,setthumbnailContent] = useState(null);
  const [tileData, setTileData] = useState([1, 2, 3, 4]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showAudioRecorder, setShowAudioRecorder] = useState(false);
  const [fetchingVideoArticle ,setFetchingVideoArticle] = useState(false);
  const [showPreviewDialog, setShowPreviewDialog] = useState(false);
  const [filename ,setFileName] = useState(null);
  const [sectionID, setSectionID] = useState(null);
  const [subSectionID, setSubSectionID] = useState(null);
  const [subsubsectionID, setSubSubSectionID] = useState(null);
  const [sectionDefaultvalue, setSectionDefaultVlaue] = useState(null);
  const [subSectionDefaultvalue, setSubSectionDefaultVlaue] = useState(null);
  const [subSubSectionDefaultvalue, setSubSubSectionDefaultVlaue] = useState(null);
  const [cashedContent ,setcashedContent] = useState(null);
  const [ savingTitle, setSavingTitle ] = useState(false);

  const logger = new LogglyTracker();
  logger.push({
  'logglyKey': '142d21f5-10d3-47a9-aa5a-f83e0a3abed7',
  'sendConsoleErrors': true,
  'tag': 'fileuploadaudio',
});

  const childRef = useRef();
  useEffect(()=>{
    return hideIntercom();
  },[])
  useEffect(() => {
    if (articleId === 'new') {
      createArticle();
    } else {
      fetchArticleInfo();
    }
  }, [articleId]);

  const fetchArticleInfo = async () => {
    try {
      setFetchingVideoArticle(true);
      const {result:metaData} =  await 
        dispatch(fetchArticleMeta(articleId));
      
      if(metaData.headerType && metaData.headerType.indexOf('audio') === -1) {
        dispatch(setAppMessage({ message: `Cannot be edited as audio Article.`, header: 'Error', type: 'alert' }));
        history.push("/dash/create");
        return;
      }
      setHeaderType(metaData.headerType);
      
      
      
      if((metaData.headerContent) && Object.keys((metaData.headerContent)).length>0){
        metaData.headerContent = JSON.parse(metaData.headerContent)
      }
      setMetadata(metaData)
      if (metaData.thumbnail){
        setTileData([metaData.thumbnail,2,3,4,5]);
        setSelectedItem(0);
      }
      setTitle(metaData.title);
      setContent(metaData.richDescription);
      setcashedContent(metaData.richDescription);
      setRequesting(false);
      setFetchingVideoArticle(false);
    }catch (err) {
      const { message } = err;
      setFetchingVideoArticle(false);
      dispatch(setAppMessage({ message: message || 'Unable to fetch article.', header: 'Error', type: 'alert' }));
      history.push('/dash/create');
    }
  }


  const createArticle = async () => {
    try {
      SetCreatingArticle(true)
      const { result: { metadata: { articleId } } } = await dispatch(createNewArticle());
      await dispatch(updateArticleMeta(articleId, { headerType: "audio/mp3", title: '' }));
      setHeaderType('audio/mp3');
      history.push(`/dash/create/podcast/${articleId}`);
      SetCreatingArticle(false)
      
    } catch (err) {
      SetCreatingArticle(false)
      const { message } = err;
      dispatch(setAppMessage({ message: message || 'Unable to process audio.', header: 'Error', type: 'alert' }));
      history.push('/dash/create/');
    }
  }

  const discardMedia = () => {
    try {
      dispatch(abortMediaAlert(async ()=>{
        if(articleId){
          try {
          setSavingTitle(true);
          await dispatch(updateArticleMeta(articleId, { "richDescription": {
            html: cashedContent.html,
            objectMap: typeof cashedContent.objectMap === 'string' ? cashedContent.objectMap : JSON.stringify(cashedContent.objectMap),
          } }));
          setSavingTitle(false);
          setShowSelectDialog(false);
          history.push('/dash/create');
          }
          catch(err){
            setSavingTitle(false);
            dispatch(setAppMessage({ message: `Something went wrong , Please click again on cross button`, header: 'Error', type: 'alert' }));
          }
        }
        else{
          setShowSelectDialog(false);
          history.push('/dash/create');
        }
        
      }))
    }
    catch(e){
      console.log("e",e)
    } 
  }

  const handleMediaUpload = async (file, cb) => {
    const format = file.type.split('/')[0];
    if (format !== "image") return alert("Invalid File Format.");
    try {
      let res = await dispatch(saveMediaToPisco(file));
      const { localUploadResponse: { cdn } } = res;
      cb(null, cdn);
    } catch (err) {
      const { message } = err;
      dispatch(setAppMessage({ message: message || 'Unable to uploa media.', header: 'Error', type: 'alert' }));
      cb(err);
    }
  }


  // update the article content at backend
  const updateHtmlContent = async (html, blockMap, count) => {
    try {
      setContent({ html, blockMap });
      await dispatch(updateArticleMeta(articleId, { "richDescription": { html: html, objectMap: JSON.stringify(blockMap) } }));
      metadata.richDescription = { html: html, objectMap:JSON.stringify(blockMap) };

      // await dispatch(updateArticleContent(articleId, html, blockMap)); 
    } catch (err) {
      // handle error
      dispatch(setAppMessage({ message: `Unable to update descripition.`, header: 'Error', type: 'alert' }));
    }
  }

  const handleTitle = (event) => {
    if (event.target.value.length <=150) {
      setTitle(event.target.value);
      if (event.target.value){
        setErrTitle(false)
      }
      else{
        setErrTitle(true)
      }
    }

  }

   // upload audio to pisco library
   const saveAudioFile = async(target, duration) => {
    if(isNaN(duration)) duration = null;
    if(duration === "audio/*") duration = null;
    let file = target;
    
    if(!(target instanceof File)) {
      file = target && target.files[0];
    }

    const data = {
      'filetype': file.type || '',
      'filename': file.name || '',
      'filesize': file.size || 0,
      'email': ((landing||{}).userProfile||{}).email || '',
      'url': (window.location||{}).href || '',
      'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
      'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
    };    
    logger.push({
      'type': 'info',
      'data': data,
    });
    console.log({
      userProfile,
      filetype: file.type || '',
      filename: file.name || '',
      filesize: file.size || 0,
      activeDomain,
    });
    const extension = file && file.type.split("/")[1];
    const allowed = ['mp3','wav','wma','x-ms-wma','3gpp','m4a','x-m4a'];
    console.log("extension",extension)
    const { name } = file;
    
    const nameArr = name.split(".");
    const nameFormat = nameArr[nameArr.length - 1];
    if(allowed.indexOf(extension) < 0 && nameFormat !== 'mp3') {
      dispatch(setAppMessage({ message: 'Media format not supported', header: 'Error', type: 'alert' }));
      return;
    }
    const { size } = file;
    setHeaderType(file.type)
    const sizeInMb = size / 1000000;
    if (sizeInMb > 1024*10) {
      dispatch(setAppMessage({ message: 'audio larger than 10GB not allowed.', header: 'Error', type: 'alert' }));
      
      return;
    }
    setFile(file);
    try {
      
      setRequesting(true);
      setShowAudioRecorder(false);
      const data=  await dispatch(uploadMediaAction(file,{
        filename: file && file.name,
        filetype: extension && extension,
        type : 'post',
        domain_id : activeDomain,
        post_id : parseInt(articleId,10),
        uniqueId : parseInt(articleId,10),
       },'audio'));
      
      setHeaderType(file.type);
      metadata.headerContent = {cdn :data.uploadedURL}
      
      metadata.cdn = data.uploadedURL;
      metadata.hlsUrl = data.hlsUrl
      const dataObj = {
        title,
        thumbnail: (tileData && tileData[selectedItem])|| '',
        headerType: headerType ||'audio/mp3',
        headerContent: JSON.stringify(metadata.headerContent),
        hlsUrl : data.hlsUrl || '',
      };
      dispatch(updateArticleMeta(articleId, dataObj));
      setMetadata({...metadata});
      setRequesting(false);
      // goToMetaPage();
    } catch (err) {
      setRequesting(false);
      const { message } = err;
      console.log(err);
      dispatch(setAppMessage({ message: message || 'Unable to save audio.', header: 'Error', type: 'alert' }));
      // do error handling
    }
  }

  const uploadMediaToPisco = async (file) => {

    try {
      setRequesting(true);
      let res = await dispatch(saveMediaToPisco(file));
      const { localUploadResponse: { cdn, s3Url }} = res;
      setMetadata({...metadata, cdn, s3Url});
      setRequesting(false);
    } catch (err) {
      setRequesting(false);
      const { message } = err;
      dispatch(setAppMessage({ message: message || 'Unable to upload media.', header: 'Error', type: 'alert' }));
      // do error handling
    }
  }

  const goToNext = () => {
    if (!metadata.hlsUrl) return dispatch(setAppMessage({ message:'Audio is required.', header: 'Error', type: 'alert' }));
    if (!title) return setErrTitle(true);
    setErrTitle(false);
    setShowMetaDialog(true);
  }

  const saveDraft = async () => {
    setSavingMeta(true);
    let tempArticleId = articleId;
    try {

      const data = {
        title,
        headerType: headerType || 'audio/mp3',
        headerContent: JSON.stringify(metadata.headerContent ||{}),
        thumbnail :  tileData[selectedItem],
        hlsUrl : metadata.hlsUrl || '',
      };
      await dispatch(updateArticleMeta(tempArticleId, data));
      setSavingMeta(false);
      history.push("/dash/create");
    } catch (err) {
      setSavingMeta(false);
      const { message } = err;
      dispatch(setAppMessage({ message: message || 'Unable to save audio article.', header: 'Error', type: 'alert' }));
      // handle error
    }
  }
  async function handleDeleteArticle(status) {
    try {
      setDeletingvideo(true);
      await dispatch(deleteArticle(articleId, metadata.status ||0 ));
      setDeletingvideo(false);
      setShowSelectDialog(true);
      history.push('/dash/create');
    } catch (err) {
      setDeletingvideo(false);
      dispatch(setAppMessage({ message: err.message || 'unable to delete image article', header: 'Error', type: 'alert' }));
    }
  }

  const handleFileUpload = (target, type) => {
    const allowed = ['jpg','jpeg','png','bmp'];
    const file = target && target.files[0];
    const uploadType = type && type.split("/")[0];
    const extension = file && file.type.split("/")[1];
    if (file && type && !(file.type.indexOf(uploadType) > -1)) {
      //this.props.InvalidFileError();
      return;
    }
    if (uploadType === 'image' && extension === 'webp') {
      //this.props.InvalidFileError('Webp images are not allowed, as not supported by Safari browser.');
      return;
    }
    if (!file || !file.name) {
      return;
    }
    if (allowed.indexOf(extension) < 0) {
      dispatch(setAppMessage({ message: 'Unsupported media format.', header: 'Error', type: 'alert' }));
      return;
    }
    const { size } = file;
    const sizeInMb = size / 1000000;
    if (sizeInMb > 1024) {
      dispatch(setAppMessage({ message: 'image larger than 1GB not allowed.', header: 'Error', type: 'alert' }));

      return;
    }
    setFileName(file.name);
    const fr = new FileReader();
    fr.onload = (stream) => {
      setthumbnailContent(stream.target.result);
      setThumbnailDialog(true);
    }
    fr.readAsDataURL(file);
    
    
  }
  const setSelected = (item) => {
    if (item === selectedItem) {
      setSelectedItem(null);
      return;
    }
    setSelectedItem(item);
  }
  const openAudioRecorder = () => {
    setShowAudioRecorder(true);
  }
  const closeAudioRecorder = () => {
    setShowAudioRecorder(false);
  }
  const resetAudio = ()=>{
    metadata.hlsUrl = null;
    setMetadata({...metadata});
  }
  const goToBack = ()=>{
    setShowSelectDialog(true);
    setShowMetaDialog(false);
    history.push(`/dash/create/podcast/${articleId}`);
  }
  const openPreview = ()=>{
    setShowPreviewDialog(true);
  }
  const closePreview= ()=>{
    setShowPreviewDialog(false);
  }
  const deleteArticlePopup = ()=>{
    dispatch(setAppMessage({ message: '',
    leftButtonText:'Close', 
    rightButtonText:'Delete', 
    handleSuccess: handleDeleteArticle, header: 'Are you sure you want to delete audio post ?' }));      
  }
  
  return (
    <>
     <PodcastArticlePreview
        open={showPreviewDialog}
        metadata={metadata}
        status={status}
        title={title}
        closePreview ={closePreview}
        description={metadata.description}
      />
    <CropImageDialog
      ref={childRef}
      open={openThumbDialog}
      setThumbnailDialog={setThumbnailDialog}
      thumbnailContent={thumbnailContent}
      setTileData ={setTileData}
      tileData = {tileData}
      name = {filename}
      selectedItem = {selectedItem}
      setSelectedItem = {setSelectedItem}
    />
     <AudioRecorder 
        open={showAudioRecorder}
        goToHome={closeAudioRecorder}
        uploadMediaToPisco={saveAudioFile}>
        
      </AudioRecorder>
    <Dialog
      fullScreen
      open={showSelectDialog} >
        { showMetaDialog &&
        <PodcastArticleMetaHome
        title = {title}
        headerType= {headerType}
        goToBack={goToBack}
        openPreview ={openPreview}
        open={showMetaDialog}
        domainInfo = {domainInfo}
        metadata={metadata}
        articleId = {articleId}
        deleteLoader ={delteVideo}
        handleDeleteArticle ={handleDeleteArticle}
        thumbnail = {tileData[selectedItem]}

        sectionID = {sectionID}
        setSectionID = {setSectionID}
        subSectionID ={subSectionID}
        setSubSectionID = {setSubSectionID}
        subsubsectionID = {subsubsectionID}
        setSubSubSectionID = {setSubSubSectionID}
        sectionDefaultvalue = {sectionDefaultvalue}
        setSectionDefaultVlaue = {setSectionDefaultVlaue}
        subSectionDefaultvalue = {subSectionDefaultvalue}
        setSubSectionDefaultVlaue = {setSubSectionDefaultVlaue}
        subSubSectionDefaultvalue = {subSubSectionDefaultvalue}
        setSubSubSectionDefaultVlaue = {setSubSubSectionDefaultVlaue}
      />
        }
      <Grid container className={classes.minHeight}>
        {/**----------------------------Header Section---------------------------- */}
        <Header position="fixed" top={0}>
          <Grid container justify="center">
            {<Grid item xs={12} md={12}>
              <Box display="flex" alignItems="center" justifyContent="space-between" ml={isMobile ? 4 : 25} mr={isMobile ? 4 : 25} >
               <Box display="flex" alignItems="center" height="100%">
                  <CloseIcon style={{ color: 'white' }} onClick={discardMedia} />
                </Box>
                
                <Box component="p" display="flex" justifyContent="center">AUDIO</Box>
                <Box component="p" >Step 1 of 2</Box>
              </Box>
            </Grid>
            }
          </Grid>
        </Header>
        {/**----------------------------End Header Section---------------------------- */}
        {(savingTitle) && <LinearLoader position="sticky" top={`${isMobile ? '70px': '75px'}`} width="100%"/>}
        {creatingArticle || fetchingVideoArticle ?
          <Grid container justify="center" className={classes.bodyContainer}>
            <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
              <Box width="100%" height="100%">
                <CircularLoader height="auto" message={fetchingVideoArticle ?"fetching audio post":"creating audio post ..."} />
              </Box>
            </Box>
          </Grid>
          :
          <Grid container justify="center" className={classes.bodyContainer}>
            {/* <Grid container justify="center" alignItems="center"  className={classes.imgContainer}> */}
            <Grid item xs={12} md={8} className={isMobile ? classes.mobileMargin : {}}>

              <Box mt={6}>
                <Box component="span" fontWeight="bold" fontSize={14}>{
                  requesting ?
                    'Uploading audio'
                    :
                    metadata.cdn ? 'Audio Uploaded' :
                      'Upload Audio'}</Box>
                <Box component="span" fontSize={12}>&nbsp;{requesting ? 
                `${file && file.name}. Please wait` : metadata.cdn ? '' : '(mp3,wav,m4a and wma only. Max file size 10GB)'}</Box>

              </Box>
              <Box mt={3}>
                <Box position="relative" width={279} height={152} bgcolor="pubSecondary" display="flex" justifyContent="center" alignItems="center">
                  {
                    requesting ?
                      <>
                        <CircularLoader height={"auto"} size={20} position="absolute" top={3} right={3} />
                        {/* <Button variant="contained" color="primary" onClick={() => { cancelAPI(); }}>CANCEL</Button> */}
                      </>
                      :
                      metadata.hlsUrl ? <Box width="100%" height="100%" display="flex" alignItems="center"> 
                       <VideoPlayer src={metadata.hlsUrl} width={279} height={40} controls >
                            
                        </VideoPlayer>
                        <DeleteIcon onClick={resetAudio}/>
                      </Box> :
                      <>
                           <WithUploadButton 
                           className={classes.uploadButton}
                           type="audio/*"
                           accept="audio/*" 
                           variant="contained" 
                           onChange={saveAudioFile} 
                           >BROWSE</WithUploadButton>
                           {
                             !((window.navigator.userAgent.indexOf('iPad') > -1 || window.navigator.userAgent.indexOf('iPhone')> -1))
                             &&
                           <MicTwoTone onClick={openAudioRecorder}></MicTwoTone>
                            }
                           </>
                  }


                </Box>

              </Box>
              <Box mt={6}>
                <Box component="span" fontWeight="bold" fontSize={14} display="flex" alignItems="center">
                  Thumbnail Image (jpg, png ,bmp only)
                  <UseInfo info=" Select and crop an image that best represents your audio file. Recommended size 800x420." />
                  </Box>
              </Box>
              <Box display="flex" oveflow="hidden" width="100%">
                <Box className={classes.wrapperClass} id="scrollbarhide">
              <Box mt={3} display="flex" className={classes.thumbnailContainer}>
               
              {
                <Box width={170}><FileUploaderButton
                type="image/*"
                onChange={handleFileUpload}
              />
              </Box>
              }
              {tileData.map((tile, key) => {
                return <Box key={key} className={clsx({ [classes.cardDesgin]: true })} >
                  {
                    tile && tile.toString().indexOf('http') == 0 ? <>
                      <img src={tile} alt="thumb-img" width="100%"
                        height="100%" style={{ objectFit: 'cover', cursor: 'pointer' }} onClick={() => setSelected(key)} />
                      {selectedItem !== null && selectedItem === key && <Box
                        className={classes.slected} width="100%" height="100%" bgcolor="pubBlack" onClick={() => setSelected(key)}>

                      </Box>}
                      {
                        selectedItem !== null && selectedItem === key && <CheckIcon className={classes.checkIconClass} onClick={() => setSelected(key)} />
                      }
                      {selectedItem !== null && selectedItem !== key &&
                        <Box width="100%" height="100%" bgcolor="pubWhite" className={classes.unslected} onClick={() => setSelected(key)} />}
                    </>
                      :
                      <Box width="100%" height="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                        <ImageIcon style={{ opacity: '0.7' }} />
                      </Box>

                  }
                </Box>

              })}
              </Box>
              </Box>
              </Box>
              <Box mt={6}>
                <InputBox
                  helperText={errTitle ? "Title can't empty" :''}
                  onChange={handleTitle}
                  heading="Title*"
                  error={errTitle}
                  value={title || ''}
                  variant="standard"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {`${ (title.length ) || 0}/150`}
                      </InputAdornment>
                    ),
                  }}
                  headingStyle={{
                    component:"span",
                    fontWeight:"bold",
                    fontSize:14,
                    mb: 1,
                  }}
                  placeholder="Enter Title Here"
                  className={{ root: classes.inputRoot }}
                  fullWidth />
                  </Box>
              <Box mt={5} >

                <DraftjsEditor
                  objectMap={content.objectMap}
                  htmlContent={content.html}
                  updateWordCount={(count) => setWordCount(count)}
                  lastUpdate={lastUpdate}
                  isMobile={isMobile}
                  wordCount={wordCount}
                  DraftPlaceholder = {"Write your description."}
                  bodytitle = {'Description'}
                  handleMediaUpload={handleMediaUpload}
                  updateApi={updateHtmlContent} />

              </Box>
            </Grid>
          </Grid>

        }
        {/**----------------------------Footer Section-------------------------------------*/}
        <Grid item xs={12} md={12}>
          <Footer>
            <Box width="100%"
              
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%">
              <Grid item md={8} xs={12} className={isMobile ? classes.mobileMargin : {}} >
                <Box display="flex" justifyContent={metadata.status ===4 ?"flex-end":"space-between"}>
                 { metadata.status !==4 && 
                  <Box display="flex">
                    <Box display="flex" alignItems="center">
                    <Button
                      color="secondary"
                      variant="contained"
                      disabled={creatingArticle || delteVideo || requesting}
                      onClick={() => saveDraft()}>
                      {isMobile ? <DraftsIcon /> : 'Save Draft'}
                    </Button>
                     {savingMeta && <CircularLoader ml={4} size={20} height="auto" />}
                    </Box>
                    

                    <Box display="flex" alignItems="center">
                      <Button variant="contained" disabled={creatingArticle || delteVideo || requesting} boxProps={{ ml: 3 }} color="secondary"
                        onClick={deleteArticlePopup}
                      >
                        <DeleteIcon />

                      </Button>
                      {delteVideo && <CircularLoader ml={4} size={20} height="auto" />}
                    </Box>
                  </Box>
                  }
                  <Box display="flex">
                    {/* <Button variant="contained"  color="secondary" onClick={openThumbnailDialoghandler}>
                      SET THUMBNAIL IMAGE
                    </Button> */}
                    <Button
                      variant="contained"
                      disabled={creatingArticle || delteVideo || requesting}
                      boxProps={{ ml: 4 }}
                      onClick={() => goToNext()}
                    >Next</Button>
                  </Box>
                </Box>
              </Grid>
            </Box>
          </Footer>
        </Grid>
        {/**----------------------------End Footer Section-------------------------------------*/}
      </Grid>
    </Dialog>
    </>
  )
}
export default PodcastHomePage;
