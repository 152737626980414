import axios from "axios";

/**
 * interceptor for response. Modifiying the error obj to have the requried keys at top level.
 */
axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if(error.response) {
    const { status, data } = error.response;
    return Promise.reject({status, data, message:data.message});
  }
  return Promise.reject(error);
});

/**
 * Function to make the api call it will return the axios
 * promise that will resolve or reject based on the response
 * @param {RequestObject}
 * @returns {Promise}
 */ 

let cancelSource;

const headers = {
  'Access-Control-Allow-Credentials': true,
  'Access-Control-Allow-Origin': '*',
};
function makeApiCall({method, endpoint, data}) {
  //check for valid endpoint
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  if(!endpoint || !method) return Promise.reject({message:"Invalid request data"});

  endpoint = endpoint.startsWith("/") ? endpoint : `/${endpoint}`;
  
  method = method.toLowerCase();

  //check for valid method
  if (!method === 'get' && !method === 'post' && !method === 'delete' && !method === 'put') return Promise.reject({message:"Invalid request method."});
  cancelSource = source;
  if(localStorage.getItem('accessToken')) {
    headers['Authorization'] = localStorage.getItem('accessToken');
  }
  return axios({
    baseURL: process.env.REACT_APP_API_SERVER || 'http://localhost:7000',// set the base url
    timeout: 200000000000,
    headers: headers,
    withCredentials: true,
    method: method,
    url: endpoint,
    data: data,
    cancelToken: source.token,
  });
}

//export the api func
export default makeApiCall;

export function calcelAPiCall(){
  return cancelSource;
}