import React from 'react';
import Typography from '../../../commons/muiCustomComponents/Typography';
import Box from '../../../commons/muiCustomComponents/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  header: {
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.25)",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    height: theme.spacing(14),
    background: theme.palette.pubWhite,
    zIndex: 100,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    [theme.breakpoints.up('sm')]: {
      right: "40%",
      height: theme.spacing(20),
      paddingLeft: theme.spacing(20),
    },
  },
  headingText: {
    fontSize: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.spacing(6),
    },
  },
}));

const OnBoardHeader = () => {
  const classes = useStyles();

  return (
    <Box className={classes.header} display="flex" alignItems="center" justifyContent="space-between">
      <Typography className={classes.headingText} variant="h4" component="h4">
        Setup basics
      </Typography>
    </Box>
  )
}

export default OnBoardHeader;
