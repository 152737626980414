import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Box from "commons/muiCustomComponents/Box";
import Button from "commons/muiCustomComponents/Button";
import {Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  dialogContainer: {
    padding: theme.spacing(5),
    boxSizing: "border-box",
  },
  btnContainer:{
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(5),
  },
  primaryBtn: {
    background: theme.palette.pubdeepSkyBlue,
    color: theme.palette.pubWhite,
    marginLeft: theme.spacing(2),
  },
}));

const CSVFakePreview = (props) => {
  const {renderData, open, closeCSVFakeModal} = props;

  const classes = useStyles();
  
  return (
    <>
      <Dialog open={open} maxWidth={'md'}
      fullWidth>
        <Box className={classes.dialogContainer}>
          <Box>Preview</Box>
          <Table style={{ minWidth: '650px' }}>
            <TableHead>
              <TableRow>
                <TableCell>email</TableCell>
                <TableCell>firstName</TableCell>
                <TableCell>lastName</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                renderData.map((ele, key) => {
                  return (
                    <TableRow key={key}>
                      <TableCell>{ele[0]}</TableCell>
                      <TableCell>{ele[1]}</TableCell>
                      <TableCell>{ele[2]}</TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
          <Box className={classes.btnContainer}>
            <Button onClick={closeCSVFakeModal} color="primary">Cancel</Button>
          </Box>
        </Box>
      </Dialog>
    </>
  )
}

export default CSVFakePreview;
