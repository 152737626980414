import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { useSelector } from "react-redux";
import Input from "../../../commons/muiCustomComponents/Input";
import Box from "../../../commons/muiCustomComponents/Box";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Grid from "commons/muiCustomComponents/Grid";
import Button from "commons/muiCustomComponents/Button";
import { LinearLoader,PageBlockLoader, CircularLoader } from 'commons/commonWrapper/Loaders';
import { LogglyTracker } from 'loggly-jslogger';

const logger = new LogglyTracker();
logger.push({ 'logglyKey': '142d21f5-10d3-47a9-aa5a-f83e0a3abed7', 'sendConsoleErrors' : true, 'tag' : 'ardbeg,advertisement,bankinfo' });

/**
 * Handle Error part 
 * Transfer only successpart to parent
 */

function BankInfo(props, ref) {
  
  const {
    name, setName,
    ifsc, setIFSC,
    accountNumber, 
    setAccountNumber,
    confirmAccountNumber, 
    setConfirmAccountNumber,
    account, setAccount,
    matchAccountNumber, 
    setMatchAccountNumber,
    updateBankDetails,
    setActiveStepItem,
    setCompletedItem,
    forthStepComplete,
  } = props;

  const [isLoading ,setLoading] = useState(false);
  const [errAcName , setErrAcName]  = useState(false);
  const [errAcNumber ,setErrAcNumber] = useState(false);
  const [errConfirmAcNumber,setErrConfirmAcNUmber] = useState(false);
  const [errifscNumber ,setErrIfscNumber] = useState(false);
  const {landing: {userProfile}} = useSelector(state => state);


  const handleName = (e) => {
    setName(e.currentTarget.value)
  }
  const handleIFSC = (e) => {
    setIFSC(e.currentTarget.value)
  }
  const handleAccountNumber = (e) => {
    setAccountNumber(e.currentTarget.value)
  }
  const handleConfirmAccountNumber = (e) => {
    setConfirmAccountNumber(e.currentTarget.value)
    // eslint-disable-next-line eqeqeq
    if (e.currentTarget.value == accountNumber) setMatchAccountNumber(true)
    else setMatchAccountNumber(false)
  }
  const handleAccount = (e) => {
    setAccount(e.target.value)
  }
  const getBankData = () => {
    return { name, ifsc, accountNumber, account }
  }
  useImperativeHandle(ref, () => getBankData);

  const saveBankInfo = async ()=>{

    if (!name) return setErrAcName(true)
    if (!accountNumber) return setErrAcNumber(true)
    if (!confirmAccountNumber) return setErrConfirmAcNUmber(true)
    if (!ifsc) return setErrIfscNumber(true)
    if (!matchAccountNumber) return setErrConfirmAcNUmber(true)
    
    setErrAcName(false)
    setErrAcNumber(false)
    setErrConfirmAcNUmber(false)
    setErrIfscNumber(false)

    try{
      setLoading(true)
      await updateBankDetails({
        account_holder_name :name,
        account_number:accountNumber,
        account_type :account,
        ifsc_code:ifsc,
      });
      if (!forthStepComplete) {
        setActiveStepItem && setActiveStepItem(4);
        setCompletedItem && setCompletedItem({0:true,1:true,2:true,3:true});
      }
      setLoading(false)

    }
    catch(err){
      const data = {
        'error': err,
        'email': (userProfile||{}).email || '',
        'url': (window.location||{}).href || '',
        'user-agent': window.navigator && window.navigator.userAgent ? window.navigator.userAgent : 'no user agent',
        'active-domain': sessionStorage.getItem('active_domain_id') || localStorage.getItem('active_domain_id') || 0,
      };
      logger.push({
          'type': 'error',
          'data': data,
        });
      setLoading(false)
    }
    
  }
  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Box m={2}>
          <Input
            fullWidth
            color="secondary"
            onChange={handleName}
            value={name}
            error={errAcName}
            label="Account Owner Name *">
          </Input>
          </Box>
        </Grid >
        <Grid item xs={12} md={6}>
        <Box m={2}>
          <Input
            fullWidth
            color="secondary"
            onChange={handleIFSC}
            value={ifsc}
            error={errifscNumber}
            label="IFSC Number *"
          >
          </Input>
          </Box>
        </Grid>
      </Grid >


      <Grid container>
        <Grid item xs={12} md={6}>
        <Box m={2}>
          <Input
            fullWidth
            color="secondary"
            value={accountNumber}
            error={errAcNumber}
            onChange={handleAccountNumber}
            label="Account Number *">
          </Input>
          </Box>
        </Grid >
        <Grid item xs={12} md={6}>
        <Box m={2}>
          <Input
            fullWidth
            color="secondary"
            value={confirmAccountNumber}
            error={errConfirmAcNumber}
            onChange={handleConfirmAccountNumber}
            label="Confirm Account Number *"
            InputProps={matchAccountNumber && {
              endAdornment: <InputAdornment position="end"><CheckCircleIcon /></InputAdornment>,
            }}
          >
          </Input>
          </Box>
        </Grid >
      </Grid >

      <Grid container>
        <Grid item xs={12} md={6}>
          <Box m={2}>
            <FormControl fullWidth>
              <InputLabel > Account Type *</InputLabel>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={account}
                onChange={handleAccount}
              >
                <MenuItem value={'saving'}>Savings Account</MenuItem>
                <MenuItem value={'current'}>Current Account</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
      <Box display="flex">
        <Button variant="contained" disabled={isLoading} onClick={saveBankInfo}>Save</Button>
        {isLoading && <CircularLoader height="auto" size={20} ml={2}/>}
      </Box>
    </>
  )
}
export default forwardRef(BankInfo);
